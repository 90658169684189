import { combineReducers } from 'redux';

import banner, { cashbackPromoBannerActions, cashbackPromoBannerOperations } from './banner';
import banners, { cashbackPromoBannersActions, cashbackPromoBannersOperations } from './banners';
import campaignAttributes, { campaignAttributesActions, campaignAttributesOperations } from './campaignAttributes';
import eventSchema, { eventSchemaOperations } from './eventSchema';
import paymentCeilings, { paymentCeilingsActions, paymentCeilingsOperations } from './paymentCeilings';
import stackableGroup, {
  stackableGroupCampaignsActions,
  stackableGroupCampaignsOperations,
  stackableGroupEventsActions,
  stackableGroupEventsOperations,
} from './stackableGroup';

import stackableGroups, { stackableGroupsOperations } from './stackableGroups';
import userBudgetTokens, { userBudgetTokensActions, userBudgetTokensOperations } from './userBudgetTokens';

export {
  campaignAttributesActions,
  campaignAttributesOperations,
  cashbackPromoBannerActions,
  cashbackPromoBannerOperations,
  cashbackPromoBannersActions,
  cashbackPromoBannersOperations,
  eventSchemaOperations,
  paymentCeilingsActions,
  paymentCeilingsOperations,
  stackableGroupCampaignsActions,
  stackableGroupCampaignsOperations,
  stackableGroupEventsActions,
  stackableGroupEventsOperations,
  stackableGroupsOperations,
  userBudgetTokensActions,
  userBudgetTokensOperations,
};

export default combineReducers<any, any>({
  banner,
  banners,
  campaignAttributes,
  eventSchema,
  paymentCeilings,
  stackableGroup,
  stackableGroups,
  userBudgetTokens,
});
