import styled from 'styled-components';

export const TableCell = styled.td`
  max-width: 200px;
  overflow: auto;
  padding: 1rem 24px;
  font-size: 14px;
  color: #29394f;
  line-height: 1.29;
  text-align: left;
  vertical-align: middle;
`;
