import { connect } from 'react-redux';

import * as actionCreators from './actions';
import * as operations from './operations';
import * as selectors from './selectors';

const mapStateToProps = state => ({
  template: selectors.template(state),
});

const mapDispatchToProps = {
  ...operations,
  ...actionCreators,
};

export default connect(mapStateToProps, mapDispatchToProps);
