import * as React from 'react';

import { ISubChannel } from 'app/types/Campaign';

import { SectionContainer } from '../SectionContainer';
import ChannelSelection from './ChannelSelection';
import SelectedChannel from './SelectedChannel';

type FnSelectChannel = (mediumId: number, subChannel?: ISubChannel) => (...args: Array<any>) => any;

type Props = {
  creative:
    | {
        apiType: string;
      }
    | null
    | undefined;

  mediumId: number;
  selectChannel: FnSelectChannel;
  subChannel: ISubChannel;
  submitAllForms: boolean;
  setCampaignField: (...args: Array<any>) => any;
  name: string;
};

const renderSelectedChannel = (
  mediumId: number,
  creative: { apiType: string } | null | undefined,
  subChannel: ISubChannel,
  props: {
    selectChannel: FnSelectChannel;
    submitAllForms: boolean;
    setCampaignField: (...args: Array<any>) => any;
    name: string;
  },
) => {
  if (creative) {
    return <SelectedChannel {...props} creative={creative} mediumId={mediumId} subChannel={subChannel} />;
  }

  return <SelectedChannel {...props} mediumId={mediumId} subChannel={subChannel} />;
};

export const Channel = ({ creative, mediumId, selectChannel, subChannel, ...rest }: Props) => (
  <SectionContainer>
    {!mediumId ? (
      <ChannelSelection selectChannel={selectChannel} />
    ) : (
      renderSelectedChannel(mediumId, creative, subChannel, {
        ...rest,
        selectChannel,
      })
    )}
  </SectionContainer>
);
