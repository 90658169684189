import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { fetchSlotGroupTypes as opFetchSlotGroupTypes } from 'app/ducks/bannerManagement/slotGroupTypes/operations';
import { mapStateToSlotGroupTypes } from 'app/ducks/bannerManagement/slotGroupTypes/selectors';

const mapDispatchToProps = {
  fetchSlotGroupTypes: opFetchSlotGroupTypes,
};

export default compose(
  connect(mapStateToSlotGroupTypes, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchSlotGroupTypes, slotGroupTypes, slotGroupTypesLoading } = this.props;

      if (!(slotGroupTypes || []).length && !slotGroupTypesLoading) {
        fetchSlotGroupTypes();
      }
    },
  }),
);
