import { combineReducers } from 'redux';

import binder, { binderActions, binderOperations } from './binder';
import creative, { creativeActions, creativeOperations } from './creative';
import creatives, { creativesActions, creativesOperations } from './creatives';
import engageCategories, { engageCategoriesActions, engageCategoriesOperations } from './engageCategories';
import gaTypes, { gaTypesActions, gaTypesOperations } from './gaTypes';
import landingPageTypes, { landingPageTypesActions, landingPageTypesOperations } from './landingPageTypes';
import locations, { locationsActions, locationsOperations } from './locations';
import slotGroupTypes, { slotGroupTypesActions, slotGroupTypesOperations } from './slotGroupTypes';
import storefront, { storefrontActions, storefrontOperations } from './storefront';
import storefronts, { storefrontsActions, storefrontsOperations } from './storefronts';
import variantTypes, { variantTypesActions, variantTypesOperations } from './variantTypes';
import widgetTypes, { widgetTypesActions, widgetTypesOperations } from './widgetTypes';

import tags from './tags';

export {
  binderActions,
  binderOperations,
  creativeActions,
  creativeOperations,
  creativesActions,
  creativesOperations,
  engageCategoriesActions,
  engageCategoriesOperations,
  gaTypesActions,
  gaTypesOperations,
  landingPageTypesActions,
  landingPageTypesOperations,
  locationsActions,
  locationsOperations,
  slotGroupTypesActions,
  slotGroupTypesOperations,
  storefrontActions,
  storefrontOperations,
  storefrontsActions,
  storefrontsOperations,
  variantTypesActions,
  variantTypesOperations,
  widgetTypesActions,
  widgetTypesOperations,
};

export default combineReducers({
  binder,
  creative,
  creatives,
  engageCategories,
  gaTypes,
  landingPageTypes,
  locations,
  slotGroupTypes,
  storefront,
  storefronts,
  tags,
  variantTypes,
  widgetTypes,
});
