import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Fab, CircularProgress } from '@material-ui/core';

import { FlashOn, Refresh, Error, DonutSmall } from '@material-ui/icons';

import Tooltip from '../Tooltip';

const circularButtonStyle = {
  height: 37,
  width: 37,
  marginRight: '0.5rem',
};

const resultTextWhileLoading = count => (count === undefined || count < 0 ? 'Calculating...' : `Selected: ${count.toLocaleString()}`);

const resultText = (count, classes) =>
  count === undefined || count < 0 ? (
    <>
      <Error color="error" style={{ marginRight: 3 }} />
      Error while calculating
    </>
  ) : (
    <div className={classes.wrapper}>Selected: {count.toLocaleString()}</div>
  );

const buildContent = (hasCalculated, isCalculating, count, calculate, classes) => {
  if (isCalculating) {
    return hasCalculated ? (
      <Typography variant="subtitle1" className={classes.wrapper}>
        <Fab color="primary" style={circularButtonStyle}>
          <FlashOn />
        </Fab>

        <CircularProgress size={47} className={classes.fabProgress} />

        {resultTextWhileLoading(count)}
      </Typography>
    ) : (
      <>
        <Button variant="contained" color="primary" disabled>
          Calculate
        </Button>

        <CircularProgress size={24} className={classes.buttonProgress} />
      </>
    );
  }

  return hasCalculated ? (
    <Typography variant="subtitle1" className={classes.wrapper}>
      <Tooltip title="Re-calculate">
        <Fab color="primary" onClick={calculate} style={circularButtonStyle}>
          <Refresh />
        </Fab>
      </Tooltip>
      {resultText(count, classes)}
    </Typography>
  ) : (
    <Tooltip title="Calculate the size of the audience defined by the current rules.">
      <Button variant="contained" color="primary" onClick={calculate}>
        <DonutSmall style={{ marginRight: 10 }} />
        Calculate
      </Button>
    </Tooltip>
  );
};

export const SegmentCount = ({ hasCalculated, isCalculating, handleCalculate, count, classes }) => (
  <div className={classes.countContainer}>{buildContent(hasCalculated, isCalculating, count, handleCalculate, classes)}</div>
);

SegmentCount.propTypes = {
  hasCalculated: PropTypes.bool.isRequired,
  isCalculating: PropTypes.bool.isRequired,
  handleCalculate: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
