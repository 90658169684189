import styled from 'styled-components';
import { sc } from 'app/styles';
import { Icon } from 'app/midgarComponents';

const Flex = styled.div`
  display: inline-flex;
`;

const Label = styled.div`
  text-transform: uppercase;
  color: ${sc.grey};
  margin: 0 1rem 1rem 0;
  text-align: left;
  font-size: ${sc.fontSize};
`;

const Section = styled.section`
  margin: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${sc.greyLight};
`;

const SubLabel = styled.span`
  text-transform: lowercase;
  color: ${sc.greyLight};
  font-size: ${sc.fontSizeSmaller};
`;

const ValidationIcon = styled(Icon)`
  margin-top: -4px;
  margin-left: 0.5rem;
  font-weight: 900;
`;

const Error = styled.div`
  color: ${sc.danger};
`;

export { Flex, Label, Section, SubLabel, ValidationIcon, Error };
