import React from 'react';
import styled from 'styled-components';
import { inject } from 'app/decorators';
import { Button as _Button } from 'app/midgarComponents';
import * as SegmentsOperations from 'app/ducks/segments2/segments/operations';
import { download } from './helpers';
import { compose } from 'recompose';

type Props = {
  id: string;
  SegmentsOperations: typeof SegmentsOperations;
};

const Download = (props: Props) => {
  const {
    id,
    SegmentsOperations: { getDownloadStatus },
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [validUrl, setValidUrl] = React.useState(true);

  const onClick = async e => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    const url = await getDownloadStatus(id);
    if (['file:///dev/null', '', undefined].includes(url)) {
      setValidUrl(false);
    } else {
      download([url]);
    }

    setLoading(false);
  };

  return (
    <Button type="secondary" disabled={!validUrl} onClick={e => onClick(e)}>
      {loading ? 'Downloading...' : 'Download'}
    </Button>
  );
};

export default compose(inject({ SegmentsOperations }))(Download);

const Button = styled(_Button)`
  border: none;
  box-shadow: none;
  width: 200px;
  height: 0;
  line-height: 0;
  padding: 0.5rem 0;
  text-align: left;
  &:hover,
  &:active,
  &:focus {
    background-color: unset;
    color: #00b9f5;
    border: none;
    text-decoration: underline;
  }
`;
