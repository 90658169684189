import { compose, withHandlers } from 'recompose';

import { DialogShell } from './DialogShell';

const handleClose =
  okayed =>
  ({ onClose }) =>
  () => {
    if (onClose) {
      onClose(okayed);
    }
  };

const addHandlers = withHandlers({
  handleOkay: handleClose(true),
  handleCancel: handleClose(false),
});

const enhance = compose(addHandlers);

export default enhance(DialogShell);
