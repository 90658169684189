import { fetchLocations as apiFetchLocations } from 'app/api/bannerManagementV2/locations';
import { fromApiLocations } from 'app/api/bannerManagementV2/locations/mappers';
import { ILocation as ApiLocation } from 'app/api/bannerManagementV2/locations/types';
import { DispatchFn, GetStateFn } from 'app/types';

import * as actions from './actions';

export const fetchLocations = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      locations: { cities: prevCities, loading, states: prevStates },
    },
  } = getState();

  if (loading || (prevCities || []).length > 0 || (prevStates || []).length > 0) {
    return;
  }

  dispatch(actions.getLocationsStart());

  try {
    const apiLocations: Array<ApiLocation> = await apiFetchLocations();
    return dispatch(actions.getLocationsSuccess(fromApiLocations(apiLocations)));
  } catch (err) {
    const errorStr = err.error && err.error.msg;
    return dispatch(actions.getLocationsFailed(errorStr));
  }
};
