import { compose, flattenProp } from 'recompose';
import { connect } from 'react-redux';
import { audienceOperations } from 'app/ducks';

import * as audienceActions from 'app/ducks/segments2/segment/actions';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import { General } from './General';

const mapStateToProps = ({ audience: { audience } }) => ({
  audience,
});

const mapDispatchToProps = {
  handleChangeName: ev => dispatch => dispatch(audienceActions.setName(ev.target.value)),
  handleChangeDescription: ev => dispatch => dispatch(audienceActions.setDescription(ev.target.value)),
  handleAddTag: audienceOperations.addTag,
  handleRemoveTag: audienceOperations.removeTag,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), flattenProp('audience'), withStyles(styles, { withTheme: true }));

export default enhance(General);
