import * as React from 'react';

import Generic from './Generic';

export default ({
  showTemplateToggleV2,
  isDynamicTemplatesDeeplinkEnable,
}: {
  showTemplateToggleV2: boolean;
  isDynamicTemplatesDeeplinkEnable: boolean;
}) => {
  return <Generic showTemplateToggleV2={showTemplateToggleV2} isDynamicTemplatesDeeplinkEnable={isDynamicTemplatesDeeplinkEnable} />;
};
