import React from 'react';

import { Spinner } from 'app/midgarComponents';
import { ISuggestion } from 'app/types';

import { handleSelectSuggestion, toSuggestion, toTitle } from '../../utils';
import { AutocompleteWithDisabled, ErrorContainer } from '../../common/components';
import useBanners from './useBanners';
import useViewsCma from './useViewsCma';
import { checkStorefront } from './utils';

type Props = {
  bannerId?: number;
  className?: string;
  disabled?: boolean;
  onSelectBanner: (arg0: ISuggestion) => (...args: Array<any>) => any;
  permittedStorefronts: Array<ISuggestion> | null | undefined;
  storefrontId?: number;
  storefronts: Array<ISuggestion> | null | undefined;
  viewId?: number;
};

export default ({ bannerId, className, disabled, onSelectBanner, permittedStorefronts, storefrontId, storefronts, viewId }: Props) => {
  const { views, viewsError, viewsLoading } = useViewsCma(storefrontId);
  const { banners, bannersError, bannersLoading } = useBanners(storefrontId, viewId);

  if (viewsLoading || bannersLoading) {
    return <Spinner size={30} />;
  }

  const { isStorefrontPermitted, storefrontExists } = checkStorefront({ permittedStorefronts, storefrontId, storefronts });

  const selectedView = viewId ? views?.find(v => v.id === viewId) : undefined;
  const viewExists = !!viewId && !!selectedView;

  const bannerSuggestions = (banners || []).map(toSuggestion);
  const selectedBanner = bannerId ? banners.find(b => b.id === bannerId) : null;
  const bannerExists = !!bannerId && !!selectedBanner;
  const isError = !storefrontExists || !viewExists || !!viewsError || !!bannersError || !bannerSuggestions.length;

  return (
    <div className={className}>
      <AutocompleteWithDisabled
        disabled={isError || !isStorefrontPermitted || disabled}
        error={isError || !bannerExists}
        id="banner"
        label="Banner"
        loading={bannersLoading}
        name="banner"
        onSelect={handleSelectSuggestion(banners, onSelectBanner)}
        required
        suggestions={bannerSuggestions}
        value={selectedBanner ? toTitle(selectedBanner) : String(bannerId || '')}
      />

      {storefrontExists && viewExists && (
        <>
          {!!bannerId && !selectedBanner && <ErrorContainer>Banner {bannerId} does not exist</ErrorContainer>}

          {bannerSuggestions.length === 0 && <ErrorContainer>No Banners in View {viewId}</ErrorContainer>}

          {!!bannersError && <ErrorContainer>Error Retrieving Banners</ErrorContainer>}
        </>
      )}
    </div>
  );
};
