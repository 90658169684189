import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { getEngageCategories } from 'app/ducks/bannerManagement/engageCategories/operations';
import { mapStateToEngageCategories } from 'app/ducks/bannerManagement/engageCategories/selectors';

const mapDispatchToProps = {
  fetchEngageCategories: getEngageCategories,
};

export default compose(
  connect(mapStateToEngageCategories, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { engageCategories, engageCategoriesLoading, fetchEngageCategories } = this.props;

      if (!(engageCategories || []).length && !engageCategoriesLoading) {
        fetchEngageCategories();
      }
    },
  }),
);
