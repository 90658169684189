import * as React from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import { withAppConfig } from 'app/decorators';
import { findChannelSetup } from 'app/ducks/configStore/utils';
import { IAppConfig, IChannelSetup } from 'configs/apps/types';
// eslint-disable-next-line import/no-cycle
import { getChannelsConfig } from 'configs/channels';
import { IChannelConfig } from 'configs/channels/types';
import { eventsBased } from 'app/utilities/constants';
import { TriggerSelectionProps } from './types';

const getTriggers = (channelConfig: IChannelConfig | null | undefined, channelSetup: IChannelSetup | null | undefined) => {
  if (channelSetup && channelSetup.triggers) {
    return channelSetup.triggers;
  }
  return channelConfig ? channelConfig.triggers || [] : [];
};

const TriggerSelectionBase = ({
  mediumId,
  useEveryoneAudience,
  updateRecurring,
  toggleModalState,
  triggerType,
  appConfig,
}: TriggerSelectionProps & { appConfig: IAppConfig }) => {
  const channelsConfig = getChannelsConfig(appConfig);
  const channelConfig = channelsConfig[mediumId];
  const channelSetup = channelConfig ? findChannelSetup(appConfig, channelConfig.name) : undefined;
  const triggers = getTriggers(channelConfig, channelSetup);

  if (!triggers || triggers.length === 0) {
    return <section data-qa="unsupported-mediumId-message">Unsupported mediumId = {mediumId}</section>;
  }

  const checkAudienceState = ev => {
    if (useEveryoneAudience && triggers.find(trigger => trigger.value === eventsBased.value) && ev.target.value !== eventsBased.value) {
      toggleModalState({ triggerType: ev.target.value });
    } else {
      updateRecurring(ev);
    }
  };

  return (
    <RadioGroup
      row
      aria-label="trigger selection"
      name="trigger-selection"
      value={triggerType}
      onChange={checkAudienceState}
      data-qa="trigger-selection"
    >
      {triggers.map(({ name, value }) => (
        <FormControlLabel value={value} label={name} key={value} control={<Radio color="primary" />} />
      ))}
    </RadioGroup>
  );
};

export const TriggerSelection = withAppConfig(TriggerSelectionBase);
