import * as React from 'react';

import { VariableProps } from './types';
import { FilterVariableLabel, Dropdown, FilterRow, Col } from './styledComponents';
import { FilterValue } from './FilterValue';

export const Filter = ({
  updateDefault,
  name,
  data_type: dataType,
  prefix,
  optionDict,
  vertical,
  category,
  feature_class,
  parentFeature,
  feature_id,
  rule,
}: VariableProps) => {
  const operands = optionDict[`${vertical}.${category}.${feature_class}.${parentFeature.name}`].find(item => item.name === name).options;

  const updateRules = rules => {
    const newRules = parentFeature;
    newRules.filterRules.operands[0].operands[0].operands = rules;
    updateDefault({
      ...parentFeature,
      filterRules: newRules,
    });
  };

  const changeOperand = op => {
    const filters = parentFeature.filterRules.operands[0].operands[0].operands.map(operand => {
      if (operand.operands[0] === rule.operands[0]) {
        return {
          ...rule,
          op,
          operands: [operand.operands[0], null],
        };
      }
      return operand;
    });
    updateRules(filters);
  };

  const changeValue = ({ target: { value } }) => {
    const filters = parentFeature.filterRules.operands[0].operands[0].operands.map(operand => {
      if (operand.operands[0] === rule.operands[0]) {
        const newOperand = [rule.operands[0], value];
        return {
          ...rule,
          operands: newOperand,
        };
      }
      return operand;
    });
    updateRules(filters);
  };

  return (
    <FilterRow>
      <Col width="40">
        <FilterVariableLabel data-qa="variable-name-text">{prefix ? `${prefix}.${name}` : name}</FilterVariableLabel>
        <Dropdown label="operator" options={operands} onChange={changeOperand} value={rule?.op} />
      </Col>
      <Col width="60">
        <FilterValue rule={rule} changeValue={changeValue} dataType={dataType} featureId={feature_id} />
      </Col>
    </FilterRow>
  );
};
