import { compose, flattenProp } from 'recompose';
import { connect } from 'react-redux';
import { segmentOperations } from 'app/ducks';

import * as segmentActions from 'app/ducks/segments/segment/actions';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import { General } from './General';

const mapStateToProps = ({ segments: { segment } }) => ({
  segment,
});

const mapDispatchToProps = {
  handleChangeName: ev => dispatch => dispatch(segmentActions.setName(ev.target.value)),
  handleChangeDescription: ev => dispatch => dispatch(segmentActions.setDescription(ev.target.value)),
  handleAddTag: segmentOperations.addTag,
  handleRemoveTag: segmentOperations.removeTag,
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), flattenProp('segment'), withStyles(styles, { withTheme: true }));

export default enhance(General);
