import { ILocation } from 'app/types/BannerManagement';

import * as types from './types';

export const getLocationsFailed = (error: string) => ({
  type: types.GET_LOCATIONS_FAILED,
  payload: { error },
});

export const getLocationsStart = () => ({
  type: types.GET_LOCATIONS_START,
});

export const getLocationsSuccess = ({ cities, states }: { cities: Array<ILocation>; states: Array<ILocation> }) => ({
  type: types.GET_LOCATIONS_SUCCESS,
  payload: { cities, states },
});
