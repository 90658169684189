import { withHandlers, compose } from 'recompose';

import { RuleTextEdit } from './RuleTextEdit';

const addHandlers = withHandlers({
  handleChange:
    ({ onChange }) =>
    ({ target: { value } }) =>
      onChange(value),
});

const enhance = compose(addHandlers);

export default enhance(RuleTextEdit);
