import { fetchSlotGroupTypes as apiFetchSlotGroupTypes } from 'app/api/bannerManagementV2/viewItems';
import { DispatchFn, GetStateFn } from 'app/types';

import * as actions from './actions';

import { getSelectedTenant } from '../../../../configs/user';
import { PAYPAY_CONSUMER } from '../../../../configs/apps/tenants/PAYPAY_CONSUMER';

export const hiddenSlotGroupTypes = ['override', 'ad'];

export const fetchSlotGroupTypes = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      slotGroupTypes: { loading, slotGroupTypes: prevSlotGroupTypes },
    },
  } = getState();

  if (loading || (prevSlotGroupTypes || []).length > 0) {
    return;
  }

  dispatch(actions.getSlotGroupTypesStart());

  try {
    const slotGroupTypes = await apiFetchSlotGroupTypes();
    return dispatch(
      actions.getSlotGroupTypesSuccess(
        slotGroupTypes.filter(
          slotGroupType => !(getSelectedTenant() === PAYPAY_CONSUMER ? hiddenSlotGroupTypes : []).includes(slotGroupType.value),
        ),
      ),
    );
  } catch (err) {
    const errorStr = err.error && err.error.msg;
    return dispatch(actions.getSlotGroupTypesFailed(errorStr));
  }
};
