import * as React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import { sc } from 'app/styles';

import { ImageUpload } from 'app/midgarComponents';
import { ConnectedPush2 } from 'app/ducks';
import { ApiImageUpload } from 'app/helpers/ImageUploadManager/ApiImageUpload';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { ValidationIcon, Label } from '../utilComponents';
import Generic from './Generic';
import PushExpiration from '../Expiration';

type Props = {
  apiFileUpload: (...args: Array<any>) => any;
  showTemplateToggleV2: boolean;
  isDynamicTemplatesDeeplinkEnable: boolean;
};

class Inbox extends React.Component<Props> {
  onUpload = async (fileUri: string, file: File, updatePush2Content: (...args: Array<any>) => any) => {
    if (file) {
      const { apiFileUpload } = this.props;
      const { status, url } = await apiFileUpload(file);
      if (status === 'SUCCESSFUL') {
        displaySuccess('Image upload Successful');
        updatePush2Content({ img_source: url });
      } else {
        displayError(`Image upload failed with the API status ${status}`);
      }
    } else {
      displayError('The file was rejected. It may have the wrong extension.');
    }
  };

  render() {
    const { showTemplateToggleV2, isDynamicTemplatesDeeplinkEnable } = this.props;

    return (
      <ConnectedPush2>
        {({
          updatePush2Content,
          payload: {
            notification: { content },
          },

          general: { expiryDate, expiryTime },
        }) => {
          const minDate = `${expiryDate} ${expiryTime}`;

          return (
            <>
              <Box>
                <ImageUpload
                  placeholder="Upload Notification Image"
                  imageUrl={content.img_source}
                  onUpload={(uri, file) => this.onUpload(uri, file, updatePush2Content)}
                  onRemove={() => {
                    updatePush2Content({ img_source: null });
                  }}
                />

                <InputContainer>
                  <Generic
                    showTemplateToggleV2={showTemplateToggleV2}
                    isDynamicTemplatesDeeplinkEnable={isDynamicTemplatesDeeplinkEnable}
                  />
                </InputContainer>
              </Box>
              <Section>
                <Label>
                  Push Notification Expiration
                  {content.expiry && content.expiry_type ? (
                    <ValidationIcon name="check-circle" size={22} color={sc.success} />
                  ) : (
                    <ValidationIcon name="warning" size={22} color={sc.warning} />
                  )}
                </Label>
              </Section>
              <PushExpiration
                expiryType={content.expiry_type}
                expiry={content.expiry}
                min={minDate}
                minDateWarning="Warning: Campaign will end at this expiry date, which is earlier than the campaign end date set in Schedule"
                onChangeType={val => updatePush2Content({ expiry_type: val, expiry: null })}
                onChangeDate={val => updatePush2Content({ expiry: val })}
                onChangeDateTime={val => updatePush2Content({ expiry: val })}
                onChangeRelative={ev => updatePush2Content({ expiry: parseFloat(ev.target.value) })}
              />
            </>
          );
        }}
      </ConnectedPush2>
    );
  }
}

const Section = styled.section`
  border-top: 1px solid ${sc.greyLight};
  padding: 2rem 1rem;
  margin: 2rem -1rem 0 -1rem;
`;

const Box = styled.div`
  display: flex;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;
// TODO: RECOMPOSE MUST DIE
export default compose(ApiImageUpload)(Inbox);
