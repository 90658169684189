import { green as greenBase } from '@material-ui/core/colors';

const green = greenBase[400];

export const styles = () => ({
  locationContainer: {
    width: '100%',
    minHeight: '16rem',
  },

  inputSection: {
    marginTop: '2rem',
  },

  rightMargin: {
    marginRight: '6px',
  },

  leftMargin: {
    marginLeft: '1rem',
  },

  checkmark: {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    margin: '0 5px',
    padding: '5px',
    background: green,
  },

  notification: {
    margin: '1rem -1rem',
    width: 'fit-content',
  },

  download: {
    float: 'right',
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    top: '-1rem',
  },

  inverted: {
    transform: 'rotate(180deg)',
    top: '4px',
    position: 'relative',
    height: '18px',
  },
});
