const ROOT = '/ec/slot';

const forSlotId = (slotId: number) => `${ROOT}/${String(slotId)}`;

export const MOVE_AFTER_BANNER_ID = (slotId: number, bannerId: number) => `${forSlotId(slotId)}/move/afterbanner/${bannerId}`;

export const MOVE_VIEW_ITEMS_TO_SLOT = (slotId: number) => `${forSlotId(slotId)}/move/viewitem`;

export const VIEW_ITEMS_FOR_SLOT_ID = (slotId: number) => `${forSlotId(slotId)}/viewitem`;

// POST  /ec/slot'
// DELETE  /ec/slot/${slotId}
// PUT   /ec/slot/${slotId}/status
// PUT   /ec/slot/${slotId}/override
// GET   /ec/slot/${slotId}/viewitem', { query });
// PUT   /ec/slot/${binder.selectedSlot.id}/viewitem
