import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouterHistory } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { isEmpty } from 'ramda';
import qs from 'query-string';
import styled from 'styled-components';

import { IFetchViewResult } from 'app/api/bannerManagementV2/views/types';
import { inject, withAppConfig, withDND, withPermissions } from 'app/decorators';
import { binderActions, binderOperations, storefrontsMgmtOperations } from 'app/ducks';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { ConfirmModal, Icon as _Icon, PageHeader as _PageHeader, Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ISlot, ISlotGroupType } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';
import { __DEV__ } from 'configs/settings';

import { HeaderTitle, HeaderTitleLink, Page, PageMain } from '../../common/components';
import { isReadOnlyStorefront } from '../../common/helpers';
import withSlotGroupTypes from '../../hoc/withSlotGroupTypes';
import withWidgetTypes from '../../hoc/withWidgetTypes';
import { parsePathView, urlStorefrontEdit } from '../../routeUtils';
import BindModal from './BindModal';
import RemoveBannersModal from './RemoveBannersModal';
import SlotContents from './SlotContents';
import Slots from './Slots';
import View from './View';
import { metaError } from './validate';
import SettingsModal from './Slots/SettingsModal';

type Props = {
  appConfig: IAppConfig;
  binderActions: typeof binderActions;
  binderOperations: typeof binderOperations;
  entityId?: number;
  entityType?: string;
  history: RouterHistory;
  isPermitted: IsPermittedFn;
  isReadOnly: (...args: Array<any>) => any;
  loading: boolean;
  selectedSlot: ISlot;
  selectedSlotGroupType: ISlotGroupType;
  slotGroupTypes: Array<ISlotGroupType>;
  storefrontId: number;
  storefrontsMgmtOperations: typeof storefrontsMgmtOperations;
  timezone: string;
  view: IFetchViewResult;
  viewId: number | null | undefined;
};

type State = {
  isBindModalOpen: boolean;
  isBulkDeleteModalOpen: boolean;
  isConfirmDeleteModalOpen: boolean;
  viewItemId: number | null | undefined;
  isSettingsModalOpen: boolean;
};

class Overview extends PureComponent<Props, State> {
  isNew: boolean;

  constructor(props: Props) {
    super(props);
    const { storefrontId } = this.props;
    this.isNew = storefrontId === null || storefrontId === undefined;
  }

  state = {
    isBindModalOpen: false,
    isBulkDeleteModalOpen: false,
    isConfirmDeleteModalOpen: false,
    viewItemId: null,
    isSettingsModalOpen: false,
  };

  componentDidMount() {
    const {
      appConfig,
      binderActions: { resetView },
      binderOperations: { getViewById },
      history,
      storefrontId,
      storefrontsMgmtOperations: { getAclSFUsers },
      viewId,
    } = this.props;

    const { useAclService, useSellerPanel } = getBannerCreativeConfig(appConfig);

    const query = qs.parse(history.location.search);
    const slotId = query.slot ? Number(query.slot) : null;
    // const selectedViewId = result('view.id', view);  https://jira.mypaytm.com/browse/PGMB-158

    if (viewId === null || viewId === undefined) {
      resetView();
      // } else if (viewId !== Number(selectedViewId)) { https://jira.mypaytm.com/browse/PGMB-158
    } else {
      // TODO: Does this always go to the storefronts page if the viewId is changed? What if the new viewId is engage-enabled?
      getViewById(viewId, {
        slotId,
        withSP: useSellerPanel,
      });
    }

    if (useAclService && storefrontId && !__DEV__) {
      getAclSFUsers(storefrontId);
    }
  }

  componentDidUpdate() {
    const {
      binderActions: { setSelectedSlotGroupType },
      history,
      selectedSlotGroupType,
      slotGroupTypes,
    } = this.props;

    if (slotGroupTypes && slotGroupTypes.length && !selectedSlotGroupType) {
      const params = qs.parse(history.location.search);
      const newSlotGroupType = params.selectedSlotGroupType
        ? slotGroupTypes.find(slotGroupType => slotGroupType.value === params.selectedSlotGroupType) || {}
        : slotGroupTypes[0];

      setSelectedSlotGroupType(newSlotGroupType);
    }
  }

  linkToStorefront = () => {
    const { entityId, entityType, storefrontId } = this.props;
    return urlStorefrontEdit(storefrontId, entityId, entityType);
  };

  onBind = (id: number) => {
    const {
      binderActions: { setSelectedViewItemId },
    } = this.props;

    setSelectedViewItemId(id);
    this.setState({ isBindModalOpen: true });
  };

  onDelete = (id: number) => {
    this.setState({
      viewItemId: id,
      isConfirmDeleteModalOpen: true,
    });
  };

  onOpenBulkDeleteModal = () => this.setState({ isBulkDeleteModalOpen: true });

  onSave = async () => {
    const {
      appConfig,
      binderOperations: { saveView },
      history,
      storefrontId,
      view: { view },
    } = this.props;

    const { widgetConfig } = view;
    const { validateMeta } = getBannerCreativeConfig(appConfig);
    if (validateMeta && metaError(widgetConfig)) {
      displayError('Metadata value cannot be empty');
    } else {
      await saveView(storefrontId);
      const { error } = this.props;
      if (error) {
        displayError('Error Saving View');
        console.error('Error Saving View', error); // eslint-disable-line no-console
      } else {
        history.push(this.linkToStorefront());
      }
    }
  };

  deleteBulkViewItems = (bannerIds: Array<number>) => {
    const {
      binderOperations: { deleteViewItemsAcrossSlots },
    } = this.props;
    deleteViewItemsAcrossSlots(bannerIds);
  };

  deleteViewItems = () => {
    const { viewItemId } = this.state;
    const {
      binderOperations: { deleteViewItems },
    } = this.props;
    deleteViewItems([viewItemId]);

    this.setState({
      viewItemId: null,
      isConfirmDeleteModalOpen: false,
    });
  };

  closeBindModal = () => this.setState({ isBindModalOpen: false });

  closeBulkDeleteModal = () => this.setState({ isBulkDeleteModalOpen: false });

  closeConfirmDeleteModal = () => this.setState({ isConfirmDeleteModalOpen: false });

  render() {
    const { isBindModalOpen, isBulkDeleteModalOpen, isConfirmDeleteModalOpen, isSettingsModalOpen } = this.state;

    const {
      binderActions: { reorderViewItems },
      binderOperations: { saveOrder },
      entityId,
      entityType,
      history,
      isReadOnly,
      loading,
      selectedSlot,
      selectedSlotGroupType,
      slotGroupTypes,
      storefrontId,
      timezone,
      view: { view },
      viewId,
    } = this.props;

    const { id: slotId } = selectedSlot || {};

    return (
      <Page>
        <PageHeader title="" backLink={this.linkToStorefront()}>
          <TitleContainer>
            <HeaderTitleLink to={this.linkToStorefront()}>Storefront {storefrontId}</HeaderTitleLink>
            <Icon name="chevron-right" size={35} />
            <HeaderTitle>{viewId === null ? 'New View' : `View ${String(viewId)}`}</HeaderTitle>
          </TitleContainer>
          <Row>
            <ActionsIcon
              name="settings"
              onClick={() => this.setState({ isSettingsModalOpen: true })}
              size={22}
              disabled={isReadOnly(storefrontId)}
            />

            <Label>Settings</Label>
          </Row>
        </PageHeader>

        <Content>
          <View isNew={!viewId} onSave={this.onSave} readOnly={isReadOnly(storefrontId)} />
        </Content>

        {viewId !== null && viewId !== undefined && (
          <>
            {view && !isEmpty(view) && (
              <SlotContent>
                <Slots
                  entityId={entityId}
                  entityType={entityType}
                  history={history}
                  readOnly={isReadOnly(storefrontId)}
                  storefrontId={storefrontId}
                  viewId={viewId}
                />
              </SlotContent>
            )}

            {loading ? (
              <Spinner />
            ) : !slotId ? (
              <NoSelectedSlot>No Selected Slot</NoSelectedSlot>
            ) : slotGroupTypes && slotGroupTypes.length && selectedSlotGroupType ? (
              <SlotContents
                key={slotId}
                entityId={entityId}
                entityType={entityType}
                history={history}
                onBind={this.onBind}
                onBulkDelete={this.onOpenBulkDeleteModal}
                onDelete={this.onDelete}
                readOnly={isReadOnly(storefrontId)}
                reorderViewItems={reorderViewItems}
                saveOrder={saveOrder}
                selectedSlotGroupType={selectedSlotGroupType}
                slotId={slotId}
                storefrontId={storefrontId}
                timezone={timezone}
                viewId={viewId}
              />
            ) : null}
          </>
        )}

        <BindModal
          closeModal={this.closeBindModal}
          isOpen={isBindModalOpen}
          selectedSlot={selectedSlot}
          selectedSlotGroupType={selectedSlotGroupType}
        />

        <ConfirmModal
          confirmText="Remove"
          isOpen={isConfirmDeleteModalOpen}
          onClose={this.closeConfirmDeleteModal}
          onConfirm={this.deleteViewItems}
          title="Confirm"
        >
          Are you sure you wish to remove this viewitem?
        </ConfirmModal>

        <RemoveBannersModal isOpen={isBulkDeleteModalOpen} onClose={this.closeBulkDeleteModal} onConfirm={this.deleteBulkViewItems} />
        <SettingsModal isOpen={isSettingsModalOpen} closeModal={() => this.setState({ isSettingsModalOpen: false })} />
      </Page>
    );
  }
}

const mapStateToProps = ({ acls: { storefrontsMgmt }, bannerManagement: { binder }, user }) => ({
  error: binder.error,
  isReadOnly: isReadOnlyStorefront(user, storefrontsMgmt),
  loading: binder.loading,
  resetView: () => Function,
  selectedSlot: binder.selectedSlot,
  selectedSlotGroupType: binder.selectedSlotGroupType,
  timezone: user.timezone,
  view: binder.view,
});

export default compose(
  withAppConfig,
  withPermissions,
  withProps(parsePathView),
  withSlotGroupTypes,
  withWidgetTypes,
  connect(mapStateToProps),
  inject({
    binderActions: binderActions,
    binderOperations: binderOperations,
    storefrontsMgmtOperations,
  }),

  withDND,
)(Overview);

const Content = styled(PageMain)`
  padding: ${sc.gutterSmall} ${sc.gutterLarger};
`;
const SlotContent = styled(PageMain)`
  padding: ${sc.gutterSmall} ${sc.gutterLarger};
  background: ghostwhite;
`;

const Icon = styled(_Icon)`
  margin: 0 ${sc.gutterSmallest};
`;

const NoSelectedSlot = styled.section`
  margin-left: ${sc.gutter};
`;

const PageHeader = styled(_PageHeader)`
  justify-content: start;
  height: 6rem;
`;

const TitleContainer = styled.div`
  flex-grow: 1;
  display: flex;
`;
const ActionsIcon = styled(Icon)`
  display: inline-block;
  vertical-align: center;
  color: ${sc.subHeadingColor};
  margin-right: ${sc.gutterSmaller};
  padding-left: ${sc.gutterSmaller};

  &:hover {
    cursor: pointer;
    color: ${props => (props.disabled ? '' : sc.primary)};
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Row = styled.div`
  display: inline-flex;
  width: 50%;
  padding: ${sc.gutterSmallest};
  margin: 2px 0;
  justify-content: right;
`;

const Label = styled.div`
  padding: ${sc.gutterSmaller};
  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmallest};
  text-transform: uppercase;
`;
