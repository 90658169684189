import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { bulkViewItemActionClearError } from 'app/ducks/bannerManagement/binder/actions';
import { parseNumericCsv } from 'app/features/BannerManagement/utils';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { ConfirmModal, Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Action } from 'app/types';

import { Dropzone as _Dropzone } from '../../common/components';
import _ErrorContainer from './SlotContents/BulkActions/ErrorContainer';
import { IBulkActionError } from './SlotContents/BulkActions/types';

type Props = {
  bulkActionError?: IBulkActionError;
  bulkActionInProgress: boolean;
  clearError: () => Action;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (arg0: Array<number>) => void;
};

type State = {
  bannerIds: Array<number>;
  invalidValues: Array<string>;
};

class RemoveBannersModal extends React.PureComponent<Props, State> {
  state = {};

  componentDidUpdate(prevProps: Props) {
    const { bulkActionError, bulkActionInProgress, onClose } = this.props;
    const { bulkActionInProgress: prevInProgress } = prevProps;
    if (prevInProgress && !bulkActionInProgress && !bulkActionError) {
      onClose();
    }
  }

  onClose = () => {
    this.setState({ bannerIds: null });
    const { clearError, onClose } = this.props;
    clearError();
    onClose();
  };

  onConfirm = () => {
    const { onConfirm } = this.props;
    const { bannerIds } = this.state;
    onConfirm(bannerIds);
  };

  onUpload = (files: Array<File>) => {
    const file = files[0];
    const reader = new FileReader();

    reader.onload = ev => {
      const { ids: bannerIds, invalidValues } = parseNumericCsv(ev.target.result);
      // TODO: Collect any invalid (non-numeric) IDs
      this.setState({ bannerIds, invalidValues });
    };

    reader.onerror = ev => {
      console.error('Error while reading file', ev);
      displayError(`Error while reading file ${file.name}`);
    };

    // TODO: If uploaded files are large, use a stream
    reader.readAsText(file);
  };

  render() {
    const { bulkActionError, bulkActionInProgress, isOpen } = this.props;
    const { bannerIds, invalidValues } = this.state;
    const numBanners = (bannerIds || []).length;

    return (
      <ConfirmModal
        confirmText="Remove"
        disabled={!numBanners || !!bulkActionError || bulkActionInProgress}
        isOpen={isOpen}
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        title="Remove Banners"
      >
        <Dropzone multiple={false} onDrop={this.onUpload} accept=".csv,.txt">
          <Instruction>Upload .csv or .txt with one banner ID per line</Instruction>
        </Dropzone>

        <UploadResults>
          <div>{!!numBanners && `${numBanners} banners ${bulkActionInProgress ? 'are being' : 'will be'} removed`}</div>

          {!!(invalidValues || []).length && <div>{invalidValues.length} invalid IDs</div>}

          {bulkActionInProgress && <Spinner />}

          {bulkActionError && <ErrorContainer bulkActionError={bulkActionError} />}
        </UploadResults>
      </ConfirmModal>
    );
  }
}

const mapStateToProps = ({
  bannerManagement: {
    binder: { bulkActionError, bulkActionInProgress },
  },
}) => ({
  bulkActionError,
  bulkActionInProgress,
});

const mapDispatchToProps = {
  clearError: bulkViewItemActionClearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveBannersModal);

const Dropzone = styled(_Dropzone)`
  margin-top: ${sc.gutter};
`;

const ErrorContainer = styled(_ErrorContainer)`
  margin-top: ${sc.gutter};
`;

const Instruction = styled.div`
  color: ${sc.grey};
  font-size: smaller;
  font-style: italic;
  margin-top: ${sc.gutterSmallest};
`;

const UploadResults = styled.div`
  min-height: 3rem;
  padding: ${sc.gutter};
`;
