import * as types from './types';
import { fetch } from '../../commonStates';

const segmentsState = {
  ...fetch,
  allIds: [],
  byId: {},
  moreLoading: false, // TODO: Remove loadMore
};

const segments = (state = segmentsState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_SEGMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.GET_ALL_SEGMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ...payload.segmentsLookups,
        ...payload.paged,
      };

    case types.GET_ALL_SEGMENTS_FAIL:
      return {
        ...state,
        loading: false,
        allIds: [],
        byId: {},
        error: payload.error,
      };

    case types.GET_DOWNLOAD_STATUS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            state: 'LOADING',
          },
        },
      };

    case types.GET_DOWNLOAD_STATUS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            ...payload,
          },
        },
      };

    case types.GET_DOWNLOAD_STATUS_FAIL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            state: 'ERROR',
          },
        },
      };

    case types.LOAD_MORE_SEGMENTS:
      return {
        ...state,
        moreLoading: true,
      };

    case types.LOAD_MORE_SEGMENTS_SUCCESS:
      return {
        ...state,
        moreLoading: false,
        ...payload.segmentsLookups,
        ...payload.paged,
      };

    case types.LOAD_MORE_SEGMENTS_FAIL:
      return {
        ...state,
        moreLoading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};

export default segments;
