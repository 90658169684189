import * as types from './types';

const get = () => ({
  type: types.GET_TEMPLATE,
});

const getSuccess = template => ({
  type: types.GET_TEMPLATE_SUCCESS,
  payload: { template },
});

const getFail = (error, errorDetails) => ({
  type: types.GET_TEMPLATE_FAIL,
  payload: { error, errorDetails },
});

const post = () => ({
  type: types.POST_TEMPLATE,
});

const postSuccess = templateId => ({
  type: types.POST_TEMPLATE_SUCCESS,
  payload: { templateId },
});

const postFail = (error, errorDetails) => ({
  type: types.POST_TEMPLATE_FAIL,
  payload: { error, errorDetails },
});

const setError = (error, errorDetails) => ({
  type: types.SET_ERROR,
  payload: { error, errorDetails },
});

const clearError = () => ({
  type: types.CLEAR_ERROR,
});

const newTemplate = ({ id, medium } = {}) => ({
  type: types.NEW_TEMPLATE,
  payload: { id, medium },
});

const setName = name => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { name },
});

const setDescription = description => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { description },
});

const setContent = content => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { content },
});

const setMediumId = mediumId => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { mediumId },
});

const setTagIds = ids => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { tagIds: ids },
});

const setSubject = subject => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { subject },
});

const setSendFrom = sendFrom => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { sendFrom },
});

const setSendFromName = sendFromName => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { sendFromName },
});

const setReplyTo = replyTo => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { replyTo },
});

const setReplyToName = replyToName => ({
  type: types.SET_TEMPLATE_FIELD,
  payload: { replyToName },
});

const addTag = tag => ({
  type: types.ADD_TAG,
  payload: { tag },
});

const removeTag = tag => ({
  type: types.REMOVE_TAG,
  payload: { tag },
});

export {
  get,
  getSuccess,
  getFail,
  post,
  postSuccess,
  postFail,
  setError,
  clearError,
  newTemplate,
  setName,
  setDescription,
  setContent,
  setMediumId,
  setTagIds,
  setSubject,
  setSendFrom,
  setSendFromName,
  setReplyTo,
  setReplyToName,
  addTag,
  removeTag,
};
