import * as React from 'react';

import { BmsError } from 'app/api/bannerManagementV2/types';
import { DrawerError } from 'app/components';
import { toErrorString } from 'app/features/BannerManagement/utils';

type Props = {
  error: BmsError;
  title: string;
};

export default ({ error, title }: Props) => (
  <DrawerError error={title} errorDetails={toErrorString(error)} allowDismiss={false} defaultExpanded />
);
