import * as types from './types';
import { NEW_CAMPAIGN, SET_API_CREATIVE } from '../types';

const isValidJson = metadata => {
  try {
    JSON.parse(metadata);
    return true;
  } catch (e) {
    return false;
  }
};

// Business requirement dictates that either phone_no or email_id is required, but gender is optional.
const isValidFacebookValues = values => values && (values.includes('email_id') || values.includes('phone_no'));

const validateFields = ({ apiType, content: { values, metadata, action } }) => ({
  valid: Boolean(apiType.length && (isValidFacebookValues(values) || (action && action.length)) && isValidJson(metadata)),
  apiType,
  content: {
    values,
    action,
    metadata,
    isValidJson: isValidJson(metadata),
    isValidFacebookValues: isValidFacebookValues(values),
  },
});

const initialState = {
  valid: false,
  apiType: '',
  content: {
    values: [],
    action: '',
    metadata: '{}',
    isValidJson: true,
    isValidFacebookValues: false,
  },
};

const apiCreative = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.UPDATE_API_TYPE:
      if (state.apiType === payload.apiType.apiType) {
        return {
          ...state,
        };
      }
      return {
        ...initialState,
        apiType: payload.apiType.apiType,
      };

    case types.UPDATE_API_FIELD:
      return validateFields({
        ...state,
        content: {
          ...state.content,
          ...payload.field,
        },
      });

    case types.UPDATE_FACEBOOK_VALUES:
      return validateFields({
        ...state,
        content: {
          ...state.content,
          values: payload.values,
        },
      });

    case NEW_CAMPAIGN: {
      return initialState;
    }

    case SET_API_CREATIVE: {
      return {
        valid: true,
        ...payload.creative,
      };
    }

    default:
      return state;
  }
};

export default apiCreative;
