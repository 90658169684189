import { CAMPAIGN_TIMELINE_CREATE, CAMPAIGN_TIMELINE_DRAFT } from 'configs/permissions';
import { notificationMediumId } from 'configs/channels/mediumIds';
import { IChannelConfig } from 'configs/channels/types';
import { triggerTypes, oneTimeTrigger } from 'app/utilities/constants';
import {
  DISPLAY as display,
  ICON as icon,
  NAME as name,
  CREATIVE as creative,
  REQUIRED_FIELDS as requiredFields,
} from './timelineNotificationConfigConstants';

import TimelineNotificationCreation from './Creation/TimelineNotificationCreation';
import TimelineNotificationOverview from './Overview';
import FullSchedule from '../Campaigns/Schedule/FullSchedule';

export default {
  createPermission: [CAMPAIGN_TIMELINE_CREATE],
  creative,
  defaultTrigger: triggerTypes.oneTime,
  icon,
  name,
  display,
  enableGoals: true,
  mediumId: notificationMediumId,
  useEveryoneAudience: false,
  permission: [CAMPAIGN_TIMELINE_CREATE, CAMPAIGN_TIMELINE_DRAFT],
  requiredFields,
  triggers: [oneTimeTrigger],
  CreationComponent: TimelineNotificationCreation,
  OverviewComponent: TimelineNotificationOverview,
  ScheduleComponent: FullSchedule,
} as IChannelConfig;
