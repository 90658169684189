import * as React from 'react';
import { connect } from 'react-redux';

import { getJourneyCreative } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { reverseTypes } from 'app/features/Cashback/constants';
import { IJourneyCreative } from 'app/features/Cashback/types';

import { Header, MainPage } from 'app/features/Campaigns/Overview/common/ChannelDetailsComponents';
import { diffObjInArrayWith } from 'app/features/Campaigns/utils';

import {
  SectionContainer,
  SectionHeader,
  SubsectionContainer,
  SubsectionHeader,
  SublineHeader,
} from '../../Overview/OverviewCommonComponents';

import Entry from '../../Overview/Entry';
import Calculation from '../../Overview/Calculation';
import EventCriteria from '../../Overview/EventCriteria';
import UserCriteria from '../../Overview/UserCriteria';
import Fulfillment from '../../Overview/Fulfillment';

type Props = {
  journeyCreative: IJourneyCreative;
  originalCreative?: Record<string, any>;
};

export const JourneyOverview = ({ journeyCreative, originalCreative }: Props) => {
  const {
    additionalAttributes,
    cashbackPromoBanner,
    customCampaignAttributes,
    stackableGroup,
    stages,
    creativeUserCriteria,
    fulfillment,
    reversalStrategy,
  } = journeyCreative;
  const { userCriteria, userCriteriaJson } = creativeUserCriteria;

  return (
    <MainPage>
      <Header>Journey</Header>

      <SectionContainer>
        <SectionHeader>Campaign Attributes</SectionHeader>

        <SubsectionContainer>
          {(customCampaignAttributes || []).map(({ defaultValue, fieldTitle, id, optional, value }) => (
            <Entry
              key={id}
              title={`${fieldTitle}${optional ? '[optional]' : ''}`}
              value={value || defaultValue || ''}
              highlight={
                originalCreative &&
                diffObjInArrayWith(originalCreative.customCampaignAttributes, {
                  findBy: { key: 'id', value: id },
                  additional: { key: 'value', value },
                })
              }
            />
          ))}
        </SubsectionContainer>

        <SubsectionContainer>
          <SubsectionHeader>Additional Attributes</SubsectionHeader>

          {additionalAttributes && additionalAttributes.length
            ? additionalAttributes.map(({ key, value }) => (
                <Entry
                  key={`${key}-${value}`}
                  title={key}
                  value={value}
                  highlight={
                    originalCreative &&
                    diffObjInArrayWith(originalCreative.additionalAttributes, {
                      findBy: { key: 'key', value: key },
                      additional: { key: 'value', value },
                    })
                  }
                />
              ))
            : 'None'}
        </SubsectionContainer>

        <SubsectionContainer>
          <SubsectionHeader>Configuration</SubsectionHeader>
          <Entry
            title="Stackable Group"
            value={stackableGroup ? stackableGroup.name : 'N/A'}
            highlight={
              originalCreative &&
              (journeyCreative?.stackableGroup || originalCreative?.stackableGroup) &&
              journeyCreative?.stackableGroup?.name !== originalCreative?.stackableGroup?.name
            }
          />

          <Entry
            title="Banner"
            value={cashbackPromoBanner ? cashbackPromoBanner.title : 'N/A'}
            highlight={
              originalCreative &&
              (journeyCreative?.cashbackPromoBanner || originalCreative?.cashbackPromoBanner) &&
              journeyCreative?.cashbackPromoBanner?.title !== originalCreative?.cashbackPromoBanner?.title
            }
          />
        </SubsectionContainer>

        <SubsectionContainer>
          <SubsectionHeader>Fulfillment</SubsectionHeader>
          <Fulfillment fulfillment={fulfillment} originalFulfillment={originalCreative?.fulfillment} />
        </SubsectionContainer>

        <SubsectionContainer>
          <SubsectionHeader>Reversal Strategy</SubsectionHeader>
          <Entry
            title="Reversal Strategy"
            value={reverseTypes[reversalStrategy].label}
            highlight={
              originalCreative &&
              (reversalStrategy || originalCreative?.reversalStrategy) &&
              reversalStrategy !== originalCreative?.reversalStrategy
            }
          />
        </SubsectionContainer>

        <SubsectionContainer>
          <SubsectionHeader>User Criteria</SubsectionHeader>
          <UserCriteria
            userCriteria={userCriteria}
            userCriteriaJson={userCriteriaJson}
            originalCriteria={originalCreative?.creativeUserCriteria}
          />
        </SubsectionContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionHeader>Stages</SectionHeader>
        {Object.values(stages)
          .sort((a: Record<string, any>, b: Record<string, any>) => a.order - b.order)
          .map((stage: Record<string, any>) => (
            <SubsectionContainer key={stage.id} highlight={originalCreative && !originalCreative?.stages?.[stage.id]}>
              <SubsectionHeader>Stage {stage.order + 1}</SubsectionHeader>

              <SublineHeader>Payout</SublineHeader>
              <Calculation calculation={stage.calculation} originalCalculation={originalCreative?.stages?.[stage.id]?.calculation} />

              <SublineHeader>Criteria</SublineHeader>
              <EventCriteria
                eventType={stage.criteria.eventType}
                eventCriteria={stage.criteria.eventCriteria}
                eventCriteriaJson={stage.criteria.eventCriteriaJson}
                originalCriteria={originalCreative?.stages?.[stage.id]?.criteria}
              />

              {(stage.customStageAttributes || []).length > 0 ? (
                <>
                  <SublineHeader>Stage Attributes</SublineHeader>
                  {(stage.customStageAttributes || []).map(({ defaultValue, fieldTitle, id, optional, value }) => (
                    <Entry
                      key={id}
                      title={`${fieldTitle}${optional ? '[optional]' : ''}`}
                      value={value || defaultValue || ''}
                      highlight={
                        originalCreative &&
                        diffObjInArrayWith(originalCreative.stages?.[stage.id]?.customStageAttributes, {
                          findBy: { key: 'id', value: id },
                          additional: { key: 'value', value },
                        })
                      }
                    />
                  ))}
                </>
              ) : null}
            </SubsectionContainer>
          ))}
      </SectionContainer>
    </MainPage>
  );
};

export const JourneyOverviewContainer = connect(state => ({
  journeyCreative: getJourneyCreative(state),
  originalCreative: state.campaigns.campaign.general.originalCampaign?.creative?.journey,
}))(JourneyOverview);
