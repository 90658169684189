import { compose } from 'recompose';

import withEngageCategories from './withEngageCategories';
import withGaTypes from './withGaTypes';
import withLandingPageTypes from './withLandingPageTypes';
import withLocations from './withLocations';
import withSlotGroupTypes from './withSlotGroupTypes';
import withVariantTypes from './withVariantTypes';
import withWidgetTypes from './withWidgetTypes';

export default compose(
  withEngageCategories,
  withGaTypes,
  withLandingPageTypes,
  withLocations,
  withSlotGroupTypes,
  withVariantTypes,
  withWidgetTypes,
);
