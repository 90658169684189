import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import _Dropzone from 'app/midgarComponents/Dropzone';
import Icon from 'app/midgarComponents/Icon';

import { UploadResultsTable } from './UploadResultsTable';

import { IUploadResult } from '../types';

export default class Personalization extends React.PureComponent<{
  fileUploads?: Array<IUploadResult>;
  loading?: boolean;
  handleUpload: (...args: Array<any>) => any;
}> {
  render() {
    const { fileUploads, loading, handleUpload } = this.props;
    return (
      <Container data-qa="personalization">
        {fileUploads && !!fileUploads.length && <UploadResultsTable uploadResults={fileUploads} />}
        <Dropzone onDrop={handleUpload} loading={loading} accept="image/*" multiple>
          <IconContainer>
            <Icon name="file-upload" color={sc.primary} size={20} />
            <IconText>Upload Images</IconText>
          </IconContainer>
          <Text>Click or Drag and Drop to upload images</Text>
        </Dropzone>
      </Container>
    );
  }
}

const Container = styled.section`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const Dropzone = styled(_Dropzone)`
  padding: ${sc.gutterLarge} ${sc.gutterSmall};
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.p`
  color: ${sc.primary};
`;

const IconText = styled.span`
  margin-left: ${sc.gutterSmaller};
  color: ${sc.primary};
  font-size: ${sc.fontSizeSmall};
  font-weight: 600;
  line-height: 1.43;
`;

const Text = styled.p`
  padding: 0.25rem 0;
  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmaller};
  line-height: 1.33;
`;
