import React from 'react';
import styled from 'styled-components';
import { Modal } from '@material-ui/core';

import { fetchUserProfiles } from 'app/api/users';
import { sc } from 'app/styles';
import { Button, Pills, Spinner } from 'app/midgarComponents';
import { IUser } from 'app/types/User';
import { ISuggestion } from 'app/types';

type Props = {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  setPermissions: (permissions: string) => void;
};

type State = {
  profiles: Array<IUser>;
  profilesLoading: boolean;
  profilesSelected: Array<string>; // emails of profiles being selected
};

export class ProfilesModal extends React.Component<Props, State> {
  state = {
    profiles: [],
    profilesLoading: false,
    profilesSelected: [],
  };

  componentDidMount = async () => {
    this.setState({ profilesLoading: true });
    const { content: profiles } = await fetchUserProfiles();
    this.setState({ profiles, profilesLoading: false });
  };

  clearSelections = () => {
    this.setState({
      profilesSelected: [],
    });
  };

  getSelectedProfiles = (profilesSelected: Array<string>) => {
    const { profiles } = this.state;

    const selectedProfiles = profilesSelected.map(email => {
      const selected = profiles.find(profile => profile.email === email);
      if (selected) {
        return selected;
      }
      console.error(`selected profile ${email} is not in the saved profiles`);
      return null;
    });

    return selectedProfiles;
  };

  onMultiSelectProfile = ({ id }: ISuggestion) => {
    const email = id; // set id to email
    const { profilesSelected } = this.state;
    const newProfilesSelected = [...profilesSelected, email];
    this.setState({
      profilesSelected: newProfilesSelected,
    });
  };

  onRemoveProfile = ({ id }: ISuggestion) => {
    const email = id; // set id to email
    const { profilesSelected } = this.state;
    const newProfilesSelected = profilesSelected.filter(existedEmail => existedEmail !== email);
    this.setState({
      profilesSelected: newProfilesSelected,
    });
  };

  getPermissionsFromProfiles = (profiles: Array<IUser>) => Array.from(new Set(profiles.map(p => p.permissions).flat()));

  closeModal = () => {
    const { setOpenModal } = this.props;
    this.clearSelections();
    setOpenModal(false);
  };

  onConfirm = e => {
    const { setPermissions, permissionsArr } = this.props;
    const { profilesSelected } = this.state;
    const selectedProfiles = this.getSelectedProfiles(profilesSelected);
    const permissionsArray = this.getPermissionsFromProfiles(selectedProfiles);
    const permissions = e.target.innerHTML === 'Add' ? [...new Set([...permissionsArr, ...permissionsArray])] : permissionsArray;
    setPermissions(permissions);
    this.closeModal();
  };

  render() {
    const { openModal, permissionsArr } = this.props;

    const hasPermissions = permissionsArr.length > 0;

    const { profilesLoading, profiles, profilesSelected } = this.state;

    const suggestions = profiles.map(profile => ({ id: profile.email, name: profile.email }));

    const formatProfilesSelected = this.getSelectedProfiles(profilesSelected).map(profile => ({
      id: profile.email,
      name: profile.email,
    }));

    return (
      <Modal open={openModal} onClose={this.closeModal}>
        <ModalContainer>
          <Heading>Prepopulate Permissions with Profiles</Heading>

          <Row>
            <Label>Profiles</Label>
            {profilesLoading ? (
              <Spinner />
            ) : (
              <ProfileContainer>
                <Pills
                  required
                  id="SelectProfiles"
                  name="Select Profiles"
                  label="Select Profiles"
                  suggestions={suggestions}
                  selected={formatProfilesSelected}
                  loading={false}
                  value="" // disable input
                  onChange={() => {
                    /* no-op */
                  }} // disable input
                  onSelect={this.onMultiSelectProfile}
                  onRemove={this.onRemoveProfile}
                />
              </ProfileContainer>
            )}
          </Row>

          <ActionsContainer>
            <Button type="secondary" onClick={this.closeModal}>
              Cancel
            </Button>
            {hasPermissions ? (
              <>
                <Button type="primary" onClick={this.onConfirm}>
                  Override
                </Button>
                <Button type="primary" onClick={this.onConfirm}>
                  Add
                </Button>
              </>
            ) : (
              <Button type="primary" onClick={this.onConfirm}>
                Confirm
              </Button>
            )}
          </ActionsContainer>
        </ModalContainer>
      </Modal>
    );
  }
}

const ModalContainer = styled.div`
  width: 830px;
  background-color: #fff;
  padding: 30px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 400;
  color: ${sc.primary};
  margin-bottom: 20px;
`;

const Row = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;

  & input {
    width: 75%;
    max-width: 500px;
  }
`;

const Label = styled.label`
  margin: 1rem;
  width: 150px;
  text-align: left;
  color: ${sc.headingColor};
`;

const ProfileContainer = styled.div`
  width: 75%;
  max-width: 500px;
`;

const ActionsContainer = styled.div`
  width: 88.5%;
  text-align: right;
  margin-top: 10px;

  & > button {
    margin-left: 10px;
  }
`;
