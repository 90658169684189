import React from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    display: 'inline-block',
    backgroundColor: ({ type }) =>
      type === 'warning' ? theme.palette.error.light : type === 'error' ? theme.palette.error.dark : theme.palette.primary.dark,
    padding: '0.5rem 0.75rem',
    fontSize: '0.75rem',
  },

  popper: {
    opacity: '0.9',
  },
}));

const Tooltip = props => {
  const { children, ...rest } = props;
  const classes = useStyles(props);

  return (
    <MuiTooltip placement="top" {...rest} classes={classes}>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
