const GET_SEGMENT = 'map/segments/GET';
const GET_SEGMENT_SUCCESS = 'map/segments/GET_SUCCESS';
const GET_SEGMENT_FAIL = 'map/segments/GET_FAIL';
const POST_SEGMENT = 'map/segments/POST';
const POST_SEGMENT_SUCCESS = 'map/segments/POST_SUCCESS';
const POST_SEGMENT_FAIL = 'map/segments/POST_FAIL';
const NEW_SEGMENT = 'map/segments/SET_NEW_SEGMENT';
const SET_SEGMENT_FIELD = 'map/segments/SET_SEGMENT_FIELD';
const ADD_TAG = 'map/segments/ADD_TAG';
const REMOVE_TAG = 'map/segments/REMOVE_TAG';
const SET_SEGMENT_INCLUDED_RULES = 'map/segments/SET_INCLUDED_RULES';
const ADD_CSV = 'map/segments/ADD_CSV';
const ADD_CSV_SUCCESS = 'map/segments/ADD_CSV_SUCCESS';
const ADD_CSV_FAIL = 'map/segments/ADD_CSV_FAIL';
const CALC_COUNT_LOADING = 'map/segments/CALC_COUNT_LOADING';
const CALC_COUNT_SUCCESS = 'map/segments/CALC_COUNT_SUCCESS';
const CALC_COUNT_FAILURE = 'map/segments/CALC_COUNT_FAILURE';
const RESET_SEGMENT = 'map/segments/RESET_SEGMENT';
const REMOVE_SEGMENT = 'map/segments/REMOVE_SEGMENT';

export {
  GET_SEGMENT,
  GET_SEGMENT_SUCCESS,
  GET_SEGMENT_FAIL,
  POST_SEGMENT,
  POST_SEGMENT_SUCCESS,
  POST_SEGMENT_FAIL,
  NEW_SEGMENT,
  SET_SEGMENT_FIELD,
  ADD_TAG,
  REMOVE_TAG,
  SET_SEGMENT_INCLUDED_RULES,
  ADD_CSV,
  ADD_CSV_SUCCESS,
  ADD_CSV_FAIL,
  CALC_COUNT_LOADING,
  CALC_COUNT_SUCCESS,
  CALC_COUNT_FAILURE,
  RESET_SEGMENT,
  REMOVE_SEGMENT,
};
