import { NEW_CAMPAIGN, SET_TIMELINE_NOTIFICATION_CREATIVE } from 'app/ducks/campaigns/campaign/types';
import * as types from './types';
import validate from './validation';

export const initialState = {
  templated: false,
  merchantRole: null,
  site_id: '',
  deeplinkType: '',
  sites: [],
  platforms: [], // android, iOS
  categoryList: {},
  payload: {
    title: '',
    messageLink: '',
    templateId: '',
    backgroundImage: '',
    isIncludeAdLogo: false,
    threadId: '',
    threadType: '',
    sender: {
      name: '',
      link: '',
      icon: '',
    },
  },

  valid: false,
  isValidCarousel: true,
  sendCampaignTo: null,
};

const addOrRemove = (state, arr) => value => ({
  ...state,
  [arr]: state[arr].includes(value) ? state[arr].filter(d => d !== value) : [...state[arr], value],
});

const TimelineNotificationCreative = (state = initialState, { type, payload }) => {
  switch (type) {
    case NEW_CAMPAIGN:
      return initialState;

    case SET_TIMELINE_NOTIFICATION_CREATIVE:
      return {
        ...state,
        payload: {
          ...payload.creative,
        },
      };

    case types.UPDATE_PLATFORM:
      return addOrRemove(state, 'platforms')(payload);

    case types.UPDATE_TIMELINE_NOTIFICATION_CONTENT:
      return {
        ...state,
        payload: {
          ...state.payload,
          sender: {
            ...state.payload.sender,
            ...payload,
          },
        },
      };

    case types.UPDATE_PAYLOAD_CONTENT:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...payload,
        },
      };

    case types.UPDATE_CATEGORY_LIST:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default (state = initialState, { type, payload }) => validate(TimelineNotificationCreative(state, { type, payload }));
