import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { branch, compose } from 'recompose';

import { IAppConfig } from 'configs/apps/types';

const mapStateToProps = ({ config }) => ({ appConfig: config });

export default compose(branch(({ appConfig }) => appConfig === null || appConfig === undefined, connect(mapStateToProps))) as (
  arg0: ComponentType<any>,
) => ComponentType<{ appConfig: IAppConfig }>;
