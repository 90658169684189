import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';

export default class Tags extends React.PureComponent<{
  tags: Array<{ id: number; name: string }>;
}> {
  render() {
    const { tags } = this.props;
    return (
      <>
        {tags.map(tag => (
          <Tag key={tag.id}>{tag.name}</Tag>
        ))}
      </>
    );
  }
}

const Tag = styled.p`
  font-size: ${sc.fontSize};
  line-height: 1.25;
  color: ${sc.bodyColor};
`;
