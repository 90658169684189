import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import ListSelectable from '../ListSelectable';

export const ListSelectableGridColumn = ({
  selectedItemId = null,
  listId = null,
  listName = null,
  items = [],
  colWidth = 3,
  handleClick = null,
  scrollIntoViewIfNeeded = false,
}) => (
  <Grid item key={listId} xs={colWidth}>
    <Typography variant="subtitle1" align="center" gutterBottom>
      {listName}
    </Typography>
    <ListSelectable selectedId={selectedItemId} items={items} handleClick={handleClick} scrollIntoViewIfNeeded={scrollIntoViewIfNeeded} />
  </Grid>
);

ListSelectableGridColumn.propTypes = {
  selectedItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  listId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  listName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  colWidth: PropTypes.oneOf([1, 2, 3, 4, 6]),
  handleClick: PropTypes.func,
  scrollIntoViewIfNeeded: PropTypes.bool,
};
