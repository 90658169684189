import { combineReducers } from 'redux';

import general from './reducers';

import * as campaignTypes from './types';
import * as campaignOperations from './operations';

import bannerCreative from './bannerCreative';
import emailCreative, { ConnectedEmail } from './emailCreative';
import push2Creative, { ConnectedPush2 } from './push2Creative';
import TimelineNotificationCreative, { ConnectedTimelineNotification } from './TimelineNotificationCreative';
import apiCreative, { ConnectedApi } from './apiCreative';
import promoCreative from './promoCreative';
import defaultCreative from './defaultCreative';
import cashbackCreative from './cashbackCreative';

export { campaignOperations, campaignTypes, ConnectedEmail, ConnectedPush2, ConnectedTimelineNotification, ConnectedApi };

export default combineReducers({
  general,
  bannerCreative,
  emailCreative,
  push2Creative,
  TimelineNotificationCreative,
  apiCreative,
  promoCreative,
  defaultCreative,
  cashbackCreative,
});
