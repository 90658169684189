import * as types from './types';
import { IRuleCriterion } from 'app/features/Cashback/types';

export const setError = (errorMsg: string) => ({
  type: types.SET_ERROR,
  payload: { error: errorMsg },
});

export const updateUserCriteria = (payload: { userCriteria: string }) => ({
  type: types.UPDATE_BANNER_USER_CRITERIA,
  payload,
});

export const updateUserCriteriaJson = (payload: { userCriteriaJson: Array<IRuleCriterion> }) => ({
  type: types.UPDATE_BANNER_USER_CRITERIA_JSON,
  payload,
});
