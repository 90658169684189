export const BIND_CREATIVE_TO_VIEWS = (creativeId: number) => `/ec/creative/${creativeId}/bind`;

export const GET_CREATIVE = (creativeId: number) => `/ec/creative/${creativeId}`;

export const GET_CREATIVES = '/ec/creatives';

export const GET_CREATIVE_VIEW_ITEMS = (creativeId: number) => `/ec/creative/${creativeId}/viewitems`;

export const GET_CREATIVE_VIEWS = (creativeId: number) => `/ec/creative/${creativeId}/views`;

export const POST_CREATIVE = '/ec/creative';

export const PUT_CREATIVE = (creativeId: number) => `/ec/creative/${creativeId}`;

// PUT   /ec/creative/${id}/variant', { body: variantBody })
