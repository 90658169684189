import * as React from 'react';
import { HTMLWithPreview } from 'app/components';
import { MissingImages, Title } from './styledComponents';

type Props = {
  content?: string;
  missingImages?: Array<string>;
  setContent: (arg0: string) => void;
  transformContent: (...args: Array<any>) => any;
  fileUploads?: Array<{
    file: File;
    url?: string;
    loading?: boolean;
    error?: string;
  }>;

  apiFileUpload: () => void;
  setFileUploads: () => void;
  handleAcceptedFiles: () => void;
  handleRejectedFiles: () => void;
};

export const Design = ({
  content = '',
  setContent,
  missingImages = [],
  transformContent,
  fileUploads,
  apiFileUpload,
  setFileUploads,
  handleAcceptedFiles,
  handleRejectedFiles,
}: Props) => (
  <>
    {(missingImages || []).length > 0 && (
      <MissingImages>
        <Title>Missing Images</Title>
        <p>The following images in your template appear to be missing. Please check your HTML and upload the images if necessary.</p>
        <pre>{(missingImages || []).join('\n')}</pre>
      </MissingImages>
    )}

    <HTMLWithPreview
      content={content}
      label="Paste or enter HTML"
      name="emailTemplateHtml"
      setContent={setContent}
      transformContent={transformContent}
      fileUploads={fileUploads}
      apiFileUpload={apiFileUpload}
      setFileUploads={setFileUploads}
      handleAcceptedFiles={handleAcceptedFiles}
      handleRejectedFiles={handleRejectedFiles}
      isUploadImage
    />
  </>
);
