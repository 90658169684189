import * as campaignTypes from 'app/ducks/campaigns/campaign/types';
import { fulfillmentTypes, calculationTypes, cashbackTypes, reverseTypes } from 'app/features/Cashback/constants';
import { deepCopy } from 'app/utilities/deepCopy';
import validate from './validation';
import * as types from './types';

export const fieldsPerCalculationType = {
  [calculationTypes.fixed]: {
    calculationType: calculationTypes.fixed,
    amount: '',
  },

  [calculationTypes.proportional]: {
    calculationType: calculationTypes.proportional,
    percentage: '',
    ceiling: '',
    roundDownToNearestAmount: '',
    percentageType: 'GLOBAL',
    allowedPaymentMethods: [],
  },

  [calculationTypes.lottery]: {
    calculationType: calculationTypes.lottery,
    scratchCard: null,
    legs: [
      {
        amount: '',
        percentPeople: '',
        isAmountPercent: false,
      },
    ],

    allowedPaymentMethods: [],
  },

  [calculationTypes.noreward]: {
    calculationType: calculationTypes.noreward,
  },
};

export const fieldsPerFulfillmentType = {
  [fulfillmentTypes.dayOfMonth]: {
    dayOfMonth: '',
    numMonths: '',
    type: fulfillmentTypes.dayOfMonth,
  },

  [fulfillmentTypes.instant]: {
    type: fulfillmentTypes.instant,
  },

  [fulfillmentTypes.relative]: {
    offsetDays: '',
    type: fulfillmentTypes.relative,
  },
};

export const generateStageId = () => `stage-${Date.now().toString(36)}`;
export const uniqueStageId = generateStageId();

export const initialCriteria = {
  eventType: null,
  eventCriteriaJson: [],
  eventCriteria: null,
};

export const initialCashbackCalc = {
  stackCeiling: null,
  payoutStrategy: fieldsPerCalculationType[calculationTypes.fixed],
};

export const initialFulfillment = fieldsPerFulfillmentType[fulfillmentTypes.instant];

export const initialState = {
  valid: false,
  errorMessages: [],
  type: cashbackTypes.regular,
  [cashbackTypes.regular]: {
    userBudgetToken: null,
    stackableGroup: null,
    cashbackPromoBanner: null,
    customCampaignAttributes: [],
    additionalAttributes: [],
    calculation: initialCashbackCalc,
    fulfillment: initialFulfillment,
    criteria: {
      ...initialCriteria,
      userCriteria: null,
      userCriteriaJson: [],
    },

    communicationTemplates: [],
  },

  [cashbackTypes.journey]: {
    stackableGroup: null,
    cashbackPromoBanner: null,
    customCampaignAttributes: [],
    additionalAttributes: [],
    fulfillment: initialFulfillment,
    reversalStrategy: reverseTypes.specificStageReverse.value,
    creativeUserCriteria: {
      userCriteria: null,
      userCriteriaJson: [],
    },

    stages: {
      [uniqueStageId]: {
        id: uniqueStageId,
        order: 0,
        calculation: {
          ...initialCashbackCalc,
        },

        criteria: initialCriteria,
        customStageAttributes: [],
      },
    },

    communicationTemplates: [],
  },
};

export const regularCreative = (state, { type, payload }) => {
  switch (type) {
    case campaignTypes.NEW_CAMPAIGN:
      return initialState[cashbackTypes.regular];
    case campaignTypes.SET_REGULAR_CASHBACK_CREATIVE:
      return { ...payload.creative };
    case types.UPDATE_CASHBACK_FIELD:
      return {
        ...state,
        ...payload,
      };

    case types.UPDATE_CASHBACK_CALCULATION_TYPE:
      return {
        ...state,
        calculation: {
          stackCeiling: payload.stackCeiling,
          payoutStrategy: {
            ...deepCopy(fieldsPerCalculationType[payload.calculationType]), // required to make a copy
          },
        },
      };

    case types.UPDATE_CASHBACK_CALCULATION_FIELD:
      return {
        ...state,
        calculation: {
          payoutStrategy: {
            ...state.calculation.payoutStrategy,
            ...payload,
          },
        },
      };

    case types.UPDATE_CASHBACK_PAYMENT_METHODS:
      return {
        ...state,
        calculation: {
          payoutStrategy: {
            ...state.calculation.payoutStrategy,
            ...payload,
          },
        },
      };

    case types.UPDATE_CASHBACK_CRITERIA:
      return {
        ...state,
        ...{
          criteria: {
            ...state.criteria,
            ...payload,
          },
        },
      };

    case types.UPDATE_CASHBACK_FULFILLMENT_TYPE:
      return {
        ...state,
        ...{
          fulfillment: { ...fieldsPerFulfillmentType[payload] },
        },
      };

    case types.UPDATE_CASHBACK_FULFILLMENT:
      return {
        ...state,
        ...{
          fulfillment: {
            ...state.fulfillment,
            ...payload,
          },
        },
      };

    case types.UPDATE_CASHBACK_STACK_CEILING:
      return {
        ...state,
        calculation: {
          ...state.calculation,
          stackCeiling: payload,
        },
      };

    case types.UPDATE_CASHBACK_PERCENTAGE_TYPE:
      const payoutStrategyData = {
        ...deepCopy(fieldsPerCalculationType[calculationTypes.proportional]),
        percentageType: payload.percentageType,
        roundDownToNearestAmount: state.calculation.payoutStrategy.roundDownToNearestAmount,
        ceiling: state.calculation.payoutStrategy.ceiling,
        percentage: state.calculation.payoutStrategy.percentage,
        allowedPaymentMethods: state.calculation.payoutStrategy.allowedPaymentMethods || [],
      };

      return {
        ...state,
        calculation: {
          payoutStrategy: payoutStrategyData,
        },
      };

    default:
      return state;
  }
};

export const journeyCreative = (state, { type, payload }) => {
  switch (type) {
    case campaignTypes.NEW_CAMPAIGN:
      return initialState[cashbackTypes.journey];
    case campaignTypes.SET_JOURNEY_CREATIVE:
      return { ...payload.creative };
    case types.UPDATE_JOURNEY_FIELD: {
      return {
        ...state,
        ...payload,
      };
    }

    // Add stage
    case types.ADD_JOURNEY_STAGE: {
      const newId = generateStageId();
      const newOrder = Object.keys(state.stages).length;
      return {
        ...state,
        stages: {
          ...state.stages,
          [newId]: {
            id: newId,
            order: newOrder,
            calculation: {
              ...initialCashbackCalc,
            },

            criteria: initialCriteria,
            customStageAttributes: [],
          },
        },
      };
    }

    // Delete stage
    case types.DELETE_JOURNEY_STAGE: {
      const { stageNum } = payload;
      const deletedOrder = state.stages[stageNum].order;
      const {
        stages: { [stageNum]: takeOutStage, ...newStages },
      } = state;

      Object.keys(newStages).forEach(id => {
        if (newStages[id].order > deletedOrder) {
          newStages[id].order--; /* eslint no-plusplus: "off" */
        }
      });
      return {
        ...state,
        stages: newStages,
      };
    }

    // Exchange order of stages
    case types.EXCHANGE_JOURNEY_STAGE_ORDER: {
      const { stageNum1, stageNum2 } = payload;
      const { stages: newStages } = state;
      const tempOrder = newStages[stageNum1].order;
      newStages[stageNum1].order = newStages[stageNum2].order;
      newStages[stageNum2].order = tempOrder;
      return {
        ...state,
        stages: newStages,
      };
    }

    // Update certain stage
    case types.UPDATE_JOURNEY_CALCULATION_TYPE: {
      const updatedStages = { ...state.stages };
      const updatedStage = updatedStages[payload.stageNum];
      updatedStages[payload.stageNum] = {
        ...updatedStage,
        calculation: {
          ...updatedStage.calculation,
          stackCeiling: payload.stackCeiling,
          payoutStrategy: {
            ...deepCopy(fieldsPerCalculationType[payload.calculationType]), // required to make a copy
          },
        },
      };

      return {
        ...state,
        stages: updatedStages,
      };
    }

    case types.UPDATE_JOURNEY_CALCULATION_FIELD: {
      const updatedStages = { ...state.stages };
      const updatedStage = updatedStages[payload.stageNum];
      const { stageNum, ...rest } = payload;
      updatedStages[stageNum] = {
        ...updatedStage,
        calculation: {
          ...updatedStage.calculation,
          payoutStrategy: {
            ...updatedStage.calculation.payoutStrategy,
            ...rest,
          },
        },
      };

      return {
        ...state,
        stages: updatedStages,
      };
    }

    case types.UPDATE_JOURNEY_STACK_CEILING: {
      const updatedStages = { ...state.stages };
      const { stageNum, ...rest } = payload;
      const updatedStage = updatedStages[stageNum];
      updatedStages[stageNum] = {
        ...updatedStage,
        calculation: {
          ...updatedStage.calculation,
          stackCeiling: rest,
        },
      };

      return {
        ...state,
        stages: updatedStages,
      };
    }

    case types.UPDATE_JOURNEY_FULFILLMENT_TYPE: {
      const { fulfillType } = payload;
      return {
        ...state,
        fulfillment: { ...fieldsPerFulfillmentType[fulfillType] },
      };
    }

    case types.UPDATE_JOURNEY_REVERSE: {
      const { reverseType } = payload;
      return {
        ...state,
        reversalStrategy: reverseTypes[reverseType].value,
      };
    }

    case types.UPDATE_JOURNEY_STAGE_ATTRIBUTES: {
      const { stageNum, customStageAttributes } = payload;
      const updatedStages = { ...state.stages };
      const updatedStage = updatedStages[stageNum];
      updatedStages[stageNum] = {
        ...updatedStage,
        customStageAttributes,
      };

      return {
        ...state,
        stages: updatedStages,
      };
    }

    case types.UPDATE_JOURNEY_FULFILLMENT: {
      return {
        ...state,
        fulfillment: {
          ...state.fulfillment,
          ...payload,
        },
      };
    }

    case types.UPDATE_JOURNEY_EVENT_CRITERIA: {
      const { stageNum, ...rest } = payload;
      const updatedStages = { ...state.stages };
      const updatedStage = updatedStages[stageNum];
      updatedStages[stageNum] = {
        ...updatedStage,
        criteria: {
          ...updatedStage.criteria,
          ...rest,
        },
      };

      return {
        ...state,
        stages: updatedStages,
      };
    }

    case types.UPDATE_JOURNEY_USER_CRITERIA: {
      const { userCriteria } = payload;
      return {
        ...state,
        creativeUserCriteria: {
          ...state.creativeUserCriteria,
          userCriteria,
        },
      };
    }

    case types.UPDATE_JOURNEY_USER_CRITERIA_JSON: {
      const { userCriteriaJson } = payload;
      return {
        ...state,
        creativeUserCriteria: {
          ...state.creativeUserCriteria,
          userCriteriaJson,
        },
      };
    }

    default:
      return state;
  }
};

export const creativeType = (state, { type, payload }) => {
  switch (type) {
    case types.UPDATE_CREATIVE_TYPE:
      return payload.type === cashbackTypes.regular || !payload.type ? cashbackTypes.regular : cashbackTypes.journey;
    default:
      return state;
  }
};

export default (state = initialState, { type, payload }) =>
  validate({
    ...state,
    type: creativeType(state.type, { type, payload }),
    [cashbackTypes.regular]: regularCreative(state[cashbackTypes.regular], {
      type,
      payload,
    }),

    [cashbackTypes.journey]: journeyCreative(state[cashbackTypes.journey], {
      type,
      payload,
    }),
  });
