import moment from 'moment';
import 'moment-timezone';

import { getChannelsConfig } from 'configs/channels';
import { IChannelConfig } from 'configs/channels/types';
import { IAppConfig } from 'configs/apps/types';
import { ICampaignPriority, ICampaignExecution } from 'app/types';
import { asPercentStr, formatNumber } from 'app/utilities/numberFormat';
import { finalStates, states, supportedMediumIds, dedupeLabels } from './constants';

const renderNumber = (n: number | null | undefined) => (n !== undefined && n !== null ? formatNumber(n) : '-');

const renderPercent = (numerator: number | null | undefined, denominator: number | null | undefined) =>
  numerator !== undefined && numerator !== null && denominator !== undefined && denominator !== null
    ? asPercentStr(numerator, denominator)
    : '';

export const getChannelOptions = (appConfig?: IAppConfig): Array<{ id: string; label: string }> => {
  const channelByMediumId: Record<number, IChannelConfig> = getChannelsConfig(appConfig);

  return supportedMediumIds
    .filter(mediumId => !!channelByMediumId[mediumId])
    .map(mediumId => {
      const channel = channelByMediumId[mediumId];
      return {
        id: channel.name,
        label: channel.displayName || channel.name,
      };
    });
};

/**
 * A masterplan is started at midnight of the tenant timezone.
 *
 * @param {*} date
 * @param {*} timezone
 * @returns Boolean indicating whether a masterplan has started for the given date and medium
 */
export const isStarted = (date: string, timezone: string): boolean => {
  const now = moment().tz(timezone);
  const executionStart = moment.tz(date, timezone);
  return now.isAfter(executionStart);
};

export const parseErrorMessage = (error: { message: string }) => {
  try {
    return JSON.parse(error.message).message;
  } catch (err) {
    return error.message;
  }
};

export const parseErrorDebugMessage = (error: { message: string }) => {
  try {
    return JSON.parse(error.message).debugMessage;
  } catch (err) {
    return '';
  }
};

export const toFinalState = (taskState: string | null | undefined, deliveryState: string | null | undefined) => {
  if (deliveryState === states.success) {
    return finalStates.success;
  }

  if (
    deliveryState === states.failed ||
    deliveryState === states.interrupted ||
    taskState === states.failed ||
    taskState === states.interrupted
  ) {
    return finalStates.failed;
  }

  if (taskState === states.pending || taskState === states.queued) {
    return finalStates.waiting;
  }

  if (
    taskState === states.running ||
    deliveryState === states.pending ||
    deliveryState === states.queued ||
    deliveryState === states.running ||
    deliveryState === states.success
  ) {
    return finalStates.processing;
  }

  return finalStates.unknown;
};

// TODO: Retire toHumanizedStatus()
export const toHumanizedStatus = (taskState: string | null | undefined, deliveryState: string | null | undefined) => {
  if (!taskState && !deliveryState) {
    return '';
  }

  const pendingStates = [states.pending, states.queued, states.running];
  const completedStates = [states.failed, states.interrupted, states.success];

  if (pendingStates.includes(taskState)) {
    return states.running === taskState ? 'Being preprocessed' : 'Waiting to be preprocessed';
  }

  if (states.success !== taskState) {
    return 'Failed in preprocessing';
  }

  if (pendingStates.includes(deliveryState)) {
    return states.running === deliveryState ? 'Being delivered' : 'Waiting to be delivered';
  }

  if (states.success !== deliveryState) {
    return 'Failed in delivery';
  }

  if (
    !pendingStates.includes(taskState) &&
    !completedStates.includes(taskState) &&
    !pendingStates.includes(deliveryState) &&
    !completedStates.includes(deliveryState)
  ) {
    return 'Unknown';
  }

  return 'Delivered';
};

export const searchCampaigns = (
  campaigns: Array<ICampaignExecution | ICampaignPriority>,
  searchValue?: string,
): Array<ICampaignExecution | ICampaignPriority> => {
  if (!Array.isArray(campaigns)) {
    return [];
  }

  if (!searchValue) {
    return campaigns;
  }

  return campaigns.filter(
    c =>
      +c.id === +searchValue ||
      (typeof c.name === 'string' && typeof searchValue === 'string' && c.name.toUpperCase().includes(searchValue.toUpperCase())),
  );
};

export const extendExecutions = (executions: Array<ICampaignExecution>) => {
  return executions.map<ICampaignExecution>(exec => {
    return {
      ...exec,
      ...{
        type: exec.planType,
        isRecurring: exec.isRecurring ? 'recurring' : 'one-time',
        pushSite: exec.pushSite ? exec.pushSite : '-',
        pushType: exec.pushType ? exec.pushType : '-',
        countPerExecutionNumber: renderNumber(exec.countPerExecution),
        acquiredNumber: renderNumber(exec.acquired),
        acquiredPercent: renderPercent(exec.acquired, exec.countPerExecution),
        deliveredNumber: renderNumber(exec.delivered),
        deliveredPercent: renderPercent(exec.delivered, exec.countPerExecution),
        dedupeLabel: dedupeLabels[exec.dedupeStrategy],
      },
    };
  });
};
