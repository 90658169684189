import emailCreative from './reducers';

// import * as emailCreativeOperations from './operations';
import { ConnectedEmail } from './ConnectedEmail';

export {
  // emailCreativeOperations,
  ConnectedEmail,
};

export default emailCreative;
