import { dataHasErrors } from 'app/helpers/FeatureMetadataHelpers/helpers';
import { isNilOrEmpty } from 'app/helpers/RamdaHelpers/helpers';

export const validateRules = ({ includedRules, includedRuleErrors }) => {
  if (isNilOrEmpty(includedRules)) {
    return {
      isValid: false,
      errorMessage: 'No rules defined.',
    };
  }

  if (dataHasErrors(includedRuleErrors)) {
    return {
      isValid: false,
      errorMessage: 'Invalid rule definition(s) detected. Fix the error(s) and try again.',
    };
  }

  const [{ expression }] = includedRules;

  return {
    isValid: true,
    rules: {
      expression,
    },
  };
};
