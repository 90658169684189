import * as React from 'react';
import styled from 'styled-components';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { sc } from 'app/styles';
import { ICampaignPriority } from 'app/types';

import CampaignPriorityRow from './CampaignPriorityRow';
import { IListVariant } from './constants';

type Props = {
  campaigns: Array<ICampaignPriority>;
  droppableId: string;
  isDropDisabled?: boolean;
  onSelect: (arg0: IListVariant, arg1: ICampaignPriority) => void;
  search?: string;
  selected: Array<ICampaignPriority>;
  showPriorities?: boolean;
  showRecurring?: boolean;
  variant: IListVariant;
  dataQa?: string;
};

export default class DragAndDropPriorityList extends React.Component<Props> {
  isSelected = (campaign: ICampaignPriority) => {
    const { selected } = this.props;
    return selected.findIndex(c => c.id === campaign.id) >= 0;
  };

  render() {
    const { campaigns, droppableId, isDropDisabled = false, onSelect, search, showPriorities, showRecurring, variant, dataQa } = this.props;

    return (
      <Droppable droppableId={`${droppableId}`} isDropDisabled={isDropDisabled}>
        {({ innerRef: droppableRef, droppableProps, placeholder }) => (
          <ListContainer ref={droppableRef} {...droppableProps} data-qa={dataQa}>
            {campaigns.map((campaign, i) => (
              <Draggable key={campaign.id} draggableId={`${campaign.id}`} index={i}>
                {({ innerRef: draggableRef, draggableProps, dragHandleProps }) => (
                  <RowContainer ref={draggableRef} {...draggableProps} {...dragHandleProps}>
                    <CampaignPriorityRow
                      element={campaign}
                      isSelected={this.isSelected(campaign)}
                      onSelect={onSelect}
                      search={search}
                      id={`${campaign.id}`}
                      showPriority={showPriorities}
                      showRecurring={showRecurring}
                      variant={variant}
                    />
                  </RowContainer>
                )}
              </Draggable>
            ))}

            {placeholder}
          </ListContainer>
        )}
      </Droppable>
    );
  }
}

const ListContainer = styled.div`
  border: 1px solid ${sc.sectionBorderColor};
  height: calc(100vh - 310px);
  overflow-y: auto;
`;

const RowContainer = styled.div`
  &:nth-child(even) {
    background-color: ${sc.sectionBackgroundColor};
  }
`;
