export const styles = theme => ({
  root: {
    // width: '100%',
    // marginTop: theme.spacing.unit * 3,
    // paddingRight: theme.spacing.unit,
    flexShrink: 0,
    // color: theme.palette.text.secondary,
    // marginLeft: theme.spacing.unit * 2.5,
  },
  tableContainer: {
    width: '100%',
    // marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },

  table: {
    minWidth: 800,
    // background: theme.palette.common.white,
  },
  tableWrapper: {
    overflowX: 'auto',
    // padding: `0 ${theme.spacing.unit}px`,
  },
  tableCell: {
    padding: '4px 12px 4px 12px',
  },

  spacer: {
    flex: '1 1 100%',
  },

  actions: {
    color: theme.palette.text.secondary,
  },

  title: {
    flex: '0 0 auto',
  },

  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),

    marginLeft: 'auto',
  },

  expandOpen: {
    transform: 'rotate(180deg)',
  },
});
