import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { withPermissions } from 'app/decorators';
import { usersEditPermissions } from 'configs/permissions';
import { Link } from 'react-router-dom';
import {
  Spinner,
  SimpleTable as _SimpleTable,
  SimpleTableBody,
  SimpleTableCell as _SimpleTableCell,
  SimpleTableHead as _SimpleTableHead,
  SimpleTableHeadCell as _SimpleTableHeadCell,
  SimpleTableRow,
  Icon as _Icon,
  ConfirmModal,
} from 'app/midgarComponents';

import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { IUser } from 'app/types/User';

const PROFILE_PREFIX = 'PROFILE_';

type Props = {
  users: Array<IUser>;
  isLoading: boolean;
  isPermitted: (...args: Array<any>) => any;
  deleteUser: (arg0: string) => (...args: Array<any>) => any;
};

type State = {
  currentUser: string;
  isConfirmModalOpen: boolean;
  loading: boolean;
};

class UserTable extends React.PureComponent<Props, State> {
  state = {
    isConfirmModalOpen: false,
    currentUser: null,
    loading: false,
  };

  handleSuccessDelete = () => {
    const { currentUser } = this.state;
    displaySuccess(`Successfully deleted user ${currentUser}`);
    this.setState({
      currentUser: null,
      loading: false,
    });
  };

  deleteUserConfirm = () => {
    const { currentUser } = this.state;
    const { deleteUser } = this.props;
    this.setState({
      loading: true,
      isConfirmModalOpen: false,
    });

    try {
      deleteUser(currentUser)
        .then(() => this.handleSuccessDelete())
        .catch(err => displayError(`Error occured: ${err.message}`));
    } catch (err) {
      displayError(`Error occured: ${err.message}`);
    }
  };

  deleteHandler = user => {
    this.setState({
      isConfirmModalOpen: true,
      currentUser: user,
    });
  };

  closeConfimationModel = () => {
    this.setState({
      isConfirmModalOpen: false,
      loading: false,
    });
  };

  render = () => {
    const { users, isLoading, isPermitted } = this.props;
    const { loading } = this.state;
    const userEdittingPermitted = isPermitted([usersEditPermissions]);
    const { isConfirmModalOpen, currentUser } = this.state;
    return (
      <section>
        {isLoading || loading ? (
          <Spinner />
        ) : (
          <SimpleTable>
            <SimpleTableHead>
              <SimpleTableRow>
                <SimpleTableHeadCell>Email</SimpleTableHeadCell>
                <SimpleTableHeadCell>Permissions</SimpleTableHeadCell>
                <SimpleTableHeadCell>Storefronts</SimpleTableHeadCell>
                {userEdittingPermitted && <SimpleTableHeadCell>Action</SimpleTableHeadCell>}
              </SimpleTableRow>
            </SimpleTableHead>

            <SimpleTableBody>
              {users.length === 0 ? (
                <SimpleTableRow>
                  <SimpleTableCell>
                    <>No Records Found</>
                  </SimpleTableCell>
                </SimpleTableRow>
              ) : (
                users.map(u => (
                  <SimpleTableRow key={u.email}>
                    <SimpleTableCell>{u.email}</SimpleTableCell>
                    <SimpleTableCell>{u.permissions.join(', ')}</SimpleTableCell>
                    <SimpleTableCell>
                      {u.storefrontsAndViews ? Object.keys(u.storefrontsAndViews).join(', ') : u.storefronts}
                    </SimpleTableCell>
                    {userEdittingPermitted && (
                      <ActionsCell>
                        {!u.email.startsWith(PROFILE_PREFIX) && (
                          <Link to={`users/edit/${u.email}`}>
                            <Icon name="edit" color={sc.grey} title={`Edit ${u.email}`} />
                          </Link>
                        )}

                        <Link to={`users/copy/${u.email}`}>
                          <Icon name="copy" color={sc.grey} title={`Clone ${u.email}'s permissions`} />
                        </Link>
                        <Icon name="delete" color={sc.grey} title={`Delete ${u.email}`} onClick={() => this.deleteHandler(u.email)} />
                      </ActionsCell>
                    )}
                  </SimpleTableRow>
                ))
              )}
            </SimpleTableBody>
          </SimpleTable>
        )}

        <ConfirmModal
          title="Confirmation"
          confirmText="Yes"
          isOpen={isConfirmModalOpen}
          onClose={this.closeConfimationModel}
          onConfirm={this.deleteUserConfirm}
        >
          Are you sure to delete {currentUser}?
        </ConfirmModal>
      </section>
    );
  };
}

export default withPermissions(UserTable);

const SimpleTable = styled(_SimpleTable)`
  font-size: ${sc.fontSizeSmall};
  color: ${sc.bodyColor};
  line-height: 1.5;
  border-bottom: 1px solid ${sc.sectionBorderColor};
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
`;

const SimpleTableHead = styled(_SimpleTableHead)`
  background: ${sc.sectionBackgroundColor};
  padding: 1rem 0;
  border-bottom: 1px solid ${sc.sectionBorderColor};
`;

const SimpleTableHeadCell = styled(_SimpleTableHeadCell)`
  padding: 1.2rem 1rem 1.2rem 1rem;
  text-align: left;
  font-weight: bold;
`;

const SimpleTableCell = styled(_SimpleTableCell)`
  padding: 1rem;
`;

const ActionsCell = styled(SimpleTableCell)`
  text-align: right;
`;

const Icon = styled(_Icon)`
  margin: 4px;
  display: inline;
`;
