import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, Select as MaterialSelect, InputLabel, MenuItem } from '@material-ui/core';

import { isPlainObject } from 'ramda-adjunct';

import { R } from 'app/helpers/RamdaHelpers/helpers';

const toMenuItem = R.curry(({ selectedItemStyle, nonSelectedItemStyle }, selected, option) => {
  const [optionValue, optionLabel] = isPlainObject(option) ? [option.value, option.label] : [option];

  return (
    <MenuItem
      key={optionValue}
      value={optionValue}
      data-qa={optionValue}
      className={selected === optionValue ? selectedItemStyle : nonSelectedItemStyle}
    >
      {optionLabel || optionValue}
    </MenuItem>
  );
});

export const RuleSelect = ({ id, options, handleChange, classes, label = '', value = '' }) => (
  <FormControl className={classes.formControl}>
    <InputLabel htmlFor={id}>{label}</InputLabel>

    <MaterialSelect
      autoWidth
      value={value}
      onChange={handleChange}
      inputProps={{
        name: id,
        id,
      }}
      data-qa="rule-select-option"
    >
      <MenuItem value="">
        <em>(None)</em>
      </MenuItem>

      {R.map(toMenuItem(classes, value), options)}
    </MaterialSelect>
  </FormControl>
);

RuleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
};
