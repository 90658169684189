import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Input } from 'app/midgarComponents';

import { getRegularCeiling, getJourneyCeiling } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';

type Props = {
  size: string | null | undefined;
  ceiling: number;
  onChangeFn: (...args: Array<any>) => any;
};

class MaxPayout extends PureComponent<Props> {
  render() {
    const { size, onChangeFn, ceiling } = this.props;
    return (
      <Input
        type="number"
        min={0}
        step="1"
        width={size || '150px'}
        name="ceiling"
        value={ceiling}
        placeholder="Maximum Cashback (eg: 100) *"
        onChange={({ target: { value } }) => onChangeFn({ ceiling: value })}
      />
    );
  }
}

export default connect((state, ownProps) => ({
  ceiling: ownProps.stageNum === '' ? getRegularCeiling(state) : getJourneyCeiling(state, ownProps.stageNum),
}))(MaxPayout);
