import * as api from 'app/api/config';
import { DispatchFn } from 'app/types';

import { UPDATECONFIGSTORE } from './types';

export const getTenantConfig = (tenant: string) => async (dispatch: DispatchFn) => {
  const config = await api.getConfig(tenant);
  dispatch({ type: UPDATECONFIGSTORE, payload: config });
  return config;
};
