import { IPriorityType } from 'app/features/MasterPlan/constants';
import { triggerNames, MANUAL, SYSTEM } from 'app/features/MasterPlan/constants';
import { triggerTypes } from 'app/utilities/constants';
import sleep from 'app/utilities/sleep';

import { ICampaignExecutionDto, IExecutionsResult, IMasterplanApiParams } from './types';

type OtherProps = {
  acquired?: number;
  countPerExecution?: number;
  delivered?: number;
  delivery_state?: string;
  plan_type?: string;
  priority_type: IPriorityType;
  task_state?: string;
};

const dummy = (id: number, props: OtherProps): ICampaignExecutionDto => ({
  ...props,
  campaignName:
    id % 1000 === 5 ? `CAMPAIGN_WITH_A_VERY_LONG_NAME_TO_TEST_HOW_IT_IS_HANDLED_BY_THE_EXECUTIONS_PAGE` : `Sample_Campaign_${id}`,
  campaign_id: id,
});

const props = (trigger: string, i: number, isManual: boolean): OtherProps => {
  const countPerExecution = Math.floor(Math.random() * 10000000);
  const acquired = Math.floor(countPerExecution * Math.random());
  const delivered = Math.floor(acquired * Math.random());

  return {
    acquired,
    countPerExecution,
    delivered,
    delivery_state: 'SUCCEEDED',
    plan_type: Math.random() > 0.1 ? 'MPG' : 'IMPROMPTU',
    priority: i + 1,
    priority_type: isManual ? MANUAL : SYSTEM,
    task_state: 'SUCCEEDED',
    trigger: trigger === triggerNames.scheduled ? (Math.random() < 0.2 ? triggerTypes.oneTime : triggerTypes.recurring) : undefined,
  };
};

const executions = (trigger, size, baseId, numManual) => ({
  data: [...[...Array(size).keys()].map((_, i) => dummy(baseId + i, props(trigger, i, i < numManual)))],
});

export const fetchExecutions = (params: IMasterplanApiParams): Promise<IExecutionsResult> => {
  sleep();

  const { trigger } = params;

  if (trigger === triggerNames.scheduled) {
    return Promise.resolve(executions(trigger, 600, 1000, 8));
  }
  if (trigger === triggerNames.location) {
    return Promise.resolve(executions(trigger, 100, 2000, 4));
  }
  return Promise.resolve(executions(trigger, 1, 3000, 0));
};
