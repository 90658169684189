import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';

export default class Description extends React.PureComponent<{
  name: string;
  id: number;
  description: string;
}> {
  render() {
    const { name, id, description } = this.props;
    return (
      <>
        <Name>{name}</Name>
        <Des>{description}</Des>
        <ID>{`ID : ${id}`}</ID>
      </>
    );
  }
}

const Name = styled.p`
  font-size: ${sc.fontSize};
  font-weight: 800;
  line-height: 1.25;
  color: ${sc.bodyColor};
`;

const Des = styled.p`
  font-size: ${sc.fontSize};
  line-height: 1.25;
  color: ${sc.bodyColor};
`;

const ID = styled.p`
  font-size: ${sc.fontSizeSmaller};
  line-height: 1.33;
  color: ${sc.headingColor};
`;
