import api from 'app/utilities/api';

import { GET_LOCATIONS, UPLOAD_LOCATION_FILE } from './endpoints';
import { ILocation } from './types';

export const fetchLocations = (): Promise<Array<ILocation>> => api.get(GET_LOCATIONS).then(({ locations }) => locations);

export const uploadLocationFile = (file: File): Promise<{ id: number }> => {
  const formData = new FormData();
  formData.append('file', file);
  return api.post(UPLOAD_LOCATION_FILE, { body: formData });
};
