const GET_ALL_TAGS = 'map/tags/GET_ALL';
const GET_ALL_TAGS_SUCCESS = 'map/tags/GET_ALL_SUCCESS';
const GET_ALL_TAGS_FAIL = 'map/tags/GET_ALL_FAIL';
const POST_TAGS = 'map/tags/POST';
const POST_TAGS_SUCCESS = 'map/tags/POST_SUCCESS';
const POST_TAGS_FAIL = 'map/tags/POST_FAIL';
const GET_ALL_V2_TAGS = 'map/tags/GET_ALL_V2_TAGS';
const GET_V2_TAGS_SUCCESS = 'map/tags/GET_V2_TAGS_SUCCESS';

export {
  GET_ALL_TAGS,
  GET_ALL_TAGS_SUCCESS,
  GET_ALL_TAGS_FAIL,
  POST_TAGS,
  POST_TAGS_SUCCESS,
  POST_TAGS_FAIL,
  GET_ALL_V2_TAGS,
  GET_V2_TAGS_SUCCESS,
};
