import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import { labelByStorefrontType, storefrontTypes } from '../constants';

export const searchParams = {
  entityId: 'entityAssociatedWith',
  entityType: 'entityType',
  id: 'id',
};

const searchById = { name: searchParams.id, label: 'ID', type: 'number' };

const searchByEntityId = { name: searchParams.entityId, label: 'Entity ID', type: 'number' };

export const searchByEntityType = {
  name: searchParams.entityType,
  label: 'Storefront Type',
  options: [
    { id: storefrontTypes.category, label: labelByStorefrontType[storefrontTypes.category] },
    { id: storefrontTypes.merchant, label: labelByStorefrontType[storefrontTypes.merchant] },
  ],
};

// TODO: if !useStorefrontType, then there is only ID search, so do not use SearchBar

export const searchBarDefiner = ({ appConfig }: { appConfig: IAppConfig }) => {
  const { useStorefrontType } = getBannerCreativeConfig(appConfig);
  return useStorefrontType ? [searchById, searchByEntityId] : [searchById];
};

export const searchDefiner = ({ appConfig }: { appConfig: IAppConfig }) => {
  const { useStorefrontType } = getBannerCreativeConfig(appConfig);
  return useStorefrontType ? [searchById, searchByEntityId, searchByEntityType] : [searchById];
};

export const defaultSearch = ({ appConfig, storefrontType }: { appConfig: IAppConfig; storefrontType: string }) => {
  const { useStorefrontType } = getBannerCreativeConfig(appConfig);
  return useStorefrontType ? { [searchParams.entityType]: storefrontType || storefrontTypes.category } : {};
};
