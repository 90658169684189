/*eslint-disable prettier/prettier */
import { IWidgetType } from 'app/types/BannerManagement';

import { State as WidgetTypesState } from './reducers';

type Store = { bannerManagement: { widgetTypes: WidgetTypesState } };

const uniqueArrayValues = (value, index, self) => self.indexOf(value) === index;

const extractUnique = (widgetTypes: Array<IWidgetType> | null | undefined, propName: string): Array<string> =>
  (widgetTypes || []).reduce((acc, curr) => [...acc, ...(curr[propName] || [])], []).filter(uniqueArrayValues);

export const getAllPlatforms = ({ bannerManagement: { widgetTypes } }: Store): Array<string> =>
  extractUnique(widgetTypes.widgetTypes, 'platform');

export const getAllSites = ({ bannerManagement: { widgetTypes } }: Store): Array<string> => extractUnique(widgetTypes.widgetTypes, 'site');

export const mapStateToWidgetTypes = ({
  bannerManagement: {
    widgetTypes: { error: widgetTypesError, loading: widgetTypesLoading, widgetTypes },
  },
}: Store): {
  widgetTypes: Array<IWidgetType>;
  widgetTypesError: string | null | undefined;
  widgetTypesLoading: boolean;
} => ({
  widgetTypes,
  widgetTypesError,
  widgetTypesLoading,
});
