import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Accordion, AccordionActions, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

export const DrawerError = ({ error, errorDetails, allowDismiss = true, handleDismiss, children, classes = {}, ...props }) => (
  <Accordion className={classes.root} {...props}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6" className={classes.summary}>
        {error}
      </Typography>
    </AccordionSummary>

    <AccordionDetails>
      <section className={classes.details}>
        {children}
        <pre className={classes.detailsPre}>{errorDetails}</pre>
      </section>
    </AccordionDetails>

    {allowDismiss && (
      <section>
        <Divider classes={{ root: classes.divider }} />

        <AccordionActions>
          <Button data-qa="dismiss-button" size="small" onClick={handleDismiss}>
            Dismiss Error
          </Button>
        </AccordionActions>
      </section>
    )}
  </Accordion>
);

DrawerError.propTypes = {
  error: PropTypes.string.isRequired,
  errorDetails: PropTypes.string,
  allowDismiss: PropTypes.bool,
  handleDismiss: PropTypes.func,
  children: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string,
    summary: PropTypes.string,
    details: PropTypes.string,
    detailsPre: PropTypes.string,
    divider: PropTypes.string,
  }),
};
