import moment from 'moment';
import { dissoc, compose } from 'ramda';
import { formatNumber } from 'app/utilities/numberFormat';
import { minDefaultRadius, triggerTypes } from 'app/utilities/constants';

import { fetch } from '../../commonStates';
import { addItem, removeItem } from '../../helpers';
import * as types from './types';

export const setId = () => -Math.floor(Math.random() * 20000);

const removeFields = compose(
  dissoc('id'),
  dissoc('createdBy'),
  dissoc('createdAt'),
  dissoc('updatedBy'),
  dissoc('updatedAt'),
  dissoc('state'),
);

const initialActivationCritieria = {
  criteriaType: triggerTypes.orderAbandon,
  orderCategories: [],
  triggerTimeInMinutes: '',
  predicateFilterObject: [],
  isDebug: false,
};

const campaignInitState = {
  ...fetch,
  name: '',
  categories: [],
  categories2: [],
  campaignPromotionCode: '',
  goal: null,
  businessPhase: null,
  tags: [],
  audienceFiltersEnabled: false,
  audienceBoost: 0,
  includedSegments: [],
  audienceId: [],
  excludedSegments: [],
  includedSegmentsFilters: [],
  excludedSegmentsFilters: [],
  includedSegmentsGeoFilters: [],
  includeType: 'profile',
  dedupeStrategy: 'SOFT_LOCK_DEDUPE',
  days: ['*'],
  expiryDate: moment().add(2, 'days').format('YYYY-MM-DD'),
  expiryTime: '23:59',
  startTime: '00:00',
  startDate: moment().add(1, 'days').format('YYYY-MM-DD'),
  timeRange: {
    from: '00',
    to: '24',
  },

  exclusionCampaign: false,
  useEveryoneAudience: false,
  variables: {
    features: [],
    events: [],
  },

  campaignSize: {
    loading: false,
    count: 0,
  },

  mediumId: 0,
  subChannel: '', // this one supports special channels like cashback, which has journey and cashback subchannels
  triggerType: triggerTypes.oneTime,
  csvUrl: null,
  defaultRadius: minDefaultRadius,
  criteria: {
    ...initialActivationCritieria,
    deactivationEvent: null,
  },

  metrics: null,
  saveError: null,
  objective: {},
  formErrors: {},
  submitAllForms: false,
  hiddenFormsSubmitted: {
    measurements: false,
    audiences: false,
    general: false,
  },

  maxAudienceLimit: null,
};

const general = (state = campaignInitState, { type, payload }) => {
  switch (type) {
    case types.GET_CAMPAIGN:
      return {
        ...state,
        loading: true,
      };

    case types.GET_CAMPAIGN_SUCCESS: {
      return {
        ...(payload.campaign.clone
          ? removeFields({ ...state, loading: false, ...payload.campaign })
          : { ...state, loading: false, ...payload.campaign }),
      };
    }
    case types.GET_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case types.POST_CAMPAIGN:
      return {
        ...state,
        loading: true,
      };

    case types.POST_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        id: payload.campaignId,
      };

    case types.POST_CAMPAIGN_FAIL:
      return {
        ...state,
        saveError: payload.error,
        loading: false,
      };

    case types.NEW_CAMPAIGN:
      return campaignInitState;
    case types.SET_CAMPAIGN_FIELD:
      return {
        ...state,
        ...payload,
      };

    case types.GET_CAMPAIGN_SIZE:
      return {
        ...state,
        campaignSize: { ...state.campaignSize, loading: true, error: false },
      };

    case types.GET_CAMPAIGN_SIZE_SUCCESS:
      return {
        ...state,
        campaignSize: {
          error: false,
          loading: false,
          count: formatNumber(payload.size),
          rawCount: payload.size,
        },
      };

    case types.GET_CAMPAIGN_SIZE_FAIL:
      return {
        ...state,
        campaignSize: { ...state.campaignSize, loading: false, error: true },
      };

    case types.UPDATE_FEATURES_VARIABLES:
      return {
        ...state,
        variables: {
          events: state.variables.events,
          features: state.variables.features.map(v => (v.id === payload.variable.id ? { ...v, defaultValue: payload.variable.value } : v)),
        },
      };

    case types.UPDATE_EVENTS_VARIABLES:
      return {
        ...state,
        variables: {
          features: state.variables.features,
          events: state.variables.events.map(v => (v.name === payload.variable.id ? { ...v, defaultValue: payload.variable.value } : v)),
        },
      };

    case types.ADD_FEATURES_VARIABLE:
      return {
        ...state,
        variables: {
          features: [{ defaultValue: '', ...payload.variable, id: -payload.variable.id }, ...state.variables.features],
          events: state.variables.events,
        },
      };

    case types.ADD_EVENTS_VARIABLE:
      return {
        ...state,
        variables: {
          features: state.variables.features,
          events: [payload.variable, ...state.variables.events],
        },
      };

    case types.REMOVE_FEATURES_VARIABLE:
      return {
        ...state,
        variables: {
          features: state.variables.features.filter(v => v.id !== payload.variable.id),
          events: state.variables.events,
        },
      };

    case types.REMOVE_EVENTS_VARIABLE:
      return {
        ...state,
        variables: {
          features: state.variables.features,
          events: state.variables.events.filter(v => v.name !== payload.variable.id),
        },
      };

    case types.REMOVE_ALL_EVENTS_VARIABLES:
      return {
        ...state,
        variables: {
          ...state.variables,
          events: [],
        },
      };

    case types.REMOVE_ALL_VARIABLES:
      return {
        ...state,
        variables: {
          features: [],
          events: [],
        },
      };

    case types.SET_STATUS: {
      if (state.id !== payload.id) {
        console.error(
          '*** THIS SHOULD NEVER HAPPEN BUT IS HAPPENING: campaigns/campaign/reducer/SET_STATUS is changing the status for the WRONG campaign. NEED TO DEBUG!',
        );
        // eslint-disable-line no-console
      }

      return {
        ...state,
        state: payload.status,
      };
    }
    case types.SET_LOCATION_TRIGGER: {
      return {
        ...state,
        ...payload,
      };
    }
    case types.ADD_SEGMENT:
      return {
        ...state,
        [payload.field]: addItem(payload.segment)(state[payload.field]),
      };

    case types.SET_SEGMENTS:
      return {
        ...state,
        [payload.field]: payload.segments,
      };

    case types.REMOVE_SEGMENT:
      return {
        ...state,
        [payload.field]: removeItem(payload.segment)(state[payload.field]),
      };

    case types.CLEAR_SEGMENTS:
      return {
        ...state,
        includedSegments: [],
        excludedSegments: [],
      };

    case types.CLEAR_FILTER_SEGMENTS:
      return {
        ...state,
        includedSegmentsFilters: [],
        excludedSegmentsFilters: [],
      };

    case types.UPDATE_FACEBOOK_VALUES:
      return {
        ...state,
        variables: {
          features: payload.values,
          events: state.variables.events,
        },
      };

    case types.SET_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          ...payload,
        },
      };

    case types.CLEAR_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initialActivationCritieria,
          deactivationEvent: state.criteria?.deactivationEvent || null,
        },
      };

    case types.SET_DEACTIVATION_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          deactivationEvent: {
            ...state.criteria.deactivationEvent,
            ...payload,
          },
        },
      };

    case types.CLEAR_DEACTIVATION_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          deactivationEvent: null,
        },
      };

    case types.GET_METRICS_SUCCESS:
      return {
        ...state,
        metrics: { ...payload.metrics },
      };

    case types.GET_METRICS_ERROR:
      return {
        ...state,
        metrics: {
          error: payload.error,
        },
      };

    case types.SET_GOAL:
      return {
        ...state,
        goal: payload.goal,
      };

    case types.SET_BUSINESS_PHASE:
      return {
        ...state,
        businessPhase: payload,
      };

    case types.SET_PROMO_CODE:
      return {
        ...state,
        campaignPromotionCode: payload.campaignPromotionCode,
      };

    // TODO: Remove once confirmed we don't need to pass errors through to validations.js
    case types.SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          ...payload,
        },
      };

    case types.RESET_FORM_ERRORS:
      return {
        ...state,
        formErrors: {},
      };

    case types.TOGGLE_SUBMIT_ALL_FORMS:
      return {
        ...state,
        submitAllForms: !state.submitAllForms,
      };

    case types.SET_HIDDEN_FORMS_SUBMITTED:
      return {
        ...state,
        hiddenFormsSubmitted: {
          ...state.hiddenFormsSubmitted,
          ...payload,
        },
      };

    case types.RESET_HIDDEN_FORMS_SUBMITTED:
      return {
        ...state,
        saveError: null,
        hiddenFormsSubmitted: Object.keys(state.hiddenFormsSubmitted).reduce((acc, formSection) => ({ ...acc, [formSection]: false }), {}),
      };

    default:
      return state;
  }
};

export default general;
