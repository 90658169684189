import { IView } from 'app/types/BannerManagement';

const requiredFields = ['name', 'widgetType'];

export const validateMissingFields = (view: IView): Array<string> => requiredFields.filter(f => !view[f]);

export const metaError = (metadata: Record<string, string>) => {
  let result = false;
  const metadataKeys = (metadata && Object.keys(metadata)) || [];
  metadataKeys.forEach(key => {
    const value = metadata[key] || '';
    if (key && value.length === 0) {
      result = true;
    }
    if (value && key.length === 0) {
      result = true;
    }
  });
  return result;
};
