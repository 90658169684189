import * as React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import { ConnectedUser } from 'app/ducks';
import { Card } from 'app/components';
import { segmentDownloadPermissions } from 'configs/permissions';
import { Button as _Button } from 'app/midgarComponents';

export default class CsvUpload extends React.PureComponent<{
  audienceType: string;
  classes: {
    header: string;
  };

  calculate: () => boolean;
  id: number;
  state: string;
  membershipCsvFileUri: string;
}> {
  onClick = (ev: Event) => {
    const { membershipCsvFileUri } = this.props;
    ev.preventDefault();
    ev.stopPropagation();

    const link = document.createElement('a');

    link.setAttribute('download', null);
    link.style.display = 'none';

    document.body.appendChild(link);

    link.setAttribute('href', membershipCsvFileUri);
    link.click();

    document.body.removeChild(link);
    return true;
  };
  render() {
    const { classes, membershipCsvFileUri } = this.props;
    return (
      <ConnectedUser>
        {({ isPermitted }) => (
          <Card
            className={classes.header}
            label={() => [
              <Typography variant="subtitle1" key="label">
                CSV location
              </Typography>,

              // audienceType === segmentTypeFlags.CSV_BASED && (
              //   <SegmentCount key="counter" calculate={calculate} classes={classes} calcOnLoad />
              // ),
            ]}
          >
            <p>{membershipCsvFileUri}</p>
            {isPermitted(segmentDownloadPermissions) && (
              <Button type="secondary" disabled={membershipCsvFileUri === 'file:///dev/null'} onClick={this.onClick}>
                Download
              </Button>
            )}
          </Card>
        )}
      </ConnectedUser>
    );
  }
}

const Button = styled(_Button)`
  border: none;
  box-shadow: none;
  width: 200px;
  height: 0;
  line-height: 0;
  padding: 0.5rem 0;
  text-align: left;
  &:hover,
  &:active,
  &:focus {
    background-color: unset;
    color: #00b9f5;
    border: none;
    text-decoration: underline;
  }
`;
