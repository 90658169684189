import { has, isEmpty, omit, pick } from 'ramda';
import { IQueryParams } from 'app/types';
import { ISearchDefs } from 'app/hocs/withReporting';

type ISearchPhraseAndType = {
  searchPhrase?: string;
  selectedSearchType?: string;
};

/**
 * Extracts the search phrase and search type.
 *
 * @param {IQueryParams} search
 * @param {ISearchDefs} searchBarDefs
 * @returns {ISearchPhraseAndType}
 */
export const getPhraseAndType = (search?: IQueryParams, searchBarDefs: ISearchDefs): ISearchPhraseAndType => {
  const searchTypeNames = searchBarDefs.map(({ name }) => name);
  const searchTypeParams = pick(searchTypeNames, search || {});
  // The following assumes that only one of the types is specified
  const selectedSearchType = searchTypeNames.find(name => has(name, searchTypeParams));

  return {
    searchPhrase: searchTypeParams[selectedSearchType],
    selectedSearchType,
  };
};

/**
 * Merges the given search phrase and search type into the given search object.
 * The original search object is not mutated.
 * Any existing searchPhraseType properties in the search object are removed from the result.
 *
 * @param {{ searchPhrase: string, selectedSearchType: string }}
 * @param {IQueryParams} search
 * @param {ISearchDefs} searchBarDefs
 * @returns {IQueryParams}
 */
export const mergePhraseAndType = (
  { searchPhrase, selectedSearchType }: ISearchPhraseAndType,
  queryParams: IQueryParams,
  searchBarDefs: ISearchDefs,
): IQueryParams => {
  const searchTypeNames = searchBarDefs.map(def => def.name);
  const otherQueryParams = omit(searchTypeNames, queryParams);
  if (selectedSearchType && selectedSearchType.length > 0) {
    return {
      ...otherQueryParams,
      [`searchBy`]: selectedSearchType,
      [`searchTerm`]: searchPhrase,
      [selectedSearchType]: searchPhrase,
    };
  }
  return otherQueryParams;
};

const getSearchLabel = (searchType?: string, searchBarDefs: ISearchDefs): string => {
  const selectedSearch = searchBarDefs.find(s => s.name === searchType);
  return selectedSearch ? selectedSearch.label || selectedSearch.name : 'N/A';
};

/**
 * Generate a message that there are no results for the given search phrase and type.
 *
 * @param {ISearchPhraseAndType}
 * @param {ISearchDefs} searchBarDefs
 * @returns {string}
 */
export const msgIfEmpty = (
  { searchPhrase, selectedSearchType }: ISearchPhraseAndType,
  searchBarDefs: ISearchDefs,
): string | null | undefined =>
  isEmpty(searchPhrase)
    ? undefined
    : `No search results corresponding to "${searchPhrase || ''}" for search ${getSearchLabel(selectedSearchType, searchBarDefs)}`;
