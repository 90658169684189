import { isNilOrEmpty } from 'app/helpers/RamdaHelpers/helpers';
import { isValid as isValidAttributes } from 'app/midgarComponents/KeyValue/utils';
import { fulfillmentTypes, calculationTypes, cashbackTypes } from 'app/features/Cashback/constants';
import { isValid as isValidPredicate } from 'app/midgarComponents/PredicateBuilder/helper';
import { isNumEqual } from 'app/utilities/isNumEqual';

export const MAX_JAVA_INTEGER = 2147483647;
export const MAX_FIX_PAYOUT = 100000;
export const MAX_PROPORTIONAL_PAYOUT = 2100000;
export const MAX_PROPORTIONAL_PAYOUT_PERCENT = 100;
export const MAX_LOTTERY_PAYOUT_PERCENT = 2000;
export const MAX_FIX_NEAREST_AMOUNT = 200;

const regularRequired = ['calculation', 'fulfillment', 'stackableGroup', 'userBudgetToken'];

const journeyRequired = ['stackableGroup', 'reversalStrategy'];

const isLotteryLegInvaid = leg => {
  if (leg.isAmountPercent) {
    return !leg.ceiling || leg.ceiling < 1 || leg.ceiling > MAX_FIX_PAYOUT || leg.amount > MAX_LOTTERY_PAYOUT_PERCENT;
  }
  return leg.amount > MAX_FIX_PAYOUT;
};

export const validateCalculation = (calc, stageOrder = '') => {
  const calcErrors = [];
  const showCashbackTypeInfo = (order: string) => (order === '' ? 'Regular' : `Journey stage ${order}`);

  if (calc.calculationType === calculationTypes.fixed) {
    if (isNilOrEmpty(calc.amount) || calc.amount < 1 || calc.amount > MAX_FIX_PAYOUT) {
      calcErrors.push(
        `${showCashbackTypeInfo(stageOrder)} Cashback amount should be more than 1 and less than or equal to ${MAX_FIX_PAYOUT}.`,
      );
    }
  }
  if (calc.calculationType === calculationTypes.proportional) {
    if (isNilOrEmpty(calc.ceiling) || calc.ceiling < 1 || calc.ceiling > MAX_PROPORTIONAL_PAYOUT || isNaN(calc.ceiling)) {
      calcErrors.push(
        `${showCashbackTypeInfo(stageOrder)} Maximum cashback should be more than 1 and less than or equal to ${MAX_PROPORTIONAL_PAYOUT}.`,
      );
    }
    if (
      calc.percentageType === 'GLOBAL' &&
      (isNilOrEmpty(calc.percentage) || calc.percentage < 0 || calc.percentage > MAX_PROPORTIONAL_PAYOUT_PERCENT || isNaN(calc.percentage))
    ) {
      calcErrors.push(
        `${showCashbackTypeInfo(
          stageOrder,
        )} Cashback percentage should be greater than or equal to 0 and less than or equal to ${MAX_PROPORTIONAL_PAYOUT_PERCENT}.`,
      );
    }
    if (
      !isNilOrEmpty(calc.roundDownToNearestAmount) &&
      !isNaN(calc.roundDownToNearestAmount) &&
      calc.roundDownToNearestAmount !== MAX_FIX_NEAREST_AMOUNT
    ) {
      calcErrors.push(`${showCashbackTypeInfo(stageOrder)} Nearest Amount should must be equal to ${MAX_FIX_NEAREST_AMOUNT}.`);
    }
    if (calc.percentageType === 'PER_PAYMENT_METHOD') {
      if (!calc?.allowedPaymentMethods?.length) {
        calcErrors.push(`Please select One or more payment methods inside Payout Strategies.`);
      }
    }
    if (calc?.allowedPaymentMethods && calc?.allowedPaymentMethods.length > 0) {
      calc?.allowedPaymentMethods?.map(item => {
        if (!item.percentage) return calcErrors.push(`Selected payment method's percentage cannot be zero.`);
      });
    }
  }
  if (calc.calculationType === calculationTypes.lottery) {
    if (
      !isNumEqual(
        (calc.legs || []).reduce((acc, curr) => acc + curr.percentPeople, 0),
        100,
      )
    ) {
      calcErrors.push(`${showCashbackTypeInfo(stageOrder)} Legs for lottery cashback must add upto 100%.`);
    }
    if ((calc.legs || []).some(isLotteryLegInvaid)) {
      calcErrors.push(
        `${showCashbackTypeInfo(
          stageOrder,
        )} Lottery cashback amount must be less than or equal to ${MAX_FIX_PAYOUT} or ${MAX_LOTTERY_PAYOUT_PERCENT}% and limits must be between 1 and ${MAX_FIX_PAYOUT}.`,
      );
    }
  }
  return calcErrors;
};

export const validateFulfillment = (fulfillment: unknown, isJourney?: boolean) => {
  const fulfillmentErrors = [];
  const showCashbackTypeInfo = (isJourneyType?: boolean) => (!isJourneyType ? 'Regular' : 'Journey');

  if (fulfillment.type === fulfillmentTypes.relative) {
    if (isNilOrEmpty(fulfillment.offsetDays) || fulfillment.offsetDays <= 0 || fulfillment.offsetDays >= MAX_JAVA_INTEGER) {
      fulfillmentErrors.push(`${showCashbackTypeInfo(isJourney)} Fulfillment - Offset days is not valid.`);
    }
  }
  if (fulfillment.type === fulfillmentTypes.dayOfMonth) {
    if (isNilOrEmpty(fulfillment.dayOfMonth) || fulfillment.dayOfMonth <= 0 || fulfillment.dayOfMonth > 28) {
      fulfillmentErrors.push(`${showCashbackTypeInfo(isJourney)} Fulfillment - Day of month is required to be between 1 and 28.`);
    }
    if (isNilOrEmpty(fulfillment.numMonths) || fulfillment.numMonths <= 0 || fulfillment.numMonths >= MAX_JAVA_INTEGER) {
      fulfillmentErrors.push(`${showCashbackTypeInfo(isJourney)} Fulfillment - Month number is not valid.`);
    }
  }
  return fulfillmentErrors;
};

const validate = state => {
  const regularState = state.regular;
  const journeyState = state.journey;

  let errorMessages = [];

  if (state.type === cashbackTypes.regular || !state.type) {
    // Validate required fields
    regularRequired.forEach(field => (isNilOrEmpty(regularState[field]) ? errorMessages.push(`Cashback ${field} is missing.`) : null));

    // Validate additional attributes
    if (!isValidAttributes(regularState.additionalAttributes)) {
      errorMessages.push('Cashback Additional Attributes are not valid.');
    }

    // Validate Event Type
    if (!regularState.criteria.eventType) {
      errorMessages.push('Event Type is missing.');
    }

    // Validate calculation
    errorMessages = errorMessages.concat(validateCalculation(regularState.calculation.payoutStrategy));

    // Validate fulfillment
    errorMessages = errorMessages.concat(validateFulfillment(regularState.fulfillment));

    // Validate custom campaign attributes
    regularState.customCampaignAttributes.forEach(att =>
      !att.optional && isNilOrEmpty(att.value) && isNilOrEmpty(att.defaultValue)
        ? errorMessages.push(`Cashback Campaign Attribute - ${att.fieldName} is missing.`)
        : null,
    );

    // Validate Event criteria
    if (!isValidPredicate(regularState.criteria.eventCriteriaJson)) {
      errorMessages.push('Event Criteria is invalid.');
    }

    // Validate Real time user criteria
    if (!isValidPredicate(regularState.criteria.userCriteriaJson)) {
      errorMessages.push('Real time user Criteria is invalid.');
    }
  }

  if (state.type === cashbackTypes.journey) {
    // Validate required fields
    journeyRequired.forEach(field => (isNilOrEmpty(journeyState[field]) ? errorMessages.push(`Journey ${field} is missing.`) : null));

    // Validate additional attributes
    if (!isValidAttributes(journeyState.additionalAttributes)) {
      errorMessages.push('Journey Additional Attributes are not valid.');
    }

    // Validate custom campaign attributes
    journeyState.customCampaignAttributes.forEach(att =>
      !att.optional && isNilOrEmpty(att.value) && isNilOrEmpty(att.defaultValue)
        ? errorMessages.push(`Journey Campaign Attribute - ${att.fieldName} is missing.`)
        : null,
    );

    // Validate stage cannot be empty
    if (Object.keys(journeyState.stages).length === 0) {
      errorMessages.push('Journey Stages cannot be empty');
    }

    // Validate fulfillment
    errorMessages = errorMessages.concat(validateFulfillment(journeyState.fulfillment, true));

    // Validate stage details
    Object.values(journeyState.stages).forEach(stage => {
      // Validate Event Type
      if (!stage.criteria.eventType) {
        errorMessages.push(`Journey Stage ${stage.order + 1} Event Type is missing.`);
      }

      // Validate calculation
      errorMessages = errorMessages.concat(validateCalculation(stage.calculation.payoutStrategy, stage.order + 1));

      // Validate Event criteria
      if (!isValidPredicate(stage.criteria.eventCriteriaJson)) {
        errorMessages.push(`Journey Stage ${stage.order + 1} Event Criteria is invalid.`);
      }

      // Validate Custom Stage Attributes
      stage.customStageAttributes.forEach(attr => {
        if (!attr.optional && !attr.value) {
          errorMessages.push(`Journey Stage ${stage.order + 1} custom stage attribute ${attr.fieldTitle} is invalid.`);
        }
      });

      // Validate Real time user criteria
      if (!isValidPredicate(stage.criteria.userCriteriaJson)) {
        errorMessages.push(`Journey Stage ${stage.order + 1} Real time user Criteria is invalid.`);
      }
    });
  }

  // add validation for journey

  const valid = errorMessages.length === 0;
  return {
    ...state,
    valid,
    errorMessages,
  };
};

export default validate;
