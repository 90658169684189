import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { inject } from 'app/decorators';
import * as selectors from 'app/ducks/categories/selectors';
import * as CategoriesOperations from 'app/ducks/categories/operations';
import { Pills } from 'app/midgarComponents';

import { ICategory, IQueryParams } from 'app/types';
import { compose } from 'recompose';

type Props = {
  categories: Array<ICategory>;
  CategoriesOperations: typeof CategoriesOperations;
  search: IQueryParams;
  pushSearch: (arg0: IQueryParams) => void;
};

class CategoriesSearch extends PureComponent<
  Props,
  {
    value: string;
    selected: Array<ICategory>;
  }
> {
  static defaultProps = {
    categories: [],
    CategoriesOperations: {},
  };

  state = {
    value: '',
    selected: [],
  };

  componentDidMount() {
    const {
      CategoriesOperations: { getAllCategories },
    } = this.props;
    getAllCategories();
  }

  onChange = (ev: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ value: ev.target.value });
  };

  onSelect = (category: ICategory) => {
    const { pushSearch, search } = this.props;

    this.setState(
      prevState => ({
        selected: prevState.selected.concat(category),
      }),

      () => {
        const { selected } = this.state;
        const categoryQueries = [];
        selected.map(obj => categoryQueries.push(obj.name));
        pushSearch({ ...search, category2: categoryQueries.join(',') });
      },
    );
  };

  onRemove = (category: ICategory) => {
    const { pushSearch, search } = this.props;

    this.setState(
      prevState => ({
        selected: prevState.selected.filter(obj => obj.id !== category.id),
      }),

      () => {
        const { selected } = this.state;
        const categoryQueries = [];
        if (!selected.length) {
          const { category2, ...rest } = search;
          return pushSearch({ ...rest });
        }
        selected.map(obj => categoryQueries.push(obj.name));
        return pushSearch({ ...search, category2: categoryQueries.join(',') });
      },
    );
  };

  render() {
    const { value, selected } = this.state;
    const { categories } = this.props;

    return (
      categories &&
      !!categories.length && (
        <div>
          <Pills
            id="categories"
            name="categories"
            label="Categories"
            suggestions={filterCategories(categories, value, selected)}
            selected={selected}
            onChange={this.onChange}
            value={value}
            onSelect={category => this.onSelect(category)}
            onRemove={category => this.onRemove(category)}
          />
        </div>
      )
    );
  }
}

export default compose(
  connect(state => ({
    categories: selectors.categories(state),
  })),

  inject({ CategoriesOperations }),
)(CategoriesSearch);

const filterCategories = (arr, value, selected, limit = 10) => {
  const result = arr.filter(x => !selected.find(y => x.id === y.id)).filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) > -1);

  return result.length > limit ? result.slice(0, limit) : result;
};
