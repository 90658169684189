import * as React from 'react';
import styled from 'styled-components';

import { IApiBannerCreative } from 'app/api/bannerManagementV2/creatives/types';
import { DrawerError } from 'app/components';
import withAutocomplete from 'app/hocs/withReporting/withAutocomplete';
import withDropdown from 'app/hocs/withReporting/withDropdown';
import { IPaging } from 'app/hocs/withReporting';
import withTextField from 'app/hocs/withReporting/withTextField';
import { Dropdown, Paging, SearchBar, Spinner } from 'app/midgarComponents';
import { Footer, SearchContainer, SearchControls as _SearchControls } from 'app/midgarComponents/ReportingPage';
import { IQueryParams } from 'app/types';
import { IWidgetType } from 'app/types/BannerManagement';

import CreativesTable from './CreativesTable';
import { searchBarDefs, searchByCreator, searchByPlatformDefiner, searchBySiteDefiner, searchByWidgetType } from './search.config';

const WidgetTypeAutocomplete = withAutocomplete(searchByWidgetType);

const CreatorTextField = withTextField(searchByCreator);

export type Props = {
  allPlatforms: Array<string>;
  allSites: Array<string>;
  bannerCreatives: Array<IApiBannerCreative>;
  error: Record<string, any>;
  goToPage: (arg0: number) => unknown;
  handleSearchPhraseChange: (arg0: string) => void;
  handleSearchTypeChange: (arg0: string) => void;
  loading: boolean;
  onSelect?: (...args: Array<any>) => any;
  openPreviewModal?: (...args: Array<any>) => any;
  paging: IPaging;
  pushSearch: (...args: Array<any>) => any;
  pushSearchBar: (arg0: { searchPhrase: string; selectedSearchType: string }) => void;
  search: IQueryParams;
  searchPhrase: string;
  selectedSearchType: string;
  timezone: string;
  widgetTypes: Array<IWidgetType>;
  widgetTypesError: string | null | undefined;
  widgetTypesLoading: boolean;
};

export const BannerCreativesReportBody = (props: Props) => {
  const {
    allPlatforms,
    allSites,
    bannerCreatives,
    error,
    handleSearchPhraseChange,
    handleSearchTypeChange,
    goToPage,
    loading,
    onSelect,
    openPreviewModal,
    paging,
    pushSearch,
    pushSearchBar,
    search,
    searchPhrase,
    selectedSearchType,
    timezone,
    widgetTypes,
    widgetTypesError,
    widgetTypesLoading,
  } = props;

  const searchDefByPlatform = searchByPlatformDefiner(allPlatforms);
  const PlatformDropdown = withDropdown(searchDefByPlatform)(Dropdown);

  let SiteDropdown = null;
  if ((allSites || []).length > 1) {
    const searchDefBySite = searchBySiteDefiner(allSites);
    SiteDropdown = withDropdown(searchDefBySite)(Dropdown);
  }

  return (
    <>
      {widgetTypesError && (
        <DrawerError error="Error while retrieving widget types" errorDetails={widgetTypesError} allowDismiss={false} defaultExpanded>
          <p>Cannot search by widget types, platforms, or sites.</p>
        </DrawerError>
      )}

      <SearchContainer>
        <SearchControls>
          <CreatorTextField pushSearch={pushSearch} search={search} />

          {widgetTypesLoading ? (
            <Spinner />
          ) : (
            !widgetTypesError && (
              <>
                <WidgetTypeAutocomplete
                  suggestions={widgetTypes && widgetTypes.map(({ name, label }) => ({ id: name, name: label }))}
                  pushSearch={pushSearch}
                  search={search}
                />

                {SiteDropdown && <SiteDropdown search={search} pushSearch={pushSearch} />}

                <PlatformDropdown search={search} pushSearch={pushSearch} />
              </>
            )
          )}
        </SearchControls>

        <SearchBar
          searchPhrase={searchPhrase}
          selectedSearchType={selectedSearchType}
          searchTypes={searchBarDefs}
          handleSearchPhraseChange={handleSearchPhraseChange}
          handleSearchTypeChange={handleSearchTypeChange}
          pushSearchBar={pushSearchBar}
        />
      </SearchContainer>

      {error && error.msg && (
        <DrawerError error="Error while retrieving creatives" errorDetails={error.msg} allowDismiss={false} defaultExpanded>
          <p>If this error persists, please report the folllowing error message:</p>
        </DrawerError>
      )}

      <CreativesTable
        bannerCreatives={bannerCreatives}
        loading={loading}
        timezone={timezone}
        onSelect={onSelect}
        openPreviewModal={openPreviewModal}
      />

      <Footer data-qa="banner-creatives-footer">
        <Paging {...paging} handleGoToPage={goToPage} />
      </Footer>
    </>
  );
};

const SearchControls = styled(_SearchControls)`
  & > div,
  & > section {
    width: 16rem;
  }
`;
