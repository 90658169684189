import { segmentCreationPermissions } from 'configs/permissions';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';

const actions = {
  clone: { id: 'CLONE', name: 'Clone' },
  newTab: { id: 'NEWTAB', name: 'Open in New Tab' },
};

const getActions = (audienceType, isPermitted: IsPermittedFn) =>
  isPermitted(segmentCreationPermissions) && audienceType !== 'STATIC' ? [actions.newTab, actions.clone] : [actions.newTab];

export { actions, getActions };
