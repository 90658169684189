import * as React from 'react';
import PropTypes from 'prop-types';

import { Add as AddIcon } from '@material-ui/icons';
import Button from '@material-ui/core/Button/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '../Autocomplete';

// TODO: During loading, hide added tags

const styles = {
  chip: {
    margin: 4,
  },
};

export const TagsEdit = ({
  classes = {},
  id = 'tagText',
  label = 'Tag',
  tagText = '',
  addedTags = [],
  allTagNames = [],
  loading = false,
  handleChangeTagText,
  handleAddTag,
  handleRemoveTag,
}) => (
  <section>
    <div className={classes.autoComplete}>
      {/* TODO: Add "Tags" label (it only shows when nothing is entered. Should be the same as other @material-ui/core elements) */}
      <Autocomplete
        id={id}
        placeholder={label}
        value={tagText}
        items={allTagNames}
        handleChange={handleChangeTagText}
        handleSuggestionSelected={handleAddTag}
      />

      {loading ? (
        <Button disabled className={classes.button}>
          <CircularProgress size={30} />
        </Button>
      ) : (
        <Button color="primary" disabled={!tagText || !tagText.length} onClick={handleAddTag} className={classes.button}>
          Add
          <AddIcon />
        </Button>
      )}
    </div>

    <div>
      {!loading &&
        addedTags.map(thisTag =>
          thisTag ? (
            <Chip id={thisTag.id} key={thisTag.id} label={thisTag.name} style={styles.chip} onDelete={() => handleRemoveTag(thisTag.id)} />
          ) : null,
        )}
    </div>
  </section>
);

TagsEdit.propTypes = {
  classes: PropTypes.shape({}),
  id: PropTypes.string,
  label: PropTypes.string,
  tagText: PropTypes.string,
  addedTags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),

  allTagNames: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  handleChangeTagText: PropTypes.func.isRequired,
  handleAddTag: PropTypes.func.isRequired,
  handleRemoveTag: PropTypes.func.isRequired,
};
