import { compose, withProps, withHandlers } from 'recompose';
import { sort } from 'app/utilities/idAndName';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import { SelectAsMenu } from './SelectAsMenu';

const doSort = withProps(({ items, sorted }) => ({
  items: items && sorted !== false ? sort(items) : items,
}));

const addHandlers = withHandlers({
  handleChange:
    ({ handleChange }) =>
    ev => {
      if (handleChange) {
        handleChange(ev.target.value);
      }
    },
});

const enhance = compose(doSort, addHandlers, withStyles(styles, { withTheme: true }));

export default enhance(SelectAsMenu);
