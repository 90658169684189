import * as React from 'react';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { lighten } from 'polished';

import { sc } from 'app/styles';
import { ICampaignPriority } from 'app/types';

import { LIST_MANUAL, PUSH_TYPES } from './constants';
import { IListVariant } from './constants';
import { Highlight, Pill as _Pill } from '../common';

type Props = {
  element: ICampaignPriority;
  isSelected: boolean;
  onSelect: (arg0: IListVariant, arg1: ICampaignPriority) => void;
  search?: string;
  showPriority?: boolean;
  showRecurring?: boolean;
  variant: IListVariant;
};

export default class CampaignPriorityRow extends React.PureComponent<Props> {
  handleCheckClicked = () => {
    const { element, onSelect, variant } = this.props;
    onSelect(variant, element);
  };

  render() {
    const { element, isSelected, search, showPriority, showRecurring, variant } = this.props;
    const { id, name, priority, pushType } = element;

    return (
      <Container>
        {(variant === LIST_MANUAL || showPriority) && <PriorityCol>{element.priority >= 0 ? String(priority) : 'N/A'}</PriorityCol>}

        <Column>
          <Checkbox checked={isSelected} onClick={this.handleCheckClicked} data-qa={`priority-row-checkbox`} />
        </Column>

        <NameCol>
          <Name>
            <Highlight text={name} highlight={search} />
          </Name>
          <Row>
            <IdAndPushTypeContainer>
              <Id>
                ID:
                <Highlight text={String(id)} highlight={search} />
              </Id>
              {pushType && <PushTypePill data-qa="push-type">{PUSH_TYPES[pushType]}</PushTypePill>}
            </IdAndPushTypeContainer>

            {showRecurring && (
              <Recurring recurring={element.isRecurring}>
                <Pill recurring={element.isRecurring}>{element.isRecurring ? 'Recurring' : 'One Time'}</Pill>
              </Recurring>
            )}
          </Row>
        </NameCol>
      </Container>
    );
  }
}

const Column = styled.div`
  font-size: ${sc.fontSizeSmall};
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 4rem;
  // opacity: ${props => (props.inStack ? '0.1' : '1')};
  border-bottom: 1px solid ${sc.greyLighter};
  // z-index: ${props => (props.selected ? '99' : '20')};
`;

const Id = styled.div`
  color: ${sc.grey};
  font-size: ${sc.fontSizeSmaller};
  font-weight: 400;
  width: 4rem;
`;

const Name = styled.div`
  margin-bottom: ${sc.gutterSmallest};
`;

const NameCol = styled(Column)`
  flex-grow: 1;
`;

const Pill = styled(_Pill)`
  background-color: ${props => (props.recurring ? lighten(0.2, sc.primary) : sc.greyLighter)};
  color: ${props => (props.recurring ? '#fff' : sc.headingColor)};
  font-size: ${sc.fontSizeSmallest};
  text-transform: uppercase;
`;

const PushTypePill = styled(_Pill)`
  background-color: ${lighten(0.2, sc.primary)};
  border-radius: 0px;
  width: 6rem;
`;

const PriorityCol = styled(Column)`
  width: 2rem;
  text-align: center;
`;

const Recurring = styled.div`
  width: 4.5rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${sc.gutterSmall};
`;

const IdAndPushTypeContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
