import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { storefrontActions } from 'app/ducks';
import { Spinner, TextField } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IStorefront } from 'app/types/BannerManagement';

import { missingProps } from './validate';

type Props = {
  className?: string;
  loading: boolean;
  readOnly: boolean;
  setStorefrontField: (arg0: Record<string, string>) => (...args: Array<any>) => any;
  shouldValidate?: boolean;
  storefront: IStorefront | null | undefined;
};

class Storefront extends React.PureComponent<Props> {
  handleChangeTextValue = (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const { setStorefrontField } = this.props;
    setStorefrontField({ [ev.target.name]: ev.target.value });
  };

  render() {
    const { className, loading, readOnly, shouldValidate, storefront } = this.props;

    const missingFields = shouldValidate ? missingProps(storefront) : [];

    return (
      <section className={className}>
        {loading ? (
          <Spinner />
        ) : (
          <InputContainer>
            <TextField
              disabled={readOnly}
              error={missingFields.includes('name')}
              label="Name"
              name="name"
              onChange={this.handleChangeTextValue}
              required
              value={(storefront || {}).name || ''}
            />
          </InputContainer>
        )}
      </section>
    );
  }
}

const mapStateToProps = ({
  bannerManagement: {
    storefront: { loading, storefront },
  },
}) => ({
  loading,
  storefront,
});

const mapDispatchToProps = {
  setStorefrontField: storefrontActions.setStorefrontField,
};

export default connect(mapStateToProps, mapDispatchToProps)(Storefront);

const InputContainer = styled.section`
  & > div,
  & > section {
    margin-bottom: ${sc.gutter};
  }
`;
