import styled from 'styled-components';
import { sc } from 'app/styles';

import Paper from '../Paper';

export const TypeaheadWrapper = styled.div`
  position: relative;
  min-width: 25vw;
`;

export const SuggestionContainer = styled(Paper)`
  height: ${({ containerHeight = 300 }) => containerHeight}px;
  box-shadow: 0 0 3px #ccc;
  overflow-y: scroll;
  position: absolute;
  min-width: 20rem;
  width: 100%;
  top: 40px;
  left: 0;
  z-index: 9000;
`;

export const Item = styled.div`
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover,
  &:focus {
    color: ${sc.primary};
  }
`;
