// == Banners ==
export const BANNERS = '/banners';
export const BANNERS_BINDER = '/banners/storefronts/:storefrontId/binder/:viewId?';
export const BANNERS_BINDER_ITEM_OVERVIEW = '/banners/storefronts/:storefrontId/binder/:viewId/viewItem/:viewItemId';
export const BANNERS_CREATIVE = '/banners/creatives';
export const BANNERS_CREATIVE_EDIT = '/banners/creatives/edit/:id';
export const BANNERS_CREATIVE_NEW = '/banners/creatives/new/:id?';
export const BANNERS_STOREFRONTS_EDIT = '/banners/storefronts/edit/:storefrontId?';
export const BANNERS_STOREFRONTS_REPORT = '/banners/storefronts';

export const bannersRoutes = [
  BANNERS,
  BANNERS_BINDER,
  BANNERS_BINDER_ITEM_OVERVIEW,
  BANNERS_CREATIVE,
  BANNERS_CREATIVE_EDIT,
  BANNERS_CREATIVE_NEW,
  BANNERS_STOREFRONTS_EDIT,
  BANNERS_STOREFRONTS_REPORT,
];

// == Campaigns ==
export const CAMPAIGNS = '/campaigns';
export const CAMPAIGNS_CREATE = '/campaigns/:type(new|edit)/:id?';
export const CAMPAIGNS_NEW = 'campaigns/new';
export const CAMPAIGNS_OVERVIEW = '/campaigns/:type(new|edit)/:id?/overview';
export const CAMPAIGNS_OVERVIEW_ID = '/campaigns/:id';

// == Cashback Management ==
export const CASHBACK_MANAGEMENT = '/cashback/';
export const CASHBACK_MANAGEMENT_BANNER_NEW = '/cashback/banner/new';
export const CASHBACK_MANAGEMENT_BANNER_OVERVIEW = '/cashback/banner/:id';
export const CASHBACK_MANAGEMENT_BANNERS = '/cashback/banners';
export const CASHBACK_MANAGEMENT_EVENT_SCHEMAS = '/cashback/event-schema';
export const CASHBACK_MANAGEMENT_GLOBAL_LIMITS = '/cashback/global-payment-limits';
export const CASHBACK_MANAGEMENT_STACKABLE_GROUP = '/cashback/stackable-group/:groupId';
export const CASHBACK_MANAGEMENT_STACKABLE_GROUPS = '/cashback/stackable-groups';
export const CASHBACK_MANAGEMENT_USER_BUDGET_TOKENS = '/cashback/user-budget';

export const CHANNELS = '/channels';
export const CHANNELS_BANNER_DEBUG = '/channels/:section(banner)/debug/:tab(upcoming|delivered)';
export const CHANNELS_BANNER_DEBUG_DEFAULT = '/channels/banner/debug';
export const CHANNELS_BANNER_DEBUG_UPCOMING = '/channels/banner/debug/upcoming';
export const CHANNELS_EMAIL_TEMPLATE_CREATION = '/channels/email/new';
export const CHANNELS_EMAIL_TEMPLATE_EDIT = '/channels/email/:id';
export const CHANNELS_EMAIL_TEMPLATE_REPORTING = '/channels/email';

export const FEATURES = '/features';

export const MASTERPLAN = '/masterplan';

export const SEGMENTS_CREATION = '/segments/new';
export const SEGMENTS_LANDING = '/segments';
export const SEGMENTS_OVERVIEW = '/segments/:id';

export const SEGMENTS_CREATION_2 = '/audience/new';
export const SEGMENTS_LANDING_2 = '/audience';
export const SEGMENTS_OVERVIEW_2 = '/audience/:id';

export const USERS = '/users';
export const USERS_COPY = '/users/copy/:email';
export const USERS_EDIT = '/users/edit/:email';
export const USERS_NEW = '/users/new';

export const SIGN_OUT = '/logout';
