export const styles = theme => ({
  desktop: {
    height: 'calc(100% - 44px)',
    minHeight: '500px',
    width: '100%',
    position: 'relative',
    padding: '1rem 0',
  },

  mobile: {
    border: `1px solid ${theme.palette.primary.dark}`,
    padding: '1rem',
    background: theme.palette.grey[500],
    margin: '0 auto',
    borderRadius: 30,
    width: 330,
    height: 600,
    position: 'relative',
  },

  iframe: {
    height: '100%',
    width: '100%',
    border: 0,
    background: theme.palette.common.white,
    '& > html': {
      height: '100%',
      width: '100%',
    },

    '& > body': {
      height: '100%',
      width: '100%',
    },
  },
});
