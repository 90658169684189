import React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { Icon } from 'app/midgarComponents';
import { FormControlLabel as _FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { deviceTypeValues, pushTypes } from '../Push2Constants';

type Props = {
  type: string;
  selected: Array<string>;
  handleSelect: (...args: Array<any>) => any;
};

const isPlatformIndependent = type => pushTypes.inbox.id === type;

export const PlatformSelection = ({ selected, handleSelect, type }: Props) => (
  <div data-qa="platform-selection">
    <FormGroup row>
      {Object.values(deviceTypeValues).map(({ label, value }) => (
        <FormControlLabel
          key={value}
          control={
            <Checkbox
              disabled={isPlatformIndependent(type)}
              value={value}
              checked={selected.includes(value) || isPlatformIndependent(type)}
              onChange={ev => handleSelect(ev.target.value)}
            />
          }
          label={label}
        />
      ))}
    </FormGroup>
    {isPlatformIndependent(type) && (
      <Info data-qa="device-independent-info">
        <Icon name="info-outline" />
        <Uppercase>{type} </Uppercase>
        push type is device independent.
      </Info>
    )}
  </div>
);

const FormControlLabel = styled(_FormControlLabel)`
  & span {
    color: ${sc.grey};
  }
`;

const Info = styled.p`
  color: ${sc.grey};
  font-size: ${sc.fontSizeSmall};
  font-style: italic;
  padding: ${sc.gutterSmaller} 0;
`;

const Uppercase = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  padding-left: ${sc.gutterSmallest};
`;
