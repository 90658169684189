import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import withAppConfig from 'app/decorators/withAppConfig';
import { urlCreativeNew } from 'app/features/BannerManagement/routeUtils';
import * as permissions from 'configs/permissions';
import * as RoutesConfig from 'configs/routes';

import { Paper, Icon } from './midgarComponents';
import { ConnectedUser } from './ducks';

import { sc } from './styles';

const links = [
  {
    label: 'New Campaign',
    to: RoutesConfig.CAMPAIGNS_NEW,
    key: 'new-campaign',
    icon: 'campaign',
    permissions: [...permissions.campaignCreationPermissions, ...permissions.campaignDraftPermissions],
  },

  {
    label: 'New Audience',
    to: RoutesConfig.SEGMENTS_CREATION,
    key: 'new-segment',
    icon: 'account',
    permissions: permissions.segmentCreationPermissions,
  },

  {
    label: 'New Banner',
    to: urlCreativeNew(),
    permittedRoute: RoutesConfig.BANNERS_CREATIVE_NEW,
    key: 'new-banner',
    icon: 'folder-special',
    permissions: permissions.bannerManagementEditPermissions,
  },
];

class Landing extends PureComponent<{
  appConfig: Record<string, any>;
}> {
  renderLink = link => (
    <LinkContainer key={link.key}>
      <Link to={link.to} data-qa={link.key}>
        <Icon name={link.icon} size="50" data-qa={link.icon} color={sc.primary} />
        <Label>{link.label}</Label>
      </Link>
    </LinkContainer>
  );

  renderExternalLink = link => (
    <LinkContainer key={link.key}>
      <a href={link.to} target="_blank" rel="noopener noreferrer">
        <Icon name={link.icon} size="50" color={sc.primary} data-qa={link.icon} />
        <Label>{link.label}</Label>
      </a>
    </LinkContainer>
  );

  render() {
    const {
      appConfig: { supportedRoutes, analyticsUrl },
    } = this.props;

    const externalLinks = [
      {
        label: 'Analytics',
        to: analyticsUrl,
        key: 'analytics',
        icon: 'grid',
      },
    ];

    return (
      <Container>
        <Links>
          <ConnectedUser>
            {({ isPermitted }) =>
              links.map(
                link => isPermitted(link.permissions) && supportedRoutes.includes(link.permittedRoute || link.to) && this.renderLink(link),
              )
            }
          </ConnectedUser>
        </Links>

        {analyticsUrl && (
          <>
            <Divider>
              <span>External Modules</span>
            </Divider>
            <Links>{externalLinks.map(link => this.renderExternalLink(link))}</Links>
          </>
        )}
      </Container>
    );
  }
}

export default withAppConfig(Landing);

const Container = styled(Paper)`
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 2rem;

  & > h1 {
    color: ${sc.primary};
    font-size: 30px;
    font-weight: 400;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${sc.greyLight};
  position: relative;
  width: 50%;
  margin: 0 auto;

  & span {
    color: ${sc.grey};
    background: white;
    padding: 0 10px;
    position: relative;
    top: -8px;
  }
`;

const Links = styled.ul`
  list-style: none;
  margin: 0;
  padding: 4rem 0;
  width: 100%;
`;

const LinkContainer = styled.li`
  display: inline-block;
  width: 20%;
  margin: 0 3%;
  vertical-align: top;

  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: 0 1px 5px ${sc.overlayLighter};
  background-color: #fff;

  text-align: center;
  transition: all 150ms ease-in-out;

  & > a {
    display: block;
    text-decoration: none;
    padding: 20px 10px;
  }

  &:hover {
    margin-top: -4px;
  }
`;

const Label = styled.p`
  color: ${sc.bodyColor};
  font-size: 14px;
  margin-top: 10px;
`;
