import { withStateHandlers, withHandlers, withProps, compose } from 'recompose';

import { viewOr } from 'ramda-adjunct';
import debounce from 'lodash.debounce';

import { transformShape, sanitizeString, tokenize, R } from 'app/helpers/RamdaHelpers/helpers';

import { FeatureMetadataKeyNames } from 'app/helpers/FeatureMetadataHelpers/common';
import { withStyles } from '@material-ui/core/styles';
import { BreadcrumbButton } from './BreadcrumbButton';

import { StandaloneBreadcrumbLabel as standaloneVersion } from './StandaloneBreadcrumbLabel';
import { get } from './featureSearchCache';

import { styles } from './styles';

const minNumOfChars = 2;
const hasLessThan = size => R.compose(R.gt(size), R.length);
const isTooShort = hasLessThan(minNumOfChars);

const featureKeyOrAlias = dict => key => viewOr(key, R.lensPath([key, FeatureMetadataKeyNames.featureLabel]), dict);

const featureDesc = dict => key => R.view(R.lensPath([key, FeatureMetadataKeyNames.featureDesc]), dict);

const filterThenConcat = R.curry((phrase, pathDict, originDict) => {
  const words = tokenize(phrase);
  if (R.isEmpty(words)) {
    return [];
  }

  const matching = sentence => R.all(a => R.contains(a, sentence), words);

  const [keyOrAlias, desc] = R.map(R.applyTo(originDict), [featureKeyOrAlias, featureDesc]);

  return R.values(
    R.mapObjIndexed(
      (ancestors, featureKey) => transformShape([R.append(R.PAPH, ancestors), keyOrAlias, desc], featureKey),
      R.pickBy((_, featureKey) => matching(R.toLower(keyOrAlias(featureKey))), pathDict),
    ),
  );
});

const stateHandlers = withStateHandlers(
  () => ({
    searchPhrase: '',
    matchingFeatures: [],
  }),

  {
    handleSearch:
      ({ searchPhrase, ...state }, { pathDict, originDict }) =>
      input => {
        const newPhrase = sanitizeString(input);

        return {
          ...state,
          searchPhrase: newPhrase,
          matchingFeatures: isTooShort(newPhrase) ? [] : get(R.toLower(newPhrase), filterThenConcat(R.PAPH, pathDict, originDict)),
          // filterThenConcat(R.toLower(newPhrase), pathDict, originDict),
        };
      },
  },
);

const debounceTime = 500;

const handlers = withHandlers({
  debouncedSearch: ({ handleSearch }) => debounce(handleSearch, debounceTime),
});

const extraProps = withProps({
  minNumOfChars,
  isTooShort,
});

const addStyles = withStyles(styles);

const enhance = compose(extraProps, stateHandlers, handlers, addStyles);
export default enhance(BreadcrumbButton);

const StandaloneBreadcrumbLabel = addStyles(standaloneVersion);

export { StandaloneBreadcrumbLabel };
