import * as React from 'react';

import { EmailHeader } from 'app/components';

type Props = {
  title: string;
  subject?: string;
  sender: string;
  senderName?: string;
  replyTo?: string;
  replyToName?: string;
  allEmailSenders?: Array<
    Partial<{
      id: number | string;
      name: string;
    }>
  >;

  emailSendersLoading?: boolean;
  setSubject: (arg0: string) => void;
  setSender: (arg0: string) => void;
  setSenderName: (arg0: string) => void;
  setReplyTo: (arg0: string) => void;
  setReplyToName: (arg0: string) => void;
  handleTestDelivery: () => void;
};

export default class EmailDetails extends React.PureComponent<Props> {
  render() {
    const {
      title,
      subject,
      allEmailSenders,
      emailSendersLoading,
      sender,
      senderName,
      replyTo,
      replyToName,
      handleTestDelivery,
      setSubject,
      setSender,
      setSenderName,
      setReplyTo,
      setReplyToName,
    } = this.props;
    return (
      <EmailHeader
        title={title}
        subject={subject}
        allEmailSenders={allEmailSenders}
        emailSendersLoading={emailSendersLoading}
        sender={sender}
        senderName={senderName}
        replyTo={replyTo}
        replyToName={replyToName}
        handleTestDelivery={handleTestDelivery}
        setSubject={setSubject}
        setSender={setSender}
        setSenderName={setSenderName}
        setReplyTo={setReplyTo}
        setReplyToName={setReplyToName}
      />
    );
  }
}
