import { withStateHandlers, compose, lifecycle } from 'recompose';

import { ExpandableTableRow } from './ExpandableTableRow';

const addStateHandlers = withStateHandlers(() => ({ expanded: false }), {
  handleExpandClick:
    ({ expanded }) =>
    () => ({
      expanded: !expanded,
    }),
});

const rowMount = lifecycle({
  componentDidMount() {
    if (this.props.onRowMount) {
      this.props.onRowMount(this.props.row);
    }
  },
});

const enhance = compose(addStateHandlers, rowMount);

export default enhance(ExpandableTableRow);
