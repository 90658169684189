import React from 'react';
import PropTypes from 'prop-types';
import { TableFooter, TableRow, TablePagination } from '@material-ui/core';

import { isNeitherNilNorEmpty } from 'app/helpers/RamdaHelpers/helpers';

import { addComponentPropsIf } from 'app/helpers/ComponentHelpers/helpers';

/*
  TODO: a bug in TablePagination requires this workaround.
  the behaviour is, when it's 0 (no data), the pagination control is
  not completely disabled where it should be. VERIFY whether current material-ui-1.x already fixed this.
*/
const workaround = rowsPerPage => (rowsPerPage === 0 ? 1 : rowsPerPage);

export const TableFooterWithPagination = ({
  numberOfRows,
  pageIndex,
  numberOfColumns,
  rowsPerPage,
  rowsPerPageOptions = [5, 10, 25],
  handleChangePage,
  handleChangeRowsPerPage,
  customPaginationActions,
  canExpandRow,
}) => (
  <TableFooter>
    <TableRow>
      <TablePagination
        {...{
          colSpan: numberOfColumns + (canExpandRow ? 1 : 0),
          count: numberOfRows,
          page: pageIndex,
          rowsPerPage: workaround(rowsPerPage),
          onChangePage: handleChangePage,
          onChangeRowsPerPage: handleChangeRowsPerPage,
          rowsPerPageOptions,
        }}
        {...addComponentPropsIf(isNeitherNilNorEmpty(customPaginationActions), {
          ActionsComponent: customPaginationActions,
        })}
      />
    </TableRow>
  </TableFooter>
);

TableFooterWithPagination.propTypes = {
  numberOfRows: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  numberOfColumns: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  customPaginationActions: PropTypes.func,
  canExpandRow: PropTypes.bool.isRequired,
};
