import * as React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const CompletionStatusContainer = styled.div`
  padding: 25px;
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  p {
    color: #aaa;
    margin-bottom: 0.5rem;
  }
`;

const ProgressContainer = styled.div`
  width: 100%;
  position: relative;
  height: 1.5rem;
  background-color: #ccc;
`;

const Progress = styled.div`
  width: ${({ completed }) => completed}%;
  max-width: 100%;
  background-color: #00b9f5;
  height: 1.5rem;
  transition: width 300ms ease-in-out;
`;

export const CompletionStatus = ({ completed }) => (
  <CompletionStatusContainer>
    <h2>Campaign Completion</h2>
    <p>{completed}%</p>
    <ProgressContainer>
      <Progress completed={completed} />
    </ProgressContainer>
  </CompletionStatusContainer>
);

CompletionStatus.propTypes = {
  completed: PropTypes.string.isRequired,
};
