import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Warning as WarningIcon } from '@material-ui/icons';

import { Tooltip } from 'app/components';
import { ConnectedUser } from 'app/ducks';
import { ConnectedCampaignForOverview } from 'app/ducks/campaigns';
import { findChannelSetup } from 'app/ducks/configStore/utils';
import { ConfirmModal } from 'app/midgarComponents';
import { IAppConfig } from 'configs/apps/types';
import channelConfig from 'configs/channels/channel.config';

import Actions from './Actions';
import { StyledHeader, Arrow, Link, Title, ActionButton } from './styledComponents';

type Props = {
  appConfig: IAppConfig;
  createCampaign: (...args: Array<any>) => any;
  goBackHandler: (...args: Array<any>) => any;
  inEditMode: boolean;
  mediumId: number;
  openModal: (...args: Array<any>) => any;
  state?: string;
  title: string;
  validated: boolean;
  validationFailures: Array<any>;
  originalCampaign: Record<string, any>;
  saveAsDraft: (...args: Array<any>) => any;
};

type State = {
  isConfirmModalOpen: boolean;
};

export default class Header extends PureComponent<Props, State> {
  state = {
    isConfirmModalOpen: false,
    isValidationModelOpen: false,
  };

  handleConfirmCreate = async () => {
    const { createCampaign } = this.props;
    const success = await createCampaign();
    if (!success) {
      this.closeConfirmModal();
    }
  };

  openConfirmModal = () => {
    this.setState({ isConfirmModalOpen: true });
  };

  closeConfirmModal = () => {
    this.setState({ isConfirmModalOpen: false });
  };

  render() {
    const { isConfirmModalOpen } = this.state;
    const {
      title,
      openModal,
      mediumId,
      goBackHandler,
      inEditMode,
      createCampaign,
      validated,
      validationFailures,
      appConfig,
      state,
      originalCampaign,
      saveAsDraft,
      ...rest
    } = this.props;
    const setMedium = id => ({ type: channelConfig[id].name.toUpperCase() });
    const currentTenantChannelConfig = findChannelSetup(appConfig, channelConfig[mediumId].name) || {};
    const tooltipMessage = currentTenantChannelConfig.message || {};
    const disabled = typeof currentTenantChannelConfig === 'object' && !!currentTenantChannelConfig.readOnly;

    const actions = inEditMode ? undefined : (
      <ConnectedCampaignForOverview>
        {params => (
          <ConnectedUser>
            {({ isPermitted }) => (
              <Actions
                canEdit={false}
                validationFailures={validationFailures}
                channelConfig={channelConfig[mediumId]}
                isPermitted={isPermitted}
                medium={setMedium(mediumId)}
                openModal={openModal}
                state={state}
                {...params}
                {...rest}
              />
            )}
          </ConnectedUser>
        )}
      </ConnectedCampaignForOverview>
    );

    return (
      <>
        <StyledHeader>
          <section>
            <Arrow />
            <Link data-qa="campaign-back-link" onClick={goBackHandler}>
              {inEditMode ? 'Back to Campaign Edit' : 'Back to Campaigns'}
            </Link>
            <Title data-qa="campaign-overview-title" highlight={originalCampaign && title !== originalCampaign.name}>
              {title}
            </Title>
          </section>
          <ConnectedUser>
            {({ isPermitted }) => (
              <section>
                {inEditMode ? (
                  <>
                    {!!mediumId && isPermitted(channelConfig[mediumId].permission) && (
                      <ActionButton type="secondary" onClick={saveAsDraft} data-qa="overview-save-as-draft-btn">
                        Save as Draft
                      </ActionButton>
                    )}

                    {!!mediumId && isPermitted(channelConfig[mediumId].createPermission) && (
                      <Tooltip open={disabled} title={tooltipMessage.unavailable || ''}>
                        <ActionButton
                          type="primary"
                          onClick={this.openConfirmModal}
                          disabled={!validated || disabled}
                          data-qa="create-campaign-btn"
                        >
                          Create Campaign
                        </ActionButton>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  actions
                )}
              </section>
            )}
          </ConnectedUser>

          <ConfirmModal
            title="Confirmation"
            confirmText="Create Campaign"
            isOpen={isConfirmModalOpen}
            onClose={this.closeConfirmModal}
            onConfirm={this.handleConfirmCreate}
          >
            A campaign is uneditable once created. Do you wish to continue?
          </ConfirmModal>
        </StyledHeader>

        {validationFailures.length > 0 && state === undefined && (
          <AlertBody>
            <Alert>
              <WarningIcon />
              {validationFailures.map((failure, key) => (
                <span key={key}>{failure}</span>
              ))}
            </Alert>
          </AlertBody>
        )}
      </>
    );
  }
}

const Alert = styled.div`
  padding: 20px;
  background: hsl(0, 100%, 100%);
  background-color: rgb(253, 236, 234);
  display: flex;
`;

const AlertBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsl(0, 100%, 100%);
  border-bottom: 1px solid #dbe2e9;
`;
