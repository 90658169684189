import { ISuggestion } from 'app/types';

type Props = {
  permittedStorefronts: Array<ISuggestion> | null | undefined;
  storefrontId: number | null | undefined;
  storefronts: Array<ISuggestion> | null | undefined;
};

export const checkStorefront = ({ permittedStorefronts, storefrontId, storefronts }: Props) => {
  const selectedStorefront = storefrontId ? storefronts?.find(s => s.id === storefrontId) : undefined;
  const storefrontExists = !!storefrontId && !!selectedStorefront;

  return {
    isStorefrontPermitted: storefrontExists && !!permittedStorefronts?.find(s => s.id === storefrontId),
    selectedStorefront,
    storefrontExists,
  };
};
