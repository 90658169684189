/* eslint-disable import/no-cycle */

import { oneTimeTrigger, recurringTrigger, triggerTypes } from 'app/utilities/constants';
// eslint-disable-next-line import/no-cycle
import FullSchedule from 'app/features/Campaigns/Schedule/FullSchedule';
import { defaultMediumId } from './mediumIds';
import { IChannelConfig } from './types';

export default {
  mediumId: defaultMediumId,
  name: 'default',
  display: 'default',
  selected: false,
  requiredFields: [],
  recurring: true, // TODO: Not in API
  triggers: [oneTimeTrigger, recurringTrigger],
  defaultTrigger: triggerTypes.oneTime,
  CreationComponent: () => null,
  OverviewComponent: () => null,
  ScheduleComponent: FullSchedule,
  creative: 'defaultCreative',
  permission: [],
  createPermission: [],
} as IChannelConfig;
