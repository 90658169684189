import { map } from 'ramda';

export const campaigns = ({
  campaigns: {
    campaigns: { byId, allIds },
  },
}) => allIds.map(id => byId[id]);

// TODO: Remove mapCampaigns
export const mapCampaigns = ({ byId, allIds }) =>
  map(
    id => ({
      ...byId[id],
    }),

    allIds,
  );

export const paging = ({
  campaigns: {
    campaigns: { number, first, last, totalElements, size },
  },
}) => ({
  number,
  first,
  last,
  totalElements,
  size,
});
