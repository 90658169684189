import { withProps, compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { Table } from './Table';

import TablePaginationActions from '../TablePaginationActions';

const addStyles = withStyles(styles);

const addProps = withProps(() => ({
  customPaginationActions: addStyles(TablePaginationActions),
}));

const enhance = compose(addStyles, addProps);

export default enhance(Table);
