import * as React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';

import { ImageUpload, Input as _Input } from 'app/midgarComponents';
import { ApiImageUpload } from 'app/helpers/ImageUploadManager/ApiImageUpload';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { DeeplinkSelection } from './../DeeplinkSelection';
import {
  updateDeeplink,
  updateLegacyDeeplink,
  updateDeeplinkExtra,
  updatePush2Content,
} from 'app/ducks/campaigns/campaign/push2Creative/actions';

import { emptyTemplate } from 'app/features/Personalization/TemplateSelection';
type Props = {
  showTemplateToggleV2: boolean;
  isDynamicTemplatesDeeplinkEnable: boolean;
  apiFileUpload: (...args: Array<any>) => any;
};

const allEmptyTemplates = {
  legacyDeeplink: emptyTemplate,
  deeplink: emptyTemplate,
  title: emptyTemplate,
  body: emptyTemplate,
  summary: emptyTemplate,
};

const BigPicture = ({ showTemplateToggleV2, isDynamicTemplatesDeeplinkEnable, apiFileUpload }: Props) => {
  const {
    push2Creative: {
      deeplinkType,
      payload: {
        notification: {
          content,
          interactive: { click },
        },
      },
    },
  } = useSelector(
    ({
      campaigns: {
        campaign: { push2Creative },
      },
    }) => ({ push2Creative }),
  );

  const [currTemplates, setCurrTemplates] = React.useState(allEmptyTemplates);

  const dispatch = useDispatch();

  const onUpload = async (fileUri: string, file: File) => {
    if (file) {
      const { status, url } = await apiFileUpload(file);

      if (status === 'SUCCESSFUL') {
        displaySuccess('Image upload Successful');
        dispatch(updatePush2Content({ img_source: url }));
      } else {
        displayError(`Image upload failed with the API status ${status}`);
      }
    } else {
      displayError('The file was rejected. It may have the wrong extension.');
    }
  };

  const updateLegacyDeeplinkValue = legacyDeeplink => dispatch(updateLegacyDeeplink(legacyDeeplink));
  const updateDeeplinkExtraValue = deeplinkExtra => dispatch(updateDeeplinkExtra(deeplinkExtra));

  const updateContentValue = (ev: Record<string, any>) => dispatch(updatePush2Content({ [ev.target.name]: ev.target.value }));
  const updateDeeplinkValue = ev => dispatch(updateDeeplink(ev.target.value));

  const setCurrTemplate = (name: string) => temp => setCurrTemplates({ ...currTemplates, [name]: temp });

  const { body, title } = content;
  return (
    <Flex>
      <ImageUpload
        id="upload-big-picture"
        placeholder="Upload Big Picture"
        imageUrl={content.img_source}
        onUpload={(uri, file) => onUpload(uri, file)}
        onRemove={() => {
          dispatch(updatePush2Content({ img_source: null }));
        }}
      />

      <div style={{ width: '75%' }}>
        {/* Todo: deeplink should be its own component */}
        {deeplinkType === 'legacy' ? (
          <DeeplinkSelection
            deeplink={click.legacyDeeplink}
            setDeeplink={updateLegacyDeeplinkValue}
            updateDeeplinkExtra={updateDeeplinkExtraValue}
            selectedDeeplink={click.legacyDeeplink}
            showTemplateToggleV2={showTemplateToggleV2}
            disableTemplate={false}
            currTemplate={currTemplates.legacyDeeplink}
            setCurrTemplate={setCurrTemplate('legacyDeeplink')}
            isDeeplink
          />
        ) : (
          <>
            <Input value={click.value} name="deeplink" id="Deeplink" placeholder="Deeplink Url" onChange={updateDeeplinkValue} />
          </>
        )}

        {/* Todo: title, body, summary should be its own component */}
        <Input value={title} name="title" id="Title" placeholder="Title" onChange={updateContentValue} />
        <Input value={body} name="body" id="Message" placeholder="Message" onChange={updateContentValue} />
        <StyledInput
          value={content.summary}
          name="summary"
          id="summary"
          placeholder="Summary"
          onChange={ev => dispatch(updatePush2Content({ summary: ev.target.value }))}
        />
      </div>
    </Flex>
  );
};

export default compose(ApiImageUpload)(BigPicture);

const Flex = styled.div`
  display: flex;
  justify-content: normal;
`;
const Input = styled(_Input)`
  height: auto;
`;

const StyledInput = styled(Input)`
  height: auto;
`;
