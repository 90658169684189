import React, { Component } from 'react';
import styled from 'styled-components';
import { Typography, Modal } from '@material-ui/core';

import { Button, ConfirmModal, Icon } from 'app/midgarComponents';
import { withAppConfig } from 'app/decorators';
import { IAppConfig } from 'configs/apps/types';
import { sc } from 'app/styles';
import { IChannelConfig } from 'configs/channels/types';
import { findChannelSetup } from 'app/ducks/configStore/utils';
import { Tooltip } from 'app/components';

import { ActionButton } from './styledComponents';
import { withCampaignActions, ActionNames } from '../../actions';
import { compose } from 'recompose';

export const actionsConfirmationMessages = {
  [ActionNames.pause]: 'Are you sure, you want to pause the campaign?',
  [ActionNames.stop]: 'Are you sure, you want to stop this campaign? It is an irreversible action.',
  [ActionNames.approve]: 'Are you sure, you want to approve the campaign?',
};

type Props = {
  actionNames: Array<string>;
  handleAction: (actionName: string) => any;
  valid: boolean;
  validationFailures: Array<string>;
  channelConfig: IChannelConfig;
  appConfig: IAppConfig;
};

type State = {
  confirmationMessage?: string;
  currentActionName?: string;
  showWarning: boolean;
};

class Actions extends Component<Props, State> {
  state = {
    confirmationMessage: null,
    currentActionName: null,
    showWarning: false,
  };

  select = (actionName: string) => (ev: Event) => {
    const { handleAction, valid } = this.props;
    ev.stopPropagation();
    if (actionName === ActionNames.approve) {
      if (!valid) {
        // show warning if it is invalid
        this.toggleWarning();
      } else {
        this.setState({
          currentActionName: actionName,
          confirmationMessage: actionsConfirmationMessages[actionName],
        });
      }
    } else if (actionsConfirmationMessages[actionName]) {
      this.setState({
        currentActionName: actionName,
        confirmationMessage: actionsConfirmationMessages[actionName],
      });
    } else {
      handleAction(actionName);
    }
  };

  toggleWarning = () =>
    this.setState(state => ({
      ...state,
      showWarning: !state.showWarning,
    }));

  closeConfirmModal = () =>
    this.setState({
      confirmationMessage: null,
      currentActionName: null,
    });

  confirmAction = actionName => {
    const { handleAction } = this.props;
    handleAction(actionName);
    this.closeConfirmModal();
  };

  render() {
    const { actionNames, validationFailures, channelConfig = {}, appConfig } = this.props;
    const { currentActionName, confirmationMessage, showWarning } = this.state;

    const currentTenantChannelConfig = findChannelSetup(appConfig, channelConfig.name) || {};

    const tooltipMessage = currentTenantChannelConfig.message || {};

    const disabled = typeof currentTenantChannelConfig === 'object' && !!currentTenantChannelConfig.readOnly;

    return (
      <>
        {!!actionNames.length && (
          <Tooltip open={disabled} title={tooltipMessage.unavailable || ''}>
            <div data-qa="campaign-action-buttons">
              {actionNames.map(actionName => (
                <ActionButton
                  key={actionName}
                  type={actionName === actionNames[actionNames.length - 1] ? 'primary' : 'secondary'}
                  onClick={this.select(actionName)}
                  data-qa={`campaign-action-button-${actionName}`}
                  disabled={disabled}
                >
                  {actionName}
                </ActionButton>
              ))}
            </div>
          </Tooltip>
        )}

        <ConfirmModal
          title="Confirmation"
          confirmText="Yes"
          isOpen={!!confirmationMessage}
          onClose={this.closeConfirmModal}
          onConfirm={() => this.confirmAction(currentActionName)}
        >
          {confirmationMessage}
        </ConfirmModal>
        <ApproveWarning openModal={showWarning} messages={validationFailures} onToggle={this.toggleWarning} />
      </>
    );
  }
}

export default compose(withAppConfig, withCampaignActions({ allowBannerEdit: true }))(Actions);

const Heading2 = styled.h2`
  font-size: ${sc.fontSizeLarge};
  color: ${props => (props && props.color ? props.color : sc.subHeadingColor)};
`;

const DangerIcon = styled(Icon)`
  margin: 0 5px;
`;

const WarningMessage = styled.pre`
  margin: 1rem 0;
  white-space: pre-wrap;
  line-height: ${sc.fontSize};
  font-size: ${sc.fontSizeSmall};
`;

const styles = {
  modalStyles: {
    background: 'white',
    width: '500px',
    height: 'fit-content',
    padding: '2rem',
    margin: '0 auto',
    marginTop: '20vh',
    alignItems: 'center',
  },
};

type ApproveWarningProps = {
  openModal: boolean;
  onToggle: () => void;
  messages: Array<string>;
};

const ApproveWarning = ({ openModal, onToggle, messages = [] }: ApproveWarningProps) => (
  <Modal open={openModal}>
    <div style={styles.modalStyles}>
      <Heading2 variant="title" gutterBottom id="modal-title" color={sc.danger}>
        Validation Error
        <DangerIcon name="warning" color={sc.danger} />
      </Heading2>
      <Typography variant="subtitle1" id="simple-modal-description">
        <WarningMessage>
          You cannot approve the campaign because{' '}
          {messages.join('\r\n\n').substring(0, 1).toLowerCase() + messages.join('\r\n\n').substring(1)}
        </WarningMessage>
      </Typography>
      <Button onClick={onToggle}>Go Back</Button>
    </div>
  </Modal>
);
