import * as types from './types';

const getAll = () => ({
  type: types.GET_ALL_SEGMENTS,
});

const getAllSuccess = data => ({
  type: types.GET_ALL_RULES_SUCCESS,
  payload: { data },
});

const getAllFail = error => ({
  type: types.GET_ALL_RULES_FAIL,
  payload: { error },
});

export { getAll, getAllSuccess, getAllFail };
