import * as React from 'react';
import { Typography } from '@material-ui/core';
import { Card, SegmentCount, ReadOnlyOrRuleGroup } from 'app/components';

export default class Rules extends React.PureComponent<{
  classes: {
    header: string;
  };

  calculate: () => boolean;
  toTableData: (arg0: {} | null | undefined, arg1: Array<{}>) => () => void;
  featureMetadata?: {};
  includedRules: Array<{}>;
}> {
  render() {
    const { classes, calculate, toTableData, featureMetadata, includedRules } = this.props;
    return (
      <Card
        className={classes.header}
        label={() => [
          <Typography variant="subtitle1" key="label">
            Target Rules
          </Typography>,

          <SegmentCount key="counter" calculate={calculate} classes={classes} calcOnLoad />,
        ]}
      >
        <ReadOnlyOrRuleGroup data={toTableData(featureMetadata, includedRules)} />
      </Card>
    );
  }
}
