import React, { PureComponent } from 'react';
import moment from 'moment';
import { prop } from 'ramda';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Tooltip, DrawerError } from 'app/components';

import { withAppConfig } from 'app/decorators';
import { segmentTypeFlags } from 'app/features/Segments/utils';
import { isNilOrEmpty, equalsByProps } from 'app/helpers/RamdaHelpers/helpers';
import { Spinner, Icon as _Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { formatNumber } from 'app/utilities/numberFormat';
import { triggerTypes } from 'app/utilities/constants';
import { generateDataQaValue } from 'app/utilities/strings';
import { getChannelCreativeConfig } from 'configs/channels';
import { defaultDateTimeFormat } from 'configs/dateTime';
import { notificationMediumId } from 'configs/channels/mediumIds';

import { IAppConfig } from 'configs/apps/types';
import { IUser } from 'app/types/User';
import { ICampaignSize } from '../../campaignReach';
import { getReachFromCampaignSize } from '../../campaignReach';
import { SectionContainer } from '../../SectionContainer';

const printLabelText = (label, text, isEmail, highlight) => (
  <Section data-qa={generateDataQaValue(label)} highlight={highlight}>
    <Label>{label}</Label>
    {isEmail ? <Email>{text}</Email> : <Text>{text}</Text>}
  </Section>
);

const setDelimiter = (items, item) => (item === items[items.length - 1] ? item.name : `${item.name},`);

const formatDate = date =>
  typeof date === 'string' && date.toLowerCase() === 'not set' ? 'Not Set' : moment(date).format(defaultDateTimeFormat);

const renderMultipleItems = (label = '', items, isLink, handleClick, highlight) => (
  <Section data-qa={generateDataQaValue(label)} highlight={highlight}>
    <Label>{label}</Label>
    {items.length === 0 ? (
      <Text>Not Set</Text>
    ) : (
      items.map(
        item =>
          item &&
          (isLink ? (
            <Link key={item.id} {...(handleClick ? { onClick: () => handleClick(item) } : {})}>
              {setDelimiter(items, item)}
            </Link>
          ) : (
            <Text key={item.id}>{setDelimiter(items, item)}</Text>
          )),
      )
    )}
  </Section>
);

const isEmptyOrInvalid = val => isNilOrEmpty(val) || val === 'Invalid date';

const renderEmptyIfNil = (label, val, renderFunc, ...args) => {
  if (isEmptyOrInvalid(val)) {
    return renderFunc(label, 'Not set', ...args);
  }

  return renderFunc(label, val, ...args);
};

const renderIFCampaginError = (error, campaignReach) => {
  if (error) {
    return (
      <>
        <Icon name="warning" size={16} color={'#f44336'} />
        {' Error'}
      </>
    );
  }
  return campaignReach ? formatNumber(campaignReach) : campaignReach;
};

const getSegmentIds = (segments, areFiltersEnabled) =>
  areFiltersEnabled ? segments.filter(x => x.type === segmentTypeFlags.RULE_BASED) : segments;

type Generic = Partial<{
  id: number;
  name: string;
  type: string;
}>;

class CampaignDetails extends PureComponent<{
  appConfig: IAppConfig;
  audienceFiltersEnabled: boolean;
  campaignSize: ICampaignSize;
  categories: Array<Generic>;
  categories2?: Array<Generic>;
  campaignPromotionCode: string;
  goal: {
    name: string;
  };

  createdBy: string;
  createdAtTime: string;
  criteria: {
    isDebug: boolean;
  };

  excludedSegments: Array<Generic>;
  excludedSegmentsFilters: Array<Generic>;
  general: {
    exclusionCampaign: boolean;
    businessPhase?: {
      name: string;
    };
  };

  handleSegmentClick: (...args: Array<any>) => any;
  handleAudienceV2Click: (...args: Array<any>) => any;
  id: number;
  includedSegments: Array<Generic>;
  includedSegmentsFilters: Array<Generic>;
  mediumId: number;
  scheduling: {
    expiryDate: string;
    maximumLimit: string;
    miniumInterval: string;
    startDate: string;
    startTime: string;
    validDays: string;
  };

  triggerType: string;
  tags: Array<Generic>;
  useEveryoneAudience?: boolean;
  objective: Record<string, any>;
  user: IUser;
  originalCampaign: Record<string, any>;
  startDate: string;
  startTime: string;
  expiryDate: string;
  expiryTime: string;
  customerCountPerExecution: string;
  audienceSource: string;
  audienceId: Array<Generic>;
}> {
  static defaultProps = {
    audienceFiltersEnabled: false,
    campaignSize: {
      loading: true,
      rawCount: {
        audienceCount: 0,
        geoFilterCount: 0,
      },
    },

    categories: [],
    categories2: [],
    campaignPromotionCode: '',
    goal: {},
    createdAtTime: '',
    createdBy: '',
    criteria: {
      isDebug: false,
    },

    excludedSegments: [],
    excludedSegmentsFilters: [],
    general: {
      exclusionCampaign: false,
      businessPhase: null,
    },

    handleSegmentClick: undefined,
    handleAudienceV2Click: undefined,
    includedSegments: [],
    includedSegmentsFilters: [],
    scheduling: {
      expiryDate: '',
      startDate: '',
      startTime: '',
      validDays: '',
      miniumInterval: '',
      maximumLimit: '',
    },

    tags: [],
    audienceSource: '',
    audienceId: [],
  };

  render() {
    const {
      appConfig,
      audienceFiltersEnabled,
      campaignSize,
      categories,
      categories2,
      campaignPromotionCode,
      goal,
      createdBy,
      createdAtTime,
      criteria,
      excludedSegments,
      excludedSegmentsFilters,
      general: { exclusionCampaign, businessPhase },
      handleSegmentClick,
      handleAudienceV2Click,
      id,
      includedSegments,
      includedSegmentsFilters,
      mediumId,
      scheduling,
      tags,
      triggerType,
      useEveryoneAudience,
      objective,
      originalCampaign,
      startDate,
      startTime,
      expiryDate,
      expiryTime,
      customerCountPerExecution,
      audienceId,
    } = this.props;

    const isDebug = !!criteria && !!criteria.isDebug; // Criteria could be null and isDebug could be undefined in criteria

    const campaignReach = getReachFromCampaignSize(campaignSize);
    const campaignType = objective?.campaignType?.name || 'Not set';

    const campaignObjective = objective?.name || 'Not set';

    const maxLimitRawStr = prop('Maximum Limit', scheduling);
    const maxLimit = maxLimitRawStr ? Number(maxLimitRawStr) : undefined;
    const maxLimitStr = !maxLimit || Number.isNaN(maxLimit) ? 'Not set' : formatNumber(maxLimit);

    const highlightMaxAudience =
      originalCampaign &&
      (customerCountPerExecution || originalCampaign.customerCountPerExecution) &&
      customerCountPerExecution !== originalCampaign.customerCountPerExecution;

    const { enableGoals = false, enableCampaignTypes } = appConfig || {};

    return (
      <Container>
        {campaignSize.error ? <DrawerError defaultExpanded={false} allowDismiss={false} error="Error Retrieving Campaign Reach" /> : ''}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Header>Campaign Details</Header>
          </Grid>

          <Grid item sm={6} xs={12}>
            {renderEmptyIfNil('ID :', id, printLabelText)}
          </Grid>

          <Grid item sm={6} xs={12}>
            {renderEmptyIfNil('Created by : ', createdBy, printLabelText, true)}
          </Grid>

          {enableCampaignTypes && (
            <>
              <Grid item sm={6} xs={12}>
                {printLabelText('Campaign Type : ', campaignType)}
              </Grid>
              <Grid item sm={6} xs={12}>
                {printLabelText('Campaign Objective : ', campaignObjective)}
              </Grid>
            </>
          )}

          <Grid item sm={6} xs={12}>
            {/* Temporary code to fix categories. We eventually need to name categories to categories2 in reducer */}
            {renderMultipleItems(
              'Categories :',
              categories2 && categories2.length ? categories2 : categories,
              false,
              undefined,
              originalCampaign && !equalsByProps(originalCampaign.categories2, categories2, 'id'),
            )}
          </Grid>

          <Grid item sm={6} xs={12}>
            {renderMultipleItems('Tags :', tags, false, undefined, originalCampaign && !equalsByProps(originalCampaign.tags, tags, 'id'))}
          </Grid>

          {getChannelCreativeConfig(appConfig, mediumId).useExclusionCampaigns && (
            <Grid item sm={6} xs={12}>
              {printLabelText(
                'Exclusion Campaign : ',
                exclusionCampaign ? 'Yes' : 'NO',
                false,
                originalCampaign && exclusionCampaign !== originalCampaign.exclusionCampaign,
              )}
            </Grid>
          )}

          {businessPhase && !enableCampaignTypes && (
            <Grid item sm={6} xs={12}>
              {printLabelText('User Lifecycle Stage : ', businessPhase?.name || 'N/A')}
            </Grid>
          )}

          {enableGoals && (
            <>
              <Grid item sm={6} xs={12}>
                {printLabelText('Campaign Goal : ', goal ? goal.name : 'N/A')}
              </Grid>
              <Grid item sm={6} xs={12}>
                {printLabelText('Promotion Code : ', campaignPromotionCode || 'N/A')}
              </Grid>
            </>
          )}

          {getChannelCreativeConfig(appConfig, mediumId).sendToEveryone && (
            <Grid item sm={6} xs={12}>
              {printLabelText(
                'Send to Everyone : ',
                useEveryoneAudience ? 'Yes' : 'NO',
                false,
                originalCampaign && useEveryoneAudience !== originalCampaign.useEveryoneAudience,
              )}
            </Grid>
          )}

          {(!getChannelCreativeConfig(appConfig, mediumId).sendToEveryone || !useEveryoneAudience) && (
            <>
              {audienceId && audienceId.length > 0 ? (
                <Grid item sm={6} xs={12}>
                  {renderMultipleItems(
                    'Audience set : ',
                    audienceId ? audienceId : [],
                    true,
                    handleAudienceV2Click,
                    originalCampaign &&
                      (useEveryoneAudience !== originalCampaign.useEveryoneAudience ||
                        !equalsByProps(originalCampaign.audienceId, audienceId, 'id')),
                  )}
                </Grid>
              ) : (
                <>
                  <Grid item sm={6} xs={12}>
                    {renderMultipleItems(
                      'Include set : ',
                      getSegmentIds(includedSegments, audienceFiltersEnabled),
                      true,
                      handleSegmentClick,
                      originalCampaign &&
                        (useEveryoneAudience !== originalCampaign.useEveryoneAudience ||
                          !equalsByProps(originalCampaign.includedSegments, includedSegments, 'id')),
                    )}
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    {renderMultipleItems(
                      'Exclude set : ',
                      getSegmentIds(excludedSegments, audienceFiltersEnabled),
                      true,
                      handleSegmentClick,
                      originalCampaign &&
                        (useEveryoneAudience !== originalCampaign.useEveryoneAudience ||
                          !equalsByProps(originalCampaign.excludedSegments, excludedSegments, 'id')),
                    )}
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    {renderMultipleItems(
                      'Include set Filters: ',
                      includedSegmentsFilters,
                      true,
                      handleSegmentClick,
                      originalCampaign &&
                        (useEveryoneAudience !== originalCampaign.useEveryoneAudience ||
                          !equalsByProps(originalCampaign.includedSegmentsFilters, includedSegmentsFilters, 'id')),
                    )}
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    {renderMultipleItems(
                      'Exclude set Filters: ',
                      excludedSegmentsFilters,
                      true,
                      handleSegmentClick,
                      originalCampaign &&
                        (useEveryoneAudience !== originalCampaign.useEveryoneAudience ||
                          !equalsByProps(originalCampaign.excludedSegmentsFilters, excludedSegmentsFilters, 'id')),
                    )}
                  </Grid>
                </>
              )}

              {campaignSize.loading ? (
                <Grid item sm={6} xs={12}>
                  <Spinner size={30} />
                </Grid>
              ) : (
                <Grid item sm={6} xs={12}>
                  {renderEmptyIfNil('Campaign Reach', renderIFCampaginError(campaignSize.error, campaignReach), printLabelText)}
                </Grid>
              )}

              {audienceId && audienceId.length === 0 && (
                <Grid item sm={6} xs={12}>
                  {printLabelText('Max. audience : ', maxLimitStr, false, highlightMaxAudience)}
                </Grid>
              )}
            </>
          )}

          <Grid item sm={6} xs={12}>
            {renderEmptyIfNil('Created Time (JST):', formatDate(createdAtTime), printLabelText)}
          </Grid>

          <Grid item sm={6} xs={12}>
            {renderEmptyIfNil(
              'Start Date (JST): ',
              formatDate(prop('Start Date', scheduling)),
              printLabelText,
              false,
              originalCampaign && (startDate !== originalCampaign.startDate || startTime !== originalCampaign.startTime),
            )}
          </Grid>

          {mediumId !== notificationMediumId && (
            <Grid item sm={6} xs={12}>
              {renderEmptyIfNil(
                'End Date (JST): ',
                formatDate(prop('Expiry Date', scheduling)),
                printLabelText,
                false,
                originalCampaign && (expiryDate !== originalCampaign.expiryDate || expiryTime !== originalCampaign.expiryTime),
              )}
            </Grid>
          )}

          {/* need to ahndle on clone or edit */}
          <Grid item sm={6} xs={12}>
            {printLabelText(
              'Audience Source : ',
              audienceId && audienceId.length > 0 && !useEveryoneAudience ? 'AUDIENCE_PROCESSOR' : 'RULE_ENGINE',
              false,
              originalCampaign &&
                (audienceId && audienceId.length > 0 && !useEveryoneAudience ? 'AUDIENCE_PROCESSOR' : 'RULE_ENGINE') !==
                  originalCampaign.audienceSource,
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <Section>
              {triggerType === triggerTypes.eventsBased && printLabelText('Debug Mode : ', isDebug.toString())}
              {isDebug && (
                <Tooltip title="Campaign is marked for testing only and will not be delivered" placement="top">
                  <Icon name="info-outline" size={18} />
                </Tooltip>
              )}
            </Section>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withAppConfig(CampaignDetails);

const Container = styled(SectionContainer)`
  padding: 1.75rem 1.625rem 3rem 1.625rem;
`;

const Header = styled.h2`
  font-size: 1.125em;
  font-weight: 900;
  color: rgba(41, 57, 79, 0.9);
`;

const Label = styled.section`
  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmall};
`;

const Link = styled.span`
  font-size: ${sc.fontSizeSmall};
  margin-left: 0.438rem;
  color: #00b9f5;
  &:hover {
    cursor: pointer;
  }
`;

const Text = styled.span`
  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmall};
  margin-left: 0.438rem;
`;

const Email = styled(Text)`
  color: #00b9f5;
  margin-right: 0.875rem;
`;

const Section = styled.section`
  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
  display: flex;
  flex-wrap: wrap;
`;

const Icon = styled(_Icon)`
  color: #00b9f5;
  margin-left: 0.5rem;
`;
