import * as React from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core'; // TODO: Use midgar TextField

import Select from 'app/components/Select';
import Button from 'app/midgarComponents/Button';
import { sanitizeString, R } from 'app/helpers/RamdaHelpers/helpers';

type Props = {
  searchPhrase: string | null | undefined;
  selectedSearchType: string;
  searchTypes: Array<{ value: string | boolean; label: string | boolean }>;
  handleSearchPhraseChange: (arg0: string) => void;
  handleSearchTypeChange: () => void;
  handleSearchButtonClick: () => void;
};

/**
 * @deprecated Use SearchBar instead
 */
export default ({
  searchPhrase,
  selectedSearchType,
  searchTypes,
  handleSearchPhraseChange,
  handleSearchTypeChange,
  handleSearchButtonClick,
}: Props) => (
  <Container>
    <TextField
      id="searchPhrase"
      type="search"
      label="Search"
      value={searchPhrase}
      onChange={handleSearchPhraseChange}
      className="searchItem"
    />

    <Select
      autoWidth
      value={selectedSearchType}
      values={searchTypes}
      handleChange={handleSearchTypeChange}
      id="searchType"
      label=""
      readValue={R.prop('value')}
      readLabel={R.prop('label')}
      className="searchItem"
    />

    <Button color="primary" disabled={R.isEmpty(sanitizeString(searchPhrase))} onClick={handleSearchButtonClick}>
      Search
    </Button>
  </Container>
);

export const Container = styled.section`
  display: flex;
  align-items: flex-end;
  margin: 1rem;
  & .searchItem {
    margin-right: 1rem;
  }
`;
