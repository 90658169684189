import * as React from 'react';
import styled from 'styled-components';

import { HTMLPreview } from 'app/components/HTMLPreview/HTMLPreview';
import Personalization from 'app/features/Templates/Email/TemplatePage/Personalization';
import _Dropdown from 'app/midgarComponents/Dropdown';
import { sc } from 'app/styles';

type Props = {
  apiFileUpload?: () => void;
  className?: string;
  content?: string;
  error?: boolean;
  fileUploads?: Array<{
    error?: string;
    file: File;
    loading?: boolean;
    url?: string;
  }>;

  handleAcceptedFiles?: () => void;
  handleRejectedFiles?: () => void;
  isUploadImage: boolean;
  label?: string;
  name: string;
  readOnly?: boolean;
  required?: boolean;
  setContent: (arg0: string) => void;
  setFileUploads?: () => void;
  transformContent?: (content?: string) => void;
};

const viewDesktop = { id: 1, label: 'Desktop' };
const viewMobile = { id: 2, label: 'Mobile' };
const views = [viewDesktop, viewMobile];

export default class HTMLWithPreview extends React.PureComponent<
  Props,
  {
    mobile: boolean;
    view: string;
  }
> {
  state = {
    mobile: false,
    view: viewDesktop.label,
  };

  handleChangeView = (selectedView: { id: number; label: string }) => {
    this.setState({
      mobile: selectedView === viewMobile,
      view: selectedView.label,
    });
  };

  handleChangeContent = (ev: InputEvent) => {
    const { setContent } = this.props;
    return setContent && setContent(ev.target.value);
  };

  render() {
    const {
      apiFileUpload,
      className,
      content,
      error,
      fileUploads,
      handleAcceptedFiles,
      handleRejectedFiles,
      isUploadImage,
      label,
      name,
      readOnly,
      required,
      setFileUploads,
      transformContent,
    } = this.props;

    const { mobile, view } = this.state;

    return (
      <Container className={className} data-qa="email-template-preview">
        <TextFieldContainer $error={error}>
          <Label required={!!required}>{label}</Label>

          <Textarea value={content} onChange={this.handleChangeContent} disabled={!!readOnly} />

          {isUploadImage && (
            <Personalization
              apiFileUpload={apiFileUpload}
              fileUploads={fileUploads}
              handleAcceptedFiles={handleAcceptedFiles}
              handleRejectedFiles={handleRejectedFiles}
              setFileUploads={setFileUploads}
            />
          )}
        </TextFieldContainer>

        <PreviewContainer>
          <PreviewHeader>
            <Title>Preview</Title>

            <Dropdown label="" value={view} options={views} onChange={this.handleChangeView} />
          </PreviewHeader>

          <HTMLPreview mobile={mobile} name={name} value={transformContent ? transformContent(content) : content} />
        </PreviewContainer>
      </Container>
    );
  }
}

const Container = styled.section`
  border: 1px solid ${sc.sectionBorderColor};
  display: flex;
  width: 100%;
`;

const Dropdown = styled(_Dropdown)`
  height: 32px;
  width: 120px;
`;

const Label = styled.section`
  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmaller};
  line-height: 1.33;
  margin-bottom: 1rem;
  padding: ${sc.gutterSmall};
  ${props =>
    props.required
      ? `
    &:after {
      content: ' *';
    }
  `
      : ''}
`;

const PreviewContainer = styled.section`
  border-left: 1px solid ${sc.sectionBorderColor};
  padding-bottom: ${sc.gutterLarge};
  width: 50%;
`;

const PreviewHeader = styled.section`
  background-color: ${sc.sectionBackgroundColor};
  display: flex;
  height: 44px;
  justify-content: space-between;
  padding: ${sc.gutterSmallest} ${sc.gutterSmall};
`;

const Textarea = styled.textarea`
  border: none;
  color: ${sc.bodyColor};
  font-size: ${sc.fontSize};
  height: calc(100% - 65px);
  line-height: 1.25;
  overflow: auto;
  padding: 0 ${sc.gutterSmall};
  resize: none;
  width: 100%;
`;

const TextFieldContainer = styled.section`
  position: relative;
  width: 50%;
  ${props => (props.$error ? `border: 1px solid ${sc.danger};` : '')}
`;

const Title = styled.p`
  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmall};
  font-weight: bold;
  line-height: 1.43;
  padding: ${sc.gutterSmallest} 0;
`;
