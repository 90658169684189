/* eslint-disable camelcase */

/**
 * Contains utilities needed for the Banner Management API.
 */

import { IPaging } from 'app/hocs/withReporting';

export type IFetch = {
  error: string | null | undefined;
  loading: boolean;
};

export type CMAPagingParams = {
  page: number;
  size: number;
};

export type BannerMgmtPagingParams = {
  page_no: number;
  page_size: number;
  totalElements: number;
};

export const baseFetch = {
  error: null,
  loading: false,
};

export const calculatePagingResults = (
  items: Array<Record<string, any>>,
  { page_no, page_size, totalElements }: BannerMgmtPagingParams,
): IPaging => {
  const totalPages = Math.ceil(totalElements / page_size);
  const last = page_no === totalPages;
  return {
    first: page_no === 1,
    last,
    pageIndex: page_no - 1,
    rowsPerPage: page_size,
    totalElements: totalPages || undefined,
  };
};

/**
 * Tranforms the given query parameters so that the paging properties are compatible with the Banner Management API.
 *
 * The `withReporting` framework is currently hard-coded to use paging parameter names for CMA:
 *  - `page` (0-based index)
 *  - `size` Rows per page
 *
 * The Banner Management API expects these to be named:
 *  - `page_no` (1-based page index)
 *  - `page_size`
 *
 * @param {CMAPagingParams} apiQueryParams Query parameters as produced by `withReporting`.
 */
export const mapPagingProps = ({ page, size, ...rest }: CMAPagingParams): BannerMgmtPagingParams => ({
  ...rest,
  page_no: page ? Number(page) + 1 : 1,
  page_size: size,
});

// TODO: Default to page 1 -- this should only happen if there is no paging
