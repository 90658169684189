import { IPaymentCeiling } from 'app/features/CashbackPromoManagement/types';

import { IFetch } from '../../commonStates';
import { fetch } from '../../commonStates';
import * as types from './types';

type State = IFetch & {
  paymentCeilings: Array<IPaymentCeiling>;
};

const initialState: State = {
  ...fetch,
  paymentCeilings: [],
};

export default (state: State = initialState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case types.GET_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
        paymentCeilings: [],
      };

    case types.GET_INIT:
      return {
        ...state,
        error: null,
        loading: true,
        paymentCeilings: [],
      };

    case types.GET_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        paymentCeilings: payload.paymentCeilings,
      };

    case types.UPDATE:
      return {
        ...state,
        paymentCeilings: (state.paymentCeilings || []).map<IPaymentCeiling>(ceiling =>
          ceiling.name === payload.paymentCeiling.name ? payload.paymentCeiling : ceiling,
        ),
      };

    default:
      return state;
  }
};
