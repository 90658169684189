import { DEFAULT_SLOT_GROUP_TYPE } from 'app/features/BannerManagement/constants';
import { IBannerCreative, IBinder, ISlotGroupType, IVariant, IViewItem } from 'app/types/BannerManagement';
import { IUser } from 'app/types/User';

import { toBmsDateString } from '../../bmsCommon/utils';
import { IApiNewVariant, IApiNewViewItem, IApiSlotGroupType } from './types';

export const fromApiSlotGroup = (apiViewItem: IApiSlotGroupType): ISlotGroupType => ({
  label: apiViewItem.displayValue,
  value: apiViewItem.value,
});

// We should not need to map, but the date fields are in the parent `binder` object, instead of using those in the `viewItem` object
export const toApiViewItem = (viewItem: IViewItem, binder: IBinder, user: IUser): IViewItem => {
  const { timezone } = user;
  const {
    selectedDates: { startDate, endDate, validFrom, validUpto },
  } = binder;

  return {
    platform: viewItem.platform,
    site: viewItem.site,
    slotId: viewItem.slotId,
    tags: viewItem.tags,
    title: viewItem.title,
    type: viewItem.type,

    // Optional Fields
    ...(endDate ? { endDate: toBmsDateString(timezone)(endDate) } : {}),
    ...(startDate ? { startDate: toBmsDateString(timezone)(startDate) } : {}),
    ...(validFrom ? { validFrom: toBmsDateString(timezone)(validFrom) } : {}),
    ...(validUpto ? { validUpto: toBmsDateString(timezone)(validUpto) } : {}),
    ...(viewItem.gaCategory ? { gaCategory: viewItem.gaCategory } : {}),
    ...(viewItem.shareable ? { shareable: viewItem.shareable } : {}),
    ...(viewItem.subtitle ? { subtitle: viewItem.subtitle } : {}),
    ...(viewItem.title ? { title: viewItem.title } : {}),
  };
};

const toApiNewVariant =
  (createdBy: string) =>
  (variant: IVariant): IApiNewVariant => ({
    sourceCreativeVariantId: variant.id,
    status: variant.status || 0,
    createdBy,
  });

export const toApiNewViewItem = (creative: IBannerCreative, binder: IBinder, user: IUser): IApiNewViewItem => {
  const { email, timezone } = user;
  const {
    selectedDates: { startDate, endDate, validFrom, validUpto },
    view: { view },
  } = binder;

  const { widgetType } = view;
  const variants = ((creative.variants || []) as unknown as IVariant[]).filter(v => v.widgetType === widgetType);

  return {
    afterViewItemId: binder.selectedViewItemId,
    priority: 1,
    slotId: binder.selectedSlot.id,
    sourceCreativeId: creative.id,
    type: binder.selectedSlotGroupType ? binder.selectedSlotGroupType.value : DEFAULT_SLOT_GROUP_TYPE,
    variants: variants.map(toApiNewVariant(email)),

    // Optional Fields
    ...(endDate ? { endDate: toBmsDateString(timezone)(endDate) } : {}),
    ...(startDate ? { startDate: toBmsDateString(timezone)(startDate) } : {}),
    ...(validFrom ? { validFrom: toBmsDateString(timezone)(validFrom) } : {}),
    ...(validUpto ? { validUpto: toBmsDateString(timezone)(validUpto) } : {}),
  };
};
