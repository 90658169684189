import { compose, withProps, withStateHandlers } from 'recompose';

import CascadingListsBrowse from '../../CascadingListsBrowse';

const buildSrcLists = (featureVerticals, featureCategories, featureClasses, features) => [
  { id: 1, name: 'Verticals', items: featureVerticals },
  { id: 2, name: 'Categories', items: featureCategories },
  { id: 3, name: 'Classes', items: featureClasses },
  { id: 4, name: 'Features', items: features },
];

const addState = withStateHandlers(
  ({ selectedFeatureId = null }) => ({
    selectedListId: selectedFeatureId !== null && selectedFeatureId !== undefined ? 4 : undefined,
    selectedItemId: selectedFeatureId,
  }),

  {
    handleClick:
      (state, { handleClick, handleFeatureSelected }) =>
      (selectedListId, selectedItemId) => {
        if (handleClick) {
          handleClick(selectedListId, selectedItemId);
        }
        if (handleFeatureSelected && selectedListId === 4 && selectedItemId !== null && selectedItemId !== undefined) {
          handleFeatureSelected(selectedItemId);
        }
        return { selectedListId, selectedItemId };
      },
  },
);

const addProps = withProps(({ featureClasses, featureVerticals, featureCategories, features }) => ({
  srcLists: buildSrcLists(featureVerticals, featureCategories, featureClasses, features),
}));

const enhance = compose(addState, addProps);

export default enhance(CascadingListsBrowse);
