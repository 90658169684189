export const users = ({
  users: {
    users: { users: theseUsers },
  },
}) => theseUsers;

export const paging = ({
  users: {
    users: { number, first, last, totalElements, size },
  },
}) => ({
  number,
  first,
  last,
  totalElements,
  size,
});

export const loading = ({
  users: {
    users: { loading: thisLoading },
  },
}) => thisLoading;

export const error = ({
  users: {
    users: { error: thisError },
  },
}) => thisError;
