import { green } from '@material-ui/core/colors';

export const styles = theme => ({
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  full: {
    width: '100%',
    margin: '1rem 0',
  },

  half: {
    width: 'calc(50% - 10px)',
    margin: '1rem 0',
  },

  grid: {
    height: 'calc(100% - 100px)',
    position: 'relative',
    padding: '10px',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
  },

  cardBody: {
    display: 'flex',
  },

  label: {
    color: theme.palette.common.white,
  },

  chip: {
    margin: theme.spacing(1),
  },

  // root: {
  //   display: 'flex',
  //   alignItems: 'center',
  // },
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },

  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
