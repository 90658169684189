import * as Yup from 'yup';

const campaignGeneralSchema = (context: { triggerType?: string; enableGoals?: boolean }): object =>
  Yup.lazy(() =>
    Yup.object().shape({
      tags: Yup.array()
        .of(
          Yup.object({
            id: Yup.number().required('A campaign tag requires an ID property'),
            name: Yup.string().required('A campaign tag requires a name property'),
          }),
        )
        .required()
        .min(1, 'At least 1 campaign tag must be selected'),
      categories: context.enableGoals
        ? Yup.mixed().notRequired()
        : Yup.array()
            .of(
              Yup.object({
                id: Yup.number().required('A category item requires an ID property'),
                name: Yup.string().required('A category item requires a name property'),
              }),
            )
            .required()
            .min(1, 'At least 1 category must be selected'),
      name: Yup.string().required('A campaign name is required'),
    }),
  );

export { campaignGeneralSchema };
