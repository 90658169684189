import * as React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';

import { withAppConfig, withPermissions } from 'app/decorators';
import { Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IAppConfig } from 'configs/apps/types';
import { getChannels } from 'configs/channels';
import { findChannelSetup } from 'app/ducks/configStore/utils';

type Props = {
  appConfig: IAppConfig;
  selectChannel: (...args: Array<any>) => any;
  isPermitted: (arg0: Array<string>) => boolean;
};

class ChannelSelection extends React.PureComponent<Props> {
  getVisibleChannels = () => {
    const { appConfig, isPermitted } = this.props;

    return getChannels(appConfig).filter(({ permission, name = '' }) => {
      const currentTenantChannelConfig = findChannelSetup(appConfig, name) || {};
      return isPermitted(permission) && !currentTenantChannelConfig.readOnly;
    });
  };

  render() {
    const { selectChannel } = this.props;

    return (
      <Container data-qa="channel-selection">
        <Title>Select a Channel</Title>
        {this.getVisibleChannels().map(({ mediumId, name, display, icon, subChannels }) => {
          if (Array.isArray(subChannels) && subChannels.length > 0) {
            return subChannels.map(subChannel => (
              <ChannelContainer
                data-qa={`channel-${subChannel.name}`}
                key={`${mediumId}-${subChannel.type}`}
                id={`${mediumId}-${subChannel.type}`}
                onClick={() => selectChannel(mediumId, subChannel)}
              >
                <Icon name={subChannel.icon} size="50" color={sc.primary} />
                <Label>{subChannel.name}</Label>
              </ChannelContainer>
            ));
          }
          return (
            <ChannelContainer data-qa={`channel-${name}`} key={mediumId} id={mediumId} onClick={() => selectChannel(mediumId)}>
              <Icon name={icon} size="50" color={sc.primary} />
              <Label>{display || name}</Label>
            </ChannelContainer>
          );
        })}
      </Container>
    );
  }
}

const enhance = compose(withAppConfig, withPermissions);

export default enhance(ChannelSelection);

const ChannelContainer = styled.div`
  display: inline-block;
  width: 175px;
  margin: 1rem 2%;
  padding: 1rem;
  vertical-align: top;
  cursor: pointer;

  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: 0 1px 5px ${sc.overlayLighter};
  background-color: #fff;

  text-align: center;
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: 0 1px 6px ${sc.overlay};
  }
`;
const Label = styled.p`
  color: ${sc.primary};
  font-size: 14px;
  margin-top: 10px;
`;

const Title = styled.h2`
  color: ${sc.secondary};
  text-transform: uppercase;
  font-size: ${sc.fontSizeLarge};
`;

const Container = styled.section`
  h2 {
    margin-bottom: ${sc.gutter};
  }
  button {
    margin-right: ${sc.gutter};
  }
`;
