import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './styles';

type Props = {
  mobile: boolean;
  name?: string;
  value: any | null;
};

const useStyles = makeStyles(styles);

export const HTMLPreview = ({ mobile, name = '', value = '' }: Props) => {
  const classes = useStyles();
  const previewIframeRef = React.useRef();

  React.useEffect(() => {
    const iframe = previewIframeRef.current?.contentWindow.document;

    if (iframe) {
      iframe.open();
      iframe.write(value);
      iframe.close();
    }
  }, [value]);

  return (
    <section className={mobile ? classes.mobile : classes.desktop}>
      <iframe
        src="/"
        sandbox="allow-same-origin"
        ref={previewIframeRef}
        className={classes.iframe}
        id={`html-preview-${name}`}
        title="email-preview"
      />
    </section>
  );
};
