import { compose } from 'recompose';
import { connect } from 'react-redux';

import { audiencesOperations, audiencesSelectors } from 'app/ducks';
import { withAppConfig } from 'app/decorators';
import withReporting from 'app/hocs/withReporting';
import withSearchBar from 'app/hocs/withReporting/withSearchBar';

import Reporting from './Reporting';
import { defaultSearch, searchDefs, searchBarDefs } from './search.config';

const mapStateToProps = state => ({
  audiences: audiencesSelectors.audiences(state),
  error: state.audience.audiences.error,
  isLoading: state.audience.audiences.loading,
  userEmail: state.user.email,
});

const mapDispatchToProps = {
  fetchSegments: audiencesOperations.getAllSegmentsPaged,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withReporting('fetchSegments', audiencesSelectors.paging, {
    searchDefs,
    defaultSearch,
  }),

  withSearchBar(searchBarDefs),
  withAppConfig,
);

export default enhance(Reporting);
