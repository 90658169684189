export default {
  palette: {
    common: {
      black: '#222',
      white: '#fff',
    },

    type: 'light',
    primary: {
      // light: '#7986cb',
      main: '#00b9f5',
      dark: '#0d2755',
      contrastText: '#fff',
    },

    secondary: {
      // light: '#ff4081',
      main: '#D086DC',
      // dark: '#c51162',
      contrastText: '#fff',
    },

    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },

    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },

    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },

    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      default: '#f9f9f9',
      tooltip: 'rgba(51, 122, 183, 0.8)',
      primary: 'hsl(216,50%,96.1%)',
      paper: '#fff',
    },

    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.14)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },

    shadow: {
      primary: 'rgba(0, 0, 0, 0.2)',
      secondary: 'rgba(0, 0, 0, 0.1)',
      dark: 'rgba(0, 0, 0, 0.8)',
    },
  },

  mixins: {
    toolbar: {
      minHeight: 50,
      height: 50,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 50,
        height: 50,
      },

      '@media (min-width:600px)': {
        minHeight: 50,
        height: 50,
      },
    },
  },

  styled: {
    text: {
      title: `
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.16667em;
        color: rgba(0, 0, 0, 0.87);
      `,
      secondary: `
        font-size: 0.75rem;
        line-height: 1.375em;
        color: rgba(0, 0, 0, 0.54);
      `,
    },
  },
};
