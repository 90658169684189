import * as React from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';

import useFormikConfigs from 'app/hooks/useFormikConfigs';
import { ButtonGroup, Icon, AutoSubmit } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ISubChannel } from 'app/types/Campaign';
import channelConfig from 'configs/channels/channel.config';
import { setFormErrors, setHiddenFormsSubmitted } from 'app/ducks/campaigns/campaign/actions';

import Schedule from '../Schedule';

const BASE_BUTTONS = [
  {
    id: 'ScheduleComponent',
    name: 'Schedule',
  },

  {
    id: 'UserCriteria',
    name: 'Real-time User Criteria',
  },

  {
    id: 'CustomAttributesComponent',
    name: 'Campaign Attributes',
  },

  {
    id: 'EventsCriteriaComponent',
    name: 'Event Criteria',
  },

  {
    id: 'PayoutStrategiesComponent',
    name: 'Payout Strategies',
  },

  {
    id: 'CreationComponent',
    name: 'Creative',
  },

  {
    id: 'EnrichFieldsCriteriaComponent',
    name: 'Real-time User Criteria',
  },

  {
    id: 'JourneyStages',
    name: 'Stage Criteria',
  },

  {
    id: 'SettingComponent',
    name: 'Settings',
  },
];

type Props = {
  mediumId: number;
  subChannel: ISubChannel;
  submitAllForms: boolean;
  setCampaignField: (...args: Array<any>) => any;
  name: string;
};

const transformButtonName = (btn, index) => ({
  ...btn,
  ...{ name: `${index + 1}. ${btn.name}` },
});

const SelectedChannel = (props: Props) => {
  const [active, setActive] = React.useState('ScheduleComponent');
  const dispatch = useDispatch();

  const { mediumId, subChannel, submitAllForms, setCampaignField } = props;

  const channel = subChannel || channelConfig[mediumId];

  const { initialValues, validationSchema, submitForm } = useFormikConfigs(channel?.formikConfigs);

  const SelectedView = active === 'ScheduleComponent' ? Schedule : channel[active];
  const buttons = [];

  BASE_BUTTONS.forEach(btn => (channel[btn.id] ? buttons.push(transformButtonName(btn, buttons.length)) : null));

  const removeChannel = () => {
    const reInitializedCampaignObject = {
      criteria: {},
      exclusionCampaign: false,
      mediumId: 0,
      subChannel: '',
      useEveryoneAudience: false,
      variables: {
        features: [],
        events: [],
      },

      promocode: '',
      csvUrl: null,
    };

    return setCampaignField(reInitializedCampaignObject);
  };

  return (
    <SelectedChannelWrapper>
      <Title data-qa={`channel-title-${channel.name}`}>
        <ChannelIcon name={channel.icon} size={26} />
        {channel.display || channel.name}
      </Title>

      <Delete data-qa="remove-channel-button" name="delete" size={26} onClick={removeChannel} />
      <ButtonGroup buttons={buttons} onClick={setActive} active={active} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          submitForm(values);
          dispatch(setHiddenFormsSubmitted({ channel: true }));
        }}
        enableReinitialize
      >
        {({ handleSubmit, errors, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <Field {...props} component={SelectedView} />
            <AutoSubmit formName="channel" setStoreValidityState={setFormErrors} submitTrigger={submitAllForms} />
          </form>
        )}
      </Formik>
    </SelectedChannelWrapper>
  );
};

export default SelectedChannel;

const ChannelIcon = styled(Icon)`
  margin-right: 0.5rem;
  margin-top: -3px;
`;

const Delete = styled(Icon)`
  margin-top: -10px;
  cursor: pointer;
`;

const SelectedChannelWrapper = styled.section`
  & > h2 {
    display: inline-block;
    font-size: 1.5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  & > svg {
    cursor: pointer;
  }
  & > section {
    margin-bottom: 1rem;
  }
`;

const Title = styled.h2`
  color: ${sc.secondary};
  text-transform: uppercase;
  font-size: ${sc.fontSizeLarge};
`;
