import { compose, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { withAppConfig, withPermissions } from 'app/decorators';
import { campaignOperations } from 'app/ducks';
import { resetTemplateManagement } from 'app/ducks/templateManagement/actions';
import { resetHiddenFormsSubmitted, setHiddenFormsSubmitted, toggleSubmitAllForms } from 'app/ducks/campaigns/campaign/actions';
import { getChannels } from 'configs/channels';
import channelConfig from 'configs/channels/channel.config';

import New from './New';
import { styles } from './styles';
import { withValidation } from './validation';

const mapStateToProps = ({
  campaigns: { campaign },
  features: {
    data: {
      dicts: { dataTypeDict },
    },
  },

  user: { currentTenant, permissions, timezone },
}) => ({
  campaign,
  timezone,
  features: dataTypeDict,
  env: currentTenant,
  userPermissions: [...permissions],
});

const mapDispatchToProps = {
  updateCampaign: campaignOperations.updateCampaign,
  postCampaign: campaignOperations.postCampaign,
  getCampaign: campaignOperations.getCampaign,
  setCampaignField: campaignOperations.setCampaignField,
  newCampaign: campaignOperations.newCampaign,
  resetTemplateManagement,
  setHiddenFormsSubmitted,
  resetHiddenFormsSubmitted,
  toggleSubmitAllForms,
};

const handler = withHandlers({
  onCancel:
    ({ history }) =>
    () =>
      history.push('/campaigns'),
  saveAsDraft:
    ({
      history,
      campaign: {
        general: { id },
      },

      postCampaign,
      updateCampaign,
    }) =>
    () => {
      if (id) {
        return updateCampaign({ id, history });
      }

      return postCampaign({ history });
    },
});

const initCampaign = lifecycle({
  async componentDidMount() {
    const { getCampaign, match, appConfig, isPermitted, resetTemplateManagement, setCampaignField, newCampaign } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    const keepState = urlParams.get('keepState');
    const clone = urlParams.get('clone');

    const channels = getChannels(appConfig).filter(({ permission }) => isPermitted(permission));
    if (channels && channels.length && channels.length === 1) {
      const { mediumId } = channels[0];
      setCampaignField({
        mediumId,
        triggerType: channelConfig[mediumId].defaultTrigger,
      });
    }

    if (!keepState) {
      if (!clone && match.params.type === 'new') {
        newCampaign();
      } else {
        getCampaign(match.params, true);
      }
    }

    resetTemplateManagement();
  },
});

const enhance = compose(
  withPermissions,
  withAppConfig,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  initCampaign,
  withValidation,
  handler,
);

export default enhance(New);
