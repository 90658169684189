import * as React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

export const Loading = ({ size = 50, classes }) => <CircularProgress className={classes.root} size={size} />;

Loading.propTypes = {
  size: PropTypes.number,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
};
