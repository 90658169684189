import * as React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import styled from 'styled-components';
import { Button as _Button } from 'app/midgarComponents';

import { getActions } from './actions.config';

type Props = {
  id?: number;
  open: boolean;
  setNode: (...args: Array<any>) => any;
  anchorEl?: React.ReactElement<any>;
  togglePopover: (...args: Array<any>) => any;
  handleClose: (...args: Array<any>) => any;
  handleSelect: (...args: Array<any>) => any;
  isPermitted: (...args: Array<any>) => any;
};

export class Actions extends React.PureComponent<Props> {
  constructor() {
    super();
    this.anchorRef = React.createRef();
  }

  componentDidMount() {
    const { setNode } = this.props;
    /* eslint-disable no-unused-vars */
    setNode(this.anchorRef.current);
  }

  handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const { togglePopover } = this.props;
    togglePopover();
  };

  close = e => {
    e.preventDefault();
    e.stopPropagation();
    const { handleClose } = this.props;
    handleClose();
  };

  select = actionId => e => {
    e.preventDefault();
    e.stopPropagation();
    const { handleSelect } = this.props;
    handleSelect(actionId);
  };

  render() {
    const { id = -1, open, setNode, anchorEl, isPermitted } = this.props;

    const actions = getActions(isPermitted);

    return (
      <span>
        <Button disableRipple onClick={this.handleClick}>
          <MoreVert ref={this.anchorRef} />
        </Button>
        <Menu
          id={`${id}-menu`}
          anchorEl={anchorEl}
          open={open}
          onClose={this.close}
          anchorReference="anchorEl"
          getContentAnchorEl={null}
          anchorPosition={{ top: 200, left: 400 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          {actions.length ? (
            actions.map(action => (
              <MenuItem key={action.id} onClick={this.select(action.id)}>
                {action.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Actions Available</MenuItem>
          )}
        </Menu>
      </span>
    );
  }
}

const Button = styled(_Button)`
  padding: 0;
  justify-content: flex-start;
  background: transparent;
  color: black;
  border: none;
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    color: black;
    border: none;
  }
`;
