import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { ListItem, ListItemText } from '@material-ui/core';
import { Tooltip } from 'app/components';
import Paper from 'app/midgarComponents/Paper';
import { useEffect, useState } from 'react';

export const ListSelectable = ({ selectedId, items, handleClick, classes, scrollIntoViewIfNeeded }) => {
  const highlightedRef = React.useRef(null);
  const [scrolledIntoView, setScrolledIntoView] = useState(false);

  useEffect(() => {
    if (scrollIntoViewIfNeeded && !scrolledIntoView) {
      if (highlightedRef.current && selectedId) {
        highlightedRef.current.scrollIntoView(false);
        setScrolledIntoView(true);
      }
    }
  }, [scrollIntoViewIfNeeded, scrolledIntoView, selectedId]);

  return items && items.length > 0 ? (
    <Paper className={classes.listContainer}>
      <List data-qa="list" disablePadding component="nav">
        {items.map(item => (
          <Tooltip title={item.desc || ''} key={item.id}>
            <ListItem
              data-qa={item.id}
              button
              divider
              onClick={handleClick(item.id)}
              className={selectedId === item.id ? classes.selected : null}
              ref={selectedId === item.id ? highlightedRef : null}
            >
              {selectedId === item.id ? <ListItemText primary={item.name} /> : <ListItemText secondary={item.name} />}
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </Paper>
  ) : null;
};

ListSelectable.propTypes = {
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),

  handleClick: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    listContainer: PropTypes.string,
  }),

  scrollIntoViewIfNeeded: PropTypes.bool,
};
