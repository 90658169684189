import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { Tooltip } from 'app/components';
import { Button as _Button } from 'app/midgarComponents';

type Props = {
  label: string;
  tooltipText?: string;
  handleSave: (...args: Array<any>) => any;
  disabled?: boolean;
  handleReset: () => void;
};

export const ButtonGroup = ({ disabled = false, label, tooltipText, handleSave, handleReset }: Props) =>
  tooltipText && tooltipText.length ? (
    <Tooltip placement="bottom" title={tooltipText}>
      <section>
        <Button type="secondary" onClick={handleReset}>
          Reset
        </Button>
        <Button color="primary" variant="contained" disabled={disabled} onClick={handleSave} data-test-id="save">
          {label}
        </Button>
      </section>
    </Tooltip>
  ) : (
    <section>
      <Button type="secondary" onClick={handleReset}>
        Reset
      </Button>
      <Button color="primary" variant="contained" disabled={disabled} onClick={handleSave} data-test-id="save">
        {label}
      </Button>
    </section>
  );

const Button = styled(_Button)`
  margin-right: ${sc.gutter};
  &:last-child {
    margin-right: 0;
  }
`;
