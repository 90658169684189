import qs from 'query-string';

import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import * as fetch from 'app/utilities/http';
import { Action, DispatchFn, IQueryParams } from 'app/types';
import { IBannerCampaignSpec } from 'app/types/Creatives/BannerCreative';
import { asApiError } from 'app/utilities/errors';

import * as actions from './actions';
import { fromApiCampaigns } from './mappers';

const api = {
  getAll: '/campaigns?:query',
  getAllPaged: '/campaigns/paged?:query',
  setState: '/campaigns/:id/state',
};

const getAllCampaignsPaged =
  (params: IQueryParams) =>
  async (dispatch: DispatchFn): Action => {
    dispatch(actions.getAll());
    try {
      const {
        content: campaigns,
        first,
        last,
        number,
        size,
        totalElements,
      } = await fetch.get(api.getAllPaged.replace(':query', qs.stringify(params)));

      const props = {
        first,
        last,
        number,
        size,
        totalElements,
      };

      return dispatch(actions.getAllSuccess(fromApiCampaigns(campaigns), props));
    } catch (e) {
      return dispatch(actions.getAllFail(e));
    }
  };

const selectRow =
  (id: number) =>
  (dispatch: DispatchFn): Action =>
    dispatch(actions.selectRow(id));

const setStatus =
  ({ id, status }: { id: number; status: string }) =>
  async (dispatch: DispatchFn): Action => {
    try {
      await fetch.put(api.setState.replace(':id', String(id)), status, 'string');
      dispatch(actions.setStatus({ status, id }));
    } catch (err) {
      const apiError = asApiError(err);
      const msg = 'Error while changing campaign state';
      displayError(msg);
      console.error(msg, apiError); // eslint-disable-line no-console
    }
  };

const updateBanner =
  (id: number, banners: Array<IBannerCampaignSpec>) =>
  (dispatch: DispatchFn): Action =>
    dispatch(actions.updateBanner(id, banners));

const updateScheduling =
  (id: number, scheduling: {}) =>
  (dispatch: DispatchFn): Action =>
    dispatch(actions.updateScheduling(id, scheduling));

const getMoreCampaigns =
  (params: IQueryParams) =>
  async (dispatch: DispatchFn, getState: (...args: Array<any>) => any): Action => {
    const {
      campaigns: {
        campaigns: { allIds, byId },
      },
    } = getState();
    const currentCampaigns = allIds.map(id => byId[id]);
    dispatch(actions.loadMore());

    try {
      const { number, first, last, totalElements, size, content } = await fetch.get(
        api.getAllPaged.replace(':query', qs.stringify(params)),
      );

      return dispatch(
        actions.loadMoreSuccess(currentCampaigns.concat(content), {
          first,
          last,
          number,
          size,
          totalElements,
        }),
      );
    } catch (err) {
      return dispatch(actions.loadMoreFail(err));
    }
  };

export { getAllCampaignsPaged, selectRow, setStatus, updateBanner, updateScheduling, getMoreCampaigns };
