/* eslint-disable camelcase */

import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { Input as _Input, Icon } from 'app/midgarComponents';
import uid from 'app/utilities/uid';

type Props = {
  context: Record<string, any>;
  removeExtra: (...args: Array<any>) => any;
  changeExtra: (...args: Array<any>) => any;
  handleChange: (...args: Array<any>) => any;
};

export default class Context extends React.PureComponent<Props> {
  handleChange = ev => {
    const { handleChange } = this.props;
    handleChange({ [ev.target.name]: ev.target.value });
  };

  handleBlur = ev => {
    const { handleChange } = this.props;
    handleChange({ [ev.target.name]: ev.target.value.trim() });
  };

  getExtras = extras => {
    const { removeExtra, changeExtra } = this.props;
    return extras.map(({ key, value }, index) => (
      <Row key={uid()}>
        <RemoveButton size={25} color="primary" name="delete" onClick={() => removeExtra(index)} />
        <Input type="text" name="key" value={key} onChange={ev => changeExtra({ key: ev.target.value }, index)} />
        <Input type="text" name="value" value={value} onChange={ev => changeExtra({ value: ev.target.value }, index)} />
      </Row>
    ));
  };

  render() {
    const {
      context: { utm_content, utm_source },
    } = this.props;
    return (
      <>
        <Input
          value={utm_content}
          name="utm_content"
          id="utm_content"
          placeholder="UTM Content"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />

        <Input
          value={utm_source}
          name="utm_source"
          id="utm_source"
          placeholder="UTM Source"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
      </>
    );
  }
}

const RemoveButton = styled(Icon)`
  margin: 2rem 1rem;
`;

const Input = styled(_Input)`
  height: auto;
  color: ${sc.greyDark};
`;

const Row = styled.div`
  margin: 1rem 0;
  display: flex;

  & > * {
    max-width: 300px;
    margin-right: 1rem;
  }
`;
