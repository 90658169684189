import update from 'immutability-helper';

import { Action } from 'app/types';

import { State } from '../state';
import { initialState } from '../state';
import { validate } from '../validation';
import * as types from './types';

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.ADD_BANNER:
      return validate(
        update(state, {
          banners: { $push: [{}] },
        }),
      );

    case types.REMOVE_BANNER: {
      const banners = state.banners.slice();
      banners[payload.index] = null;

      return validate(
        update(state, {
          banners: { $set: banners },
        }),
      );
    }

    case types.SET_BANNERS:
      return validate(
        update(state, {
          banners: { $set: payload.banners },
        }),
      );

    case types.SET_BANNER_ID: {
      const { index, id } = payload;

      const newBanners = state.banners.slice();
      if (newBanners[index]) {
        newBanners[index].id = id;
      }

      return validate(
        update(state, {
          banners: { $set: newBanners },
        }),
      );
    }

    case types.SET_STOREFRONT_ID: {
      const { index, storefrontId } = payload;

      const newBanners = state.banners.slice();
      if (newBanners[index]) {
        newBanners[index] = {
          storefrontId,
        };
      }

      return validate(
        update(state, {
          banners: { $set: newBanners },
        }),
      );
    }

    case types.SET_VIEW_ID: {
      const { index, viewId } = payload;

      const newBanners = state.banners.slice();
      if (newBanners[index]) {
        newBanners[index] = {
          storefrontId: newBanners[index].storefrontId,
          viewId,
        };
      }

      return validate(
        update(state, {
          banners: { $set: newBanners },
        }),
      );
    }

    default:
      return state;
  }
};
