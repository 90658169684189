import { isNilOrEmpty } from 'src/app/helpers/RamdaHelpers/helpers';
import { notificationLinkValidator } from 'src/app/helpers/TimelineNotificationHelper/';

const required = [
  { title: 'Title', value: 'title' },
  { title: 'Category', value: 'threadId' },
  { title: 'Sender Name', value: 'name' },
  { title: 'Sender Icon', value: 'icon' },
  { title: 'Sender Link', value: 'link' },
];

const validate = state => {
  const errorMessages = [];

  // Validate required fields
  required.forEach(field =>
    isNilOrEmpty(state.payload[field.value] || state.payload.sender[field.value]) ? errorMessages.push(`${field.title} is missing.`) : null,
  );

  if (state.payload?.messageLink && notificationLinkValidator(state.payload?.messageLink, 'Notification')) {
    errorMessages.push(`Message Link URL must start with paypay://, https:// or http:/.`);
  }
  if (state.payload?.sender?.icon && notificationLinkValidator(state.payload?.sender?.icon, 'Sender')) {
    errorMessages.push(`Sender icon URL must start with https:// or http:/.`);
  }
  if (state.payload?.sender?.link && notificationLinkValidator(state.payload?.sender?.link, 'Sender')) {
    errorMessages.push(`Sender link URL must start with https:// or http:/.`);
  }

  const valid = errorMessages.length === 0;
  return {
    ...state,
    valid,
    errorMessages,
  };
};

export default validate;
