import * as React from 'react';
import styled from 'styled-components';
import PaytmLogo from 'src/assets/paytm-logo.png';
import { sc } from 'app/styles';

type Props = {
  name: string;
  version: string;
};

export default ({ name, version }: Props) => (
  <Logo>
    <img src={PaytmLogo} alt="paytm" />
    {name}
    <Version>{version ? `v${version}` : ''}</Version>
  </Logo>
);

const Logo = styled.h1`
  color: #00b9f5;
  height: 24px;
  width: 170px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  font-size: ${sc.fontSize};
  font-weight: 400;

  img {
    height: 24px;
  }
`;

const Version = styled.span`
  font-size: ${sc.fontSizeSmallest};
  margin-left: ${sc.gutterSmallest};
`;
