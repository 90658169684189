import { isEmpty } from 'ramda';
import { RouterHistory } from 'react-router-dom';

import { ISegment } from 'app/types';
import { toOrGroupDataStruct2, validateRuleData } from 'app/helpers/FeatureMetadataHelpers/helpers';
import { FeatureDicts } from 'app/helpers/FeatureMetadataHelpers/helpers';

import { audienceTypesV2 } from './audienceTypes';
import { isString } from 'ramda-adjunct';

/**
 * Create a copy of the given segment, resetting the appropriate fields.
 *
 * @param {ISegment} segment
 */
const cloneSegment = ({ id, name, description, ...rest }: ISegment) => ({
  ...rest,
  name: `${name} (Copy)`,
  description: `[Copy] ${description || ''}`,
  createdAt: null,
  createdBy: null,
  updatedAt: null,
  updatedBy: null,
  ingestState: null,
});

/**
 * Derive missing properties for an existing segment.
 *
 * Specifically, this is used to initialise an existing segment to be edited in the UI.
 * Certain properties or used by the UI but are not persisted, and so must be derived from existing properties.
 *
 * @param ISegment segment
 * @param {FeatureDicts} featureDicts
 * @return {ISegment} Copy of the input segment with additional derived properties
 */
const enhanceSegment = (segment: ISegment, featureDicts: FeatureDicts): ISegment => {
  if (segment.audienceType === segmentTypeFlags.RULE_BASED) {
    if (isEmpty(segment.ruleData || {}) && !isEmpty(segment.includedRules || {})) {
      const ruleData = toOrGroupDataStruct2(featureDicts, segment.includedRules);
      return {
        ...segment,
        includedRuleErrors: validateRuleData(ruleData),
        ruleData,
      };
    }
  }

  return { ...segment };
};

const openClonePage = (history: RouterHistory, { id, audience }: { id?: number; audience?: ISegment }) => {
  let state;
  if (audience) state = { audience: cloneSegment(audience) };
  else if (id !== undefined && isString(id)) state = { id };
  history.push({
    pathname: '/audience/new',
    state,
  });
};

/**
 * Audience types as returned by the API and stored in the Redux store.
 *
 * TODO: The codebase uses both these values and those in `audienceTypesV2`. They should be merged to use one set of values.
 */
const segmentTypeFlags = {
  CSV_BASED: 'STATIC',
  RULE_BASED: 'RULE_BASED',
  COMPOSITION: 'COMPOSITION',
};

const segmentTypesDisplayValue = {
  [segmentTypeFlags.CSV_BASED]: 'csv',
  [segmentTypeFlags.RULE_BASED]: 'rule',
  [segmentTypeFlags.COMPOSITION]: 'composition',
};

const segmentTypesColorCodes = {
  [segmentTypeFlags.CSV_BASED]: '60c06a',
  [segmentTypeFlags.RULE_BASED]: '2f9dc2',
  [segmentTypeFlags.COMPOSITION]: '2f9dc2',
};

/**
 * Convert the {@link segmentTypeFlags} value to the `audienceType` value.
 * @param {string} flag
 */
const toAudienceTypeState = (flag: 'RULE_BASED' | 'COMPOSITION ' | 'CSV_BASED'): ('csv' | 'rule' | 'composition') | null | undefined => {
  if (flag === segmentTypeFlags.CSV_BASED) return audienceTypesV2.csv;
  if (flag === segmentTypeFlags.RULE_BASED) return audienceTypesV2.rule;
  if (flag === segmentTypeFlags.COMPOSITION) return audienceTypesV2.composition;
  return undefined;
};

export {
  cloneSegment,
  enhanceSegment,
  openClonePage,
  segmentTypeFlags,
  segmentTypesDisplayValue,
  segmentTypesColorCodes,
  toAudienceTypeState,
};
