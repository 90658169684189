export default {
  getAll: '/tags',
  /**
   * Success:
   * Status = 201
   * Return value = { "id": {Number > 0} }
   *
   * Failure:
   * Status = 400
   * Return value = {
   *  "apierror": {
   *    "status": "BAD_REQUEST",
   *    "timestamp": "05-06-2018@14:34:08",
   *    "message": "Duplicated fields error",
   *    "debugMessage": "Duplicate entry 'E TEst Tag 2' for key 'name_UNIQUE', please use a different one",
   *    "details": "uri=/clients/paytm-india/tags",
   *    "subErrors": null
   *  }
   * }
   */
  post: '/tags',
  getV2Tags: '/audience/tag/list?:params',
};
