import * as React from 'react';

import { Row, Name, Criteria } from './CriteriaComponents';
import { IRuleCriterion } from '../types';
import { formatCriteriaString } from './formatCriteriaString';

type Props = {
  userCriteria: string | null | undefined;
  userCriteriaJson: Array<IRuleCriterion> | null | undefined;
  originalCriteria?: Record<string, any>;
};

export default ({ userCriteria, userCriteriaJson, originalCriteria }: Props) => {
  const userCriteriaStr = userCriteriaJson && userCriteriaJson.length > 0 ? userCriteriaJson : formatCriteriaString(userCriteria);
  const originalUserCriteria = originalCriteria
    ? originalCriteria.userCriteriaJson && originalCriteria.userCriteriaJson.length > 0
      ? originalCriteria.userCriteriaJson
      : formatCriteriaString(originalCriteria.userCriteria)
    : null;

  return (
    <Row>
      <Name>Real-time User Criteria</Name>
      <Criteria criteria={userCriteriaStr || 'N/A'} originalCriteria={originalUserCriteria} />
    </Row>
  );
};
