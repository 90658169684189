import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  updateDeeplink,
  updateLegacyDeeplink,
  updateDeeplinkExtra,
  updatePush2Content,
} from 'app/ducks/campaigns/campaign/push2Creative/actions';

import { Input as _Input } from 'app/midgarComponents';
import { DeeplinkSelection } from './../DeeplinkSelection';
import { emptyTemplate } from 'app/features/Personalization/TemplateSelection';
type Props = {
  showTemplateToggleV2: boolean;
  isDynamicTemplatesDeeplinkEnable: boolean;
};

const allEmptyTemplates = {
  legacyDeeplink: emptyTemplate,
  deeplink: emptyTemplate,
  title: emptyTemplate,
  body: emptyTemplate,
};

const Generic = ({ showTemplateToggleV2, isDynamicTemplatesDeeplinkEnable }: Props) => {
  const [currTemplates, setCurrTemplates] = React.useState(allEmptyTemplates);
  const {
    push2Creative: {
      deeplinkType,
      payload: {
        notification: {
          content,
          interactive: { click },
        },
      },
    },
  } = useSelector(
    ({
      campaigns: {
        campaign: { push2Creative },
      },
    }) => ({ push2Creative }),
  );

  const dispatch = useDispatch();
  const updateDeeplinkValue = ev => dispatch(updateDeeplink(ev.target.value.trim()));
  const updateLegacyDeeplinkValue = legacyDeeplink => dispatch(updateLegacyDeeplink(legacyDeeplink));
  const updateDeeplinkExtraValue = deeplinkExtra => dispatch(updateDeeplinkExtra(deeplinkExtra));

  const setCurrTemplate = (name: string) => temp => setCurrTemplates({ ...currTemplates, [name]: temp });

  const updateContentValue = (ev: Record<string, any>) => dispatch(updatePush2Content({ [ev.target.name]: ev.target.value }));

  const { body, title } = content;
  // todo: handle Templated here
  return (
    <>
      {deeplinkType === 'legacy' ? (
        <>
          <DeeplinkSelection
            deeplink={click.legacyDeeplink}
            setDeeplink={updateLegacyDeeplinkValue}
            updateDeeplinkExtra={updateDeeplinkExtraValue}
            selectedDeeplink={click.legacyDeeplink}
            showTemplateToggleV2={showTemplateToggleV2}
            disableTemplate={false}
            currTemplate={currTemplates.legacyDeeplink}
            setCurrTemplate={setCurrTemplate('legacyDeeplink')}
            isDeeplink
          />

          <Spacer />
        </>
      ) : (
        <>
          <Input value={click.value} name="deeplink" id="Deeplink" placeholder="Deeplink Url" onChange={updateDeeplinkValue} />
        </>
      )}

      <Input value={title} name="title" id="Title" placeholder="Title" onChange={updateContentValue} />
      <Input value={body} name="body" id="Message" placeholder="Message" onChange={updateContentValue} />
    </>
  );
};

const Input = styled(_Input)`
  height: auto;
`;
export default Generic;

const Spacer = styled.div`
  margin: 0.5rem;
`;
