import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';

type IOption = {
  value: string;
  label: string;
};

type Props = {
  handleChange: (arg0: string) => void;
  options: Array<IOption>;
  value: string;
};

export default class OptionsSwitch extends React.Component<Props> {
  handleClick = clickedValue => () => {
    const { handleChange, value: prevValue } = this.props;
    handleChange(clickedValue, prevValue);
  };

  render() {
    const { options, value } = this.props;
    return (
      <Container>
        <Switch>
          {options.map(option => (
            <Option
              key={option.value}
              data-qa={`switch-${option.label}`}
              selected={option.value === value}
              onClick={this.handleClick(option.value)}
            >
              {option.label}
            </Option>
          ))}
        </Switch>
      </Container>
    );
  }
}

const Container = styled.div`
  display: inline-block;
`;

const Switch = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: ${sc.gutterSmallest};
  background: ${sc.greyLighter};
  border-radius: 1rem;
`;

const Option = styled.span`
  font-size: ${sc.fontSizeSmaller};
  margin-right: ${sc.gutterSmallest};
  padding: 2px ${sc.gutterSmallest};
  background: ${props => (props.selected ? 'white' : 'inherit')};
  border-radius: 1rem;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background: #fff;
  }
`;
