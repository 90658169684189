import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import FilterMenu from 'app/components/FilterMenu';
import { mapIndexed } from 'app/helpers/RamdaHelpers/helpers';

export const BreadcrumbMenu = ({ labels = [], breadcrumbState, updatedAt, equals, idOf, nameOf, descOf, handleSelect, classes }) => {
  const commonProps = {
    equals,
    idOf,
    nameOf,
    descOf,
    handleSelect,
    classes,
  };

  return (
    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
      {mapIndexed(
        ({ options, selected }, index) => (
          <FilterMenu
            key={`menu-${updatedAt}-${index}`}
            id={`menu-${index}`}
            label={labels[index] || ''}
            options={options}
            selected={selected}
            groupIndex={index}
            {...commonProps}
          />
        ),

        breadcrumbState,
      )}
    </Grid>
  );
};

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    desc: PropTypes.string,
  }),
]);

BreadcrumbMenu.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  breadcrumbState: PropTypes.arrayOf(
    PropTypes.shape({
      options: PropTypes.arrayOf(valueType).isRequired,
      selected: valueType,
    }),
  ).isRequired,
  updatedAt: PropTypes.number.isRequired,
  equals: PropTypes.func.isRequired,
  idOf: PropTypes.func.isRequired,
  nameOf: PropTypes.func.isRequired,
  descOf: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
