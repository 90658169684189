import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { inject } from 'app/decorators';
import { TextField as _TextField, Typography } from '@material-ui/core';
import Categories from 'app/features/Campaigns/Measurements/Categories';
import * as campaignOperations from 'app/ducks/campaigns/campaign/operations';
import { compose } from 'recompose';

class OrderAbandon extends React.PureComponent<{
  campaignOperations: {
    setCriteria: (...args: Array<any>) => any;
  };

  criteria: {
    orderCategories: Array<any>;
    triggerTimeInMinutes: number;
  };
}> {
  remove = (category, categorylist) => categorylist.filter(cat => cat.id !== category.id);

  render() {
    const {
      campaignOperations: { setCriteria },
      criteria: { orderCategories, triggerTimeInMinutes },
    } = this.props;

    return (
      <>
        <FlexWrapper>
          <Typography style={{ marginRight: '1rem' }}>Trigger Time </Typography>
          <TextField
            required
            min={30}
            type="number"
            id="triggerTimeInMinutes"
            label=""
            onChange={ev => setCriteria({ triggerTimeInMinutes: ev.target.value })}
            value={triggerTimeInMinutes}
          />

          <BottomLine>minutes after non-completion of payment.</BottomLine>
        </FlexWrapper>
        <CategoriesWapper>
          <Categories
            label="Order Categories"
            selected={orderCategories}
            onSelect={category => setCriteria({ orderCategories: [...orderCategories, category] })}
            onRemove={category => setCriteria({ orderCategories: this.remove(category, [...orderCategories]) })}
          />
        </CategoriesWapper>
      </>
    );
  }
}

export default compose(
  connect(
    ({
      campaigns: {
        campaign: {
          general: { criteria },
        },
      },
    }) => ({
      criteria,
    }),
  ),

  inject({ campaignOperations }),
)(OrderAbandon);

const TextField = styled(_TextField)`
  & input {
    margin-top: -13px; /** Offset the inherited 13 px padding.*/
  }
`;

const FlexWrapper = styled.section`
  display: flex;
`;

const BottomLine = styled.span`
  vertical-align: bottom;
`;

const CategoriesWapper = styled.section`
  margin: 1rem 0 5rem 0;
  max-width: 600px;
`;
