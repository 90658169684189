import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FieldArray, useFormikContext } from 'formik';
import { Pills } from 'app/midgarComponents';
import { getAllCategories } from 'app/ducks/categories/operations';
import { CategoriesState } from '../../../utilities/interfaces';
import { GeneralFormikProps } from '../../../utilities/types/formik';

type Props = {
  label?: string;
  selected?: Array<object>;
  onSelect?: Function;
  onRemove?: Function;
};

const Categories: React.FC<Props> = ({ label = 'Categories', selected, onSelect, onRemove }) => {
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch();

  const { categories } = useSelector((state: CategoriesState) => state);
  const mappedCategories = React.useMemo(
    () => (Array.isArray(categories.allIds) ? categories.allIds.map((id: string) => categories.byId[id]) : []),
    [categories.allIds, categories.byId],
  );

  const fetchCategories = React.useCallback(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  React.useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const parentFormikContext = useFormikContext<GeneralFormikProps>();
  const baseComponentProps = {
    id: 'categories',
    name: 'categories',
    required: true,
    label,
    limit: 10,
    suggestions: mappedCategories,
    loading: categories.loading,
    value,
    onChange: (ev: React.ChangeEvent<HTMLInputElement>): void => setValue(ev.target.value),
  };

  // TODO: Make midgarComponents integrate with formik Fields/FieldArrays better
  const resolveCategoriesContext = (): JSX.Element => {
    if (parentFormikContext) {
      const { values, setFieldTouched, touched, errors } = parentFormikContext;

      const selectCategorySuggestion = (suggestion: object, push: Function): void => {
        push(suggestion);
        setFieldTouched('categories');
      };

      const removeCategorySuggestion = (suggestion: { id: string | number }, remove: Function): void => {
        const foundIndex = values.categories.findIndex(({ id }: { id: string | number }) => id === suggestion.id);
        remove(foundIndex);
        setFieldTouched('categories');
      };

      return (
        <FieldArray name="categories">
          {({ push, remove }): JSX.Element => (
            <Pills
              {...baseComponentProps}
              error={touched.categories && errors.categories}
              selected={values.categories}
              onSelect={(suggestion: object): Function | void => selectCategorySuggestion(suggestion, push)}
              onRemove={(suggestion: { id: string | number }): Function | void => removeCategorySuggestion(suggestion, remove)}
            />
          )}
        </FieldArray>
      );
    } else return <Pills {...baseComponentProps} selected={selected} onSelect={onSelect} onRemove={onRemove} />;
  };

  return <section data-qa="categories-component">{resolveCategoriesContext()}</section>;
};

export default Categories;
