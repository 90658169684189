import * as React from 'react';
import styled from 'styled-components';
import { formatDateTime, parseDateTimeUtc } from 'app/utilities/date';
import { sc } from 'app/styles';

const formatTimestamp = (ts, timezone) => (timezone ? formatDateTime(parseDateTimeUtc(ts), timezone, "DD MMM'YY") : ts);

export default class CreatedOn extends React.PureComponent<{
  createdAt: string;
  createdBy: string;
  timezone: string;
}> {
  render() {
    const { createdAt, createdBy, timezone } = this.props;
    return (
      <>
        <CreatedAt>{formatTimestamp(createdAt, timezone)}</CreatedAt>
        <CreatedBy>{createdBy || 'N/A'}</CreatedBy>
      </>
    );
  }
}

const CreatedAt = styled.p`
  font-size: ${sc.fontSize};
  line-height: 1.25;
  color: ${sc.bodyColor};
`;

const CreatedBy = styled.p`
  font-size: ${sc.fontSizeSmaller};
  line-height: 1.33;
  color: ${sc.headingColor};
`;
