import styled from 'styled-components';
import { sc } from 'app/styles';
import { Grid as _Grid, PageMain as _PageMain, Spinner as _Spinner } from 'app/midgarComponents';

// TODO: Allow for margins when using dividers (now you need to add to '11' as a hack)
export const Grid = styled(_Grid)`
  ${props => {
    const css = props.css || '';

    if (props.container) {
      return `
        width: 100%;
        ${
          props.dividers
            ? `
          & > section { /* Do I need '&' here? */
            padding-right: ${Number(props.spacing || '0') / 2}px;
            margin-right: ${Number(props.spacing || '0') / 2}px;
          }
          & > section:last-child {
            padding-right: 0;
            margin-right: 0;
            border-right: none;
          }
        `
            : ''
        }
        ${css}`;
    }

    return css;
  }}
`;

export const PageMain = styled(_PageMain)`
  height: 100%;
  background-color: ${sc.sectionWhiteColor};
  padding: 3rem;
`;

export const Spinner = styled(_Spinner).attrs(() => ({
  size: 50,
}))`
  padding: 2rem;
  background-color: ${sc.sectionWhiteColor};
`;
