import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ErrorMetricsChart: React.FC = () => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    fetch('/api/error-metrics')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch error metrics data');
        }
        return response.json();
      })
      .then((data: any) => {
        if (!chartRef.current) return;

        const { min_ts_errors, min_lint_errors } = data;

        const chartData = {
          labels: Object.keys(data.historical_data),
          datasets: [
            {
              label: `TS Errors: ${min_ts_errors}`,
              data: Object.keys(data.historical_data).map(date => data.historical_data[date].ts_errors),
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              fill: true,
              tension: 0.1,
            },
            {
              label: `Lint Errors: ${min_lint_errors}`,
              data: Object.keys(data.historical_data).map(date => data.historical_data[date].lint_errors),
              borderColor: 'rgb(54, 162, 235)',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              fill: true,
              tension: 0.1,
            },
          ],
        };

        const chartOptions = {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Error Metrics Over Time',
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem: any) => {
                  const { datasetIndex, raw, label } = tooltipItem;
                  return `${datasetIndex === 0 ? 'TS Errors' : 'Lint Errors'} on ${label}: ${raw}`;
                },
              },
            },
          },
          scales: {
            x: {
              type: 'category',
              title: {
                display: true,
                text: 'Date',
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 10,
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Count',
              },
            },
          },
        };

        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          new Chart(ctx, {
            type: 'line',
            data: chartData,
            // @ts-ignore
            options: chartOptions,
          });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    return () => {
      if (chartRef.current) {
        const ctx = chartRef.current.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, chartRef.current.width, chartRef.current.height);
        }
      }
    };
  }, []);

  return (
    <div style={{ height: '400px', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '200px' }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default ErrorMetricsChart;
