export const validateFileContents = (contents: string, requiredColumnNames: Array<string>): { isValid: boolean; errorMessage?: string } => {
  const rows = contents.split(/\r?\n/);
  if (rows.length < 2) {
    return {
      isValid: false,
      errorMessage: `File must contain the header row and at least one ID. (File contains ${rows.length} rows)`,
    };
  }

  const headerRow = rows[0].trim();
  const headerCols = headerRow.split(',').map(col => col.trim());
  if (headerCols.length === 0 || !requiredColumnNames.includes(headerCols[0])) {
    const errorMessage =
      requiredColumnNames.length > 1
        ? `Header row must be one of: [${requiredColumnNames.join(', ')}]`
        : requiredColumnNames.length === 1
        ? `Header row must be: ${requiredColumnNames[0]}`
        : 'No required headers have been configured. This is an app-wide error. Please contact support.';
    return {
      isValid: false,
      errorMessage,
    };
  }

  const duplicatedHeaders = getDuplicatedHeaders(headerCols);
  if (duplicatedHeaders.length) {
    return {
      isValid: false,
      errorMessage: `Header contains duplicate values: ${duplicatedHeaders.join(', ')}`,
    };
  }

  const hasNonEmptyRow = rows.slice(1).some(row => row.trim().length > 0);
  if (hasNonEmptyRow) {
    return {
      isValid: true,
    };
  }

  return {
    isValid: false,
    errorMessage: `File contains no non-empty data rows. Total data rows = ${rows.length - 1}`,
  };
};

export const getDuplicatedHeaders = (cols: Array<string> = []): Array<string> => {
  if (!Array.isArray(cols)) {
    return [];
  }
  const set = {};
  cols.forEach(col => {
    if (set[col]) {
      set[col] += 1;
    } else {
      set[col] = 1;
    }
  });
  return Object.keys(set).filter((col: string) => set[col] > 1);
};
