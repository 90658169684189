export const styles = theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
  },

  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  button: {
    marginTop: '1rem',
    marginLeft: '1rem',
  },
});
