const styles = theme => ({
  root: {
    width: '100%',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  button: {
    margin: theme.spacing(1),
  },

  leftIcon: {
    marginRight: theme.spacing(1),
  },

  rightIcon: {
    marginLeft: theme.spacing(1),
  },

  fab: {
    margin: theme.spacing(2),
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  tableContainer: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },

  table: {
    minWidth: 700,
  },

  badge: {
    margin: `0 ${theme.spacing(2)}px`,
  },

  avatar: {
    margin: 10,
  },

  row: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export { styles };
