const ROOT = '/ec/viewitem';
const forViewItem = (viewItemId: number) => `${ROOT}/${viewItemId}`;

export const GET_LANDING_TYPES = `${ROOT}/landing-types`;

export const GET_SLOT_GROUP_TYPES = `${ROOT}/types`;

export const GET_TAGS = `${ROOT}/tags`;

export const GET_VARIANT_TYPES = `${ROOT}/variant-types`;

export const GET_VIEW_ITEM = forViewItem;

export const PUT_VARIANT = (viewItemId: number) => `${forViewItem(viewItemId)}/variant`;

export const PUT_VARIANT_STATUS = (viewItemId: number) => `${forViewItem(viewItemId)}/variant-status`;

export const POST_VIEW_ITEM = ROOT;

export const PUT_VIEW_ITEM = GET_VIEW_ITEM;
