import { withProps, withStateHandlers, lifecycle, compose } from 'recompose';
import { filterBy, R } from 'app/helpers/RamdaHelpers/helpers';

import { FilterMultiSelect } from './FilterMultiSelect';

const addProps = withProps({
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },

  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
});

const addStateHandlers = withStateHandlers(
  ({ options, value = [] }) => ({
    options,
    selections: value,
    isDropdownOpen: false,
  }),

  {
    handleSelect:
      ({ selections }, { onChange }) =>
      (event, clicked) => {
        const newSelections = selections.includes(clicked) ? R.without([clicked], selections) : [...selections, clicked];
        onChange(newSelections);

        return {
          selections: newSelections,
        };
      },
    handleOpen: () => () => {
      return {
        isDropdownOpen: true,
      };
    },
    handleClose:
      (_, { options }) =>
      () => ({
        options,
        isDropdownOpen: false,
      }),

    handleFilter:
      (_, { options: allOptions }) =>
      ({ target: { value } }) => ({
        options: filterBy(value, allOptions),
      }),
  },
);

const updateOnValueChange = lifecycle({
  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) this.setState({ selections: nextProps.value });
  },
});

const enhance = compose(addProps, addStateHandlers, updateOnValueChange);

export default enhance(FilterMultiSelect);
