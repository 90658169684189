import * as React from 'react';
import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@material-ui/core';
import Loading from '../Loading';

export const LoadingTable = ({ columns }) => (
  <TableRow>
    <TableCell colSpan={columns.length}>
      <Loading />
    </TableCell>
  </TableRow>
);

LoadingTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};
