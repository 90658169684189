import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';

export const OrSeparator = ({ classes }) => (
  <div className={classes.row}>
    <Avatar className={classes.avatar}>OR</Avatar>
  </div>
);

OrSeparator.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
