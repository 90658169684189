import React from 'react';
import PropTypes from 'prop-types';

import { OrRuleGroup, SegmentCount } from 'app/components';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';

const onCalcuate = (validateRules, calculate) => () => {
  const { isValid, errorMessage, rules } = validateRules();

  if (isValid) calculate(rules);
  else displayError(errorMessage);

  return isValid;
};

export const Rules = ({
  rules,
  errors = [],
  pathDict,
  originDict,
  pathsOfFeature,
  optionsByPathKey,
  newRowValue,
  initialRuleForGroup,
  updateState,
  validateRules,
  countOfQuery,
  classes,
}) => (
  <section className={classes.customContainer}>
    <SegmentCount calculate={onCalcuate(validateRules, countOfQuery)} classes={classes} calcOnLoad={false} />
    <OrRuleGroup
      data={rules}
      newItem={newRowValue}
      {...{
        errors,
        pathDict,
        originDict,
        pathsOfFeature,
        optionsByPathKey,
        initialRuleForGroup,
        updateState,
      }}
    />
  </section>
);

Rules.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  pathDict: PropTypes.objectOf(PropTypes.array).isRequired,
  originDict: PropTypes.objectOf(PropTypes.object).isRequired,
  pathsOfFeature: PropTypes.func.isRequired,
  optionsByPathKey: PropTypes.func.isRequired,
  newRowValue: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialRuleForGroup: PropTypes.shape({
    key: PropTypes.number.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  updateState: PropTypes.func.isRequired,
  validateRules: PropTypes.func.isRequired,
  countOfQuery: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
