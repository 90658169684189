import { IDeeplink } from 'app/types';
import * as types from './types';

export const getDeepLinks = () => ({
  type: types.GET_ALL_DEEPLINKS,
});

export const getDeepLinksSuccess = (deeplinks: Array<IDeeplink>) => ({
  type: types.GET_ALL_DEEPLINKS_SUCCESS,
  payload: { deeplinks },
});

export const getDeepLinksFailed = (error: Error) => ({
  type: types.GET_ALL_DEEPLINKS_FAILED,
  payload: { error },
});
