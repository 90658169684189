import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormControlLabel, RadioGroup as _RadioGroup, Radio } from '@material-ui/core';
import { sc } from 'app/styles';
import { reverseTypes } from 'app/features/Cashback/constants';
import { Icon } from 'app/midgarComponents';
import { getJourneyReversalStrategy } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { updateJourneyReversalStrategy } from 'app/ducks/campaigns/campaign/cashbackCreative/actions';

type Props = {
  reversalStrategy: string;
  updateStrategy: (arg0: { reverseType: string }) => void;
};

export const Reverse = (props: Props) => {
  const { reversalStrategy, updateStrategy } = props;

  return (
    <Section>
      <Label>
        Reverse Strategy
        {reversalStrategy ? (
          <ValidationIcon name="check-circle" size={22} color={sc.success} />
        ) : (
          <ValidationIcon name="warning" size={22} color={sc.warning} />
        )}
      </Label>

      <Wrapper>
        <RadioGroup
          aria-label="Fulfillment"
          name="type"
          value={reversalStrategy}
          onChange={e => {
            updateStrategy({ reverseType: e.target.value });
          }}
          style={{ display: 'inline-block' }}
        >
          {Object.values(reverseTypes).map((type: Record<string, any>) => (
            <FormControlLabel key={type.value} value={type.value} control={<Radio />} label={type.label} />
          ))}
        </RadioGroup>
      </Wrapper>
    </Section>
  );
};

const mapStateToProps = state => ({
  reversalStrategy: getJourneyReversalStrategy(state),
});

const mapDispatchToProps = { updateStrategy: updateJourneyReversalStrategy };

const ReverseContainer = connect(mapStateToProps, mapDispatchToProps)(Reverse);

export default ReverseContainer;

const ValidationIcon = styled(Icon)`
  margin-top: -4px;
  margin-left: 0.5rem;
  font-weight: 900;
`;

const Label = styled.div`
  text-transform: uppercase;
  color: ${sc.grey};
  margin: 0 1rem 1rem 0;
  text-align: left;
  font-size: ${sc.fontSize};
`;

const RadioGroup = styled(_RadioGroup)`
  display: inline-block;
`;

const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${sc.primary};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;
