import { IVariantType } from 'app/types/BannerManagement';

import { State } from './reducers';

type Store = { bannerManagement: { variantTypes: State } };

export const mapStateToVariantTypes = ({
  bannerManagement: {
    variantTypes: { error: variantTypesError, loading: variantTypesLoading, variantTypes },
  },
}: Store): {
  variantTypesError: string | null | undefined;
  variantTypesLoading: boolean;
  variantTypes: Array<IVariantType>;
} => ({
  variantTypesError,
  variantTypesLoading,
  variantTypes,
});
