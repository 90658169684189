import { IExecutionsResult } from 'app/types';
import { triggerTypes } from 'app/utilities/constants';
import * as cma from 'app/utilities/http';

import endpoints from './endpoints';
import { IMasterplanApiParams, IPostPriorityPayload, IPrioritiesApiParams, IPrioritiesResult } from './types';
import * as mockExecutions from './executions.mock';
import * as mockPriorities from './priorities.mock';

// We need the mock when development locally because staging data isn't very useful
const MOCK = false;

export const fetchExecutions = async (params: IMasterplanApiParams): Promise<IExecutionsResult> => {
  const { trigger } = params;
  if (trigger.includes(triggerTypes.location) || trigger.includes(triggerTypes.cartAbandon)) {
    throw Error(`trigger=[${trigger}]: Coordinator only supports the triggers [${triggerTypes.oneTime}, ${triggerTypes.recurring}]`);
  }
  return MOCK ? mockExecutions.fetchExecutions(params) : cma.get(endpoints.executions(params));
};

export const fetchPriorities = async (params: IPrioritiesApiParams): Promise<IPrioritiesResult> =>
  MOCK ? mockPriorities.fetchPriorities(params) : cma.get(endpoints.priorities(params));

export const fetchSystemPriorities = async (params: IPrioritiesApiParams): Promise<IPrioritiesResult> =>
  MOCK ? mockPriorities.previewSystemPriorities(params) : cma.get(endpoints.priorities({ ...params, previewPlan: true }));

export const postPriorities = async (params: IPrioritiesApiParams, payload: IPostPriorityPayload): Promise<void> =>
  cma.put(endpoints.priorities(params), payload);
// mockPriorities.savePriorities(params, manualPriorities);
