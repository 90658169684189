import { ICmaStorefront } from 'app/api/storefront/types';

import { State } from './state';

type Store = {
  storefrontsCma: {
    storefronts: State;
  };
};

export const mapStateToStorefronts = ({
  storefrontsCma: {
    storefronts: { error: storefrontsError, loading: storefrontsLoading, permittedStorefronts, storefronts },
  },
}: Store): {
  permittedStorefronts: Array<ICmaStorefront> | null | undefined;
  storefronts: Array<ICmaStorefront> | null | undefined;
  storefrontsError: string | null | undefined;
  storefrontsLoading: boolean;
} => ({
  permittedStorefronts,
  storefronts,
  storefrontsError,
  storefrontsLoading,
});
