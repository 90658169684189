import * as React from 'react';
import styled from 'styled-components';

import Button from 'app/midgarComponents/Button';
import Icon from 'app/midgarComponents/Icon';
import { sc } from 'app/styles';

const Footer = styled.div`
  padding: ${sc.gutter};
  display: flex;
  justify-content: flex-end;
`;

const SearchContainer = styled.section`
  margin-bottom: ${sc.gutterLarge};
`;

const SearchControls = styled.section`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* TODO: Leave margin-bottom to be overridden as needed. Not needed if this is the only child of SearchContainer, i.e. no SearchBar */
  margin-bottom: ${sc.gutterSmaller};

  @media screen and (min-width: 600px) {
    & > div,
    & > section {
      margin-right: ${sc.gutterSmaller};
    }
    & > div:last-child,
    & > section:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 600px) {
    && > div,
    && > section {
      width: 100%;
      margin-bottom: ${sc.gutterSmaller};
    }
  }
`;

const SpacedGroup = styled.div`
  & > * {
    margin-right: ${sc.gutter};
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

const Switches = styled(SpacedGroup)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: ${sc.gutter};
`;

type CreateNewButtonProps = {
  className?: string;
  to: string;
};

const CreateNewButton = ({ className, to, ...otherProps }: CreateNewButtonProps) => (
  <Button className={className} to={to} type="primary" data-qa="create-new-button" {...otherProps}>
    <Icon name="add" color="white" />
    Create New
  </Button>
);

export { CreateNewButton, Footer, SearchContainer, SearchControls, SpacedGroup, Switches };
