import styled from 'styled-components';

import { Autocomplete as _Autocomplete, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';

export const AutocompleteWithDisabled = styled(_Autocomplete)`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

export const ErrorContainer = styled.div`
  color: ${sc.danger};
  font-style: italic;
  margin-top: ${sc.gutterSmall};
`;

export const ErrorText = styled.span`
  color: ${sc.danger};
  font-style: italic;
`;

export const IconLink = styled(Icon)`
  color: ${sc.subHeadingColor};
  &:hover {
    color: ${props => !props.disabled && sc.primary};
    cursor: pointer;
  }
`;

export const Title = styled.h4`
  margin-bottom: ${sc.gutterSmallest};
`;
