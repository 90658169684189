import { ISearchDefs } from 'app/hocs/withReporting';
import { audienceTypesV2 } from 'app/utilities/constants';

export const searchTypeAudienceType = 'type';
export const searchTypeCreatedBy = 'creator';
export const searchTypeName = 'name';

export const searchDefs: ISearchDefs = [
  { name: searchTypeAudienceType, label: 'By Type', options: [audienceTypesV2.rule, audienceTypesV2.csv, audienceTypesV2.composition] },
  { name: 'id', label: 'By Audience ID' },
  { name: searchTypeName, label: 'By Name' },
  { name: 'tag', label: 'By Tag' },
  { name: searchTypeCreatedBy, label: 'By Creator' },
];

export const searchBarDefs: ISearchDefs = searchDefs.filter(def => def.name !== searchTypeAudienceType);

export const defaultSearch = ({ userEmail }: { userEmail: string }) => ({
  [searchTypeAudienceType]: audienceTypesV2.rule,
  searchBy: 'creator',
  searchTerm: userEmail,
});
