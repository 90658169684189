import { post, get } from 'app/utilities/http';

import { createSeedData, R } from 'app/helpers/FeatureMetadataHelpers/helpers';

import { enrichFeatureReduxState } from 'app/helpers/FeatureMetadataHelpers/dictBuilder';

import { unixTimeWithMillis } from 'app/helpers/RamdaHelpers/helpers';

import { echoLog } from 'app/helpers/DebugHelpers/helpers';

import { fromApiEventSchema } from 'app/api/schemaRegistry/mappers';

import { displayError } from 'app/helpers/NotificationHelpers/helpers';

import { getEvents } from 'app/api/schemaRegistry';

import { getEventSchema } from '../cashbackPromo/selectors';

import { loading, succeedWith, failWith, setRealTimeSchemaData } from './actions';
import { getAllSuccess, getAllFail } from '../segments2/audienceRules/actions';

import { shouldRefresh } from './selectors';

// dict gen switches
const usingServerSideDictionaries = true;

const onFailure = (type, error) => {
  const message = usingServerSideDictionaries
    ? 'Error retrieving or enriching feature dictionaries'
    : 'Error retrieving or rebuilding feature metadata';
  console.error(message, error); // eslint-disable-line no-console

  return type === 'audience_v2' ? getAllFail(error) : failWith(error);
};

const onError = R.curry((type, dispatch, error) => {
  dispatch(onFailure(type, error));
});

const onRebuildSuccess = R.curry((type, dispatch, startTime, { dicts, ...otherData }) => {
  const elapse = unixTimeWithMillis() - startTime;
  echoLog(dicts, 'Feature dictionaries have been refreshed, with elapsed time of', elapse);
  if (type === 'audience_v2') {
    dispatch(
      getAllSuccess({
        ...otherData,
        dicts,
        seed: createSeedData(dicts),
      }),
    );
  } else {
    dispatch(
      succeedWith({
        ...otherData,
        dicts,
        seed: createSeedData(dicts),
      }),
    );
  }
});

const onSuccessFetchFeatureDicts = (type, dicts, startTime, dispatch) => {
  echoLog(usingServerSideDictionaries, 'Using server-side feature dictionaries?');
  try {
    onRebuildSuccess(type, dispatch, startTime, enrichFeatureReduxState(dicts));
  } catch (error) {
    onError(type, dispatch, error);
  }
};

const buildResponse = (type, response, startTime, dispatch) => {
  try {
    let i = 0;
    const tempObjVerticals = {};
    const tempObjOperators = {};
    const tempObjExpressions = {};
    const pathDict = {};
    const optionDict = { _VirtualRoot_: response?.verticals?.map(vertical => vertical.displayName) };
    const originDict = {};
    const dataTypeDict = {};
    const operatorTypes = {};
    const expressionsDict = {};

    response?.operators?.map(
      value =>
        (tempObjOperators[value.id] = value.values.map(operator => {
          operatorTypes[operator.displayValue] = operator.value;
          operatorTypes[operator.value] = operator.displayValue;
          return operator.displayValue;
        })),
    );

    response?.expressions?.map(value => {
      value.operatorId = tempObjOperators[value.operatorId];
      tempObjExpressions[value.id] = value;
      expressionsDict[value.code] = value;
    });

    response?.verticals?.map(value => {
      const tempArr1 = [value.displayName];
      optionDict[value.displayName] = value.categories.map(category => category.displayName);
      value.categories.map(category => {
        const tempArr2 = [...tempArr1, category.displayName];
        optionDict[tempArr2.join('.')] = category.querySelectors.map(query => {
          i = i++;
          return {
            feature_desc: query.displayName,
            id: i,
            label: query.displayName,
            value: query.code,
          };
        });
        category.querySelectors.map(query => {
          pathDict[query.code] = tempArr2;
          const tempArr3 = [...tempArr2, query.code];
          const expressionArr = [];
          const expressionDetails = query.expressionIds.map(expression => {
            expressionArr.push({
              category: tempArr2[1],
              client_id: 'paypay-consumer',
              data_type: tempObjExpressions[expression].operatorCode === 'idOperator' ? 'number' : tempObjExpressions[expression].type,
              feature_class: '',
              feature_desc: query.displayName,
              feature_id: i,
              feature_label: query.displayName,
              feature_name: query.code,
              last_updates: '', // 8601
              name: query.code,
              nested_types: [],
              status: true,
              vertical: tempArr2[0],
            });

            dataTypeDict[query.code + '.' + tempObjExpressions[expression].code] = {
              dataType: tempObjExpressions[expression].operatorCode === 'idOperator' ? 'number' : tempObjExpressions[expression].type,
              originalDataType: tempObjExpressions[expression].type,
              featureId: i,
              label: 'Value',
            };

            return {
              key: query.code + '.' + tempObjExpressions[expression].code,
              label: 'Operator',
              name: tempObjExpressions[expression].code,
              options: tempObjExpressions[expression].operatorId,
              type: tempObjExpressions[expression].type,
            };
          });
          i = i + 1;
          optionDict[tempArr3.join('.')] = expressionDetails;
          dataTypeDict[query.code] = {
            dataType: 'nested',
            originalDataType: 'nested',
            featureId: i,
            label: 'Value',
          };

          originDict[query.code] = {
            category: tempArr2[1],
            client_id: 'paypay-consumer',
            data_type: 'Nested',
            feature_class: '',
            feature_desc: query.displayName,
            feature_id: i,
            feature_label: query.displayName,
            feature_name: query.code,
            last_updates: '',
            name: query.code,
            nested_types: expressionArr,
            status: true,
            vertical: tempArr2[0],
          };
        });
      });
      tempObjVerticals[value.code] = value;
    });

    const dicts = {
      dataTypeDict,
      optionDict,
      pathDict,
      originDict,
      operatorTypes,
      expressionsDict,
    };

    onSuccessFetchFeatureDicts(type, dicts, startTime, dispatch);
  } catch (error) {
    console.log(error);
    onError(type, dispatch, error);
  }
};

const endpoints = (root => ({
  features: root,
  serverSideDicts: `${root}/dicts`,
}))('/features');

const refreshFeatureMetadata = () => (dispatch, getState) => {
  const storeState = getState();

  if (shouldRefresh(storeState)) {
    dispatch(loading());

    const {
      user: { permissions },
    } = storeState;
    const startTime = unixTimeWithMillis();
    // const [endpoint, onSuccess] = usingServerSideDictionaries
    // ? [endpoints.serverSideDicts, ({ dicts }) => onSuccessFetchFeatureDicts('audience_v1', dicts, startTime, dispatch)]
    // : [endpoints.features, ({ features }) => onSuccessFetchFeatures(features, dispatch)];
    const [querySelectorEndpoint, onSuccessQuerySelector] = [
      '/query-selector',
      data => buildResponse('audience_v2', data, startTime, dispatch),
    ];

    const [endpoint, onSuccess] = [
      endpoints.serverSideDicts,
      ({ dicts }) => onSuccessFetchFeatureDicts('audience_v1', dicts, startTime, dispatch),
    ];

    get(querySelectorEndpoint, { permissions }).then(onSuccessQuerySelector).catch(onError('audience_v2', dispatch));
    post(endpoint, { permissions }).then(onSuccess).catch(onError('audience_v1', dispatch));
  }
};

const refreshSchemaMeta = id => async (dispatch, getState) => {
  const {
    campaigns: {
      campaign: {
        general: {
          criteria: { eventId, eventName },
        },
      },
    },
  } = getState();
  let selectedEventId = id || eventId;

  if (!selectedEventId && !eventName) {
    return dispatch(setRealTimeSchemaData([], eventId, eventName));
  }

  try {
    if (eventName && !selectedEventId) {
      const events = await getEvents();
      const selectedEvent = events.find(ev => ev.eventName === eventName);

      if (selectedEvent) {
        selectedEventId = selectedEvent.eventId;
      }
    }

    const { variables, schemaId } = await getEventSchema(selectedEventId, eventName);
    dispatch(setRealTimeSchemaData(fromApiEventSchema(variables), selectedEventId, eventName, schemaId));
  } catch {
    dispatch(setRealTimeSchemaData([], eventId, eventName));
    displayError(`Failed fetching schema for event: ${eventName}`);
  }
};

export { refreshFeatureMetadata, refreshSchemaMeta };
