import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { creativeActions } from 'app/ducks';
import { Separator } from 'app/features/BannerManagement/common/components';
import { ColorField, TextField } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Action } from 'app/types';

class Fields extends React.PureComponent<{
  label: string;
  labelBackgroundColor: string;
  labelTextColor: string;
  readOnly: boolean;
  shouldValidate: boolean;
  updateVariantsLabelField: (index: number, field: string, value: any) => Action;
  variantFieldHasError: (variantKey: string, fieldName: string) => boolean;
  variantKey: string;
  widgetType: string;
}> {
  render() {
    const {
      label,
      labelBackgroundColor,
      labelTextColor,
      readOnly,
      shouldValidate,
      updateVariantsLabelField,
      variantFieldHasError,
      variantKey,
      widgetType,
    } = this.props;

    const allowedWidgetTypes = [
      'smart-icon-group-grid',
      'smart-icon-button',
      'smart-icon-list',
      'smart-icon-header',
      'smart-icon-grid',
      'list-small-ti',
      'smart-icon-grid-4xn',
      'promo-widget',
    ];

    if (!allowedWidgetTypes.includes(widgetType)) return null;

    return (
      <Container>
        <Separator>
          <span>Icon Label</span>
        </Separator>

        <div>
          <LabelField>
            <TextField
              disabled={readOnly}
              error={shouldValidate && variantFieldHasError(variantKey, 'icon-label')}
              id="icon-label"
              label="Label"
              name="icon-label"
              onChange={ev => updateVariantsLabelField(Number(variantKey), 'label', ev.target.value)}
              type="text"
              value={label || ''}
            />
          </LabelField>

          <InlineField>
            <ColorField
              disabled={readOnly || !label}
              error={shouldValidate && variantFieldHasError(variantKey, 'icon-label-color')}
              id="icon-label-color"
              label="Text Color"
              name="icon-label-color"
              onChange={color => updateVariantsLabelField(Number(variantKey), 'labelTextColor', color)}
              value={labelTextColor || ''}
            />
          </InlineField>

          <InlineField>
            <ColorField
              disabled={readOnly || !label}
              error={shouldValidate && variantFieldHasError(variantKey, 'icon-label-bg-color')}
              id="icon-label-bg-color"
              label="Background Color"
              name="icon-label-bg-color"
              onChange={color => updateVariantsLabelField(Number(variantKey), 'labelBackgroundColor', color)}
              value={labelBackgroundColor || ''}
            />
          </InlineField>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (
  {
    bannerManagement: {
      creative: { form },
    },
  },

  { variantKey },
) => {
  const variant = form.variants[variantKey];
  const widgetType = variant.widgetType || form.widgetType;
  return {
    label: variant.labelDetails.label,
    labelBackgroundColor: variant.labelDetails.labelBackgroundColor,
    labelTextColor: variant.labelDetails.labelTextColor,
    widgetType,
  };
};

const mapDispatchToProps = {
  updateVariantsLabelField: creativeActions.updateVariantsLabelField,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fields);

const Container = styled.div`
  position: relative;
  margin-top: ${sc.gutter};
`;

const InlineField = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 28%;
  margin-right: 2%;

  &:last-child {
    margin-right: 0;
  }
`;

const LabelField = styled(InlineField)`
  width: 40%;
`;
