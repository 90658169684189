import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { SectionContainer } from '../../SectionContainer';

export default class ChannelDetails extends PureComponent<{
  OverviewComponent: any;
}> {
  render() {
    const { OverviewComponent, ...rest } = this.props;

    return (
      <Container>
        <Header>Channel Details</Header>
        <OverviewComponent {...rest} />
      </Container>
    );
  }
}

const Container = styled(SectionContainer)`
  margin-top: 10px;
`;

const Header = styled.h2`
  height: 70px;
  font-size: 18px;
  font-weight: 900;
  color: #29394f;
`;
