import * as React from 'react';
import { connect } from 'react-redux';
import Calendar from 'rc-calendar';
import TimePanel from 'rc-time-picker/lib/Panel';
import Picker from 'rc-calendar/lib/Picker';
import moment from 'moment';
import Moment from 'moment';

import { dateTimeCmaFormat, dateTimeSecFormat, tzUtc, defaultDateTimeFormat, defaultDateFormat, defaultTimeFormat } from 'configs/dateTime';

import TextField from '../TextField/';

type Props = {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  getCalendarContainer: () => React.ReactNode;
  id?: string;
  label: string;
  name: string;
  onChange?: (arg0: Moment) => any;
  required?: boolean;
  timezone: string;
  disabledDate?: (arg0: Moment) => boolean;
  showToday?: boolean;
  value: string; // ISO-8601 format without timezone, `yyyy-MM-ddThh:mm:ss`, e.g. 2019-08-31T22:16:00,
  keepDefaultFormat?: boolean;
  splitOnChangeDate?: boolean;
};

class DateTimeUtcField extends React.Component<Props> {
  now = moment();

  onChange = (m: Moment) => {
    let dateOnChangeValue = '';
    const { onChange, splitOnChangeDate } = this.props;
    if (m && onChange) {
      if (splitOnChangeDate) {
        dateOnChangeValue = {
          date: `${m.clone().tz(tzUtc).format(defaultDateFormat)}`,
          time: `${m.clone().tz(tzUtc).format(defaultTimeFormat)}`,
        };
      } else {
        dateOnChangeValue = `${m.clone().tz(tzUtc).format(dateTimeCmaFormat)}Z`;
      }
    }
    onChange(dateOnChangeValue);
  };

  render() {
    const {
      className,
      disabled,
      error,
      getCalendarContainer,
      id,
      disabledDate,
      label,
      showToday = false,
      name,
      required,
      timezone,
      keepDefaultFormat,
      value,
      ...rest
    } = this.props;

    const m = moment.tz(value, tzUtc).tz(timezone);
    const displayValue = (ts: Moment | null | undefined) => {
      if (ts && ts.isValid() && keepDefaultFormat) {
        ts.utc();
      }

      return ts && ts.isValid() ? ts.format(keepDefaultFormat ? defaultDateTimeFormat : dateTimeSecFormat) : '';
    };

    return (
      <Picker
        animation="slide-up"
        calendar={
          <Calendar
            className={className}
            dateInputPlaceholder="Select a Date"
            defaultValue={this.now}
            showToday={showToday}
            format={keepDefaultFormat ? defaultDateTimeFormat : dateTimeSecFormat} // format: string | Array<String>, // def = depends on whether you set timePicker and your locale. Use to format/parse date (without time) value to/from input. When an array is provided, all values are used for parsing and first value for display.
            // locale={cn ? zhCN : enUS} // locale: any, // Object calendar locale, def = import from 'rc-calendar/lib/locale/en_US'
            // onChange: Moment => any, // called when a date is changed inside calendar (next year/next month/keyboard)
            // onClear: () => any, // called when a date is cleared from calendar
            // onOk: Moment => any, // called when ok button is pressed, only if it's visible
            // onPanelChange: (Moment, ICalendarMode) => any, // called when panel changed
            // onSelect: Moment => any, // called when a date is selected from calendar
            // showOk: boolean, // whether has ok button in footer
            // showToday: boolean, // def = true; whether to show today button
            timePicker={<TimePanel />}
            // value: Moment, // current value like input's value
            disabledDate={disabledDate}
          />
        }
        getCalendarContainer={getCalendarContainer}
        onChange={this.onChange}
        value={m && m.isValid() ? m : undefined}
      >
        {({ value: mVal }) => {
          return (
            <TextField
              className={className}
              disabled={disabled}
              error={error}
              id={id}
              label={label}
              name={name}
              onChange={() => {
                /* no-op */
              }}
              required={required}
              value={displayValue(mVal)}
              {...rest}
            />
          );
        }}
      </Picker>
    );
  }
}

export default connect(({ user: { timezone } }, { timezone: propsTimezone }: { timezone?: string }) => ({
  timezone: propsTimezone || timezone,
}))(DateTimeUtcField);
