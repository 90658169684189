// import buildLookupsTest from 'app/utilities/buildLookups';
import * as types from './types';

const buildLookupsTest = items => {
  const lookups = { allIds: [...items], byId: {} };
  return lookups;
};

const getAll = () => ({
  type: types.GET_ALL_SEGMENTS,
});

const getAllSuccess = (audiences, paged = {}) => ({
  type: types.GET_ALL_SEGMENTS_SUCCESS,
  payload: { paged, segmentsLookups: buildLookupsTest(audiences) },
});

const getAllFail = error => ({
  type: types.GET_ALL_SEGMENTS_FAIL,
  payload: { error },
});

const checkDownloadStatus = id => ({
  type: types.GET_DOWNLOAD_STATUS,
  payload: { id },
});

const checkDownloadStatusSuccess = payload => ({
  type: types.GET_DOWNLOAD_STATUS_SUCCESS,
  payload,
});

const checkDownloadStatusError = id => ({
  type: types.GET_DOWNLOAD_STATUS_FAIL,
  payload: { id },
});

const loadMore = () => ({
  type: types.LOAD_MORE_SEGMENTS,
});

const loadMoreSuccess = (audiences, paged = {}) => ({
  type: types.LOAD_MORE_SEGMENTS_SUCCESS,
  payload: { paged, segmentsLookups: buildLookupsTest(audiences) },
});

const loadMoreFail = error => ({
  type: types.LOAD_MORE_SEGMENTS_FAIL,
  payload: { error },
});

export {
  getAll,
  getAllSuccess,
  getAllFail,
  checkDownloadStatus,
  checkDownloadStatusSuccess,
  checkDownloadStatusError,
  loadMore,
  loadMoreFail,
  loadMoreSuccess,
};
