import * as api from 'app/api/tags';
import { failed } from 'app/utilities/tags';

import * as actions from './actions';

export const getAllTags = () => async dispatch => {
  dispatch(actions.getAll());
  try {
    const tags = await api.getAll();
    return dispatch(actions.getAllSuccess(tags));
  } catch (e) {
    return dispatch(actions.getAllFail(e.message));
  }
};

export const postTag = tag => dispatch => {
  dispatch(actions.post());
  return api.post(tag).then(
    async res => {
      await getAllTags()(dispatch);
      return { ...tag, ...res };
    },
    err => {
      dispatch(actions.postFail());
      throw err;
    },
  );
};

export const postTags = tags => async dispatch => {
  if (!tags || !tags.length) return undefined;

  dispatch(actions.post());

  return Promise.all(
    tags.map(tag =>
      api.post(tag).then(
        resolved => ({ ...tag, success: true, newId: resolved.id }),
        rejected => ({ ...tag, success: false, error: rejected.message, debugError: rejected.debugMessage }),
      ),
    ),
  )
    .then(res => {
      const resFailed = failed(res);
      if (!resFailed.length) {
        dispatch(actions.postSuccess());
      } else {
        dispatch(actions.postFail(`Failed to create ${failed.length} of ${res.length} tags`));
      }
      return res;
    })
    .finally(() =>
      // Always refresh the cache, to keep things simple
      getAllTags()(dispatch),
    );
};

export const getV2Tags = (params: IQueryParams) => async dispatch => {
  dispatch(actions.getAllV2Tags());
  try {
    const audienceTags = await api.getV2Tags(params);
    return dispatch(actions.getV2TagsSuccess(audienceTags.content));
  } catch (e) {
    return dispatch(actions.getAllFail(e.message));
  }
};
