import { bindCreativeToViews as apiBindCreativeToViews } from 'app/api/bannerManagementV2/creatives';
import { IApiBannerCreative } from 'app/api/bannerManagementV2/creatives/types';
import { IStorefront, IView } from 'app/types/BannerManagement';
import { IBannerCampaignSpec } from 'app/types/Creatives/BannerCreative';

const asBannerSpec = (storefront?: IStorefront, view?: IView): IBannerCampaignSpec => ({
  ...(storefront?.id ? { storefrontId: storefront.id } : {}),
  ...(view?.id ? { viewId: view.id } : {}),
});

const asBannerSpecs = (storefront?: IStorefront, views?: Array<IView>): Array<IBannerCampaignSpec> =>
  (views || []).map(v => asBannerSpec(storefront, v));

export default async (
  creative?: IApiBannerCreative,
  storefront?: IStorefront,
  views?: Array<IView>,
  validFrom?: string,
  validUpto?: string,
): Promise<Array<IBannerCampaignSpec>> => {
  if (!creative || !storefront) {
    return asBannerSpecs(storefront, views);
  }

  try {
    const { bindings } = await apiBindCreativeToViews(creative, views || [], validFrom, validUpto);

    // Unsuccessful binding may result in two cases:
    // 1. A binding is returned, but with no banner ID
    // 2. The entire binding entry might be dropped.

    const successfulBannerSpecs = bindings.map(({ bannerId, viewId }) => ({
      ...(bannerId ? { id: bannerId } : {}), // Handle case 1 (above)
      storefrontId: storefront.id,
      viewId: viewId,
    }));

    // Handle case 2 (above)
    const missingViews = (views || []).filter(v => !successfulBannerSpecs.find(b => b.viewId === v.id));
    const missingBannerSpecs = missingViews.map(v => ({ storefrontId: storefront.id, viewId: v.id }));

    return successfulBannerSpecs.concat(missingBannerSpecs);
  } catch (err) {
    console.error(`Error binding creative ${creative.id} to views [${(views || []).map(v => v.id).join(',')}]`, err);
    return asBannerSpecs(storefront, views);
  }
};
