import * as React from 'react';
import styled from 'styled-components';

import { sc } from 'app/styles';

type Props = {
  title: string;
  value: any;
  highlight?: boolean;
};

export default ({ title, value, highlight }: Props) => (
  <Row highlight={highlight}>
    <Name>{title}</Name>
    <Value>{value}</Value>
  </Row>
);

const Row = styled.div`
  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmall};
  display: flex;
  margin: ${sc.gutterSmaller} 0;
  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
`;

const Name = styled.div`
  margin-right: ${sc.gutterSmallest};
  width: 25%;
  min-width: 210px;
`;

const Value = styled.div`
  flex-grow: 1;
  min-width: 10rem;
  white-space: pre-wrap;
  width: 75%;
  overflow-wrap: break-word
  max-height: 200px;
  overflow-y: auto;
`;
