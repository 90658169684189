import { ISlotGroupType } from 'app/types/BannerManagement';

import * as types from './types';

export const getSlotGroupTypesFailed = (error: string) => ({
  type: types.GET_SLOT_GROUP_TYPES_FAILED,
  payload: { error },
});

export const getSlotGroupTypesStart = () => ({
  type: types.GET_SLOT_GROUP_TYPES_START,
});

export const getSlotGroupTypesSuccess = (slotGroupTypes: Array<ISlotGroupType>) => ({
  type: types.GET_SLOT_GROUP_TYPES_SUCCESS,
  payload: { slotGroupTypes },
});
