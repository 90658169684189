import { cashbackTypes } from 'app/features/Cashback/constants';

export const fromApiBanner = banner => ({
  createdAt: banner.createdAt || '',
  createdBy: banner.createdBy || null,
  updatedAt: banner.updatedAt || '',
  updatedBy: banner.updatedBy || null,
  id: banner.id || null,
  title: banner.title || '',
  priority: banner.priority || null,
  customBannerAttributes: banner.customBannerAttributes || {},
  additionalAttributes: banner.additionalAttributes || [],
  userBudgetToken: banner.userBudgetToken || null,
  startDate: banner.startDate ? `${banner.startDate}Z` : '',
  endDate: banner.endDate ? `${banner.endDate}Z` : '',
  cashbackType: banner.userBudgetToken ? cashbackTypes.regular : cashbackTypes.journey,
});

export const fromApiBanners = banners => banners.map(banner => fromApiBanner(banner));

export const toApiBanner = (banner, removeId = true) => {
  const { id, cashbackType, ...removedIdTypeBanner } = banner;
  const { customBannerAttributes, startDate, endDate, userBudgetToken, title, additionalAttributes, priority = null } = removedIdTypeBanner;
  const newAttributes = Object.entries(customBannerAttributes || {}).reduce((acc, [key, value]) => ({ ...acc, [key]: String(value) }), {});
  return {
    title,
    priority,
    additionalAttributes,
    customBannerAttributes: newAttributes,
    startDate: startDate.substring(0, startDate.length - 1),
    endDate: endDate.substring(0, endDate.length - 1),
    ...(userBudgetToken ? { userBudgetTokenId: userBudgetToken.id } : undefined),
    ...(removeId ? undefined : { id }),
  };
};
