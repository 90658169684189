import { findChannelSetup } from 'app/ducks/configStore/utils';
import { IAppConfig } from 'configs/apps/types';
import { IPush2 } from 'configs/channels/constants';
import { channelNamePush2 } from 'configs/channels/constants';
export type IPush2CreativeConfig = Partial<{
  pushTypes: Array<string>;
  sites: Array<string>;
  testDelivery: {
    sourceSchema: {
      /**
       * This is the name of the recipient field, e.g. `customer_id` or `merchant_id`.
       */
      pkField: string;
      /**
       * Although the PK field is set as a `string` in the source schema,
       * the validation on the front end can enforce that it is numeric.
       */
      pkType: 'string' | 'number';
    };
  };

  useInteractives?: boolean;
  useMetadata?: boolean;
  useOverrides?: boolean;
  useSendCampaignTo?: boolean;
  usePersonalization?: boolean;
  useImageCarousel?: boolean;
  useSenderDetails?: boolean;
  useCampaignRole?: boolean;
  chatEnabled: boolean;
  chatConfig: {
    pushTypes: Array<{ id: string }>;
    sites: Array<string>;
  };
}>;

export type IPush2Config = {
  name: IPush2;
  creative?: IPush2CreativeConfig;
};

export const getPush2CreativeConfig = (appConfig: IAppConfig): IPush2CreativeConfig => {
  const { creative } = findChannelSetup(appConfig, channelNamePush2) || {};
  return (creative || ({} as any)) as IPush2CreativeConfig;
};
