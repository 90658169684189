import { isEmpty } from 'ramda';

import { ICategory } from 'app/types';
import { ICreativeCategoryWeight } from 'app/types/BannerManagement';

import { IApiCategoryWeight } from '../types';

const fromApiCategoryWeight = (
  categories: Array<ICategory>,
  apiCatWeight?: IApiCategoryWeight,
): ICreativeCategoryWeight | null | undefined => {
  if (isEmpty(apiCatWeight)) {
    return null;
  }

  const { categoryId, weight } = apiCatWeight || {};
  const engageCategory = (categories || []).find(cat => cat.id === categoryId) || {};
  const categoryName = isEmpty(engageCategory)
    ? categoryId
      ? String(categoryId)
      : ''
    : `${engageCategory.level} - ${engageCategory.name}`;

  return {
    category: {
      id: categoryId,
      name: categoryName,
    },

    value: categoryName,
    weight,
  };
};

export const fromApiCategoryWeights = (
  categories: Array<ICategory>,
  categoryWeights?: Array<IApiCategoryWeight>,
): Record<string, ICreativeCategoryWeight> =>
  (categoryWeights || []).reduce(
    (acc, curr, i) => ({
      ...acc,
      [i]: fromApiCategoryWeight(categories, curr),
    }),

    { '0': {} },
  );

const toApiCategoryWeight = (categoryWeight: ICreativeCategoryWeight): IApiCategoryWeight => ({
  categoryId: categoryWeight.category.id,
  weight: Number(categoryWeight.weight),
});

export const toApiCategoryWeights = (categories: Record<string, ICreativeCategoryWeight>): Array<IApiCategoryWeight> =>
  Object.keys(categories || {})
    .filter(categoryKey => {
      const category = categories[categoryKey];
      return !isEmpty(category) && category.weight && !isEmpty(category.category) && category.category.id;
    })
    .map(categoryKey => toApiCategoryWeight(categories[categoryKey]));
