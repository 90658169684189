import { setError } from 'app/ducks/campaigns/campaign/bannerCreative/actions';
import { setBanners } from 'app/ducks/campaigns/campaign/bannerCreative/bannerSelection/actions';
import { State as IBannerCreativeState } from 'app/ducks/campaigns/campaign/bannerCreative/state';
import { displayError, displayInfo } from 'app/helpers/NotificationHelpers/helpers';
import { DispatchFn, ICampaign } from 'app/types';
import { IBannerCampaignSpec } from 'app/types/Creatives/BannerCreative';
import { getBannerCreativeConfig, LEVEL_CREATIVE } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';
import channelConfig from 'configs/channels/channel.config';
import { bannerMediumId } from 'configs/channels/mediumIds';

import bindCreativeToViews from './bindCreativeToViews';
import { compareBannerSpecs } from './utils';

type Props = {
  appConfig: IAppConfig;
  campaign: ICampaign;
  dispatch: DispatchFn;
};

export default async ({ appConfig, campaign, dispatch }: Props): Promise<boolean> => {
  const { creative: propNameCreative } = channelConfig[bannerMediumId];
  const bannerCreative: IBannerCreativeState = campaign[propNameCreative];

  const creativeConfig = getBannerCreativeConfig(appConfig);
  if (creativeConfig.widgetType?.level === LEVEL_CREATIVE) {
    return true;
  }

  if (!bannerCreative.valid) {
    displayError('Creative section is not valid. Aborted.');
    return false;
  }

  const { banners } = bannerCreative;
  const { creative, targets, validFrom, validUpto } = bannerCreative.source;

  if (banners?.length > 0) {
    // If banner specs have been provided, ignore the source for autobinding banners. This is probably not a correct use case, and needs care to code correctly.
    return true;
  }

  if (!creative?.id) {
    displayError('Source creative is required. Aborted.');
    dispatch(setError('Source creative is required. Campaign creation aborted.'));
    return false;
  }

  const validTargets = (targets || []).filter(b => !!b);
  if (validTargets.length === 0) {
    displayError('At least one target view is required. Aborted.');
    dispatch(setError('At least one target view is required. Campaign creation aborted.'));
    return false;
  }

  displayInfo('Creation may take 1 minute');

  const promiseBannerSpecs: Array<Promise<Array<IBannerCampaignSpec>>> = (targets || [])
    .filter(b => !!b)
    .map(target =>
      target?.storefront && (target?.views || []).length
        ? bindCreativeToViews(creative, target.storefront, target.views, validFrom, validUpto)
        : Promise.reject(),
    );

  const bannerSpecs: Array<IBannerCampaignSpec> = (await Promise.all(promiseBannerSpecs)).flat();
  bannerSpecs.sort(compareBannerSpecs);
  dispatch(setBanners(bannerSpecs));

  const allBindingsFailed = bannerSpecs.every(b => !b.id);
  if (allBindingsFailed) {
    displayError('Could not bind any banners. Aborted.');
    dispatch(setError('Could not bind any banners. Campaign creation aborted.'));
  }

  return !allBindingsFailed;
};
