import { compose, defaultProps, withHandlers, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import { Autocomplete } from './Autocomplete';

const getSuggestions = (items, value) => {
  if (!items) return undefined;
  return (value || '').length === 0 ? items : items.filter(suggestion => suggestion.toLowerCase().includes(value.toLowerCase()));
};

const addHandlers = withHandlers({
  handleSuggestionsFetchRequested:
    ({ id, handleChange }) =>
    ({ value }) =>
      handleChange({ [id]: value }),
  handleChange:
    ({ id, handleChange }) =>
    (ev, { newValue }) =>
      handleChange({ [id]: newValue }),
  handleSuggestionSelected:
    ({ id, handleSuggestionSelected }) =>
    (ev, { newValue }) =>
      handleSuggestionSelected && handleSuggestionSelected({ [id]: newValue }),
});

const addSuggestions = withProps(({ items, value }) => ({ suggestions: getSuggestions(items, value) }));

const enhance = compose(defaultProps({ id: 'default-autosuggest' }), addHandlers, addSuggestions, withStyles(styles, { withTheme: true }));

export default enhance(Autocomplete);
