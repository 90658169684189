export const styles = theme => ({
  root: {
    backgroundColor: theme.palette.error.main,
  },

  summary: {},
  details: {},
  detailsPre: {
    whiteSpace: 'pre-wrap',
    marginTop: '1rem',
  },

  divider: {
    backgroundColor: theme.palette.common.black,
  },
});
