import { compose, lifecycle, branch, renderComponent, flattenProp, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import qs from 'query-string';
import { Loading, NotFound } from 'app/components';

import { withPermissions } from 'app/decorators';
import { audienceOperations, featureOperations, featureSelectors } from 'app/ducks';
import { toTableDataFormat } from 'app/helpers/FeatureMetadataHelpers/helpers';

import { R } from 'app/helpers/RamdaHelpers/helpers';
import { withStyles } from '@material-ui/core/styles';
import Overview from './Overview';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';

import { styles } from './styles';
import { openClonePage } from '../utils';

const toTableData = (featureMetadata, includedRules) => {
  try {
    let expression = {};
    if (Array.isArray(includedRules) && includedRules.length) {
      [{ expression }] = includedRules;
    }
    const resolvedExpression = expression.expression || expression;

    // todo: fixme - ask Kamal to support version
    const rules = {
      version: '1',
      expression: resolvedExpression,
    };

    return toTableDataFormat(featureMetadata, rules);
  } catch (e) {
    displayError(e);
    return [{ columns: [], rows: [] }];
  }
};

const fetchSegment = lifecycle({
  componentDidMount() {
    const { getSegment, refreshFeatureMetadata, match } = this.props;
    getSegment(match.params.id);
    refreshFeatureMetadata();
  },
  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id: prevId },
      },
    } = prevProps;
    const {
      getSegment,
      match: {
        params: { id },
      },
    } = this.props;
    if (prevId !== id) {
      getSegment(id);
    }
  },
  componentWillUnmount() {
    this.props.resetSegment();
  },
});

const loading = branch(({ audience, isLoadingFeatureMetadata }) => audience.loading || isLoadingFeatureMetadata, renderComponent(Loading));

const mapStateToProps = state => {
  const featureData = featureSelectors.readMeV2(state);

  return {
    audience: state.audience.audience,
    isLoadingFeatureMetadata: featureSelectors.isLoading(featureData),
    featureMetadata: featureSelectors.metaData(featureData),
    toTableData,
  };
};

const mapDispatchToProps = {
  countOfSegment: audienceOperations.countOfSegment,
  getSegment: audienceOperations.getSegment,
  refreshFeatureMetadata: featureOperations.refreshFeatureMetadata,
  resetSegment: audienceOperations.resetSegment,
};

const addHandlers = withHandlers({
  backToTableHandler:
    ({ history }) =>
    () => {
      const {
        length,
        location: { state },
      } = history;
      const searchquery = R.lensPath(['location', 'search']);
      const params = R.view(searchquery, history);

      if (length > 2 && state) {
        history.goBack();
      } else if (params) {
        const { type } = qs.parse(params);
        history.push(`/audience/${type}/?${params}`);
      } else {
        history.push('/audience');
      }
    },
  handleCloneClicked: props => () => {
    const { history, audience } = props;
    openClonePage(history, { audience });
  },
});

const addPropsToNotFound = withProps({
  errorTitle: 'SEGMENT NOT FOUND', // TODO: Probably should be changed to 'AUDIENCE NOT FOUND', but left as is due to the `feature` property below
  goBackPath: '/audience',
  feature: 'SEGMENTS',
})(NotFound);

const withIdMissingHandle = branch(({ audience }) => audience.error, renderComponent(addPropsToNotFound));

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  fetchSegment,
  loading,
  flattenProp('audience'),
  addHandlers,
  withStyles(styles, { withTheme: true }),
  withIdMissingHandle,
  withPermissions,
);

export default enhance(Overview);
