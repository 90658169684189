import * as React from 'react';
import { connect } from 'react-redux';

import { DrawerError } from 'app/components';
import { creativeActions } from 'app/ducks/bannerManagement';
import { Spinner } from 'app/midgarComponents';
import { Action } from 'app/types';
import { IBannerCreative } from 'app/types/BannerManagement';

import { ICreativeValidation } from '../validation/types';
import Creative from './Creative';
import Variants from './Variants';

type Props = ICreativeValidation & {
  addVariantGroup: (index: number) => Action;
  creativeFieldHasError: (arg0: string) => boolean;
  fetchCreativeLoading: boolean;
  form: IBannerCreative;
  isEdit?: boolean;
  removeVariantGroup: (index: number) => Action;
  widgetTypesError?: string;
  widgetTypesLoading: boolean;
};

const CreativeEditor = ({
  addVariantGroup,
  creativeFieldHasError,
  fetchCreativeLoading,
  form,
  isEdit,
  removeVariantGroup,
  shouldValidate,
  variantCategoryFieldHasError,
  variantCategoryHasErrors,
  variantFieldHasError,
  variantHasErrors,
  widgetTypesError,
  widgetTypesLoading,
}: Props) => {
  if (fetchCreativeLoading || widgetTypesLoading) {
    return <Spinner />;
  }

  if (widgetTypesError) {
    return <DrawerError allowDismiss={false} defaultExpanded error="Error while retrieving widget types" errorDetails={widgetTypesError} />;
  }

  return (
    <>
      <Creative creativeFieldHasError={creativeFieldHasError} form={form} shouldValidate={shouldValidate} />

      <Variants
        addVariantGroup={isEdit ? null : addVariantGroup}
        isCreative
        loading={fetchCreativeLoading}
        removeVariantGroup={isEdit ? null : removeVariantGroup}
        shouldValidate={shouldValidate}
        variantCategoryFieldHasError={variantCategoryFieldHasError}
        variantCategoryHasErrors={variantCategoryHasErrors}
        variantFieldHasError={variantFieldHasError}
        variantHasErrors={variantHasErrors}
      />
    </>
  );
};

const mapStateToProps = ({
  bannerManagement: {
    creative: { fetchCreativeLoading, form },
  },
}) => ({
  fetchCreativeLoading,
  form,
});

const mapDispatchToProps = {
  addVariantGroup: creativeActions.addVariantGroup,
  removeVariantGroup: creativeActions.removeVariantGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreativeEditor);
