export const styles = theme => ({
  root: {
    margin: '250px auto',
    height: '30%',
    width: '60%',
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: '5em 0',
  },

  error: {
    color: 'red',
    fontSize: '2.75em',
    marginBottom: '100px',
  },

  button: {
    padding: '1em 2em',
  },
});
