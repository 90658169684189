import { asReplacements } from 'app/helpers/ImageUploadManager';

const regex = /src="([a-zA-Z0-9_-]+\.[a-zA-Z]+)"/g;

const findImagePlaceholders = content => {
  let names = [];
  let matches = regex.exec(content);
  while (matches) {
    names = [...names, matches[1]];
    matches = regex.exec(content);
  }
  return names.filter((name, index, self) => self.indexOf(name) === index);
};

const imageNames = fileUploads => asReplacements(fileUploads).map(repl => repl.name);

const unusedNames = (usedNames, definedNames) => definedNames.filter(n => !usedNames.includes(n));

export const missingImages = (fileUploads, content) => {
  const usedImages = imageNames(fileUploads);
  const definedImages = findImagePlaceholders(content);
  return unusedNames(usedImages, definedImages);
};
