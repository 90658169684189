import { fetchCreatives } from 'app/api/bannerManagementV2/creatives';
import { CMAPagingParams } from 'app/ducks/bannerManagement/utils';
import { calculatePagingResults, mapPagingProps } from 'app/ducks/bannerManagement/utils';
import { DispatchFn } from 'app/types';

import * as actions from './actions';

export const getAllPaged = (cmaQuery: CMAPagingParams) => async (dispatch: DispatchFn) => {
  dispatch(actions.getAll());
  try {
    // TODO: Support alternate param mappings in withPaging
    const query = mapPagingProps(cmaQuery);
    const { creatives, totalElements } = await fetchCreatives(query);
    const paging = calculatePagingResults(creatives, { ...query, totalElements });
    return dispatch(actions.getAllSuccess(creatives, paging));
  } catch (error) {
    // TODO: Confirm that error is displayed in drawer, then remove console statement
    console.error(error); // eslint-disable-line no-console
    return dispatch(actions.getAllFailed(error));
  }
};
