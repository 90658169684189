import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withAppConfig } from 'app/decorators';
import withReporting from 'app/hocs/withReporting';
import withSearchBar from 'app/hocs/withReporting/withSearchBar';
import { templatesOperations, templatesSelectors } from 'app/ducks';

import { Reporting } from './Reporting';
import { searchBarDefiner, searchDefiner } from './search.config';

const mapStateToProps = state => ({
  templates: templatesSelectors.templates(state),
  error: templatesSelectors.error(state),
  isLoading: templatesSelectors.loading(state),
  timezone: state.user.timezone,
  userEmail: state.user.email,
});

const mapDispatchToProps = {
  fetchTemplates: templatesOperations.getTemplatesPaged,
};

const enhance = compose(
  withAppConfig,
  connect(mapStateToProps, mapDispatchToProps),
  withReporting('fetchTemplates', templatesSelectors.paging, { searchDefiner }),
  withSearchBar(searchBarDefiner),
);

export default enhance(Reporting);
