import * as React from 'react';
import PropTypes from 'prop-types';

import { map, defaultTo, identity } from 'ramda';

import { FormControl, Select as MaterialSelect, Input, InputLabel, MenuItem } from '@material-ui/core';

const defaultToIdentity = defaultTo(identity);

export const Select = ({
  id,
  label = 'Select',
  value = '',
  values,
  handleChange,
  required = false,
  native = false,
  defaultValue = '',
  readValue,
  readLabel,
  selectStyle = {},
  classes = {},
  style = {},
  ...props
}) => {
  const [valueOf, labelOf] = map(defaultToIdentity, [readValue, readLabel]);

  return (
    <FormControl style={selectStyle} className={classes.root}>
      <InputLabel required={required} htmlFor={id}>
        {label}
      </InputLabel>
      <MaterialSelect
        data-qa={`${id}-select-component`}
        native={native}
        value={value || ''}
        onChange={handleChange}
        input={<Input name={id} id={id} />}
        style={{ ...style, selectStyle }}
        {...props}
      >
        {defaultValue && (native ? <option value="">{defaultValue}</option> : <MenuItem value="">{defaultValue}</MenuItem>)}
        {map(
          v =>
            native ? (
              <option key={valueOf(v)} value={valueOf(v)}>
                {labelOf(v)}
              </option>
            ) : (
              <MenuItem key={valueOf(v)} value={valueOf(v)} data-qa={`${labelOf(v)}--select-component-option`}>
                {labelOf(v)}
              </MenuItem>
            ),

          values,
        )}
      </MaterialSelect>
    </FormControl>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  handleChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  native: PropTypes.bool,
  defaultValue: PropTypes.string,
  readValue: PropTypes.func,
  readLabel: PropTypes.func,
  selectStyle: PropTypes.objectOf(PropTypes.string),
  style: PropTypes.objectOf(PropTypes.string),
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};
