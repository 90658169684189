import { map } from 'ramda';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';

import { possibleValuesOfFeature } from 'app/api/features';
import * as api from 'app/api/debug';
import { parseDateTimeUtc, formatDateTime } from '../../utilities/date';

import * as actions from './actions';

const API_VERSION = 'v2';

const mapTimestamps = ({ timestamp, ...b }) => ({ ...b, timestamp: formatDateTime(parseDateTimeUtc(timestamp)) });

export const fetchStates = originDict => async (dispatch, getState) => {
  if (!originDict) {
    return null;
  }

  dispatch(actions.startFetch());

  try {
    const stateFeatureId = originDict.province_state.feature_id;

    const states = await possibleValuesOfFeature(stateFeatureId);
    return dispatch(actions.fetchStatesSuccess(states));
  } catch (error) {
    displayError(`Error retrieving banners: ${error}`);
    return dispatch(actions.fetchStatesError(error));
  }
};

export const fetchUpcomingBanners =
  (viewId, userId, params = {}) =>
  async dispatch => {
    if (!viewId || !userId) {
      return null;
    }

    const defaultParams = {
      customer_id: userId,
    };

    dispatch(actions.searchStart());

    try {
      const { banners: bannerIds, debug: bannerDebugs } = await api.fetchUpcomingBanners(
        viewId,
        userId,
        Object.assign(defaultParams, params),
        { apiVersion: API_VERSION },
      );

      return dispatch(actions.searchSuccess(bannerIds, bannerDebugs));
    } catch (error) {
      displayError(`Error retrieving debug banners: ${error}`);
      return dispatch(actions.searchError(error));
    }
  };

export const fetchDeliveredBanners = userId => async dispatch => {
  dispatch(actions.searchStart());

  try {
    const banners = await api.fetchDeliveredBanners(userId);
    return dispatch(actions.searchSuccess(map(mapTimestamps)(banners)));
  } catch (error) {
    displayError(`Error retrieving debug banners: ${error}`);
    return dispatch(actions.searchError(error));
  }
};
