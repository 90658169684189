import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'ramda';

import { binderActions, binderOperations } from 'app/ducks';
import { Modal } from 'app/midgarComponents';
import { Action } from 'app/types';
import { IBannerCreative } from 'app/types/BannerManagement';

import BindCreative from './BindCreative';
import SelectCreative from './SelectCreative';

type Props = {
  bindCreative: (creative: Record<string, any>, cb?: (...args: Array<any>) => any) => (...args: Array<any>) => any;
  closeModal: () => void;
  history: RouterHistory;
  isOpen: boolean;
  resetDates: () => Action;
};

type State = {
  selectedCreative: IBannerCreative | null | undefined;
};

class BindModal extends PureComponent<Props, State> {
  state = {
    selectedCreative: null,
  };

  onBindCreative = () => {
    const { selectedCreative } = this.state;
    const { bindCreative, closeModal } = this.props;
    bindCreative(selectedCreative, closeModal);
  };

  onReset = () => {
    const { resetDates } = this.props;
    resetDates();
    this.setState({ selectedCreative: null });
  };

  onSelectCreative = (creative: IBannerCreative) => {
    this.setState({ selectedCreative: creative });
  };

  updateVariantStatus = (variant: Record<string, any>, status: boolean) => {
    const { selectedCreative } = this.state;
    const creative = { ...selectedCreative };
    const idx = creative.variants.findIndex(x => x.id === variant.id);

    if (idx > -1) {
      creative.variants[idx] = { ...variant, status: status ? 1 : 0 };
    }

    this.setState({ selectedCreative: creative });
  };

  componentDidUpdate(prevProps: Props) {
    const { history, isOpen } = this.props;

    if (isOpen && !prevProps.isOpen) {
      history.replace({
        pathname: history.location.pathname,
        search: '',
      });
    }
  }

  render() {
    const { selectedCreative } = this.state;
    const { isOpen, closeModal } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={closeModal}>
        {isOpen && (
          <>
            {selectedCreative ? (
              <BindCreative
                creative={selectedCreative}
                onBind={this.onBindCreative}
                onReset={this.onReset}
                updateVariantStatus={this.updateVariantStatus}
              />
            ) : (
              <SelectCreative closeModal={closeModal} onSelect={this.onSelectCreative} />
            )}
          </>
        )}
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  bindCreative: binderOperations.bindCreative,
  resetDates: binderActions.resetDates,
};

export default compose(withRouter, connect(null, mapDispatchToProps))(BindModal);
