import { IAppConfig, IChannelSetup } from 'configs/apps/types';

export const getChannels = (appConfig: IAppConfig | null | undefined): Array<string | IChannelSetup> =>
  appConfig ? appConfig.channels || [] : [];

export const getChannelNames = (appConfig: IAppConfig | null | undefined): Array<string> =>
  getChannels(appConfig).map(c => (typeof c === 'string' ? c : c.name));

/**
 * Get the `IChannelSetup` for the given channel.
 *
 * If the channel is active, and there is no custom setup for this channel, returns an object with only the `name` property.
 *
 * If the channel is not active, return `undefined`.
 *
 * @param {IAppConfig} appConfig
 * @param {string} channelName
 * @returns ?IChannelSetup
 */
export const findChannelSetup = (appConfig: IAppConfig | null | undefined, channelName: string): any => {
  const channel = getChannels(appConfig).find(config => {
    if (typeof channelName !== 'string') {
      return;
    }

    return typeof config === 'string'
      ? channelName.toUpperCase() === config.toUpperCase()
      : typeof config.name === 'string' && channelName.toUpperCase() === config.name.toUpperCase();
  });

  return typeof channel === 'string' ? { name: channel } : channel;
};
