import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ICmaView } from 'app/api/storefront/types';
import { mapStateToViews } from 'app/ducks/storefrontsCma/viewsByStorefrontId/selectors';
import { getViews as opGetViews } from 'app/ducks/storefrontsCma/viewsByStorefrontId/operations';
import { mapStateToStorefronts } from 'app/ducks/storefrontsCma/storefronts/selectors';

export default (
  storefrontId?: number,
): {
  views: Array<ICmaView> | null | undefined;
  viewsError: string | null | undefined;
  viewsLoading: boolean;
} => {
  const { storefronts } = useSelector(mapStateToStorefronts);
  const { views, viewsError, viewsLoading } = useSelector(mapStateToViews(storefrontId));
  const prevState: { current: { storefrontId?: number } } = useRef({});
  const dispatch = useDispatch();

  useEffect(() => {
    const prevStorefrontId = prevState.current.storefrontId;

    if (storefrontId !== prevStorefrontId) {
      if (storefronts?.length > 0) {
        prevState.current.storefrontId = storefrontId;

        if (storefrontId) {
          dispatch(opGetViews(storefrontId));
        }
      }
    }
  }, [dispatch, storefrontId, storefronts]);

  return {
    views,
    viewsError,
    viewsLoading,
  };
};
