import { IFetch } from 'app/ducks/commonStates';
import { fetch } from 'app/ducks/commonStates';
import { ICampaignAttribute, IMedium, IScope } from 'app/features/CashbackPromoManagement/types';
import { Action } from 'app/types';

import * as types from './types';

type State = IFetch & {
  campaignAttributes: Array<ICampaignAttribute> | null | undefined;
  medium: IMedium | null | undefined;
  scope: IScope | null | undefined;
};

const initialState = {
  ...fetch,
  campaignAttributes: null,
  medium: null,
  scope: null,
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_ERROR:
      return {
        ...state,
        campaignAttributes: null,
        error: payload.error,
        loading: false,
      };

    case types.GET_INIT:
      return {
        ...state,
        campaignAttributes: null,
        error: null,
        loading: true,
        medium: null,
        scope: null,
      };

    case types.GET_SUCCESS:
      return {
        ...state,
        campaignAttributes: payload.campaignAttributes,
        error: null,
        loading: false,
        medium: payload.medium,
        scope: payload.scope,
      };

    default:
      return state;
  }
};
