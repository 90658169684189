/* eslint-disable import/no-cycle */
import * as React from 'react';
import { connect } from 'react-redux';
import { sc } from 'app/styles';

import { ConnectedTimelineNotification } from 'app/ducks';
import { IAppConfig } from 'configs/apps/types';

import { Label, Section, ValidationIcon } from './utilComponents';
import TimelineContent from './Content';
import { notificationLinkValidator } from '../../../helpers/TimelineNotificationHelper';
type Props = {
  appConfig: IAppConfig;
};

const isValidNotificationLink = messageLink => !notificationLinkValidator(messageLink, 'Notification');

class TimelineNotificationCreation extends React.Component<Props> {
  render() {
    return (
      <ConnectedTimelineNotification>
        {({
          payload: {
            title,
            messageLink,
            threadId,
            sender: { name, link, icon },
          },
        }) => (
          <>
            {/* <Section data-qa="supported-devices">
             <Label>
               Supported Devices
               {platforms.length > 0 ? (
                 <ValidationIcon name="check-circle" size={22} color={sc.success} />
               ) : (
                 <ValidationIcon name="warning" size={22} color={sc.warning} />
               )}
             </Label>
             <PlatformSelection type={type} selected={platforms} handleSelect={updatePush2Platform} />
            </Section> */}

            <Section data-qa="push-notification-content">
              <Label>
                Timeline Notification Content
                {threadId && title && (!messageLink || isValidNotificationLink(messageLink)) && name && icon && link ? (
                  <ValidationIcon name="check-circle" size={22} color={sc.success} />
                ) : (
                  <ValidationIcon name="warning" size={22} color={sc.warning} />
                )}
              </Label>
              <TimelineContent />
            </Section>
          </>
        )}
      </ConnectedTimelineNotification>
    );
  }
}

export default connect(({ config }) => ({ appConfig: config }))(TimelineNotificationCreation);
