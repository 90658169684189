import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

import { TextField, RadioGroup, Radio, FormControlLabel, Typography, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { Search as SearchIcon, NotInterested as NotFoundIcon } from '@material-ui/icons';

import { isNilOrEmpty } from 'app/helpers/RamdaHelpers/helpers';
import { addComponentPropsIfNot } from 'app/helpers/ComponentHelpers/helpers';
import DialogShell from '../../components/DialogShell';

// const clearValueOption = toFormControlLabel('', 'None');

const currentlySelected = (selected, styleClass) =>
  selected && (
    <Typography
      variant="caption"
      className={styleClass}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      Currently selected: &nbsp; <i>{`${selected}`}</i>
    </Typography>
  );

const pluralize = (word, size) => `${word}${size > 1 ? 's' : ''}`;

const searchHintOf = count => (count < 1 ? 'No matching item found' : `${count} ${pluralize('item', count)} matching`);

const dialogHeader = (title, selected, handleSearch, searchPhrase, numberOfItems, classes) => (
  <>
    {title}
    <div
      style={{
        // alignItems: 'flex-end',
        display: 'flex',
        marginRight: '0.8rem',
      }}
    >
      <SearchIcon
        style={{
          marginTop: '1rem',
          marginLeft: 0,
          marginRight: '0.3rem',
        }}
      />

      <TextField
        placeholder="Type here to search..."
        type="search"
        margin="dense"
        onChange={handleSearch}
        className={classes.textField}
        style={{
          minWidth: 200,
        }}
        {...(isNilOrEmpty(searchPhrase)
          ? {}
          : {
              helperText: searchHintOf(numberOfItems),
            })}
      />
    </div>
    {currentlySelected(isNilOrEmpty(selected) ? '(None)' : selected, classes.wrapper)}
  </>
);

export const SingleSelectDialog = ({
  isOpen,
  value,
  options,
  handleChange,
  handleClose,
  handleSearch,
  handleEntering,
  handleRadioGroupRef,
  handleContentContainerResize,
  listContainerHeight,
  dialogTitle,
  groupName,
  searchPhrase = '',
  classes,
}) => {
  const customRowRender = ({ index, key, isScrolling, style }) => {
    // Stringify the option otherwise React complains
    const option = options[index];
    const opt = `${option}`;

    return (
      <FormControlLabel
        key={opt}
        value={opt}
        data-qa={index}
        // name={name}
        checked={value === opt}
        onChange={handleChange}
        label={isNilOrEmpty(opt) ? '(None)' : opt}
        control={<Radio color="primary" />}
        style={style}
      />
    );
  };

  return (
    <DialogShell
      {...{
        isOpen,
        onClose: handleClose,
        onEntering: handleEntering,
        onContentContainerResize: handleContentContainerResize,
      }}
      dialogHeader={dialogHeader(dialogTitle, value, handleSearch, searchPhrase, options.length, classes)}
    >
      {options.length < 1 && (
        <ListItem disabled>
          <ListItemIcon>
            <NotFoundIcon />
          </ListItemIcon>
          <ListItemText primary="No item found." secondary="Clear search to see all." style={{ padding: 0 }} />
        </ListItem>
      )}

      {options.length > 0 && (
        <RadioGroup
          ref={handleRadioGroupRef}
          value={value || ''}
          // onChange={handleChange}
          {...addComponentPropsIfNot(isNilOrEmpty(groupName), {
            'aria-label': groupName,
            name: groupName,
          })}
        >
          <List height={600} width={400} rowHeight={40} rowRenderer={customRowRender} rowCount={options.length} />
        </RadioGroup>
      )}
    </DialogShell>
  );
};

SingleSelectDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleEntering: PropTypes.func,
  handleRadioGroupRef: PropTypes.func.isRequired,
  handleContentContainerResize: PropTypes.func.isRequired,
  listContainerHeight: PropTypes.number.isRequired,
  dialogTitle: PropTypes.string,
  groupName: PropTypes.string,
  searchPhrase: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
