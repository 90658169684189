import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { withAppConfig } from 'app/decorators';
import { fetchTags as opFetchTags } from 'app/ducks/bannerManagement/tags/operations';
import { mapStateToTags } from 'app/ducks/bannerManagement/tags/selectors';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';

const mapDispatchToProps = {
  fetchTags: opFetchTags,
};

export default compose(
  withAppConfig,
  connect(mapStateToTags, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { appConfig, fetchTags, tags, tagsLoading } = this.props;

      const { useTags } = getBannerCreativeConfig(appConfig);

      if (useTags && !(tags || []).length && !tagsLoading) {
        fetchTags();
      }
    },
  }),
);
