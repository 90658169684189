export const template = ({ templates: { template: thisTemplate } }) => thisTemplate;
export const loading = ({
  templates: {
    template: { loading: thisLoading },
  },
}) => thisLoading;
export const error = ({
  templates: {
    template: { error: thisError },
  },
}) => thisError;
export const errorDetails = ({
  templates: {
    template: { errorDetails: thisErrorDetails },
  },
}) => thisErrorDetails;
