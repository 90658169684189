import React from 'react';
import styled from 'styled-components';

import { sc } from 'app/styles';
import { ISuggestion } from 'app/types';
import { IBannerCampaignSpec } from 'app/types/Creatives/BannerCreative';

import StorefrontAutocomplete from '../../common/StorefrontAutocomplete';
import useBannerCampaignCreative from '../../common/useBannerCampaignCreative';
import BannerAutocomplete from './BannerAutocomplete';
import ViewAutocomplete from './ViewAutocomplete';
import useStorefrontPermissions from './useStorefrontPermissions';

type Props = {
  bannerSpec: IBannerCampaignSpec;
  className?: string;
  index: number;
  permittedStorefronts: Array<ISuggestion> | null | undefined;
  storefronts: Array<ISuggestion> | null | undefined;
};

export default ({ bannerSpec, className, index, permittedStorefronts, storefronts }: Props) => {
  const { setSpecBannerId, setSpecStorefrontId, setSpecViewId } = useBannerCampaignCreative();

  const { id: bannerId, storefrontId, viewId } = bannerSpec || {};
  const { canEditStorefront } = useStorefrontPermissions();
  const disabled = !!storefrontId && !canEditStorefront(storefrontId);

  return (
    <Container className={className}>
      <StorefrontAutocomplete
        disabled={disabled}
        onSelectStorefront={(storefront: ISuggestion) => setSpecStorefrontId(index, storefront?.id)}
        permittedStorefronts={permittedStorefronts}
        storefrontId={storefrontId}
        storefronts={storefronts}
      />

      <ViewAutocomplete
        disabled={disabled}
        onSelectView={(view: ISuggestion) => setSpecViewId(index, view?.id)}
        permittedStorefronts={permittedStorefronts}
        storefrontId={storefrontId}
        storefronts={storefronts}
        viewId={viewId}
      />

      <BannerAutocomplete
        bannerId={bannerId}
        disabled={disabled}
        onSelectBanner={(banner: ISuggestion) => setSpecBannerId(index, banner?.id)}
        storefrontId={storefrontId}
        permittedStorefronts={permittedStorefronts}
        storefronts={storefronts}
        viewId={viewId}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-bottom: ${sc.gutter};
  & > div {
    padding-right: ${sc.gutter};
    width: 33%;
  }
`;
