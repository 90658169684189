import * as React from 'react';

import { templateCreationPermissions } from 'configs/permissions';
import { ConnectedUser } from 'app/ducks';
import { DrawerError } from 'app/components';
import { Page, PageHeader, PageMain, Paging, SearchBar } from 'app/midgarComponents';
import { CreateNewButton, Footer, SearchContainer, Switches } from 'app/midgarComponents/ReportingPage';
import { CreatedBySwitch as _CreatedBySwitch } from 'app/midgarComponents/OptionsSwitch/CreatedBySwitch';
import { searchBarAdaptor } from 'app/hocs/withReporting/withCreatedBySwitch';

import { IQueryParams, ITemplate } from 'app/types';
import { IPaging } from 'app/hocs/withReporting';
import { IAppConfig } from 'configs/apps/types';
import { CHANNELS_EMAIL_TEMPLATE_CREATION } from 'configs/routes';

import TemplateTable from './TemplateTable';
import { searchBarDefiner, searchCreatedBy, searchName } from './search.config';

const CreatedBySwitch = searchBarAdaptor(searchCreatedBy, searchName)(_CreatedBySwitch);

type Props = {
  templates: Array<ITemplate>;
  error: Error;
  isLoading: boolean;
  timezone: string;
  userEmail: string;
  // history: Object, // From Segments Reporting, probably not needed
  msgIfEmpty?: string;
  paging: IPaging;
  goToPage: (arg0: number) => unknown;
  search: IQueryParams;
  searchPhrase: string;
  selectedSearchType: string;
  handleSearchPhraseChange: (arg0: string) => void;
  handleSearchTypeChange: (arg0: string) => void;
  pushSearchBar: (arg0: { searchPhrase: string; selectedSearchType: string }) => void;
  appConfig: IAppConfig;
};

export class Reporting extends React.PureComponent<Props> {
  render() {
    const {
      templates,
      error,
      isLoading,
      userEmail,
      msgIfEmpty,
      paging,
      goToPage,
      searchPhrase,
      selectedSearchType,
      handleSearchPhraseChange,
      handleSearchTypeChange,
      pushSearchBar,
      appConfig,
    } = this.props;

    const searchBarDefs = searchBarDefiner({ appConfig });

    return (
      <ConnectedUser>
        {({ isPermitted, user }) => (
          <Page>
            <PageHeader title="Templates">
              <Switches>
                <CreatedBySwitch
                  userEmail={userEmail}
                  searchPhrase={searchPhrase}
                  selectedSearchType={selectedSearchType}
                  pushSearchBar={pushSearchBar}
                />
              </Switches>

              {isPermitted(templateCreationPermissions) && <CreateNewButton to={CHANNELS_EMAIL_TEMPLATE_CREATION} />}
            </PageHeader>

            <PageMain>
              <SearchContainer>
                <SearchBar
                  searchPhrase={searchPhrase}
                  selectedSearchType={selectedSearchType}
                  searchTypes={searchBarDefs}
                  handleSearchPhraseChange={handleSearchPhraseChange}
                  handleSearchTypeChange={handleSearchTypeChange}
                  pushSearchBar={pushSearchBar}
                />
              </SearchContainer>

              {error && error.message && (
                <DrawerError error="Error while retrieving audiences" errorDetails={error.message} allowDismiss={false} defaultExpanded />
              )}

              <TemplateTable
                templates={templates}
                editable={isPermitted(templateCreationPermissions)}
                isLoading={isLoading}
                isPermitted={isPermitted}
                msgIfEmpty={msgIfEmpty}
                timezone={user.timezone}
              />

              {isLoading ? undefined : (
                <Footer>
                  <Paging {...paging} handleGoToPage={goToPage} />
                </Footer>
              )}
            </PageMain>
          </Page>
        )}
      </ConnectedUser>
    );
  }
}
