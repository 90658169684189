import React from 'react';
import { connect } from 'react-redux';
import * as CashBackPromoConfigSelectors from 'app/ducks/cashbackPromo/selectors';
import * as BannerCreativeSelectors from 'app/ducks/campaigns/campaign/bannerCreative/selectors';
import * as BannerCreativeActions from 'app/ducks/campaigns/campaign/bannerCreative/actions';
import { eventSchemaOperations } from 'app/ducks/cashbackPromo/eventSchema';
import { EnrichFieldsCriteria } from '../../Cashback/Criteria/EnrichFieldsCriteria';

const mapStateToProps = state => ({
  customerEvent: CashBackPromoConfigSelectors.getCustomerEvent(state),
  criteria: {
    userCriteria: BannerCreativeSelectors.getUserCriteria(state),
    userCriteriaJson: BannerCreativeSelectors.getUserCriteriaJson(state),
  },
});

const mapDispatchToProps = {
  getCustomerEvent: eventSchemaOperations.getCustomerEvent,
  updateUserCriteria: BannerCreativeActions.updateUserCriteria,
  updateUserCriteriaJson: BannerCreativeActions.updateUserCriteriaJson,
};

const UserCriteria = connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const { criteria, customerEvent, getCustomerEvent, updateUserCriteria, updateUserCriteriaJson } = props;

  return (
    <EnrichFieldsCriteria
      criteria={criteria}
      customerEvent={customerEvent.customerEvent}
      loadingCustomerEvent={customerEvent.loadingCustomerEvent}
      getCustomerEvent={getCustomerEvent}
      updateCashbackCriteria={payload => {
        updateUserCriteria({ userCriteria: payload.userCriteria });
        updateUserCriteriaJson({
          userCriteriaJson: payload.userCriteriaJson,
        });
      }}
    />
  );
});

export default UserCriteria;
