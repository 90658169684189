import { R } from 'app/helpers/RamdaHelpers/helpers';

const blueWithLightnessOf = lightness => `hsla(198, 100%, ${lightness}%, 1)`;
const [shadeOfBlue1, shadeOfBlue2, shadeOfBlue3, shadeOfBlue4] = R.map(blueWithLightnessOf, [35, 45, 55, 65]);

const pathContentAfterStyle = {
  content: '" "',
  display: 'block',
  width: 0,
  height: 0,
  borderTop: '50px solid transparent',
  borderBottom: '50px solid transparent',
  borderLeft: `30px solid ${shadeOfBlue1}`,
  position: 'absolute',
  top: '50%',
  marginTop: '-50px',
  left: '100%',
  zIndex: 2,
};

const styles = theme => ({
  button: {
    backgroundColor: 'transparent',
    padding: 0,
    textTransform: 'none',
    minWidth: 'fit-content',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  pathComponent: {},
  pathContent: {},
  container: {
    listStyle: 'none',
    overflow: 'hidden',
    font: '12px Helvetica, Arial, Sans-Serif',
    margin: '0px',
    padding: 0,

    '& $pathComponent': {
      float: 'left',
    },

    '& $pathComponent $pathContent': {
      color: 'white',
      textDecoration: 'none',
      padding: '10px 0 10px 55px',
      background: shadeOfBlue1,
      position: 'relative',
      display: 'block',
      float: 'left',
    },

    '& $pathComponent $pathContent:after': {
      ...pathContentAfterStyle,
      borderRight: '30px solid transparent',
    },

    '& $pathComponent $pathContent:before': {
      ...pathContentAfterStyle,
      borderLeft: '30px solid white',
      marginLeft: '1px',
      zIndex: 1,
    },

    '& $pathComponent:first-child $pathContent': {
      paddingLeft: '20px',
    },

    '& $pathComponent:nth-child(2) $pathContent': {
      background: shadeOfBlue2,
    },

    '& $pathComponent:nth-child(2) $pathContent:after': {
      borderLeftColor: shadeOfBlue2,
    },

    '& $pathComponent:nth-child(3) $pathContent': {
      background: shadeOfBlue3,
    },

    '& $pathComponent:nth-child(3) $pathContent:after': {
      borderLeftColor: shadeOfBlue3,
    },

    '& $pathComponent:nth-child(4) $pathContent': {
      background: shadeOfBlue4,
    },

    '& $pathComponent:nth-child(4) $pathContent:after': {
      borderLeftColor: shadeOfBlue4,
    },

    '& $pathComponent:last-child $pathContent': {
      background: 'transparent !important',
      padding: 0,
    },

    '& $pathComponent:last-child $pathContent:after': {
      border: 0,
    },
  },

  tooltip: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
  },
});

export { styles };
