import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Typeahead, Spinner } from 'app/midgarComponents';
import { textFilter } from 'app/utilities/suggestionFiltering';
import { deeplinksOperations } from 'app/ducks';
import { SelectDeeplinkProps } from './types';

const mapStateToProps = ({ deeplinks: { deeplinks, fetchDeeplinksLoading } }) => ({
  deeplinks,
  fetchDeeplinksLoading,
});

const mapDispatchToProps = {
  getDeepLinks: deeplinksOperations.getDeepLinks,
};

type Props = SelectDeeplinkProps & {
  fetchDeeplinksLoading: boolean;
};

class SelectDeepLink extends PureComponent<Props> {
  componentDidMount = () => {
    const { getDeepLinks, deeplinks } = this.props;

    if (deeplinks && !deeplinks.length) {
      getDeepLinks();
    }
  };

  getSuggestions = (deeplink: string | null | undefined) => {
    const { deeplinks } = this.props;
    if (deeplinks && !!deeplinks.length) {
      return textFilter(deeplink)(deeplinks);
    }
    return [];
  };

  onSelect = (deeplink: Record<string, any>) => {
    const { setDeeplink } = this.props;
    setDeeplink(deeplink);
  };

  render() {
    const { fetchDeeplinksLoading, selectedDeeplink } = this.props;

    if (fetchDeeplinksLoading) {
      return <Spinner />;
    }
    return (
      <Container>
        <Typeahead
          name="deeplink"
          placeholder="Select Deeplink..."
          value={selectedDeeplink?.label || ''}
          getSuggestions={this.getSuggestions}
          onItemSelect={this.onSelect}
        />
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDeepLink);
const Container = styled.div`
  font-size: 14px;
  color: #002e6e;
  margin-right: 0.5rem;
`;
