import * as React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'ramda';
import styled from 'styled-components';

import { Tooltip } from 'app/components';
import { withAppConfig } from 'app/decorators';
import { ConnectedUser } from 'app/ducks';
import { Button, Paper, Icon as _Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { audienceTypes, audienceTypeDisplay } from 'app/utilities/constants';
import { IAppConfig } from 'configs/apps/types';
import { segmentCreationPermissions, SEGMENT_UPLOAD } from 'configs/permissions';

import Manual from './Manual';
import Rules from './Rules';

const audienceTypeTabs = [
  {
    label: audienceTypeDisplay.rule,
    value: audienceTypes.rule,
    permissions: segmentCreationPermissions,
  },

  {
    label: audienceTypeDisplay.csv,
    value: audienceTypes.csv,
    permissions: [SEGMENT_UPLOAD],
  },
];

const getLabel = value => audienceTypeTabs.map(tab => (tab.value === value ? tab.label : null));

const filterTabs = (isPermitted, supportedAudienceTypes) =>
  filter(({ permissions, value }) => isPermitted(permissions) && supportedAudienceTypes?.includes(value));

const renderSets = audienceType => {
  switch (audienceType) {
    case audienceTypes.csv:
      return <Manual />;
    case audienceTypes.rule:
      return <Rules />;
    default:
      return null;
  }
};

type Props = {
  appConfig: IAppConfig;
  audienceType?: string;
};

class AudienceSet extends React.Component<Props> {
  undoSelection = () => {
    const { setAudienceType } = this.props;
    return setAudienceType(null);
  };

  handleChange = e => {
    const { setAudienceType } = this.props;
    return setAudienceType(e.target.id);
  };

  renderAudienceCreationOptions(isPermitted) {
    const {
      audienceType,
      appConfig: { supportedAudienceTypes },
    } = this.props;

    if (!audienceType) {
      return (
        <>
          <Title>Select audience type</Title>
          <ButtonsWrapper>
            {filterTabs(
              isPermitted,
              supportedAudienceTypes,
            )(audienceTypeTabs).map(opt => (
              <Button onClick={this.handleChange} key={opt.value} id={opt.value} data-qa={`${opt.value}-button`}>
                {opt.label}
              </Button>
            ))}
          </ButtonsWrapper>
        </>
      );
    }
    return (
      <Title>
        {getLabel(audienceType)}
        <Tooltip title="Select an alternative method to create audience">
          <Icon name="delete" onClick={this.undoSelection} />
        </Tooltip>
      </Title>
    );
  }

  render() {
    const { audienceType } = this.props;

    return (
      <AudienceWrapper>
        <ConnectedUser>{({ isPermitted }) => this.renderAudienceCreationOptions(isPermitted)}</ConnectedUser>
        {renderSets(audienceType)}
      </AudienceWrapper>
    );
  }
}

AudienceSet.propTypes = {
  audienceType: PropTypes.string,
  setAudienceType: PropTypes.func.isRequired,
  appConfig: PropTypes.objectOf(PropTypes.any),
};

export default withAppConfig(AudienceSet);

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-in-between;
  margin: 2rem 0;

  & * {
    margin-right: 1rem;
  }
`;

const AudienceWrapper = styled(Paper)`
  background: ${sc.sectionWhiteColor};
  margin: 1rem 0;
  padding: 1rem;
  min-height: 200px;
`;

const Title = styled.h2`
  color: ${sc.secondary};
  text-transform: uppercase;
  font-size: ${sc.fontSizeLarge};
`;

const Icon = styled(_Icon)`
  margin-left: 1rem;
`;
