import { combineReducers } from 'redux';

import executions from './executions';
import priorities from './priorities';

export * from './executions';
export * from './priorities';

export default combineReducers({
  executions,
  priorities,
});
