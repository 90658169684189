import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { ConfirmModal, TextField } from 'app/midgarComponents';

import ErrorContainer from './ErrorContainer';
import { IBulkActionError } from './types';

type Props = {
  bulkActionError?: IBulkActionError;
  bulkActionInProgress: boolean;
  onClose: () => void;
  onConfirm: (afterBannerId: number) => void;
};

type State = {
  afterBannerId?: string;
};

class ConfirmMoveModal extends React.PureComponent<Props, State> {
  state = {};

  onConfirm = () => {
    const { onConfirm } = this.props;
    const { afterBannerId } = this.state;
    onConfirm(afterBannerId);
  };

  render() {
    const { bulkActionError, bulkActionInProgress, onClose } = this.props;

    const { afterBannerId } = this.state;

    return (
      <ConfirmModal
        confirmText="Change"
        disabled={!afterBannerId || bulkActionInProgress || !!bulkActionError}
        isOpen
        onClose={onClose}
        onConfirm={this.onConfirm}
        title="Change Priority"
      >
        <div>Move banners after banner:</div>

        <TextField
          error={!afterBannerId}
          label="Banner ID"
          name="after-banner-id"
          onChange={ev => this.setState({ afterBannerId: ev.target.value })}
          required
          value={afterBannerId || ''}
        />

        <StatusContainer>
          {bulkActionInProgress && <>Moving...</>}

          {bulkActionError && <ErrorContainer bulkActionError={bulkActionError} />}
        </StatusContainer>
      </ConfirmModal>
    );
  }
}

const mapStateToProps = ({ bannerManagement: { binder, slotGroupTypes } }) => ({
  bulkActionError: binder.bulkActionError,
  bulkActionInProgress: binder.bulkActionInProgress,
  slotGroupTypes: slotGroupTypes.slotGroupTypes,
  slots: binder.view.slots,
});

export default connect(mapStateToProps)(ConfirmMoveModal);

const StatusContainer = styled.div`
  min-height: 5rem;
`;
