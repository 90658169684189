import { compose } from 'ramda';

import * as types from './types';

import { NEW_CAMPAIGN, SET_EMAIL_CREATIVE } from '../types';

const initialState = {
  valid: false,
  sender: '',
  subject: '',
  html: '',
  senderName: '',
  replyTo: '',
  replyToName: '',
};

const required = ['sender', 'subject', 'html', 'senderName'];

const validate = state => ({ ...state, valid: required.reduce((acc, r) => (acc ? Boolean(state[r] && state[r].length) : acc), true) });

const update = state => payload => ({ ...state, ...payload.email });

const emailCreative = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.UPDATE_EMAIL_FIELD:
      return compose(validate, update(state))(payload);

    case SET_EMAIL_CREATIVE:
      return validate({ ...state, ...payload.creative });

    case NEW_CAMPAIGN:
      return initialState;

    default:
      return state;
  }
};

export default emailCreative;
