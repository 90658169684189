import * as Yup from 'yup';

const campaignTargetSchema = (context: { exclusionCampaign: boolean }): object =>
  // eslint-disable-next-line
  Yup.lazy((value: any) => {
    return Yup.object().shape({
      includedSegments:
        value.useEveryoneAudience === true || context?.exclusionCampaign
          ? Yup.mixed().notRequired()
          : Yup.array()
              .of(
                Yup.object({
                  id: Yup.number().required('An Audience requires an ID property'),
                  name: Yup.string().required('An Audience requires a name property'),
                }),
              )
              .required()
              .min(1, 'At least 1 Audience must be selected'),
      excludedSegments:
        (context?.exclusionCampaign || value.exclusionCampaign) && !value.useEveryoneAudience
          ? Yup.array()
              .of(
                Yup.object({
                  id: Yup.number().required('An Audience requires an ID property'),
                  name: Yup.string().required('An Audience requires a name property'),
                }),
              )
              .required()
              .min(1, 'At least 1 Audience must be selected')
          : Yup.array().of(
              Yup.object({
                id: Yup.number().required('An Audience requires an ID property'),
                name: Yup.string().required('An Audience requires a name property'),
              }),
            ),
      includedSegmentsFilters: Yup.array().of(
        Yup.object({
          id: Yup.number().required('An Audience requires an ID property'),
          name: Yup.string().required('An Audience requires a name property'),
        }),
      ),
      excludedSegmentsFilters: Yup.array().of(
        Yup.object({
          id: Yup.number().required('An Audience requires an ID property'),
          name: Yup.string().required('An Audience requires a name property'),
        }),
      ),
      audienceFiltersEnabled: Yup.boolean(),
      exclusionCampaign: Yup.boolean(),
    });
  });

export { campaignTargetSchema };
