import { ILocation } from 'app/types/BannerManagement';

import { ILocation as ApiLocation } from './types';

export const fromApiLocations = (
  apiLocations: Array<ApiLocation> | null | undefined,
): { cities: Array<ILocation>; states: Array<ILocation> } =>
  (apiLocations || []).reduce(
    (acc, locn) => {
      const { city, state } = locn || {};
      if (city && !acc.cities.find(c => c.name === city)) {
        acc.cities.push({ id: city.toLowerCase(), name: city, type: 'City' });
      }
      if (state && !acc.states.find(s => s.name === state)) {
        acc.states.push({ id: state.toLowerCase(), name: state, type: 'State' });
      }
      return acc;
    },
    { cities: [], states: [] },
  );
