import * as React from 'react';
import { ITemplate } from 'app/types';
import { FullTable } from 'app/midgarComponents';
import { Description, Tags, CreatedOn, Actions } from './ColumnComponents';

const tableColumns = () => {
  const genTableColumn = (label, key, customComponent) => ({
    key,
    label,
    customComponent,
  });

  const renderDescription = (_, row) => <Description {...row} />;
  const renderTags = (_, { tags }) => <Tags tags={tags} />;
  const renderCreatedOn = (_, row) => <CreatedOn {...row} />;
  const renderActions = (_, row) => <Actions template={row} />;

  return [
    genTableColumn('Name', 'name', renderDescription),
    genTableColumn('Tags', 'tags', renderTags),
    genTableColumn('Created On', 'createdOn', renderCreatedOn),
    genTableColumn('Actions', 'actions', renderActions),
  ];
};

const rows = (templates, timezone, editable, setError) => templates.map(t => ({ ...t, timezone, editable, setError }));

export default class TemplateList extends React.PureComponent<{
  templates: Array<ITemplate>;
  timezone: string;
  editable?: boolean;
  setError: () => void;
}> {
  render() {
    const { templates, timezone, editable, setError } = this.props;
    return <FullTable rows={rows(templates, timezone, editable, setError)} columns={tableColumns()} rowEditable={false} />;
  }
}
