import * as React from 'react';
import styled from 'styled-components';

import { sc } from 'app/styles';
import _Icon from 'app/midgarComponents/Icon';
import Paper from 'app/midgarComponents/Paper';

export default class extends React.PureComponent<{ validationFailures?: Array<string> }> {
  render() {
    const { validationFailures } = this.props;
    if (!validationFailures || !validationFailures.length) {
      return null;
    }

    return (
      <Container>
        <Icon name="warning" />

        <ul>
          {validationFailures.map(msg => (
            <li key={msg}>{msg}</li>
          ))}
        </ul>
      </Container>
    );
  }
}

const Container = styled(Paper)`
  display: flex;
  align-items: center;
  border: 1px solid ${sc.danger};
  padding: ${sc.gutter};
`;

const Icon = styled(_Icon)`
  color: ${sc.danger};
  margin-right: ${sc.gutter};
`;
