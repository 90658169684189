import http from 'app/utilities/api';
import { tenantPrefix } from 'configs/apps/tenants';

const baseUrl = `/tms/tenants${tenantPrefix}`;

const postTemplate = template =>
  http.request('POST', `${baseUrl}/templates`, {
    body: template,
  });

const evaluateTemplate = template =>
  http.request('POST', `${baseUrl}/template-evaluation`, {
    body: template,
  });

const getTemplateById = id => http.get(`${baseUrl}/templates/${id}`);

const updateTemplate = (id, payload) =>
  http.put(`${baseUrl}/templates/${id}`, {
    body: payload,
  });

const getTemplates = (query = null) => http.request('GET', `${baseUrl}/templates`, { query });

export { postTemplate, evaluateTemplate, getTemplates, getTemplateById, updateTemplate };
