import * as React from 'react';
import { Typeahead, Label } from 'app/midgarComponents';

import { TemplateSelectionProps } from './types';

export const TemplateSelection = ({ getTemplates, selectTemplate }: TemplateSelectionProps) => (
  <Label htmlFor="template-selection" data-qa="template-selection">
    Select Template
    <Typeahead isPromise name="template-selection" debounceTime={500} getSuggestions={getTemplates} onItemSelect={selectTemplate} />
  </Label>
);
