import * as React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TextField } from '@material-ui/core';

import { branch, withHandlers, renderComponent } from 'recompose';

const updateHandler = withHandlers({
  onChange:
    ({ column: { key }, onChange }) =>
    ({ target: { value } }) =>
      onChange({ [key]: value }),
});

const EditableColumn = updateHandler(({ column, clonedRow: row, onChange }) => (
  <TableCell>
    <TextField type={column.type || 'text'} value={row[column.key]} onChange={onChange} />
  </TableCell>
));

const StaticColumn = ({ column, row, valueFormatter }) => (
  <TableCell>{valueFormatter ? valueFormatter(column, row) : row[column.key]}</TableCell>
);

StaticColumn.propTypes = {
  column: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  row: PropTypes.object.isRequired, // eslint-disable-line
  valueFormatter: PropTypes.func,
};

export const ColumnSwitch = branch(
  ({ column: { editable }, editState }) => editable && editState,
  renderComponent(EditableColumn),
)(StaticColumn);
