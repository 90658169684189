import { whitelistedDomains } from './constants';

export const abbrev = (value: number, options: Record<string, any> = { prefix: true }): string => {
  const dm = options.dm === undefined ? 1 : options.dm;
  const exp = Math.floor(Math.log(value) / Math.log(1000));
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const prefix = options.prefix ? sizes[exp] : '';

  if (exp === 0) {
    return value + prefix;
  }

  return parseFloat(String(value / Math.pow(1000, exp))).toFixed(dm) + prefix;
};

export const isEmailValid = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) && whitelistedDomains.includes(email.split('@')[1] || email);
};

export const generateDataQaValue = (value = '', suffix = '') =>
  `${
    value.length
      ? value
          .toLowerCase()
          .replace(/([:.])/g, '')
          .trim()
          .replace(/\s/g, '-')
      : 'unknown'
  }-${suffix || 'label'}`;
