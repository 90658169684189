export default () => ({
  container: {
    padding: '1rem',
  },

  header: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: '0 1rem 1rem',
    width: '100%',
  },

  textField: {
    width: '15%',
    margin: '0 1rem 0 0',
  },
});
