import { ISearchDefs } from 'app/hocs/withReporting';
import { getEmailCreativeConfig } from 'configs/apps/creatives';
import { IAppConfig } from 'configs/apps/types';

export const searchCreatedBy = 'createdBy';
export const searchName = 'name';

const allSearchDefs: ISearchDefs = [
  { name: 'name', label: 'Name' },
  { name: 'description', label: 'Description' },
  { name: 'subject', label: 'Subject' },
  { name: 'content', label: 'Content' },
  { name: searchCreatedBy, label: 'Created By' },
  { name: 'sendFrom', label: 'Send From' },
  { name: 'sendFromName', label: 'Send From Name' },
  { name: 'replyTo', label: 'Reply To Email' },
  { name: 'replyToName', label: 'Reply To Name' },
  { name: 'tag', label: 'Tag' },
  { name: 'id', label: 'Template ID', type: 'number' },
];

export const searchDefiner = ({ appConfig }: { appConfig: IAppConfig }): ISearchDefs => {
  const channelSetup = getEmailCreativeConfig(appConfig) || {};
  if (channelSetup.replyTo) {
    return allSearchDefs;
  }
  return allSearchDefs.filter(def => !def.name.startsWith('replyTo'));
};

export const searchBarDefiner = ({ appConfig }: { appConfig: IAppConfig }) => searchDefiner({ appConfig });
