const ROOT = 'map/bannerManagement';

export const GET_VIEW = `${ROOT}/GET_VIEW`;
export const GET_VIEW_SUCCESS = `${ROOT}/GET_VIEW_SUCCESS`;
export const GET_VIEW_FAILED = `${ROOT}/GET_VIEW_FAILED`;
export const GET_SP_VIEW_START = `${ROOT}/GET_SP_VIEW_START`;
export const GET_SP_VIEW_SUCCESS = `${ROOT}/GET_SP_VIEW_SUCCESS`;

export const SET_SELECTED_SLOT_GROUP_TYPE = `${ROOT}/SET_SELECTED_SLOT_GROUP_TYPE`;
export const SET_SELECTED_VIEW_ITEM_ID = `${ROOT}/SET_SELECTED_VIEW_ITEM_ID`;
export const SET_SELECTED_DATE = `${ROOT}/SET_SELECTED_DATE`;
export const UPDATE_SLOT_ORDER = `${ROOT}/UPDATE_SLOT_ORDER`;
export const UPDATE_SLOT_ORDER_SUCCESS = `${ROOT}/UPDATE_SLOT_ORDER_SUCCESS`;
export const UPDATE_SLOT_ORDER_ERROR = `${ROOT}/UPDATE_SLOT_ORDER_ERROR`;

export const BIND_CREATIVE_START = `${ROOT}/BIND_CREATIVE_START`;
export const BIND_CREATIVE_SUCCESS = `${ROOT}/BIND_CREATIVE_SUCCESS`;
export const BIND_CREATIVE_ERROR = `${ROOT}/BIND_CREATIVE_ERROR`;

export const UPDATE_SLOT_STATUS_SUCCESS = `${ROOT}/UPDATE_SLOT_STATUS_SUCCESS`;
export const UPDATE_SLOT_OVERRIDE_SUCCESS = `${ROOT}/UPDATE_SLOT_OVERRIDE_SUCCESS`;
export const DELETE_SLOT_START = `${ROOT}/DELETE_SLOT_START`;
export const DELETE_SLOT_SUCCESS = `${ROOT}/DELETE_SLOT_SUCCESS`;
export const DELETE_SLOT_FAILED = `${ROOT}/DELETE_SLOT_FAILED`;
export const ADD_SLOT = `${ROOT}/ADD_SLOT`;
export const ADD_SLOT_SUCCESS = `${ROOT}/ADD_SLOT_SUCCESS`;
export const ADD_SLOT_FAILED = `${ROOT}/ADD_SLOT_FAILED`;
export const UPDATE_SELECTED_SLOT = `${ROOT}/UPDATE_SELECTED_SLOT`;

export const GET_SLOT_VIEW_ITEMS = `${ROOT}/GET_SLOT_VIEW_ITEMS`;
export const GET_SLOT_VIEW_ITEMS_SUCCESS = `${ROOT}/GET_SLOT_VIEW_ITEMS_SUCCESS`;
export const GET_SLOT_VIEW_ITEMS_FAILED = `${ROOT}/GET_SLOT_VIEW_ITEMS_FAILED`;
export const REORDER_VIEW_ITEMS = `${ROOT}/REORDER_VIEW_ITEMS`;
export const SET_SLOT_VIEW_ITEMS_PAGE = `${ROOT}/SET_SLOT_VIEW_ITEMS_PAGE`;
export const SET_SLOT_VIEW_ITEMS_PAGE_SIZE = `${ROOT}/SET_SLOT_VIEW_ITEMS_PAGE_SIZE`;

export const FETCH_VIEW_ITEM_START = `${ROOT}/FETCH_VIEW_ITEM_START`;
export const FETCH_VIEW_ITEM_SUCCESS = `${ROOT}/FETCH_VIEW_ITEM_SUCCESS`;
export const FETCH_VIEW_ITEM_ERROR = `${ROOT}/FETCH_VIEW_ITEM_ERROR`;
export const FETCH_VIEW_ITEM_LOCATION_SUCCESS = `${ROOT}/FETCH_VIEW_ITEM_LOCATION_SUCCESS`;
export const SET_VIEWITEM_FIELD = `${ROOT}/SET_VIEWITEM_FIELD`;
export const RESET_HIGHLIGHTED_VIEW_ITEM = `${ROOT}/RESET_HIGHLIGHTED_VIEW_ITEM`;
export const FETCH_VIEW_ITEMS_LOCATION_SUCCESS = `${ROOT}/FETCH_VIEW_ITEMS_LOCATION_SUCCESS`;

export const SAVE_VIEWITEM_START = `${ROOT}/SAVE_VIEWITEM_START`;
export const SAVE_VIEWITEM_SUCCESS = `${ROOT}/SAVE_VIEWITEM_SUCCESS`;
export const SAVE_VIEWITEM_ERROR = `${ROOT}/SAVE_VIEWITEM_ERROR`;

export const SAVE_VARIANT_ERROR = `${ROOT}/SAVE_VARIANT_ERROR`;
export const SAVE_VARIANT_START = `${ROOT}/SAVE_VARIANT_START`;
export const SAVE_VARIANT_SUCCESS = `${ROOT}/SAVE_VARIANT_SUCCESS`;

export const SAVE_VARIANT_STATUS_ERROR = `${ROOT}/SAVE_VARIANT_STATUS_ERROR`;
export const SAVE_VARIANT_STATUS_START = `${ROOT}/SAVE_VARIANT_STATUS_START`;
export const SAVE_VARIANT_STATUS_SUCCESS = `${ROOT}/SAVE_VARIANT_STATUS_SUCCESS`;
export const UPDATE_VARIANT_STATUS = `${ROOT}/UPDATE_VARIANT_STATUS`;

export const SET_IS_PERSONALIZED_SAVING = `${ROOT}/SET_IS_PERSONALIZED_SAVING`;
export const SET_IS_PERSONALIZED = `${ROOT}/SET_IS_PERSONALIZED`;

export const RESET_DATES = `${ROOT}/RESET_DATES`;
export const RESET_VIEW_ITEM = `${ROOT}/RESET_VIEW_ITEM`;

export const MOVE_AFTER_BANNER_ID_START = `${ROOT}/MOVE_AFTER_BANNER_ID_START`;
export const MOVE_AFTER_BANNER_ID_SUCCESS = `${ROOT}/MOVE_AFTER_BANNER_ID_SUCCESS`;
export const MOVE_AFTER_BANNER_ID_ERROR = `${ROOT}/MOVE_AFTER_BANNER_ID_ERROR`;

export const UPDATE_VIEW_FIELD = `${ROOT}/UPDATE_VIEW_FIELD`;
export const RESET_VIEW = `${ROOT}/RESET_VIEW`;

export const SAVE_VIEW_FAILED = `${ROOT}/SAVE_VIEW_FAILED`;
export const SAVE_VIEW_START = `${ROOT}/SAVE_VIEW_START`;
export const SAVE_VIEW_SUCCESS = `${ROOT}/SAVE_VIEW_SUCCESS`;

export const BULK_VIEW_ITEM_ACTION_CLEAR_ERROR = `${ROOT}/BULK_VIEW_ITEM_ACTION_CLEAR_ERROR`;
export const BULK_VIEW_ITEM_ACTION_ERROR = `${ROOT}/BULK_VIEW_ITEM_ACTION_ERROR`;
export const BULK_VIEW_ITEM_ACTION_START = `${ROOT}/BULK_VIEW_ITEM_ACTION_START`;
export const BULK_VIEW_ITEM_DELETE_SUCCESS = `${ROOT}/BULK_VIEW_ITEM_DELETE_SUCCESS`;
export const BULK_VIEW_ITEM_MOVE_SUCCESS = `${ROOT}/BULK_VIEW_ITEM_MOVE_SUCCESS`;
