import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { ConnectedEmailSenders } from 'app/ducks/emailSenders/ConnectedEmailSenders';
import * as emailCreativeOperations from 'app/ducks/campaigns/campaign/emailCreative/operations';
import { campaignOperations } from 'app/ducks';
import { fetchTemplates } from 'app/api/templates';

// eslint-disable-next-line import/no-cycle
import { EmailCreation } from './EmailCreation';
import { styles } from './styles';

const setParams = name => ({
  name,
  page: 0,
  size: 25,
});

const addHandlers = withHandlers({
  setEmailCreativeField: () => updateFn => fieldName => fieldValue => updateFn({ [fieldName]: fieldValue }),
  selectTemplate:
    ({ updateEmailField }) =>
    ({ subject, replyTo, replyToName, content: html, sendFrom: sender, sendFromName: senderName }) =>
      updateEmailField({
        subject,
        html,
        sender,
        senderName,
        replyTo,
        replyToName,
      }),

  getTemplates: () => v => fetchTemplates(setParams(v)),
});

const addProps = withProps(({ emailSenders }) => ({ allEmails: emailSenders.map(e => ({ id: e.id, name: e.address })) }));

const mapStateToProps = ({
  campaigns: {
    campaign: {
      general: { variables },
    },
  },

  user: { currentTenant },
}) => ({
  tenant: currentTenant,
  variables,
});

const mapDispatchToProps = {
  addVariable: campaignOperations.addVariable,
  removeVariable: campaignOperations.removeVariable,
  testDelivery: campaignOperations.testDelivery,
  updateDefaultVariable: campaignOperations.updateVariable,
  updateEmailField: emailCreativeOperations.updateEmailField,
};

const enhance = compose(
  ConnectedEmailSenders,
  addProps,
  connect(mapStateToProps, mapDispatchToProps),
  addHandlers,
  withStyles(styles, { withTheme: true }),
);

export default enhance(EmailCreation);
