import * as React from 'react';
import { TableRow } from '../Table/TableRow';
import { TableCell } from '../Table/TableCell';

type Props = {
  columns: Array<{}>;
  msg?: string;
};

export const EmptyTableBody = ({ columns = [], msg = 'No records found' }: Props) => (
  <TableRow>
    <TableCell style={{ textAlign: 'center' }} colSpan={columns.length}>
      {msg}
    </TableCell>
  </TableRow>
);
