import { compose, withStateHandlers } from 'recompose';

import { IQueryParams } from 'app/types';

/**
 * Use the incoming properties as the initial state for the report.
 *
 * Injects the following properties:
 *  - `queryParams` The parsed parameters from the query string.
 *  - 'apiQueryParams' Maps the `queryParams` to names required by the API.
 *  - `pushQuery` Pushes the given query parameters to the report state (no merge performed).
 */
export const withPropsReader = compose(
  withStateHandlers(
    ({ reportingState }: IQueryParams) => ({
      apiQueryParams: reportingState,
      queryParams: reportingState,
    }),

    {
      pushQuery: () => (newQueryParams: IQueryParams) => ({
        apiQueryParams: newQueryParams,
        queryParams: newQueryParams,
      }),
    },
  ),
);
