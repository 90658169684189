import { compose, withHandlers } from 'recompose';

import General from './General';

const addHandlers = withHandlers({
  handleChangeName:
    ({ setName }) =>
    ev =>
      setName && setName(ev.target.value),
  handleChangeDescription:
    ({ setDescription }) =>
    ev =>
      setDescription && setDescription(ev.target.value),
});

const enhance = compose(addHandlers);

export default enhance(General);
