export const audiences = ({
  audience: {
    audiences: { byId, allIds },
  },
}) => allIds;

export const paging = ({
  audience: {
    audiences: { number, first, last, totalElements, size },
  },
}) => ({
  number,
  first,
  last,
  totalElements,
  size,
});
