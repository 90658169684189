import { withProps } from 'recompose';
import { length, values, compose, filter } from 'ramda';

import { CompletionStatus } from './CompletionStatus';

const calc = validations =>
  validations
    ? (compose(
        length,
        filter(v => v),
        values,
      )(validations) /
        compose(length, values)(validations)) *
      100
    : 0;

const setCompleted = withProps(({ validations }) => ({
  completed: calc(validations),
}));

export default setCompleted(CompletionStatus);
