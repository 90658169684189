import { isEmpty } from 'ramda';
import { isNeitherNilNorEmpty, meaningfulString } from 'app/helpers/RamdaHelpers/helpers';

import { audienceTypesV2 } from '../audienceTypes';

const humanized = {
  name: 'Name',
  description: 'Description',
  tags: 'Tag',
  firstExtractionAt: 'First extraction',
  extractionIntervalInHours: 'Extraction interval',
  compositionIntervalInHours: 'Composition Extraction interval',
  willBeUpdatedUntil: 'Extraction Ends',
  includedRuleErrors: 'Rules',
  csvName: 'File',
  includedSeedSegments: 'Seed Segment',
  audienceType: 'Audience Type',
  boostAudienceSize: 'Maximum audience size',
  includedSegments: 'Included Segment',
  excludedSegments: 'Excluded Segment',
};

const assertionsGeneral = {
  name: meaningfulString,
  description: meaningfulString,
  tags: isNeitherNilNorEmpty,
};

const assertionsRule = {
  includedRuleErrors: (includedRuleErrors = []) => includedRuleErrors.length > 0 && includedRuleErrors.every(isEmpty),
};

const assertionsCsv = {
  csvName: meaningfulString,
};

const assertionsComposition = {
  includedSegments: isNeitherNilNorEmpty,
  excludedSegments: isNeitherNilNorEmpty,
  compositionIntervalInHours: meaningfulString,
  firstExtractionAt: meaningfulString,
  willBeUpdatedUntil: meaningfulString,
};

const assertionsExtraction = {
  extractionIntervalInHours: meaningfulString,
  firstExtractionAt: meaningfulString,
  willBeUpdatedUntil: meaningfulString,
};

const assertionsNoType = {
  // If there is no type specified, then validation fails.
  // Cannot use the `type` property because it is not cleared when the type is changed e.g. from manual -> rule
  type: () => false,
};

const assertions = {
  [audienceTypesV2.rule]: { ...assertionsGeneral, ...assertionsExtraction, ...assertionsRule },
  [audienceTypesV2.csv]: { ...assertionsGeneral, ...assertionsCsv },
  [audienceTypesV2.composition]: { ...assertionsGeneral, ...assertionsComposition },
  '': { ...assertionsGeneral, ...assertionsNoType },
};

export { assertions, humanized };
