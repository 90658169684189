import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, Typography } from '@material-ui/core';

import { Page } from 'app/components';
import { ConnectedUser } from 'app/ducks';
import { segmentCreationPermissions, segmentUploadPermissions } from 'configs/permissions';
import { sc } from 'app/styles';

import AudienceSet from './AudienceSet';
import General from './General';

type Props = {
  audienceType?: string;
  handleCreate: () => unknown;
  loading: boolean;
  setAudienceType: (arg0: string) => unknown;
  valid: boolean;
  validationError?: string;
};

export const New = ({ audienceType, handleCreate, loading, setAudienceType, valid, validationError }: Props) => (
  <Page
    renderHeader={() => [
      <div key="backButton">
        <BackButton to="/audience">Back to Target Audiences V2</BackButton>
        <Typography
          key="title"
          variant="h6"
          style={{
            textTransform: 'capitalize',
          }}
        >
          Create new audience V2
        </Typography>
      </div>,
      <ConnectedUser key="connectedUser">
        {({ isPermitted }) =>
          isPermitted([...segmentCreationPermissions, ...segmentUploadPermissions]) && (
            <Button
              color="primary"
              variant="contained"
              key="action"
              onClick={handleCreate}
              disabled={loading || !valid}
              data-qa="confirm-create"
            >
              Confirm and Create
              {loading && <CircularProgress size={25} style={{ marginLeft: '1rem' }} />}
            </Button>
          )
        }
      </ConnectedUser>,
    ]}
  >
    <section>
      <div>
        <General loading={loading} valid={valid} validationError={validationError} />
      </div>
      <AudienceSet audienceType={audienceType} setAudienceType={setAudienceType} />
    </section>
  </Page>
);

const BackButton = styled(Link)`
  font-size: 0.75rem;
  text-decoration-line: none;
  line-height: 1.25rem;
  color: ${sc.primary};
  &:before {
    content: ' ◂';
    font-size: 1.25rem;
    margin-right: 3px;
  }
`;
