import React, { memo } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import { Label } from 'app/midgarComponents';
import { displayInfo } from 'app/helpers/NotificationHelpers/helpers';
import { Segments } from '../../../types/typescript/Segments';
import Audience from './Audience';

type Props = {
  segmentsFetching: boolean;
  audienceFiltersEnabled: boolean;
  fetchSegments: Function;
  filterEnableHandler: Function;
  includedSegmentsFilters: Segments;
  excludedSegmentsFilters: Segments;
  includedsegmentOptions: Segments;
  excludedsegmentOptions: Segments;
  exclusionCampaign: boolean;
  setFieldValue: Function;
};

const audienceFilterMsg = 'Only Rule based audiences are selectable when audience filters are included.';

const AudienceFilter = ({
  includedsegmentOptions,
  excludedsegmentOptions,
  segmentsFetching,
  fetchSegments,
  audienceFiltersEnabled,
  includedSegmentsFilters,
  excludedSegmentsFilters,
  exclusionCampaign,
  filterEnableHandler,
  setFieldValue,
}: Props): JSX.Element => {
  const onCheckBoxChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    if (ev.target.checked) {
      displayInfo(audienceFilterMsg);
    } else {
      setFieldValue('excludedSegmentsFilters', []);
      setFieldValue('includedSegmentsFilters', []);
    }
    filterEnableHandler(ev.target.checked);
    setFieldValue('audienceFiltersEnabled', ev.target.checked);
  };

  return (
    <Wrapper>
      <FilterLabel htmlFor="geo-filter-toggle">
        Include Audience Filters
        <Checkbox checked={!!audienceFiltersEnabled} name="audience-filter-toggle" onChange={onCheckBoxChange} />
      </FilterLabel>
      {!!audienceFiltersEnabled ? (
        <div>
          {!exclusionCampaign && (
            <Audience
              id="includedSegmentsFilters"
              name="includedSegmentsFilters"
              label="Included Audiences Filters"
              segments={includedsegmentOptions}
              segmentsFetching={segmentsFetching}
              selected={includedSegmentsFilters}
              fetchSegments={fetchSegments}
              typeBase="RULE"
            />
          )}
          <Audience
            id="excludedSegmentsFilters"
            name="excludedSegmentsFilters"
            label="Excluded Audiences Filters"
            segments={excludedsegmentOptions}
            segmentsFetching={false}
            selected={excludedSegmentsFilters}
            fetchSegments={fetchSegments}
            typeBase="RULE"
          />
        </div>
      ) : null}
    </Wrapper>
  );
};
export default memo(AudienceFilter);
const FilterLabel = styled(Label)`
  & > .label-text {
    margin-right: 0;
  }
`;

const Wrapper = styled.section`
  position: relative;
  padding: 1rem 0;
`;
