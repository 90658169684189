import { IStackableGroupCampaign } from 'app/features/CashbackPromoManagement/types';

import * as types from './types';

export const clear = () => ({
  type: types.GET_SUCCESS,
  payload: {
    campaigns: [],
  },
});

export const getError = (error: Error) => ({
  type: types.GET_ERROR,
  payload: { error },
});

export const getInit = () => ({
  type: types.GET_INIT,
});

export const getSuccess = (campaigns: Array<IStackableGroupCampaign>) => ({
  type: types.GET_SUCCESS,
  payload: {
    campaigns,
  },
});

export const saveError = (error: Error) => ({
  type: types.SAVE_ERROR,
  payload: {
    error,
  },
});

export const saveStart = () => ({
  type: types.SAVE_START,
});

export const saveSuccess = () => ({
  type: types.SAVE_SUCCESS,
});

export const update = (campaigns: Array<IStackableGroupCampaign>) => ({
  type: types.UPDATE,
  payload: {
    campaigns,
  },
});
