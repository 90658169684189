import { withStateHandlers, compose } from 'recompose';
import { retainSubFeatureUserInputIfNecessary } from 'app/helpers/FeatureMetadataHelpers/helpers';
// eslint-disable-next-line import/no-cycle
import { SubFeatureRuleRow } from './SubFeatureRuleRow';

const addStateHandlers = withStateHandlers(({ items = [] }) => ({ items }), {
  handleChange:
    ({ items: oldValue }, { onChange }) =>
    (isUserInput, newValue) => {
      const change = isUserInput ? newValue : retainSubFeatureUserInputIfNecessary(oldValue, newValue);

      onChange(change);
      return { items: change };
    },
});

const enhance = compose(addStateHandlers);
export default enhance(SubFeatureRuleRow);
