import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import { connect } from 'react-redux';

import { featureSelectors, segmentOperations, segmentSelectors } from 'app/ducks';
import { getSuccess } from 'app/ducks/segments/segment/actions';

import { New } from './New';
import { validate } from './validation';
import { enhanceSegment, toAudienceTypeState } from '../utils';

const mapStateToProps = state => {
  const segment = segmentSelectors.readMe(state);
  return {
    segment,
    loading: segment.loading,
    featureDicts: featureSelectors.metaData(featureSelectors.readMe(state)),
  };
};

const mapDispatchToProps = {
  cloneSegment: segmentOperations.cloneSegment,
  newSegment: segmentOperations.newSegment,
  postSegment: segmentOperations.postSegment,
  resetSegment: segmentOperations.resetSegment,
  setSegment: segment => dispatch => dispatch(getSuccess(segment)),
};

const addState = withState('audienceType', 'setAudienceType', ({ audienceType }) => audienceType);

const addValidation = withProps(({ audienceType, segment }) => validate(segment, audienceType));

const addHandlers = withHandlers({
  handleCreate:
    ({ history, postSegment }) =>
    () =>
      postSegment(history),
});

const initStore = lifecycle({
  async componentDidMount() {
    const {
      history: {
        location: { state },
      },
    } = this.props;
    if (state) {
      const { id, segment } = state;
      const { featureDicts } = this.props;
      if (segment) {
        this.props.setSegment(enhanceSegment(segment, featureDicts));
        this.props.setAudienceType(toAudienceTypeState(segment.type));
      } else if (id !== undefined) {
        await this.props.cloneSegment(id, featureDicts);
        this.props.setAudienceType(toAudienceTypeState(this.props.segment.type));
      }
    } else {
      this.props.newSegment();
    }
  },
  componentWillUnmount() {
    this.props.resetSegment();
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), addState, addValidation, addHandlers, initStore);

export default enhance(New);
