import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { Props as _Props } from 'app/features/BannerManagement/common/withCreativesReporting';
import withCreativesReporting, { BannerCreativesReportBody } from 'app/features/BannerManagement/common/withCreativesReporting';

import { searchParams } from 'app/features/BannerManagement/common/withCreativesReporting/search.config';
import { searchBarAdaptor } from 'app/hocs/withReporting/withCreatedBySwitch';
import { CreatedBySwitch as _CreatedBySwitch } from 'app/midgarComponents/OptionsSwitch/CreatedBySwitch';
import { SearchContainer as _SearchContainer } from 'app/midgarComponents/ReportingPage';

type Props = _Props & {
  onSelect: (...args: Array<any>) => any;
  userEmail: string;
};

const CreatedBySwitch = searchBarAdaptor(searchParams.createdBy, searchParams.id)(_CreatedBySwitch);

class SelectCreativeTable extends PureComponent<Props> {
  render() {
    const { pushSearchBar, searchPhrase, selectedSearchType, userEmail } = this.props;

    return (
      <div>
        <SearchContainer>
          <CreatedBySwitch
            userEmail={userEmail}
            searchPhrase={searchPhrase}
            selectedSearchType={selectedSearchType}
            pushSearchBar={pushSearchBar}
          />
        </SearchContainer>

        <BannerCreativesReportBody {...this.props} />
      </div>
    );
  }
}

export default withCreativesReporting(SelectCreativeTable);

const SearchContainer = styled(_SearchContainer)`
  margin-left: 1rem;
`;
