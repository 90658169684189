import React from 'react';

import MultiValueTextField from '../MultiValueTextField';
import ChipInputDialog from './ChipInputDialog';

const MAX_CHIPS_IN_TOOLTIP = 20;

type Props = {
  id: string;
  label: string;
  values?: Array<string | number>;
  stringifier: (...args: Array<any>) => any;
  handleOpen: (...args: Array<any>) => any;
  handleClose: (...args: Array<any>) => any;
  isOpen: boolean;
  dialogTitle?: string;
  classes: {};
};

export const ReadOnlyInputWithChipInputDialog = ({
  id,
  label,
  values = [],
  stringifier,
  handleOpen,
  handleClose,
  isOpen,
  dialogTitle,
  classes,
}: Props) => (
  <>
    <MultiValueTextField
      id={id}
      label={label}
      stringifier={stringifier}
      handleOpen={handleOpen}
      classes={{}}
      fullWidth
      values={values}
      tooltipId={`chip-input-tooltip-${id}`}
      maxChipsInTooltip={MAX_CHIPS_IN_TOOLTIP}
      helperText={values.length >= MAX_CHIPS_IN_TOOLTIP && `${values.length} Values`}
    />

    <ChipInputDialog values={values} isOpen={isOpen} dialogTitle={dialogTitle} classes={classes} onClose={handleClose} />
  </>
);
