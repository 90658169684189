import * as types from './types';
import { fetch } from '../../commonStates';

const usersState = {
  ...fetch,
  users: [],
};

const users = (state = usersState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_USERS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        users: payload.users,
        ...payload.paged,
      };

    case types.GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case types.DELETE_USER:
      return {
        ...state,
        loading: false,
        error: null,
        users: state.users.filter(u => u.email !== payload),
      };

    default:
      return state;
  }
};

export default users;
