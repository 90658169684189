const root = ({ templates: { templates } }) => templates;

const templates = state => {
  const { allIds, byId } = root(state);
  return allIds.map(id => byId[id]);
};

const byId = state => root(state).byId;

const paging = state => {
  const { first, last, number, size, totalElements } = root(state);

  return {
    first,
    last,
    number,
    size,
    totalElements,
  };
};

const loading = state => root(state).loading;

const error = state => {
  const thisError = root(state).error;
  return thisError ? thisError.message : null;
};

export { byId, error, loading, paging, templates };
