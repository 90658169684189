import { fetchTemplates, fetchTemplatesPaged } from 'app/api/templates';

import { Action, DispatchFn, IQueryParams } from 'app/types';

import * as actions from './actions';

const getTemplates =
  (params: IQueryParams = {}) =>
  async (dispatch: DispatchFn): Action => {
    dispatch(actions.getAll);
    try {
      const templates = await fetchTemplates(params);
      return dispatch(actions.getAllSuccess(templates));
    } catch (e) {
      return dispatch(actions.getAllFail(e));
    }
  };

const getTemplatesPaged =
  (params: IQueryParams = {}) =>
  async (dispatch: DispatchFn): Action => {
    dispatch(actions.getAll());
    try {
      const { content: templates, ...paging } = await fetchTemplatesPaged(params);
      return dispatch(
        actions.getAllSuccess(templates, {
          ...paging,
        }),
      );
    } catch (e) {
      return dispatch(actions.getAllFail(e));
    }
  };

export { getTemplates, getTemplatesPaged };
