import { Segments } from '../../../types/typescript/Segments';

export type TargetValues = {
  customerCountPerExecution: number;
  includedSegments: Segments;
  excludedSegments: Segments;
  includedSegmentsFilters: Segments;
  excludedSegmentsFilters: Segments;
  includedSegmentsGeoFilters: Segments;
  audienceFiltersEnabled: boolean;
  useEveryoneAudience: boolean;
  exclusionCampaign: boolean;
  audienceId: Segments;
};

export const initializeTargetValues = (storedValues: TargetValues): object => ({
  customerCountPerExecution: storedValues.customerCountPerExecution || '',
  includedSegments: storedValues.includedSegments,
  excludedSegments: storedValues.excludedSegments,
  includedSegmentsFilters: storedValues.includedSegmentsFilters,
  excludedSegmentsFilters: storedValues.excludedSegmentsFilters,
  includedSegmentsGeoFilters: storedValues.includedSegmentsGeoFilters,
  audienceFiltersEnabled: storedValues.audienceFiltersEnabled,
  useEveryoneAudience: storedValues.useEveryoneAudience,
  exclusionCampaign: storedValues.exclusionCampaign,
  audienceId: storedValues.audienceId || [],
});
