import * as React from 'react';

import { VariableProps } from './types';
import { standardComparableOps, dateType } from 'app/helpers/FeatureMetadataHelpers/common';
import { StyledInput, Dropdown, SpaceHolder } from './styledComponents';
import { datetimeType, datetimeTypeV2 } from '../../helpers/FeatureMetadataHelpers/common';
import MultiChoiceFeatureValue from './MultiChoiceFeatureValue';

const INPUTTYPES = {
  double: 'number',
  date: 'date',
  float: 'number',
  string: 'text',
};

const MULTICHOICEOPS = ['in', 'not in'];
const TIMEUNITS = {
  m: 'minute',
  h: 'hour',
  d: 'day',
  w: 'week',
  M: 'month',
  y: 'year',
};

const TIME_UNIT_OPTIONS = Object.keys(TIMEUNITS).map(unit => ({
  id: unit,
  label: `${TIMEUNITS[unit]}(s)`,
  name: unit,
}));

const splitInputUnit = val => {
  // If value is empty
  if (!val) return ['', ''];

  // If only unit is selected
  if (TIMEUNITS[val]) {
    return ['', val];
  }
  // If unit and value both are selected
  if (isNaN(val)) {
    return [val.substring(0, val.length - 1) || '', val.substring(val.length - 1)];
  }
  // If only unit is selected
  return [val, ''];
};

export const FilterValue = ({ featureId, dataType, rule, changeValue }: VariableProps) => {
  const changeStringSetValue = val => changeValue({ target: { value: val.split(',') } });

  const chnageUnit = inputUnit => {
    const [value] = splitInputUnit(rule.operands[1]);
    const newValue = `${value}${inputUnit.id}`;
    changeValue({ target: { value: newValue } });
  };

  const changeInputValue = ({ target: { value } }) => {
    const unit = splitInputUnit(rule.operands[1])[1];
    const newValue = `${Number.parseInt(value) || ''}${unit}`;
    changeValue({ target: { value: newValue } });
  };

  if (!rule?.op || rule?.op.toLowerCase() === 'exists') {
    return <SpaceHolder />;
  }

  if ([dateType, datetimeType, datetimeTypeV2].includes(dataType.toLowerCase()) && !standardComparableOps.includes(rule.op)) {
    const [filterValue, timeUnit] = splitInputUnit(rule.operands[1]);
    const displayTimeUnitValue = timeUnit && TIMEUNITS[timeUnit] ? `${TIMEUNITS[timeUnit]}s` : '';
    return (
      <>
        <StyledInput type="number" placeholder="Filter value" value={filterValue} onChange={changeInputValue} />
        <Dropdown label="Time Unit" options={TIME_UNIT_OPTIONS} value={displayTimeUnitValue} onChange={chnageUnit} />
      </>
    );
  }

  if (MULTICHOICEOPS.includes(rule.op.toLowerCase())) {
    return <MultiChoiceFeatureValue value={(rule.operands[1] || []).join(',')} changeValue={changeStringSetValue} featureId={featureId} />;
  }
  return (
    <StyledInput
      type={INPUTTYPES[dataType.toLowerCase()] || 'text'}
      placeholder="Filter value"
      value={rule.operands[1]}
      onChange={changeValue}
    />
  );
};
