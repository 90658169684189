import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';

import { prettifyPredicate, countPredicateRuleValue } from 'app/midgarComponents/PredicateBuilder/helper';
import { diffArray } from 'app/helpers/RamdaHelpers/helpers';

export const Row = styled.div`
  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmall};
  display: flex;
  margin: ${sc.gutterSmaller} 0;
  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
`;

export const Name = styled.div`
  margin-right: ${sc.gutterSmallest};
  width: 25%;
  min-width: 210px;
`;

export const Value = styled.div`
  flex-grow: 1;
  min-width: 10rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-height: 200px;
  overflow-y: auto;
  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
`;

export const Li = styled.li`
  flex-grow: 1;
  min-width: 10rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin: 0 1rem 0.5rem 0;
  list-style: inside;
  display: block;
  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
`;

const UL = styled.ul`
  overflow: hidden;
  word-break: break-all;
`;

const Predicate = styled.div`
  overflow-wrap: anywhere;
  overflow-y: scroll;
  max-height: 200px;
  display: list-item;
`;

const Count = styled.p`
  font-weight: bold;
  margin-top: 1rem;
  text-align: right;
`;

export const Criteria = ({ criteria, originalCriteria }) => {
  if (typeof criteria === 'string') {
    return <Value highlight={originalCriteria && criteria !== originalCriteria}>{criteria}</Value>;
  }

  const diffs = diffArray(criteria, originalCriteria);

  return (
    <UL>
      {criteria.map(crit => {
        const total = countPredicateRuleValue(crit);
        return (
          <Li key={crit.id} highlight={originalCriteria && diffs.find(diff => diff.id === crit.id)}>
            <Predicate>{prettifyPredicate(crit)}</Predicate>
            {total && <Count>Total: {total}</Count>}
          </Li>
        );
      })}
    </UL>
  );
};
