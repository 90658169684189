export const segments = ({
  segments: {
    segments: { byId, allIds },
  },
}) => allIds.map(id => byId[id]);

export const paging = ({
  segments: {
    segments: { number, first, last, totalElements, size },
  },
}) => ({
  number,
  first,
  last,
  totalElements,
  size,
});
