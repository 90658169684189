import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { IFeature } from 'app/api/features/types';
import { campaignOperations } from 'app/ducks';
import { FeaturesConnect } from 'app/ducks/features/Features';
import Variables from 'app/features/Variables';
import { IVariable } from 'app/types/Campaign';

type Props = {
  addVariable: (arg0: IVariable) => any;
  className?: string;
  features: {
    data: {
      allNames: Array<IFeature>;
    };

    error?: any;
    loading: boolean;
    realTimeEvents: Array<any>;
    eventName: string;
  };

  removeVariable: (arg0: IVariable) => any;
  updateVariable: (arg0: IVariable) => any;
  variables: Array<IVariable>;
  campaign: {
    triggerType: string;
  };

  templated: boolean;
};

class PersonalizationVariables extends React.PureComponent<Props> {
  render() {
    const {
      addVariable,
      className,
      features,
      removeVariable,
      variables,
      updateVariable,
      campaign: { triggerType },
      templated,
    } = this.props;

    return (
      <section className={className} data-qa="variables-section">
        <Variables
          addVariable={addVariable}
          error={features.error}
          features={features}
          title="By Feature(s)"
          type="features"
          removeVariable={removeVariable}
          updateDefault={updateVariable}
          variables={variables}
          prefix={templated ? 'features' : ''}
        />

        {triggerType === 'REAL_TIME' && (
          <Variables
            addVariable={addVariable}
            error={features.error}
            features={features.realTimeEvents}
            title="By Real-Time Event(s)"
            type="events"
            removeVariable={removeVariable}
            updateDefault={updateVariable}
            variables={variables}
            prefix={templated ? `event.${features.eventName}` : ''}
          />
        )}
      </section>
    );
  }
}

export default compose(
  FeaturesConnect,
  connect(
    ({
      campaigns: {
        campaign: {
          general: { variables },
        },
      },
    }) => ({
      variables,
    }),

    {
      addVariable: campaignOperations.addVariable,
      removeVariable: campaignOperations.removeVariable,
      updateVariable: campaignOperations.updateVariable,
    },
  ),
)(PersonalizationVariables);
