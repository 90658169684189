import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { sc } from 'app/styles';
import { Button, Dropdown, Icon, Spinner, TextField } from 'app/midgarComponents';
import { inject } from 'app/decorators';
import { fetchUserIdByPhone } from 'app/api/debug';
import * as DebugOperations from 'app/ducks/debug/operations';
import { IDropdownOption } from 'app/midgarComponents/Dropdown';
import { Tooltip } from 'app/components';

import { compose } from 'recompose';

type Props = {
  loading: boolean;
  bannerIds: Array<number>;
  bannerDebugs: Record<string, any>;
  DebugOperations: typeof DebugOperations;
};

type State = {
  userSearch: {
    error?: Error;
    loading?: boolean;
    phone: string;
    userId?: string;
  };

  userId: string;
  viewId: string;
  selectedPlatform: string;
  state: string;
  city: string;
  personalization: string;
  version: string;
  siteId: string;
};

class UpcomingBanners extends PureComponent<Props, State> {
  static defaultProps = {
    loading: false,
    bannerIds: [],
    DebugOperations: {},
  };

  state = {
    userSearch: {
      phone: '',
    },

    userId: '',
    viewId: '',
    state: '',
    city: '',
    selectedPlatform: 'ANDROID',
    personalization: 'manual',
    version: '7.0.4',
    siteId: '1',
  };

  handleUserSearchChange = ({ target: { value } }) => this.setState(({ userSearch }) => ({ userSearch: { ...userSearch, phone: value } }));

  handleUserSearchKeyPress = async ev => {
    if (ev.key === 'Enter') {
      this.setState(({ userSearch }) => ({ userSearch: { ...userSearch, error: null, loading: true, userId: null } }));
      const {
        userSearch: { phone },
      } = this.state;
      try {
        const { customerId } = await fetchUserIdByPhone(phone);
        this.setState(({ userSearch }) => ({ userSearch: { ...userSearch, loading: false, userId: customerId } }));
      } catch (error) {
        this.setState(({ userSearch }) => ({ userSearch: { ...userSearch, loading: false, error } }));
      }
    }
  };

  handleSearchResultClick = () => this.setState(({ userSearch }) => ({ userId: userSearch.userId }));

  search = () => {
    const {
      userId,
      viewId,
      city,
      state,
      selectedPlatform: platform,
      personalization: personalization_type, // eslint-disable-line camelcase
      version,
      siteId,
    } = this.state;
    const {
      DebugOperations: { fetchUpcomingBanners },
    } = this.props;

    fetchUpcomingBanners(viewId, Number(userId), {
      platform,
      model_id: 4,
      personalization_type,
      version,
      site_id: Number(siteId),
      location: { city, state },
      size: '100',
    });
  };

  render() {
    const {
      loading,
      bannerIds,
      bannerDebugs: { banners, user_categories: userCategories, view_meta: bannerMetaData, boosted_categories: boostedCategories },
    } = this.props;

    const { userSearch, userId, viewId, selectedPlatform, personalization, version, siteId, city, state } = this.state;

    const platforms = ['ANDROID', 'IOS', 'WEB'];
    const isValidSearch = !!userId && !!viewId && !!personalization && !!siteId && !!selectedPlatform;

    return (
      <section>
        <UserSearchGroup>
          <PhoneFieldSearchField
            id="phone"
            name="phone"
            label="Find User By Phone"
            value={userSearch.phone}
            onChange={this.handleUserSearchChange}
            onKeyPress={this.handleUserSearchKeyPress}
          />

          <div>
            {userSearch.loading && <Spinner />}

            {userSearch.userId && <Button onClick={this.handleSearchResultClick}>User ID = {userSearch.userId}</Button>}

            {userSearch.error && <UserSearchError>{userSearch.error.message}</UserSearchError>}
          </div>
        </UserSearchGroup>

        <SearchGroup>
          <TextField
            required
            id="version"
            name="version"
            type="text"
            label="Version"
            value={version}
            onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ version: ev.currentTarget.value })}
          />

          <TextField
            required
            id="userId"
            name="userId"
            type="number"
            label="User ID"
            value={userId}
            onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ userId: ev.currentTarget.value })}
          />

          <TextField
            required
            id="viewId"
            name="viewId"
            type="number"
            label="View ID"
            value={viewId}
            onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ viewId: ev.currentTarget.value })}
          />

          <TextField
            required
            id="personalization"
            name="personalization"
            type="text"
            label="Personalization"
            value={personalization}
            onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ personalization: ev.currentTarget.value })}
          />
        </SearchGroup>

        <SearchGroup>
          <TextField
            required
            id="siteId"
            name="siteId"
            type="number"
            label="Site ID"
            value={siteId}
            onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ siteId: ev.currentTarget.value })}
          />

          <Dropdown
            required
            options={platforms}
            value={selectedPlatform}
            label="Platform"
            onChange={(platform: string | IDropdownOption) =>
              this.setState({ selectedPlatform: typeof platform === 'string' ? platform : platform.id })
            }
          />

          <TextField
            id="city"
            name="city"
            type="text"
            label="City"
            value={city}
            onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ city: ev.currentTarget.value })}
          />

          <TextField
            id="state"
            name="state"
            type="text"
            label="State"
            value={state}
            onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ state: ev.currentTarget.value })}
          />

          <Tooltip title={!isValidSearch ? 'Please fill out all the required search boxes!' : ''} type="error">
            <SearchIcon size={40} name="search" disabled={!isValidSearch} onClick={() => (isValidSearch ? this.search() : null)} />
          </Tooltip>
        </SearchGroup>

        {loading ? (
          <Spinner />
        ) : (
          <>
            <DebugContainer>
              <DebugHeader>Banner IDs</DebugHeader>
              <ContentSection>{JSON.stringify(bannerIds, null, 2)}</ContentSection>
            </DebugContainer>

            <DebugContainer>
              <DebugHeader>Banners</DebugHeader>
              <ContentSection>{JSON.stringify(banners, null, 2)}</ContentSection>
            </DebugContainer>

            <DebugContainer>
              <DebugHeader>User Categories</DebugHeader>
              <ContentSection>{JSON.stringify(userCategories, null, 2)}</ContentSection>
            </DebugContainer>

            <DebugContainer>
              <DebugHeader>Banner Meta Data</DebugHeader>
              <ContentSection>{JSON.stringify(bannerMetaData, null, 2)}</ContentSection>
            </DebugContainer>

            <DebugContainer>
              <DebugHeader>Boosted Categories</DebugHeader>
              <ContentSection>{JSON.stringify(boostedCategories, null, 2)}</ContentSection>
            </DebugContainer>
          </>
        )}
      </section>
    );
  }
}

export default compose(
  connect(state => ({
    originDict: state.features.data.dicts.originDict,
    loading: state.debug.loading,
    bannerIds: state.debug.bannerIds,
    bannerDebugs: state.debug.bannerDebugs,
    states: state.debug.states,
    statesLoading: state.debug.statesLoading,
  })),

  inject({ DebugOperations }),
)(UpcomingBanners);

const SearchGroup = styled.div`
  margin-top: ${sc.gutterSmall};
  display: flex;
  & > div,
  & > section,
  & > button {
    width: calc((100% - 4 * ${sc.gutterSmall} - 2 * ${sc.gutterLarge}) / 5);
    margin-left: ${sc.gutterSmall};

    &:first-child {
      margin-left: 0;
    }
  }
`;

const DebugContainer = styled.div`
  margin-top: ${sc.gutterLargest};
`;

const DebugHeader = styled.div`
  font-size: ${sc.fontSize};
  color: ${sc.headingColor};
`;

const ContentSection = styled.pre`
  white-space: pre-wrap;
  font-family: monospace;
  color: ${sc.subHeadingColor};
  height: 300px;
  margin-top: ${sc.gutterSmallest};
  padding: ${sc.gutterSmall};
  border-bottom: 1px solid ${sc.sectionBackgroundColor};
  border-top: 1px solid ${sc.sectionBackgroundColor};

  overflow-y: auto;
`;

const PhoneFieldSearchField = styled(TextField)`
  width: 25%;
`;

const SearchIcon = styled(Icon)`
  color: ${props => (props.disabled ? sc.subHeadingColor : sc.primary)};
  margin-left: ${sc.gutterLargest};
  padding-top: ${sc.gutterSmaller};

  &:hover {
    cursor: pointer;
  }
`;

const UserSearchGroup = styled.section`
  display: flex;
  align-items: center;
  & > * {
    margin-left: ${sc.gutter};
  }
  & > *:first-child {
    margin-left: 0;
  }
  padding-bottom: ${sc.gutter};
  border-bottom: 3px solid ${sc.greyLighter};
`;

const UserSearchError = styled.section`
  font-size: ${sc.fontSizeSmaller};
  color: ${sc.danger};
`;
