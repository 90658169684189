import { combineReducers } from 'redux';

import segments, { segmentsOperations, segmentsSelectors } from './segments';
import segment, { segmentActions, segmentOperations, segmentSelectors } from './segment';

export { segmentsOperations, segmentsSelectors, segmentActions, segmentOperations, segmentSelectors };

export default combineReducers({
  segment,
  segments,
});
