const ROOT = 'map/campaign';

export const GET_CAMPAIGN = `${ROOT}/GET_CAMPAIGN`;
export const GET_CAMPAIGN_SUCCESS = `${ROOT}/GET_CAMPAIGN_SUCCESS`;
export const GET_CAMPAIGN_FAIL = `${ROOT}/GET_CAMPAIGN_FAIL`;
export const POST_CAMPAIGN = `${ROOT}/POST_CAMPAIGN`;
export const POST_CAMPAIGN_SUCCESS = `${ROOT}/POST_CAMPAIGN_SUCCESS`;
export const POST_CAMPAIGN_FAIL = `${ROOT}/POST_CAMPAIGN_FAIL`;
export const NEW_CAMPAIGN = `${ROOT}/NEW_CAMPAIGN`;
export const SET_CAMPAIGN_FIELD = `${ROOT}/SET_CAMPAIGN_FIELD`;
export const ADD_TO_CAMPAIGN_FIELD = `${ROOT}/ADD_TO_CAMPAIGN_FIELD`;
export const REMOVE_FROM_CAMPAIGN_FIELD = `${ROOT}/REMOVE_FROM_CAMPAIGN_FIELD`;
export const GET_CAMPAIGN_SIZE = `${ROOT}/GET_CAMPAIGN_SIZE`;
export const GET_CAMPAIGN_SIZE_SUCCESS = `${ROOT}/GET_CAMPAIGN_SIZE_SUCCESS`;
export const GET_CAMPAIGN_SIZE_FAIL = `${ROOT}/GET_CAMPAIGN_SIZE_FAIL`;
export const UPDATE_FEATURES_VARIABLES = `${ROOT}/UPDATE_FEATURES_VARIABLES`;
export const UPDATE_EVENTS_VARIABLES = `${ROOT}/UPDATE_EVENTS_VARIABLES`;
export const ADD_FEATURES_VARIABLE = `${ROOT}/ADD_FEATURES_VARIABLE`;
export const ADD_EVENTS_VARIABLE = `${ROOT}/ADD_EVENTS_VARIABLE`;
export const REMOVE_FEATURES_VARIABLE = `${ROOT}/REMOVE_FEATURES_VARIABLE`;
export const REMOVE_ALL_EVENTS_VARIABLES = `${ROOT}/REMOVE_ALL_EVENTS_VARIABLES`;
export const REMOVE_EVENTS_VARIABLE = `${ROOT}/REMOVE_EVENTS_VARIABLE`;
export const REMOVE_ALL_VARIABLES = `${ROOT}/REMOVE_ALL_VARIABLES`;
export const SET_EMAIL_CAMPAIGN = `${ROOT}/SET_EMAIL_CAMPAIGN`;
export const SET_BANNER_CAMPAIGN = `${ROOT}/SET_BANNER_CAMPAIGN`;
export const SET_STATUS = `${ROOT}/SET_STATUS`;
export const SET_LOCATION_TRIGGER = `${ROOT}/SET_LOCATION_TRIGGER`;
export const ADD_SEGMENT = `${ROOT}/ADD_SEGMENT`;
export const SET_SEGMENTS = `${ROOT}/SET_SEGMENTS`;
export const REMOVE_SEGMENT = `${ROOT}/REMOVE_SEGMENT`;
export const CLEAR_SEGMENTS = `${ROOT}/CLEAR_SEGMENTS`;
export const CLEAR_FILTER_SEGMENTS = `${ROOT}/CLEAR_FILTER_SEGMENTS`;
export const UPDATE_FACEBOOK_VALUES = `${ROOT}/UPDATE_API_FACEBOOK_VALUES`;
export const SET_CRITERIA = `${ROOT}/SET_CRITERIA`;
export const CLEAR_CRITERIA = `${ROOT}/CLEAR_CRITERIA`;
export const SET_DEACTIVATION_CRITERIA = `${ROOT}/SET_DEACTIVATION_CRITERIA`;
export const CLEAR_DEACTIVATION_CRITERIA = `${ROOT}/CLEAR_DEACTIVATION_CRITERIA`;
export const SET_GOAL = `${ROOT}/ADD_GOAL`;
export const REMOVE_GOAL = `${ROOT}/REMOVE_GOAL`;
export const SET_PROMO_CODE = `${ROOT}/SET_PROMO_CODE`;
export const SET_BUSINESS_PHASE = `${ROOT}/SET_BUSINESS_PHASE`;
// One for each mediumId
export const SET_API_CREATIVE = `${ROOT}/SET_API_CREATIVE`;
export const SET_BANNER_CREATIVE = `${ROOT}/SET_BANNER_CREATIVE`;
export const SET_REGULAR_CASHBACK_CREATIVE = `${ROOT}/SET_REGULAR_CASHBACK_CREATIVE`;
export const SET_JOURNEY_CREATIVE = `${ROOT}/SET_JOURNEY_CREATIVE`;
export const SET_EMAIL_CREATIVE = `${ROOT}/SET_EMAIL_CREATIVE`;
// SET_FACEBOOK_CREATIVE: Not needed, same as SET_API_CREATIVE
export const SET_MERCHANT_BANNER_CREATIVE = `${ROOT}/SET_MERCHANT_BANNER_CREATIVE`;
export const SET_PUSH2_CREATIVE = `${ROOT}/SET_PUSH2_CREATIVE`;
export const SET_TIMELINE_NOTIFICATION_CREATIVE = `${ROOT}/SET_TIMELINE_NOTIFICATION_CREATIVE`;

// Metrics
export const GET_METRICS_SUCCESS = 'map/campaigns/GET_METRICS_SUCCESS';
export const GET_METRICS_ERROR = 'map/campaigns/GET_METRICS_ERROR';

// Form Logic
// TODO: Remove once confirmed we don't need to pass errors through to validations.js
export const SET_FORM_ERRORS = 'map/campaigns/SET_FORM_ERRORS';
export const TOGGLE_SUBMIT_ALL_FORMS = 'map/campaigns/TOGGLE_SUBMIT_ALL_FORMS';
export const SET_HIDDEN_FORMS_SUBMITTED = 'map/campaigns/SET_HIDDEN_FORMS_SUBMITTED';
export const RESET_HIDDEN_FORMS_SUBMITTED = 'map/campaigns/RESET_HIDDEN_FORMS_SUBMITTED';
export const RESET_FORM_ERRORS = 'map/campaigns/RESET_FORM_ERRORS';
