import { compose, withHandlers, withProps } from 'recompose';

import { SearchDefsArg } from 'app/hocs/withReporting';
import { asSearchDefs } from 'app/hocs/withReporting/withSearch/utils';
import { getPhraseAndType, mergePhraseAndType, msgIfEmpty } from './utils';

/**
 * Enhances the component with:
 *  - `searchPhrase: string` = Text entered in search bar.
 *  - `selectedSearchType: string` = Selected search type in search bar.
 *  - `msgIfEmpty: string` = Message to be displayed if there are no matching search results.
 *  - `handleSearchPhraseChange: () => void` = Executes search if the phrase is cleared. Otherwise, executes search when Enter is pressed.
 *  - `handleSearchTypeChange: () => void` = Executes the search immediately if a search phrase has been entered.
 *  - `pushSearchBar: Function`: Executes the given `searchPhrase` and `selectedSearchType`,
 *    including all the search state which may have not been executed yet.
 */
export default (searchBarDefiner: SearchDefsArg) =>
  compose(
    withProps((props: Record<string, any>) => {
      const { search } = props;
      const searchDefs = asSearchDefs(searchBarDefiner, props);
      const { searchPhrase, selectedSearchType } = getPhraseAndType(search, searchDefs);

      return {
        searchPhrase,
        selectedSearchType,
        msgIfEmpty: msgIfEmpty({ searchPhrase, selectedSearchType }, searchDefs),
      };
    }),

    withHandlers({
      handleSearchPhraseChange: (props: Record<string, any>) => (searchPhrase?: string) => {
        const {
          pushSearch,
          search,
          setSearchState,
          search: { searchBy },
        } = props;
        let { selectedSearchType } = props;
        selectedSearchType = selectedSearchType ? selectedSearchType : searchBy;
        const searchDefs = asSearchDefs(searchBarDefiner, props);
        const newSearch = mergePhraseAndType({ searchPhrase, selectedSearchType }, search, searchDefs);
        const { type } = searchDefs.find(def => def.name === selectedSearchType) || {};

        if ((!searchPhrase || searchPhrase.length === 0) && type !== 'number') {
          pushSearch(newSearch);
        } else {
          setSearchState(newSearch);
        }
      },

      handleSearchTypeChange: (props: Record<string, any>) => (selectedSearchType?: string) => {
        const { pushSearch, search, setSearchState } = props;
        let { searchPhrase } = props;
        const searchDefs = asSearchDefs(searchBarDefiner, props);
        const { type } = searchDefs.find(def => def.name === selectedSearchType) || {};

        if (type === 'number') {
          if (searchPhrase && Number.isNaN(Number(searchPhrase))) {
            searchPhrase = '0';
          }
        }

        const newSearch = mergePhraseAndType({ searchPhrase, selectedSearchType }, search, searchDefs);

        if (selectedSearchType && selectedSearchType.length > 0 && searchPhrase && searchPhrase.length > 0) {
          pushSearch(newSearch);
        } else {
          setSearchState(newSearch);
        }
      },

      pushSearchBar:
        props =>
        ({ searchPhrase, selectedSearchType }: { searchPhrase: string; selectedSearchType: string }) => {
          const { pushSearch, search } = props;
          const searchDefs = asSearchDefs(searchBarDefiner, props);
          const newSearch = mergePhraseAndType({ searchPhrase, selectedSearchType }, search, searchDefs);

          pushSearch(newSearch);
        },
    }),
  );
