import * as types from './types';

const getAll = () => ({
  type: types.GET_ALL_USERS,
});

const getAllSuccess = (users, paged = {}) => ({
  type: types.GET_ALL_USERS_SUCCESS,
  payload: { paged, users },
});

const getAllFail = error => ({
  type: types.GET_ALL_USERS_FAIL,
  payload: { error },
});

const deleteUser = userEmail => ({
  type: types.DELETE_USER,
  payload: userEmail,
});

export { getAll, getAllSuccess, getAllFail, deleteUser };
