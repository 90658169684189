import { compose, withStateHandlers } from 'recompose';

import { notEquals } from 'app/helpers/RamdaHelpers/helpers';
import { ReadOnlyInputWithChipInputDialog } from './ReadOnlyInputWithChipInputDialog';

type IValues = Array<string | number>;

const addStateHandlers = withStateHandlers(
  ({ values = [] }: { values: IValues }) => ({
    values,
    isOpen: false,
  }),

  {
    handleOpen: () => () => ({ isOpen: true }),
    handleClose:
      (_, { values: oldValues, onChange }: { values: IValues; onChange: (...args: Array<any>) => any }) =>
      (newValues: IValues) => {
        const newSet = new Set(newValues);

        if (notEquals(newSet, new Set(oldValues))) {
          const values = [...newSet];
          onChange(values);
          return { values, isOpen: false };
        }

        return { isOpen: false };
      },
  },
);

const enhance = compose(addStateHandlers);

export default enhance(ReadOnlyInputWithChipInputDialog);
