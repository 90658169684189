export const styles = theme => ({
  root: {
    width: '100%',
    minHeight: '10rem',
    textAlign: 'center',
    paddingTop: '3rem',
  },

  loading: {
    backgroundColor: theme.palette.grey[100],
  },
});
