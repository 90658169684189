import { lifecycle, compose } from 'recompose';

import React from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import sc from 'app/styles/config';

const lifeCycle = lifecycle({
  componentDidCatch(error, info) {
    const { displayError = defaultErrorDisplay } = this.props;
    console.error(error, { info, displayError });
    this.setState({
      boundaryError: error,
      boundaryInfo: info,
    });
  },
});

const defaultErrorDisplay = error => (
  <Paper elevation={4}>
    <ErrorBox>
      <Text>Please report this bug to the dev team.</Text>
      <Text>{error.message}</Text>
    </ErrorBox>
  </Paper>
);

const ErrorBoundary = ({ boundaryError, boundaryInfo, children, displayError = defaultErrorDisplay }) =>
  boundaryError ? displayError(boundaryError, boundaryInfo) : children;

const enhance = compose(lifeCycle);
export default enhance(ErrorBoundary);

const ErrorBox = styled.div`
  width: 50%;
  max-width: 500px;
  margin: 20% auto;
  padding: ${sc.gutterLarge};
  text-align: center;
  color: ${sc.danger};
`;

const Text = styled.p`
  margin: ${sc.gutterSmallest};
`;
