import { getCampaign } from '../selectors';

const getRegular = state => {
  const { cashbackCreative } = getCampaign(state);
  const { regular } = cashbackCreative;
  return regular;
};

export const getRegularCashbackCreative = state => {
  const { cashbackCreative } = getCampaign(state);
  const regular = getRegular(state);
  return {
    valid: cashbackCreative.valid,
    errorMessages: cashbackCreative.errorMessages,
    ...regular,
  };
};

export const getRegularCriteria = state => {
  const regular = getRegular(state);
  const { criteria } = regular;
  return criteria;
};

export const getRegularCalculation = state => {
  const regular = getRegular(state);
  const { calculation } = regular;
  return calculation;
};

export const getRegularStackCeiling = state => {
  const calculation = getRegularCalculation(state);
  const { stackCeiling } = calculation;
  return stackCeiling;
};

export const getRegularCalculationType = state => {
  const calculation = getRegularCalculation(state);
  const {
    payoutStrategy: { calculationType },
  } = calculation;
  return calculationType;
};

export const getPercentageType = state => {
  const { percentageType } = getRegularCalculation(state);
  return percentageType;
};

export const getRegularCeiling = state => {
  const calculation = getRegularCalculation(state);
  const {
    payoutStrategy: { ceiling },
  } = calculation;
  return ceiling;
};

const getJourney = state => {
  const { cashbackCreative } = getCampaign(state);
  const { journey } = cashbackCreative;
  return journey;
};

export const getJourneyCreative = state => {
  const { cashbackCreative } = getCampaign(state);
  const journey = getJourney(state);
  return {
    valid: cashbackCreative.valid,
    errorMessages: cashbackCreative.errorMessages,
    ...journey,
  };
};

export const getJourneyFulfillment = state => {
  const { fulfillment } = getJourneyCreative(state);
  return fulfillment;
};

export const getJourneyReversalStrategy = state => {
  const { reversalStrategy } = getJourneyCreative(state);
  return reversalStrategy;
};

export const getJourneyStackableGroup = state => {
  const { stackableGroup } = getJourneyCreative(state);
  return stackableGroup;
};

export const getJourneyPromoBanner = state => {
  const { cashbackPromoBanner } = getJourneyCreative(state);
  return cashbackPromoBanner;
};

export const getOrderedStageIds = state => {
  const { stages } = getJourney(state);
  return Object.values(stages)
    .sort((a, b) => a.order - b.order)
    .map(s => s.id);
};

export const getJourneyStage = (state, stageNum) => {
  const { stages } = getJourney(state);
  return stages[stageNum];
};

export const getJourneyOrder = (state, stageNum) => {
  const { order } = getJourneyStage(state, stageNum);
  return order;
};

export const getJourneyStageAttributes = (state, stageNum) => {
  const { customStageAttributes } = getJourneyStage(state, stageNum);
  return customStageAttributes;
};

export const getJourneyUserCriteria = state => {
  const {
    creativeUserCriteria: { userCriteria },
  } = getJourneyCreative(state);
  return userCriteria;
};

export const getJourneyUserCriteriaJson = state => {
  const {
    creativeUserCriteria: { userCriteriaJson },
  } = getJourneyCreative(state);
  return userCriteriaJson;
};

export const getJourneyEventCriteria = (state, stageNum) => {
  const { criteria } = getJourneyStage(state, stageNum);
  return criteria;
};

export const getJourneyCalculation = (state, stageNum) => {
  const { calculation } = getJourneyStage(state, stageNum);
  return calculation;
};

export const getJourneyCalculationType = (state, stageNum) => {
  const {
    payoutStrategy: { calculationType },
  } = getJourneyCalculation(state, stageNum);
  return calculationType;
};

export const getJourneyStackCeiling = (state, stageNum) => {
  const { stackCeiling } = getJourneyCalculation(state, stageNum);
  return stackCeiling;
};

export const getJourneyCeiling = (state, stageNum) => {
  const {
    payoutStrategy: { ceiling },
  } = getJourneyCalculation(state, stageNum);
  return ceiling;
};
