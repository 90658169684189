import templates from './reducers';

import * as templatesActions from './actions';
import * as templatesOperations from './operations';
import * as templatesSelectors from './selectors';
import { ConnectedTemplates, ConnectedTemplatesContainer } from './ConnectedTemplates';

export { ConnectedTemplates, ConnectedTemplatesContainer, templatesActions, templatesOperations, templatesSelectors };

export default templates;
