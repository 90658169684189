import { fetchStorefronts as v1FetchStorefronts } from 'app/api/bannerManagementV1/storefronts';
import { fetchStorefronts as v2FetchStorefronts } from 'app/api/bannerManagementV2/storefronts';
import bmsVersions from 'app/api/bmsCommon/bmsVersions';
import { storefrontTypes } from 'app/features/BannerManagement/Storefronts/constants';
import { DispatchFn, GetStateFn } from 'app/types';

import * as actions from './actions';
import { asBannerStorefront } from './IBannerStorefront';
import { mapStateToStorefronts } from './selectors';

const DEFAULT_ENTITY_TYPE = storefrontTypes.category;
// We can't return only the user's permitted storefronts, so retrieve all. But can't retrieve all, so retrieve a very large number.
const DEFAULT_PAGE_SIZE = 10000;

export const AllStoreFrontsQuery = {
  page_size: DEFAULT_PAGE_SIZE,
};

export const getStorefronts = (bmsVersion: string, entityType?: string) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const { storefronts, storefrontsError, storefrontsLoading } = mapStateToStorefronts(getState());

  if (storefronts || storefrontsError || storefrontsLoading) {
    return;
  }

  dispatch(actions.getStorefrontsStart());

  const useBmsV2 = bmsVersions.useV2(bmsVersion);

  try {
    const rawStorefronts = useBmsV2
      ? await v2FetchStorefronts(AllStoreFrontsQuery)
      : await v1FetchStorefronts(entityType || DEFAULT_ENTITY_TYPE, AllStoreFrontsQuery);
    const storefronts = rawStorefronts.map(asBannerStorefront);

    const {
      user: { storefronts: permittedStorefrontIds },
    } = getState();
    const permittedStorefronts = storefronts.filter(s => permittedStorefrontIds.includes(s.id));

    dispatch(actions.getStorefrontsSuccess({ permittedStorefronts, storefronts }));
  } catch (err) {
    console.error(err);
    const errorStr = err?.error?.msg || err.message || 'Error Retrieving Storefronts';
    dispatch(actions.getStorefrontsError(errorStr));
  }
};
