import styled from 'styled-components';

export const StatusIndicator = styled.span.attrs(({ color, status }) => ({
  color: () => {
    if (status) {
      switch (status) {
        case 'ERROR':
        case 'FAILED':
          return 'red';
        case 'SUCCESS':
        case 'READY':
          return 'green';
        case 'PENDING':
          return 'orange';
        default:
          return 'gray';
      }
    }
    return color || 'grey';
  },
}))`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${({ color }) => color};
`;
