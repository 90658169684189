import { IApiBannerCreative } from 'app/api/bannerManagementV2/creatives/types';
import { BmsError } from 'app/api/bannerManagementV2/types';
import { ICategory } from 'app/types';
import { IBannerCreative, IView, IViewItem } from 'app/types/BannerManagement';

import * as types from './types';

export const setCreativeFormErrors = (errors: Array<string>) => ({
  type: types.SET_CREATIVE_FORM_ERRORS,
  payload: { errors },
});

export const resetCreativeForm = () => ({
  type: types.RESET_CREATIVE_FORM,
});

export const updateField = (field: string, value: any) => ({
  type: types.UPDATE_CREATIVE_FORM_FIELD,
  payload: { field, value },
});

export const addVariantGroup = (newIndex: number) => ({
  type: types.UPDATE_CREATIVE_FORM_ADD_VARIANT_GROUP,
  payload: { newIndex },
});

export const removeVariantGroup = (index: number) => ({
  type: types.UPDATE_CREATIVE_FORM_REMOVE_VARIANT_GROUP,
  payload: { index },
});

export const addEngageCategoryGroup = (index: number, newIndex: number) => ({
  type: types.UPDATE_CREATIVE_FORM_ADD_ENGAGE_CATEGORY_GROUP,
  payload: { index, newIndex },
});

export const deleteEngageCategoryGroup = (variantIndex: number, categoryIndex: number) => ({
  type: types.UPDATE_CREATIVE_FORM_DELETE_ENGAGE_CATEGORY_GROUP,
  payload: { variantIndex, categoryIndex },
});

export const addAppVersionGroup = (index: number, newIndex: number) => ({
  type: types.UPDATE_CREATIVE_FORM_ADD_APP_VERSION_GROUP,
  payload: { index, newIndex },
});

export const deleteAppVersionGroup = (variantIndex: number, versionIndex: number) => ({
  type: types.UPDATE_CREATIVE_FORM_DELETE_APP_VERSION_GROUP,
  payload: { variantIndex, versionIndex },
});

export const updateVariantsField = (index: number, field: string, value: any) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_FIELD,
  payload: { index, field, value },
});

export const updateVariantsLocationsField = (index: number, field: string, value: any) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_LOCATIONS_FIELD,
  payload: { index, field, value },
});

export const updateVariantsEngageCategoriesField = (index: number, catIndex: number, field: string, value: any) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_ENGAGE_CATEGORIES_FIELD,
  payload: { index, catIndex, field, value },
});

export const updateVariantsAppVersionsField = (index: number, versionIndex: number, field: string, value: any) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_APP_VERSIONS_FIELD,
  payload: { index, versionIndex, field, value },
});

export const updateVariantsLabelField = (index: number, field: string, value: any) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_LABEL_FIELD,
  payload: { index, field, value },
});

export const updateVariantsMetadata = (variantIndex: number, metadata: Record<string, string>) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_METADATA,
  payload: { variantIndex, metadata },
});

export const updateVariantsPersonalizationMetadata = (variantIndex: number, personalizationMetadata: Record<string, string>) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_VARIANTS_PERSONALIZATION_METADATA,
  payload: { variantIndex, personalizationMetadata },
});

export const updateDuplicateMetadataFound = (duplicateMetadataFound: boolean) => ({
  type: types.UPDATE_CREATIVE_FORM_SET_DUPLICATE_METADATA_FOUND,
  payload: { duplicateMetadataFound },
});

/**
 * Fetch creative
 */
export const fetchCreative = () => ({
  type: types.FETCH_CREATIVE,
});

export const fetchCreativeSuccess = (creative: IBannerCreative) => ({
  type: types.FETCH_CREATIVE_SUCCESS,
  payload: { creative },
});

export const fetchCreativeError = (error: any) => ({
  type: types.FETCH_CREATIVE_ERROR,
  payload: { error: error.error },
});

/**
 * Save creative
 */
export const saveCreativeError = (error: BmsError) => ({
  type: types.SAVE_CREATIVE_ERROR,
  payload: { error },
});

export const saveCreativeStart = () => ({
  type: types.SAVE_CREATIVE_START,
});

export const saveCreativeSuccess = (res: any) => ({
  type: types.SAVE_CREATIVE_SUCCESS,
  payload: { res },
});

export const populateFormVariants = (
  view: IView | null | undefined,
  viewItem: IApiBannerCreative | IViewItem,
  engageCategories: Array<ICategory>,
) => ({
  type: types.POPULATE_CREATIVE_FORM_VARIANTS,
  payload: { engageCategories, view, viewItem },
});

export const populateCreativeFormStart = () => ({
  type: types.POPULATE_CREATIVE_FORM_START,
});

export const populateCreativeFormSuccess = (creative: Record<string, any>) => ({
  type: types.POPULATE_CREATIVE_FORM_SUCCESS,
  payload: { creative },
});

export const populateCreativeFormError = () => ({
  type: types.POPULATE_CREATIVE_FORM_ERROR,
});
