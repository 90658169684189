import { ICmaView } from 'app/api/storefront/types';

import * as types from './types';

export const getViewsError = (storefrontId: number, error: string) => ({
  type: types.GET_VIEWS_ERROR,
  payload: { error, storefrontId },
});

export const getViewsStart = (storefrontId: number) => ({
  type: types.GET_VIEWS_START,
  payload: { storefrontId },
});

export const getViewsSuccess = (storefrontId: number, views: Array<ICmaView>) => ({
  type: types.GET_VIEWS_SUCCESS,
  payload: { storefrontId, views },
});
