import { AppState, Action } from 'app/types';
import * as types from './types';

const initalState = {
  users: null,
  error: false,
};

export default (state: AppState = initalState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_ACL_STOREFRONT_USERS_SUCCESS:
      return {
        users: payload.users,
        error: false,
      };

    case types.GET_ACL_STOREFRONT_USERS_FAILED:
      return {
        users: null,
        error: true,
      };

    default:
      return state;
  }
};
