import styled from 'styled-components';
import { mixins } from 'app/styles';

export const Main = styled.section`
  width: 100%;
`;

export const MissingImages = styled.div`
  margin-bottom: 3rem;
`;

export const Title = styled.p`
  ${mixins.sectionHeading}
`;
