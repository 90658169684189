/**
 * Returns the item with the given ID from the list with the given ID.
 *
 * @param {*} srcLists
 * @param {*} listId
 * @param {*} itemId
 */
const getItem = (srcLists, listId, itemId) => {
  if (listId === undefined || itemId === undefined) {
    return undefined;
  }
  const list = srcLists.find(thisList => thisList.id === listId);
  return list ? list.items.find(thisItem => thisItem.id === itemId) : undefined;
};

/**
 * Returns the lists of visible items for the given selected path.
 *
 * @param {*} srcLists
 * @param {*} selected
 */
const getLists = (srcLists, selected) => {
  if (!srcLists || srcLists.length === 0) {
    return [];
  }
  const lists = [srcLists[0]];
  if (!selected || selected.length === 0) {
    return lists;
  }
  return selected.reduce((acc, thisSelected, i) => {
    const { itemId } = thisSelected;
    const childList = srcLists[i + 1];
    if (childList && childList.items.length > 0) {
      return [
        ...acc,
        {
          id: childList.id,
          name: childList.name,
          items: childList.items.filter(item => item.parentId === itemId),
        },
      ];
    }
    return acc;
  }, lists);
};

/**
 * Returns the list and item IDs of the parent of the given IDs.
 *
 * @param {*} srcLists
 * @param {*} listId
 * @param {*} itemId
 */
const getParentIds = (srcLists, listId, itemId) => {
  const item = getItem(srcLists, listId, itemId);
  if (!item) {
    return undefined;
  }
  const index = srcLists.findIndex(thisList => thisList.id === listId);
  if (index <= 0) {
    return undefined;
  }
  const parentList = srcLists[index - 1];
  const parent = getItem(srcLists, parentList.id, item.parentId);
  return parent ? { listId: parentList.id, itemId: parent.id } : undefined;
};

/**
 * Returns the list and item IDs tracing the path from the root to the given item.
 *
 * @param {*} srcLists
 * @param {*} listId
 * @param {*} itemId
 */
const getPath = (srcLists, listId, itemId) => {
  const srcItem = getItem(srcLists, listId, itemId);
  if (!srcItem) {
    return undefined;
  }
  let path = [{ listId, itemId }];
  let parentIds = getParentIds(srcLists, listId, itemId);
  while (parentIds) {
    path = [{ listId: parentIds.listId, itemId: parentIds.itemId }, ...path];
    parentIds = getParentIds(srcLists, parentIds.listId, parentIds.itemId);
  }
  return path;
};

export { getItem, getLists, getParentIds, getPath };
