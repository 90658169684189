import { Match } from 'react-router-dom';

import { CASHBACK_MANAGEMENT_BANNER_OVERVIEW } from 'configs/routes';

export const bannerOverviewGetUrl = (bannerId: number) => CASHBACK_MANAGEMENT_BANNER_OVERVIEW.replace(':id', String(bannerId));

export const bannerOverviewParseUrl = ({ match }: { match: Match }): { bannerId?: number } => {
  const {
    params: { id },
  } = match;
  const bannerId = Number(id);
  return Number.isNaN(bannerId) ? {} : { bannerId: Number(id) };
};
