import { withHandlers, compose, withProps, onlyUpdateForKeys, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { campaignOperations } from 'app/ducks';
import channelConfig from 'configs/channels/channel.config';
import { withStyles } from '@material-ui/core/styles';
import { Schedule } from './Schedule';

import { styles } from './styles';
import FullSchedule from './FullSchedule';

const handleChangeStartDateAndTime =
  ({ setCampaignField }) =>
  moment => {
    let newStartDate = '';
    let newStartTime = '';
    if (moment?.date && moment?.time) {
      newStartDate = moment.date;
      newStartTime = moment.time;
    }
    setCampaignField({
      startDate: newStartDate,
    });

    setCampaignField({
      startTime: newStartTime,
    });
  };

const handleChangeExpiryDateAndTime =
  ({ setCampaignField }) =>
  moment => {
    let newExpiryDate = '';
    let newExpiryTime = '';
    if (moment?.date && moment?.time) {
      newExpiryDate = moment.date;
      newExpiryTime = moment.time;
    }
    setCampaignField({
      expiryDate: newExpiryDate,
    });

    setCampaignField({
      expiryTime: newExpiryTime,
    });
  };

const updateTotalExecutions =
  ({ setCampaignField }) =>
  ({ target: { value } }) =>
    setCampaignField({
      customerCountPerExecution: value,
    });

const updateRecurring =
  ({ setCampaignField }) =>
  ({ target: { value } }) =>
    setCampaignField({
      triggerType: value,
      csvUrl: null,
    });

const setTimeRange =
  ({ setCampaignField }) =>
  value =>
    setCampaignField({
      timeRange: value,
    });

const shouldUpdate = onlyUpdateForKeys([
  'quotaPeriodInDays',
  'startDate',
  'startTime',
  'expiryDate',
  'updateExpiry',
  'updateStartDate',
  'updateStartTime',
  'min',
  'customerCountPerExecution',
  'updateTotalExecutions',
  'classes',
  'recurring',
  'updateRecurring',
  'mediumId',
  'updateExpiryTime',
  'expiryTime',
  'triggerType',
  'csvUrl',
]);

const addHandlers = withHandlers({
  handleChangeStartDateAndTime,
  handleChangeExpiryDateAndTime,
  updateRecurring,
  updateTotalExecutions,
  setTimeRange,
});

const addMinDates = withProps({
  min: moment().add(1, 'days').format('YYYY-MM-DD'),
  minExpiry: moment().add(2, 'days').format('YYYY-MM-DD'),
});

const withChannel = withPropsOnChange(['mediumId'], ({ mediumId, subChannel }) => {
  let ScheduleComponent = subChannel === '' ? channelConfig[mediumId].ScheduleComponent : subChannel.ScheduleComponent;
  if (!ScheduleComponent) {
    ScheduleComponent = FullSchedule;
  }
  return {
    ScheduleComponent,
  };
});

const enhance = compose(
  connect(null, { setCampaignField: campaignOperations.setCampaignField }),
  addMinDates,
  addHandlers,
  withStyles(styles),
  withChannel,
  shouldUpdate,
);

export default enhance(Schedule);
