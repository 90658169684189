import { ICampaignExecution } from 'app/types';
import { ICampaignExecutionDto } from './types';

export const fromExecutionDto = (src: ICampaignExecutionDto): ICampaignExecution => {
  const {
    acquired,
    campaignName: name,
    campaign_id: id,
    countPerExecution,
    delivered,
    // deliveryId,
    // delivery_error,
    delivery_state: deliveryState,
    // delivery_time,
    // fire_time,
    // medium,
    plan_type: planType,
    priority,
    priority_type: priorityType,
    // request_id,
    // task_error,
    // task_id,
    // task_stage,
    task_state: taskState,
    trigger,
  } = src;

  return {
    acquired,
    countPerExecution,
    delivered,
    deliveryState,
    id,
    name,
    planType,
    priority,
    priorityType,
    taskState,
    trigger,
  };
};
