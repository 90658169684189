import { red, green } from '@material-ui/core/colors';
import { map, merge } from 'ramda';

const statusColors = (theme, color) =>
  map(merge(color), {
    CREATED: {
      backgroundColor: green[500],
    },

    DRAFT: {
      backgroundColor: theme.palette.secondary.main,
    },

    ERROR: {
      backgroundColor: red[500],
    },

    FINISHED: {
      backgroundColor: theme.palette.primary.main,
    },

    PAUSED: {
      backgroundColor: 'grey',
    },

    STARTED: {
      backgroundColor: green.A400,
    },

    STOPPED: {
      backgroundColor: 'grey',
    },
  });

const tooltipIcon = theme => ({
  width: 20,
  height: 20,
  fontSize: 10,
  backgroundColor: theme.palette.primary.main,
});

export const styles = theme => ({
  buttons: {
    justifyContent: 'flex-start',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  disabledTooltipIcon: {
    ...tooltipIcon(theme),
    backgroundColor: theme.palette.text.disabled,
  },

  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: '100%',
  },

  headerItem: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  headerItemGroup: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  lastHeaderItem: {
    marginLeft: 'auto',
    marginBottom: theme.spacing(1),
  },

  root: {
    padding: '1rem',
  },

  searchField: {
    minWidth: '7rem',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  tooltipIcon: tooltipIcon(theme),
  ...statusColors(theme, { color: 'white' }),
});
