import { IAppConfig } from 'configs/apps/types';
import { campaignStates } from 'app/utilities/constants';
import { emailMediumId, push2MediumId } from 'configs/channels/mediumIds';
import { prettifyPredicates } from 'app/midgarComponents/PredicateBuilder/helper';
import { timeUnits, minuteInSeconds, hourInSeconds, dayInSeconds } from 'app/utilities/constants';
import { equalsArray } from 'app/helpers/RamdaHelpers/helpers';
import { ICriteria } from 'app/types';

export const isMetricsEnabled = (appConfig: IAppConfig | null | undefined, mediumId: string, campaignState: string) => {
  // only push2 and email support metrics
  // email: 1,  push2: 10
  // all campaign states except draft
  const { campaigns = {} } = appConfig || {};
  const { metrics = true } = campaigns;
  const avaliableMetrics = [push2MediumId, emailMediumId];
  const notAvaliableStates = [campaignStates.draft, campaignStates.created, undefined];

  return metrics && !notAvaliableStates.includes(campaignState) && avaliableMetrics.includes(mediumId);
};

export const printPredicateFilter = (criteria: ICriteria) => {
  if (criteria?.predicateFilter && typeof criteria.predicateFilter === 'string') {
    return criteria.predicateFilter;
  }

  if (Array.isArray(criteria?.predicateFilterObject) && criteria.predicateFilterObject.length) {
    return prettifyPredicates(criteria.predicateFilterObject);
  }

  return null;
};

export const formatDelayTime = (triggerDelayInSeconds: null | number): { value: null | number; unit: string } => {
  if (!triggerDelayInSeconds || Number.isNaN(Number(triggerDelayInSeconds))) return { value: null, unit: timeUnits.mins.value };

  if (triggerDelayInSeconds / dayInSeconds >= 1 && triggerDelayInSeconds % dayInSeconds === 0) {
    return { value: triggerDelayInSeconds / dayInSeconds, unit: timeUnits.days.value };
  }

  if (triggerDelayInSeconds / hourInSeconds >= 1 && triggerDelayInSeconds % hourInSeconds === 0) {
    return { value: triggerDelayInSeconds / hourInSeconds, unit: timeUnits.hours.value };
  }

  if (triggerDelayInSeconds / minuteInSeconds >= 1 && triggerDelayInSeconds % minuteInSeconds === 0) {
    return { value: triggerDelayInSeconds / minuteInSeconds, unit: timeUnits.mins.value };
  }

  return { value: null, unit: timeUnits.mins.value };
};

export const getDelayInSeconds = (value: number, unit: string): number => {
  if (!value || !unit) return 0;

  switch (unit) {
    case timeUnits.mins.value:
      return value * minuteInSeconds;
    case timeUnits.hours.value:
      return value * hourInSeconds;
    case timeUnits.days.value:
      return value * dayInSeconds;
    default:
      return 0;
  }
};

export const diffObjInArrayWith = (array?: Record<string, any>[], { findBy, additional }: Record<string, any>) => {
  if (!array || (Array.isArray(array) && array.length === 0)) return true;

  const found = array.find(item => item[findBy.key] === findBy.value);

  if (!found) return true;

  return (found[additional.key] || additional.value) && found[additional.key] !== additional.value;
};

export const highlightCriteria = ({
  jsonArr,
  str,
  originalJsonArr,
  originalStr,
}: {
  jsonArr: Array<Record<string, any>> | null | undefined;
  str: string | null | undefined;
  originalJsonArr: Array<Record<string, any>> | null | undefined;
  originalStr: string | null | undefined;
}) => {
  if ((jsonArr && jsonArr.length > 0) || (originalJsonArr && originalJsonArr.length > 0)) {
    return !equalsArray(jsonArr, originalJsonArr);
  }

  if (str || originalStr) {
    return str !== originalStr;
  }

  return false;
};
