import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, ListItemIcon, ListItem, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon, Done as DoneIcon } from '@material-ui/icons';
import { getItem } from 'app/utilities/idAndName';
const getName = (id, items) => {
  const item = id !== null && id !== undefined ? getItem(id, items) : undefined;
  return item ? item.name : undefined;
};

export const ButtonMenu = ({ id = 'buttonMenu', selectedId, items, menu, handleClick, handleSelect, handleClose, classes = {} }) => (
  <span className={classes.root}>
    <Button aria-owns={menu ? id : undefined} aria-haspopup="true" onClick={handleClick} className={classes.button} data-qa="button-menu">
      <ArrowDropDownIcon className={classes.arrowDropDownIcon} />
      <Typography className={classes.countryName}>{getName(selectedId, items)}</Typography>
    </Button>
    <Menu id={id} anchorEl={menu} data-qa={'menu'} open={Boolean(menu)} onClose={handleClose} className={classes.menu}>
      {items.map(item => (
        <MenuItem
          key={item.id}
          onClick={handleSelect(item.id)}
          className={classes.menuItem}
          data-qa={`menu-item-${item.name}`}
          component="div"
        >
          <ListItem>
            {item.id === selectedId && (
              <ListItemIcon>
                <DoneIcon />
              </ListItemIcon>
            )}

            <ListItemText
              style={{
                paddingLeft: item.id === selectedId ? '0px' : '56px',
              }}
              selected={item.id === selectedId}
              inset
              primary={item.name}
            />
          </ListItem>
        </MenuItem>
      ))}
    </Menu>
  </span>
);

ButtonMenu.propTypes = {
  id: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleClick: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    button: PropTypes.string,
    menu: PropTypes.string,
    menuItem: PropTypes.string,
  }),
};
