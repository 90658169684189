import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Page as _Page, PageHeader, PageMain, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IView } from 'app/types/BannerManagement';

import SelectCreativeTable from './SelectCreativeTable';

class SelectView extends PureComponent<{
  closeModal: (...args: Array<any>) => any;
  onSelect: (...args: Array<any>) => any;
  view: IView;
}> {
  render() {
    const { closeModal, onSelect, view } = this.props;
    const { widgetType } = view;

    return (
      <Page>
        <PageHeader title="Select a Creative">
          <section>
            <CloseButton onClick={closeModal}>
              <Icon name="remove" size={25} />
            </CloseButton>
          </section>
        </PageHeader>

        <PageWrapper>
          <SelectCreativeTable onSelect={onSelect} reportingState={{ widgetType }} />
        </PageWrapper>
      </Page>
    );
  }
}

const mapStateToProps = ({
  bannerManagement: {
    binder: {
      view: { view },
    },
  },
}) => ({
  view,
});

export default connect(mapStateToProps)(SelectView);

const Page = styled(_Page)`
  height: 100%;
  margin: 0;
`;

const PageWrapper = styled(PageMain)`
  height: calc(100% - 90px);
  overflow-y: scroll;
`;

const CloseButton = styled.span`
  display: block;
  padding: ${sc.gutterSmallest};
  cursor: pointer;
  transition: all 100ms linear;

  &:hover {
    color: ${sc.primary};
  }
`;
