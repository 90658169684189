import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import styled from 'styled-components';

import { withAppConfig, withPermissions } from 'app/decorators';
import { binderActions } from 'app/ducks';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { Button, Spinner, TextField } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Action } from 'app/types';
import { IView } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';
import { bannerManagementEditPermissions } from 'configs/permissions';

import WidgetType from '../../common/WidgetType';
import { validateMissingFields } from './validate';

type Props = {
  appConfig: IAppConfig;
  isNew?: boolean;
  isPermitted: IsPermittedFn;
  onSave: () => void;
  readOnly?: boolean;
  updateViewField: (arg0: Record<string, any>) => Action;
  view: IView;
};

class View extends React.PureComponent<Props> {
  handleChangeTextValue = (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = ev;
    const { updateViewField } = this.props;
    updateViewField({ [name]: value });
  };

  render() {
    const { appConfig, isNew, isPermitted, onSave, readOnly, updateViewField, view } = this.props;

    const missingFields = validateMissingFields(view);

    // Temporary hack, ensures that a blank is displayed when a new view is created instead of the previous value, left over from the last view in Redux
    // Note that when a second view is rendered, until the API call is complete, the previous widgetType will be displayed before being replaced by the correct one.
    // Introduction of a loading flag will get rid of the need for this,.
    const widgetType = isNew ? '' : (view || {}).widgetType || '';

    const loading = false;
    const { useViewCreate, useViewUpdate } = getBannerCreativeConfig(appConfig);

    const allowUpdate = !readOnly && (useViewCreate || useViewUpdate) && isPermitted(bannerManagementEditPermissions);

    return (
      <Container>
        <InputContainer>
          <TextField
            disabled={!allowUpdate}
            error={missingFields.includes('name')}
            label="Name"
            name="name"
            onChange={this.handleChangeTextValue}
            required
            value={(view || {}).name || ''}
          />

          <WidgetType
            disabled={!allowUpdate}
            error={missingFields.includes('widgetType')}
            onChangeWidgetType={value => updateViewField({ widgetType: value })}
            required
            value={widgetType}
          />
        </InputContainer>

        {allowUpdate && (
          <section>
            <Button disabled={loading || !!validateMissingFields(view).length} onClick={onSave}>
              {loading ? <Spinner /> : 'Save'}
            </Button>
          </section>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({
  bannerManagement: {
    binder: {
      view: { view },
    },
  },
}) => ({
  view,
});

const mapDispatchToProps = {
  updateViewField: binderActions.updateViewField,
};

export default compose(withAppConfig, withPermissions, connect(mapStateToProps, mapDispatchToProps))(View);

const Container = styled.section`
  display: flex;
  & > div,
  & > section {
    margin-right: ${sc.gutterLargest};
  }
  & > div:last-child,
  & > section:last-child {
    margin-right: 0;
  }
`;

const InputContainer = styled.section`
  flex-grow: 1;
  & > div,
  & > section {
    margin-bottom: ${sc.gutter};
  }
`;
