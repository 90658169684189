import * as React from 'react';
import styled from 'styled-components';

import { fulfillmentTypes } from 'app/features/Cashback/constants';
import { IFulfillment } from 'app/features/Cashback/Creation/Fulfillment/types';

import Entry from './Entry';

const NO_VAL = 'N/A';

const asOrdinal = (n: number): string | null | undefined => {
  if (Number.isNaN(n)) {
    return null;
  }

  if (n === 1 || n === 21 || n === 31) {
    return `${n}st`;
  }

  if (n === 2 || n === 22) {
    return `${n}nd`;
  }

  if (n === 3 || n === 23) {
    return `${n}rd`;
  }

  return `${n}th`;
};

const dayOfMonthAsString = (dayOfMonth: (number | string) | null | undefined): string => {
  if (dayOfMonth === null || dayOfMonth === undefined) {
    return NO_VAL;
  }

  if (typeof dayOfMonth === 'string') {
    return asOrdinal(Number(dayOfMonth)) || NO_VAL;
  }

  return asOrdinal(dayOfMonth) || NO_VAL;
};

const offsetAsString = (offset: (number | string) | null | undefined) =>
  offset ? (typeof offset === 'number' ? (offset && offset > 0 ? String(offset) : NO_VAL) : offset || NO_VAL) : NO_VAL;

type Props = {
  fulfillment: IFulfillment;
  originalFulfillment?: Record<string, any>;
};

export default ({ fulfillment, originalFulfillment }: Props) => {
  const title = 'Will be paid out';

  switch (fulfillment.type) {
    case fulfillmentTypes.dayOfMonth:
      return (
        <section>
          <Entry
            title={title}
            value={`${offsetAsString(fulfillment.numMonths)} month(s) after the transaction, on the ${dayOfMonthAsString(
              fulfillment.dayOfMonth,
            )}`}
            highlight={
              originalFulfillment &&
              (((fulfillment.type || originalFulfillment?.type) && fulfillment.type !== originalFulfillment?.type) ||
                ((fulfillment.numMonths || originalFulfillment?.numMonths) && fulfillment.numMonths !== originalFulfillment?.numMonths) ||
                ((fulfillment.dayOfMonth || originalFulfillment?.dayOfMonth) && fulfillment.dayOfMonth !== originalFulfillment?.dayOfMonth))
            }
          />
        </section>
      );

    case fulfillmentTypes.instant:
      return (
        <Entry
          title={title}
          value="immediately"
          highlight={
            originalFulfillment && (fulfillment.type || originalFulfillment?.type) && fulfillment.type !== originalFulfillment?.type
          }
        />
      );

    case fulfillmentTypes.relative:
      return (
        <section>
          <Entry
            title={title}
            value={`${offsetAsString(fulfillment.offsetDays)} days after the transaction`}
            highlight={
              originalFulfillment &&
              (((fulfillment.type || originalFulfillment?.type) && fulfillment.type !== originalFulfillment?.type) ||
                ((fulfillment.offsetDays || originalFulfillment?.offsetDays) && fulfillment.offsetDays !== originalFulfillment?.offsetDays))
            }
          />
        </section>
      );

    default:
      return (
        <section>
          <Pre>{JSON.stringify(fulfillment, null, 2)}</Pre>
        </section>
      );
  }
};

const Pre = styled.pre`
  white-space: pre-wrap;
  font-family: monospace;
`;
