export const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
  },

  name: {
    width: '79px',
    height: '16px',
    fontSize: '12px',
    color: '#29394f',
    marginLeft: '8px',
  },
});
