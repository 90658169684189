import { compose, setPropTypes, withHandlers } from 'recompose';
import PropTypes from 'prop-types';

import { ListSelectableGroup } from './ListSelectableGroup';

const addHandlers = withHandlers({
  handleListClick:
    ({ handleClick }) =>
    listId =>
    itemId => {
      if (handleClick) {
        handleClick(listId, itemId);
      }
    },
});

const propTypes = setPropTypes({
  handleClick: PropTypes.func,
});

const enhance = compose(addHandlers, propTypes);

export default enhance(ListSelectableGroup);
