export const styles = theme => ({
  page: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },

  header: {
    height: '4.5rem',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '1rem 2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.background.default}`,
    position: 'relative',
  },

  body: {
    height: 'calc(100% - 4.5rem)',
    position: 'relative',
    overflowY: 'auto',
  },
});
