import { withProps, withHandlers, compose } from 'recompose';

import { TablePaginationActions } from './TablePaginationActions';

const addProps = withProps(({ count, rowsPerPage }) => ({
  lastPageIndex: Math.max(0, Math.ceil(count / rowsPerPage) - 1),
  // changePageTo: targetPage => event => onChangePage(event, targetPage),
}));

const addHandlers = withHandlers({
  handleFirstPageButtonClick:
    ({ onChangePage }) =>
    e =>
      onChangePage(e, 0),
  handleBackButtonClick:
    ({ onChangePage, page }) =>
    e =>
      onChangePage(e, page - 1),
  handleNextButtonClick:
    ({ onChangePage, page }) =>
    e =>
      onChangePage(e, page + 1),
  handleLastPageButtonClick:
    ({ onChangePage, lastPageIndex }) =>
    e =>
      onChangePage(e, lastPageIndex),
});

const enhance = compose(addProps, addHandlers);

export default enhance(TablePaginationActions);
