import update from 'immutability-helper';

import * as campaignTypes from 'app/ducks/campaigns/campaign/types';
import * as types from './types';

import bannerSelectionReducer from './bannerSelection';
import sourceSelectionReducer from './sourceSelection';
import { State } from './state';
import { initialState } from './state';
import { SET_ERROR } from './types';
import { validate } from './validation';

export default (state: State = initialState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case campaignTypes.NEW_CAMPAIGN:
      return { ...initialState };

    case campaignTypes.SET_BANNER_CREATIVE: {
      if (payload.preserveRawCreative) {
        return state;
      }

      return validate(
        update(initialState, {
          banners: { $set: payload.creative.banners },
          bannerRealTimeUserCriteria: { $set: payload.creative.bannerRealTimeUserCriteria },
        }),
      );
    }

    case types.UPDATE_BANNER_USER_CRITERIA: {
      const { userCriteria } = payload;
      return {
        ...state,
        bannerRealTimeUserCriteria: {
          ...state.bannerRealTimeUserCriteria,
          userCriteria,
        },
      };
    }

    case types.UPDATE_BANNER_USER_CRITERIA_JSON: {
      const { userCriteriaJson } = payload;
      return {
        ...state,
        bannerRealTimeUserCriteria: {
          ...state.bannerRealTimeUserCriteria,
          userCriteriaJson,
        },
      };
    }

    case SET_ERROR:
      return update(state, {
        error: { $set: payload.error },
      });

    default: {
      let nextState = bannerSelectionReducer(state, { type, payload });
      nextState = sourceSelectionReducer(nextState, { type, payload });
      return nextState;
    }
  }
};
