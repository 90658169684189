import * as React from 'react';

import EditBannerIdsModal from 'app/features/Banner/CampaignEdit/EditBannerIdsModal';
import { ICampaign } from 'app/types';
import { IBannerCampaignSpec } from 'app/types/Creatives/BannerCreative';
import { IScheduling } from 'app/types/Scheduling';

import { PartialScheduleUpdateModal } from '../Schedule/PartialScheduleUpdateModal';

const modals = {
  bannerId: 'bannerId',
  campaignExpiry: 'campaignExpiry',
};

type Props = {
  bannerUpdate?: (campaignId: number, arg1: Array<IBannerCampaignSpec>) => void;
  campaign: ICampaign;
  closeModal: () => void;
  modal: string;
  schedulingUpdate?: (arg0: number, arg1: IScheduling) => void;
  setCampaignField?: (arg0: Record<string, any>) => void;
  user: { timezone: string };
};

export const CampaignEdit = ({ campaign, user, modal, closeModal, bannerUpdate, setCampaignField, schedulingUpdate }: Props) => (
  <>
    <EditBannerIdsModal
      bannerUpdate={bannerUpdate}
      campaign={campaign}
      closeModal={closeModal}
      open={modal === modals.bannerId}
      setCampaignField={setCampaignField}
    />

    <PartialScheduleUpdateModal
      open={modal === 'campaignExpiry'}
      campaign={campaign}
      user={user}
      closeModal={closeModal}
      setCampaignField={setCampaignField}
      schedulingUpdate={schedulingUpdate}
    />
  </>
);
