import React, { PureComponent } from 'react';
import { Autocomplete } from 'app/midgarComponents';
import { ISearchDef } from 'app/hocs/withReporting/withSearch/types';
import { ISuggestion, IQueryParams } from 'app/types';

type Props = {
  suggestions: Array<ISuggestion>;
  pushSearch: (arg0: IQueryParams) => void;
  search: IQueryParams;
};

export default (searchDef: ISearchDef) => {
  class Wrapper extends PureComponent<Props> {
    onSelect = (suggestion: string | ISuggestion) => {
      const { pushSearch, search } = this.props;
      pushSearch({ ...search, [searchDef.name]: typeof suggestion === 'string' ? suggestion : suggestion.id });
    };

    onChange = (ev: React.SyntheticEvent<HTMLInputElement>) => {
      const { pushSearch, search } = this.props;
      if (!ev.target.value) {
        const newSearch = { ...search };
        delete newSearch[searchDef.name];
        pushSearch({ ...newSearch });
      }
    };

    render() {
      const { suggestions, search } = this.props;
      const suggestion =
        suggestions &&
        !!suggestions.length &&
        suggestions.find(x => (typeof x === 'string' ? x === search[searchDef.name] : x.id === search[searchDef.name]));

      return (
        <Autocomplete
          id={searchDef.name}
          name={searchDef.name}
          label={searchDef.label || ''}
          value={typeof suggestion === 'string' ? suggestion : suggestion ? suggestion.name : ''}
          suggestions={suggestions}
          onSelect={this.onSelect}
          onChange={this.onChange}
          type="search"
        />
      );
    }
  }

  return Wrapper;
};
