import { fTrue, R } from '../RamdaHelpers/helpers';
// import { echoLog } from '../DebugHelpers/helpers';

const newCache = () => ({
  dict: {},
  sequence: [],
});

const lru =
  ({ maxNumOfItems = 10, testPass = fTrue }) =>
  (key, fetch, { dict, sequence }) => {
    if (R.has(key, dict)) {
      // echoLog(key, 'cache got a hit ==>');
      return [
        R.prop(key, dict),
        {
          dict,
          sequence: R.startsWith(key, sequence) ? sequence : [key, ...R.without([key], sequence)],
        },
      ];
    }

    const keys = R.take(maxNumOfItems, [key, ...sequence]);
    const value = fetch(key);

    // echoLog(value, value.length, 'cache missed, got ==>', testPass(value));

    return [
      value,
      testPass(value)
        ? {
            dict: R.mergeAll([R.pick(keys, dict), { [key]: value }]),
            sequence: keys,
          }
        : {
            dict,
            sequence,
          },
    ];
  };

export { newCache, lru };
