import * as React from 'react';
import styled from 'styled-components';

import { sc } from 'app/styles';

import { IBulkActionError } from './types';

type Props = {
  bulkActionError: IBulkActionError;
  className?: string;
};

export default ({ bulkActionError, className }: Props) => {
  if (!bulkActionError) return null;

  const { msg } = bulkActionError.error || {};
  return <Container className={className}>{msg ? `Error: ${msg}` : 'Unknown error occurred'}</Container>;
};

const Container = styled.div`
  color: ${sc.danger};
  font-family: monospace;
`;
