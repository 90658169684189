import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { connect } from 'react-redux';

import { storefrontOperations } from 'app/ducks';
import { ConfirmModal, Icon as _Icon } from 'app/midgarComponents';

import EnableEngage from './EnableEngage';

type Props = {
  deleteView: (arg0: number) => (...args: Array<any>) => any;
  deletingViewId?: number | null | undefined;
  entityId: number;
  managedByEngage: boolean;
  readOnly: boolean;
  storefrontId: number;
  supportedByEngage: boolean;
  viewId: number;
};

type State = {
  isModalOpen: boolean;
};

class EngageStatus extends PureComponent<Props, State> {
  state = {
    isModalOpen: false,
  };

  componentDidUpdate(prevProps: Props) {
    const { deletingViewId } = this.props;

    if (prevProps.deletingViewId !== deletingViewId) {
      if (!deletingViewId) {
        this.closeModal();
      }
    }
  }

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  onConfirm = (ev: Event) => {
    const { deleteView, viewId } = this.props;

    ev.preventDefault();
    ev.stopPropagation();
    deleteView(viewId);
  };

  onClose = (ev: Event) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.closeModal();
  };

  onClick = (ev: Event) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.openModal();
  };

  render() {
    const { deletingViewId, entityId, managedByEngage, readOnly, storefrontId, supportedByEngage, viewId } = this.props;
    const { isModalOpen } = this.state;

    const isInProgress = !!deletingViewId;

    return managedByEngage ? (
      <Container>
        <Icon name="checkmark" />

        {!readOnly && <Icon name="delete" onClick={this.onClick} />}

        <ConfirmModal
          title="Delete Confirmation"
          isOpen={isModalOpen}
          onClose={this.onClose}
          confirmText="Delete this view?"
          onConfirm={this.onConfirm}
          disabled={isInProgress}
        >
          {isInProgress ? 'Deleting...' : `Are you sure you would like to delete view ${viewId}?`}
        </ConfirmModal>
      </Container>
    ) : (
      !readOnly && supportedByEngage && <EnableEngage entityId={entityId} storefrontId={storefrontId} viewId={viewId} />
    );
  }
}

const mapStateToProps = ({
  bannerManagement: {
    storefronts: { deletingViewId },
  },
}) => ({
  deletingViewId,
});

const mapDispatchToProps = {
  deleteView: storefrontOperations.deleteView,
};

export default connect(mapStateToProps, mapDispatchToProps)(EngageStatus);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Icon = styled(_Icon)`
  color: ${props => (props.onClick ? sc.subHeadingColor : sc.success)};

  &:hover {
    color: ${props => props.onClick && sc.primary};
  }
`;
