import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'ramda';

import { withAppConfig } from 'app/decorators';
import { creativeActions } from 'app/ducks';
import { ImageUpload as _ImageUpload } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';
import { uploadImage } from 'app/api/bannerManagementV2/images';

type Props = {
  alternateImageUrl: string;
  appConfig: IAppConfig;
  imageUrl: string;
  shouldValidate: boolean;
  updateVariantsField: (index: number, field: string, value: any) => (...args: Array<any>) => any;
  variantFieldHasError: (variantKey: string, fieldName: string) => boolean;
  variantKey: string;
  widgetType: string;
};

class ImagePreview extends React.PureComponent<Props> {
  useAlternateImage = () => {
    const { appConfig, widgetType } = this.props;
    const { useAlternateImage: altImageOption } = getBannerCreativeConfig(appConfig);
    const { widgetTypes } = altImageOption;

    return Array.isArray(widgetTypes) ? widgetTypes.includes(widgetType) : !!altImageOption;
  };

  onUploadImage = async (variantKey, url, file, updateVariantsField) => {
    if (file) {
      try {
        const { imageUrl } = await uploadImage(file);
        updateVariantsField(variantKey, url, imageUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  render() {
    const { alternateImageUrl, imageUrl, shouldValidate, updateVariantsField, variantFieldHasError, variantKey } = this.props;

    const nVariantKey = Number(variantKey);
    return (
      <Container>
        <ImageUpload
          error={shouldValidate && variantFieldHasError(variantKey, 'imageUrl')}
          id="banner-image-upload"
          imageUrl={
            imageUrl
              ? imageUrl.replace('https://s3.ap-northeast-1.amazonaws.com/pai-gm-resources-stg', 'https://pai-gm-stg.paypay-corp.co.jp')
              : imageUrl
          }
          onUpload={(url: string, file: File) => {
            this.onUploadImage(nVariantKey, 'imageUrl', file, updateVariantsField);
          }}
          onView={() => null}
          placeholder="Upload Banner Image"
        />

        {this.useAlternateImage() && (
          <ImageUpload
            error={shouldValidate && variantFieldHasError(variantKey, 'alternateImageUrl')}
            id="banner-altImage-upload"
            imageUrl={
              alternateImageUrl
                ? alternateImageUrl.replace(
                    'https://s3.ap-northeast-1.amazonaws.com/pai-gm-resources-stg',
                    'https://pai-gm-stg.paypay-corp.co.jp',
                  )
                : alternateImageUrl
            }
            onUpload={(url: string, file: File) => {
              this.onUploadImage(nVariantKey, 'alternateImageUrl', file, updateVariantsField);
            }}
            onView={() => null}
            placeholder="Upload Alternate Image"
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (
  {
    bannerManagement: {
      creative: {
        form: { variants },
      },
    },
  },

  { variantKey },
) => ({
  alternateImageUrl: variants[variantKey].alternateImageUrl,
  imageUrl: variants[variantKey].imageUrl,
  widgetType: variants[variantKey].widgetType,
});

const mapDispatchToProps = {
  updateVariantsField: creativeActions.updateVariantsField,
};

export default compose(withAppConfig, connect(mapStateToProps, mapDispatchToProps))(ImagePreview);

const Container = styled.div`
  width: 100%;
`;

const ImageUpload = styled(_ImageUpload)`
  width: 100%;
  margin: 0 0 ${sc.gutter};
`;
