import { fromApiWidgetType } from 'app/api/bannerManagementV2/views/mappers';
import { IView, IViewItem, IWidgetType } from 'app/types/BannerManagement';
import api from 'app/utilities/api';

import {
  DELETE_VIEW,
  DELETE_VIEW_ITEMS,
  POST_ENABLE_ENGAGE_FOR_VIEW,
  GET_STOREFRONT_PROXY_VIEW,
  GET_VIEW,
  GET_VIEWS,
  GET_VIEW_ITEM_BY_BANNER_ID,
  GET_VIEW_ITEM_BY_CREATIVE_ID,
  GET_WIDGET_TYPES,
  POST_VIEW,
  PUT_SLOT_ORDER,
  PUT_VIEW,
} from './endpoints';

import { IFetchViewResult } from './types';

// TODO: entityType and entityId are needed for now, but will be removed
export const createView = (storefrontId: number, view: IView): Promise<{ msg: string }> =>
  api.post(POST_VIEW, { body: { ...view, decoratorId: storefrontId, entityType: 'DUMMY', entityId: 0 } });

export const deleteView = (viewId: number): Promise<{ msg: string }> => api.delete(DELETE_VIEW(viewId));

export const deleteViewItems = (viewId: number, bannerIds: Array<number>): Promise<any> =>
  api.delete(DELETE_VIEW_ITEMS(viewId), { body: { bannerIds } });

export const enableEngageForView = (entityType: string, entityId: number, decoratorId: number, viewId: number): Promise<{ msg: string }> =>
  api.post(POST_ENABLE_ENGAGE_FOR_VIEW(entityType, entityId, decoratorId, viewId));

export const fetchStorefrontProxyView = (entityType: string, entityId: number, decoratorId: number, viewId: number) =>
  api.get(GET_STOREFRONT_PROXY_VIEW(entityType, entityId, decoratorId, viewId));

export const fetchView = (viewId: number): Promise<IFetchViewResult> => api.get(GET_VIEW(viewId));

export const fetchViewItemByBannerId = (
  viewId: number,
  bannerId: number,
): Promise<{ viewItems: Array<IViewItem>; versions: Array<{ type: string; version: number }> }> =>
  api.get(GET_VIEW_ITEM_BY_BANNER_ID(viewId, bannerId));

export const fetchViewItemByCreativeId = (viewId: number, creativeId: number) => api.get(GET_VIEW_ITEM_BY_CREATIVE_ID(viewId, creativeId));

export const fetchViews = (
  storefrontId: number,
  entityType?: string,
  entityId?: number,
  query?: Record<string, string>,
): Promise<Array<IView>> =>
  api.get(GET_VIEWS, {
    query: {
      ...(query || {}),
      // TODO: API should rename the parameter to be storefrontId instead of decoratorId
      decoratorId: storefrontId,
      ...(entityType ? { entityType } : {}),
      ...(entityId ? { entityId } : {}),
    },
  });

export const fetchWidgetTypes = (): Promise<Array<IWidgetType>> =>
  api.get(GET_WIDGET_TYPES).then(({ viewTypes }) => viewTypes.map(fromApiWidgetType));

export const updateViewPersonalization = (
  entityType: string,
  entityId: number,
  decoratorId: number,
  viewId: number,
  isPersonalized: boolean,
): Promise<void> => api.put(GET_STOREFRONT_PROXY_VIEW(entityType, entityId, decoratorId, viewId), { body: { isPersonalized } });

export const updateSlotOrder = (viewId: number, slots: Array<{ id: number; priority: number }>) =>
  api.put(PUT_SLOT_ORDER(viewId), { body: slots });

export const updateView = (viewId: number, view: Partial<IView>): Promise<{ msg: string }> => api.put(PUT_VIEW(viewId), { body: view });
