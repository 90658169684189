import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import { setCampaignField } from 'app/ducks/campaigns/campaign/actions';
import { triggerTypes } from 'app/utilities/constants';
import { DateTimeUtcField } from 'app/midgarComponents';
import { disablePastDates } from 'app/utilities/disablePastDates';

export const StartEndDateTime = () => {
  const dispatch = useDispatch();
  const { expiryDate, expiryTime, startDate, startTime, triggerType } = useSelector(state => state.campaigns.campaign.general);

  const timeStampStartTime = React.useMemo(() => {
    return startDate && startTime ? `${startDate}T${startTime}:00Z` : '';
  }, [startDate, startTime]);

  const timeStampExpiryTime = React.useMemo(() => {
    return expiryDate && expiryTime ? `${expiryDate}T${expiryTime}:00Z` : '';
  }, [expiryDate, expiryTime]);

  return (
    <>
      <Section data-qa="schedule-time-component">
        <Typography style={{ marginRight: '1rem' }}>Starts On (JST)</Typography>

        <DateTimeUtcField
          id="startDate"
          label="Start Date"
          name="startDate"
          onChange={value => {
            let newStartDate = '';
            let newStartTime = '';
            if (value?.date && value?.time) {
              newStartDate = value.date;
              newStartTime = value.time;
            }

            dispatch(
              setCampaignField({
                startDate: newStartDate,
              }),
            );

            dispatch(
              setCampaignField({
                startTime: newStartTime,
              }),
            );
          }}
          required
          keepDefaultFormat
          splitOnChangeDate
          value={timeStampStartTime}
          disabledDate={disablePastDates}
        />
      </Section>

      {triggerType !== triggerTypes.oneTime && (
        <>
          <Section data-qa="end-date-component">
            <Typography style={{ marginRight: '1rem' }}>Ends On (JST)</Typography>

            <DateTimeUtcField
              id="expiryDate"
              label="Expiry Date"
              name="expiryDate"
              onChange={value => {
                let newExpiryDate = '';
                let newExpiryTime = '';
                if (value?.date && value?.time) {
                  newExpiryDate = value.date;
                  newExpiryTime = value.time;
                }

                dispatch(
                  setCampaignField({
                    expiryDate: newExpiryDate,
                  }),
                );

                dispatch(
                  setCampaignField({
                    expiryTime: newExpiryTime,
                  }),
                );
              }}
              required
              keepDefaultFormat
              splitOnChangeDate
              value={timeStampExpiryTime}
              disabledDate={disablePastDates}
            />
          </Section>
        </>
      )}
    </>
  );
};

const Section = styled.section`
  display: flex;
  min-height: 48px;
  align-items: center;
  margin-bottom: 1rem;
`;
