import { ComponentType } from 'react';
import { DragDropContext } from 'react-dnd/lib/esm';
import HTML5Backend from 'react-dnd-html5-backend/lib/esm';

/**
 * HTML5Backend decorator for dnd.
 *
 * Required when you're trying to add multiple
 * drag contexts on a single view.
 */
export default (DecoratedComponent: ComponentType<any>) => DragDropContext(HTML5Backend)(DecoratedComponent);
