import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Icon as _Icon, TextField } from 'app/midgarComponents';
import { sc } from 'app/styles';

export default class KeyValueGroup extends PureComponent<{
  isLast: boolean;
  keyName: string;
  keyNameError?: boolean;
  keyValue: string;
  readOnly: boolean;
  onAdd: () => void;
  onDelete: () => void;
  onChange: (arg0: React.SyntheticEvent<HTMLInputElement>) => void;
}> {
  render() {
    const { isLast, keyName, keyNameError, keyValue, readOnly, onAdd, onDelete, onChange } = this.props;

    return (
      <Container>
        <InlineField>
          <TextField
            type="text"
            id="keyName"
            name="keyName"
            label="Key"
            value={keyName || ''}
            onChange={onChange}
            disabled={readOnly}
            error={keyNameError}
          />
        </InlineField>

        <InlineField>
          <TextField
            type="text"
            id="keyValue"
            name="keyValue"
            label="Value"
            value={keyValue || ''}
            onChange={onChange}
            disabled={readOnly}
          />
        </InlineField>

        {isLast ? (
          <Icon size={25} name="add" onClick={() => !readOnly && onAdd()} disabled={readOnly} />
        ) : (
          <Icon size={20} name="remove" onClick={() => !readOnly && onDelete()} disabled={readOnly} />
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin-bottom: ${sc.gutter};

  &:last-child {
    margin-bottom: 0;
  }
`;

const InlineField = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 45%;
  margin-right: 2%;

  &:last-child {
    margin-right: 0;
  }
`;

// Row Icons are copied from CategoryGroup and AppVerionGroup
const Icon = styled(_Icon)`
  position: absolute;
  top: 50%;
  right: 0;

  color: ${sc.subHeadingColor};
  padding: ${sc.gutterSmallest};
  transform: translate(0, -50%);

  &:hover {
    color: ${props => !props.disabled && sc.primary};
    cursor: pointer;
  }
`;
