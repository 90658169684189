import { green } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  customContainer: {
    width: '100%',
    padding: '1rem',
  },

  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },

  countContainer: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
  },

  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  tooltip: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
  },
});

export { styles };
