import { IStorefront, IView } from 'app/types/BannerManagement';

import * as types from './types';

// === STOREFRONTS ===

export const getStorefrontFailed = (error: string) => ({
  type: types.GET_STOREFRONT_FAILED,
  payload: { error },
});

export const getStorefrontStart = () => ({
  type: types.GET_STOREFRONT_START,
});

export const getStorefrontSuccess = (storefront: IStorefront) => ({
  type: types.GET_STOREFRONT_SUCCESS,
  payload: { storefront },
});

export const resetStorefront = () => ({
  type: types.RESET_STOREFRONT,
});

export const saveStorefrontFailed = (error: string) => ({
  type: types.SAVE_STOREFRONT_FAILED,
  payload: { error },
});

export const saveStorefrontStart = () => ({
  type: types.SAVE_STOREFRONT_START,
});

export const saveStorefrontSuccess = () => ({
  type: types.SAVE_STOREFRONT_SUCCESS,
});

export const setStorefrontField = (field: Record<string, string>) => ({
  type: types.SET_STOREFRONT_FIELD,
  payload: { field },
});

export const setStorefrontType = (storefrontType: string) => ({
  type: types.SET_STOREFRONT_TYPE,
  payload: { storefrontType },
});

// === VIEWS ===

export const deleteViewFailed = (error: string) => ({
  type: types.DELETE_VIEW_FAILED,
  payload: { error },
});

export const deleteViewStart = (viewId: number) => ({
  type: types.DELETE_VIEW_START,
  payload: { viewId },
});

export const deleteViewSuccess = (viewId: number) => ({
  type: types.DELETE_VIEW_SUCCESS,
  payload: { viewId },
});

export const getViewsFailed = (error: string) => ({
  type: types.GET_VIEWS_FAILED,
  payload: { error },
});

export const getViewsStart = () => ({
  type: types.GET_VIEWS_START,
});

export const getViewsSuccess = (views: Array<IView>) => ({
  type: types.GET_VIEWS_SUCCESS,
  payload: { views },
});

export const enableEngageFailed = (viewId: number) => ({
  type: types.ENABLE_ENGAGE_FAILED,
  payload: { viewId },
});

export const enableEngageStart = (viewId: number) => ({
  type: types.ENABLE_ENGAGE_START,
  payload: { viewId },
});

export const enableEngageSuccess = (viewId: number) => ({
  type: types.ENABLE_ENGAGE_SUCCESS,
  payload: { viewId },
});
