import * as React from 'react';
import { connect } from 'react-redux';

import { inject } from 'app/decorators';
import { getSiteIds } from 'app/api/campaigns/siteIds';
import { ButtonGroup } from 'app/midgarComponents';
import { sc } from 'app/styles';
import * as push2CreativeActions from 'app/ducks/campaigns/campaign/push2Creative/actions';
import { IAppConfig } from 'configs/apps/types';

import { Label, Section, ValidationIcon, Flex } from '../utilComponents';
import { pushTypes as allPushTypes, PUSH2_SITE_ID_PARAM_MEDIUM_NAME } from '../Push2Constants';
import { compose } from 'recompose';

type ISite = Partial<{
  id: number;
  name: string;
  medium: string;
  deeplinkType: string;
  inboxEnabled: boolean;
}>;

type ChatCreative = {};

type Props = {
  siteId: number;
  creative: any;
  type: string;
  expiry?: string;
  push2CreativeActions: Record<string, any>;
  notificationCreative: Record<string, any>;
  sites: Array<ISite>;
  pushToChat?: boolean;
  chatCreative?: ChatCreative;
  currentTenant: string;
  config: IAppConfig;
};

type State = {
  error: string;
  loading: boolean;
  selectedSite: ISite;
};

const getSupportedPushTypes = (creativeConfig: { pushTypes: any }, selectedSite: { inboxEnabled?: any }) => {
  const supportedTypes = creativeConfig && Array.isArray(creativeConfig.pushTypes) ? creativeConfig.pushTypes : Object.values(allPushTypes);

  return supportedTypes.filter((type: any) => type.id !== allPushTypes.inbox.id || selectedSite.inboxEnabled);
};

class SitesAndTypes extends React.PureComponent<Props, State> {
  state = {
    error: '',
    loading: true,
    selectedSite: {},
  };

  componentDidMount = async () => {
    const {
      creative,
      siteId,
      sites: push2Sites,
      push2CreativeActions: { updatePush2Field },
    } = this.props;
    const showSites = creative && !!creative.useSenderDetails;

    try {
      let sites = push2Sites;

      if (!Array.isArray(sites) || !sites.length) {
        sites = await getSiteIds(PUSH2_SITE_ID_PARAM_MEDIUM_NAME);
      }

      if (!Array.isArray(sites) || sites.length === 0) {
        return this.setState({
          error: 'No sites have been configured',
          loading: false,
        });
      }

      let selectedSite = sites.find(({ id }) => +siteId === +id) || {};

      if (!showSites || sites.length === 1) {
        selectedSite = sites[0] || {};
      }

      updatePush2Field({
        site_id: selectedSite.id || '',
        deeplinkType: selectedSite.deeplinkType || '',
        sites,
      });

      this.setState({
        selectedSite,
        loading: false,
        error: '',
      });
    } catch (err) {
      this.setState({
        error: err.message || 'Failed to fetch site ids. Please refresh and try again.',
        loading: false,
      });
    }
  };

  handlePushTypeSelect = (type: unknown) => {
    const {
      push2CreativeActions: { updatePush2Type },
    } = this.props;
    updatePush2Type(type);
  };

  render() {
    const { type, creative } = this.props;
    const { selectedSite } = this.state;
    const supportedPushTypes = getSupportedPushTypes(creative, selectedSite);

    return (
      <>
        <Section data-qa="push-type-selection">
          <Label>
            Push Type <ValidationIcon name="check-circle" size={22} color={sc.success} />
          </Label>
          <Flex>
            <ButtonGroup buttons={supportedPushTypes} onClick={this.handlePushTypeSelect} active={type} />
          </Flex>
        </Section>
      </>
    );
  }
}

export default compose(
  connect(
    ({
      user: { timezone, currentTenant },
      campaigns: {
        campaign: {
          push2Creative: { type, site_id: siteId, notificationCreative, pushToChat, chatCreative, sites },
        },
      },

      config,
    }) => ({
      config,
      type,
      siteId,
      pushToChat,
      chatCreative,
      sites,
      notificationCreative,
      timezone,
      currentTenant,
    }),
  ),

  inject({ push2CreativeActions }),
)(SitesAndTypes);
