import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { ICriteria } from 'app/features/Cashback/types';
import { Button, PredicateBuilder, Spinner, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { isValid } from 'app/midgarComponents/PredicateBuilder/helper';
import { isNull } from 'ramda-adjunct';

type Props = {
  criteria: ICriteria;
  customerEvent: any;
  getCustomerEvent: () => any;
  loadingCustomerEvent: boolean;
  updateCashbackCriteria: (arg0: Record<string, any>) => any;
};

export class EnrichFieldsCriteria extends PureComponent<Props> {
  componentDidMount() {
    const { getCustomerEvent } = this.props;
    getCustomerEvent();
  }

  getPromoEventOptions = () => {
    const { customerEvent } = this.props;
    return customerEvent.map(ev => ({
      ...ev,
      ...{ label: ev.name },
    }));
  };

  handleDelete = () => this.updateRules([]);

  updateRules = rules => {
    const { updateCashbackCriteria } = this.props;
    updateCashbackCriteria({
      userCriteriaJson: rules,
      userCriteria: null,
    });
  };

  render() {
    const { loadingCustomerEvent, customerEvent, criteria } = this.props;
    const userCriteriaJson = criteria.userCriteriaJson || [];
    const userCriteria = criteria.userCriteria || null;
    const promoEventFields = !isNull(customerEvent)
      ? (customerEvent.promoEventFields || []).filter(v => v.name !== 'mynapoint_application_status')
      : [];

    return (
      <Section data-qa="enrich-fields-criteria">
        <Label>
          Add real time event criterias
          {isValid(userCriteriaJson) ? (
            <ValidationIcon name="check-circle" size={22} color={sc.success} />
          ) : (
            <ValidationIcon name="warning" size={22} color={sc.warning} />
          )}
        </Label>
        {loadingCustomerEvent ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (userCriteriaJson && userCriteriaJson.length > 0) || !userCriteria ? (
          <PredicateBuilder
            sourceEvent={customerEvent}
            sourceFields={promoEventFields}
            rules={userCriteriaJson}
            updateRules={this.updateRules}
            eventLabel="Select promo event field"
          />
        ) : (
          <>
            <CriteriaContainer>{userCriteria}</CriteriaContainer>

            <Button onClick={this.handleDelete} type="secondary">
              Start Over
            </Button>
          </>
        )}
      </Section>
    );
  }
}

export default EnrichFieldsCriteria;
const CriteriaContainer = styled.section`
  margin-bottom: ${sc.gutter};
  word-break: break-all;
`;

const Label = styled.div`
  text-transform: uppercase;
  color: ${sc.grey};
  margin: 0 1rem 1rem 0;
  text-align: left;
  font-size: ${sc.fontSize};
`;

const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem 1rem 3rem 1rem;
  margin: 1rem;
  padding: 1rem;
`;

const SpinnerWrapper = styled.div`
  width: 250px;
  margin: 1rem 1rem 0 0;
  height: 70px;
  padding; 2rem;
`;

const ValidationIcon = styled(Icon)`
  margin-top: -4px;
  margin-left: 0.5rem;
  font-weight: 900;
`;
