import { UPDATECONFIGSTORE } from './types';

const initialState = {
  channels: [],
  supportedPermissions: [],
  supportedRoutes: [
    '/campaigns',
    '/campaigns/:type(new|edit)/:id?',
    'campaigns/new',
    '/users',
    '/users/copy/:email',
    '/users/edit/:email',
    '/users/new',
    '/features',
  ],

  supportedAudienceTypes: [],
  analyticsUrl: '',
};

const config = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATECONFIGSTORE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default config;
