/* eslint-disable camelcase, prettier/prettier */
import { IWidgetType } from 'app/types/BannerManagement';

import { IApiWidgetType } from './types';

export const fromApiWidgetType = (widgetType: IApiWidgetType): IWidgetType => {
  const { id, label, name, platform, platform_version, site } = widgetType;

  return {
    id,
    label,
    name,
    platform: platform || [],
    platform_version: platform_version || [],
    site: site || [],
  };
};
