import * as React from 'react';

import { DrawerError } from 'app/components';
import { ConnectedUser } from 'app/ducks';
import { IPaging } from 'app/hocs/withReporting';
import { searchBarAdaptor } from 'app/hocs/withReporting/withCreatedBySwitch';
import { Dropdown, Page, PageHeader, PageMain, Paging, SearchBar } from 'app/midgarComponents';
import { CreatedBySwitch as _CreatedBySwitch } from 'app/midgarComponents/OptionsSwitch/CreatedBySwitch';
import { CreateNewButton, Footer, SearchContainer, Switches } from 'app/midgarComponents/ReportingPage';
import { IQueryParams, ISegment } from 'app/types';
import { audienceTypes, audienceTypeDisplay } from 'app/utilities/constants';
import { IAppConfig } from 'configs/apps/types';
import { segmentUploadPermissions, segmentCreationPermissions } from 'configs/permissions';

import SegmentTable from './SegmentTable';
import { searchBarDefs, searchTypeAudienceType, searchTypeCreatedBy, searchTypeName } from './search.config';

const CreatedBySwitch = searchBarAdaptor(searchTypeCreatedBy, searchTypeName)(_CreatedBySwitch);

type Props = {
  history: Record<string, any>;
  segments: Array<ISegment>;
  isLoading: boolean;
  error: Error;
  search: IQueryParams;
  pushSearch: (arg0: IQueryParams) => IQueryParams;
  searchPhrase: string;
  selectedSearchType: string;
  handleSearchPhraseChange: (arg0: string) => void;
  handleSearchTypeChange: (arg0: string) => void;
  pushSearchBar: (arg0: { searchPhrase: string; selectedSearchType: string }) => void;
  msgIfEmpty?: string;
  paging: IPaging;
  goToPage: (arg0: number) => unknown;
  userEmail: string;
  appConfig: IAppConfig;
};

const audienceTypeOptions = [
  { id: audienceTypes.rule, label: audienceTypeDisplay.rule },
  { id: audienceTypes.csv, label: audienceTypeDisplay.csv },
];

export default class Reporting extends React.Component<Props> {
  getAudienceOptionById = id => audienceTypeOptions.filter(type => type.id === id)[0];

  handleChangeAudienceType = type => {
    const { pushSearch, search } = this.props;
    const newSearch = {
      ...search,
      [searchTypeAudienceType]: type.id,
    };

    pushSearch(newSearch);
  };

  handleRowClick = ({ id }) => {
    const { history } = this.props;

    return history.push(`/segments/${id}/`, {
      state: {
        from: history.location.pathname,
      },
    });
  };

  render() {
    const {
      segments,
      isLoading,
      error,
      search,
      searchPhrase,
      selectedSearchType,
      handleSearchPhraseChange,
      handleSearchTypeChange,
      pushSearchBar,
      msgIfEmpty,
      paging,
      goToPage,
      userEmail,
      appConfig: { supportedAudienceTypes = [] },
    } = this.props;

    return (
      <ConnectedUser>
        {({ isPermitted }) => (
          <Page>
            <PageHeader title="Audiences">
              <Switches>
                <Dropdown
                  label="Audience Type"
                  value={this.getAudienceOptionById(search[searchTypeAudienceType])}
                  options={audienceTypeOptions.filter(type => supportedAudienceTypes.includes(type.id))}
                  onChange={this.handleChangeAudienceType}
                />

                <CreatedBySwitch
                  userEmail={userEmail}
                  searchPhrase={searchPhrase}
                  selectedSearchType={selectedSearchType}
                  pushSearchBar={pushSearchBar}
                />
              </Switches>

              {isPermitted([...segmentCreationPermissions, ...segmentUploadPermissions]) && <CreateNewButton to="/segments/new" />}
            </PageHeader>

            <PageMain>
              <SearchContainer>
                <SearchBar
                  searchPhrase={searchPhrase}
                  selectedSearchType={selectedSearchType}
                  searchTypes={searchBarDefs}
                  handleSearchPhraseChange={handleSearchPhraseChange}
                  handleSearchTypeChange={handleSearchTypeChange}
                  pushSearchBar={pushSearchBar}
                />
              </SearchContainer>

              {error && error.message && (
                <DrawerError error="Error while retrieving audiences" errorDetails={error.message} allowDismiss={false} defaultExpanded>
                  <p>If this error persists, please report the folllowing error message:</p>
                </DrawerError>
              )}

              <SegmentTable
                segments={segments}
                isLoading={isLoading}
                isPermitted={isPermitted}
                handleRowClick={this.handleRowClick}
                msgIfEmpty={msgIfEmpty}
              />

              {isLoading ? undefined : (
                <Footer>
                  <Paging {...paging} handleGoToPage={goToPage} />
                </Footer>
              )}
            </PageMain>
          </Page>
        )}
      </ConnectedUser>
    );
  }
}
