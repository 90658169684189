import qs from 'query-string';
import api from 'app/utilities/api';

import { IQueryParams } from 'app/types/IQueryParams';
import { IUser } from 'app/types/User';
import { ResultGetPaged } from 'app/api/types';
import { fromApiMe } from './mappers';

import endpoints from './endpoints';

import Cache from 'memory-cache';
import { CACHE_KEYS } from '../../utilities/cacheKeys';

export const fetchUser = async (email: string): Promise<IUser> => api.get(endpoints.get.replace(':email', email));

export const fetchUsersPaged = async (params: IQueryParams): ResultGetPaged<IUser> =>
  api.get(`${endpoints.getAll}?${qs.stringify(params)}`);

export const fetchUserProfiles = async () => {
  const cachedUserProfiles = Cache.get(CACHE_KEYS.USER_PROFILES);

  if (cachedUserProfiles) return cachedUserProfiles;

  const userProfiles = await api.get(endpoints.getProfiles);

  Cache.put(CACHE_KEYS.USER_PROFILES, userProfiles);

  return userProfiles;
};

export const createNewUser = async (params: Record<string, any>, isNewUser: boolean) =>
  isNewUser ? api.post(endpoints.new, { body: params }) : api.put(endpoints.update, { body: params });

export const removeUser = async (params: { email: string }) => api.delete(endpoints.delete, { body: params });

export const getCurrentUser = () => api.get('/api/me').then(fromApiMe);
