const ROOT = '/campaigns';

export default {
  bannerIds: `${ROOT}/:id/banners`,

  fileUpload: '/files/upload',

  getDownloadableUrl: '/files/translate',

  getCampaign: (campaignId: number): string => `${ROOT}/${campaignId}`,

  scheduling: '/schedulings/:id',

  siteIds: '/sites/medium/:mediumName',

  testDelivery: `${ROOT}/testDelivery`,

  merchantRoles: '/ump/tags',

  getCategories: '/timeline-notifications/senders/default',

  getCategoryList: '/timeline-notifications/threads',
};
