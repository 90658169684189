import * as React from 'react';
import PropTypes from 'prop-types';

import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';

export const SelectAsMenu = ({
  id = 'select',
  label = 'Select',
  selectedId = '',
  items = [],
  handleChange,
  disabled = false,
  required = false,
  classes = {},
}) => (
  <FormControl disabled={disabled} className={classes.root}>
    {label && (
      <InputLabel required={required} htmlFor={id}>
        {label}
      </InputLabel>
    )}

    <Select autoWidth value={selectedId} onChange={handleChange} input={<Input name={id} id={id} />}>
      {items.map(item => (
        <MenuItem data-test-id="menu-item" data-qa="menu-item" key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

SelectAsMenu.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),

  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};
