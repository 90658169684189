import update from 'immutability-helper';
import { AppState, Action } from 'app/types';

import * as types from './types';

const deeplinksInitState = {
  deeplinks: [],
  fetchDeeplinksLoading: false,
  error: null,
};

export default (state: AppState = deeplinksInitState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_ALL_DEEPLINKS:
      return update(state, {
        fetchDeeplinksLoading: { $set: true },
      });

    case types.GET_ALL_DEEPLINKS_SUCCESS:
      return update(state, {
        deeplinks: { $set: payload.deeplinks },
        fetchDeeplinksLoading: { $set: false },
      });

    case types.GET_ALL_DEEPLINKS_FAILED:
      return update(state, {
        error: { $set: payload.error },
        fetchDeeplinksLoading: { $set: false },
      });

    default:
      return state;
  }
};
