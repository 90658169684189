import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import { BmsError } from 'app/api/bannerManagementV2/types';
import { withAppConfig } from 'app/decorators';
import { storefrontOperations } from 'app/ducks';
import { CMAPagingParams } from 'app/ducks/bannerManagement/utils';
import BmsDrawerError from 'app/features/BannerManagement/common/BmsDrawerError';
import withWidgetTypes from 'app/features/BannerManagement/hoc/withWidgetTypes';
import withReporting from 'app/hocs/withReporting';
import withDropdown from 'app/hocs/withReporting/withDropdown';
import { Dropdown as _Dropdown, Spinner as _Spinner } from 'app/midgarComponents';
import { SearchContainer, SearchControls } from 'app/midgarComponents/ReportingPage';
import { sc } from 'app/styles';
import { IQueryParams } from 'app/types';
import { IView } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import ViewTable from './ViewTable';
import { searchByPlatformDefiner, searchByPlatformVersion, searchBySiteDefiner, searchDefiner } from './search.config';

type Props = {
  allPlatforms: Array<string>;
  allSites: Array<string>;
  appConfig: IAppConfig;
  entityId?: number;
  entityType?: string;
  fetchViews: (...args: Array<any>) => any; // eslint-disable-line react/no-unused-prop-types
  pushSearch: (...args: Array<any>) => any;
  readOnly: boolean;
  search: IQueryParams;
  storefrontId: number;
  views: Array<IView>;
  viewsError?: BmsError | null | undefined;
  viewsLoading: boolean;
  permittedStorefronts: Record<string, any>;
  isSuperAdmin: boolean;
};

type State = {
  engageOnly: boolean;
};

class Views extends React.PureComponent<Props, State> {
  state = {
    engageOnly: false,
  };

  bannerConfig = getBannerCreativeConfig(this.props.appConfig); // eslint-disable-line react/destructuring-assignment

  toggleEngageOnly = () => {
    const { views } = this.props;

    if (views && views.length) {
      this.setState(prevState => ({
        engageOnly: !prevState.engageOnly,
      }));
    }
  };

  render() {
    const {
      allPlatforms,
      allSites,
      entityId,
      entityType,
      pushSearch,
      readOnly,
      search,
      storefrontId,
      viewsError, // TODO: Display the error
      viewsLoading,
      views,
      permittedStorefronts,
      isSuperAdmin,
    } = this.props;
    const { engageOnly } = this.state;

    const { useManagedByEngageFilter, usePlatformVersion } = this.bannerConfig;
    const filteredViews = useManagedByEngageFilter && engageOnly ? views.filter(x => x.managed_by_engage) : views;
    const permittedViewsSet = new Set(permittedStorefronts[storefrontId]);
    const permittedViews = isSuperAdmin
      ? filteredViews
      : filteredViews.map(view => {
          const modifiedView = { ...view };
          modifiedView.disabled = !permittedViewsSet.has(modifiedView.id);
          return modifiedView;
        });

    const SearchByPlatform = withDropdown(searchByPlatformDefiner(allPlatforms))(Dropdown);
    const SearchBySite = (allSites || []).length > 1 ? withDropdown(searchBySiteDefiner(allSites))(Dropdown) : null;

    return (
      <section>
        <SearchContainer>
          <SearchControls>
            <SearchByPlatform search={search} pushSearch={pushSearch} />

            {usePlatformVersion && <SearchByPlatformVersion search={search} pushSearch={pushSearch} />}

            {SearchBySite && <SearchBySite search={search} pushSearch={pushSearch} />}

            {useManagedByEngageFilter && (
              <EngageOnlyFlag active={!!engageOnly} onClick={this.toggleEngageOnly}>
                Engage Only
              </EngageOnlyFlag>
            )}
          </SearchControls>
        </SearchContainer>

        {viewsError && <BmsDrawerError error={viewsError} title="Error while retrieving views" />}

        {viewsLoading ? (
          <Spinner />
        ) : permittedViews.length === 0 ? (
          <Empty>No views found</Empty>
        ) : (
          <ViewTable entityId={entityId} entityType={entityType} readOnly={readOnly} storefrontId={storefrontId} views={permittedViews} />
        )}
      </section>
    );
  }
}

const mapStateToProps = ({
  bannerManagement: {
    storefront: { views, viewsError, viewsLoading },
  },

  user: { storefrontsAndViews: permittedStorefronts, isSuperAdmin },
}) => ({
  views,
  viewsError,
  viewsLoading,
  permittedStorefronts,
  isSuperAdmin,
});

const mapDispatchToProps = (dispatch: Dispatch<{ type: string }>, { appConfig }: { appConfig: IAppConfig }) => {
  const { bmsVersion } = getBannerCreativeConfig(appConfig);

  return {
    fetchViews: (storefrontId: number, entityType?: string, entityId?: number, cmaQuery: IQueryParams | CMAPagingParams) =>
      dispatch(storefrontOperations.getViews(bmsVersion)(storefrontId, entityType, entityId, cmaQuery)),
  };
};

export default compose(
  withRouter,
  withAppConfig,
  withWidgetTypes,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    fetchViews:
      ({ entityId, entityType, fetchViews, storefrontId }) =>
      (apiQueryParams: IQueryParams) =>
        fetchViews(storefrontId, entityType, entityId, apiQueryParams),
  }),

  withReporting('fetchViews', null, { searchDefiner }),
)(Views);

const Dropdown = styled(_Dropdown)`
  width: 15rem;
`;

const Empty = styled.span`
  display: block;
  padding: ${sc.gutterLarge};
`;

const EngageOnlyFlag = styled.span`
  position: absolute;
  top: 50%;
  right: ${sc.gutterSmall};
  transform: translate(0, -50%);

  display: block;
  padding: ${sc.gutterSmaller} ${sc.gutterSmall};

  border: 1px solid ${props => (props.active ? sc.primary : sc.primary)};
  border-radius: 3px;
  background-color: ${props => (props.active ? sc.primary : '#fff')};

  color: ${props => (props.active ? '#fff' : sc.primary)};
  font-size: ${sc.fontSizeSmall};
  cursor: pointer;
  transition: all 50ms ease-in-out;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

  &:hover {
    color: #fff;
    border-color: ${props => (props.active ? '#43d1ff' : sc.primary)};
    background-color: ${props => (props.active ? '#43d1ff' : sc.primary)};
  }
`;

const Spinner = styled(_Spinner)`
  padding: ${sc.gutter};
`;

const SearchByPlatformVersion = withDropdown(searchByPlatformVersion)(Dropdown);
