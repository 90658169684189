import { withHandlers } from 'recompose';
import { textFilter } from 'app/utilities/suggestionFiltering';

import Variables from './Variables';

const generateFilterRules = variable => {
  if (variable.data_type !== 'Nested' || variable.nested_types.length === 0) {
    return null;
  }
  const operands = variable.nested_types.map(nestedFeature => ({
    op: '',
    dataType: nestedFeature.data_type,
    operands: [nestedFeature.name],
  }));

  // FYI - this layer of ANDs and ORs are useless and adds no information.
  // But, this is needed in order to make rule engine work. For more clarification, ask Rule Engine team.
  return {
    op: 'OR',
    type: 'bool',
    operands: [
      {
        op: 'AND',
        type: 'bool',
        operands: [
          {
            op: 'AND',
            type: 'bool',
            parentFeature: variable.name,
            operands: operands,
          },
        ],
      },
    ],
  };
};

const typeaheadHandlers = withHandlers({
  onSelect:
    ({ addVariable }) =>
    v => {
      const rules = generateFilterRules(v);
      addVariable({
        ...v,
        filterRules: rules,
      });
    },
  getSuggestions:
    ({ features }) =>
    v => {
      let selections = [];
      if (Array.isArray(features)) {
        selections = features;
      } else {
        const {
          data: { allNames },
        } = features;
        selections = allNames;
      }
      return textFilter(v)(selections).slice(0, 100);
    },
});

export default typeaheadHandlers(Variables);
