import styled, { css } from 'styled-components';
import { sc } from 'app/styles';

export const TableRow = styled.tr`
  height: 95px;
  max-height: 95px;
  border-bottom: 1px solid #e6ecf0;

  ${props =>
    props.$hover && props.rowHoverOutline
      ? css`
          &:hover {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            background-color: ${sc.greyLightest};
          }
        `
      : ''};

  & > td {
    overflow: hidden;
  }
`;
