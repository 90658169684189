import React, { PureComponent } from 'react';
import { isEmpty } from 'ramda';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { inject } from 'app/decorators';
import { SideModal as _SideModal, Icon, TextField, Button, Spinner } from 'app/midgarComponents';
import { findPageFromPriority } from 'app/utilities/pagination';
import { sc } from 'app/styles';
import { binderOperations } from 'app/ducks';
import { compose } from 'recompose';

class MoveModal extends PureComponent<
  {
    isOpen: boolean;
    selected: Record<string, any>[];
    viewItem: Record<string, any>;
    viewItemMoving: boolean;
    slotViewItemsPageSize: number;
    fetchSlotViewItems: (...args: Array<any>) => any;
    closeModal: (...args: Array<any>) => any;
  },
  {
    afterBannerId: string | null | undefined;
  }
> {
  static defaultProps = {
    isOpen: false,
  };

  state = {
    afterBannerId: null,
  };

  onSave = async (selectedViewItems: Record<string, any>[]) => {
    const { afterBannerId } = this.state;
    const {
      closeModal,
      fetchSlotViewItems,
      slotViewItemsPageSize,
      BinderOperations: { moveAfterBannerId },
    } = this.props;

    const priority = await moveAfterBannerId(afterBannerId, selectedViewItems);
    const page = findPageFromPriority(priority, slotViewItemsPageSize);

    closeModal();
    fetchSlotViewItems(page);
  };

  render() {
    const { afterBannerId } = this.state;
    const { isOpen, selected, viewItem, viewItemMoving, closeModal } = this.props;

    const viewItems = selected && selected.length ? selected : isEmpty(viewItem) ? [] : [viewItem];

    return (
      <SideModal isOpen={isOpen} onClose={closeModal}>
        <Header>
          <h3>Move View Item</h3>

          <CloseIcon size={18} name="remove" onClick={closeModal} />
        </Header>

        <SelectedContainer>
          {viewItems && viewItems.length
            ? viewItems.map((item, index) => {
                const variant = item.variants[0];
                const imageUrl = variant && variant.image && variant.image.indexOf('http') > -1 ? variant.image : null;

                return (
                  <Card key={`move-banner-${item.id}`} last={index === viewItems.length - 1}>
                    <PreviewContainer>
                      <PreviewImage src={imageUrl} />
                    </PreviewContainer>

                    <Info>
                      <Name>{item.name}</Name>

                      <span>Banner ID:</span>
                      <Value>{variant.catalogBannerId || '-'}</Value>
                    </Info>
                  </Card>
                );
              })
            : null}
        </SelectedContainer>

        <TextField
          id="after-banner-id"
          name="after-banner-id"
          label="After Banner ID"
          value={afterBannerId || ''}
          onChange={ev => this.setState({ afterBannerId: ev.target.value })}
        />

        <SaveButton type="primary" onClick={() => this.onSave(viewItems)} disabled={viewItemMoving}>
          {viewItemMoving ? <Spinner /> : 'Save'}
        </SaveButton>
      </SideModal>
    );
  }
}

export default compose(
  inject({ BinderOperations: binderOperations }),
  connect(({ bannerManagement: { binder } }) => ({
    viewItemMoving: binder.viewItemMoving,
    slotViewItemsPageSize: binder.slotViewItemsPageSize,
  })),
)(MoveModal);

const SideModal = styled(_SideModal)`
  width: 370px;
`;

const Header = styled.div`
  padding-bottom: ${sc.gutterLarge};

  & > h3 {
    letter-spacing: 0.4px;
  }
`;

const CloseIcon = styled(Icon)`
  padding: ${sc.gutterSmaller};
  position: absolute;
  top: ${sc.gutterSmaller};
  right: ${sc.gutterSmaller};
  color: ${sc.headingColor};

  &:hover {
    color: ${sc.primary};
    cursor: pointer;
  }
`;

const SaveButton = styled(Button)`
  margin-top: ${sc.gutterSmall};
`;

const Card = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: ${sc.gutterLarger};
  position: relative;

  ${props =>
    props.last
      ? css`
          margin-bottom: ${sc.gutterSmallest};
        `
      : css`
          &:after {
            content: '';
            position: absolute;
            bottom: -${sc.gutterSmall};
            left: 0;
            right: 0;
            height: 1px;
            background-color: ${sc.sectionBorderColor};
          }
        `}
`;

const PreviewContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 25%;
  height: 100%;
`;

const Info = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 75%;
  padding: ${sc.gutterSmaller};
  font-size: ${sc.fontSizeSmaller};
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;

  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  border-radius: 3px;
  border: 1px solid ${sc.greyLighter};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Name = styled.div`
  width: 100%;
  font-weight: 600;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Value = styled.span`
  margin-left: ${sc.gutterSmallest};
`;

const SelectedContainer = styled.div`
  max-height: 75%;
  margin-bottom: ${sc.gutterSmaller};
  overflow-y: scroll;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;
