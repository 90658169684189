import React from 'react';
import { withStateHandlers, renderComponent, lifecycle, compose, branch } from 'recompose';

import { isPromise } from 'ramda-adjunct';

import { CircularProgress } from '@material-ui/core';

import { echoLog } from 'app/helpers/DebugHelpers/helpers';
import RuleValueEdit from './RuleValueEdit';

const lifeCycle = lifecycle({
  componentDidMount() {
    const { promise, handleSuccess, handleFailure } = this.props;

    if (isPromise(promise)) {
      promise.then(handleSuccess).catch(handleFailure);
    } else {
      const message = 'The `promise` prop is not a promise.';
      echoLog(promise, message);

      handleFailure(new Error(message));
    }
  },
});

const noop = () => ({});

const updateStateWith = (outcome, handle = noop) => ({
  ...handle(outcome),
  isAwaiting: false,
});

const stateHandlers = withStateHandlers(
  () => ({
    isAwaiting: true,
  }),

  {
    handleSuccess:
      (_, { onSuccess }) =>
      result =>
        updateStateWith(result, onSuccess),
    handleFailure:
      (_, { onFailure }) =>
      error =>
        updateStateWith(error, onFailure),
  },
);

const withLoading = branch(
  ({ isAwaiting }) => isAwaiting,
  renderComponent(() => <CircularProgress size={20} />),
);

const enhance = compose(stateHandlers, lifeCycle, withLoading);
export default enhance(RuleValueEdit);
