import { getCategories } from 'app/api/categories';
import { DispatchFn } from 'app/types';

import * as actions from './actions';

export const getAllCategories = () => async (dispatch: DispatchFn) => {
  dispatch(actions.getAll());
  try {
    const categories = await getCategories();
    return dispatch(actions.getAllSuccess(categories));
  } catch (e) {
    return dispatch(actions.getAllFail(e));
  }
};
