import * as React from 'react';
import PropTypes from 'prop-types';
import { branch, renderComponent } from 'recompose';

import Button from 'app/midgarComponents/Button';
import { TableCell } from '../Table/TableCell';

const EditAction = ({ onEdit }) => (
  <TableCell>
    <Button onClick={onEdit}>Edit</Button>
  </TableCell>
);

EditAction.propTypes = {
  onEdit: PropTypes.func.isRequired,
};

const SaveActions = ({ onSave, onCancel }) => (
  <TableCell>
    <Button onClick={onSave}>Save</Button>
    <Button onClick={onCancel}>Cancel</Button>
  </TableCell>
);

SaveActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export const EditActions = branch(({ editState }) => editState, renderComponent(SaveActions))(EditAction);
