import { isEmpty } from 'ramda';
import moment from 'moment';
import { emailMediumId, push2MediumId } from 'configs/channels/mediumIds';
import { defaultDateFormat } from 'configs/dateTime';
import { PAYPAY_CONSUMER } from 'configs/apps/tenants/PAYPAY_CONSUMER';
import { getSelectedTenant } from 'configs/user';

// These functions have to be in a separate file, because the use of channel.config.js in validation.js causes jest tests to fail

type ICreative = any;

export const MAX_CAMPAIGN_DURATION_DAYS = getSelectedTenant() === PAYPAY_CONSUMER ? 1095 : 365;

export const failMsgCreativeInvalid = 'The "Creative" section is not completed correctly';
export const failMsgNoChannel = 'A channel must be selected';
export const failMsgFormErrors = (formSections: string) => `Please review the following invalid form sections: ${formSections}`;
export const failMsgSegmentFilters = 'You cannot specify "Excluded Audiences Filter" without specifying "Excluded Audiences"';
export const failMsgVariables = 'All non-nested variables must have default values and they must be used.';
export const failPredicateFilter = 'Schedule predicate filter is invalid.';
export const failBannerUserCriteria = 'Real-time User Criteria is invalid.';
export const failMsgScheduleStartTime = 'Schedule start time should not be 30 mins earlier than current time.';
export const failMsgBusinessPhase = 'User Lifecycle Stage is required for all one time and reccuring campaigns';
export const failMsgBannerEvents = 'Either Number of Impressions or Deactivation Event is required for Events-based Banners';
export const failMsgEvent = 'Event is required for event based campaigns';
export const failMsgEventValidTime = 'Event ‘Valid To’ should be AFTER ‘Valid From’';
export const failMsgDelayTime = 'Delay time cannot be longer than 2 days';
export const failMsgEndDate = 'End Date has to be after Start Date';
export const failMsgMaxDurationExceeded = `The end date should not be later than ${MAX_CAMPAIGN_DURATION_DAYS} days from the start date`;
export const failMsgMaxAudienceLimit = (maxAudienceLimit: number) => {
  const limitStr = maxAudienceLimit >= 1000000 ? `${maxAudienceLimit / 1000000}M` : maxAudienceLimit;
  return `max audience should not exceed ${limitStr}`;
};
export const failMsgRequired = (missingFields: Array<string>, fieldTitleByName: Record<string, string>) =>
  `Following required values are missing: ${missingFields.map(fName => fieldTitleByName[fName] || fName).join(', ')}. `;

export const isDefaultValueEmpty = (v: { defaultValue?: unknown }) =>
  !v.defaultValue || (typeof v.defaultValue === 'string' && !v.defaultValue.trim());

export const isRequiredValueNonEmpty = (v: unknown) =>
  v !== undefined && v !== null && (typeof v === 'string' ? !isEmpty(v.trim()) : !isEmpty(v));

export const missingRequiredFields = (required: Array<string>, obj: {}) =>
  required.reduce((acc, propName) => (isRequiredValueNonEmpty(obj[propName]) ? acc : [...acc, propName]), []);

export const validateSegmentFilters = (excludedSegments: Array<{}>, excludedSegmentsFilters: Array<{}>) =>
  !excludedSegmentsFilters || isEmpty(excludedSegmentsFilters) || (!!excludedSegments && !isEmpty(excludedSegments));

const validateVariablesAreUsed = (variables: Record<string, any>, creative?: ICreative, mediumId = 0) => {
  const fieldsToCheck = {
    [push2MediumId]: [
      creative?.payload?.notification?.content?.body,
      creative?.payload?.notification?.content?.summary,
      creative?.payload?.notification?.content?.title,
      creative?.payload?.notification?.interactive?.click?.value,
      creative?.payload?.notification?.interactive?.click?.legacyDeeplink?.options?.url,
    ],

    [emailMediumId]: ['html', 'subject'],
  };

  const variablesCheckList = [];
  let allVariablesLength = 0;

  Object.keys(variables).forEach(variableType => {
    variables[variableType].forEach(v => {
      const item = v.name || '';
      allVariablesLength += 1;
      fieldsToCheck[mediumId].forEach(field => {
        const itemString = creative?.templated ? item : `{{${item}}}`;
        const resolvedConditionals = mediumId === push2MediumId ? field?.includes(itemString) : creative?.[field].includes(itemString);

        if (resolvedConditionals && !variablesCheckList.includes(item)) {
          variablesCheckList.push(item);
        }
      });

      if (!variablesCheckList.includes(item)) {
        return false;
      }
    });
  });

  return variablesCheckList.length === allVariablesLength;
};

export const compareDates = (
  startDate: string | null | undefined,
  startTime: string | null | undefined,
  endDate: string | null | undefined,
  endTime: string | null | undefined,
) => {
  if (!startDate || !startTime || !endDate || !endTime) return {};
  return {
    isMaxDurationExceeded: moment(`${endDate}`).diff(moment(`${startDate}`), 'days') > MAX_CAMPAIGN_DURATION_DAYS,
    isSameOrAfter: moment(`${startDate} ${startTime}`).isSameOrAfter(moment(`${endDate} ${endTime}`)),
    isAfter: moment(`${startDate} ${startTime}`).isAfter(moment(`${endDate} ${endTime}`)),
  };
};

export const isValidTimeValid = (from: string | null | undefined, to: string | null | undefined) => {
  if (!from && !to) return true;
  if (!from || !to) return false;

  const dateStr = moment().format(defaultDateFormat);
  return moment(`${dateStr} ${to}`).isAfter(moment(`${dateStr} ${from}`));
};

export const validateVariables = (variables: Record<string, any>, creative?: ICreative, mediumId?: number) =>
  variables.features &&
  variables.events &&
  !variables.features.some(isDefaultValueEmpty) &&
  !variables.events.some(isDefaultValueEmpty) &&
  ([push2MediumId, emailMediumId].includes(mediumId) ? validateVariablesAreUsed(variables, creative, mediumId) : true);

const getDuplicatesByField = (arr: Array<Record<string, any>>, field: string) => {
  if (!arr || arr.length) return [];
  const fieldsList = arr.map(item => item[field]);
  return fieldsList.filter((item, idx) => item && fieldsList.indexOf(item) !== idx);
};

export const getDuplicateVariables = (variables: Record<string, any>) => [
  ...getDuplicatesByField(variables.events, 'name'),
  ...getDuplicatesByField(variables.features, 'feature_name'),
];
