import { cashbackTypes } from 'src/app/features/Cashback/constants';

export default {
  // Only used in cashback for now. Should be moved to separate folder when used more widely.
  campaignAttributes: (scope: string, medium: string) => `/campaign-attributes/scope/${scope}/medium/${medium}`,

  // Cashback Banners
  getAllCashbackBanners: '/cashback-banners',
  getCashbackBanner: (id: number) => `/cashback-banners/${id}`,
  postCashbackBanner: '/cashback-banners',
  postJourneyBanner: `/cashback-banners/${cashbackTypes.journey}`,
  putCashbackBannersPriorties: '/cashback-banners/updatePriority',

  // Payment Ceiling
  getPaymentCeilings: '/stack-ceilings',
  getPaymentCeiling: (id: number) => `/stack-ceilings/${id}`,
  putPaymentCeiling: (id: number) => `/stack-ceilings/${id}`,

  // Promo Events
  getAllPromoEvents: '/promo-events?visibleType=visible',
  getPromoEventFieldTypes: '/promo-events/fieldTypes',

  // Promo Event for real time customer
  getCustomerPromoEvent: '/promo-events/name/customer',

  // Stackable Groups
  getStackableGroups: '/stackable-groups',
  getStackableGroupEvents: (groupId: number) => `/stackable-groups/${groupId}/events`,
  getStackableGroupCampaigns: (groupId: number, eventName: string) =>
    `/management/cashback/priority-plan/groups/${groupId}/events/${eventName}`,
  postStackableGroupPriorities: (groupId: number, eventName: string) =>
    `/management/cashback/priority-plan/groups/${groupId}/events/${eventName}`,

  // User Budget
  getUserBudgetTokens: '/user-budget-tokens',
  postUserBudgetToken: '/user-budget-tokens',
};
