import { Action } from 'app/types';
import { ISlotGroupType } from 'app/types/BannerManagement';

import { IFetch } from '../utils';
import { baseFetch } from '../utils';
import * as types from './types';

export type State = IFetch & {
  slotGroupTypes: Array<ISlotGroupType>;
};

const initialState: State = {
  ...baseFetch,
  slotGroupTypes: [],
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_SLOT_GROUP_TYPES_FAILED:
      return {
        ...state,
        error: payload.error,
        loading: false,
        slotGroupTypes: [],
      };

    case types.GET_SLOT_GROUP_TYPES_START:
      return {
        ...state,
        error: null,
        loading: true,
        slotGroupTypes: [],
      };

    case types.GET_SLOT_GROUP_TYPES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        slotGroupTypes: payload.slotGroupTypes,
      };

    default:
      return state;
  }
};
