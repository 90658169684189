import * as React from 'react';
import { connect } from 'react-redux';
import { RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import qs from 'query-string';
import styled from 'styled-components';

import { DrawerError } from 'app/components';
import { withPermissions } from 'app/decorators';
import {
  stackableGroupCampaignsActions,
  stackableGroupCampaignsOperations,
  stackableGroupEventsOperations,
  stackableGroupsOperations,
} from 'app/ducks';

import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { Button, Dropdown, Page, PageHeader, PageMain, Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { CASHBACK_MANAGEMENT_STACKABLE_GROUP_CREATE } from 'configs/permissions';

import { cashbackTypes } from 'app/features/Cashback/constants';
import CampaignsList from './CampaignsList';
import { parseStackableGroupUrl } from './routeUtils';
import { IStackableGroupCampaign } from '../types';

type Props = {
  campaigns: Array<IStackableGroupCampaign>;
  campaignsError: Error | null | undefined;
  campaignsLoading: boolean;
  clearCampaigns: () => (...args: Array<any>) => any;
  event?: string;
  events: Array<string>;
  eventsError: Error | null | undefined;
  eventsLoading: boolean;
  getCampaigns: (groupId: number, eventName: string) => (...args: Array<any>) => any;
  getEvents: (groupId: number) => (...args: Array<any>) => any;
  getStackableGroups: () => (...args: Array<any>) => any;
  groupId: number;
  groupName?: string;
  history: RouterHistory;
  isPermitted: IsPermittedFn;
  saveCampaignPriorities: (groupId: number, eventName: string, campaignIds: Array<number>) => (...args: Array<any>) => any;
  saving: boolean;
  updateCampaignPriorities: (arg0: Array<IStackableGroupCampaign>) => (...args: Array<any>) => any;
};

type State = {
  isDirty: boolean;
};

class Overview extends React.PureComponent<Props, State> {
  state = {
    isDirty: false,
  };

  componentDidMount() {
    const { clearCampaigns, event, getCampaigns, getEvents, getStackableGroups, groupId, groupName } = this.props;

    if (!groupName) {
      getStackableGroups();
    }

    if (groupName && groupName.toLowerCase().indexOf(cashbackTypes.journey) > -1 && !event) {
      this.onEventChange(cashbackTypes.journey);
    }

    getEvents(groupId);

    if (event) {
      getCampaigns(groupId, event);
    } else {
      clearCampaigns();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { event, events, getCampaigns, groupId } = this.props;

    if (events && events.length && (!prevProps.events || !prevProps.events.length)) {
      this.onEventChange(events[0]);
    } else if (event && event !== prevProps.event) {
      getCampaigns(groupId, event);
    }
  }

  onEventChange = (event: string) => {
    const { history } = this.props;
    history.push(`?${qs.stringify({ event })}`);
  };

  onSave = () => {
    const { campaigns, event, groupId, saveCampaignPriorities } = this.props;
    if (groupId !== undefined && groupId !== null) {
      saveCampaignPriorities(groupId, event, campaigns);
      this.setState({
        isDirty: false,
      });
    }
  };

  updateCampaignPriorities = (campaigns: Array<IStackableGroupCampaign>) => {
    const { updateCampaignPriorities } = this.props;
    updateCampaignPriorities(campaigns);
    this.setState({ isDirty: true });
  };

  render() {
    const {
      campaigns,
      campaignsError,
      campaignsLoading,
      event,
      events,
      eventsError,
      eventsLoading,
      groupId,
      groupName,
      isPermitted,
      saving,
    } = this.props;

    const { isDirty } = this.state;

    const pageTitle = groupName || `Group: ID=${groupId !== undefined && groupId !== null ? String(groupId) : 'N/A'}`;
    const errorDetails = eventsError ? eventsError.message : campaignsError ? campaignsError.message : '';
    const readOnly = !isPermitted([CASHBACK_MANAGEMENT_STACKABLE_GROUP_CREATE]);

    return (
      <Page>
        {eventsLoading ? (
          <PageMain>
            <Spinner />
          </PageMain>
        ) : (
          <>
            <PageHeader title={pageTitle}>
              <Button disabled={!isDirty || saving} onClick={this.onSave}>
                {saving ? <Spinner /> : 'Save'}
              </Button>
            </PageHeader>

            <PageMain>
              <SearchContainer>
                <Dropdown label="Event" value={event} options={events} onChange={this.onEventChange} error={!!eventsError} />
              </SearchContainer>

              {(campaignsError || eventsError) && (
                <DrawerError error="Error during retrieval" errorDetails={errorDetails} allowDismiss={false} defaultExpanded />
              )}

              {campaignsLoading ? (
                <Spinner />
              ) : campaigns.length ? (
                <CampaignsList campaigns={campaigns} readOnly={readOnly} updateCampaignPriorities={this.updateCampaignPriorities} />
              ) : (
                (event || !events.length) && <section>No Campaigns in Group</section>
              )}
            </PageMain>
          </>
        )}
      </Page>
    );
  }
}

export default compose(
  withRouter,
  withPermissions,
  withProps(parseStackableGroupUrl),
  connect(
    (
      {
        cashbackPromoConfig: {
          stackableGroup: {
            campaigns: { campaigns, error: campaignsError, loading: campaignsLoading, saving },
            events: { events, error: eventsError, loading: eventsLoading },
          },

          stackableGroups: { stackableGroups },
        },
      },

      { groupId, location: { search } },
    ) => {
      const { name: groupName } = stackableGroups.find(g => g.id === groupId) || {};
      const { event } = qs.parse(search);

      if (groupName && groupName.toLowerCase().indexOf(cashbackTypes.journey) > -1) {
        // journey only have journey event
        return {
          campaigns,
          campaignsError,
          campaignsLoading,
          event,
          events: [cashbackTypes.journey],
          eventsError: null,
          eventsLoading: false,
          groupName,
          saving,
        };
      }

      return {
        campaigns,
        campaignsError,
        campaignsLoading,
        event,
        events,
        eventsError,
        eventsLoading,
        groupName,
        saving,
      };
    },
    {
      clearCampaigns: stackableGroupCampaignsActions.clear,
      getCampaigns: stackableGroupCampaignsOperations.getCampaignPriorities,
      getEvents: stackableGroupEventsOperations.getEvents,
      getStackableGroups: stackableGroupsOperations.getStackableGroups,
      saveCampaignPriorities: stackableGroupCampaignsOperations.saveCampaignPriorities,
      updateCampaignPriorities: stackableGroupCampaignsActions.update,
    },
  ),
)(Overview);

const SearchContainer = styled.section`
  margin-bottom: ${sc.gutter};
  width: 20rem;
`;
