import * as React from 'react';
import PropTypes from 'prop-types';

import { TableHead, TableRow, TableCell } from '@material-ui/core';
import { map } from 'ramda';

const renderCell = column => <TableCell key={column.key}>{column.renderHeader ? column.renderHeader(column) : column.label}</TableCell>;

export const Header = ({ columns = [], rowEditable = true }) => (
  <TableHead>
    <TableRow>
      {map(renderCell)(columns)}
      {rowEditable && <TableCell />}
    </TableRow>
  </TableHead>
);

Header.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      renderHeader: PropTypes.func,
    }),
  ),

  rowEditable: PropTypes.bool,
};
