import * as React from 'react';

import { Typeahead } from 'app/midgarComponents';
import { getSegment as fetchSegment, getSegmentsPaged as fetchSegments } from 'app/api/segments';
import { searchTypeName } from 'app/features/Segments/Reporting/search.config'; // TODO: Move query param names to API

import { IQueryParams, ISuggestion } from 'app/types';

import { searchParams } from './search.config';

const searchParam = searchParams.segment;

type Props = {
  search: IQueryParams;
  pushSearch: (arg0: IQueryParams) => void;
};

type State = {
  loading: boolean;
  selectedSuggestion?: ISuggestion;
};

export default class SegmentsSearch extends React.PureComponent<Props, State> {
  state = {
    loading: false,
  };

  async componentDidMount() {
    const { search } = this.props;
    const idStr = search[searchParam];
    if (idStr) {
      const id = Number(idStr);
      if (id > 0) {
        this.setState({
          loading: true,
          selectedSuggestion: undefined,
        });

        const selectedSegment = await fetchSegment(id);
        const selectedSuggestion = selectedSegment ? { id, name: selectedSegment.name } : { id, name: `Error retrieving segment ${id}` }; // TODO: Better error handling
        this.setState({
          loading: false,
          selectedSuggestion,
        });
      }
    }
  }

  getSegments = async str => {
    const { content } = await fetchSegments({ [searchTypeName]: str, page: 0, size: 25 });
    return content;
  };

  onItemSelect = (suggestion: ISuggestion) => {
    const { pushSearch, search } = this.props;
    pushSearch({ ...search, [searchParam]: suggestion.id });
    this.setState({
      selectedSuggestion: suggestion,
    });
  };

  render() {
    const { loading, selectedSuggestion } = this.state;

    return (
      <div>
        <Typeahead
          isPromise
          loading={loading}
          debounceTime={500}
          value={(selectedSuggestion || {}).name}
          getSuggestions={this.getSegments}
          onItemSelect={this.onItemSelect}
          placeholder="Segment"
          name="segment-id"
        />
      </div>
    );
  }
}
