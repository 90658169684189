import { PAYPAY_CONSUMER } from './PAYPAY_CONSUMER';

const ApiPrefixByTenant = {
  [PAYPAY_CONSUMER]: '/paypay-consumer',
};

export const tenantPrefix = ApiPrefixByTenant[PAYPAY_CONSUMER];

const allTenants = [PAYPAY_CONSUMER];

const tenantNameKeys: Record<string, string> = allTenants.reduce((acc, tenantName: string) => ({ ...acc, [tenantName]: tenantName }), {});

type Keys<T> = keyof T;

export type ITenantName = Keys<typeof tenantNameKeys>;
