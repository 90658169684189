import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import styled from 'styled-components';
import moment from 'moment';

import { withAppConfig } from 'app/decorators';
import { binderActions } from 'app/ducks';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { Autocomplete, DateRangeField, Dropdown, Pills, Spinner, TextField, Toggle } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Action, ISuggestion } from 'app/types';
import { ISlotGroupType } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import { MAX_DATE_MOMENT } from '../../constants';
import { asPillSuggestionsWithAll } from '../../common/helpers';
import withGaTypes from '../../hoc/withGaTypes';
import withSlotGroupTypes from '../../hoc/withSlotGroupTypes';
import withWidgetTypes from '../../hoc/withWidgetTypes';
import { SaveButton } from '../../Creatives/CreativeEditor/Variants';
import withTags from '../../hoc/withTags';

type Props = {
  allPlatforms: Array<string>;
  allSites: Array<string>;
  appConfig: IAppConfig;
  gaTypes: Array<string>;
  loading: boolean;
  readOnly?: boolean;
  saveViewItem: (...args: Array<any>) => any;
  selectedDates: Record<string, any>;
  setSelectedDate: (field: string, value: any) => Action;
  setViewItemField: (field: string, value: any) => Action;
  slotGroupTypes: Array<ISlotGroupType>;
  tags: Array<string>;
  tagsError?: Error;
  tagsLoading: boolean;
  view: Record<string, any>;
  viewItem: Record<string, any>;
};

type State = {
  platformValue: string;
  siteValue: string;
  tagValue: string;
};

class EditableFields extends PureComponent<Props, State> {
  state = {
    platformValue: '',
    siteValue: '',
    tagValue: '',
  };

  bannerConfig = getBannerCreativeConfig(this.props.appConfig); // eslint-disable-line react/destructuring-assignment

  onPlatformAdd = (platformToAdd: ISuggestion) => {
    const {
      allPlatforms,
      setViewItemField,
      viewItem: { platform },
    } = this.props;

    if (platformToAdd && platformToAdd.id === '') {
      setViewItemField('platform', allPlatforms);
    } else if (platform && !platform.includes(platformToAdd.name)) {
      setViewItemField('platform', [...platform, platformToAdd.name]);
    }
  };

  onPlatformRemove = (platformToRemove: ISuggestion) => {
    const {
      setViewItemField,
      viewItem: { platform },
    } = this.props;
    const platforms = platform && !!platform.length ? platform.slice() : [];
    const idx = platforms.findIndex(p => p === platformToRemove.name);

    if (idx > -1) {
      platforms.splice(idx, 1);
      setViewItemField('platform', platforms);
    }
  };

  onSelectDateRange = (fromFieldName: string, toFieldName: string) => (range: Array<any>) => {
    const { setSelectedDate } = this.props;

    if (MAX_DATE_MOMENT.isBefore(range[0]) || MAX_DATE_MOMENT.isBefore(range[1])) {
      displayError('Choose date before 2038-01-01');
    } else {
      setSelectedDate(fromFieldName, range[0]);
      setSelectedDate(toFieldName, range[1]);
    }
  };

  onSiteAdd = (siteToAdd: ISuggestion) => {
    const {
      allSites,
      setViewItemField,
      viewItem: { site },
    } = this.props;

    if (siteToAdd && siteToAdd.id === '') {
      setViewItemField('site', allSites);
    } else if (site && !site.includes(siteToAdd.name)) {
      setViewItemField('site', [...site, siteToAdd.name]);
    }
  };

  onSiteRemove = (siteToRemove: ISuggestion) => {
    const {
      setViewItemField,
      viewItem: { site },
    } = this.props;
    const sites = site && !!site.length ? site.slice() : [];
    const iSite = sites.findIndex(s => s === siteToRemove.name);

    if (iSite > -1) {
      sites.splice(iSite, 1);
      setViewItemField('site', sites);
    }
  };

  onTagAdd = (tagToAdd: ISuggestion) => {
    const {
      setViewItemField,
      viewItem: { tags },
    } = this.props;

    if (!(tags || []).includes(tagToAdd.id)) {
      const newTags = [...(tags || []), tagToAdd.id];
      setViewItemField('tags', newTags);
    }
  };

  onTagRemove = (tagToRemove: ISuggestion) => {
    const {
      setViewItemField,
      viewItem: { tags },
    } = this.props;

    const newTags = tags.filter(tag => tag !== tagToRemove.id);
    setViewItemField('tags', newTags);
  };

  render() {
    const { platformValue, siteValue, tagValue } = this.state;

    const {
      allPlatforms,
      allSites,
      gaTypes,
      loading,
      readOnly,
      saveViewItem,
      selectedDates,
      setViewItemField,
      slotGroupTypes,
      tags,
      tagsError,
      tagsLoading,
      view,
      viewItem,
    } = this.props;

    const { useGACategory } = this.bannerConfig;
    const { gaCategory, id, platform, site, slotId, type } = viewItem;

    const platformOptions = asPillSuggestionsWithAll(allPlatforms);
    const siteOptions = asPillSuggestionsWithAll(allSites);
    const slotOptions = view && view.slots.map(slot => ({ id: slot.id, label: slot.id }));
    const slotGroupOptions = slotGroupTypes.map(({ label, value }) => ({ id: value, label }));

    const tagOptions = (tags || []).map(tag => ({ id: tag, name: tag }));

    return (
      <Content>
        <SaveButton
          onClick={() => !readOnly && saveViewItem(id)}
          disabled={(site && !site.length) || (platform && !platform.length) || readOnly}
        >
          {loading ? <Spinner color={sc.sectionWhiteColor} /> : 'Update'}
        </SaveButton>

        <Group>
          {useGACategory && (
            <InlineField>
              <Autocomplete
                id="ga"
                name="ga"
                label="GA Tracking Category"
                suggestions={gaTypes}
                value={gaCategory}
                onSelect={(suggestion: string) => setViewItemField('gaCategory', suggestion)}
                disabled={readOnly}
              />
            </InlineField>
          )}

          <InlineField>
            <InlineField>
              <Dropdown
                label="Slot ID"
                value={slotId && slotOptions.find(slot => slot.id === slotId)}
                options={slotOptions}
                onChange={option => setViewItemField('slotId', typeof option === 'object' && option.id)}
                disabled={readOnly}
              />
            </InlineField>

            <InlineField>
              <Dropdown
                label="Slot Group"
                value={type && slotGroupOptions.find(slotGroup => slotGroup.id === type)}
                options={slotGroupOptions}
                onChange={option => setViewItemField('type', typeof option === 'object' && option.id)}
                disabled={readOnly}
              />
            </InlineField>
          </InlineField>
        </Group>

        <Row>
          <Pills
            required
            id="platforms"
            name="platforms"
            label="Platforms"
            suggestions={platformOptions}
            selected={platform && platform.map(p => ({ id: p.toLowerCase(), name: p }))}
            value={platformValue}
            onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ platformValue: ev.target.value })}
            onSelect={this.onPlatformAdd}
            onRemove={this.onPlatformRemove}
            error={platform && !platform.length}
            disabled={readOnly}
          />

          <Pills
            required
            id="site"
            name="site"
            label="Site"
            suggestions={siteOptions}
            selected={site && site.map(s => ({ id: s.toLowerCase(), name: s }))}
            value={siteValue}
            onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ siteValue: ev.target.value })}
            onSelect={this.onSiteAdd}
            onRemove={this.onSiteRemove}
            error={site && !site.length}
            disabled={readOnly}
          />

          {this.bannerConfig.useTags && (
            <Pills
              disabled={readOnly || tagsLoading || !!tagsError}
              error={!!tagsError}
              id="tags"
              label="Tags"
              name="tags"
              onChange={(ev: React.SyntheticEvent<HTMLInputElement>) => this.setState({ tagValue: ev.target.value })}
              onRemove={this.onTagRemove}
              onSelect={this.onTagAdd}
              selected={tagOptions.filter(tag => (viewItem.tags || []).includes(tag.id))}
              suggestions={tagOptions}
              value={tagsError ? 'Error retrieving tags' : tagValue}
            />
          )}
        </Row>

        <Group>
          <InlineField>
            <DateRangeField
              id="bind-schedule-date-time-range"
              name="bind-schedule-date-time-range"
              label="Schedule (Start - End) Date and Time"
              withTimePicker
              startValue={selectedDates.validFrom}
              endValue={selectedDates.validUpto}
              pickerDefaultStartValue={selectedDates.validFrom || moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm')}
              pickerDefaultEndValue={selectedDates.validUpto || moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm')}
              handleSelect={this.onSelectDateRange('validFrom', 'validUpto')}
              disabled={readOnly}
            />
          </InlineField>

          {this.bannerConfig.useSaleDates && (
            <InlineField>
              <DateRangeField
                id="bind-sale-date-time-range"
                name="bind-sale-date-time-range"
                label="Sale (Start - End) Date and Time"
                withTimePicker
                startValue={selectedDates.startDate}
                endValue={selectedDates.endDate}
                pickerDefaultStartValue={selectedDates.startDate || moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm')}
                pickerDefaultEndValue={selectedDates.endDate || moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm')}
                handleSelect={this.onSelectDateRange('startDate', 'endDate')}
                disabled={readOnly}
              />
            </InlineField>
          )}
        </Group>

        {(this.bannerConfig.useTitles || this.bannerConfig.useShareable) && (
          <Row>
            {this.bannerConfig.useTitles && (
              <InlineField>
                <TextField
                  id="title"
                  name="title"
                  label="Title"
                  value={viewItem.title || ''}
                  onChange={ev => setViewItemField('title', ev.target.value)}
                  disabled={!!readOnly}
                />
              </InlineField>
            )}

            <InlineField>
              <Row>
                {this.bannerConfig.useTitles && (
                  <TextField
                    id="subtitle"
                    name="subtitle"
                    label="Sub-Title"
                    value={viewItem.subtitle || ''}
                    onChange={ev => setViewItemField('subtitle', ev.target.value)}
                    disabled={!!readOnly}
                  />
                )}

                {this.bannerConfig.useShareable && (
                  <div>
                    <Label>Shareable</Label>
                    <Toggle
                      active={!!viewItem.shareable}
                      onChange={status => setViewItemField('shareable', status)}
                      disabled={!!readOnly}
                    />
                  </div>
                )}
              </Row>
            </InlineField>
          </Row>
        )}
      </Content>
    );
  }
}

const mapDispatchToProps = {
  setSelectedDate: binderActions.setSelectedDate,
  setViewItemField: binderActions.setViewItemField,
};

export default compose(
  withAppConfig,
  withGaTypes,
  withSlotGroupTypes,
  withWidgetTypes,
  withTags,
  connect(null, mapDispatchToProps),
)(EditableFields);

const Content = styled.section`
  border: 1px solid ${sc.sectionBorderColor};
  padding: ${sc.gutter};
  margin-bottom: ${sc.gutter};
  position: relative;
`;

const InlineField = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 10px);
`;

const Group = styled.section`
  margin-bottom: ${sc.gutter};

  & ${InlineField} {
    margin-right: ${sc.gutter};

    &:last-child {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmallest};
  text-transform: uppercase;
  margin-bottom: ${sc.gutterSmallest};
`;

const Row = styled.div`
  display: flex;
  margin-bottom: ${sc.gutter};
  & > div {
    margin-right: ${sc.gutter};
  }
  & > div:last-child {
    margin-right: 0;
  }
`;
