import usePermissions from 'app/hooks/usePermissions';
import { CAMPAIGN_BANNER_CREATE } from 'configs/permissions';

import useStorefrontsCma from '../../common/useStorefrontsCma';

export default (): {
  isCampaignEditable: boolean;
  canEditStorefront: (storefrontId?: number) => boolean;
} => {
  const { permittedStorefronts } = useStorefrontsCma();
  const isPermitted = usePermissions();
  const isCampaignEditable = isPermitted([CAMPAIGN_BANNER_CREATE]);

  return {
    isCampaignEditable,
    canEditStorefront: (storefrontId?: number) =>
      isCampaignEditable && !!storefrontId && !!permittedStorefronts?.find(s => s.id === storefrontId),
  };
};
