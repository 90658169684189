import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { Pills } from 'app/midgarComponents';
import { FieldArray, useFormikContext } from 'formik';
import { segmentTypesDisplayValue, segmentTypesColorCodes } from 'app/features/Segments/utils';
import * as segmentTypesDisplayValues from 'app/features/Segments2/utils';
import { addIdToName } from 'app/utilities/addIdToName';
import { AudiencesFormikProps } from '../../../utilities/types/formik';
import { Segments } from 'app/types/typescript/Segments';

type Props = {
  id: string;
  name:
    | 'includedSegments'
    | 'excludedSegments'
    | 'includedSegmentsFilters'
    | 'excludedSegmentsFilters'
    | 'includedSegmentsGeoFilters'
    | 'audienceId';
  label: string;
  required?: boolean;
  selected: typeof Segments;
  segments: typeof Segments;
  segmentsFetching: boolean;
  onSelect?: Function;
  onRemove?: Function;
  fetchSegments: Function;
  typeBase: string;
  className?: string;
  audienceType?: string;
};
const Audience = (props: Props): JSX.Element => {
  const [value, setValue] = React.useState('');
  //TODO: add suggestion types
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [suggestions, setSuggestions] = React.useState<any[]>([]);

  const onFocus = (): void => {
    const { segments, typeBase, fetchSegments } = props;
    if (!segments || !segments.length) {
      fetchSegments(value, typeBase);
    }
  };

  useEffect(() => {
    //filtering based on unique id to dedupe
    const v2 = props.segments?.length ? !!props.segments?.[0]?.audienceId : !!suggestions?.[0]?.audienceId;
    setSuggestions(prevState =>
      [...new Map([...prevState, ...props.segments].map(item => [item[v2 ? 'audienceId' : 'id'], item])).values()].filter(item =>
        v2 ? item.audienceId : item.id,
      ),
    );
  }, [props.segments]); //eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const { typeBase, fetchSegments } = props;
    setValue(ev.target.value);
    if (ev.target.value.trim()) {
      fetchSegments(ev.target.value, typeBase);
    }
  };

  const { id, name, label, required, selected, segmentsFetching, onSelect, onRemove, className, audienceType } = props;

  const parentFormikContext = useFormikContext<AudiencesFormikProps>();

  const baseComponentProps = {
    id,
    name,
    required,
    label,
    limit: 25,
    suggestions: suggestions.map(addIdToName),
    loading: segmentsFetching,
    value,
    onFocus,
    onChange,
    suggestionTagTypeMapper: { ...segmentTypesDisplayValue, ...segmentTypesDisplayValues.segmentTypesDisplayValue },
    suggestionTagTypeColors: { ...segmentTypesColorCodes, ...segmentTypesDisplayValues.segmentTypesColorCodes },
  };

  const renderAudiences = (): JSX.Element => {
    if (parentFormikContext) {
      const { values, touched, errors } = parentFormikContext;
      const selectAudienceSegment = (segment: object, push: Function, pop: Function): void => {
        if (values.audienceId.length > 0) pop();
        push(segment);
      };

      const removeAudienceSegment = (segment: { id: string | number }, remove: Function): void => {
        const foundIndex = values[name].findIndex(({ id }: { id: string | number }) => id === segment.id);
        remove(foundIndex);
      };
      return (
        <FieldArray name={name}>
          {({ push, pop, remove }): JSX.Element => (
            <Pills
              {...baseComponentProps}
              onFocus={onFocus}
              error={touched[name] && errors[name]}
              selected={values[name]}
              onSelect={(segment: object): Function | void => selectAudienceSegment(segment, push, pop)}
              onRemove={(segment: { id: string | number }): Function | void => removeAudienceSegment(segment, remove)}
              isLimited={audienceType === 'V2'}
            />
          )}
        </FieldArray>
      );
    } else
      return <Pills {...baseComponentProps} selected={selected} value={value} onFocus={onFocus} onSelect={onSelect} onRemove={onRemove} />;
  };

  return (
    <Container className={className} data-qa={`audiences-component-${name}`}>
      {renderAudiences()}
    </Container>
  );
};

export default memo(Audience);

const Container = styled.section`
  display: inline-block;
  vertical-align: top;
  margin: 1rem 1rem 1rem 0;

  width: 40.5%;
  min-width: 20rem;

  &:last-child {
    margin-right: 0;
  }
`;
