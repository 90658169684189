import { State } from './reducers';

type Store = { bannerManagement: { locations: State } };

export const mapStateToLocations = ({
  bannerManagement: {
    locations: { cities, error: locationsError, loading: locationsLoading, states },
  },
}: Store) => ({
  cities,
  locationsError,
  locationsLoading,
  states,
});
