import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { withAppConfig } from 'app/decorators';
import { fetchVariantTypes as opFetchVariantTypes } from 'app/ducks/bannerManagement/variantTypes/operations';
import { mapStateToVariantTypes } from 'app/ducks/bannerManagement/variantTypes/selectors';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';

const mapDispatchToProps = {
  fetchVariantTypes: opFetchVariantTypes,
};

export default compose(
  withAppConfig,
  connect(mapStateToVariantTypes, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { appConfig, fetchVariantTypes, variantTypes, variantTypesLoading } = this.props;

      const { useVariantType } = getBannerCreativeConfig(appConfig);

      if (useVariantType && !(variantTypes || []).length && !variantTypesLoading) {
        fetchVariantTypes();
      }
    },
  }),
);
