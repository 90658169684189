import { fetch } from '../commonStates';
import * as types from './types';

const tagsInitState = {
  ...fetch,
  allIds: [],
  byId: {},
  allNames: [],
  idByName: {},
};

const tags = (state = tagsInitState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_TAGS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload.tagsLookups,
      };

    case types.GET_ALL_TAGS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case types.POST_TAGS:
      return {
        ...state,
        loading: true,
      };

    case types.POST_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.POST_TAGS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case types.GET_ALL_V2_TAGS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_V2_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload.tagsV2Lookups,
      };

    default:
      return state;
  }
};

export default tags;
