import { concat } from 'ramda';

const actionId = concat('map/features/');

const GET_FEATURES_LOADING = actionId('GET_FEATURES_LOADING');
const GET_FEATURES_SUCCESS = actionId('GET_FEATURES_SUCCESS');
const GET_FEATURES_FAILURE = actionId('GET_FEATURES_FAILURE');
const SET_REAL_TIME_SCHEMA_DATA = actionId('SET_REAL_TIME_SCHEMA_DATA');

export { GET_FEATURES_LOADING, GET_FEATURES_SUCCESS, GET_FEATURES_FAILURE, SET_REAL_TIME_SCHEMA_DATA };
