import * as React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

export const FeatureButton = ({ names = [], placeholder = 'Choose Feature', handleClick, classes }) => (
  <>
    {names !== undefined && names.length > 0 ? (
      <Button onClick={handleClick} classes={classes}>
        {names.join(' > ')}
      </Button>
    ) : (
      <Button onClick={handleClick} variant="contained" color="primary">
        {placeholder}
      </Button>
    )}
  </>
);

FeatureButton.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};
