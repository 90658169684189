import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { setCampaignField } from 'app/ducks/campaigns/campaign/actions';
import { triggerTypes, daysOfWeek } from 'app/utilities/constants';
import { Typography, Radio, RadioGroup, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

export const ValidDays = () => {
  const dispatch = useDispatch();
  const { days, triggerType } = useSelector(state => state.campaigns.campaign.general);

  const filterDays = (days, v) => (days.includes(v) ? days.filter(d => d !== v) : [...days, v]);

  const updateDays = ({ target: { value } }) => {
    dispatch(
      setCampaignField({
        days: value === 'true' ? ['*'] : value === 'false' ? [] : filterDays(days, value),
      }),
    );
  };

  if (triggerType === triggerTypes.oneTime) {
    return null;
  }

  return (
    <Section data-qa="valid-days-component">
      <Typography style={{ marginRight: '1rem' }}>Repeats</Typography>
      <RadioGroup row aria-label="days" name="days" value={(!!(days[0] && days[0] === '*')).toString()} onChange={updateDays}>
        <FormControlLabel value="true" control={<Radio color="primary" />} label="Everyday" />
        <FormControlLabel value="false" control={<Radio color="primary" />} label="Custom" />
      </RadioGroup>
      {!days[0] || days[0] !== '*' ? (
        <FormGroup row>
          {daysOfWeek.map(d => (
            <FormControlLabel key={d} control={<Checkbox checked={days.includes(d)} value={d} onChange={updateDays} />} label={d} />
          ))}
        </FormGroup>
      ) : null}
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  min-height: 48px;
  align-items: center;
  margin-bottom: 1rem;
`;
