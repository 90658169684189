import * as React from 'react';
import { PropTypes } from 'prop-types';
import Calendar from 'rc-calendar';
import Picker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import { TextField } from '@material-ui/core';

import { momentFromDateStr, momentToDateStr } from 'app/utilities/date';
import { defaultDateFormat } from 'configs/dateTime';

export const DateField = ({ label = '', placeholder = '', value = '', setValue, name, classes = {}, ...otherProps }) => (
  <Picker
    animation="slide-up"
    calendar={<Calendar onSelect={m => setValue(momentToDateStr(m), name)} format={defaultDateFormat} dateInputPlaceholder={placeholder} />}
    value={momentFromDateStr(value)}
  >
    {() => <TextField label={label} value={value} className={classes.textField} {...otherProps} />}
  </Picker>
);

DateField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    textField: PropTypes.string,
  }),
};
