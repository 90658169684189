import { compose } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import { ReadOnlyOrRuleGroup } from './ReadOnlyOrRuleGroup';

const addStyles = withStyles(styles);

const enhance = compose(addStyles);

export default enhance(ReadOnlyOrRuleGroup);
