import { IBannerCampaignCreativeRaw } from 'app/types/Creatives/BannerCreative';
import { IRuleCriterion } from 'app/features/Cashback/types';

export type State = IBannerCampaignCreativeRaw & {
  error: string | null | undefined;
  valid: boolean;
  bannerRealTimeUserCriteria: {
    userCriteria: string | null;
    userCriteriaJson: Array<IRuleCriterion>;
  };
};

export const initialState: State = {
  banners: [],
  error: null,
  source: {
    targets: [{}],
  },

  bannerRealTimeUserCriteria: {
    userCriteria: null,
    userCriteriaJson: [],
  },

  valid: false,
};
