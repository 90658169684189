import * as React from 'react';
import styled from 'styled-components';

import { sc } from 'app/styles';
import _Input from 'app/midgarComponents/Input';

export type Props = {
  onChange: (...args: Array<any>) => any;
  handleFocus?: (...args: Array<any>) => any;
  debounceTime?: number;
  value?: string;
  name?: string;
};

export type State = {
  value: string;
};

export default class DebounceInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    debounceTime: 0,
  };

  timeoutCall = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value || '',
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props;

    // TODO: Called setState() in componentDidUpdate() is an anti-pattern
    if (prevProps.value !== value) {
      this.setState(() => ({ value })); // eslint-disable-line react/no-did-update-set-state
    }
  }

  notifyListener = (value: string) => {
    const { onChange } = this.props;
    return onChange(value);
  };

  // debouncedCallback = (value: string) => debounce(this.notifyListener, this.props.debouncetime)({ value }); // eslint-disable-line react/destructuring-assignment
  debouncedCallback = (value: string) => {
    if (this.timeoutCall) {
      clearTimeout(this.timeoutCall);
    }
    this.timeoutCall = setTimeout(() => this.notifyListener(value), this.props.debounceTime);
  };

  handleChange = ({ target: { value } }) => {
    this.setState(() => ({ value }), this.debouncedCallback(value));
  };

  handleFocus = ({ target: { value } }) => {
    const { handleFocus } = this.props;

    if (typeof handleFocus === 'function') {
      handleFocus(value);
    }
  };

  render() {
    const { value } = this.state;
    const { name } = this.props;

    return (
      <Input
        autoComplete="off"
        className="debounce-input"
        name={name}
        value={value}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
      />
    );
  }
}

export const Input = styled(_Input).attrs(({ name }) => ({
  'data-qa': `${name}-debounce-input`,
}))`
  height: 4.5rem;
  padding: 0 1rem;
  margin-bottom: 0;
  &::placeholder {
    color: ${sc.subHeadingColor};
  }
`;
