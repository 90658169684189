import React from 'react';
import styled from 'styled-components';

import { sc } from 'app/styles';
import { ISuggestion } from 'app/types';

import { handleSelectSuggestion, toSuggestion, toTitle } from '../utils';
import { checkStorefront } from '../Creation/BannerSelection/utils';
import { AutocompleteWithDisabled, ErrorContainer } from './components';

type Props = {
  className?: string;
  disabled?: boolean;
  onSelectStorefront: (arg0: ISuggestion) => unknown;
  permittedStorefronts: Array<ISuggestion> | null | undefined;
  storefrontId?: number;
  storefronts: Array<ISuggestion> | null | undefined;
  storefrontsError?: string;
  storefrontsLoading?: boolean;
};

export default ({
  className,
  disabled,
  onSelectStorefront,
  permittedStorefronts,
  storefrontId,
  storefronts,
  storefrontsError,
  storefrontsLoading,
}: Props) => {
  const storefrontSuggestions = permittedStorefronts?.map(toSuggestion) || [];
  const { isStorefrontPermitted, selectedStorefront, storefrontExists } = checkStorefront({
    permittedStorefronts,
    storefrontId,
    storefronts,
  });

  let storefrontsErrorMsg;
  if (!storefrontsLoading) {
    if (storefrontsError) {
      storefrontsErrorMsg = storefrontsError;
    } else if (!storefronts?.length) {
      storefrontsErrorMsg = 'No storefronts retrieved';
    } else if (!permittedStorefronts?.length) {
      storefrontsErrorMsg = 'No storefronts permitted';
    }
  }

  return (
    <div className={className}>
      <AutocompleteWithDisabled
        disabled={disabled}
        error={!!storefrontsErrorMsg || (storefrontId && !storefrontExists)}
        id="storefront"
        label="Storefront"
        name="storefront"
        onSelect={handleSelectSuggestion(storefronts, onSelectStorefront)}
        required
        suggestions={storefrontSuggestions}
        value={selectedStorefront ? toTitle(selectedStorefront) : String(storefrontId || '')}
      />

      {storefrontsErrorMsg ? (
        <ErrorContainer>{storefrontsErrorMsg}</ErrorContainer>
      ) : storefrontExists ? (
        !isStorefrontPermitted && <NotPermitted>Storefront {storefrontId}: Access denied</NotPermitted>
      ) : (
        !!storefrontId && <ErrorContainer>Storefront {storefrontId} does not exist</ErrorContainer>
      )}
    </div>
  );
};

const NotPermitted = styled.div`
  font-style: italic;
  padding: ${sc.gutterSmall};
`;
