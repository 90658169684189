import React from 'react';
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

import { isNeitherNilNorEmpty, R } from 'app/helpers/RamdaHelpers/helpers';

import { addComponentPropsIf } from 'app/helpers/ComponentHelpers/helpers';

const ASC = 'asc';
const DESC = 'desc';

const sortHandler = (handleSort, currentSortOrder, currentSortColumn, newSortColumn, sorter) => event => {
  const newSortOrder = currentSortColumn === newSortColumn ? (currentSortOrder === ASC ? DESC : ASC) : ASC;

  handleSort(newSortOrder, newSortColumn, sorter, event);
};

export const SortableTableHeader = ({ columns, sortOrder = ASC, sortColumn = '', handleSort, canExpandRow, classes, style = {} }) => (
  <TableHead>
    <TableRow>
      {R.map(({ key, label, onSort, isNumeric, style: headerStyle }) => {
        // withPadding,
        const canSort = isNeitherNilNorEmpty(handleSort);
        const isSorted = sortColumn === key;

        return (
          <TableCell
            key={key}
            align={isNumeric ? 'right' : 'inherit'}
            className={classes.tableCell}
            {...addComponentPropsIf(isNeitherNilNorEmpty(headerStyle), { style: headerStyle })}
            // padding={withPadding ? 'default' : 'none'}
            // padding="default"
            {...addComponentPropsIf(isNeitherNilNorEmpty(style), {
              style,
            })}
            {...addComponentPropsIf(canSort, {
              sortDirection: isSorted ? sortOrder : false,
            })}
          >
            {canSort ? (
              <TableSortLabel active={isSorted} direction={sortOrder} onClick={sortHandler(handleSort, sortOrder, sortColumn, key, onSort)}>
                {label}
              </TableSortLabel>
            ) : (
              label
            )}
          </TableCell>
        );
      }, columns)}
      {canExpandRow && <TableCell />}
    </TableRow>
  </TableHead>
);

SortableTableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortOrder: PropTypes.string,
  sortColumn: PropTypes.string,
  handleSort: PropTypes.func,
  canExpandRow: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  style: PropTypes.objectOf(PropTypes.string),
};
