import update from 'immutability-helper';

import { Action } from 'app/types';

import { State } from '../state';
import { initialState } from '../state';
import * as types from './types';
import { validate } from '../validation';

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.ADD_TARGET:
      return validate(
        update(state, {
          source: {
            targets: { $push: [{}] },
          },
        }),
      );

    case types.REMOVE_TARGET: {
      const newTargets = (state.source.targets || []).slice();
      newTargets[payload.index] = null;

      return validate(
        update(state, {
          source: {
            targets: { $set: newTargets },
          },
        }),
      );
    }

    case types.SET_SOURCE_CREATIVE:
      return validate(
        update(state, {
          source: {
            creative: { $set: payload.creative },
          },
        }),
      );

    case types.SET_TARGET_STOREFRONT: {
      const targets = state.source.targets || [];
      const { index, storefront } = payload;

      const newTargets = targets.slice();
      newTargets[index] = {
        ...targets[index],
        storefront,
      };

      return validate(
        update(state, {
          source: {
            targets: { $set: newTargets },
          },
        }),
      );
    }

    case types.SET_TARGET_VIEWS: {
      const targets = state.source.targets || [];
      const { index, views } = payload;

      const newTargets = targets.slice();
      newTargets[index] = {
        ...targets[index],
        views,
      };

      return validate(
        update(state, {
          source: {
            targets: { $set: newTargets },
          },
        }),
      );
    }

    case types.SET_VALID_FROM:
      return update(state, {
        source: {
          validFrom: { $set: payload.validFrom },
        },
      });

    case types.SET_VALID_UPTO:
      return update(state, {
        source: {
          validUpto: { $set: payload.validUpto },
        },
      });

    default:
      return state;
  }
};
