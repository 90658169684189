import * as React from 'react';
import PropTypes from 'prop-types';

const defaultValue = `
<html>
  <head></head>
  <body></body>
</html>
`;

export const HTMLEditor = ({ value = defaultValue, change, classes }) => (
  <textarea className={classes.editor} value={value} onChange={change} />
);

HTMLEditor.propTypes = {
  value: PropTypes.string,
  change: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    editor: PropTypes.string,
  }).isRequired,
};
