import { compose, withHandlers } from 'recompose';
import sleep from 'app/utilities/sleep';

const mockResult = {
  status: 'SUCCESSFUL',
  url: 'http://PLACEHOLDER_URL',
  rows: 0,
};

export const MockImageUpload = compose(
  withHandlers({
    apiFileUpload: () => async () => {
      await sleep();
      return mockResult;
    },
  }),
);
