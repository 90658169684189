import * as React from 'react';
import styled from 'styled-components';
import { Menu, MenuItem } from '@material-ui/core';

import { Checkbox, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IViewItem } from 'app/types/BannerManagement';

const isViewItemActive = (viewItem: IViewItem) => {
  const { variants } = viewItem;
  const variant = (variants || [])[0];
  return !!variant?.status;
};

type Props = {
  className?: string;
  onSelect: (arg0: Array<IViewItem>) => void;
  selected?: Array<IViewItem>;
  slotViewItems: Array<IViewItem>;
};

type State = {
  anchorEl: React.ReactNode;
};

export default class MultiSelectCheckbox extends React.PureComponent<Props, State> {
  state = {
    anchorEl: null,
  };

  isAllSelected = () => {
    const { selected, slotViewItems } = this.props;
    return slotViewItems.length > 0 && slotViewItems.length === (selected || []).length;
  };

  onClick = (viewItems: Array<IViewItem>) => () => {
    const { onSelect } = this.props;
    onSelect(viewItems);
    this.setState({ anchorEl: null });
  };

  onClickActive = () => {
    const { slotViewItems } = this.props;
    const activeViewItems = slotViewItems.filter(isViewItemActive);
    this.onClick(activeViewItems)();
  };

  onClickInactive = () => {
    const { slotViewItems } = this.props;
    const inactiveViewItems = slotViewItems.filter(item => !isViewItemActive(item));
    this.onClick(inactiveViewItems)();
  };

  onSelectCheckbox = () => {
    const { onSelect, slotViewItems } = this.props;

    if (this.isAllSelected()) {
      onSelect([]);
    } else {
      onSelect([...slotViewItems]);
    }
  };

  render() {
    const { anchorEl } = this.state;

    const { className, slotViewItems } = this.props;

    return (
      <Container className={className}>
        <Checkbox checked={this.isAllSelected()} name="select-all-checkbox" onChange={this.onSelectCheckbox} />

        <Icon color={sc.grey} name="chevron-down" onClick={ev => this.setState({ anchorEl: ev.target })} size={24} />

        {anchorEl && (
          <Menu anchorEl={anchorEl} onClose={() => this.setState({ anchorEl: null })} open={!!anchorEl}>
            <MenuItem onClick={this.onClick([])}>None</MenuItem>

            <MenuItem onClick={this.onClickActive}>Active</MenuItem>

            <MenuItem onClick={this.onClickInactive}>Inactive</MenuItem>

            <MenuItem onClick={this.onClick(slotViewItems)}>All</MenuItem>
          </Menu>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
`;
