import * as React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';

export const EmptyTable = ({ columns }) => (
  <TableRow>
    <TableCell style={{ textAlign: 'center' }} colSpan={columns.length}>
      No records found
    </TableCell>
  </TableRow>
);

EmptyTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};
