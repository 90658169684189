import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, Grid, Select, MenuItem } from '@material-ui/core';

import RuleTextEdit from 'app/components/RuleTextEdit';

import { R } from 'app/helpers/RamdaHelpers/helpers';

const toMenuItem = R.curry((classes, selected, { label, value }) => (
  <MenuItem key={value} value={value} className={selected === value ? classes.boldFont : classes.regularFont}>
    {label}
  </MenuItem>
));

const inputProps = id => ({
  name: id,
  id,
});

export const EditWithDropdown = ({
  id,
  label,
  dataType,
  inputValue,
  validator,
  handleChangeInput,
  options,
  optionValue,
  handleChangeOption,
  classes,
}) => (
  <FormControl className={classes.formControl}>
    <Grid container direction="row" alignItems="flex-end" justifyContent="flex-start">
      <RuleTextEdit
        id={`${id}-input`}
        label={label}
        dataType={dataType}
        value={inputValue}
        validator={validator}
        onChange={handleChangeInput}
        handleChange={handleChangeInput}
        classes={classes}
        style={{
          width: 60,
        }}
      />

      <Select value={optionValue} onChange={handleChangeOption} inputProps={inputProps(`${id}-option`)} autoWidth>
        {R.map(toMenuItem(classes, optionValue), options)}
      </Select>
    </Grid>
  </FormControl>
);

EditWithDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dataType: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  validator: PropTypes.func.isRequired,
  handleChangeInput: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionValue: PropTypes.string,
  handleChangeOption: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
