/* eslint-disable camelcase */

import qs from 'query-string';

import { IMasterplanApiParams, IPrioritiesApiParams } from './types';

export default {
  priorities: (params: IPrioritiesApiParams) =>
    `/management/priorities/${params.date}/${params.medium}/${params.trigger}?${qs.stringify({ preview_plan: params.previewPlan })}`,
  executions: (params: IMasterplanApiParams) => `/management/v2/executions/${params.date}/status?channel=${params.medium}`,
};
