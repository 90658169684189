import { compose, withHandlers, withProps, withState } from 'recompose';
import { isEmpty, omit, pick } from 'ramda';

import { withAppConfig } from 'app/decorators';
import { IQueryParams } from 'app/types';
import { asSearchDefs } from './utils';
import { ISearchDefs, SearchDefsArg } from './types';

const searchParamNames = (searchDefs: ISearchDefs) => searchDefs.map(def => def.name);
const searchParams = (searchDefs: ISearchDefs, queryParams: IQueryParams) => pick(searchParamNames(searchDefs), queryParams);

/**
 * Extract the search parameters from `queryParams` based on the given search definitions.
 *
 * If there are no search parameters, then the search is set to the default.
 *
 * If a default search has been defined, then in order to have a blank search, at least one empty search parameter must be specified.
 * Example: `{ name: null }`
 *
 * If this default behaviour is not satisfactory, this HOC should be modified.
 *
 * @param {SearchDefsArg} searchDefs The search definitions.
 * @param {Function} fnDefaultSearch Function which generates the default search from the incoming `props`.
 */
const handleSearchParams = (searchDefiner: SearchDefsArg, fnDefaultSearch?: (...args: Array<any>) => any) =>
  withProps(props => {
    const searchDefs = asSearchDefs(searchDefiner, props);
    let searchQueryParams = searchParams(searchDefs, props.queryParams);
    // TODO: The default is always set if we clear all search params. Should only be set the first time.
    if (isEmpty(searchQueryParams) && fnDefaultSearch) {
      searchQueryParams = fnDefaultSearch(props);
      // Ensure that the default search isn't also empty, or else we get an infinite loop generating the default search
      if (!isEmpty(searchQueryParams)) {
        const newQueryParams = {
          ...props.queryParams,
          ...searchQueryParams,
        };

        props.pushQuery(newQueryParams);
        return {
          queryParams: newQueryParams,
          searchQueryParams,
        };
      }
    }
    return { searchQueryParams };
  });

const addSearchState = withState('search', 'setSearchState', ({ searchQueryParams }) => searchQueryParams);

const addPush = (searchDefiner: SearchDefsArg) =>
  withHandlers({
    pushSearch: props => searchQueryParams => {
      const { pushQuery, queryParams, setSearchState } = props;
      const searchDefs = asSearchDefs(searchDefiner, props);
      let otherQueryParams = omit(searchParamNames(searchDefs), queryParams);
      const { creator, ...queryParamsSet } = searchQueryParams;

      if (creator) {
        queryParamsSet['searchBy'] = 'creator';
        queryParamsSet['searchTerm'] = creator;
        searchQueryParams = queryParamsSet;
      }

      if (typeof creator !== 'undefined' && creator.length === 0) {
        const { searchBy, searchTerm, ...otherQueryParamsSet } = otherQueryParams;
        if (searchBy === 'creator') {
          delete queryParamsSet['searchBy'];
          delete queryParamsSet['searchTerm'];
        }
        searchQueryParams = queryParamsSet;
        otherQueryParams = otherQueryParamsSet;
      }
      if (!window.location.pathname.includes('/audience')) {
        delete searchQueryParams['searchBy'];
        delete searchQueryParams['searchTerm'];
      }
      setSearchState(searchQueryParams);
      pushQuery({
        ...otherQueryParams,
        ...searchQueryParams,
      });
    },
  });

/**
 * Search behaviour.
 *
 * Injects the following properties:
 *
 *  - `pushSearch: IQueryParams => void` = Execute the given search.
 *    This pushes the given search parameters to the report state, which in turn triggers a fetching of the records.
 *    Note that since it implicitly updates the state, there is no need to call `setSearchState()`.
 *
 *  - `search: IQueryParams` = Local state of the search, for controlled components.
 *    This allows criteria to be set without immediately executing the search, for example when entering text search.
 *
 *  - `setSearchState: IQueryParams => void` = Set the local state of the search.
 *    It does not execute the search. In order to execute a search, call `pushSearch()` instead.
 *
 *  - `searchQueryParams: IQueryParams` = Search parameters, which are a subset of the incoming `queryParams`.
 */
export default (searchDefiner: SearchDefsArg, fnDefaultSearch?: (...args: Array<any>) => any) =>
  compose(withAppConfig, handleSearchParams(searchDefiner, fnDefaultSearch), addSearchState, addPush(searchDefiner));

export * from './types';
