import { connect } from 'react-redux';

import * as actions from 'app/ducks/campaigns/campaign/push2Creative/actions';

const mapStateToProps = ({
  campaigns: {
    campaign: {
      push2Creative,
      general: { expiryDate, expiryTime },
    },
  },
}) => {
  return {
    ...push2Creative,
    general: {
      expiryDate,
      expiryTime,
    },
  };
};

const push2Connect = connect(mapStateToProps, actions);

export const ConnectedPush2 = push2Connect(({ children, ...props }) => children && <>{children(props)}</>);
