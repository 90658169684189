import { withStateHandlers, withHandlers, withProps, compose } from 'recompose';

import { unixTimeWithMillis, isOneElementArray, isNilOrEmpty, R } from 'app/helpers/RamdaHelpers/helpers';

import { BreadcrumbMenu } from './BreadcrumbMenu';

const autoAdvance = (optionsBySelections, selections, state) => {
  const options = optionsBySelections(selections);

  return isOneElementArray(options)
    ? autoAdvance(
        optionsBySelections,
        [...selections, options[0]],
        [
          ...state,
          {
            options,
            selected: options[0],
          },
        ],
      )
    : [selections, state];
};

const expand = (seed, optionsBySelections, selectionsToProcess, selectionsToKeep = [], currentState = [], usingAutoAdvance = true) => {
  const selections = R.concat(selectionsToKeep, selectionsToProcess);
  if (isNilOrEmpty(selections)) {
    return seed;
  }

  const expanded = [
    ...currentState,
    ...R.last(
      R.mapAccum(
        (acc, selected) => [
          [...acc, selected],
          {
            selected,
            options: optionsBySelections(acc),
          },
        ],

        selectionsToKeep,
        selectionsToProcess,
      ),
    ),
  ];

  const [newSelections, newExpanded] = usingAutoAdvance ? autoAdvance(optionsBySelections, selections, expanded) : [selections, expanded];

  const options = optionsBySelections(newSelections);
  const exhausted = isNilOrEmpty(options);

  return {
    expanded: [...newExpanded, ...(exhausted ? [] : [{ options }])],
    exhausted,
  };
};

const addProps = withProps(({ initialOptions = [] }) => ({
  seed: {
    expanded: [{ options: initialOptions }],
    exhausted: false,
  },
}));

const addHandlers = withHandlers({
  initializeState:
    ({ seed, optionsBySelections }) =>
    (paths = []) => ({
      breadcrumbState: expand(seed, optionsBySelections, paths).expanded,
      updatedAt: unixTimeWithMillis(),
    }),

  equals:
    ({ idOf }) =>
    (a, b) =>
      R.apply(R.equals, R.map(idOf, [a, b])),
  updateState:
    ({ seed, optionsBySelections }) =>
    (index, selected, currentState, usingAutoAdvance = true) => {
      const state = R.take(index, currentState);

      return expand(seed, optionsBySelections, [selected], R.pluck('selected', state), state, usingAutoAdvance);
    },
});

const addStateHandlers = withStateHandlers(({ initializeState, selections }) => initializeState(selections), {
  handleSelect:
    ({ breadcrumbState, ...others }, { updateState, onFeatureSelected, initializeState }) =>
    (index, selected) => {
      const { expanded, exhausted } = updateState(index, selected, breadcrumbState);

      if (exhausted) {
        const newSelections = R.pluck('selected', expanded);
        // todo: improve this - no R.last assumption
        onFeatureSelected(R.last(newSelections));

        return initializeState(newSelections);
      }

      return {
        ...others,
        breadcrumbState: expanded,
        updatedAt: unixTimeWithMillis(),
      };
    },
});

const enhance = compose(addProps, addHandlers, addStateHandlers);

export default enhance(BreadcrumbMenu);
