import { fetch } from '../commonStates';
import * as types from './types';

const categoriesInitState = {
  ...fetch,
  allIds: [],
  byId: {},
};

const categories = (state = categoriesInitState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        ...payload.categoryLookups,
        loading: false,
      };

    case types.GET_ALL_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};

export default categories;
