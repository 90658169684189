import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import App from './app/App';
import { configureStore } from './configs/store';

const history = createBrowserHistory();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <Provider store={configureStore(history)}>
    <App history={history} />
  </Provider>,
);
