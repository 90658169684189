import { Action } from 'app/types';

import { IFetch } from '../utils';
import { baseFetch } from '../utils';
import * as types from './types';

export type State = IFetch & {
  gaTypes: Array<string>;
};

const initialState: State = {
  ...baseFetch,
  gaTypes: [],
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_GA_TYPES_FAILED:
      return {
        ...state,
        error: payload.error,
        loading: false,
        gaTypes: [],
      };

    case types.GET_GA_TYPES_START:
      return {
        ...state,
        error: null,
        loading: true,
        gaTypes: [],
      };

    case types.GET_GA_TYPES_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        gaTypes: payload.gaTypes,
      };

    default:
      return state;
  }
};
