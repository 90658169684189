import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

import { withAppConfig } from 'app/decorators';
import { CampaignTable } from './CampaignTable';
import { styles } from './styles';

const enhance = compose(
  withAppConfig,
  connect(({ user }) => ({ user })),
  withProps({
    isLastPage: true, // TODO: Suppress LoadMore rendering until table is reworked
    isMoreLoading: false, // TODO: Remove isMoreLoading
  }),
  withHandlers({
    getMoreCampaigns: () => () => {
      /* no-op */
    }, // TODO: Remove getMoreCampaigns
  }),
  // TODO: Implement msgIfEmpty in CampaignTable
  withStyles(styles, { withTheme: true }),
);

export default enhance(CampaignTable);
