import { IEvent, IEventVariable } from 'app/types/typescript/SchemaRegistry';

export const fromApiEvents = (response: { data: Array<typeof IEvent> }): Array<typeof IEvent> => {
  const data = (response && response.data) || [];

  return data.map(ev => ({
    eventId: ev.eventId || '',
    eventName: ev.eventName || '',
    eventDisplayName: ev.eventDisplayName || '',
    categoryLevel: ev.categoryLevel ? Number(ev.categoryLevel) : null,
    geoLocationSupported: !!ev.geoLocationSupported,
    expirySupported: !!ev.expirySupported,
    validTimeSupported: !!ev.validTimeSupported,
    delaySupported: !!ev.delaySupported,
  }));
};

// TODO: Remove this map when API changes structure of schema
export const fromApiEventSchema = (variables: Array<typeof IEventVariable>): Array<typeof IEventVariable> => {
  if (!Array.isArray(variables)) {
    return [];
  }
  return variables.map(variable => {
    const { possibleOperators, possibleValues, dataType, label, name } = variable;
    const transformedVariable = variable;
    transformedVariable.promoEventFieldType = {
      operators: Array.isArray(possibleOperators) ? possibleOperators.join(',') : '',
      name: dataType,
    };
    transformedVariable.possibleValues = Array.isArray(possibleValues) && possibleValues.length ? possibleValues.join(',') : null;
    transformedVariable.name = label || name || '';
    return transformedVariable;
  });
};
