import React, { PureComponent } from 'react';
import { Publish as FileUploadIcon } from '@material-ui/icons';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { displayError, displayWarning } from 'app/helpers/NotificationHelpers/helpers';
import { Dropzone, Tooltip } from 'app/components';

type Props = {
  onChange: (...args: Array<any>) => any;
};

export default class CsvUpload extends PureComponent<Props> {
  onDrop = (acceptedFiles: Array<File>) => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      displayError('Error: File was rejected. Please try again');
      return;
    }

    const file = acceptedFiles[0];
    if (acceptedFiles.length > 1) {
      displayWarning(`Uploaded ${acceptedFiles.length} files, but can only accept one. Will use ${file.name}`); // eslint-disable-line no-console
    }

    const reader = new FileReader();
    reader.onload = ev => this.onSuccess(file, ev.target.result);
    reader.onerror = () => displayError(`Failed to read ${file.name}`);
    reader.readAsText(file);
  };

  parseCsv = (content: string) =>
    content
      .split(/,|\n/)
      .map(str => str.trim())
      .filter(str => str !== '')
      .join(',');

  onSuccess = (file: File, contents: string) => {
    const { onChange } = this.props;
    const result = this.parseCsv(contents);
    if (!result || !result.length) {
      displayError(`${file.name} was empty.`);
    }
    onChange(result);
  };

  render() {
    return (
      <Tooltip title="Click to upload a csv file.">
        <Section>
          <Dropzone
            style={{
              minHeight: 'auto',
              padding: '14px',
            }}
            accept=".csv"
            onDropAccepted={this.onDrop}
          >
            <FileUploadIcon />
          </Dropzone>
        </Section>
      </Tooltip>
    );
  }
}

const Section = styled.div`
  margin: 0;
  background: ${sc.tertiary};

  &:hover {
    cursor: 'pointer';
  }
`;
