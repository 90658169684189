import * as api from 'app/api/features';
import { Action, DispatchFn, IQueryParams } from 'app/types';

import * as actions from './actions';

export const getFeatures =
  (params: IQueryParams) =>
  async (dispatch: DispatchFn): Action => {
    dispatch(actions.getFeatures());

    try {
      const { content: features, first, last, number, size, totalElements } = await api.getFeaturesPaged(params);

      const paged = {
        first,
        last,
        number,
        size,
        totalElements,
      };

      return dispatch(actions.getFeaturesSuccess(features, paged));
    } catch (e) {
      return dispatch(actions.getFeaturesFail(e));
    }
  };
