import { CAMPAIGN_BANNER_CREATE, CAMPAIGN_BANNER_DRAFT } from 'configs/permissions';
import { bannerMediumId } from 'configs/channels/mediumIds';
import { IChannelConfig } from 'configs/channels/types';
import { triggerTypes, recurringTrigger, locationTrigger } from 'app/utilities/constants';
import { PAYPAY_CONSUMER } from 'configs/apps/tenants/PAYPAY_CONSUMER';
import { getSelectedTenant } from 'configs/user';

import BannerCreation from './Creation';
import BannerOverview from './Overview';

import FullSchedule from '../Campaigns/Schedule/FullSchedule';
import preCampaignCreation from './preCampaignCreation';
import UserCriteria from './UserCriteria';

export default {
  createPermission: [CAMPAIGN_BANNER_CREATE],
  creative: 'bannerCreative',
  defaultTrigger: triggerTypes.recurring,
  icon: 'star',
  mediumId: bannerMediumId,
  name: 'Banner',
  permission: [CAMPAIGN_BANNER_CREATE, CAMPAIGN_BANNER_DRAFT],
  preCampaignCreation,
  requiredFields: [],
  selected: false,
  triggers: [recurringTrigger, locationTrigger],
  CreationComponent: BannerCreation,
  OverviewComponent: BannerOverview,
  ScheduleComponent: FullSchedule,
  UserCriteria: getSelectedTenant() === PAYPAY_CONSUMER && UserCriteria,
} as IChannelConfig;
