import { emailMediumId } from 'configs/channels/mediumIds';
import * as fetch from 'app/utilities/http';

import endpoints from './endpoints';
import { stripCarousel } from './utils';

export const minimalEmailCampaign = ({ html, subject, sender, senderName, replyTo, replyToName }) => {
  const creative = { html, subject, sender, senderName, replyTo, replyToName };
  if (!senderName || !senderName.length) delete creative.senderName;
  if (!replyTo || !replyTo.length) delete creative.replyTo;
  if (!replyToName || !replyToName.length) delete creative.replyToName;
  return { creative, mediumId: emailMediumId };
};

const asDto = (campaign, targetEmails) => ({ campaign, targetUsers: targetEmails });

/**
 * Sample return value (for 2 targets):
 *
 * ```
 * { status: { attemptedRecipients: 2 } }
 * ```
 */
export default async (campaign, targetEmails) => fetch.post(endpoints.testDelivery, asDto(stripCarousel(campaign), targetEmails));
