import moment from 'moment';

export const disablePastDates = currentDate => {
  if (!currentDate) {
    return false;
  }
  const today = moment().startOf('day');
  const copyCurrentDate = currentDate.clone();
  return copyCurrentDate.startOf('day').isBefore(today);
};
