import * as types from './types';

export const getTagsFailed = (error: string) => ({
  type: types.GET_TAGS_FAILED,
  payload: { error },
});

export const getTagsStart = () => ({
  type: types.GET_TAGS_START,
});

export const getTagsSuccess = (tags: Array<string>) => ({
  type: types.GET_TAGS_SUCCESS,
  payload: { tags },
});
