import { Component } from 'react';
import { getBoundingClientRect } from './dom';

const UP = 'up';
const DOWN = 'down';
const LEFT = 'left';
const RIGHT = 'right';
const NONE = 'none';

function getDirection(dragIndex: number, hoverIndex: number, coords: Record<string, any>) {
  if (coords.linear) {
    if (dragIndex < hoverIndex && coords.clientX < coords.middleX) {
      return RIGHT;
    }

    if (dragIndex > hoverIndex && coords.clientX > coords.middleX) {
      return LEFT;
    }
  } else {
    if (dragIndex < hoverIndex && coords.clientY < coords.middleY) {
      return DOWN;
    }

    if (dragIndex > hoverIndex && coords.clientY > coords.middleY) {
      return UP;
    }
  }

  return NONE;
}

export function moveWhenStopped(props: Record<string, any>, monitor: any, component: Component<any, any>, displayType = 'vertical') {
  const { index: hoverIndex, onMove } = props;
  const { index: dragIndex, stack } = monitor.getItem();
  const isSelf = dragIndex === hoverIndex;

  if (!isSelf) {
    const hoverBoundingRect = getBoundingClientRect(component);

    if (hoverBoundingRect) {
      const clientOffset = monitor.getClientOffset();
      const coords = {
        clientX: clientOffset.x - hoverBoundingRect.left,
        clientY: clientOffset.y - hoverBoundingRect.top,
        middleX: (hoverBoundingRect.right - hoverBoundingRect.left) / 2,
        middleY: (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2,
        linear: displayType === 'linear',
      };

      if (getDirection(dragIndex, hoverIndex, coords) === NONE) {
        onMove(dragIndex, hoverIndex, stack);
        monitor.getItem().index = hoverIndex; // eslint-disable-line
      }
    }
  }
}
