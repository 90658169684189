import * as React from 'react';

import OptionsSwitch from '.';

export const creatorMy = 'my';
export const creatorAll = 'all';

const options = [
  { value: creatorMy, label: 'My' },
  { value: creatorAll, label: 'All' },
];

type Props = {
  value: 'all' | 'my';
  handleSelectAll: () => void;
  handleSelectMy: () => void;
};

export class CreatedBySwitch extends React.PureComponent<Props> {
  handleChange = (newValue: any) => {
    const { handleSelectMy, handleSelectAll } = this.props;

    return newValue === creatorMy ? handleSelectMy() : handleSelectAll();
  };

  render() {
    const { value } = this.props;

    return <OptionsSwitch options={options} value={value} handleChange={this.handleChange} />;
  }
}
