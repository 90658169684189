import React from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignState } from '../types/typescript/State';

type Values = {
  creative?: {};
};

type formikConfigs = {
  initialValueSelector: (state: CampaignState) => Values;
  submitForm: (values: Values, dispatch: Function) => void;
  validationSchema: Function;
};

const defaultFormikConfigs = {
  initialValueSelector: (): Values => ({}),
  validationSchema: Yup.object().shape({}),
  submitForm: (): void => {
    // do nothing
  },
};

const useFormikConfigs = (configs: formikConfigs): object => {
  const dispatch = useDispatch();

  const formikConfigs = configs || defaultFormikConfigs;

  const submitForm = React.useCallback(
    (values: unknown) => {
      return formikConfigs.submitForm(<Values>values, dispatch);
    },
    [dispatch, formikConfigs],
  );

  const initialValues = useSelector(formikConfigs.initialValueSelector);

  return {
    validationSchema: formikConfigs.validationSchema,
    submitForm,
    initialValues,
  };
};

export default useFormikConfigs;
