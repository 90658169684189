import { connect } from 'react-redux';
import { compose } from 'recompose';

import { usersOperations, usersSelectors } from '../../../ducks/users';
import withReporting from 'app/hocs/withReporting';
import withSearchBar from 'app/hocs/withReporting/withSearchBar';
import { Reporting } from './Reporting';
import { searchBarDefs, searchDefs } from './search.config';

const mapStateToProps = state => ({
  users: usersSelectors.users(state),
  error: usersSelectors.error(state),
  isLoading: usersSelectors.loading(state),
});

const mapDispatchToProps = {
  deleteUser: usersOperations.deleteUser,
  fetchUsers: usersOperations.getUsersPaged,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withReporting('fetchUsers', usersSelectors.paging, { searchDefs }),
  withSearchBar(searchBarDefs),
)(Reporting);
