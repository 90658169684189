import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { FormControlLabel, RadioGroup as _RadioGroup, Radio } from '@material-ui/core';

import { validateFulfillment } from 'app/ducks/campaigns/campaign/cashbackCreative/validation';
import { fulfillmentTypes } from 'app/features/Cashback/constants';
import { Input as _Input, Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';

import { IFulfillment } from './types';

type Props = IFulfillment & {
  updateFulfillment: (arg0: Record<string, string>) => any;
  updateFulfillmentType: (arg0: string) => any;
};

export class Fulfillment extends PureComponent<Props> {
  handleChange = ({ target: { name, value } }) => {
    const { updateFulfillment } = this.props;
    updateFulfillment({
      [name]: value,
    });
  };

  handleFulfillmentTypeChange = ({ target: { value } }) => {
    const { updateFulfillmentType } = this.props;
    updateFulfillmentType(value);
  };

  validate = () => validateFulfillment(this.props).length === 0;

  render() {
    const { type, numMonths, offsetDays, dayOfMonth } = this.props;

    return (
      <Section>
        <Label>
          Cashback should dispense
          {this.validate() ? (
            <ValidationIcon name="check-circle" size={22} color={sc.success} />
          ) : (
            <ValidationIcon name="warning" size={22} color={sc.warning} />
          )}
        </Label>

        <Wrapper>
          <RadioGroup
            aria-label="Fulfillment"
            name="type"
            value={type}
            onChange={this.handleFulfillmentTypeChange}
            style={{ display: 'inline-block' }}
          >
            <FormControlLabel value={fulfillmentTypes.instant} control={<Radio />} label="Immediately" />
            <FormControlLabel value={fulfillmentTypes.relative} control={<Radio />} label="Relative" />
            <FormControlLabel value={fulfillmentTypes.dayOfMonth} control={<Radio />} label="Absolute" />
          </RadioGroup>
        </Wrapper>

        {type === fulfillmentTypes.relative && (
          <Container>
            After
            <Input type="number" step="1" min={1} name="offsetDays" value={offsetDays} onChange={this.handleChange} />
            {offsetDays === 1 ? 'day' : 'days'} of the transaction.
            {offsetDays && offsetDays !== 30 && !isNaN(offsetDays) ? (
              <Info>
                <ValidationIcon name="warning" size={22} color={sc.warning} />
                Please consult with Growth Platform team if you want to change this value from 30 days.{' '}
                <a
                  href="https://paypay-corp.rickcloud.jp/wiki/display/ProductDevDiv/Cashback+Campaign#CashbackCampaign-2.4Fulfillmenttime"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {' '}
                  Click here
                </a>{' '}
                for more info.
              </Info>
            ) : null}
          </Container>
        )}

        {type === fulfillmentTypes.dayOfMonth && (
          <Container>
            On the
            <Input type="number" step="1" name="dayOfMonth" max={28} min={1} value={dayOfMonth} onChange={this.handleChange} /> th of after
            <Input type="number" step="1" name="numMonths" value={numMonths} onChange={this.handleChange} /> months of the transaction.
          </Container>
        )}
      </Section>
    );
  }
}

const ValidationIcon = styled(Icon)`
  margin-top: -4px;
  margin-left: 0.5rem;
  font-weight: 900;
`;

const Container = styled.div`
  margin: 1rem 0;
`;

const Input = styled(_Input)`
  width: 80px;
  margin: 0 0.5rem;
`;

const Label = styled.div`
  text-transform: uppercase;
  color: ${sc.grey};
  margin: 0 1rem 1rem 0;
  text-align: left;
  font-size: ${sc.fontSize};
`;

const RadioGroup = styled(_RadioGroup)`
  display: inline-block;
`;

const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${sc.greyLight};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const Info = styled.p`
  color: ${sc.grey};
  font-size: ${sc.fontSizeSmall};
  font-style: italic;
  padding: ${sc.gutterSmaller} 0;
`;
