import { IBannerCampaignSpec } from 'app/types/Creatives/BannerCreative';

import * as types from './types';

export const addBanner = () => ({
  type: types.ADD_BANNER,
});

export const removeBanner = (index: number) => ({
  type: types.REMOVE_BANNER,
  payload: { index },
});

export const setBanners = (banners: Array<IBannerCampaignSpec>) => ({
  type: types.SET_BANNERS,
  payload: { banners },
});

export const setBannerId = (index: number, id: number) => ({
  type: types.SET_BANNER_ID,
  payload: { index, id },
});

export const setStorefrontId = (index: number, storefrontId: number) => ({
  type: types.SET_STOREFRONT_ID,
  payload: { index, storefrontId },
});

export const setViewId = (index: number, viewId: number) => ({
  type: types.SET_VIEW_ID,
  payload: { index, viewId },
});
