import * as React from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@material-ui/core';
import { map } from 'ramda';
import { Row } from './Row';
import { EmptyTable } from './EmptyTable';
import { LoadingTable } from './LoadingTable';

const renderTableRow = props => row => <Row key={row.bannerId || row.id} {...props} row={row} />;

export const Body = ({ rows = [], rowsLoading = false, ...props }) => (
  <TableBody>
    {rowsLoading && <LoadingTable {...props} />}
    {!rows.length && !rowsLoading ? <EmptyTable {...props} /> : null}
    {rows.length && !rowsLoading ? map(renderTableRow(props))(rows) : null}
  </TableBody>
);

Body.propTypes = {
  rows: PropTypes.array, //eslint-disable-line
  rowsLoading: PropTypes.bool,
};
