/* eslint-disable import/no-cycle */
import * as React from 'react';
import { connect } from 'react-redux';
import { sc } from 'app/styles';

import { ConnectedPush2 } from 'app/ducks';
import { getPush2CreativeConfig } from 'configs/apps/creatives';
import { IAppConfig } from 'configs/apps/types';

import { Label, Section, ValidationIcon } from './utilComponents';
import Push2Content from './Content';
import ImageCarousel from './ImageCarousel';
import Context from './Context';
import Overrides from './Overrides';
import { PlatformSelection } from './PlatformSelection';
import { pushTypes } from './Push2Constants';
import SitesAndTypes from './SitesAndTypes';
type Props = {
  appConfig: IAppConfig;
};

class Push2Creation extends React.Component<Props> {
  render() {
    const { appConfig } = this.props;

    const creative = getPush2CreativeConfig(appConfig);
    const carouselPushTypes = [pushTypes.push.id, pushTypes.inbox.id];

    return (
      <ConnectedPush2>
        {({
          type,
          platforms,
          updatePush2Context,
          updatePush2Platform,
          updatePush2Field,
          addInteractive,
          removeInteractive,
          updateInteractive,
          addExtraContext,
          updateExtraContext,
          removeExtraContext,
          payload: {
            notification: {
              interactive,
              extras,
              content: { title, body },
            },

            context,
          },

          isValidCarousel,
        }) => (
          <>
            <SitesAndTypes creative={creative} />

            <Section data-qa="supported-devices">
              <Label>
                Supported Devices
                {pushTypes.inbox.id === type ? null : platforms.length > 0 ? (
                  <ValidationIcon name="check-circle" size={22} color={sc.success} />
                ) : (
                  <ValidationIcon name="warning" size={22} color={sc.warning} />
                )}
              </Label>
              <PlatformSelection type={type} selected={platforms} handleSelect={updatePush2Platform} />
            </Section>

            <Section data-qa="push-notification-content">
              <Label>
                Push Notification Content
                {title && body ? (
                  <ValidationIcon name="check-circle" size={22} color={sc.success} />
                ) : (
                  <ValidationIcon name="warning" size={22} color={sc.warning} />
                )}
              </Label>
              <Push2Content />
            </Section>

            {creative && creative.useImageCarousel && carouselPushTypes.includes(type) && (
              <Section data-qa="image-carousel">
                <Label>
                  Image Carousel
                  {isValidCarousel ? (
                    <ValidationIcon name="check-circle" size={22} color={sc.success} />
                  ) : (
                    <ValidationIcon name="warning" size={22} color={sc.warning} />
                  )}
                </Label>
                <ImageCarousel />
              </Section>
            )}

            <Section data-qa="utm-content">
              <Label>UTM Content</Label>
              <Context
                extras={extras}
                context={context}
                handleChange={updatePush2Context}
                addExtra={addExtraContext}
                changeExtra={updateExtraContext}
                removeExtra={removeExtraContext}
                hideMetadata={!creative.useMetadata}
              />
            </Section>

            {creative.useOverrides && (
              <Section data-qa="override">
                <Label>Override</Label>
                <Overrides />
              </Section>
            )}
          </>
        )}
      </ConnectedPush2>
    );
  }
}

export default connect(({ config }) => ({ appConfig: config }))(Push2Creation);
