import { Action } from 'app/types';
import { IBannerCreative } from 'app/types/BannerManagement';

import { IFetch } from '../../commonStates';
import { fetch } from '../../commonStates';
import * as types from './types';

export type State = IFetch & {
  creatives: Array<IBannerCreative>;
};

const initialState = {
  ...fetch,
  creatives: [],
  paging: {},
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_ALL:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        creatives: payload.bannerCreatives,
        paging: payload.paging,
      };

    case types.GET_ALL_FAILED:
      return {
        ...state,
        loading: false,
        creatives: [],
        paging: {},
        error: payload.error,
      };

    default:
      return state;
  }
};
