import { fetch } from '../commonStates';
import * as types from './types';

const emailSendersInitState = {
  ...fetch,
  allIds: [],
  byId: {},
};

const emailSenders = (state = emailSendersInitState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_EMAIL_SENDERS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ALL_EMAIL_SENDERS_SUCCESS:
      return {
        ...state,
        ...payload.emailSenderLookups,
        loading: false,
      };

    case types.GET_ALL_EMAIL_SENDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};

export default emailSenders;
