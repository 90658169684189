import { ISearchDef, ISearchDefs, SearchDefsGenerator } from 'app/hocs/withReporting';

import { asDropdownOptionsWithAll } from '../helpers';

export const searchParams = {
  createdBy: 'createdBy',
  id: 'id',
  name: 'name',
  platform: 'platform', // Multipick
  site: 'site', // Multipick
  widgetType: 'widgetType',
};

export const searchBarDefs: ISearchDefs = [
  { name: searchParams.id, label: 'By ID', type: 'number' },
  { name: searchParams.name, label: 'By Name' },
];

export const searchByCreator: ISearchDef = {
  name: searchParams.createdBy,
  label: 'By Creator',
};

export const searchByPlatformDefiner = (platforms: Array<string> | null | undefined): ISearchDef => ({
  name: searchParams.platform,
  label: 'By Platform',
  options: asDropdownOptionsWithAll(platforms, 'All Platforms'),
});

export const searchBySiteDefiner = (sites: Array<string> | null | undefined): ISearchDef => ({
  name: searchParams.site,
  label: 'By Site',
  options: asDropdownOptionsWithAll(sites, 'All Sites'),
});

export const searchByWidgetType: ISearchDef = {
  name: searchParams.widgetType,
  label: 'By Widget Type',
};

export const searchDefiner: SearchDefsGenerator = ({ allPlatforms, allSites }) => [
  ...searchBarDefs,
  searchByWidgetType,
  searchBySiteDefiner(allSites),
  searchByPlatformDefiner(allPlatforms),
  searchByCreator,
];

export const defaultSearch = ({ userEmail }: { userEmail: string }) => ({
  [searchParams.createdBy]: userEmail,
});
