import { compose, withHandlers } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

import { DrawerError } from './DrawerError';
import { styles } from './styles';

const enhanced = compose(
  withHandlers({
    handleDismiss:
      ({ handleDismiss }) =>
      () =>
        handleDismiss && handleDismiss(),
  }),

  withStyles(styles, { withTheme: true }),
);

export default enhanced(DrawerError);
