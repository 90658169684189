import { combineReducers } from 'redux';

import audiences, { audiencesOperations, audiencesSelectors } from './segments';
import audience, { audienceActions, audienceOperations, audienceSelectors } from './segment';
import audienceRules, { audiencesFeatureOperations } from './audienceRules';

export { audiencesOperations, audiencesSelectors, audienceActions, audienceOperations, audienceSelectors, audiencesFeatureOperations };

export default combineReducers({
  audience,
  audiences,
  audienceRules,
});
