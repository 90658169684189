import { unixTimeWithMillis, R } from 'app/helpers/RamdaHelpers/helpers';
import { GET_FEATURES_LOADING, GET_FEATURES_SUCCESS, GET_FEATURES_FAILURE, SET_REAL_TIME_SCHEMA_DATA } from './types';

const defaultState = {
  data: {
    dicts: {},
    seed: [],
    allNames: [],
    byName: {},
  },

  eventId: undefined,
  eventName: '',
  realTimeEvents: [],
  schemaId: null,
  loading: undefined,
  error: undefined,
  lastUpdate: undefined,
};

const reducer = (
  state = defaultState,
  {
    type,
    payload = {
      data: {},
      error: undefined,
    },
  },
) => {
  if (type === SET_REAL_TIME_SCHEMA_DATA && state.eventId === payload.eventId) {
    return state; // only set realTimeEvents if eventId is changed
  }
  const actionResults = {
    [GET_FEATURES_LOADING]: {
      ...state,
      loading: true,
    },

    [GET_FEATURES_SUCCESS]: {
      ...state,
      loading: false,
      data: payload.data,
      error: undefined,
      lastUpdate: unixTimeWithMillis(),
    },

    [GET_FEATURES_FAILURE]: {
      ...state,
      loading: false,
      error: payload.error,
    },

    [SET_REAL_TIME_SCHEMA_DATA]: {
      ...state,
      loading: false,
      eventId: payload.eventId,
      eventName: payload.eventName,
      realTimeEvents: payload.data,
      schemaId: payload.schemaId,
    },
  };

  return R.propOr(state, type, actionResults);
};

export default reducer;
