import * as React from 'react';
import styled from 'styled-components';

import { IFeature } from 'app/api/features/types';
import { sc, media } from 'app/styles';

type Props = {
  features: Array<IFeature>;
  msgIfEmpty?: string;
};

export default class FeaturesTable extends React.PureComponent<Props> {
  render() {
    const { features, msgIfEmpty } = this.props;

    return (
      <Container>
        {features.length === 0 ? (
          <div>{msgIfEmpty}</div>
        ) : (
          <>
            {features.map(feature => (
              <FeatureRow key={feature.feature_id} data-qa="feature-row">
                <DescWrapper>
                  <Label data-qa="feature-label">{feature.feature_label}</Label>
                  <Id data-qa="feature-id-name">
                    {feature.feature_id} <span>- {feature.name}</span>
                  </Id>
                  <Id data-qa="feature-description">{feature.feature_desc}</Id>
                </DescWrapper>
                <PillContainer>
                  <VerticalPill data-qa="feature-vertical">{feature.vertical}</VerticalPill>
                  <CategoryPill data-qa="feature-category">{feature.category}</CategoryPill>
                  <FeatureClassPill data-qa="feature-class">{feature.feature_class}</FeatureClassPill>
                </PillContainer>
              </FeatureRow>
            ))}
          </>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  margin: 1rem 0;
`;

const DescWrapper = styled.div`
  width: -webkit-fill-available;
  text-align: left;
`;

const PillContainer = styled.div`
  display: grid;

  ${media.smallScreen({
    display: 'inline',
  })}
`;

const Pill = styled.span`
  color: ${sc.sectionWhiteColor};
  padding: 0.25rem;
  width: inherit;
  border-radius: 2px;
  margin: 0.25rem 0.5rem 0.25rem 0;
  font-size: ${sc.fontSizeSmaller};
  display: inline-block;
  justify-self: end;

  ${media.largeScreen({
    width: 'max-content',
  })}
`;

const VerticalPill = styled(Pill)`
  background: ${sc.primary};
`;

const CategoryPill = styled(Pill)`
  background: ${sc.secondary};
`;

const FeatureClassPill = styled(Pill)`
  background: ${sc.tertiary};
`;

const Label = styled.div`
  font-size: ${sc.fontSize};
  font-weight: bold;
  padding-bottom: 10px;
`;
const Id = styled.div`
  color: ${sc.grey};
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  margin-bottom: 5px;
`;

const FeatureRow = styled.div`
  border-bottom: 1px solid ${sc.greyLighter};
  padding: 8px 16px;
  display: flex;

  ${media.smallScreen({
    display: 'block',
  })}

  &:nth-child(even) {
    background: ${sc.sectionBackgroundColor};
  }

  & > div {
    font-size: ${sc.fontSizeSmall};

    ${media.smallScreen({
      display: 'inline',
      'text-align': 'left',
      float: 'unset',
    })}
  }
`;
