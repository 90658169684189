import styled from 'styled-components';
import { sc } from 'app/styles';

export const SectionContainer = styled.section`
  border-bottom: 1px solid ${sc.greyLight};
  padding: ${sc.gutterSmall};
  padding-bottom: ${sc.gutter};
  margin: ${sc.gutterSmall};
  &: last-child {
    border-bottom: none;
  }
`;

export const SectionHeader = styled.section`
  font-weight: bold;
  font-size: ${sc.fontSizeLarge};
  margin-bottom: ${sc.gutterSmaller};
`;

export const SubsectionContainer = styled.section`
  padding-top: ${sc.gutterSmallest};
  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
`;

export const SubsectionHeader = styled.section`
  font-weight: bold;
  font-size: ${sc.fontSize};
  margin-bottom: ${sc.gutterSmaller};
  margin-top: ${sc.gutterSmall};
`;

export const SublineHeader = styled.section`
  font-weight: bold;
  font-size: ${sc.fontSizeSmall};
  margin-bottom: ${sc.gutterSmallest};
  margin-top: ${sc.gutterSmaller};
`;
