const ROOT = '/ec/store';

const forId = (storefrontId: number) => `${ROOT}/${String(storefrontId)}`;

export const DELETE_STOREFRONT = forId;

export const GET_STOREFRONT = forId;

export const GET_STOREFRONTS = ROOT;

export const POST_STOREFRONT = ROOT;

export const PUT_STOREFRONT = forId;
