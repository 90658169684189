import React from 'react';
import styled from 'styled-components';
import Select from 'app/components/Select';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Typography } from '@material-ui/core';
import { setCampaignField } from 'app/ducks/campaigns/campaign/actions';
import { triggerTypes } from 'app/utilities/constants';

const dedupeValues = ['SOFT_LOCK_DEDUPE', 'HARD_LOCK_DEDUPE', 'NO_DEDUPE'];

export const CampaignSettings = () => {
  const dispatch = useDispatch();
  const { dedupeStrategy, quotaPeriodInDays, triggerType } = useSelector(state => state.campaigns.campaign.general);

  const updateDedupe = value => {
    dispatch(setCampaignField(value));
  };

  const updateQuotaPeriodInDays = ({ target: { value } }) => {
    dispatch(
      setCampaignField({
        quotaPeriodInDays: value,
      }),
    );
  };

  return (
    <>
      <Section>
        <Select
          required
          name="dedupeStrategy"
          style={{ width: '10rem' }}
          values={dedupeValues}
          value={dedupeStrategy}
          id="dedupeStrategy"
          label="Dedupe Strategy"
          handleChange={updateDedupe}
        />
      </Section>
      {triggerType !== triggerTypes.oneTime && (
        <Section>
          <Typography style={{ marginRight: '1rem' }}>Frequency Capping </Typography>
          Wait
          <TextField
            required
            type="number"
            id="quotaPeriodInDays"
            label="Number of Days"
            onChange={updateQuotaPeriodInDays}
            value={quotaPeriodInDays || ''}
          />
          days before second delivery.
        </Section>
      )}
    </>
  );
};

const Section = styled.section`
  display: flex;
  min-height: 48px;
  align-items: center;
  margin-bottom: 1rem;
`;
