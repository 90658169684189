import { R } from '../RamdaHelpers/helpers';

export const createLoadingAction = type => () => ({
  type,
  payload: {},
});

export const createErrorAction = R.curry((type, error) => ({
  type,
  payload: { error },
}));

export const executeSimplePipeline = (fetchAction, isLoading, onSuccess, onFailure) => (dispatch, getState) => {
  dispatch(isLoading);

  fetchAction(getState)
    .then(data => dispatch(onSuccess(data)))
    .catch(error => {
      dispatch(onFailure(error));
    });
};
