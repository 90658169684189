import { compose } from 'recompose';
import { connect } from 'react-redux';

import { featuresSimpleOperations, featuresSimpleSelectors } from 'app/ducks';
import withReporting from 'app/hocs/withReporting';
import withSearchBar from 'app/hocs/withReporting/withSearchBar';

import { Reporting } from './Reporting';
import { searchBarDefs, searchDefs } from './search.config';

const mapStateToProps = state => ({
  features: featuresSimpleSelectors.features(state),
  ...featuresSimpleSelectors.status(state),
});

const mapDispatchToProps = {
  fetchFeatures: featuresSimpleOperations.getFeatures,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withReporting('fetchFeatures', featuresSimpleSelectors.paging, { searchDefs }),
  withSearchBar(searchBarDefs),
);

export default enhance(Reporting);
