import { compose, lifecycle, branch, renderComponent, flattenProp, withProps, mapProps, withHandlers } from 'recompose';
import moment from 'moment';
import { connect } from 'react-redux';
import { prop } from 'ramda';
import 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';

import { Loading, NotFound } from 'app/components';
import { withAppConfig } from 'app/decorators';
import { campaignOperations, ConnectedCategories, ConnectedTags } from 'app/ducks';
import * as campaignActions from 'app/ducks/campaigns/campaign/actions';
import { timeZone } from 'app/helpers/AppEnvHelpers/helpers';
import { R } from 'app/helpers/RamdaHelpers/helpers';

import channelConfig from 'configs/channels/channel.config';
import { cashbackMediumId } from 'configs/channels/mediumIds';

import Overview from './Overview';
import { styles } from './styles';
import { withValidation } from '../New/validation';

const fetchCampaign = lifecycle({
  async componentDidMount() {
    const { getCampaign, getCampaignSize, match, appConfig, campaign, getMaxAudienceLimit, setCampaignField } = this.props;

    if (match.params) {
      if (match.params.id && match.params.type !== 'edit') {
        setCampaignField({ originalCampaign: null });
        getCampaign(match.params);
      }
      getCampaignSize(match.params);
    }

    if (appConfig.enableMaxAudienceLimit && !campaign.general.maxAudienceLimit) {
      getMaxAudienceLimit();
    }
  },

  componentDidUpdate(oldProps) {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    const {
      match: {
        params: { id: prevId },
      },
    } = oldProps;

    if (id && id !== prevId) {
      const {
        getCampaign,
        getCampaignSize,
        match: { params },
      } = this.props;

      const { preserveRawCreative } = this.props.history.location.state || {};
      if (!preserveRawCreative) {
        getCampaign(params);
      }
      getCampaignSize(params);
    }
  },
});

const loading = branch(({ campaign }) => campaign.general.loading, renderComponent(Loading));

const setHandlers = withHandlers({
  createCampaign: props => async (): boolean => {
    const { campaign, history, postCampaign, updateCampaign } = props;
    const {
      general: { id, mediumId },
    } = campaign;
    const { preCampaignCreation } = channelConfig[mediumId];

    let preConditionPassed;
    if (preCampaignCreation) {
      try {
        preConditionPassed = await preCampaignCreation(props);
      } catch (err) {
        console.error('preCampaignCreation failed: ', err);
        preConditionPassed = false;
      }
    } else {
      preConditionPassed = true;
    }

    if (preConditionPassed) {
      id ? updateCampaign({ active: true, history, id }) : postCampaign({ active: true, history });
      return true;
    }

    return false;
  },

  saveAsDraft:
    ({
      history,
      postCampaign,
      updateCampaign,
      campaign: {
        general: { id },
      },
    }) =>
    () =>
      id ? updateCampaign({ id, history }) : postCampaign({ history }),

  handleSegmentClick:
    ({ history }) =>
    ({ id }) =>
      history.push(`/segments/${id}`),
  handleAudienceV2Click:
    ({ history }) =>
    ({ id }) =>
      history.push(`/audience/${id}`),
});

const mapStateToProps = ({
  campaigns: { campaign },
  user,
  features: {
    data: {
      dicts: { dataTypeDict },
    },
  },

  user: { currentTenant, timezone },
}) => ({
  campaign,
  features: dataTypeDict,
  env: currentTenant,
  timezone,
  user,
});

const mapDispatchToProps = {
  getCampaign: campaignOperations.getCampaign,
  postCampaign: campaignOperations.postCampaign,
  getCampaignSize: campaignOperations.getCampaignSize,
  updateCampaign: campaignOperations.updateCampaign,
  testDelivery: campaignOperations.testDelivery,
  notificationDelivery: campaignOperations.notificationDelivery,
  setCampaignField: campaignActions.setCampaignField,
  getMaxAudienceLimit: campaignOperations.getMaxAudienceLimit,
};

const stringDate = (val, nullTitle) => {
  if (val) {
    const dateTime = moment.tz(val, 'Etc/UTC').clone().tz(timeZone()).format('YYYY-MM-DD[T]HH:mm').split('T');
    return `${dateTime[0]} ${dateTime[1]}`;
  }
  return nullTitle;
};

const RenamePropsForSchedule = withProps(props => ({
  createdAtTime: stringDate(prop('createdAt', props.general), 'Not set'),
  quotaPeriodInDays: props.general.quotaPeriodInDays ? props.general.quotaPeriodInDays : 'Not set',
  scheduling: {
    'Start Date': `${props.general.startDate} ${props.general.startTime}`,
    'Expiry Date': props.general.expiryDate ? `${props.general.expiryDate} ${props.general.expiryTime}` : 'Not set',
    'Minium Interval': props.general.quotaPeriodInDays ? props.general.quotaPeriodInDays : 'Not Set',
    'Maximum Limit': props.general.customerCountPerExecution ? props.general.customerCountPerExecution : 'Not set',
  },
}));

const withOverview = withProps(({ general, cashbackCreative: { type } }) => {
  // Handle special case of journey campaigns, for PayPay cashback
  if (cashbackMediumId === general.mediumId) {
    return {
      OverviewComponent: channelConfig[general.mediumId].subChannels.find(sub => sub.type === type).OverviewComponent,
    };
  }

  return {
    OverviewComponent: channelConfig[general.mediumId].OverviewComponent,
  };
});

const withBackHandler = withHandlers({
  goBackHandler:
    ({ history, match }) =>
    () => {
      const {
        length,
        location: { state },
      } = history;
      const searchquery = R.lensPath(['location', 'search']);
      const params = R.view(searchquery, history);
      // redirect back to new campaign creation
      if (match.params.type === 'new' || match.params.type === 'edit') {
        if (match.params.type === 'new') {
          history.push('/campaigns/new?keepState=true');
        } else {
          history.push(`/campaigns/edit/${match.params.id}?keepState=true`);
        }
      } else if (length > 2 && state) {
        history.goBack();
      } else if (params) {
        history.push(`/campaigns/?${params}`);
      } else {
        history.push('/campaigns');
      }
    },
});

const addPropsToNotFound = withProps({
  errorTitle: 'CAMPAIGN NOT FOUND',
  goBackPath: '/campaigns',
  feature: 'CAMPAIGNS',
})(NotFound);

const withIdMissingHandle = branch(({ campaign }) => campaign.general.error, renderComponent(addPropsToNotFound));

const enhance = compose(
  ConnectedCategories,
  ConnectedTags,
  connect(mapStateToProps, mapDispatchToProps),
  connect(), // Also inject dispatch as a property, to allow arbitrary actions and operations to be executed.
  mapProps(({ categories, tags, ...otherProps }) => otherProps),
  withAppConfig,
  fetchCampaign,
  loading,
  flattenProp('campaign'),
  RenamePropsForSchedule,
  setHandlers,
  withOverview,
  withBackHandler,
  withStyles(styles, { withTheme: true }),
  withIdMissingHandle,
  withValidation,
);

export default enhance(Overview);
