import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { AutoSubmit, TextField } from 'app/midgarComponents';
import { sc } from 'app/styles';
import * as campaignActions from 'app/ducks/campaigns/campaign/actions';
import { setCampaignFieldPromise } from 'app/ducks/campaigns/campaign/operations';
import Tags from 'app/features/Tags';
// eslint-disable-next-line
// @ts-ignore
import { SectionContainer } from '../SectionContainer';
import Categories from '../Measurements/Categories';
import { GeneralFormikProps, FormikField } from 'app/utilities/types/formik';
import { campaignGeneralSchema } from './validationSchema';
import { initializeGeneralValues, GeneralForm } from './formValues';
import { CampaignState, ConfigRootState } from 'src/app/types/typescript/State';

type Props = {
  appConfig: {
    enableGoals: boolean;
    supportedRoutes: Array<string>;
  };
};

type GeneralFormPayload = {
  name: string;
  businessPhase?: object;
  categories2?: Array<object>;
  objective?: object;
  tags: Array<object>;
};

const General: React.FC<Props> = (props): JSX.Element => {
  const { name, businessPhase, selectedTags, selectedCategories, objective, appConfig, submitAllForms, triggerType } = useSelector(
    ({
      campaigns: {
        campaign: {
          general: { name, businessPhase, tags: selectedTags, categories2: selectedCategories, objective, submitAllForms, triggerType },
        },
      },
      config: appConfig,
    }: typeof CampaignState & typeof ConfigRootState) => ({
      name,
      businessPhase,
      selectedTags,
      selectedCategories,
      appConfig,
      objective,
      submitAllForms,
      triggerType,
    }),
  );
  const dispatch = useDispatch();
  const addTag = React.useCallback((tag: unknown) => dispatch(campaignActions.addTag(tag)), [dispatch]);
  const removeTag = React.useCallback((tag: unknown) => dispatch(campaignActions.removeTag(tag)), [dispatch]);
  // TODO: The only reason this is being done is to allow integration testing at this time...
  const { enableGoals = false } = props.appConfig || appConfig;

  const saveGeneralValues = React.useCallback(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (values: any) => {
      const { name, tags, categories, campaignPhase, campaignObjective, campaignType } = values;
      const formattedPayload: GeneralFormPayload = {
        name: name.trim(),
        businessPhase: campaignPhase,
        objective: { ...campaignObjective, campaignType },
        tags,
      };
      if (!enableGoals) formattedPayload.categories2 = categories;

      // eslint-disable-next-line promise/catch-or-return
      setCampaignFieldPromise(formattedPayload)(dispatch).then(() => {
        return dispatch(campaignActions.setHiddenFormsSubmitted({ general: true }));
      });
    },
    [dispatch, enableGoals],
  );

  const initialGeneralValues: GeneralForm = {
    campaignPhase: businessPhase,
    campaignObjective: objective,
    name,
    tags: selectedTags,
  };

  if (!enableGoals) {
    initialGeneralValues.categories = selectedCategories;
  }

  return (
    <SectionContainer data-qa="general-section">
      <Formik
        initialValues={initializeGeneralValues(initialGeneralValues)}
        onSubmit={saveGeneralValues}
        validationSchema={campaignGeneralSchema({ triggerType, enableGoals })}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, setFieldTouched, errors, touched, values }: typeof GeneralFormikProps): JSX.Element => (
          <Form onSubmit={handleSubmit}>
            <Gutter>
              <Field name="name">
                {({ field: { name, value, onChange }, form: { touched, errors } }: typeof FormikField): JSX.Element => (
                  <TextField
                    data-qa="general-name"
                    required
                    id="name"
                    name={name}
                    label="Name"
                    value={value}
                    onChange={(ev: React.ChangeEvent): void => {
                      setFieldTouched('name');
                      onChange(ev);
                    }}
                    error={touched.name && errors.name}
                    autocomplete="off"
                  />
                )}
              </Field>
            </Gutter>
            {!enableGoals && (
              <Gutter>
                <Categories />
              </Gutter>
            )}
            <Gutter>
              <Tags size={90} addTag={addTag} removeTag={removeTag} selected={selectedTags} />
            </Gutter>
            <AutoSubmit formName="general" setStoreValidityState={campaignActions.setFormErrors} submitTrigger={submitAllForms} />
          </Form>
        )}
      </Formik>
    </SectionContainer>
  );
};

export default General;

const Gutter = styled.section`
  margin-bottom: ${sc.gutter};
`;
