import { combineReducers } from 'redux';

import { ConnectedCampaignForTable, ConnectedCampaignForOverview } from './ConnectedCampaign';
import campaigns, { campaignsOperations, campaignsSelectors } from './campaigns';
import campaign, { campaignOperations, ConnectedEmail, ConnectedPush2, ConnectedTimelineNotification, ConnectedApi } from './campaign';

export {
  campaignsOperations,
  campaignsSelectors,
  campaignOperations,
  ConnectedEmail,
  ConnectedPush2,
  ConnectedTimelineNotification,
  ConnectedCampaignForTable,
  ConnectedCampaignForOverview,
  ConnectedApi,
};

export default combineReducers({
  campaigns,
  campaign,
});
