import update from 'immutability-helper';

import { Action } from 'app/types';

import { State } from './state';
import * as types from './types';

export default (state: State = {}, { type, payload }: Action) => {
  if (!payload?.storefrontId) {
    return state;
  }

  switch (type) {
    case types.GET_VIEWS_ERROR:
      return update(state, {
        [payload.storefrontId]: {
          $set: {
            views: null,
            error: payload.error,
            loading: false,
          },
        },
      });

    case types.GET_VIEWS_START:
      return update(state, {
        [payload.storefrontId]: {
          $set: {
            views: null,
            error: null,
            loading: true,
          },
        },
      });

    case types.GET_VIEWS_SUCCESS:
      return update(state, {
        [payload.storefrontId]: {
          $set: {
            views: payload.views,
            error: null,
            loading: false,
          },
        },
      });

    default:
      return state;
  }
};
