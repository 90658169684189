import { ICampaignAttribute, IMedium, IScope } from 'app/features/CashbackPromoManagement/types';

type CampaignAttributesState = {
  cashbackPromoConfig: {
    campaignAttributes: {
      campaignAttributes: Array<ICampaignAttribute & { value: string }> | null | undefined;
      medium: IMedium;
      scope: IScope;
    };
  };
};

export const getCampaignAttributes = (
  scope: IScope,
  medium: IMedium,
  { cashbackPromoConfig: { campaignAttributes } }: CampaignAttributesState,
) => {
  const { campaignAttributes: currAttributes, medium: currMedium, scope: currScope } = campaignAttributes;

  return scope === currScope && medium === currMedium ? currAttributes : null;
};
