import * as React from 'react';

import { Item } from './styledComponents';

import { Suggestion } from './types';

type Props = {
  suggestions: Suggestion[];
  onItemSelect: (...args: Array<any>) => any;
};

export const Suggestions = ({ suggestions, onItemSelect }: Props) =>
  suggestions.map<React.ReactElement<'div'>>(({ id, name, ...props }) => {
    const handleClick = () => onItemSelect({ id, name, ...props });
    const handleKeyDown = ({ keyCode }) => keyCode === 13 && handleClick();
    return (
      <Item
        id={`variable-option-${id}`}
        data-qa={`variable-option-${name}`}
        tabIndex={0}
        key={id}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
      >
        {name}
      </Item>
    );
  });
