import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouterHistory } from 'react-router-dom';
import { compose } from 'ramda';
import qs from 'query-string';
import styled from 'styled-components';

import { inject } from 'app/decorators';
import { binderActions, binderOperations } from 'app/ducks';
import { urlView } from 'app/features/BannerManagement/routeUtils';
import { Button, Checkbox, ConfirmModal, Toggle } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ISlot } from 'app/types/BannerManagement';
import { getSelectedTenant } from 'configs/user';
import { PAYPAY_CONSUMER } from 'configs/apps/tenants/PAYPAY_CONSUMER';

import Slot from './Slot';
import BannerNav from '../BannerNav';

type Props = {
  addSlotLoading: boolean;
  entityId?: number;
  entityType?: string;
  history: RouterHistory;
  readOnly?: boolean;
  selectedSlot: ISlot;
  slots: Array<ISlot>;
  slotDeleting: boolean;
  slotOrderUpdated: boolean;
  storefrontId: number;
  viewId: number;
  binderActions: typeof binderActions;
  binderOperations: typeof binderOperations;
};

type State = {
  isModalOpen: boolean;
  slotError: string | null | undefined;
  deletingSlotId: number | null | undefined;
};

class Slots extends PureComponent<Props, State> {
  state = {
    slotError: null,
    isModalOpen: false,
    deletingSlotId: 0,
  };

  componentDidMount() {
    const {
      binderOperations: { getSlotViewItems },
      history,
      selectedSlot,
    } = this.props;
    const query = qs.parse(history.location.search);
    const slotId = selectedSlot ? selectedSlot.id : null;

    if (slotId) {
      if (!query.slot || Number(query.slot) !== slotId) {
        this.updateSlotQuery(slotId, true);
      }
    }

    getSlotViewItems(slotId, { type: query.slotGroupType });
  }

  componentDidUpdate(prevProps: Props) {
    const { slotDeleting } = this.props;

    if (prevProps.slotDeleting !== slotDeleting) {
      if (!slotDeleting) {
        this.closeModal();
      }
    }
  }

  updateSlotQuery = (slotId: number, preserve = false) => {
    const { entityId, entityType, history, storefrontId, viewId } = this.props;

    if (storefrontId && entityId && viewId && slotId) {
      let query = { slot: slotId };

      if (preserve) {
        const params = qs.parse(history.location.search);
        query = Object.assign(params, query);
      }

      history.push(urlView(viewId, storefrontId, entityId, entityType, query));
    }
  };

  openModal = slotID => {
    const { readOnly } = this.props;
    if (!readOnly) {
      this.setState({
        deletingSlotId: slotID,
        isModalOpen: true,
      });
    }
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  onSelect = (slotId: number) => {
    const {
      slots,
      selectedSlot,
      binderActions: { updateSelectedSlot, resetHighlightedViewItem },
      binderOperations: { getSlotViewItems },
    } = this.props;

    if (slotId !== selectedSlot.id) {
      const newSelectedSlot = slots.find(slot => slot.id === slotId);
      resetHighlightedViewItem();
      updateSelectedSlot(newSelectedSlot);
      this.updateSlotQuery(newSelectedSlot.id);
      getSlotViewItems(newSelectedSlot.id);
    }
  };

  onAddSlot = () => {
    const {
      binderOperations: { addSlot },
      readOnly,
      viewId,
    } = this.props;

    if (!readOnly) {
      addSlot(viewId);
    }
  };

  renderSlotError = (slotError: string) => {
    this.setState({ slotError });
  };

  render() {
    const { slotError, isModalOpen, deletingSlotId } = this.state;
    const {
      viewId,
      entityId,
      entityType,
      storefrontId,
      readOnly,
      selectedSlot,
      slots,
      slotDeleting,
      slotOrderUpdated,
      binderActions: { updateSlotOrder },
      binderOperations: { saveSlotOrder, deleteSlot, updateSlotStatus, updateSlotOverride },
    } = this.props;

    return (
      <>
        <Label>The search is across all slots</Label>
        <BannerNav entityId={entityId} entityType={entityType} storefrontId={storefrontId} viewId={viewId} />
        <Container>
          <SlotsContainer>
            {!slots.length ? (
              <>
                <EmptySlots>No Slots</EmptySlots>
                <CreateSlotButton type="secondary" onClick={this.onAddSlot} disabled={readOnly}>
                  <LabelSpan>+</LabelSpan> Add Slot
                </CreateSlotButton>
              </>
            ) : (
              <>
                {slots.map((slot: ISlot, idx: number) => (
                  <Slot
                    index={idx}
                    key={slot.id}
                    slot={slot}
                    onMove={(from, to) => updateSlotOrder(from, to)}
                    onMoveSave={() => {
                      /* no-op */
                    }}
                    onSelect={this.onSelect}
                    isSelected={selectedSlot.id === slot.id}
                    renderError={this.renderSlotError}
                    openDeleteModel={this.openModal}
                    selectedSlot={this.selectedSlot}
                    readOnly={readOnly}
                  />
                ))}

                <CreateSlotButton type="secondary" onClick={this.onAddSlot} disabled={readOnly}>
                  <LabelSpan>+</LabelSpan> Add Slot
                </CreateSlotButton>
                <SaveOrderButton onClick={() => saveSlotOrder()} type="secondary" disabled={!slotOrderUpdated || readOnly}>
                  Save Order
                </SaveOrderButton>

                {slotError && <SlotErrorMessage>{slotError}</SlotErrorMessage>}
              </>
            )}
          </SlotsContainer>

          <ActionsContainer>
            <Actions>
              <Row>
                <StatusToggle
                  active={!!selectedSlot.status}
                  onChange={status => updateSlotStatus(selectedSlot.id, status)}
                  disabled={!selectedSlot.id || readOnly}
                />

                <Label>{selectedSlot.status ? 'Deactivate' : 'Activate'}</Label>
              </Row>
              {getSelectedTenant() !== PAYPAY_CONSUMER && (
                <Row>
                  <Checkbox
                    name="slot-override"
                    checked={!!selectedSlot.overridden}
                    onChange={() => updateSlotOverride(selectedSlot.id, !selectedSlot.overridden)}
                  />

                  <Label>Override</Label>
                </Row>
              )}
            </Actions>
            <SlotInfo>
              <Group>
                <Label>Group Tag</Label>
                <Field>{selectedSlot.engageGroupTagForSF}</Field>
              </Group>
            </SlotInfo>
          </ActionsContainer>

          <ConfirmModal
            isOpen={isModalOpen}
            title="Delete Confirm"
            confirmText="Delete"
            onClose={this.closeModal}
            onConfirm={() => deleteSlot(deletingSlotId)}
            disabled={slotDeleting}
          >
            {slotDeleting ? 'Deleting...' : `Are you sure you would like to delete slot ${deletingSlotId}?`}
          </ConfirmModal>
        </Container>
      </>
    );
  }
}

export default compose(
  connect(({ bannerManagement: { binder } }) => ({
    slots: binder.view.slots,
    selectedSlot: binder.selectedSlot,
    addSlotLoading: binder.addSlotLoading,
    slotDeleting: binder.slotDeleting,
    slotOrderUpdated: binder.slotOrderUpdated,
  })),

  inject({ binderActions: binderActions, binderOperations: binderOperations }),
)(Slots);

const SLOTS_ACTIONS_WIDTH = '140px';

const Actions = styled.div`
  background: ${sc.greyLightest};
`;

const ActionsContainer = styled.section`
  width: ${SLOTS_ACTIONS_WIDTH};
  padding-top: 12px;
`;

const Row = styled.div`
  display: inline-flex;
  width: 100%;
  padding: ${sc.gutterSmallest};
  margin: 2px 0;
  justify-content: space-between;
`;

const Container = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  min-width: 100px;
`;

const EmptySlots = styled.section`
  color: ${sc.danger};
  font-style: italic;
  padding-top: ${sc.gutter};
`;

const Field = styled.div`
  font-size: ${sc.fontSizeSmaller};
  font-weight: 600;
`;

const Group = styled.div`
  margin: ${sc.gutterSmaller};
`;

const Label = styled.div`
  padding: ${sc.gutterSmaller};
  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmallest};
  text-transform: uppercase;
`;

const SaveOrderButton = styled(Button)`
  vertical-align: top;
  border-radius: 5px;
  min-width: 70px;
  margin: ${sc.gutterSmallest};
  padding: ${sc.gutterSmallest};
  font-size: ${sc.fontSizeSmaller};
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-top: 20px;
`;
const CreateSlotButton = styled(Button)`
  vertical-align: top;
  border-radius: 5px;
  min-width: 70px;
  margin: ${sc.gutterSmallest};
  padding: ${sc.gutterSmallest};
  font-size: ${sc.fontSizeSmaller};
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-top: 20px;
`;

const SlotsContainer = styled.section`
  vertical-align: top;
  width: calc(98% - ${SLOTS_ACTIONS_WIDTH});
  height: 100%;
  margin-right: 2%;
`;

const SlotErrorMessage = styled.div`
  position: absolute;
  top: -${sc.gutterSmallest};
  left: ${sc.gutterSmallest};
  padding: ${sc.gutterSmallest} ${sc.gutterSmaller};

  border: 1px solid red;
  border-radius: 2px;
  background-color: ${sc.danger};

  color: #fff;
  font-size: ${sc.fontSizeSmaller};

  transform: translate(0, -100%);
  box-shadow: 0 0 5px ${sc.shadowDarker};
  pointer-events: none;
`;

const SlotInfo = styled.div``;

const StatusToggle = styled(Toggle)`
  display: inline-block;
  vertical-align: top;
  padding-left: ${sc.gutterSmaller};
  margin-right: ${sc.gutterSmallest};
  height: 24px;
  width: 46px;
`;

const LabelSpan = styled.span`
  font-size: 16px;
  text-transform: uppercase;
`;
