import React from 'react';
import styled from 'styled-components';
import { Toggle } from 'app/midgarComponents';
import { DialogShell } from 'app/components';
import { Action } from 'app/types';

import { TemplateDropdown } from './TemplateDropdown';

export const emptyTemplate = { template: '', name: '', defaults: { event: [], features: [] } };

export const initUiState = {
  dynamicInputActive: false,
  isDialogOpen: false,
};

export const uiStateTypes = {
  dynamicInputActive: 'dynamicInputActive',
  setIsDialogOpen: 'setIsDialogOpen',
};

type State = {
  dynamicInputActive: boolean;
  isDialogOpen: boolean;
};

export const uiStateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case uiStateTypes.dynamicInputActive:
      return { ...state, dynamicInputActive: action.payload };
    case uiStateTypes.setIsDialogOpen:
      return { ...state, isDialogOpen: action.payload };
    default:
      throw new Error();
  }
};

const TemplateSelection = ({
  id,
  name,
  onChange,
  setActiveStatus,
  label,
}: {
  id: string;
  name: string;
  onChange: (...args: Array<any>) => any;
  setActiveStatus: (...args: Array<any>) => any;
  label?: string;
}) => {
  const [currTemplate, setCurrTemplate] = React.useState(emptyTemplate);
  const [uiState, setUiState] = React.useReducer(uiStateReducer, initUiState);

  const onCloseDialog = okay => {
    setUiState({ type: uiStateTypes.setIsDialogOpen, payload: false });
    if (okay) {
      setCurrTemplate({ ...emptyTemplate });
      onChange({ target: { value: '', name } });
    } else {
      onChangeStatus(true);
    }
  };

  const toggleStatus = toggled => {
    if (!toggled && currTemplate?.template !== '') {
      setUiState({ type: uiStateTypes.setIsDialogOpen, payload: true });
    }
    onChangeStatus(toggled);
  };

  const onChangeStatus = React.useCallback(
    ev => {
      setUiState({ type: uiStateTypes.dynamicInputActive, payload: ev });
      setActiveStatus({ name, isActive: ev });
    },
    [name, setActiveStatus],
  );

  return (
    <FlexContainer data-qa="template-selection">
      <DialogShell isOpen={uiState.isDialogOpen} dialogHeader="Confirmation" onClose={onCloseDialog} okayButtonText="Continue">
        All the content will be removed if you disable Dynamic Templates. Do you wish to continue?
      </DialogShell>
      <Toggle id="template-mode" active={uiState.dynamicInputActive} onChange={toggleStatus} />
      <label data-qa="template-mode-label" htmlFor="template-mode-toggle">
        {label || 'Enable Templates'}
      </label>
      <TemplateDropdown
        disabled={!uiState.dynamicInputActive}
        onChange={onChange}
        name={name}
        currTemplate={currTemplate}
        setCurrTemplate={setCurrTemplate}
      />
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  & > label {
    margin-left: 0.5rem;
  }
`;

export default TemplateSelection;
