export const getCashbackPromoConfig = ({ cashbackPromoConfig }) => cashbackPromoConfig;

export const getEventSchema = state => {
  const cashbackPromoConfig = getCashbackPromoConfig(state);
  const { eventSchema } = cashbackPromoConfig;
  return eventSchema;
};

export const getPromoEvents = state => {
  const eventSchema = getEventSchema(state);
  const { promoEvents } = eventSchema;
  return promoEvents;
};

export const getCustomerEvent = state => {
  const eventSchema = getEventSchema(state);
  const { customerEvent, loadingCustomerEvent } = eventSchema;
  return {
    customerEvent,
    loadingCustomerEvent,
  };
};

export const getBanners = state => {
  const cashbackPromoConfig = getCashbackPromoConfig(state);
  const { banners } = cashbackPromoConfig;
  return banners;
};

export const getStackableGroups = state => {
  const cashbackPromoConfig = getCashbackPromoConfig(state);
  const { stackableGroups } = cashbackPromoConfig;
  return stackableGroups;
};

export const getUserBudgetTokens = state => {
  const cashbackPromoConfig = getCashbackPromoConfig(state);
  const { userBudgetTokens } = cashbackPromoConfig;
  return userBudgetTokens;
};

export const getPaymentCeilings = state => {
  const cashbackPromoConfig = getCashbackPromoConfig(state);
  const { paymentCeilings } = cashbackPromoConfig;
  return paymentCeilings;
};
