/*
 *
 */

import { renameKeys, isUndefined, isPlainObj, isArray } from 'ramda-adjunct';

import {
  __,
  flip,
  always,
  identity,
  adjust,
  append,
  concat,
  update,
  remove,
  either,
  both,
  all,
  allPass,
  anyPass,
  any,
  none,
  isNil,
  isEmpty,
  ifElse,
  defaultTo,
  unless,
  when,
  cond,
  is,
  not,
  complement,
  trim,
  contains,
  intersection,
  join,
  split,
  splitEvery,
  unnest,
  compose,
  pipe,
  map,
  mapAccum,
  mapObjIndexed,
  chain,
  ap,
  forEach,
  evolve,
  invertObj,
  addIndex,
  zip,
  zipObj,
  zipWith,
  uniq,
  partition,
  length,
  values,
  has,
  prop,
  propEq,
  propOr,
  pathOr,
  props,
  lensPath,
  lensProp,
  lensIndex,
  path,
  pick,
  pickBy,
  pluck,
  omit,
  curry,
  curryN,
  equals,
  lt,
  lte,
  gt,
  gte,
  apply,
  applyTo,
  startsWith,
  toLower,
  view,
  set,
  over,
  groupBy,
  indexBy,
  head,
  tail,
  init,
  last,
  take,
  reverse,
  keys,
  filter,
  without,
  where,
  find,
  reject,
  assoc,
  reduce,
  repeat,
  range,
  fromPairs,
  toPairs,
  merge,
  mergeAll,
  mergeWith,
  memoizeWith,
  sort,
  sortBy,
  sortWith,
  ascend,
  descend,
  tap,
  tryCatch,
  difference,
  differenceWith,
  xprod,
  transpose,
  converge,
  juxt,
  // useWith, applySpec, objOf, of, nth, pair,
  symmetricDifferenceWith,
  symmetricDifference,
  eqBy,
} from 'ramda';

// PAPH - Paritally application placeholder, alias to R.__, so we don't need to turn off eslint for the line every time we use R.__
const PAPH = __;

const R = {
  PAPH,
  flip,
  always,
  identity,
  adjust,
  append,
  concat,
  update,
  remove,
  either,
  both,
  all,
  allPass,
  anyPass,
  any,
  none,
  isNil,
  isEmpty,
  ifElse,
  defaultTo,
  unless,
  when,
  cond,
  is,
  not,
  complement,
  trim,
  contains,
  intersection,
  join,
  split,
  splitEvery,
  unnest,
  compose,
  pipe,
  map,
  mapAccum,
  mapObjIndexed,
  chain,
  ap,
  forEach,
  evolve,
  invertObj,
  addIndex,
  zip,
  zipObj,
  zipWith,
  uniq,
  partition,
  length,
  values,
  has,
  prop,
  propEq,
  propOr,
  pathOr,
  props,
  lensPath,
  lensProp,
  lensIndex,
  path,
  pick,
  pickBy,
  pluck,
  omit,
  curry,
  curryN,
  equals,
  lt,
  lte,
  gt,
  gte,
  apply,
  applyTo,
  startsWith,
  toLower,
  view,
  set,
  over,
  groupBy,
  indexBy,
  head,
  tail,
  init,
  last,
  take,
  reverse,
  keys,
  filter,
  without,
  where,
  find,
  reject,
  assoc,
  reduce,
  repeat,
  range,
  fromPairs,
  toPairs,
  merge,
  mergeAll,
  mergeWith,
  memoizeWith,
  sort,
  sortBy,
  sortWith,
  ascend,
  descend,
  tap,
  tryCatch,
  difference,
  differenceWith,
  xprod,
  transpose,
  converge,
  juxt,
};

// Common constant-value functions for easy function compositions
const [fEmptyString, fEmptyArray, fEmptyObject, fTrue, fFalse, fOne] = R.map(R.always, ['', [], {}, true, false, 1]);

// TODO: Delete this commented out old function once testing is completed
// const [joinWithDot, joinWithComma, joinWithSpace] = R.map(R.join, ['.', ',', ' ']);
const joinWithSeparator = separator => arr => Array.isArray(arr) ? arr.join(separator) : arr;

const joinWithDot = a => joinWithSeparator('.')(a);
const joinWithComma = a => joinWithSeparator(',')(a);
const joinWithSpace = a => joinWithSeparator(' ')(a);

const fNot = predicate => R.compose(R.not, predicate);

const fTruthy = R.pipe(...R.repeat(R.complement, 2));

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);
const isNeitherNilNorEmpty = fNot(isNilOrEmpty);

const notContains = R.curry(fNot(R.contains));
const notEquals = R.curry(fNot(R.equals));

const sanitizeString = R.ifElse(R.is(String), R.trim, fEmptyString);
const meaningfulString = R.compose(isNeitherNilNorEmpty, sanitizeString);
const notMeaningfulString = fNot(meaningfulString);

const mapIndexed = R.addIndex(R.map);

const enrichObjectsWith = (generator, f = R.identity) => mapIndexed((a, index) => R.merge(f(a), generator(index)));

const enrichObjectsWithId = enrichObjectsWith(id => ({ id }));

const extractAndRename = ([extract, rename]) => R.pipe(R.pick(extract), renameKeys(rename));
// mainly transforming from {a, b} to {a, b, b'}
const deriveAndRenameObject = (mapping, customize = () => ({})) => {
  const instructionsFrom = R.pipe(
    R.toPairs,
    R.map(([k, v]) => R.xprod([k], R.map(R.defaultTo(k), v))),
    R.transpose,
    R.converge(R.zip, R.map(R.map, [R.map(R.head), R.fromPairs])),
  );

  const transforms = R.map(extractAndRename, instructionsFrom(mapping));

  return R.pipe(R.juxt([...transforms, customize]), R.mergeAll);
};
const extractAndRenameKeys = mapping => extractAndRename([R.keys(mapping), mapping]);

const zipAndMergeAll = R.compose(R.map(R.mergeAll), R.zip);

const dictLength = R.pipe(R.keys, R.length);

const updateWithLens = R.curry((lens, key, value, source) => R.over(lens, R.assoc(key, value), source));
const mergeWithLens = R.curry((lens, entries, source) => R.over(lens, a => R.mergeAll([a, entries]), source));
const transformWithLenses = (pairs, sourceObj) => R.reduce((obj, [lens, transform]) => R.over(lens, transform, obj), sourceObj, pairs);

// currently this assumes the first arg is the one that needs enrichment
const enrichProps = (f, extra) => obj => f({ ...obj, ...extra });

// probably there's already something like that in R/RA
const transformWhen = R.curry((check, transform, source, defaultValue) => (check(source) ? transform(source) : defaultValue));

// This is similar to `applySpec`
const transformShape = R.curry((transformersOfShape, value) =>
  R.ap([R.ifElse(R.either(isPlainObj, isArray), R.map(R.applyTo(value)), R.applyTo(value))], transformersOfShape),
);

const innerJoinWithMerge = R.curry((xId, xs, yId, ys) => {
  const lookupX = R.indexBy(xId, xs);
  const defaultToEmptyArray = R.ifElse(isUndefined, fEmptyArray);

  return R.chain(y => defaultToEmptyArray(x => [R.merge(y, x)])(lookupX[yId(y)]), ys);
});

const explodeOjValue = explode => R.pipe(R.toPairs, R.chain(explode), R.fromPairs);

const tokenize = phrase => (phrase || '').split(/\s*[\s,]\s*/).filter(Boolean);

const filterBy = (x, ys, readX = R.identity, readY = R.identity) => {
  if (isNilOrEmpty(ys)) {
    return [];
  }
  const filterString = sanitizeString(`${readX(x)}`);
  if (isNilOrEmpty(filterString)) {
    return ys;
  }
  const words = tokenize(R.toLower(filterString));
  if (R.isEmpty(words)) {
    return [];
  }

  const matching = sentence => R.all(a => R.contains(a, sentence), words);

  return R.filter(y => matching(R.toLower(`${readY(y)}`)), ys);
};

const memoize = R.memoizeWith(fOne);
const isOneOf = R.flip(R.contains);
const notOneOf = R.compose(fNot, isOneOf);

const randomInt = (max = 1000, min = 0, inclusive = false) => {
  const minn = Math.ceil(min);
  const maxx = Math.floor(max);
  const intRange = maxx - minn + (inclusive ? 1 : 0);

  return Math.floor(Math.random() * intRange) + minn;
};

const isOneElementArray = a => isArray(a) && a.length === 1;

const unixTimeWithMillis = () => new Date().getTime();

const setEquals = (set1, set2) => set1.size === set2.size && [...set1].every(value => set2.has(value));

const equalsByProps = (list1, list2, key) => {
  if ((!list1 && list2) || (list1 && !list2)) return false;

  if (!list1 && !list2) return true;

  let eq = eqBy(prop(key));

  if (Array.isArray(key)) {
    eq = (a, b) => key.reduce((acc, cur) => acc && a[cur] === b[cur], true);
  }

  return compose(isEmpty, symmetricDifferenceWith)(eq, list1, list2);
};

const equalsArray = (list1, list2) => {
  if ((!list1 && list2) || (list1 && !list2)) return false;

  if (!list1 && !list2) return true;

  return compose(isEmpty, symmetricDifference)(list1, list2);
};

const diffArray = (list1, list2) => {
  if (!list1 && list2) return list2;

  if (list1 && !list2) return list1;

  if (!list1 && !list2) return [];

  return symmetricDifference(list1, list2);
};

export {
  fEmptyString,
  fEmptyArray,
  fEmptyObject,
  fTrue, // same but more readable than R.T
  fFalse, // same but more readable than R.F
  fNot,
  fTruthy,
  isNilOrEmpty,
  isNeitherNilNorEmpty,
  notContains,
  notEquals,
  sanitizeString,
  meaningfulString,
  notMeaningfulString,
  joinWithDot,
  joinWithComma,
  joinWithSpace,
  mapIndexed,
  enrichObjectsWith,
  enrichObjectsWithId,
  deriveAndRenameObject,
  extractAndRenameKeys,
  zipAndMergeAll,
  dictLength,
  updateWithLens,
  mergeWithLens,
  transformWithLenses,
  enrichProps,
  transformWhen,
  transformShape,
  innerJoinWithMerge,
  explodeOjValue,
  tokenize,
  filterBy,
  memoize,
  isOneOf,
  notOneOf,
  randomInt,
  isOneElementArray,
  unixTimeWithMillis,
  R,
  setEquals,
  equalsByProps,
  equalsArray,
  diffArray,
};
