import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'ramda';

import { withAppConfig } from 'app/decorators';
import { bulkViewItemActionClearError } from 'app/ducks/bannerManagement/binder/actions';
import {
  deleteViewItems as opDeleteViewItems,
  getSlotViewItems as opGetSlotViewItems,
  moveAfterBannerId as opMoveAfterBannerId,
  moveViewItems as opMoveViewItems,
} from 'app/ducks/bannerManagement/binder/operations';

import { sc } from 'app/styles';
import { Action } from 'app/types';
import { ISlot, ISlotGroupType, IViewItem } from 'app/types/BannerManagement';
import { findPageFromPriority } from 'app/utilities/pagination';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import ConfirmChangePrioritiesModal from './ConfirmChangePrioritiesModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import ConfirmMoveModal from './ConfirmMoveModal';
import { BulkAction } from './constants';
import { ACTION_CHANGE_PRIORITY, ACTION_DELETE, ACTION_MOVE } from './constants';
type Props = {
  appConfig: IAppConfig;
  bulkActionError: Error;
  bulkActionInProgress: boolean;
  clearError: () => Action;
  deleteViewItems: (ids: Array<number>) => (...args: Array<any>) => any;
  getSlotViewItems: (slotId: number | null | undefined, params: Record<string, any>, showLoading: boolean) => (...args: Array<any>) => any;
  moveAfterBannerId: (afterBannerId: number, viewItems: Array<{ id: number }>) => (...args: Array<any>) => any;
  moveViewItems: (ids: number[], targetSlotId: number, targetSlotGroupTypeName: string) => (...args: Array<any>) => any;
  onDeselectAll: (...args: Array<any>) => any;
  selectedIds: Array<number>;
  selectedSlot: ISlot;
  selectedSlotGroupType: ISlotGroupType;
  slotViewItems: Array<IViewItem>;
  slotViewItemsPage: number;
  slotViewItemsPageSize: number;
};

type State = {
  isModalOpen: boolean;
  modalAction?: BulkAction;
};

class BulkActionsToast extends PureComponent<Props, State> {
  state = {
    isModalOpen: false,
  };

  closeModal = () => {
    const { clearError } = this.props;
    clearError();
    this.setState({
      isModalOpen: false,
      modalAction: null,
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    const { bulkActionError, bulkActionInProgress, onDeselectAll } = this.props;
    const { isModalOpen } = this.state;

    const { bulkActionInProgress: prevInProgress } = prevProps;

    if (isModalOpen && !bulkActionInProgress && prevInProgress) {
      if (!bulkActionError) {
        onDeselectAll();
        this.closeModal();
      }
    }
  };

  onDelete = () => {
    const { deleteViewItems, selectedIds } = this.props;
    deleteViewItems(selectedIds);
  };

  onMove = async (targetSlotId: number, targetSlotGroupType: ISlotGroupType) => {
    const { moveViewItems, selectedIds } = this.props;
    await moveViewItems(selectedIds, targetSlotId, targetSlotGroupType.id);
  };

  onMoveAfterBannerId = async (afterBannerId: number) => {
    const { getSlotViewItems, moveAfterBannerId, selectedIds, selectedSlot, selectedSlotGroupType, slotViewItems, slotViewItemsPageSize } =
      this.props;

    const selectedViewItems = slotViewItems.filter(({ id }) => selectedIds.includes(id));

    const priority = await moveAfterBannerId(afterBannerId, selectedViewItems);
    const page = findPageFromPriority(priority, slotViewItemsPageSize);
    getSlotViewItems(selectedSlot.id, { type: selectedSlotGroupType.value, page });
  };

  openModal = (modalAction: BulkAction) => () => {
    this.setState({
      isModalOpen: true,
      modalAction,
    });
  };

  renderModal() {
    const { isModalOpen, modalAction } = this.state;
    if (!isModalOpen) {
      return null;
    }

    switch (modalAction) {
      case ACTION_CHANGE_PRIORITY:
        return <ConfirmChangePrioritiesModal onClose={this.closeModal} onConfirm={this.onMoveAfterBannerId} />;

      case ACTION_DELETE:
        return <ConfirmDeleteModal onClose={this.closeModal} onConfirm={this.onDelete} />;

      case ACTION_MOVE:
        return <ConfirmMoveModal onClose={this.closeModal} onConfirm={this.onMove} />;

      default:
        return null;
    }
  }

  render() {
    const { appConfig, onDeselectAll, selectedIds } = this.props;

    const numItems = (selectedIds || []).length;
    const selectedItemsStr = `${numItems} item${numItems > 1 ? 's' : ''}`;

    const { useBulkMove } = getBannerCreativeConfig(appConfig);

    return (
      <Container>
        <Header>
          {selectedItemsStr} selected
          <Link onClick={onDeselectAll}>Deselect All</Link>
        </Header>

        <Content>
          <Link onClick={this.openModal(ACTION_DELETE)}>Remove</Link>

          {useBulkMove && <Link onClick={this.openModal(ACTION_MOVE)}>Change Slot/Group</Link>}

          <Link onClick={this.openModal(ACTION_CHANGE_PRIORITY)}>Change Priority</Link>
        </Content>

        {this.renderModal()}
      </Container>
    );
  }
}

const mapStateToProps = ({ bannerManagement: { binder } }) => ({
  bulkActionError: binder.bulkActionError,
  bulkActionInProgress: binder.bulkActionInProgress,
  selectedSlot: binder.selectedSlot,
  selectedSlotGroupType: binder.selectedSlotGroupType,
  slotViewItems: binder.slotViewItems,
  slotViewItemsPage: binder.slotViewItemsPage,
  slotViewItemsPageSize: binder.slotViewItemsPageSize,
});

const mapDispatchToProps = {
  clearError: bulkViewItemActionClearError,
  deleteViewItems: opDeleteViewItems,
  getSlotViewItems: opGetSlotViewItems,
  moveAfterBannerId: opMoveAfterBannerId,
  moveViewItems: opMoveViewItems,
};

export default compose(withAppConfig, connect(mapStateToProps, mapDispatchToProps))(BulkActionsToast);

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  padding: ${sc.gutterSmall};
  text-align: center;

  border-radius: 4px;
  background-color: ${sc.sectionWhiteColor};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  z-index: 100;
`;

const Content = styled.div`
  & > div {
    border-bottom: 1px solid ${sc.greyLight};
  }
  & > div:last-child {
    border-bottom: 0;
  }
`;

const Header = styled.div`
  border-bottom: 1px solid ${sc.greyLight};
`;

const Link = styled.div`
  color: ${sc.primary};
  padding: ${sc.gutterSmallest};
  &:hover {
    background-color: ${sc.greyLighter};
    cursor: pointer;
  }
`;
