// These channel names must match the names in the channels config.js
export const channelNameBanner = 'Banner';

export const channelNameCashback = 'Cashback';

export const channelNameEmail = 'Email';

export const channelNamePush2 = 'Push2';

export const channelNameTimelineNotification = 'Notification_Center';
