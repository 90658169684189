import * as React from 'react';
import { Typography } from '@material-ui/core';

import { ConnectedUser } from 'app/ducks';
import { Card, SegmentCount } from 'app/components';
import Download from 'app/features/Segments/components/Download';
import { segmentTypeFlags } from 'app/features/Segments/utils';
import { segmentDownloadPermissions } from 'configs/permissions';

export default class CsvUpload extends React.PureComponent<{
  type: string;
  classes: {
    header: string;
  };

  calculate: () => boolean;
  id: number;
  state: string;
  downloadUrls: Array<{}>;
}> {
  render() {
    const { type, classes, calculate, id, state, downloadUrls } = this.props;
    return (
      <ConnectedUser>
        {({ isPermitted }) => (
          <Card
            className={classes.header}
            label={() => [
              <Typography variant="subtitle1" key="label">
                CSV location
              </Typography>,

              type === segmentTypeFlags.CSV_BASED && <SegmentCount key="counter" calculate={calculate} classes={classes} calcOnLoad />,
            ]}
          >
            {isPermitted(segmentDownloadPermissions) && <Download id={id} state={state} downloadUrls={downloadUrls} />}
          </Card>
        )}
      </ConnectedUser>
    );
  }
}
