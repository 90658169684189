// http.js is used for CMA and Schema Registry

import { tenantPrefix } from 'configs/apps/tenants';
import { catchErrors } from './errors';

const setUrl = url => `/v1${tenantPrefix}${url}`;

const toJson = res => {
  const contentType = res.headers.get('content-type');

  if (contentType && contentType.includes('application/json')) {
    return res.json();
  }

  if (contentType && contentType.includes('text/plain')) {
    return res.text();
  }

  if (res.headers.get('location')) {
    return Promise.resolve({
      id: res.headers.get('location').split('/').reverse()[0],
    });
  }

  return Promise.resolve();
};

const headers = new Headers({
  'Content-Type': 'application/json',
  'No-Cache': 'true',
});

export const get = url => fetch(setUrl(url), { headers, credentials: 'include' }).then(catchErrors).then(toJson);

export const postText = (url, body) =>
  fetch(setUrl(url), {
    credentials: 'include',
    body: JSON.stringify(body),
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'text/plain' }),
  })
    .then(res => catchErrors(res, { reqBody: body }))
    .then(toJson);

export const post = (url, body) =>
  fetch(setUrl(url), {
    credentials: 'include',
    body: JSON.stringify(body),
    method: 'POST',
    headers,
  })
    .then(res => catchErrors(res, { reqBody: body }))
    .then(toJson);

export const put = (url, body, type) =>
  fetch(setUrl(url), {
    credentials: 'include',
    body: type === 'string' ? body : JSON.stringify(body),
    method: 'PUT',
    headers,
  })
    .then(res => catchErrors(res, { reqBody: body }))
    .then(toJson);

export const httpDelete = (url, body) =>
  fetch(setUrl(url), {
    credentials: 'include',
    body: JSON.stringify(body),
    method: 'DELETE',
    headers,
  })
    .then(res => catchErrors(res, { reqBody: body }))
    .then(toJson);

export const fileUpload = (url, data, customHeader) =>
  fetch(setUrl(url), {
    credentials: 'include',
    body: data,
    method: 'POST',
    customHeader,
  })
    .then(res => catchErrors(res, { reqBody: data }))
    .then(toJson);

export const getProxy = url => fetch(url, { headers, credentials: 'include' }).then(catchErrors).then(toJson);
