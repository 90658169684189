import * as React from 'react';

import { segmentTypeFlags } from 'app/features/Segments2/utils';

import CsvUpload from './CsvUpload';
import Rules from './Rules';
import Composition from './Composition';

type Props = {
  audienceType: string;
  classes: {
    header: string;
  };

  calculate: () => boolean;
  id: number;
  state: string;
  membershipCsvFileUri: string;
  toTableData: () => () => void;
  featureMetadata: {};
  includedRules: Array<{}>;
  includedSeedSegments: Array<{}>;
  excludedSeedSegments: Array<{}>;
  includedUniverseSegments: Array<{}>;
  excludedUniverseSegments: Array<{}>;
  maxAudienceSize: number;
  includedSegments: Array<{}>;
  excludedSegments: Array<{}>;
  history: Array<{}>;
};

export const Switcher = ({
  audienceType,
  classes,
  calculate,
  id,
  state,
  membershipCsvFileUri,
  toTableData,
  featureMetadata,
  includedRules,
  includedSegments,
  excludedSegments,
  history,
}: Props) => {
  switch (audienceType) {
    case segmentTypeFlags.CSV_BASED:
      return <CsvUpload {...{ audienceType, classes, calculate, id, state, membershipCsvFileUri }} />;
    case segmentTypeFlags.COMPOSITION:
      return <Composition {...{ classes, includedSegments, excludedSegments, history }} />;
    default:
      return <Rules {...{ classes, calculate, toTableData, featureMetadata, includedRules }} />;
  }
};
