import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers, withState } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import { ButtonMenu } from './ButtonMenu';

const addState = withState('menu', 'setMenu', null);

const addHandlers = withHandlers({
  handleClick:
    ({ setMenu }) =>
    ({ target }) =>
      setMenu(target),
  handleClose:
    ({ setMenu }) =>
    () =>
      setMenu(null),
  handleSelect:
    ({ selectedId, handleChangeSelected, setMenu }) =>
    id =>
    () => {
      if (id !== selectedId && handleChangeSelected) {
        handleChangeSelected(id);
      }
      setMenu(null);
    },
});

const propTypes = setPropTypes({
  handleChangeSelected: PropTypes.func.isRequired,
});

const enhance = compose(addState, addHandlers, propTypes, withStyles(styles, { withTheme: true }));

export default enhance(ButtonMenu);
