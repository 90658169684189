import { path } from 'ramda';

import { ICampaign } from 'app/types';
import { triggerTypes } from 'app/utilities/constants';

// TODO: ICampaignSize should be moved to ICampaign
export type ICampaignSize = {
  count?: number | string;
  loading: boolean;
  rawCount?: {
    audienceCount?: number;
  };
};

export const MAX_AUDIENCE = 100000000;
export const MAX_AUDIENCE_FRIENDLY_STR = '100M';

export const getReachFromCampaignSize = (campaignSizeObj: ICampaignSize) => {
  const rawCount = path(['rawCount', 'audienceCount'], campaignSizeObj) || 0;
  const { count = 0 } = campaignSizeObj;
  const campaignCount = typeof count === 'string' ? Number(count) : count;
  return Math.max(rawCount, Number.isNaN(campaignCount) ? 0 : campaignCount);
};

export const getReachFromCampaign = (campaign: ICampaign) => {
  const campaignSizeObj = path(['general', 'campaignSize'], campaign) || { loading: false };
  return getReachFromCampaignSize(campaignSizeObj);
};

export const maxAudienceProp = 'customerCountPerExecution';

export const isMaxAudienceRequired = (triggerType: string) =>
  triggerType !== triggerTypes.location && triggerType !== triggerTypes.cartAbandon && triggerType !== triggerTypes.eventsBased;
