import * as React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';

export const ImageAvatar = ({ classes, picture = '' }) => <Avatar alt="user" src={picture} className={classes.root} />;

ImageAvatar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  picture: PropTypes.string,
};
