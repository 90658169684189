import * as types from './types';
import { fetch } from '../../commonStates';

const initialState = {
  ...fetch,
  allIds: [],
  byId: {},
};

const templates = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_TEMPLATES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.GET_ALL_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ...payload.templatesLookups,
        ...payload.paged,
      };

    case types.GET_ALL_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};

export default templates;
