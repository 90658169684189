import React from 'react';
import styled from 'styled-components';

import { withAppConfig } from 'app/decorators';
import { sc } from 'app/styles';
import { IAppConfig } from 'configs/apps/types';
import BannerSelection from './BannerSelection';

type Props = {
  appConfig: IAppConfig;
};

const Creation = ({ appConfig }: Props) => {
  return (
    <Container>
      <BannerSelection />
    </Container>
  );
};

export default withAppConfig(Creation);

const Container = styled.section`
  & > section,
  & > div,
  & > section {
    margin-bottom: ${sc.gutter};
  }
`;
