import * as React from 'react';
import PropTypes from 'prop-types';

import { TableCell } from '../Table/TableCell';
import { TableRow } from '../Table/TableRow';

export const LoadingTable = ({ columns }) => (
  <TableRow>
    <TableCell colSpan={columns.length}>Loading...</TableCell>
  </TableRow>
);

LoadingTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};
