import api from 'app/utilities/api';

import { UPLOAD_IMAGE } from './endpoints';

export const uploadImage = (file: File, widgetType?: string): Promise<{ imageUrl: string }> => {
  const formData = new FormData();
  formData.append('image', file);
  if (widgetType) {
    formData.append('widget_type', widgetType);
  }
  return api.post(UPLOAD_IMAGE, { body: formData });
};
