import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withAppConfig } from 'app/decorators';
import { campaignsOperations, campaignsSelectors } from 'app/ducks';
import withReporting from 'app/hocs/withReporting';
import withSearchBar from 'app/hocs/withReporting/withSearchBar';

import Reporting from './Reporting';
import { searchBarDefiner, searchDefiner } from './search.config';

const mapStateToProps = state => ({
  campaigns: campaignsSelectors.campaigns(state),
  error: state.campaigns.campaigns.error,
  isLoading: state.campaigns.campaigns.loading,
  userEmail: state.user.email,
  user: state.user, // TODO: Only need timezone
});

const mapDispatchToProps = {
  bannerUpdate: campaignsOperations.updateBanner,
  fetchCampaigns: campaignsOperations.getAllCampaignsPaged,
  schedulingUpdate: campaignsOperations.updateScheduling,
  selectRow: campaignsOperations.selectRow,
};

const enhance = compose(
  withAppConfig,
  connect(mapStateToProps, mapDispatchToProps),
  withReporting('fetchCampaigns', campaignsSelectors.paging, {
    searchDefiner,
  }),

  withSearchBar(searchBarDefiner),
);

export default enhance(Reporting);
