import moment from 'moment';

export const dateTimeCmaFormat = `${moment.HTML5_FMT.DATE}T${moment.HTML5_FMT.TIME_SECONDS}`;
export const dateTimeSecFormat = `${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME_SECONDS}`;
export const defaultDateTimeFormat = `${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME}`;
export const defaultDateFormat = moment.HTML5_FMT.DATE;
export const defaultTimeFormat = moment.HTML5_FMT.TIME;

export const tzJapan = 'Asia/Tokyo';
export const tzUtc = 'Etc/UTC';
