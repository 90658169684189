import React from 'react';
import { CampaignAttributesScope, fetchCampaignAttributes, fetchUserBudgetTokens } from 'app/api/cashbackPromo';
import { cashbackTypes } from 'app/features/Cashback/constants';

const MEDIUM = 'cashback';

export const useMetaData = (cashbackType: string) => {
  const [campaignAttributes, setCampaignAttributes] = React.useState([]);
  const [userBudgetTokens, setUserBudgetTokens] = React.useState([]);
  const [loadMeta, setLoadMeta] = React.useState(false);

  const fetchCampaignAttributesAPI = React.useCallback(async (scope, medium) => {
    try {
      const fetchedCampaignAttributes = await fetchCampaignAttributes(scope, medium);
      setCampaignAttributes(fetchedCampaignAttributes);
    } catch (error) {
      console.log(error);
      // display error
    }
  }, []);

  const fetchUserBudgetTokensAPI = React.useCallback(async () => {
    try {
      const fetchedUserBudgetTokens = await fetchUserBudgetTokens();
      setUserBudgetTokens(fetchedUserBudgetTokens);
    } catch (error) {
      console.log(error);
      // display error
    }
  }, []);

  React.useEffect(() => {
    const scope = cashbackType === cashbackTypes.regular ? CampaignAttributesScope.RegularBanner : CampaignAttributesScope.JourneyBanner;

    setLoadMeta(true);

    const promises = [fetchCampaignAttributesAPI(scope, MEDIUM)];
    if (cashbackType === cashbackTypes.regular) {
      promises.push(fetchUserBudgetTokensAPI());
    }

    Promise.all(promises)
      .then(() => setLoadMeta(false))
      .catch(error => {
        console.log(error);
        setLoadMeta(false);
      });
  }, [cashbackType, fetchCampaignAttributesAPI, fetchUserBudgetTokensAPI]);

  return {
    campaignAttributes,
    userBudgetTokens,
    loadMeta,
  };
};
