import { BmsError } from 'app/api/bannerManagementV2/types';
import { Action } from 'app/types';
import { IStorefront, IView } from 'app/types/BannerManagement';

import { IFetch } from '../../commonStates';
import { fetch } from '../../commonStates';
import * as types from './types';

export type State = IFetch & {
  deletingViewId?: number | null | undefined;
  enablingViewId?: number | null | undefined;
  saving?: boolean;
  storefront: IStorefront | null | undefined;
  views: Array<IView>;
  viewsError?: BmsError | null | undefined;
  viewsLoading: boolean;
};

const initialState: State = {
  ...fetch,
  deletingViewId: null,
  enablingViewId: null,
  saving: false,
  storefront: null,
  views: [],
  viewsLoading: false,
  viewsError: null,
};

export default (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    // === STOREFRONTS ===

    case types.GET_STOREFRONT_FAILED:
      return {
        ...state,
        error: payload.error,
        loading: false,
        storefront: null,
      };

    case types.GET_STOREFRONT_START:
      return {
        ...state,
        error: null,
        loading: true,
        storefront: null,
      };

    case types.GET_STOREFRONT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        storefront: payload.storefront,
      };

    case types.RESET_STOREFRONT:
      return initialState;

    case types.SAVE_STOREFRONT_FAILED:
      return {
        ...state,
        error: payload.error,
        saving: false,
      };

    case types.SAVE_STOREFRONT_START:
      return {
        ...state,
        error: null,
        saving: true,
      };

    case types.SAVE_STOREFRONT_SUCCESS:
      return {
        ...state,
        error: null,
        saving: false,
      };

    case types.SET_STOREFRONT_FIELD:
      return {
        ...state,
        storefront: {
          ...state.storefront,
          ...payload.field,
        },
      };

    case types.SET_STOREFRONT_TYPE:
      return {
        ...state,
        storefrontType: payload.storefrontType,
      };

    // === VIEWS ===

    case types.DELETE_VIEW_FAILED:
      return {
        ...state,
        deletingViewId: null,
        viewsError: payload.error,
      };

    case types.DELETE_VIEW_START:
      return {
        ...state,
        deletingViewId: payload.viewId,
      };

    case types.DELETE_VIEW_SUCCESS:
      return {
        ...state,
        deletingViewId: null,
        views: state.views.filter(v => v.id !== payload.viewId),
      };

    case types.GET_VIEWS_FAILED:
      return {
        ...state,
        views: [],
        viewsError: payload.error,
        viewsLoading: false,
      };

    case types.GET_VIEWS_START:
      return {
        ...state,
        views: [],
        viewsError: null,
        viewsLoading: true,
      };

    case types.GET_VIEWS_SUCCESS:
      return {
        ...state,
        views: payload.views,
        viewsError: null,
        viewsLoading: false,
      };

    // TODO: Distinguish between the error and successes
    case types.ENABLE_ENGAGE_FAILED:
      // TODO: Display an error and the reason
      return {
        ...state,
        enablingViewId: null,
      };

    case types.ENABLE_ENGAGE_START:
      return {
        ...state,
        enablingViewId: payload.viewId,
      };

    case types.ENABLE_ENGAGE_SUCCESS: {
      const { views } = state;
      const { viewId } = payload;
      console.debug('UPDATE_ENABLE_ENGAGE_SUCCESS', viewId, views);

      // const viewsToUpdate = views.slice();
      // const idx = viewsToUpdate.findIndex(v => v.id === viewId);

      // if (idx > -1) {
      //   viewsToUpdate[idx].managed_by_engage = true;
      // }

      // return update(state, {
      //   enablingId: { $set: undefined },
      //   views: { $set: viewsToUpdate },
      // });

      return {
        ...state,
        enablingViewId: null,
      };
    }

    default:
      return state;
  }
};
