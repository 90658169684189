const ROOT = '/ec/view';
const byViewId = (viewId: number) => `${ROOT}/${viewId}`;

export const DELETE_VIEW = byViewId;

export const DELETE_VIEW_ITEMS = (viewId: number) => `${byViewId(viewId)}/viewitem`;

export const GET_VIEW = byViewId;

export const GET_VIEWS = ROOT;

export const GET_VIEW_ITEM_BY_BANNER_ID = (viewId: number, bannerId: number) => `${GET_VIEW(viewId)}/viewitem/banner/${String(bannerId)}`;

export const GET_VIEW_ITEM_BY_CREATIVE_ID = (viewId: number, creativeId: number) =>
  `${GET_VIEW(viewId)}/viewitem/sourcecreative/${String(creativeId)}`;

export const GET_WIDGET_TYPES = `${ROOT}/types`;

export const POST_VIEW = ROOT;

export const PUT_SLOT_ORDER = (viewId: number) => `${byViewId(viewId)}/slot`;

export const PUT_VIEW = byViewId;

const STOREFRONT_PROXY_ROOT = (entityType: string, entityId: number, decoratorId: number) =>
  `/ec/proxy/${entityType}/${entityId}/decorator/${decoratorId}/view`;

export const GET_STOREFRONT_PROXY_VIEW = (entityType: string, entityId: number, decoratorId: number, viewId: number) =>
  `${STOREFRONT_PROXY_ROOT(entityType, entityId, decoratorId)}/${viewId}`;

export const POST_ENABLE_ENGAGE_FOR_VIEW = (entityType: string, entityId: number, decoratorId: number, viewId: number) =>
  `${GET_STOREFRONT_PROXY_VIEW(entityType, entityId, decoratorId, viewId)}/manage`;
