import * as React from 'react';
import Button from '../Button';

import { Props } from './types';

export const ButtonGroup = ({ buttons, active, onClick, size }: Props) => {
  const handleClick = ({ target: { id } }) => onClick(id);
  return (
    <section data-qa="button-group">
      {buttons.map(({ name, id }) => (
        <Button data-qa={`button-${id}`} key={id} type={active === id ? 'primary' : 'secondary'} id={id} onClick={handleClick} size={size}>
          {name}
        </Button>
      ))}
    </section>
  );
};
