import { IPaymentCeiling } from 'app/features/CashbackPromoManagement/types';

import * as types from './types';

export const getError = (error: Error) => ({
  type: types.GET_ERROR,
  payload: { error },
});

export const getInit = () => ({
  type: types.GET_INIT,
});

export const getSuccess = (paymentCeilings: Array<IPaymentCeiling>) => ({
  type: types.GET_SUCCESS,
  payload: { paymentCeilings },
});

export const updatePaymentCeiling = (paymentCeiling: IPaymentCeiling) => ({
  type: types.UPDATE,
  payload: { paymentCeiling },
});
