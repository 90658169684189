import styled from 'styled-components';

import { sc } from 'app/styles';

export default styled.span`
  background-color: ${sc.primary};
  border-radius: 2px;
  color: ${sc.sectionWhiteColor};
  display: inline-block;
  justify-self: end;
  padding: 0.25rem;
  text-align: center;
  width: inherit;
`;
