import { curryN } from 'ramda';

export default curryN(2, (path: string, obj: Record<string, any>) => {
  if (!obj) {
    return undefined;
  }

  let res = { ...obj };
  path.split('.').some(segment => {
    res = res[segment];
    return !res;
  });

  return res;
});
