import { withStateHandlers, compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Actions } from './Actions';
import { actions } from './actions.config';
import { openClonePage } from '../../utils';

const withState = withStateHandlers(({ open = false, anchorEl = null }) => ({ open, anchorEl }), {
  setNode: state => el => ({ ...state, anchorEl: el }),
  togglePopover: state => () => ({ ...state, open: true }),
  handleClose: state => () => ({ ...state, open: false }),
});

const handlers = withHandlers({
  handleSelect: props => actionId => {
    const { id } = props;
    switch (actionId) {
      case actions.newTab.id: {
        return window.open(`/segments/${id}`);
      }
      case actions.clone.id: {
        const { history } = props;
        return openClonePage(history, { id });
      }
      default:
        return undefined;
    }
  },
});

const enhance = compose(withState, withRouter, handlers);

export default enhance(Actions);
