import React from 'react';
import { useSelector } from 'react-redux';
import { getTemplates } from 'app/api/templateManagement';
import { mapTemplateSelection } from 'app/api/templateManagement/mappers';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { eventsBased } from 'app/utilities/constants';

export const useInitBatchCampaign = setTemplates => {
  const {
    general: { triggerType },
  } = useSelector(
    ({
      campaigns: {
        campaign: { general },
      },
    }) => ({
      general,
    }),
  );

  const initBatchCamp = React.useCallback(async () => {
    try {
      const data = await getTemplates().then(res => mapTemplateSelection(res, ''));
      setTemplates(data);
    } catch (error) {
      displayError('Failed to fetch personalization templates. Please refresh.');
    }
  }, [setTemplates]);

  React.useEffect(() => {
    if (triggerType !== eventsBased.value) {
      initBatchCamp();
    }
  }, [initBatchCamp, triggerType]);
};
