import React, { PureComponent } from 'react';
import { Input } from 'app/midgarComponents';

type Props = {
  size: string | null | undefined;
  roundDownToNearestAmount: number;
  onChangeFn: (...args: Array<any>) => any;
};

export default class NearestLimit extends PureComponent<Props> {
  render() {
    const { size, onChangeFn, roundDownToNearestAmount } = this.props;
    return (
      <Input
        type="number"
        min={0}
        step="1"
        width={size || '150px'}
        name="roundDownToNearestAmount"
        value={roundDownToNearestAmount}
        placeholder="Round down Nearest Amount (eg: 200) *"
        onChange={({ target: { value } }) => onChangeFn({ roundDownToNearestAmount: value })}
      />
    );
  }
}
