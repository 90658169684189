import { fetchCampaignAttributes } from 'app/api/cashbackPromo';
import { ICampaignAttribute, IMedium, IScope } from 'app/features/CashbackPromoManagement/types';
import { DispatchFn, GetStateFn } from 'app/types';

import * as actions from './actions';

export const getCampaignAttributes = (scope: IScope, medium: IMedium) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const { medium: prevMedium, scope: prevScope } = getState();

  if (scope !== prevScope && medium !== prevMedium) {
    dispatch(actions.getInit());

    try {
      const campaignAttributes: Array<ICampaignAttribute> = await fetchCampaignAttributes(scope, medium);
      return dispatch(actions.getSuccess(scope, medium, campaignAttributes));
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      return dispatch(actions.getError(error));
    }
  }
};
