import { get } from 'app/utilities/http';

import { GET_BANNERS, GET_STOREFRONTS, GET_VIEWS } from './endpoints';
import { ICmaBanner, ICmaStorefront, ICmaView } from './types';

export const fetchBanners = (viewId: number, storefrontId: number, entityId: number): Promise<Array<ICmaBanner>> =>
  get(GET_BANNERS(viewId, storefrontId, entityId));

export const fetchStorefronts = (): Promise<Array<ICmaStorefront>> => get(GET_STOREFRONTS);

export const fetchViews = (storefrontId: number, entityId: number): Promise<Array<ICmaView>> => get(GET_VIEWS(storefrontId, entityId));
