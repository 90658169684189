import { compose, withHandlers, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import { withAppConfig } from 'app/decorators';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { campaignStates } from 'app/utilities/constants';
import { ICampaignState } from 'app/utilities/types';
import 'app/utilities/types';
import { IAppConfig } from 'configs/apps/types';

import {
  CAMPAIGN_BANNER_CREATE,
  CAMPAIGN_BANNER_DRAFT,
  CAMPAIGN_CASHBACK_CREATE,
  CAMPAIGN_CASHBACK_DRAFT,
  CAMPAIGN_EMAIL_CREATE,
  CAMPAIGN_EMAIL_DRAFT,
  CAMPAIGN_PROMO_CREATE,
  CAMPAIGN_PROMO_DRAFT,
  CAMPAIGN_PUSH_CREATE,
  CAMPAIGN_PUSH_DRAFT,
  CAMPAIGN_CASHBACK_APPROVE,
  CAMPAIGN_EMAIL_APPROVE,
  CAMPAIGN_PUSH_APPROVE,
  CAMPAIGN_TIMELINE_CREATE,
  CAMPAIGN_TIMELINE_DRAFT,
  CAMPAIGN_TIMELINE_APPROVE,
} from 'configs/permissions';

const editPermissionsByChannel = {
  BANNER: [CAMPAIGN_BANNER_CREATE, CAMPAIGN_BANNER_DRAFT],
  CASHBACK: [CAMPAIGN_CASHBACK_CREATE, CAMPAIGN_CASHBACK_DRAFT],
  EMAIL: [CAMPAIGN_EMAIL_CREATE, CAMPAIGN_EMAIL_DRAFT],
  PROMO: [CAMPAIGN_PROMO_CREATE, CAMPAIGN_PROMO_DRAFT],
  PUSH: [CAMPAIGN_PUSH_CREATE, CAMPAIGN_PUSH_DRAFT],
  PUSH2: [CAMPAIGN_PUSH_CREATE, CAMPAIGN_PUSH_DRAFT],
  NOTIFICATION_CENTER: [CAMPAIGN_TIMELINE_CREATE, CAMPAIGN_TIMELINE_DRAFT],
};

const approvePermissionsByChannel = {
  BANNER: [],
  CASHBACK: [CAMPAIGN_CASHBACK_APPROVE],
  EMAIL: [CAMPAIGN_EMAIL_APPROVE],
  PROMO: [],
  PUSH: [],
  PUSH2: [CAMPAIGN_PUSH_APPROVE],
  NOTIFICATION_CENTER: [CAMPAIGN_TIMELINE_APPROVE],
};

const pausePermissionsByChannel = {
  BANNER: [CAMPAIGN_BANNER_CREATE],
  CASHBACK: [CAMPAIGN_CASHBACK_CREATE, CAMPAIGN_CASHBACK_APPROVE],
  EMAIL: [CAMPAIGN_EMAIL_CREATE, CAMPAIGN_EMAIL_APPROVE],
  PROMO: [CAMPAIGN_PROMO_CREATE],
  PUSH: [CAMPAIGN_PUSH_CREATE],
  PUSH2: [CAMPAIGN_PUSH_CREATE, CAMPAIGN_PUSH_APPROVE],
  NOTIFICATION_CENTER: [CAMPAIGN_TIMELINE_CREATE, CAMPAIGN_TIMELINE_APPROVE],
};

export const ActionNames = {
  clone: 'Clone',
  edit: 'Edit',
  editBannerIds: 'Edit Banner IDs',
  editCampaignExpiry: 'Edit Campaign Expiry',
  newTab: 'Open in New Tab',
  pause: 'Pause',
  resume: 'Resume',
  stop: 'Stop',
  approve: 'Approve',
};

type ActionPropsInput = {
  allowNewTab: boolean;
  appConfig: IAppConfig;
  isBannerEditable: boolean;
  isPermitted: IsPermittedFn;
  medium: { type: string };
  state: ICampaignState;
};

const withCampaignActionProps = (allowNewTabs = false, allowBannerEdit = false) =>
  withProps(
    ({
      appConfig,
      isBannerEditable = true,
      isPermitted,
      medium: { type: channel },
      state,
    }: ActionPropsInput): {
      actionNames: Array<string>;
    } => {
      const { useStopState = false, enableDraftCampaignClone = true } = appConfig.campaigns || {};

      const canEdit = isPermitted(editPermissionsByChannel[channel] || []);
      const canPause = isPermitted(pausePermissionsByChannel[channel] || []);
      const canApprove = isPermitted(approvePermissionsByChannel[channel] || []);

      const bannerEditActions =
        allowBannerEdit && isBannerEditable && canPause && channel === 'BANNER'
          ? [ActionNames.editBannerIds, ActionNames.editCampaignExpiry]
          : [];
      const draftEditActions = canEdit ? [ActionNames.edit] : [];
      const draftApproveActions = canApprove ? [ActionNames.approve] : [];
      const cloneActions = canEdit ? [ActionNames.clone] : [];
      const newTabActions = !!allowNewTabs && canEdit ? [ActionNames.newTab] : [];
      const pauseActions = canPause ? [ActionNames.pause] : [];
      if (canPause && useStopState) {
        pauseActions.push(ActionNames.stop);
      }
      const resumeActions = canPause ? [ActionNames.resume] : [];

      let actionNames = [];
      switch (state) {
        case campaignStates.draft:
          actionNames = enableDraftCampaignClone
            ? [...draftEditActions, ...cloneActions, ...draftApproveActions]
            : [...draftEditActions, ...draftApproveActions];
          break;

        case campaignStates.paused:
          actionNames = [...resumeActions, ...cloneActions, ...bannerEditActions];
          break;

        case campaignStates.started:
        case campaignStates.created:
          actionNames = [...pauseActions, ...cloneActions, ...bannerEditActions];
          break;

        case campaignStates.stopped:
          actionNames = [...cloneActions];
          break;

        default:
          actionNames = [...cloneActions];
      }

      actionNames = actionNames.concat(newTabActions);
      return { actionNames };
    },
  );

const withCampaignActionHandlers = withHandlers({
  handleAction: (props: Record<string, any>) => (actionName: string) => {
    const { activateCampaign, cloneCampaign, history, id, location, openModal, setStatus, timezone } = props;
    switch (actionName) {
      case ActionNames.approve:
        activateCampaign({ id });
        return history.push(`/campaigns/${id}`);
      case ActionNames.clone:
        cloneCampaign({ id, timezone });
        return history.push('/campaigns/new?clone=true');
      case ActionNames.edit:
        return history.push(`/campaigns/edit/${id}`);
      case ActionNames.editBannerIds:
        return openModal({ modal: 'bannerId', selectedId: id });
      case ActionNames.editCampaignExpiry:
        return openModal({ modal: 'campaignExpiry', selectedId: id });
      case ActionNames.newTab: {
        const { search } = location;
        return window.open(`/campaigns/${id}/${search}`);
      }
      case ActionNames.pause:
        return setStatus({ id, status: campaignStates.paused });
      case ActionNames.resume:
        return setStatus({ id, status: campaignStates.created });
      case ActionNames.stop:
        return setStatus({ id, status: campaignStates.stopped });
      default:
        return {};
    }
  },
});

export const withCampaignActions = ({ allowNewTabs, allowBannerEdit }: { allowNewTabs?: boolean; allowBannerEdit?: boolean }) =>
  compose(withRouter, withAppConfig, withCampaignActionProps(allowNewTabs, allowBannerEdit), withCampaignActionHandlers);
