import * as React from 'react';

import { TextField } from 'app/midgarComponents';

type Props = {
  className?: string;
  onSearch: (arg0: string) => void;
  value?: string;
};

type State = {
  value: string;
};

export default class Search extends React.PureComponent<Props, State> {
  state = {
    value: this.props.value || '', // eslint-disable-line react/destructuring-assignment
  };

  handleChangeValue = ev => {
    this.setState({ value: ev.target.value });
  };

  handleKeyUp = ({ key }) => {
    if (key === 'Enter') {
      const { onSearch } = this.props;
      const { value } = this.state;
      onSearch(value);
    }
  };

  render() {
    const { className } = this.props;

    const { value } = this.state;

    return (
      <TextField
        id="mp-search"
        name="mp-search"
        label="Search"
        value={value}
        onChange={this.handleChangeValue}
        onKeyUp={this.handleKeyUp}
        className={className}
      />
    );
  }
}
