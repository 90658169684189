import React from 'react';
import { StateChip, Label, Section } from 'app/features/Campaigns/Overview/common/ChannelDetailsComponents';
import sc from 'app/styles/config';
import { red, green } from '@material-ui/core/colors';

export const ChannelStatus = ({ label = 'Channel Status:', state }: { label?: string; state: keyof typeof statusColors }) => (
  <Section>
    <Label>{label}</Label>
    <StateChip state={state}>{state}</StateChip>
  </Section>
);

export const statusColors = {
  DRAFT: sc.tertiary,
  STARTED: green.A400,
  CREATED: green[500],
  PAUSED: 'grey',
  ERROR: red[500],
  FINISHED: '#3f51b5',
  STOPPED: 'grey',
} as const;
