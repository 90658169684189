import React from 'react';
import { prop, equals } from 'ramda';
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import TestDelivery from 'app/components/TestDelivery';
import Personalization from 'app/features/Campaigns/Overview/common/Personalization';
import { ConnectedPush2 } from 'app/ducks';
import { getSiteIds } from 'app/api/campaigns/siteIds';
import { DISPLAY as display } from '../push2ConfigConstants';
import { PUSH2_SITE_ID_PARAM_MEDIUM_NAME } from 'app/features/Push2/Creation/Push2Constants';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { Grid } from 'app/midgarComponents';
import { prettifyPredicates } from 'app/midgarComponents/PredicateBuilder/helper';
import { triggerDisplayValues } from 'app/utilities/constants';
import { formatDate, formatTime } from 'app/utilities/date';
import { getChannelCreativeConfig } from 'configs/channels';
import { push2MediumId } from 'configs/channels/mediumIds';
import { channelNamePush2 } from 'configs/channels/constants';
import { equalsArray } from 'app/helpers/RamdaHelpers/helpers';
import { highlightCriteria } from 'app/features/Campaigns/utils';
import * as push2Actions from 'app/ducks/campaigns/campaign/push2Creative/actions';

import {
  MainPage,
  Header,
  Name,
  Details,
  LeftColumn,
  RightColumn,
  Label,
  Text,
  Section,
} from '../../Campaigns/Overview/common/ChannelDetailsComponents';

import RecurringOverview from '../../Channels/RecurringOverview';

import { IAppConfig } from 'configs/apps/types';
import { ChannelStatus } from '../../../components/ChannelStatus/ChannelStatus';

const printLabelText = (label, text, highlight) => (
  <Section $highlight={highlight}>
    <Label>{label}</Label>
    <Text>{text || 'Not Set'}</Text>
  </Section>
);

const deeplinkOverviewValue = (deeplinkContainer, push2Sites = [], siteId) => {
  if (!deeplinkContainer || !siteId || !Array.isArray(push2Sites) || !push2Sites.length) return '';

  const selectedSite = push2Sites.find(site => +site.id === +siteId);

  if (selectedSite?.deeplinkType === 'standard') return deeplinkContainer.value;

  let finalizedDeeplinkText = '[Legacy] ';

  if (deeplinkContainer.legacyDeeplink && Object.keys(deeplinkContainer.legacyDeeplink).length) {
    const extras = deeplinkContainer.legacyDeeplink.options;
    const extrasText = extras && Object.keys(extras).length ? `- ${JSON.stringify(extras)}` : '(No Extras)';

    finalizedDeeplinkText += `${deeplinkContainer.legacyDeeplink.label || 'Not Set'} ${extrasText}`;
  }

  return finalizedDeeplinkText;
};

const highlightDeeplink = (sites = [], updated, original) => {
  if (!original.deeplink) return false;

  const updatedSite = sites.find(site => +site.id === +updated.siteId);
  const originalSite = sites.find(site => +site.id === +original.siteId);

  if (updatedSite?.deeplinkType !== originalSite?.deeplinkType) return true;

  if (updatedSite?.deeplinkType === 'standard' && originalSite?.deeplinkType === 'standard') {
    return (updated.deeplink?.value || original.deeplink?.value) && updated.deeplink?.value !== original.deeplink?.value;
  }

  const updatedLegacyDeeplink = updated.deeplink?.legacyDeeplink || {};
  const originalLegacyDeeplink = original.deeplink?.legacyDeeplink || {};

  return (
    updatedLegacyDeeplink.value !== originalLegacyDeeplink.value || !equals(updatedLegacyDeeplink.options, originalLegacyDeeplink.options)
  );
};

const mapStateToProps = ({
  campaigns: {
    campaign: {
      push2Creative: { sites },
    },
  },
}) => ({
  sites,
});

const mapDispatchToProps = {
  updatePush2Field: push2Actions.updatePush2Field,
};

class Push2Overview extends React.PureComponent<{
  appConfig: IAppConfig;
  variables: Array<any>;
  scheduling: Partial<{
    expiryDate: string;
    startDate: string;
    startTime: string;
    validDays: string;
    miniumInterval: string;
    maximumLimit: string;
  }>;

  state: string;
  cron: string;
  days: Array<string>;
  quotaPeriodInDays: number;
  triggerType: string;
  testDelivery: (...args: Array<any>) => any;
  dedupeStrategy: string;
  criteria: Partial<{
    predicateFilter: string;
    eventName: string;
    predicateFilterObject: Array<any>;
    delayTime: null | { value: number; unit: string };
  }>;

  startDate: string;
  startTime: string;
  originalCampaign?: Record<string, any>;
}> {
  componentDidMount = async () => {
    const { sites, updatePush2Field } = this.props;

    if (!Array.isArray(sites) || !sites.length) {
      try {
        const push2Sites = await getSiteIds(PUSH2_SITE_ID_PARAM_MEDIUM_NAME);
        updatePush2Field({ sites: push2Sites });
      } catch (error) {
        displayError('Error fetching sites');
      }
    }
  };

  render() {
    const {
      appConfig,
      variables,
      state,
      startDate,
      startTime,
      scheduling,
      triggerType,
      dedupeStrategy,
      testDelivery,
      criteria,
      originalCampaign,
    } = this.props;
    const push2CreativeConfig = appConfig ? getChannelCreativeConfig(appConfig, push2MediumId) : {};

    let predicateFilter = null;
    let eventName = null;
    let predicateFilterObject = null;

    if (criteria) {
      ({ predicateFilter, eventName, predicateFilterObject } = criteria);
    }

    return (
      <MainPage>
        <ConnectedPush2>
          {({
            platforms,
            type,
            merchantRole,
            payload: {
              notification: {
                content: { title, body, summary, position, duration, expiry, expiry_type },
                interactive: { click },
              },

              context: {
                utm_content, // eslint-disable-line camelcase
                utm_source, // eslint-disable-line camelcase
              },
            },

            pushToChat,
            chatCreative,
            site_id,
            sites,
          }) => (
            <>
              <Header>
                <Name>{display}</Name>
                <ChannelStatus state={state} />
              </Header>
              <Details>
                <LeftColumn>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} data-qa="overview-trigger-type">
                      {printLabelText(
                        'Trigger Type: ',
                        triggerDisplayValues[triggerType],
                        originalCampaign && originalCampaign.triggerType !== triggerType,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} data-qa="overview-dedupe-strategy">
                      {printLabelText(
                        'Dedupe Strategy: ',
                        dedupeStrategy,
                        originalCampaign && originalCampaign.dedupeStrategy !== dedupeStrategy,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} data-qa="overview-start-date">
                      {printLabelText(
                        'Start Date: ',
                        formatDate(prop('Start Date', scheduling)),
                        originalCampaign && originalCampaign.startDate !== startDate,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} data-qa="overview-trigger-time">
                      {printLabelText(
                        'Trigger time: ',
                        formatTime(prop('Start Date', scheduling)),
                        originalCampaign && originalCampaign.startTime !== startTime,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} data-qa="overview-push-type">
                      {printLabelText('Push Type: ', type, originalCampaign && originalCampaign.creative?.type !== type)}
                    </Grid>
                    {pushToChat && (
                      <>
                        <Grid item xs={12} sm={6}>
                          {printLabelText(
                            'Push To Chat: ',
                            'yes',
                            originalCampaign && originalCampaign.creative?.pushToChat !== pushToChat,
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {printLabelText(
                            'Chat Channels: ',
                            chatCreative && chatCreative.channel_id ? chatCreative.channel_id : '',
                            originalCampaign &&
                              (chatCreative?.channel_id || originalCampaign.creative?.chatCreative?.channel_id) &&
                              chatCreative?.channel_id !== originalCampaign.creative?.chatCreative?.channel_id,
                          )}
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sm={6} data-qa="overview-supported-platforms">
                      {printLabelText(
                        'Supported Platforms: ',
                        platforms.join(', '),
                        originalCampaign && !equalsArray(platforms, originalCampaign.creative?.platforms),
                      )}
                    </Grid>
                    {push2CreativeConfig.useCampaignRole && (
                      <Grid item xs={12} sm={6} data-qa="overview-merchant-role">
                        {printLabelText('Merchant Role: ', merchantRole)}
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6} data-qa="overview-placement">
                      {printLabelText(
                        'Placement: ',
                        position,
                        originalCampaign &&
                          (position || originalCampaign.creative?.payload?.context?.position) &&
                          position !== originalCampaign.creative?.payload?.context?.position,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} data-qa="overview-visibility-duration">
                      {printLabelText(
                        'Visibility Duration: ',
                        duration,
                        originalCampaign &&
                          (duration || originalCampaign.creative?.payload?.context?.duration) &&
                          duration !== originalCampaign.creative?.payload?.context?.duration,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} hide={expiry_type === undefined} data-qa="overview-expiry-type">
                      {printLabelText(
                        'Expiry Type: ',
                        expiry_type,
                        originalCampaign &&
                          (expiry_type || originalCampaign.creative?.payload?.context?.expiry_type) &&
                          expiry_type !== originalCampaign.creative?.payload?.context?.expiry_type,
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} hide={expiry === undefined} data-qa="overview-expires-on-after">
                      <Section
                        $highlight={
                          originalCampaign &&
                          (expiry || originalCampaign.creative?.payload?.context?.expiry) &&
                          expiry !== originalCampaign.creative?.payload?.context?.expiry
                        }
                      >
                        <Label>Expires on/after:</Label>
                        {expiry ? (
                          <Text>
                            {expiry} {expiry_type === 'relative' ? 'days' : ''}
                          </Text>
                        ) : (
                          <Text>Not Set</Text>
                        )}
                      </Section>
                    </Grid>
                    <RecurringOverview {...this.props} />
                  </Grid>
                  <Grid container spacing={3}>
                    {triggerType === 'REAL_TIME' && (
                      <Grid item xs={12} sm={6} data-qa="overview-trigger-event">
                        {printLabelText(
                          'Trigger Event: ',
                          eventName || 'N/A',
                          originalCampaign &&
                            (eventName || originalCampaign.criteria?.eventName) &&
                            eventName !== originalCampaign.criteria?.eventName,
                        )}
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6} data-qa="overview-deeplink">
                      {printLabelText(
                        'Deeplink: ',
                        deeplinkOverviewValue(click, sites, site_id),
                        highlightDeeplink(
                          sites,
                          { siteId: site_id, deeplink: click },
                          {
                            siteId: originalCampaign?.creative?.site_id,
                            deeplink: originalCampaign?.creative?.payload?.notification?.interactive?.click,
                          },
                        ),
                      )}
                    </Grid>
                    {triggerType === 'REAL_TIME' && (
                      <>
                        {criteria?.delayTime && (
                          <Grid item xs={12} sm={12}>
                            {printLabelText('Trigger in', `${criteria.delayTime?.value} ${criteria.delayTime?.unit}`)}
                          </Grid>
                        )}

                        <Grid item xs={12} sm={12} data-qa="overview-predicate-filter">
                          {printLabelText(
                            'Predicate Filter: ',
                            predicateFilter ||
                              (predicateFilterObject && predicateFilterObject.length ? prettifyPredicates(predicateFilterObject) : 'N/A'),
                            originalCampaign &&
                              highlightCriteria({
                                jsonArr: predicateFilterObject,
                                str: predicateFilter,
                                originalJsonArr: originalCampaign.criteria?.predicateFilterObject,
                                originalStr: originalCampaign.criteria?.predicateFilter,
                              }),
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Personalization variables={variables} />
                  </Grid>
                </LeftColumn>
                <RightColumn>
                  <section>
                    <TestDelivery buttonText="Send Samples" deliveryChannel={channelNamePush2} handleSend={testDelivery} rowsMax={3} />
                  </section>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} data-qa="overview-title">
                      {printLabelText(
                        'Title: ',
                        title,
                        originalCampaign &&
                          (title || originalCampaign.creative?.payload?.notification?.content?.title) &&
                          title !== originalCampaign.creative?.payload?.notification?.content?.title,
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} data-qa="overview-body">
                      {printLabelText(
                        'Body: ',
                        body,
                        originalCampaign &&
                          (body || originalCampaign.creative?.payload?.notification?.content?.body) &&
                          body !== originalCampaign.creative?.payload?.notification?.content?.body,
                      )}
                    </Grid>

                    {summary && (
                      <Grid item xs={12} sm={12} data-qa="overview-summary">
                        {printLabelText(
                          'Summary: ',
                          summary,
                          originalCampaign &&
                            (summary || originalCampaign.creative?.payload?.notification?.content?.summary) &&
                            summary !== originalCampaign.creative?.payload?.notification?.content?.summary,
                        )}
                      </Grid>
                    )}

                    {utm_content && (
                      <Grid item xs={12} sm={12} data-qa="overview-utm-content">
                        {printLabelText(
                          'UTM Content: ',
                          utm_content,
                          originalCampaign &&
                            (utm_content || originalCampaign.creative?.payload?.context?.utm_content) &&
                            utm_content !== originalCampaign.creative?.payload?.context?.utm_content,
                        )}
                      </Grid>
                    )}

                    {utm_source && (
                      <Grid item xs={12} sm={12} data-qa="overview-utm-source">
                        {printLabelText(
                          'UTM Source: ',
                          utm_source,
                          originalCampaign &&
                            (utm_source || originalCampaign.creative?.payload?.context?.utm_source) &&
                            utm_source !== originalCampaign.creative?.payload?.context?.utm_source,
                        )}
                      </Grid>
                    )}
                  </Grid>
                </RightColumn>
              </Details>
            </>
          )}
        </ConnectedPush2>
      </MainPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Push2Overview);
