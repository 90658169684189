import * as React from 'react';
import styled from 'styled-components';

import { IMasterplanApiParams } from 'app/api/masterplan/types';
import { withAppConfig } from 'app/decorators';
import { DateField as _DateField, Dropdown as _Dropdown } from 'app/midgarComponents';
import DownloadCSV from 'app/components/DownloadCSV';
import { sc } from 'app/styles';
import { IAppConfig } from 'configs/apps/types';

import Search from './Search';
import { triggerOptionsByMedium } from '../constants';
import { getChannelOptions } from '../utils';

export type IMasterplanFilterProps = IMasterplanApiParams & {
  handleChangeDate: (arg0: string) => void;
  handleChangeMedium: (arg0: { id: string }) => void;
  handleChangeTrigger: (arg0: { id: string }) => void;
  handleSearch: (arg0: string) => void;
  search?: string;
};

type Props = IMasterplanFilterProps & {
  appConfig: IAppConfig;
  downloadCSVHeaders: (...args: Array<any>) => any;
  downloadCSVData: (...args: Array<any>) => any;
  downloadCSVFilename: (...args: Array<any>) => any;
};

class Filters extends React.PureComponent<Props> {
  render() {
    const {
      appConfig,
      date,
      handleChangeDate,
      handleChangeMedium,
      handleChangeTrigger,
      handleSearch,
      medium,
      search,
      trigger,
      downloadCSVHeaders,
      downloadCSVData,
      downloadCSVFilename,
    } = this.props;

    const channelOptions = getChannelOptions(appConfig);
    const selectedChannelOption = channelOptions.find(ch => ch.id === medium);
    const triggerOptions = triggerOptionsByMedium[medium];
    const selectedTriggerOption = triggerOptions?.find(opt => opt.id === trigger);

    return (
      <Container>
        <Group>
          <Dropdown label="Channel" value={selectedChannelOption} options={channelOptions} onChange={handleChangeMedium} />

          <TriggerDropdown label="Campaign Type" value={selectedTriggerOption} options={triggerOptions} onChange={handleChangeTrigger} />

          <DateField id="mp-date" name="mp-date" label="Date" value={date} handleSelect={handleChangeDate} />
        </Group>

        <Group>
          <ExecutionNameSearch value={search} onSearch={handleSearch} />

          <DownloadCSV headers={downloadCSVHeaders} data={downloadCSVData} filename={downloadCSVFilename} />
        </Group>
      </Container>
    );
  }
}

export default withAppConfig(Filters);

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${sc.gutterSmaller};
`;

const Dropdown = styled(_Dropdown)`
  width: 8rem;
`;

const DateField = styled(_DateField)`
  width: 8rem;
`;

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: ${sc.gutterSmallest};
    margin-bottom: ${sc.gutterSmaller};
    flex-grow: 1;
  }
  & > div:last-child {
    margin-right: 0;
  }
  & > a {
    margin-bottom: ${sc.gutterSmaller};
    flex-grow: 1;
  }
`;

const TriggerDropdown = styled(Dropdown)`
  width: 15rem;
`;

const ExecutionNameSearch = styled(Search)`
  width: 11rem;
`;
