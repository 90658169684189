import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { isNotEmpty } from 'ramda-adjunct';
import { ICriteria } from 'app/features/Cashback/types';
import { IPromoEvent } from 'app/features/CashbackPromoManagement/types';
import { Button, Dropdown, Icon, PredicateBuilder, Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { isValid, validCriteriaCombinations } from 'app/midgarComponents/PredicateBuilder/helper';
import { promoEventFieldTypes } from '../constants';

type Props = {
  criteria: ICriteria;
  getAllEvents: () => any;
  promoEvents: Array<any>;
  updateCriteria: (arg0: Record<string, any>) => any;
};

type State = {
  showWarning: boolean;
};

export class EventsCriteria extends PureComponent<Props, State> {
  state = {
    showWarning: false,
  };

  componentDidMount() {
    const { getAllEvents } = this.props;
    getAllEvents();
  }

  getPromoEventOptions = () => {
    const { promoEvents } = this.props;
    const options = promoEvents.map(ev => ({
      ...ev,
      ...{ label: ev.name },
    }));

    return options;
  };

  handleDelete = () => this.updateRules([]);

  updateRules = rules => {
    const { updateCriteria } = this.props;
    this.setState({
      showWarning: !validCriteriaCombinations(rules),
    });

    updateCriteria({
      eventCriteriaJson: rules,
      eventCriteria: null,
    });
  };

  getPromoEventFields = (eventType: IPromoEvent) => {
    if (eventType && eventType.id) {
      const { promoEvents } = this.props;
      const matchedEvent = promoEvents.find(event => event.id === eventType.id);
      if (matchedEvent && matchedEvent.promoEventFields) {
        const matchedEventFields = matchedEvent.promoEventFields.filter(({ fieldSource }) => fieldSource === promoEventFieldTypes.event);
        return matchedEventFields;
      }
    }
    return [];
  };

  render() {
    const { criteria, promoEvents, updateCriteria } = this.props;
    const { eventType, eventCriteria } = criteria;
    const eventCriteriaJson = criteria.eventCriteriaJson || [];

    if (promoEvents === null) {
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    }

    if ((eventCriteriaJson && eventCriteriaJson.length > 0) || !eventCriteria) {
      return (
        <Section data-qa="events-criteria">
          <Label>
            <LabelText>Select the event type</LabelText>
            {eventType && isValid(eventCriteriaJson) ? (
              <ValidationIcon name="check-circle" size={22} color={sc.success} />
            ) : (
              <ValidationIcon name="warning" size={22} color={sc.warning} />
            )}

            {this.state.showWarning && (
              <Pill>
                Caution! This combination may not work properly and the number of objects will be very few. Please check this again.
              </Pill>
            )}
          </Label>
          <>
            <Row>
              <Dropdown
                error={!eventType}
                id="eventType"
                label="Select the event type"
                onChange={val => updateCriteria({ eventType: val, eventCriteriaJson: [] })}
                options={this.getPromoEventOptions()}
                required
                size="300"
                value={eventType || {}}
              />
            </Row>

            {eventType && isNotEmpty(eventType) && (
              <PredicateBuilder
                sourceEvent={eventType}
                sourceFields={this.getPromoEventFields(eventType)}
                rules={eventCriteriaJson}
                updateRules={this.updateRules}
                eventLabel="Select promo event field"
              />
            )}
          </>
        </Section>
      );
    }

    return (
      <Section>
        <Row>Event Type: {eventType.label || eventType.name || 'N/A'}</Row>

        <CriteriaContainer>{eventCriteria}</CriteriaContainer>

        <Button type="secondary" onClick={this.handleDelete}>
          Start Over
        </Button>
      </Section>
    );
  }
}

export default EventsCriteria;
const CriteriaContainer = styled.section`
  margin-bottom: ${sc.gutter};
  word-break: break-all;
`;

const Label = styled.div`
  text-transform: uppercase;
  color: ${sc.grey};
  margin: 0 1rem 1rem 0;
  text-align: left;
  font-size: ${sc.fontSize};
  display: flex;
`;

const LabelText = styled.span`
  min-width: 13rem;
`;

const Pill = styled.span`
  background-color: ${sc.warning};
  color: ${sc.greyDarkest};
  border-radius: 4px;
  display: inline-block;
  justify-self: end;
  padding: 0.25rem;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
  margin-top: -4px;
  text-align: justify;
  width: inherit;
`;

const Row = styled.div`
  display: flex;
  margin: 1.5rem 0;
`;

const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem 1rem 3rem 1rem;
  padding: 1rem;
`;

const SpinnerWrapper = styled.div`
  width: 250px;
  margin: 1rem 1rem 0 0;
  height: 70px;
  padding; 2rem;
`;

const ValidationIcon = styled(Icon)`
  margin-top: -4px;
  margin-left: 0.5rem;
  font-weight: 900;
`;
