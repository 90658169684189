import * as fetch from 'app/utilities/http';
import qs from 'query-string';

import endpoints from './endpoints';
import { IFetchUserIdByPhoneResult } from './types';

export const fetchDeliveredBanners = async (userId: string) => fetch.get(endpoints.fetchDeliveredBanners.replace(':userId', userId));

// TODO: Note that userId is a number here, but string for fetchDeliveredBanners. Should settle on one standard
export const fetchUpcomingBanners = async (viewId: string, userId: number, params: Record<string, any>, queries: Record<string, any>) =>
  fetch.post(endpoints.fetchUpcomingBanners.replace(':viewId', viewId).replace(':queries', qs.stringify(queries)), params);

export const fetchUserIdByPhone = async (phone: string): Promise<IFetchUserIdByPhoneResult> =>
  fetch.get(endpoints.fetchUserIdByPhone.replace(':phone', phone));
