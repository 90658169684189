import { State } from './reducers';

type Store = { storefrontsBms: State };

export const mapStateToStorefronts = ({
  storefrontsBms: { error: storefrontsError, loading: storefrontsLoading, permittedStorefronts, storefronts },
}: Store) => ({
  permittedStorefronts,
  storefronts,
  storefrontsError,
  storefrontsLoading,
});
