import { IVariantType } from 'app/types/BannerManagement';

import * as types from './types';

export const getVariantTypesFailed = (error: any) => ({
  type: types.GET_VARIANT_TYPES_FAILED,
  payload: { error },
});

export const getVariantTypesStart = () => ({
  type: types.GET_VARIANT_TYPES_START,
});

export const getVariantTypesSuccess = (variantTypes: Array<IVariantType>) => ({
  type: types.GET_VARIANT_TYPES_SUCCESS,
  payload: { variantTypes },
});
