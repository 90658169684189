import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Menu, MenuItem, ListItem, ListItemIcon, ListItemText, TextField } from '@material-ui/core';
import { Search as SearchIcon, Check as SelectionIcon, NotInterested as NotFoundIcon } from '@material-ui/icons';
import { isPlainObject } from 'ramda-adjunct';

import { R } from 'app/helpers/RamdaHelpers/helpers';
import Tooltip from '../Tooltip';

const toMenuItem = R.curry(({ selectedItemStyle, nonSelectedItemStyle }, selected, handleSelect, option) => {
  const [optionValue, optionLabel] = isPlainObject(option) ? [option.value, option.label] : [option];
  const isSelected = selected === optionValue;
  const text = optionLabel || optionValue;

  return (
    <MenuItem
      key={optionValue}
      value={optionValue}
      selected={isSelected}
      className={isSelected ? selectedItemStyle : nonSelectedItemStyle}
      onClick={event => handleSelect(event, optionValue)}
    >
      {isSelected && (
        <ListItemIcon>
          <SelectionIcon />
        </ListItemIcon>
      )}

      <ListItemText inset disableTypography primary={text} />
    </MenuItem>
  );
});

export const FilterSelect = ({
  id,
  label,
  options,
  value = '',
  handleSelect,
  handleOpen,
  handleClose,
  handleFilter,
  isDropdownOpen,
  transformOrigin,
  classes,
}) => {
  const anchorRef = React.useRef(null);
  const filterRef = React.useRef(null);

  useEffect(() => {
    if (filterRef) filterRef.current?.focus();
  }, []);

  return (
    <>
      <Tooltip title={value}>
        <TextField
          data-qa="select-textfield"
          required
          margin="dense"
          className={classes.textField}
          id={id}
          label={label}
          InputProps={{
            readOnly: true,
          }}
          ref={anchorRef}
          value={value}
          onClick={handleOpen}
          // onFocus={handleOpen}
        />
      </Tooltip>

      <Menu
        key="dropdown"
        open={isDropdownOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorReference="anchorEl"
        transformOrigin={transformOrigin}
      >
        <div
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            marginRight: '0.8rem',
          }}
        >
          <SearchIcon
            style={{
              marginTop: '1rem',
              marginLeft: '0.8rem',
              marginRight: '0.3rem',
            }}
          />

          <Input
            data-qa="search-input"
            margin="dense"
            type="search"
            placeholder="Search..."
            className={classes.textField}
            style={{
              minWidth: 200,
            }}
            onChange={handleFilter}
            ref={filterRef}
          />
        </div>

        {options.length < 1 && (
          <ListItem disabled>
            <ListItemIcon>
              <NotFoundIcon />
            </ListItemIcon>
            <ListItemText primary="No item found." secondary="Clear search to see all." style={{ padding: 0 }} />
          </ListItem>
        )}

        {options.length > 0 &&
          R.map(toMenuItem(classes, value, handleSelect), [
            {
              value: '',
              label: '(None)',
            },

            ...options,
          ])}
      </Menu>
    </>
  );
};

FilterSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
  ).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  // anchorPosition: PropTypes.objectOf(PropTypes.number).isRequired,
  // anchorOrigin: PropTypes.objectOf(PropTypes.string).isRequired,
  transformOrigin: PropTypes.objectOf(PropTypes.string).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  // style: PropTypes.objectOf(PropTypes.any),
};
