export const features = ({
  featuresSimple: {
    features: { byId, allIds },
  },
}) => allIds.map(id => byId[id]);

export const paging = ({
  featuresSimple: {
    features: { number, first, last, totalElements, size },
  },
}) => ({
  number,
  first,
  last,
  totalElements,
  size,
});

export const status = ({
  featuresSimple: {
    features: { loading, error },
  },
}) => ({ loading, error });
