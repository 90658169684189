import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { getTemplates } from 'app/api/templateManagement';
import { mapTemplateSelection } from 'app/api/templateManagement/mappers';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { eventsBased } from 'app/utilities/constants';

export const useInitBasedOnEvent = setTemplates => {
  const [currSelectedEvent, setCurrEvent] = React.useState('');

  const {
    general: { triggerType },
  } = useSelector(
    ({
      campaigns: {
        campaign: { general },
      },
    }) => ({
      general,
    }),
  );

  const selectedEvent = useSelector(state => state.features.eventName, shallowEqual);

  const init = React.useCallback(async () => {
    try {
      const data = await getTemplates().then(res => mapTemplateSelection(res, selectedEvent));
      setTemplates(data);
      setCurrEvent(selectedEvent);
    } catch (error) {
      displayError('Failed to fetch personalization templates. Please refresh.');
    }
  }, [selectedEvent, setTemplates]);

  React.useEffect(() => {
    if (triggerType === eventsBased.value && selectedEvent !== currSelectedEvent) {
      init();
    }
  }, [currSelectedEvent, init, selectedEvent, triggerType]);
};
