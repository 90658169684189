import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';

import { ImageUpload, TextField as _TextField, Input } from 'app/midgarComponents';
import { ConnectedPush2 } from 'app/ducks';
import { ApiImageUpload } from 'app/helpers/ImageUploadManager/ApiImageUpload';
import { displaySuccess, displayError } from 'app/helpers/NotificationHelpers/helpers';
import { pushTypes, deviceTypeValues } from '../Push2Constants';

export default class Overrides extends React.PureComponent<{}> {
  onUpload = async (fileUri: string, file: File, updateOverrides: (...args: Array<any>) => any) => {
    if (file) {
      const { apiFileUpload } = ApiImageUpload;
      const { status, url } = await apiFileUpload(file);

      if (status === 'SUCCESSFUL') {
        updateOverrides({ icon_src: url });
        displaySuccess('Image upload Successful');
      } else {
        displayError(`Image upload failed with the API status ${status}`);
      }
    } else {
      displayError('The file was rejected. It may have the wrong extension.');
    }
  };

  renderPushOverrides = (platforms, overrides, updateOverrides) => (
    <Flex>
      {platforms.includes('android') && (
        <HalfSection>
          <SubLabel>Android</SubLabel>
          <Container>
            <TextField
              value={overrides.expiry}
              name="expiry"
              id="expiry"
              type="date"
              label="Expires on"
              onChange={ev => updateOverrides({ expiry: ev.target.value }, deviceTypeValues.android.value)}
            />

            <Input
              value={overrides.subtext}
              name="subtext"
              data-qa="overrides-subtext"
              type="text"
              placeholder="Visiblity Duration (seconds)"
              onChange={ev => updateOverrides({ subtext: ev.target.value }, deviceTypeValues.android.value)}
            />

            <ImageUpload
              placeholder="Upload Notification Icon"
              imageUrl={overrides.icon_src}
              onUpload={(uri, file) => this.onUpload(uri, file, updateOverrides)}
              onRemove={() => updateOverrides({ icon_src: null }, deviceTypeValues.android.value)}
            />
          </Container>
        </HalfSection>
      )}

      {platforms.includes('ios') && (
        <HalfSection>
          <SubLabel>iOS</SubLabel>
          <Container>Not Applicable</Container>
        </HalfSection>
      )}
    </Flex>
  );

  renderBigPictureOverrides = (platforms, overrides, updateOverrides) => (
    <Flex>
      {platforms.includes('android') && (
        <HalfSection>
          <SubLabel>Android</SubLabel>
          <Container>
            <TextField
              value={overrides.expiry}
              name="expiry"
              id="expiry"
              type="date"
              label="Expires on"
              onChange={ev => updateOverrides({ expiry: ev.target.value }, deviceTypeValues.android.value)}
            />

            <Input
              value={overrides.subtext}
              name="subtext"
              data-qa="overrides-subtext"
              type="text"
              placeholder="Subtext"
              onChange={ev => updateOverrides({ subtext: ev.target.value }, deviceTypeValues.android.value)}
            />

            <Input
              value={overrides.summary}
              name="summary"
              data-qa="overrides-summary"
              type="text"
              placeholder="Summary"
              onChange={ev => updateOverrides({ summary: ev.target.value }, deviceTypeValues.android.value)}
            />
          </Container>
        </HalfSection>
      )}

      {platforms.includes('ios') && (
        <HalfSection>
          <SubLabel>iOS</SubLabel>
          <Container>Not Applicable</Container>
        </HalfSection>
      )}
    </Flex>
  );

  render() {
    return (
      <ConnectedPush2>
        {({ type, platforms, overrides, updateOverrides }) => {
          switch (type) {
            case pushTypes.push.id:
              return this.renderPushOverrides(platforms, overrides, updateOverrides);
            case pushTypes.bigPicture.id:
              return this.renderBigPictureOverrides(platforms, overrides, updateOverrides);
            case pushTypes.inbox.id:
            default:
              return null;
          }
        }}
      </ConnectedPush2>
    );
  }
}

const HalfSection = styled.section`
  margin: 1rem;
  padding: 1rem;
  width: 50%;
  bottom-border: 1px solid ${sc.greyLight};
`;

const Flex = styled.div`
  display: inline-flex;
  width: 100%;
`;

const SubLabel = styled.span`
  text-transform: capitalize;
  color: ${sc.secondary};
  font-size: ${sc.fontSize};
`;

const TextField = styled(_TextField)`
  margin-bottom: 1rem;
`;

const Container = styled.div`
  margin: 2rem 0;
`;
