import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import { branch, withHandlers, renderComponent } from 'recompose';

import { TableCell } from '../Table/TableCell';

const updateHandler = withHandlers({
  onChange:
    ({ column: { key }, onChange }) =>
    ({ target: { value } }) =>
      onChange({ [key]: value }),
});

const EditableColumn = updateHandler(({ column, clonedRow: row, onChange }) => (
  <TableCell>
    <TextField type={column.type || 'text'} value={row[column.key]} onChange={onChange} />
  </TableCell>
));

const renderCustomColumn = ({ customComponent, key }, { row }) => customComponent(key, row);

const renderStandardColumn = (column, { valueFormatter, row }) => (valueFormatter ? valueFormatter(column, row) : row[column.key]);

const eventPreventHandler = withHandlers({
  handleClick:
    ({ handleRowClick }) =>
    e => {
      if (handleRowClick) {
        e.preventDefault();
      }
    },
});

const StaticColumn = eventPreventHandler(({ column, handleClick, ...props }) => {
  const { row } = props;

  if (row.to) {
    return (
      <StyledCell>
        <StyledLink to={row.to} onClick={handleClick}>
          {column.customComponent ? renderCustomColumn(column, props) : renderStandardColumn(column, props)}
        </StyledLink>
      </StyledCell>
    );
  }

  return <TableCell>{column.customComponent ? renderCustomColumn(column, props) : renderStandardColumn(column, props)}</TableCell>;
});

StaticColumn.propTypes = {
  column: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  row: PropTypes.object.isRequired, // eslint-disable-line
  valueFormatter: PropTypes.func,
  handleClick: PropTypes.func,
};

export const ColumnSwitch = branch(
  ({ column: { editable }, editState }) => editable && editState,
  renderComponent(EditableColumn),
)(StaticColumn);

const StyledCell = styled.td`
  padding: 0;
  text-align: left;
  vertical-align: middle;
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 1rem 1rem 1rem 1.5rem;
  font-size: 14px;
  color: #29394f;
  text-decoration: none;
  line-height: 1.29;
`;
