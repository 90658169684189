import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import * as tmsService from 'app/api/templateManagement';
import { mapEvaluateTemplatePayload } from 'app/api/templateManagement/mappers';

const evaluateTemplate = () => async (dispatch, getState) => {
  const payload = mapEvaluateTemplatePayload(getState());

  try {
    const { data } = await tmsService.evaluateTemplate(payload);

    return data;
  } catch (error) {
    displayError(`Error evaluating template: ${error.reason}`);
    throw error;
  }
};

export { evaluateTemplate };
