import React, { PureComponent } from 'react';
import { get } from 'app/utilities/http';
import Loading from 'app/components/Loading';
import { StringSetPills } from 'app/midgarComponents/TypedAttributes/InputTypes';
import { StyledInput } from './styledComponents';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';

type Props = {
  featureId: number;
  changeValue: (...args: Array<any>) => any;
  value: string;
};

type State = {
  feature_value: [];
  loading: boolean;
};

class MultiChoiceFeatureValue extends PureComponent<Props, State> {
  state = {
    loading: true,
    feature_value: [],
  };

  componentDidMount() {
    const { featureId } = this.props;
    if (!this.state.feature_value.length) {
      get(`/features/${featureId}`)
        .then(response =>
          this.setState({
            loading: false,
            feature_value: response.feature.feature_value,
          }),
        )
        .catch(err => {
          this.setState({
            loading: false,
          });

          displayError('Error occured while fetching feature' + err);
        });
    }
  }

  handleChange = ({ target: { value } }) => this.props.changeValue(value);

  render() {
    const { value, changeValue } = this.props;
    const { loading, feature_value } = this.state;

    if (loading) {
      return <Loading />;
    }
    if (feature_value && feature_value.length) {
      return (
        <StringSetPills possibleValues={this.state.feature_value.join(',')} label="Choose from" value={value} handleChange={changeValue} />
      );
    }
    return <StyledInput type="text" placeholder="Enter comma separated values" value={value} onChange={this.handleChange} />;
  }
}

export default MultiChoiceFeatureValue;
