// API.js was written for BMS.
// Also used for Autopilot, Quota, Users, Storefronts ACL, and Announcements.

/* eslint-disable promise/always-return, promise/no-nesting */

import qs from 'query-string';
import { isEmpty } from 'ramda';

type Method = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'get' | 'put' | 'post' | 'delete';

type Options = {
  body?: Record<string, any>;
  query?: Record<string, any>;
  headers?: Record<string, any>;
};

/**
 * Http wrapper
 */
class Api {
  // eslint-disable-next-line class-methods-use-this
  request(method: Method, path: string, options: Options = {}): Promise<any> {
    const req = {
      url: path,
      method,
      headers: {},
      body: options.body,
      query: {},
      credentials: 'include',
    };

    if (options.query) {
      Object.assign(req.query, options.query);
    }

    if (options.body) {
      if (method === 'GET') {
        Object.assign(req.query, options.body);
      } else if (options.body.toString() === '[object FormData]') {
        req.body = options.body;
      } else {
        req.body = JSON.stringify(options.body);
      }
    }

    if (options.headers) {
      req.headers = { ...options.headers };
    }

    req.headers.Accept = 'application/json';

    if (options.body && options.body.toString() !== '[object FormData]') {
      req.headers['Content-Type'] = 'application/json';
    }

    if (!isEmpty(req.query)) {
      req.url += `?${qs.stringify(req.query)}`;
    }

    return new Promise((resolve: (...args: Array<any>) => any, reject: (...args: Array<any>) => any) =>
      fetch(req.url, req)
        .then((response: any) => {
          const defaultError = {
            statusText: response.statusText,
            status: response.status,
            reason: '',
            error: {
              msg: 'Invalid request',
            },
          };

          if (response.status >= 200 && response.status < 300) {
            if (response.status === 204) return resolve(response);

            return response
              .json()
              .then(data => resolve(data))
              .catch(err => reject(err));
          }
          if (response.status === 401) {
            window.location.href = '/auth-error?type=invalid-tenant';
          }

          const contentType = response.headers.get('content-type');

          if (typeof contentType === 'string' && contentType.includes('application/json')) {
            // eslint-disable-next-line promise/no-nesting, promise/always-return
            return (
              // eslint-disable-next-line promise/no-nesting
              response
                .json()
                // eslint-disable-next-line promise/always-return
                .then((data: Record<string, any>) => {
                  // api changed from error to reason in v2 need to confirm with them
                  // eslint-disable-next-line promise/always-return

                  reject(
                    Object.assign(defaultError, {
                      error: data.error || data.status, // TMS service returns error object in 'status'
                      // eslint-disable-next-line promise/always-return
                      reason: data.reason || (data.status ? data.status.message : ''), // TMS service returns error object in 'status'
                    }),
                  );
                })
                .catch(err => {
                  // TODO: This occurs if there is a 5xx error -- no JSON is returned. It may happen for 4xx as well. In those cases, do not attempt to parse the JSON

                  reject(
                    Object.assign(defaultError, {
                      error: { msg: `${response.statusText} [${response.status}]` },
                    }),
                  );
                })
            );
          }

          if (typeof contentType === 'string' && contentType.includes('text/plain')) {
            return (
              // eslint-disable-next-line promise/no-nesting
              response
                .text()
                // eslint-disable-next-line promise/always-return
                .then(data => {
                  // eslint-disable-next-line promise/always-return
                  reject(
                    Object.assign(defaultError, {
                      error: { msg: data },
                    }),
                  );
                })
                .catch(err => {
                  reject(
                    Object.assign(defaultError, {
                      error: { msg: `${response.statusText} [${response.status}]` },
                    }),
                  );
                })
            );
          }

          reject(response);
        })
        .catch(err => {
          reject(err);
        }),
    );
  }

  get(path: string, options: Record<string, any> = {}) {
    return this.request('get', path, options);
  }

  put(path: string, options: Record<string, any> = {}) {
    return this.request('put', path, options);
  }

  post(path: string, options: Record<string, any> = {}) {
    return this.request('post', path, options);
  }

  delete(path: string, options: Record<string, any> = {}) {
    return this.request('delete', path, options);
  }
}

export default new Api();
