import update from 'immutability-helper';
import { AppState, Action } from 'app/types';

import * as types from './types';

const interactivesInitState = {
  interactives: {},
  fetchInteractivesLoading: false,
  error: null,
};

export default (state: AppState = interactivesInitState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_ALL_INTERACTIVES:
      return update(state, {
        fetchInteractivesLoading: { $set: true },
      });

    case types.GET_ALL_INTERACTIVES_SUCCESS:
      return update(state, {
        interactives: { $set: payload.interactives },
        fetchInteractivesLoading: { $set: false },
      });

    case types.GET_ALL_INTERACTIVES_FAILED:
      return update(state, {
        error: { $set: payload.error },
        fetchInteractivesLoading: { $set: false },
      });

    default:
      return state;
  }
};
