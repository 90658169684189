/* eslint-disable import/no-cycle */

import bannerConfig from 'app/features/Banner/banner.config';
import emailConfig from 'app/features/Email/email.config';
import push2Config from 'app/features/Push2/push2.config';
import cashbackConfig from 'app/features/Cashback/cashback.config';
import notificationConfig from 'app/features/TimelineNotification/notification.config';

import defaultConfig from './defaultChannel.config';
import { IChannelConfig } from './types';

export default {
  [defaultConfig.mediumId]: defaultConfig,
  [bannerConfig.mediumId]: bannerConfig,
  [emailConfig.mediumId]: emailConfig,
  [push2Config.mediumId]: push2Config,
  [cashbackConfig.mediumId]: cashbackConfig,
  [notificationConfig.mediumId]: notificationConfig,
} as Record<number, IChannelConfig>;
