import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IBannerStorefront } from 'app/ducks/storefrontsBms/IBannerStorefront';
import { getStorefronts } from 'app/ducks/storefrontsBms/operations';
import { mapStateToStorefronts } from 'app/ducks/storefrontsBms/selectors';
import { storefrontTypes } from 'app/features/BannerManagement/Storefronts/constants';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

const ENTITY_TYPE = storefrontTypes.category;

export default (
  appConfig: IAppConfig,
): {
  permittedStorefronts: Array<IBannerStorefront> | null | undefined;
  storefronts: Array<IBannerStorefront>;
  storefrontsError: string;
  storefrontsLoading: boolean;
} => {
  const { bmsVersion } = getBannerCreativeConfig(appConfig);
  const dispatch = useDispatch();
  const { permittedStorefronts, storefronts, storefrontsError, storefrontsLoading } = useSelector(mapStateToStorefronts);

  useEffect(() => {
    dispatch(getStorefronts(bmsVersion, ENTITY_TYPE));
  }, [bmsVersion, dispatch, storefronts, storefrontsError, storefrontsLoading]);

  return {
    permittedStorefronts,
    storefronts,
    storefrontsError,
    storefrontsLoading,
  };
};
