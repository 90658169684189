import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import withAppConfig from 'app/decorators/withAppConfig';
import { cashbackPromoBannersOperations, cashbackPromoBannersActions } from 'app/ducks';
import { ICashbackBannerCreative } from 'app/features/CashbackPromoManagement/types';
import withReporting from 'app/hocs/withReporting';
import { Button, Dropdown as _Dropdown, Page, PageHeader, PageMain, Spinner } from 'app/midgarComponents';
import { CASHBACK_MANAGEMENT_BANNER_NEW } from 'configs/routes';
import { cashbackTypes } from 'app/features/Cashback/constants';
import { IAppConfig } from 'configs/apps/types';

import BannersList from './BannersList';
import withPermission from '../withPermission';
import { compose } from 'recompose';

type Props = {
  appConfig: IAppConfig;
  banners: Array<ICashbackBannerCreative>;
  error: Error | null | undefined;
  history: RouterHistory;
  loading: boolean;
  readOnly?: boolean;
  updateBanners: (...args: Array<any>) => any;
  updateCashbackBannersPriorties: (...args: Array<any>) => any;
};

const AllType = 'all';
type State = {
  isDirty: boolean;
  type: string;
  showArchived: boolean;
};

class Reporting extends React.PureComponent<Props, State> {
  state = {
    type: AllType,
    isDirty: false,
    showArchived: false,
  };

  newBanner = () => {
    const { history } = this.props;
    history.push(CASHBACK_MANAGEMENT_BANNER_NEW);
  };

  updatePriorties = updatedBanners => {
    updatedBanners = updatedBanners.map((item, index) => ({
      ...item,
      priority: index + 1,
    }));

    const { banners, updateBanners } = this.props;
    const { type } = this.state;
    const cashbackBanners = banners.filter(banner => banner.cashbackType === cashbackTypes.regular);
    const journeyBanners = banners.filter(banner => banner.cashbackType === cashbackTypes.journey);
    // put together unTouched banners with banners that changed priority
    const allUpdatedBanners =
      type === AllType
        ? updatedBanners
        : type === cashbackTypes.regular
        ? [...updatedBanners, ...journeyBanners]
        : [...updatedBanners, ...cashbackBanners];
    this.setState({ isDirty: true });
    updateBanners(allUpdatedBanners);
  };

  savePriorties = () => {
    const { banners, updateCashbackBannersPriorties } = this.props;
    const bannerPriorityMapping = banners.map(banner => ({
      id: banner.id,
      priority: banner.priority,
    }));

    updateCashbackBannersPriorties(bannerPriorityMapping);
    this.setState({ isDirty: false });
  };

  onChangeType = (type: string) => {
    this.setState({ type });
  };

  handleToggleShowArchived = () => {
    this.setState({ showArchived: !this.state.showArchived });
  };

  render() {
    const { banners, error, loading, readOnly, appConfig } = this.props;
    // sort banners and put null priority the last
    banners.sort((b1, b2) => {
      if (b1.priority === null) return 1;
      if (b2.priority === null) return -1;
      return b1.priority - b2.priority;
    });
    const cashbackBanners = banners.filter(banner => banner.cashbackType === cashbackTypes.regular);
    const journeyBanners = banners.filter(banner => banner.cashbackType === cashbackTypes.journey);

    const { isDirty, type, showArchived } = this.state;

    return (
      <Page>
        <PageHeader title="Cashback Banners">
          {isDirty && type === AllType && <Button onClick={this.savePriorties}>Save Priority</Button>}
          <RightContainer>
            {appConfig.isJourneyEnabled && (
              <Dropdown
                label="Select Type"
                value={type}
                options={[...Object.values(cashbackTypes), AllType]}
                required
                onChange={this.onChangeType}
              />
            )}

            <Button onClick={this.newBanner} disabled={readOnly}>
              Create New
            </Button>
          </RightContainer>
        </PageHeader>

        <PageMain>
          {error && <section>error is {JSON.stringify(error)}</section>}
          <FormControlLabel
            control={
              <Checkbox
                data-qa="show-archived-checkbox"
                name="show-archived-banners"
                checked={showArchived}
                color="primary"
                onChange={this.handleToggleShowArchived}
              />
            }
            label="Show Archived Banners"
          />

          {loading ? (
            <Spinner />
          ) : banners.length === 0 ? (
            <section>No Banners</section>
          ) : type === cashbackTypes.regular ? (
            <BannersList banners={cashbackBanners} readOnly />
          ) : type === cashbackTypes.journey ? (
            <BannersList banners={journeyBanners} readOnly />
          ) : (
            <BannersList banners={banners} updatePriortiesFn={this.updatePriorties} showArchived={showArchived} />
          )}
        </PageMain>
      </Page>
    );
  }
}

const mapStateToProps = ({
  cashbackPromoConfig: {
    banners: { banners, error, loading },
  },
}) => ({
  banners,
  error,
  loading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateCashbackBannersPriorties: props => dispatch(cashbackPromoBannersOperations.updateCashbackBannersPriorties(props)),
  fetchCashbackBanners: props => dispatch(cashbackPromoBannersOperations.getAllCashbackBanners(ownProps.appConfig.isJourneyEnabled)(props)),
  updateBanners: props => dispatch(cashbackPromoBannersActions.getSuccess(props)),
});

export default compose(
  withAppConfig,
  withPermission,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withReporting('fetchCashbackBanners'),
)(Reporting);

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Dropdown = styled(_Dropdown)`
  width: 15rem;
  margin-right: 1rem;
`;
