import * as React from 'react';

import TemplateList from 'app/features/Templates/Email/ListPage/TemplateList';
import { Spinner } from 'app/midgarComponents';

import { ITemplate } from 'app/types';

type Props = {
  templates: Array<ITemplate>;
  editable: boolean;
  isLoading: boolean;
  msgIfEmpty?: string;
  timezone: string;
};

export default ({ templates, editable, isLoading, msgIfEmpty, timezone }: Props) => {
  if (isLoading) return <Spinner />;

  if (templates.length === 0) return <span>{msgIfEmpty}</span>;

  return (
    <TemplateList
      templates={templates}
      editable={editable}
      timezone={timezone}
      setError={() => {
        console.error('TemplateTable Placeholder: handleDismiss() called');
      }}
    />
  );
};
