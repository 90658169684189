import { compose, withHandlers, withProps } from 'recompose';

import { timeZone } from 'app/helpers/AppEnvHelpers/helpers';
import { isNilOrEmpty } from 'app/helpers/RamdaHelpers/helpers';

import { RuleDateTimePicker } from './RuleDateTimePicker';

const addProps = withProps({
  timeZone: timeZone(),
});

const normalizeMoment = (aMoment, withTime) => (withTime ? aMoment : aMoment.minutes(0).hours(0).seconds(0));

const addHandlers = withHandlers({
  onChange:
    ({ onChange, withTimePicker, timeZone: timezone }) =>
    aMoment => {
      const unixTime = isNilOrEmpty(aMoment) ? undefined : normalizeMoment(aMoment.tz(timezone), withTimePicker).unix();
      onChange(unixTime);
    },
});

const enhance = compose(addProps, addHandlers);

export default enhance(RuleDateTimePicker);
