import React from 'react';
import PropTypes from 'prop-types';

import { DialogActions, Button, Typography, Input, Chip } from '@material-ui/core';

import { green } from '@material-ui/core/colors';
import { BorderColor as HighlightIcon } from '@material-ui/icons';
import ChipInput from 'material-ui-chip-input';

import DialogShell from 'app/components/DialogShell';
import { isNilOrEmpty, isNeitherNilNorEmpty } from 'app/helpers/RamdaHelpers/helpers';

const inputWidth = 312;

const itemCount = (values, styleClass) => {
  if (isNilOrEmpty(values)) {
    return undefined;
  }

  const count = values.length;
  const text = `(${count} item${count > 1 ? 's' : ''})`;

  return (
    <Typography variant="subtitle1" className={styleClass}>
      <i>{text}</i>
    </Typography>
  );
};

const highlighter = (values, handleSearch, classes) => {
  if (isNilOrEmpty(values)) {
    return undefined;
  }

  return (
    <div
      className={classes.actions}
      style={{
        alignItems: 'flex-end',
        display: 'flex',
        marginRight: '0.8rem',
      }}
    >
      <HighlightIcon />
      <Input
        margin="dense"
        type="search"
        placeholder="Type to highlight item(s)"
        className={classes.textField}
        style={{
          minWidth: inputWidth - 32,
        }}
        onChange={handleSearch}
      />
    </div>
  );
};

const shouldHighlight = (value, searchString) => isNeitherNilNorEmpty(searchString) && (value || '').includes(searchString);

export const ChipInputDialog = ({
  isOpen,
  values,
  searchString,
  handleAdd,
  handleDelete: handleDeletion,
  handleClear,
  handleClose,
  handleSearch,
  handleInputUpdate,
  dialogTitle,
  classes,
}) => (
  <DialogShell
    isOpen={isOpen}
    dialogHeader={
      <>
        {dialogTitle}
        <DialogActions>
          <Button onClick={handleClear} color="primary">
            Delete All
          </Button>
        </DialogActions>
      </>
    }
    onClose={handleClose}
  >
    <ChipInput
      autoFocus
      value={values}
      multiline
      onAdd={handleAdd}
      onDelete={handleDeletion}
      onUpdateInput={handleInputUpdate}
      placeholder="Eg: Goa, UP, MP"
      style={{
        width: inputWidth,
      }}
      chipRenderer={({ value, handleDelete, defaultStyle }, key) => (
        <Chip
          key={key}
          style={{
            ...defaultStyle,
            margin: '0 8px 8px 0',
            ...(shouldHighlight(value, searchString) ? { backgroundColor: green[300] } : {}),
          }}
          onDelete={handleDelete}
          label={value}
        />
      )}
    />

    {itemCount(values, classes.wrapper)}
    {highlighter(values, handleSearch, classes)}
  </DialogShell>
);

ChipInputDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  searchString: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleInputUpdate: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
