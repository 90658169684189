import * as React from 'react';
import { connect } from 'react-redux';
import { RouterHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { DrawerError } from 'app/components';
import { stackableGroupsOperations } from 'app/ducks';
import withReporting from 'app/hocs/withReporting';
import { Page, PageHeader, PageMain, Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';

import { getStackableGroupUrl } from '../Overview/routeUtils';
import { IStackableGroup } from '../types';
import { compose } from 'recompose';

type Props = {
  error: Error;
  history: RouterHistory;
  loading: boolean;
  stackableGroups: Array<IStackableGroup>;
};

class Reporting extends React.PureComponent<Props> {
  onGroupClick = (groupId: number) => () => {
    const { history } = this.props;
    history.push(getStackableGroupUrl(groupId));
  };

  render() {
    const { error, loading, stackableGroups } = this.props;

    return (
      <Page>
        <PageHeader title="Stackable Groups" />

        <PageMain data-qa="stackable-groups">
          {error && (
            <DrawerError
              error="Error while retrieving stackable groups"
              errorDetails={error.message}
              allowDismiss={false}
              defaultExpanded
            />
          )}

          {loading ? (
            <Spinner />
          ) : stackableGroups && stackableGroups.length > 0 ? (
            stackableGroups
              .sort((a, b) => a.priority - b.priority)
              .map(group => (
                <StackableGroup
                  key={group.id}
                  onClick={
                    group.managedBy !== 'CMA'
                      ? () => {
                          /* no-op */
                        }
                      : this.onGroupClick(group.id)
                  }
                  disabled={group.managedBy !== 'CMA'}
                  data-qa={group.name}
                >
                  <GroupId>Priority: {group.priority}</GroupId>
                  <GroupName>{group.name}</GroupName>
                </StackableGroup>
              ))
          ) : (
            <section>No groups</section>
          )}
        </PageMain>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(
    ({
      cashbackPromoConfig: {
        stackableGroups: { error, loading, stackableGroups },
      },
    }) => ({
      error,
      loading,
      stackableGroups,
    }),

    {
      fetchStackableGroups: stackableGroupsOperations.getStackableGroups,
    },
  ),

  withReporting('fetchStackableGroups'),
)(Reporting);

const GroupId = styled.div`
  color: ${sc.grey};
  font-size: ${sc.fontSizeSmall};
  width: 6rem;
`;

const GroupName = styled.div``;

const StackableGroup = styled.section`
  border-bottom: 1px solid ${sc.greyLighter};
  display: flex;
  padding: 1.5rem;
  background: ${props => (props.disabled ? '#d8d8d8' : 'white')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  &:last-child {
    border-bottom: 0;
  }
`;
