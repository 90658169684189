import React, { PureComponent } from 'react';
import { inject } from 'app/decorators';
import { withStyles } from '@material-ui/core/styles';
import { Tab, Tabs, Typography } from '@material-ui/core';
import { BorderClear as UpcomingIcon, BorderAll as DeliveredIcon } from '@material-ui/icons';

import { Page } from 'app/components';
import { PageMain } from 'app/midgarComponents';
import * as debugActions from 'app/ducks/debug/actions';

import UpcomingBanners from './UpcomingBanners';
import DeliveredBanners from './DeliveredBanners';

import styles from './styles';

import { compose } from 'recompose';

class RootRouteHandler extends PureComponent<{
  history: Record<string, any>;
  classes: Record<string, any>;
  match: Record<string, any>;
}> {
  onTabChange = segment => {
    const { history } = this.props;

    if (history.location.pathname !== `/channels/banner/debug/${segment}`) {
      return history.push(`/channels/banner/debug/${segment}`);
    }

    return null;
  };

  renderHeader(tab: string) {
    const { classes } = this.props;

    return (
      <>
        <Typography key="tab" variant="h6">
          Banners Debug
        </Typography>

        <Tabs
          key="tabs"
          value={tab}
          onChange={(e, val) => this.onTabChange(val)}
          textColor="primary"
          indicatorColor="primary"
          classes={{
            root: classes.tabs,
            flexContainer: classes.tabsContainer,
          }}
        >
          <Tab icon={<UpcomingIcon />} value="upcoming" label="Upcoming" />

          <Tab icon={<DeliveredIcon />} value="delivered" label="delivered" />
        </Tabs>
      </>
    );
  }

  render() {
    const {
      match: {
        params: { tab },
      },
    } = this.props;

    return (
      <Page renderHeader={() => this.renderHeader(tab)}>
        {tab === 'upcoming' ? (
          <PageMain>
            <UpcomingBanners />
          </PageMain>
        ) : (
          <DeliveredBanners />
        )}
      </Page>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }), inject({ debugActions }))(RootRouteHandler);
