import { isEmpty } from 'ramda';
import { isNeitherNilNorEmpty, meaningfulString } from 'app/helpers/RamdaHelpers/helpers';

import { audienceTypes } from '../audienceTypes';

const humanized = {
  name: 'Name',
  description: 'Description',
  tags: 'Tag',
  includedRuleErrors: 'Rules',
  csvUrl: 'File',
  includedSeedSegments: 'Seed Segment',
  type: 'Audience Type',
  boostAudienceSize: 'Maximum audience size',
};

const assertionsGeneral = {
  name: meaningfulString,
  description: meaningfulString,
  tags: isNeitherNilNorEmpty,
};

const assertionsRule = {
  includedRuleErrors: (includedRuleErrors = []) => includedRuleErrors.length > 0 && includedRuleErrors.every(isEmpty),
};

const assertionsCsv = {
  csvUrl: meaningfulString,
};

const assertionsNoType = {
  // If there is no type specified, then validation fails.
  // Cannot use the `type` property because it is not cleared when the type is changed e.g. from manual -> rule
  type: () => false,
};

const assertions = {
  [audienceTypes.rule]: { ...assertionsGeneral, ...assertionsRule },
  [audienceTypes.csv]: { ...assertionsGeneral, ...assertionsCsv },
  '': { ...assertionsGeneral, ...assertionsNoType },
};

export { assertions, humanized };
