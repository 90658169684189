import { State } from './reducers';

type Props = {
  bannerManagement: {
    gaTypes: State;
  };
};

export const mapStateToGaTypes = ({
  bannerManagement: {
    gaTypes: { error: gaTypesError, loading: gaTypesLoading, gaTypes },
  },
}: Props) => ({
  gaTypes,
  gaTypesError,
  gaTypesLoading,
});
