import React from 'react';
import { connect } from 'react-redux';

import { cashbackPromoBannersOperations } from 'app/ducks/cashbackPromo';
import { getJourneyPromoBanner } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { getCampaign } from 'app/ducks/campaigns/campaign/selectors';
import * as CashbackPromoConfigSelectors from 'app/ducks/cashbackPromo/selectors';
import * as fetch from 'app/utilities/http';
import { cashbackActions } from 'app/ducks/campaigns/campaign/cashbackCreative';
import { api as campaignAPI } from 'app/ducks/campaigns/campaign/operations';
import { cashbackTypes } from 'app/features/Cashback/constants';

import { Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Label, Section, SpinnerWrapper, Dropdown, ValidationIcon } from '../../Creation/CreationCommonComponents';

type Props = {
  getJourneyBanners: (arg0: Record<string, string | number>) => void;
  journeyBanner: { title: string };
  banners: Array<{ title: string }>;
  bannersLoading: boolean;
  updateJourneyField: any;
  campaignId: string | undefined;
};

export class BannerComponent extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      campaignBanner: null,
    };
  }

  fetchCampaign = async id => {
    const campaign = await fetch.get(campaignAPI.get.replace(':id', id));
    return campaign;
  };

  updateBanner = async id => {
    try {
      if (id) {
        const campaign = await this.fetchCampaign(id);
        const campaignBanner = campaign?.creative?.cashbackPromoBanner;
        this.setState({
          campaignBanner,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    const { getJourneyBanners } = this.props;
    getJourneyBanners({ used: false });
    this.updateBanner(this.props.campaignId);
  }

  render() {
    const { journeyBanner, banners, bannersLoading, updateJourneyField } = this.props;
    return (
      <Section>
        <Label>
          Banner
          {journeyBanner?.title ? (
            <ValidationIcon name="check-circle" size={22} color={sc.success} />
          ) : (
            <ValidationIcon name="warning" size={22} color={sc.warning} />
          )}
        </Label>
        {bannersLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <Dropdown
            label="Journey Promo Banner"
            name="journeyPromoBanner"
            onChange={val => updateJourneyField({ cashbackPromoBanner: val })}
            options={(this.state.campaignBanner ? [...banners, this.state.campaignBanner] : banners).map(banner => ({
              ...banner,
              label: banner.title,
            }))}
            value={journeyBanner || {}}
          />
        )}
      </Section>
    );
  }
}

const BannerContainer = connect(
  state => {
    const banners = CashbackPromoConfigSelectors.getBanners(state);
    const selectedBanner = getJourneyPromoBanner(state);
    const selectedBannerWithLabel = {
      ...selectedBanner,
      label: selectedBanner?.title,
    };

    const unUsedJourneyBanners = banners.banners.filter(banner => banner.cashbackType === cashbackTypes.journey);
    const campaign = getCampaign(state);
    return {
      journeyBanner: selectedBannerWithLabel,
      banners: unUsedJourneyBanners,
      bannersLoading: banners.loading,
      campaignId: campaign?.general?.id,
    };
  },
  {
    getJourneyBanners: cashbackPromoBannersOperations.getJourneyBanners,
    updateJourneyField: cashbackActions.updateJourneyField,
  },
)(BannerComponent);

export default BannerContainer;
