import styled from 'styled-components';

const IconButton = styled.button`
  margin: 0;
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export default IconButton;
