import { IApiBannerCreative } from 'app/api/bannerManagementV2/creatives/types';
import { IStorefront, IView } from 'app/types/BannerManagement';

import * as types from './types';

export const addTarget = () => ({
  type: types.ADD_TARGET,
});

export const removeTarget = (index: number) => ({
  type: types.REMOVE_TARGET,
  payload: { index },
});

export const setSourceCreative = (creative: IApiBannerCreative) => ({
  type: types.SET_SOURCE_CREATIVE,
  payload: { creative },
});

export const setTargetStorefront = (index: number, storefront: IStorefront) => ({
  type: types.SET_TARGET_STOREFRONT,
  payload: { index, storefront },
});

export const setTargetViews = (index: number, views: Array<IView>) => ({
  type: types.SET_TARGET_VIEWS,
  payload: { index, views },
});

export const setValidFrom = (validFrom: string) => ({
  type: types.SET_VALID_FROM,
  payload: { validFrom },
});

export const setValidUpto = (validUpto: string) => ({
  type: types.SET_VALID_UPTO,
  payload: { validUpto },
});
