import { withStateHandlers, compose } from 'recompose';

import { EditWithDropdown } from './EditWithDropdown';

const addStateHandlers = withStateHandlers(({ inputValue, optionValue }) => ({ inputValue, optionValue }), {
  handleChangeInput:
    ({ inputValue: oldInput, optionValue }, { onChange }) =>
    newInput => {
      if (oldInput === newInput) {
        return undefined;
      }

      const newState = {
        inputValue: newInput,
        optionValue,
      };

      onChange(newState);
      return newState;
    },
  handleChangeOption:
    ({ optionValue: oldOption, inputValue }, { onChange }) =>
    ({ target: { value: newOption } }) => {
      if (oldOption === newOption) {
        return undefined;
      }

      const newState = {
        optionValue: newOption,
        inputValue,
      };

      onChange(newState);
      return newState;
    },
});

const enhance = compose(addStateHandlers);

export default enhance(EditWithDropdown);
