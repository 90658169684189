import api from 'app/utilities/api';

import { IStorefront } from 'app/types/BannerManagement';

import { GET_STOREFRONT, GET_STOREFRONTS } from './endpoints';
import { IStoresQuery } from './types';

export const fetchStorefronts = (entityType: string, query: IStoresQuery): Promise<Array<IStorefront>> =>
  // TODO: Translate the V2 IStoresQuery into V1
  api.get(GET_STOREFRONTS(entityType), { query }).then(({ decorators: storefronts }) => storefronts);

export const fetchStorefront = (entityType: string, entityId: number, storefrontId: number): Promise<IStorefront | null | undefined> =>
  api
    .get(GET_STOREFRONT, {
      query: {
        entityType,
        id: storefrontId,
      },
    })
    .then(storefronts => ((storefronts || []).length ? storefronts[0] : null));
