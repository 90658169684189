const ROOT = 'map/bannerManagement/storefront';

// === STOREFRONTS ===

export const GET_STOREFRONT_FAILED = `${ROOT}/GET_STOREFRONT_FAILED`;
export const GET_STOREFRONT_START = `${ROOT}/GET_STOREFRONT_START`;
export const GET_STOREFRONT_SUCCESS = `${ROOT}/GET_STOREFRONT_SUCCESS`;
export const RESET_STOREFRONT = `${ROOT}/RESET_STOREFRONT`;
export const SAVE_STOREFRONT_FAILED = `${ROOT}/SAVE_STOREFRONT_FAILED`;
export const SAVE_STOREFRONT_START = `${ROOT}/SAVE_STOREFRONT_START`;
export const SAVE_STOREFRONT_SUCCESS = `${ROOT}/SAVE_STOREFRONT_SUCCESS`;
export const SET_STOREFRONT_FIELD = `${ROOT}/SET_STOREFRONT_FIELD`;
export const SET_STOREFRONT_TYPE = `${ROOT}/SET_STOREFRONT_TYPE`;

// === VIEWS ===

export const DELETE_VIEW_START = `${ROOT}/DELETE_VIEW_START`;
export const DELETE_VIEW_SUCCESS = `${ROOT}/DELETE_VIEW_SUCCESS`;
export const DELETE_VIEW_FAILED = `${ROOT}/DELETE_VIEW_FAILED`;
export const GET_VIEWS_FAILED = `${ROOT}/GET_VIEWS_FAILED`;
export const GET_VIEWS_START = `${ROOT}/GET_VIEWS_START`;
export const GET_VIEWS_SUCCESS = `${ROOT}/GET_VIEWS_SUCCESS`;

export const ENABLE_ENGAGE_START = `${ROOT}/ENABLE_ENGAGE_START`;
export const ENABLE_ENGAGE_SUCCESS = `${ROOT}/ENABLE_ENGAGE_SUCCESS`;
export const ENABLE_ENGAGE_FAILED = `${ROOT}/ENABLE_ENGAGE_FAILED`;
