import styled from 'styled-components';

import { sc } from 'app/styles';

export default styled.section`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  background-color: ${sc.sectionWhiteColor};
`;
