import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import { isNilOrEmpty, R } from 'app/helpers/RamdaHelpers/helpers';
import Tooltip from '../Tooltip';

const propsBasedOnValidation = (validate, value, dataType) =>
  R.isNil(validate) || validate(value)
    ? {
        value,
      }
    : {
        value,
        error: true,
        helperText: `Error: Not a valid ${dataType}`,
      };

export const RuleTextEdit = ({ id, label, dataType, value = '', validator, handleChange, multiLine = false, classes, style = {} }) => {
  const onKeyDown = e => {
    if (dataType === 'number') {
      const invalidChars = ['-', '+', 'e', 'E', '.'];
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    }
  };

  return (
    <Tooltip title={isNilOrEmpty(value) ? value : ''}>
      <TextField
        required
        margin="dense"
        className={classes.textField}
        style={style}
        key={id}
        id={id}
        label={label}
        type={dataType}
        multiline={multiLine}
        data-qa="rule-text-field"
        onChange={handleChange}
        onKeyDown={e => onKeyDown(e)}
        {...(isNilOrEmpty(value) ? { value: '' } : propsBasedOnValidation(validator, value, dataType))}
      />
    </Tooltip>
  );
};

RuleTextEdit.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dataType: PropTypes.string.isRequired,
  value: PropTypes.string,
  validator: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  multiLine: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  style: PropTypes.objectOf(PropTypes.any),
};
