import { isEmpty } from 'ramda';

export const validateForm = (
  form: Record<string, any>,
  fields: Array<string>,
  customFilter: (fieldName: string) => boolean = () => true,
): Array<string> => fields.filter(field => !form[field] || isEmpty(form[field])).filter(customFilter);

export const fieldProps = (field: string, form: Record<string, any>) => ({
  error: form.errors.indexOf(field) > -1,
});
