import { connect } from 'react-redux';
import { cashbackActions } from 'app/ducks/campaigns/campaign/cashbackCreative';
import { eventSchemaOperations } from 'app/ducks/cashbackPromo/eventSchema';
import { getRegularCriteria } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import * as CashBackPromoConfigSelectors from 'app/ducks/cashbackPromo/selectors';
import { EnrichFieldsCriteria } from '../../Criteria/EnrichFieldsCriteria';

export const EnrichFieldsCriteriaContainer = connect(
  state => {
    const customerEvent = CashBackPromoConfigSelectors.getCustomerEvent(state);
    const criteria = getRegularCriteria(state);
    return {
      customerEvent: customerEvent.customerEvent,
      loadingCustomerEvent: customerEvent.loadingCustomerEvent,
      criteria,
    };
  },
  {
    getCustomerEvent: eventSchemaOperations.getCustomerEvent,
    updateCashbackCriteria: cashbackActions.updateCashbackCriteria,
  },
)(EnrichFieldsCriteria);
