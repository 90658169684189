import React from 'react';
import window from 'global/window';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

import { creativeActions } from 'app/ducks';
import { Dropzone as _Dropzone, Separator as _Separator } from 'app/features/BannerManagement/common/components';
import { sc } from 'app/styles';
import { Action } from 'app/types';
import { abbrev } from 'app/utilities/strings';

type Props = {
  variantKey: string;
  latLongId?: number;
  fileUrl?: string;
  geoUrl?: string;
  geoFile?: File;
  placeholder?: string;
  updateVariantsField: (index: number, field: string, value: any) => Action;
};

class VariantGeo extends React.PureComponent<Props> {
  onDrop = (files: Array<File>) => {
    const { updateVariantsField, variantKey } = this.props;
    const file = files && files.length ? files[0] : null;

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (ev: Event) => {
        updateVariantsField(variantKey, 'geoUrl', ev.target.result);
        updateVariantsField(variantKey, 'geoFile', file);
      };
    }
  };

  onOpen = (ev: Event, url: string) => {
    ev.preventDefault();
    ev.stopPropagation();
    window.open(url);
  };

  render() {
    const { fileUrl, geoFile, geoUrl, latLongId, placeholder } = this.props;

    return (
      <Container>
        <Separator>
          <span>Lat / Long</span>
        </Separator>

        <Dropzone
          data-qa="banner-location-upload"
          multiple={false}
          onDrop={this.onDrop}
          hasfile={!geoFile ? undefined : true}
          accept=".csv"
        >
          {geoFile ? (
            <>
              <FileFieldLabel>File:</FileFieldLabel>
              <FileFieldValue>{geoFile.name}</FileFieldValue>
              <FileFieldLabel>Size:</FileFieldLabel>
              <FileFieldValue>{abbrev(geoFile.size)}</FileFieldValue>
            </>
          ) : fileUrl ? (
            <FileContainer>
              <FileFieldLabel>Geo ID:</FileFieldLabel>
              <FileFieldValue>{latLongId}</FileFieldValue>
              <FileFieldLabel>File URL:</FileFieldLabel>
              <FileFieldValue>
                <a href={fileUrl} onClick={ev => this.onOpen(ev, fileUrl)} rel="noopener noreferrer" target="_blank">
                  {fileUrl}
                </a>
              </FileFieldValue>
            </FileContainer>
          ) : (
            <DropzonePlaceholder>{placeholder || 'Upload Lat/Long CSV'}</DropzonePlaceholder>
          )}
        </Dropzone>

        {!geoFile && !geoUrl && (
          <>
            <Sample>Note: Radius unit is in Meters</Sample>
            <Sample>
              <span>Sample:</span>
              <a
                href="https://s3.ap-south-1.amazonaws.com/map-cma-images-production/sample-lat-long.csv"
                rel="noopener noreferrer"
                target="_blank"
              >
                sample-lat-long.csv
              </a>
            </Sample>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (
  {
    bannerManagement: {
      creative: { form },
    },
  },

  { variantKey },
) => ({
  fileUrl: form.variants[variantKey].fileUrl,
  geoFile: form.variants[variantKey].geoFile,
  geoUrl: form.variants[variantKey].geoUrl,
  latLongId: form.variants[variantKey].latLongId,
});

const mapDispatchToProps = {
  updateVariantsField: creativeActions.updateVariantsField,
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantGeo);

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const DropzonePlaceholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;

  color: ${sc.greyLight};
  font-size: ${sc.fontSizeSmall};
  transform: translate(-50%, -50%);
`;

const Dropzone = styled(_Dropzone)`
  ${props =>
    props.hasfile
      ? css`
          text-align: left;
        `
      : css`
          &:hover {
            border-color: ${sc.primary};
            transform: scale(1.015);

            & ${DropzonePlaceholder} {
              color: ${sc.primary};
            }
          }
        `}
`;

const FileFieldLabel = styled.span`
  display: inline-block;
  vertical-align: top;
  width: 20%;
  font-size: ${sc.fontSizeSmall};
  color: ${sc.subHeadingColor};
  margin-bottom: ${sc.gutterSmallest};
`;

const FileFieldValue = styled(FileFieldLabel)`
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FileContainer = styled.div`
  text-align: left;

  & > ${FileFieldLabel} {
    font-size: ${sc.fontSizeSmaller};
    margin-bottom: ${sc.gutterSmaller};
    width: 32%;
  }

  & > ${FileFieldValue} {
    font-size: ${sc.fontSizeSmaller};
    width: 68%;
  }

  & a {
    display: inline-block;
    vertical-align: top;
    color: ${sc.primary};

    &:hover {
      border-bottom: 1px solid ${sc.primary};
    }
  }
`;

const Sample = styled.div`
  margin-top: ${sc.gutterSmaller};
  font-size: ${sc.fontSizeSmaller};
  color: ${sc.subHeadingColor};
  text-align: center;

  & > a {
    color: ${sc.primary};
    margin-left: ${sc.gutterSmallest};

    &:hover {
      border-bottom: 1px solid ${sc.primary};
    }
  }
`;

const Separator = styled(_Separator)`
  & > span {
    font-size: ${sc.fontSizeSmallest};
  }
`;
