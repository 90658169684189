import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'app/midgarComponents';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { Text } from 'app/features/BannerManagement/common/components';
import { getBinderState } from '../../../../../../ducks/bannerManagement/binder/selectors';
import Slot from '../../Slots/Slot';

const SearchedViewItemsModal = props => {
  const { isOpen, onClose, slots, onFindSelect } = props;
  const { searchedViewItems } = useSelector(getBinderState);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);

  useEffect(() => {
    if (searchedViewItems) {
      const tempSelectedSlots = slots.filter(slot => searchedViewItems.map(i => i.slotId).includes(slot.id));
      setSelectedSlots(tempSelectedSlots);
      setSelectedSlot(searchedViewItems[0]?.slotId);
    }
  }, [searchedViewItems, slots]);

  const handleSlotSelect = slot => {
    setSelectedSlot(slot);
  };

  const renderError = error => {
    return error;
  };

  const onVariantSelect = variant => {
    onFindSelect(variant);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {isOpen && (
        <Container>
          {selectedSlots.map((slot, index) => (
            <Slot
              slot={slot}
              key={slot.id}
              index={index}
              isSelected={selectedSlot === slot.id}
              onSelect={handleSlotSelect}
              renderError={renderError}
            />
          ))}

          {searchedViewItems
            .filter(item => item.slotId === selectedSlot)
            .map((item, index) => (
              <VariantContainer key={index} onClick={() => onVariantSelect(item)}>
                <PreviewImage height="100" src={item.variants[0].image} />
                <div>
                  <NameCell>
                    <span>{item.name}</span>
                  </NameCell>
                  <FieldGroup>
                    <Label dark>Banner ID:</Label>
                    <Field>
                      <span>{item.variants[0].id}</span>
                    </Field>
                  </FieldGroup>
                </div>
              </VariantContainer>
            ))}
        </Container>
      )}
    </Modal>
  );
};

const Container = styled.div`
  text-align: center;
`;

const VariantContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Cell = styled.div`
  ${props => (props.width ? `width: ${props.width}px;` : '')};
  margin-right: ${sc.gutterSmaller};
  display: flex;
  align-items: center;
  flex-grow: 5;
  flex-shrink: 0;
  flex-basis: auto;
  color: ${sc.subHeadingColor};
  &:last-child {
    margin: 0;
  }
`;

const NameCell = styled(Cell)`
  flex-grow: 25;
  width: 200px;
  & > span {
    font-weight: 600;
  }
`;

const Label = styled(Text)`
  display: inline-block;
  vertical-align: top;
  margin-right: ${sc.gutterSmallest};
`;

const FieldGroup = styled.div`
  line-height: 22px;
`;

const Field = styled(Text)`
  display: inline-block;
  vertical-align: top;

  & > div,
  & > span {
    display: inline-block;
    vertical-align: top;
    margin-right: ${sc.gutterSmallest};
  }
`;

const PreviewImage = styled.img`
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  border-radius: 3px;
`;

export default SearchedViewItemsModal;
