import { connect } from 'react-redux';
import { getRegularCashbackCreative, getJourneyCreative } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { cashbackActions, cashbackOperations } from 'app/ducks/campaigns/campaign/cashbackCreative';
import CustomAttributesComponent from './CustomAttributes';
import { cashbackTypes } from '../constants';

const CustomAttributesContainer = (cashbackType: string) =>
  connect(
    state => ({
      cashbackCreative:
        cashbackType === cashbackTypes.regular || !cashbackType ? getRegularCashbackCreative(state) : getJourneyCreative(state),
    }),

    {
      updateCashbackField:
        cashbackType === cashbackTypes.regular || !cashbackType ? cashbackActions.updateCashbackField : cashbackActions.updateJourneyField,
      getCampaignAttributes: cashbackOperations.getCampaignAttributes(cashbackType),
    },
  )(CustomAttributesComponent);

export default CustomAttributesContainer;
