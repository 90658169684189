import { IStorefront } from 'app/types/BannerManagement';

export type IBannerStorefront = {
  entity_associated_with?: number;
  entity_type?: string;
  id: number;
  name: string;
};

/**
 * Minimal version of storefront.
 * Needed because of the large number of storefronts which will be kept in Redux.
 */
export const asBannerStorefront = ({ entity_associated_with, entity_type, id, name }: IStorefront): IBannerStorefront => ({
  ...(entity_associated_with ? { entity_associated_with } : {}),
  ...(entity_type ? { entity_type } : {}),
  id,
  name,
});
