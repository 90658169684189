export default [
  'Mobile Prepaid',
  'Mobile Postpaid',
  'Electricity',
  'Bill Payments',
  'Movies',
  'Events',
  'Amunsment Park',
  'Flights',
  'Trains',
  'Bus',
  'Gold',
  'Landline',
  'Broadband',
  'Cable TV',
  'Fees',
  'Gas',
  'Forex',
  'Data Card',
  'Challan',
  'Loan',
  'Fastag',
  'Insurance',
  'Water',
  'Devotion',
  'Credit Card',
  'Muncipal Payments',
  'Gamepind',
  'Donation',
  'Deals',
  'Hotel',
  'Gift Card',
  'Paytm Money',
  'Offline Unorganized',
  'Online Payments',
  'Offline Food',
  'Cross Category',
  'Offline Dairy',
  'Offline Petrol',
  'Offline Healthcare',
  'Offline Toll',
  'Mall-Mobiles',
  'Mall-Consumer Electronics',
  'Mall-FMCG',
  'Mall-Laptops and PC',
  'Mall-Apparel',
  'Mall-Home and Kitchen',
  'Mall-Large Appliances',
  'Mall-Footwear',
  'Mall-BPC',
  'Mall-Fashion Accessories',
  'Mall-Small Appliances',
  'Mall-Cars & Bikes',
  'Mall-General Merchandise',
  'Mall-World Store',
  'Mall-Fashion',
  'Others',
  'Mall-Others',
];
