import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { ListSelectableGridColumn } from './ListSelectableGridColumn';

const getSelectedItemId = (selected, list) => {
  if (selected && selected.length > 0) {
    const found = selected.find(s => s.listId === list.id);
    if (found) {
      return found.itemId;
    }
  }
  return null;
};

export const ListSelectableGroup = ({ selected = [], lists = [], colWidth, handleListClick, scrollIntoViewIfNeeded = false }) => (
  <Grid container spacing={0}>
    {lists.map(list => (
      <ListSelectableGridColumn
        key={list.id}
        selectedItemId={getSelectedItemId(selected, list)}
        listId={list.id}
        listName={list.name}
        items={list.items}
        colWidth={colWidth}
        handleClick={handleListClick(list.id)}
        scrollIntoViewIfNeeded={scrollIntoViewIfNeeded}
      />
    ))}
  </Grid>
);

ListSelectableGroup.propTypes = {
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      listId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),

  lists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ),
    }),
  ),

  colWidth: PropTypes.oneOf([1, 2, 3, 4, 6]),
  handleListClick: PropTypes.func,
  scrollIntoViewIfNeeded: PropTypes.bool,
};
