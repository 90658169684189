import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Error as ErrorIcon } from '@material-ui/icons';

import Tags from 'app/features/Tags';
import { Paper, Input } from 'app/midgarComponents';

// ------------ Styles ----------------
const GeneralWrapper = styled(Paper)`
  padding: 3rem 1rem;
`;
const InputContainer = styled.div`
  width: 50%;
`;
// ----------- End of Styles ----------

export const General = ({
  name = '',
  description = '',
  tags = [],
  handleChangeName,
  handleChangeDescription,
  handleAddTag,
  handleRemoveTag,
  classes,
  valid,
  validationError = '',
  loading,
}) => (
  <GeneralWrapper>
    {!loading && !valid && validationError && (
      <span>
        <ErrorIcon color="error" style={{ marginRight: '0.2rem' }} />
        <i data-qa="audience-validation-error">{validationError}</i>
      </span>
    )}

    <InputContainer>
      <Input
        required
        autoFocus
        id="name"
        label="Name"
        placeholder="Name"
        value={name}
        data-qa="segment-name"
        type="search"
        onChange={handleChangeName}
      />

      <Input
        required
        id="description"
        label="Description"
        placeholder="Description"
        value={description}
        data-qa="segment-description"
        type="search"
        onChange={handleChangeDescription}
      />

      <Tags selected={tags} removeTag={handleRemoveTag} addTag={handleAddTag} size={100} />
    </InputContainer>
  </GeneralWrapper>
);

General.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),

  handleChangeName: PropTypes.func.isRequired,
  handleChangeDescription: PropTypes.func.isRequired,
  handleAddTag: PropTypes.func.isRequired,
  handleRemoveTag: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  valid: PropTypes.bool.isRequired,
  validationError: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};
