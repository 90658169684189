import { IViewItem } from 'app/types/BannerManagement';
import api from 'app/utilities/api';

import { MOVE_AFTER_BANNER_ID, MOVE_VIEW_ITEMS_TO_SLOT, VIEW_ITEMS_FOR_SLOT_ID } from './endpoints';

export const deleteViewItems = (
  slotId: number,
  slotGroupTypeName: string,
  slotVersion: number,
  viewItemIds: Array<number>,
): Promise<{ version: number }> =>
  api.delete(VIEW_ITEMS_FOR_SLOT_ID(slotId), {
    body: {
      ids: viewItemIds,
      type: slotGroupTypeName,
      version: slotVersion,
    },
  });

export const moveAfterBannerId = (
  slotId: number,
  bannerId: number,
  params: {
    type: string;
    version: number;
    viewItems: Array<IViewItem>;
  },
): Promise<{ priority: number }> => {
  const { type, version, viewItems } = params;
  return api.put(MOVE_AFTER_BANNER_ID(slotId, bannerId), {
    body: {
      type,
      version,
      viewItems: (viewItems || []).map(({ id }) => ({ id })),
    },
  });
};

export const moveViewItemsToSlot = async (
  ids: Array<number>,
  targetSlotId: number,
  targetSlotGroupTypeName: string,
): Promise<{ msg: string }> =>
  api.put(MOVE_VIEW_ITEMS_TO_SLOT(targetSlotId), {
    body: {
      ids,
      type: targetSlotGroupTypeName,
    },
  });
