import * as types from './types';

const initialState = {
  templates: [],
  currentTemplate: {
    name: '',
    template: {},
    defaults: {},
  },
};

const templateManagement = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.UPDATE_TEMPLATE_MANAGEMENT_FIELDS:
      return {
        ...state,
        ...payload,
      };

    case types.UPDATE_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          ...payload,
        },
      };

    case types.UPDATE_TEMPLATE_FORM:
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          template: {
            ...state.currentTemplate.template,
            ...payload,
          },
        },
      };

    case types.RESET_TEMPLATE_MANAGEMENT:
      return initialState;

    default:
      return state;
  }
};

export default templateManagement;
