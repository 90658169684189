import cashbackConfig from 'app/features/Cashback/cashback.config';
import { ICampaign } from 'app/types/Campaign';
import { cashbackTypes } from 'src/app/features/Cashback/constants';
import { cashbackMediumId } from 'configs/channels/mediumIds';

export const getSubChannel = (response: ICampaign) => {
  const defaultSubChannelType = !!response.medium?.id && response.medium.id === cashbackMediumId && cashbackTypes.regular;
  const subChannelType = (response.creative && response.creative.type) || defaultSubChannelType;
  const subChannel = cashbackConfig.subChannels.find(channel => channel.type === subChannelType) || '';
  return subChannel;
};

export const fromApiCampaigns = (response: Array<ICampaign>) => {
  const data = response.map<ICampaign>((item: ICampaign) => ({
    ...item,
    subChannel: getSubChannel(item),
  }));

  return data;
};
