import styled from 'styled-components';

import { Spinner as _Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';

export { default as Filters } from './Filters';
export type { IMasterplanFilterProps } from './Filters';
export { default as Highlight } from './Highlight';
export { default as Pill } from './Pill';

export const Spinner = styled(_Spinner)`
  padding: ${sc.gutterLargest};
`;
