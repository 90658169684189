import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import { Input as _Input, Dropdown, DateTimeUtcField } from 'app/midgarComponents';
import { localToUtc, setDateStrUtcToTimezone } from 'app/ducks/campaigns/campaign/helpers';
import { expiryTypes } from '../Push2Constants';
import { tzUtc } from '../../../../../configs/dateTime';

type Props = {
  expiryType: string | null;
  expiry: string | null;
  onChangeType: (...args: Array<any>) => any;
  onChangeDate: (...args: Array<any>) => any;
  onChangeDateTime: (...args: Array<any>) => any;
  onChangeRelative: (...args: Array<any>) => any;
  timezone?: string;
  min?: string;
  minDateWarning?: string;
};

const minDate = moment().add(1, 'days');

function disabledDate(current) {
  if (!current) {
    // allow empty select
    return false;
  }
  const date = moment();
  date.hour(0);
  date.minute(0);
  date.second(0);
  return current.valueOf() < minDate.valueOf(); // can not select days before today
}

export class PushExpiration extends React.PureComponent<Props> {
  render() {
    const { expiryType, expiry, onChangeType, onChangeDate, onChangeDateTime, onChangeRelative, timezone, min, minDateWarning } =
      this.props;

    let minDateUTC = minDate;
    if (min) {
      minDateUTC = moment.tz(min, 'YYYY-MM-DD HH:mm', timezone).tz(tzUtc);
    }

    return (
      <Wrapper data-qa="push-notification-expiration">
        <Box>
          <StyledDropdown
            label="Expiry Type"
            value={expiryType || undefined}
            options={Object.values(expiryTypes)}
            onChange={onChangeType}
          />
        </Box>
        <Box>
          {expiryType === expiryTypes.date && (
            <TextField
              value={expiry ? setDateStrUtcToTimezone(expiry, timezone) : ''}
              name="expiry"
              id="expiry"
              data-qa="expiration-date"
              type="date"
              placeholder="Expires on"
              onChange={ev => onChangeDate(localToUtc(ev.target.value, timezone))}
              inputProps={{ min: minDate.format('YYYY-MM-DD') }}
            />
          )}

          {expiryType === expiryTypes.datetime && (
            <DateTimeUtcField
              label="Expires on"
              name="expiry"
              id="expiry"
              data-qa="expiration-date-time"
              timezone={timezone}
              onChange={onChangeDateTime}
              required
              showToday={false}
              disabledDate={disabledDate}
              value={expiry}
            />
          )}

          {expiryType === expiryTypes.relative && (
            <Input
              value={expiry || ''}
              name="expiry"
              id="expiry"
              data-qa="expiration-relative"
              type="number"
              placeholder="Expires in days"
              onChange={onChangeRelative}
            />
          )}
        </Box>
        <Box>
          {expiryType !== expiryTypes.relative && min && minDateWarning && moment(expiry).tz(tzUtc).isBefore(minDateUTC) && (
            <ErrorMsg data-qa="expiry-error-msg">{minDateWarning}</ErrorMsg>
          )}
        </Box>
      </Wrapper>
    );
  }
}

export default connect(({ user: { timezone } }) => ({
  timezone,
}))(PushExpiration);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: -webkit-box;
  margin-bottom: 1rem;

  & .MuiTextField-root {
    border: 1px solid ${sc.greyLighter};
    padding: 1rem;
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 20rem;
`;

const Input = styled(_Input)`
  height: auto;
`;

const ErrorMsg = styled.span`
  color: red;
`;
