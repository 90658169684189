export const styles = theme => ({
  root: {},
  button: {},
  menu: {},
  menuItem: {},
  arrowDropDownIcon: {
    color: theme.palette.text.secondary,
  },

  countryName: {
    color: theme.palette.text.secondary,
  },
});
