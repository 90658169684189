import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Select from 'app/components/Select';

const hours = [...Array(25).keys()].map(hr => (hr < 10 ? `0${hr}` : `${hr}`));

export class TimeRange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      from: props.timeRange.from || hours[0],
      to: props.timeRange.to || hours[24],
      toRange: hours,
    };
  }

  dispatchNewTimeRange = () => {
    const { from, to } = this.state;
    const { setTimeRangeFn } = this.props;

    setTimeRangeFn({
      from,
      to,
    });
  };

  handleHoursFromChange = event => {
    const { to } = this.state;
    const endTime = to > event.scheduleFrom ? to : hours[24];

    this.setState(
      {
        from: event.scheduleFrom,
        toRange: hours.slice(parseInt(event.scheduleFrom, 10) + 1),
        to: endTime,
      },

      this.dispatchNewTimeRange,
    );
  };

  handleHoursToChange = event => {
    this.setState(
      {
        to: event.scheduleTo,
      },

      this.dispatchNewTimeRange,
    );
  };

  render() {
    const { to, from, toRange } = this.state;
    const { classes = {} } = this.props;

    return (
      <section key="hours" className={classes.marginTop}>
        <Typography gutterBottom variant="subtitle1" color="primary">
          Valid Hours
        </Typography>
        <div>
          <Select
            classes={classes.select}
            label="From"
            id="scheduleFrom"
            values={hours}
            value={from}
            handleChange={this.handleHoursFromChange}
          />{' '}
          hrs
          <span> - </span>
          <Select
            classes={classes.select}
            label="To"
            id="scheduleTo"
            values={toRange}
            value={to}
            handleChange={this.handleHoursToChange}
          />{' '}
          hrs
        </div>
      </section>
    );
  }
}

TimeRange.propTypes = {
  timeRange: PropTypes.shape({
    to: PropTypes.string,
    from: PropTypes.string,
  }),

  classes: PropTypes.shape({
    select: PropTypes.string,
    marginTop: PropTypes.string,
  }),

  setTimeRangeFn: PropTypes.func.isRequired,
};
