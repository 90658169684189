import { withHandlers, compose } from 'recompose';
import { connect } from 'react-redux';

import { campaignOperations } from 'app/ducks';
import * as cashbackCreativeActions from 'app/ducks/campaigns/campaign/cashbackCreative/actions';
import channelConfig from 'configs/channels/channel.config';

import { Channel } from './Channel';

const withChannels = withHandlers({
  selectChannel:
    ({ setCampaignField, updateCreativeType }) =>
    (id, subChannel = '') => {
      if (subChannel !== '') {
        updateCreativeType({ type: subChannel.type });
      }
      setCampaignField({
        mediumId: Number(id),
        subChannel,
        triggerType: subChannel && subChannel.defaultTrigger ? subChannel.defaultTrigger : channelConfig[id].defaultTrigger,
      });
    },
  selectTriggerType:
    ({ setCampaignField }) =>
    ({ target: { id } }) =>
      setCampaignField({ triggerType: id }),
});

const mapStateToProps = ({
  campaigns: {
    campaign: {
      general: { mediumId, triggerType, subChannel },
    },
  },

  user: { permissions },
}) => ({ mediumId, permissions, triggerType, subChannel });

const enhance = compose(
  connect(mapStateToProps, {
    setCampaignField: campaignOperations.setCampaignField,
    updateCreativeType: cashbackCreativeActions.updateCreativeType,
  }),

  withChannels,
);

export default enhance(Channel);
