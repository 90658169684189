import { useDispatch, useSelector } from 'react-redux';

import { IApiBannerCreative } from 'app/api/bannerManagementV2/creatives/types';
import { setError } from 'app/ducks/campaigns/campaign/bannerCreative/actions';
import {
  addBanner,
  removeBanner,
  setBanners,
  setBannerId,
  setStorefrontId,
  setViewId,
} from 'app/ducks/campaigns/campaign/bannerCreative/bannerSelection/actions';

import {
  addTarget,
  removeTarget,
  setSourceCreative,
  setTargetStorefront,
  setTargetViews,
  setValidFrom,
  setValidUpto,
} from 'app/ducks/campaigns/campaign/bannerCreative/sourceSelection/actions';

import { getBannerCreative } from 'app/ducks/campaigns/campaign/bannerCreative/selectors';
import { DispatchFn } from 'app/types';
import { IBannerCampaignSpec, IBannerCampaignTarget } from 'app/types/Creatives/BannerCreative';
import { IStorefront, IView } from 'app/types/BannerManagement';

export default (): {
  bannerSpecs: Array<IBannerCampaignSpec>;
  error: string | null | undefined;
  sourceCreative: IApiBannerCreative;
  targets: Array<IBannerCampaignTarget | null | undefined>;
  valid: boolean;
  validFrom?: string;
  validUpto?: string;
  bannerRealTimeUserCriteria: {
    userCriteria: null | string;
    userCriteriaJson: Array<any>;
  };

  // Root actions
  setError: (arg0: string) => DispatchFn;

  // Spec Actions
  addBannerSpec: () => DispatchFn;
  removeBannerSpec: (index: number) => DispatchFn;
  setBannerSpecs: (bannerSpecs: Array<IBannerCampaignSpec>) => DispatchFn;
  setSpecBannerId: (index: number, id: number) => DispatchFn;
  setSpecStorefrontId: (index: number, storefrontId: number) => DispatchFn;
  setSpecViewId: (index: number, viewId: number) => DispatchFn;

  // Source Actions
  addTarget: () => DispatchFn;
  removeTarget: (index: number) => DispatchFn;
  setSourceCreative: (creative: IApiBannerCreative) => DispatchFn;
  setSourceValidFrom: (arg0: string) => DispatchFn;
  setSourceValidTo: (arg0: string) => DispatchFn;
  setTargetStorefront: (index: number, storefront: IStorefront) => DispatchFn;
  setTargetViews: (index: number, views: Array<IView>) => DispatchFn;
} => {
  const dispatch = useDispatch();

  const { bannerSpecs, error, sourceCreative, targets, valid, validFrom, validUpto, bannerRealTimeUserCriteria } =
    useSelector(getBannerCreative);

  return {
    bannerSpecs,
    error,
    sourceCreative,
    targets,
    valid,
    validFrom,
    validUpto,
    bannerRealTimeUserCriteria,

    // Root Actions
    setError: (errorMsg: string) => dispatch(setError(errorMsg)),

    // Spec Actions
    addBannerSpec: () => dispatch(addBanner()),
    removeBannerSpec: (index: number) => dispatch(removeBanner(index)),
    setBannerSpecs: (banners: Array<IBannerCampaignSpec>) => dispatch(setBanners(banners)),
    setSpecBannerId: (index: number, bannerId: number) => dispatch(setBannerId(index, bannerId)),
    setSpecStorefrontId: (index: number, storefrontId: number) => dispatch(setStorefrontId(index, storefrontId)),
    setSpecViewId: (index: number, viewId: number) => dispatch(setViewId(index, viewId)),

    // Source Actions
    addTarget: () => dispatch(addTarget()),
    removeTarget: (index: number) => dispatch(removeTarget(index)),
    setSourceCreative: (creative: IApiBannerCreative) => dispatch(setSourceCreative(creative)),
    setSourceValidFrom: (valdFrom: string) => dispatch(setValidFrom(validFrom)),
    setSourceValidTo: (validTo: string) => dispatch(setValidUpto(validUpto)),
    setTargetStorefront: (index: number, storefront: IStorefront) => dispatch(setTargetStorefront(index, storefront)),
    setTargetViews: (index: number, views: Array<IView>) => dispatch(setTargetViews(index, views)),
  };
};
