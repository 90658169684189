import React from 'react';
import PropTypes from 'prop-types';

import {
  Chip,
  Checkbox,
  DialogActions,
  TextField,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import { List } from 'react-virtualized';

import { Search as SearchIcon, NotInterested as NotFoundIcon } from '@material-ui/icons';

import { isNeitherNilNorEmpty, isNilOrEmpty, R } from 'app/helpers/RamdaHelpers/helpers';
import DialogShell from '../DialogShell';
import Tooltip from '../Tooltip';

const MAX_TOOLTIP_ITEMS = 60;

const renderTooltipContent = values => {
  let content = null;
  const exceedsMax = values.length > MAX_TOOLTIP_ITEMS;

  if (values.length === 1) {
    content = `${values[0]}`;
  } else if (!exceedsMax) {
    content = (
      <>
        {values.map(key => (
          <Chip
            key={key}
            {...{
              label: key,
              key,
              style: {
                margin: '8px',
                borderRadius: '10px',
              },
            }}
          />
        ))}
      </>
    );
  } else {
    content = 'Tooltip is disabled because too many items are selected.';
  }

  return content;
};

const itemCount = (values, classes) =>
  isNeitherNilNorEmpty(values) && (
    <Tooltip title={renderTooltipContent(values)} type={values.length > MAX_TOOLTIP_ITEMS ? 'warning' : 'info'} placement="bottom">
      <Typography
        variant="caption"
        className={classes.wrapper}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          textDecorationStyle: 'dotted',
        }}
      >
        {`${values.length} selected`}
      </Typography>
    </Tooltip>
  );

const pluralize = (word, size) => `${word}${size > 1 ? 's' : ''}`;

const searchHintOf = count => (count < 1 ? 'No matching item found' : `${count} matching ${pluralize('item', count)}`);

const dialogHeader = (title, handleSearch, onSelectAll, handleClear, selection, searchPhrase = '', numberOfItems, classes) => (
  <>
    {title}
    <div
      style={{
        // alignItems: 'flex-end',
        display: 'flex',
        marginRight: '0.8rem',
      }}
    >
      <SearchIcon
        style={{
          marginTop: '1rem',
          marginLeft: 0,
          marginRight: '0.3rem',
        }}
      />

      <TextField
        placeholder="Type here to search..."
        type="search"
        margin="dense"
        onChange={handleSearch}
        className={classes.textField}
        style={{
          minWidth: 200,
        }}
        {...(isNilOrEmpty(searchPhrase)
          ? {}
          : {
              helperText: searchHintOf(numberOfItems),
            })}
      />
    </div>

    <DialogActions>
      {itemCount(selection, classes)}
      <Button onClick={onSelectAll} color="primary">
        Select All
      </Button>
      <Button onClick={handleClear} color="primary">
        Clear
      </Button>
    </DialogActions>
  </>
);

export const MultiSelectDialog = ({
  isOpen,
  selectionSet,
  options,
  handleChange,
  handleClose,
  handleSelectAll,
  handleClear,
  handleSearch,
  listContainerHeight,
  dialogTitle,
  searchPhrase,
  classes,
}) => {
  // Stringify the option otherwise React complains
  const choices = R.map(a => `${a}`, options);
  const onSelectAll = () => handleSelectAll(choices);
  const numberOfItems = options.length;

  const customRowRender = ({ index, key, isScrolling, style }) => {
    const option = choices[index];
    return (
      <FormControlLabel
        key={option}
        label={option}
        control={<Checkbox value={option} data-qa={`select-${option}`} checked={selectionSet.has(option)} onChange={handleChange} />}
        style={style}
      />
    );
  };
  return (
    <DialogShell
      {...{
        isOpen,
        onClose: handleClose,
      }}
      dialogHeader={dialogHeader(
        dialogTitle,
        handleSearch,
        onSelectAll,
        handleClear,
        [...selectionSet],
        searchPhrase,
        numberOfItems,
        classes,
      )}
    >
      {numberOfItems < 1 && (
        <ListItem disabled>
          <ListItemIcon>
            <NotFoundIcon />
          </ListItemIcon>
          <ListItemText primary="No item found." secondary="Clear search to see all." style={{ padding: 0 }} />
        </ListItem>
      )}

      {numberOfItems > 0 && (
        <FormControl
          {...{
            component: 'fieldset',
            style: { width: '100%' },
          }}
        >
          <FormGroup>
            <List height={600} width={400} rowHeight={40} rowRenderer={customRowRender} rowCount={choices.length} />
          </FormGroup>
        </FormControl>
      )}
    </DialogShell>
  );
};

MultiSelectDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectionSet: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  listContainerHeight: PropTypes.number.isRequired,
  dialogTitle: PropTypes.string,
  searchPhrase: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
