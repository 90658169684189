import { ILandingPageType } from 'app/types/BannerManagement';

import { State } from './reducers';

type Store = { bannerManagement: { landingPageTypes: State } };

export const mapStateToLandingPageTypes = ({
  bannerManagement: {
    landingPageTypes: { error: landingPageTypesError, loading: landingPageTypesLoading, landingPageTypes },
  },
}: Store): {
  landingPageTypesError: string | null | undefined;
  landingPageTypesLoading: boolean;
  landingPageTypes: Array<ILandingPageType>;
} => ({
  landingPageTypesError,
  landingPageTypesLoading,
  landingPageTypes,
});
