import { MasterplanExecutionsState } from 'app/types';
import { fetch } from 'app/ducks/commonStates';

import * as types from './types';

const initialState: MasterplanExecutionsState = {
  ...fetch,
  date: null,
  executions: [],
  medium: null,
  trigger: null,
};

export default (state: MasterplanExecutionsState = initialState, { type, payload }: { type: string; payload: Record<string, any> }) => {
  switch (type) {
    case types.GET_EXECUTIONS:
      return {
        ...initialState,
        loading: true,
      };

    case types.GET_EXECUTIONS_SUCCESS:
      return {
        ...state,
        ...payload,
        error: null,
        loading: false,
      };

    case types.GET_EXECUTIONS_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false,
      };

    default:
      return state;
  }
};
