import { compose, withProps, setPropTypes } from 'recompose';
import PropTypes from 'prop-types';
import { getLists, getPath } from 'app/utilities/cascadingLists';
import ListSelectableGroup from '../ListSelectableGroup';

const addProps = withProps(({ srcLists = [], selectedListId, selectedItemId }) => {
  const selected = getPath(srcLists, selectedListId, selectedItemId);
  return {
    selected,
    lists: getLists(srcLists, selected),
  };
});

const propTypes = setPropTypes({
  selectedListId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  srcLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
      ),
    }),
  ),

  handleClick: PropTypes.func,
});

const enhance = compose(propTypes, addProps);

export default enhance(ListSelectableGroup);
