import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { sc } from 'app/styles';
import { lighten } from 'polished';

import { setSlotViewItemsPageSize } from 'app/ducks/bannerManagement/binder/actions';
import { Dropdown as _Dropdown } from 'app/midgarComponents';
import { Action } from 'app/types';

const PAGES = 3; // number of pagination items to display

const PAGE_SIZE_OPTIONS = [50, 100, 200, 500];

type Props = {
  onPageChange: (arg0: number) => void;
  setSlotViewItemsPageSize: (arg0: number) => Action;
  slotViewItemsPage: number;
  slotViewItemsPageSize: number;
  slotViewItemsTotalPages: number;
};

class Pagination extends React.PureComponent<Props> {
  onPageChange = (page: number) => {
    const { onPageChange, slotViewItemsPage } = this.props;

    if (page !== slotViewItemsPage) {
      onPageChange(page);
    }
  };

  onPageSizeChange = (nStr: string) => {
    const { onPageChange, setSlotViewItemsPageSize } = this.props;
    setSlotViewItemsPageSize(Number(nStr));
    onPageChange(1);
  };

  renderPrev() {
    const { slotViewItemsPage } = this.props;

    if (slotViewItemsPage - PAGES - 1 < 1) {
      return null;
    }

    return (
      <>
        <Page key="page-first" onClick={() => this.onPageChange(1)}>
          1
        </Page>

        <Page key="page-prev" onClick={() => this.onPageChange(slotViewItemsPage - PAGES - 1)}>
          ...
        </Page>
      </>
    );
  }

  renderNext() {
    const { slotViewItemsPage, slotViewItemsTotalPages } = this.props;

    if (slotViewItemsPage + PAGES + 1 > slotViewItemsTotalPages) {
      return null;
    }

    return (
      <>
        <Page key="page-next" onClick={() => this.onPageChange(slotViewItemsPage + PAGES + 1)}>
          ...
        </Page>

        <Page key="page-final" onClick={() => this.onPageChange(slotViewItemsTotalPages)}>
          {slotViewItemsTotalPages}
        </Page>
      </>
    );
  }

  renderPages(): any {
    const { slotViewItemsPage, slotViewItemsTotalPages } = this.props;
    let prevPages = slotViewItemsPage - PAGES < 1 ? PAGES - (PAGES - slotViewItemsPage + 1) : PAGES;

    let nextPages = slotViewItemsPage + PAGES > slotViewItemsTotalPages ? slotViewItemsTotalPages - slotViewItemsPage : PAGES;

    if (!prevPages || prevPages < 0) {
      prevPages = 0;
    }

    if (!nextPages || nextPages < 0) {
      nextPages = 0;
    }

    const prev = [...Array(prevPages).keys()].map(x => slotViewItemsPage - x - 1).reverse();
    const current = [slotViewItemsPage];
    const next = [...Array(nextPages).keys()].map(x => slotViewItemsPage + x + 1);

    return prev
      .concat(current)
      .concat(next)
      .map(page => (
        <Page key={`page-${String(page)}`} onClick={() => this.onPageChange(page)} active={slotViewItemsPage === page}>
          {page}
        </Page>
      ));
  }

  render() {
    const { slotViewItemsPageSize, slotViewItemsTotalPages } = this.props;

    const pageSizeOptionNums = [
      ...PAGE_SIZE_OPTIONS,
      ...(PAGE_SIZE_OPTIONS.includes(slotViewItemsPageSize) ? [] : [slotViewItemsPageSize]),
    ];

    const pageSizeOptions = pageSizeOptionNums.sort((a, b) => a - b).map(n => String(n));

    if (slotViewItemsTotalPages < 2 && slotViewItemsPageSize === pageSizeOptionNums[0]) {
      return null;
    }

    return (
      <Container>
        {slotViewItemsTotalPages > 1 && (
          <Pages>
            {this.renderPrev()}
            {this.renderPages()}
            {this.renderNext()}
          </Pages>
        )}

        <PageSize>
          <div>Page Size:</div>

          <Dropdown label="" value={String(slotViewItemsPageSize)} options={pageSizeOptions} onChange={this.onPageSizeChange} />
        </PageSize>
      </Container>
    );
  }
}

const mapStateToProps = ({
  bannerManagement: {
    binder: { setSlotViewItemsPage },
  },
}) => ({ setSlotViewItemsPage });

const mapDispatchToProps = { setSlotViewItemsPageSize };

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: ${sc.gutterSmall};
  text-align: right;
  & > div {
    margin-left: ${sc.gutter};
  }
`;

const Dropdown = styled(_Dropdown)`
  width: 5rem;
`;

const Page = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 40px;
  line-height: 38px;

  border: 1px solid ${sc.primary};
  border-right: none;

  color: ${sc.headingColor};
  font-weight: 600;
  cursor: pointer;
  text-align: center;

  ${props =>
    props.active
      ? css`
          color: #fff;
          background-color: ${sc.primary};
        `
      : css`
          &:hover {
            color: #fff;
            background-color: ${lighten(0.3, sc.primary)};
          }
        `}

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-child {
    border-right: 1px solid ${sc.primary};
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

const Pages = styled.div``;

const PageSize = styled.div`
  align-items: center;
  display: flex;
  white-space: nowrap;
  & > div {
    margin-left: ${sc.gutterSmall};
  }
`;
