import { BmsError } from 'app/api/bannerManagementV2/types';
import { Action } from 'app/types';
import { ISlot, ISlotGroupType, IVariant, IView, IViewItem } from 'app/types/BannerManagement';

import * as types from './types';

// Re-order Slots
export const updateSlotOrderSuccess = () => ({
  type: types.UPDATE_SLOT_ORDER_SUCCESS,
});

export const updateSlotOrderError = (error: Record<string, any>) => ({
  type: types.UPDATE_SLOT_ORDER_ERROR,
  payload: { error },
});

export const updateSlotOrder = (from: number, to: number) => ({
  type: types.UPDATE_SLOT_ORDER,
  payload: { from, to },
});

// Save variant
export const saveVariantStart = (): Action => ({
  type: types.SAVE_VARIANT_START,
});

export const saveVariantSuccess = (variant: IVariant): Action => ({
  type: types.SAVE_VARIANT_SUCCESS,
  payload: { variant },
});

export const saveVariantError = (error: Record<string, any>): Action => ({
  type: types.SAVE_VARIANT_ERROR,
  payload: { error },
});

export const saveVariantStatusError = (viewItem: IViewItem, variant: IVariant, error: BmsError): Action => ({
  type: types.SAVE_VARIANT_STATUS_ERROR,
  payload: { error, variant },
});

export const saveVariantStatusStart = (viewItem: IViewItem, variant: IVariant): Action => ({
  type: types.SAVE_VARIANT_STATUS_START,
  payload: { variant },
});

export const saveVariantStatusSuccess = (viewItem: IViewItem, variant: IVariant): Action => ({
  type: types.SAVE_VARIANT_STATUS_SUCCESS,
  payload: { variant },
});

export const updateVariantStatus = (viewItem: IViewItem, variant: IVariant, status: 0 | 1): Action => ({
  type: types.UPDATE_VARIANT_STATUS,
  payload: { viewItem, variant, status },
});

// Personalization
export const setIsPersonalizedSaving = (isPersonalizedSaving: boolean): Action => ({
  type: types.SET_IS_PERSONALIZED_SAVING,
  payload: { isPersonalizedSaving },
});

export const setIsPersonalized = (isPersonalized: boolean): Action => ({
  type: types.SET_IS_PERSONALIZED,
  payload: { isPersonalized },
});

// Fetch Viewitem
export const fetchViewItemStart = (): Action => ({
  type: types.FETCH_VIEW_ITEM_START,
});

export const fetchViewItemSuccess = (viewItem: Record<string, any>): Action => ({
  type: types.FETCH_VIEW_ITEM_SUCCESS,
  payload: { viewItem },
});

export const fetchViewItemError = (error: Record<string, any>): Action => ({
  type: types.FETCH_VIEW_ITEM_ERROR,
  payload: { error },
});

// Update Viewitem
export const setViewItemField = (field: string, value: any): Action => ({
  type: types.SET_VIEWITEM_FIELD,
  payload: { field, value },
});

export const saveViewItemStart = () => ({
  type: types.SAVE_VIEWITEM_START,
});

export const saveViewItemSuccess = (slotId: number) => ({
  type: types.SAVE_VIEWITEM_SUCCESS,
  payload: { slotId },
});

export const saveViewItemError = (error: Error) => ({
  type: types.SAVE_VIEWITEM_ERROR,
  payload: { error },
});

// Reset view item
export const resetViewItem = (): Action => ({
  type: types.RESET_VIEW_ITEM,
});

// Dates
export const resetDates = (): Action => ({
  type: types.RESET_DATES,
});

export const setSelectedDate = (field: string, value: any): Action => ({
  type: types.SET_SELECTED_DATE,
  payload: { field, value },
});

// Views
export const setSelectedSlotGroupType = (slotGroupType: ISlotGroupType): Action => ({
  type: types.SET_SELECTED_SLOT_GROUP_TYPE,
  payload: { slotGroupType },
});

export const getViewById = (): Action => ({
  type: types.GET_VIEW,
});

export const getViewByIdSuccess = (view: IView, slotId: number): Action => ({
  type: types.GET_VIEW_SUCCESS,
  payload: { view, slotId },
});

export const getViewByIdFailed = (error: Error): Action => ({
  type: types.GET_VIEW_FAILED,
  payload: { error },
});

export const getSPViewStart = (): Action => ({
  type: types.GET_SP_VIEW_START,
});

export const getSPViewSuccess = (view: Record<string, any>, spView: Record<string, any>): Action => ({
  type: types.GET_SP_VIEW_SUCCESS,
  payload: { view, spView },
});

// Bind creative
export const setSelectedViewItemId = (id: number): Action => ({
  type: types.SET_SELECTED_VIEW_ITEM_ID,
  payload: { id },
});

export const bindCreativeStart = (): Action => ({
  type: types.BIND_CREATIVE_START,
});

export const bindCreativeSuccess = (slotId: number): Action => ({
  type: types.BIND_CREATIVE_SUCCESS,
  payload: { slotId },
});

export const bindCreativeError = (error: Error): Action => ({
  type: types.BIND_CREATIVE_ERROR,
  payload: { error },
});

// Slots
export const updateSlotStatusSuccess = (slotId: number, status: boolean) => ({
  type: types.UPDATE_SLOT_STATUS_SUCCESS,
  payload: { slotId, status },
});

export const updateSlotOverrideSuccess = (slotId: number, override: boolean) => ({
  type: types.UPDATE_SLOT_OVERRIDE_SUCCESS,
  payload: { slotId, override },
});

export const deleteSlotStart = () => ({
  type: types.DELETE_SLOT_START,
});

export const deleteSlotSuccess = (slotId: number) => ({
  type: types.DELETE_SLOT_SUCCESS,
  payload: { slotId },
});

export const deleteSlotFailed = () => ({
  type: types.DELETE_SLOT_FAILED,
});

/**
 * Binder Slots
 */
export const addSlot = (): Action => ({
  type: types.ADD_SLOT,
});

export const addSlotSuccess = (slots: Array<ISlot>): Action => ({
  type: types.ADD_SLOT_SUCCESS,
  payload: { slots },
});

export const addSlotFailed = (error: Error): Action => ({
  type: types.ADD_SLOT_FAILED,
  payload: { error },
});

export const updateSelectedSlot = (selectedSlot: ISlot): Action => ({
  type: types.UPDATE_SELECTED_SLOT,
  payload: { selectedSlot },
});

/**
 * Binder Slot ViewItems
 */
export const getSlotViewItems = (): Action => ({
  type: types.GET_SLOT_VIEW_ITEMS,
});

export const getSlotViewItemsSuccess = (viewItems: IViewItem[], totalRows: number, version: number, page: number): Action => ({
  type: types.GET_SLOT_VIEW_ITEMS_SUCCESS,
  payload: { viewItems, totalRows, version, page },
});

export const getSlotViewItemsFailed = (error: Error): Action => ({
  type: types.GET_SLOT_VIEW_ITEMS_FAILED,
  payload: { error },
});

export const setSlotViewItemsPage = (page: number): Action => ({
  type: types.SET_SLOT_VIEW_ITEMS_PAGE,
  payload: { page },
});

export const setSlotViewItemsPageSize = (pageSize: number): Action => ({
  type: types.SET_SLOT_VIEW_ITEMS_PAGE_SIZE,
  payload: { pageSize },
});

export const reorderViewItems = (viewItems: Array<Record<string, any>>): Action => ({
  type: types.REORDER_VIEW_ITEMS,
  payload: { viewItems },
});

// move after banner id
export const moveAfterBannerIdStart = () => ({
  type: types.MOVE_AFTER_BANNER_ID_START,
});

export const moveAfterBannerIdSuccess = (priority: number) => ({
  type: types.MOVE_AFTER_BANNER_ID_SUCCESS,
  payload: { priority },
});

export const moveAfterBannerIdError = () => ({
  type: types.MOVE_AFTER_BANNER_ID_ERROR,
});

export const fetchViewItemLocationSuccess = (viewItem: Record<string, any>): Action => ({
  type: types.FETCH_VIEW_ITEM_LOCATION_SUCCESS,
  payload: { viewItem },
});

export const fetchViewItemsLocationSuccess = (viewItems: Record<string, any>): Action => ({
  type: types.FETCH_VIEW_ITEMS_LOCATION_SUCCESS,
  payload: { viewItems },
});

export const resetHighlightedViewItem = (): Action => ({
  type: types.RESET_HIGHLIGHTED_VIEW_ITEM,
});

export const updateViewField = (field: Record<string, string>): Action => ({
  type: types.UPDATE_VIEW_FIELD,
  payload: { field },
});

export const resetView = (): Action => ({
  type: types.RESET_VIEW,
});

export const saveViewFailed = (error: string): Action => ({
  type: types.SAVE_VIEW_FAILED,
  payload: { error },
});

export const saveViewStart = (): Action => ({
  type: types.SAVE_VIEW_START,
});

export const saveViewSuccess = (): Action => ({
  type: types.SAVE_VIEW_SUCCESS,
});

export const bulkViewItemActionClearError = (): Action => ({
  type: types.BULK_VIEW_ITEM_ACTION_CLEAR_ERROR,
});

export const bulkViewItemActionError = (actionName: string, error: Error): Action => ({
  type: types.BULK_VIEW_ITEM_ACTION_ERROR,
  payload: {
    bulkAction: actionName,
    error,
  },
});

export const bulkViewItemActionStart = (actionName: string): Action => ({
  type: types.BULK_VIEW_ITEM_ACTION_START,
  payload: { bulkAction: actionName },
});

export const bulkViewItemDeleteSuccess = (ids: Array<number>, version: number): Action => ({
  type: types.BULK_VIEW_ITEM_DELETE_SUCCESS,
  payload: { ids, version },
});

export const bulkViewItemMoveSuccess = (ids: Array<number>): Action => ({
  type: types.BULK_VIEW_ITEM_MOVE_SUCCESS,
  payload: { ids },
});
