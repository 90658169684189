import * as types from './types';

const initialState = {
  bannerIds: [],
  bannerDebugs: {},
  states: null,
  statesLoading: false,
  loading: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SEARCH_START:
      return {
        ...state,
        loading: true,
      };

    case types.SEARCH_SUCCESS:
      return {
        ...state,
        bannerIds: payload.bannerIds,
        bannerDebugs: payload.bannerDebugs,
        loading: false,
        error: null,
      };

    case types.SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case types.FETCH_STATES_START:
      return {
        ...state,
        statesLoading: true,
      };

    case types.FETCH_STATES_SUCCESS:
      return {
        ...state,
        states: payload.states,
        statesLoading: false,
        error: null,
      };

    case types.FETCH_STATES_FAIL:
      return {
        ...state,
        statesLoading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};
