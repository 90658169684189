import { fetchViews } from 'app/api/storefront';
import { ICmaView } from 'app/api/storefront/types';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { DispatchFn, GetStateFn } from 'app/types';

import { mapStateToStorefronts } from '../storefronts/selectors';
import * as actions from './actions';
import { mapStateToViews } from './selectors';

const DEFAULT_ENTITY_ID = 1;

export const getViews =
  (storefrontId: number) =>
  async (dispatch: DispatchFn, getState: GetStateFn): Promise<void> => {
    const { views, viewsError, viewsLoading } = mapStateToViews(storefrontId)(getState());
    const { storefronts } = mapStateToStorefronts(getState());

    const areStorefrontsRetrieved = !!storefronts?.length;
    const areViewsRetrieved = views?.length || viewsError || viewsLoading;

    if (!areStorefrontsRetrieved || areViewsRetrieved) {
      return;
    }

    dispatch(actions.getViewsStart(storefrontId));

    try {
      const storefront = storefronts?.find(s => s.id === storefrontId);
      const entityId = storefront?.entityAssociatedWith || DEFAULT_ENTITY_ID;
      const views: Array<ICmaView> = await fetchViews(storefrontId, entityId);
      dispatch(actions.getViewsSuccess(storefrontId, views));
    } catch (err) {
      displayError(`Error fetching views: ${err.message}`);
      dispatch(actions.getViewsError(storefrontId, err.message));
    }
  };
