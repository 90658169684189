import { ISearchDefs } from 'app/hocs/withReporting';

export const searchParams = {
  email: 'email',
  tenant: 'tenant',
};

export const searchDefs: ISearchDefs = [{ name: searchParams.email, label: 'email' }];

export { searchDefs as searchBarDefs };

export const searchLabel = (name: string) => (searchDefs.find(def => def.name === name) || {}).label || '';
