import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import { isNeitherNilNorEmpty } from 'app/helpers/RamdaHelpers/helpers';
import SingleSelectDialog from '../SingleSelectDialog';
import Tooltip from '../Tooltip';

export const ReadOnlyInputWithSingleSelectDialog = ({
  id,
  label,
  value,
  options,
  handleOpen,
  handleClose,
  isOpen,
  dialogTitle,
  groupName,
  classes,
}) => (
  <>
    <Tooltip title={isNeitherNilNorEmpty(value) ? `${value}` : ''}>
      <TextField
        required
        margin="dense"
        className={classes.textField}
        id={id}
        data-qa="value"
        label={label}
        InputProps={{
          readOnly: true,
        }}
        value={value || ''}
        onClick={handleOpen}
      />
    </Tooltip>
    <SingleSelectDialog
      value={value}
      isOpen={isOpen}
      options={options}
      onClose={handleClose}
      dialogTitle={dialogTitle}
      groupName={groupName}
      classes={classes}
    />
  </>
);

ReadOnlyInputWithSingleSelectDialog.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string,
  groupName: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
