import { dissoc, has, map } from 'ramda';
import moment from 'moment';
import 'moment-timezone';

import { mapIds } from 'app/ducks/helpers';
import { cashbackTypes } from 'app/features/Cashback/constants';
import { getDelayInSeconds } from 'app/features/Campaigns/utils';
import { segmentTypeFlags } from 'app/features/Segments/utils';
import { stringifyPredicates } from 'app/midgarComponents/PredicateBuilder/helper';
import { IEdge, IStage } from 'app/types/Campaign';
import { triggerTypes, daysOfWeek } from 'app/utilities/constants';
import { bannerMediumId, push2MediumId, emailMediumId, cashbackMediumId, notificationMediumId } from 'configs/channels/mediumIds';
import { tzUtc } from 'configs/dateTime';

import { toBannerCampaignCreative } from './bannerCreative/utils';
import { isNull } from 'ramda-adjunct';

const oneTimeFields = ['triggerType', 'dedupeStrategy', 'customerCountPerExecution', 'firstOccurrence'];
const recurringFields = [...oneTimeFields, 'quotaPeriodInDays', 'expiryDate', 'cron'];
const locationFields = [...recurringFields, 'defaultRadius', 'csvUrl', 'timeRange'];
const eventsBasedFields = [...locationFields, 'numberOfImpressions'];

const allFields = {
  [triggerTypes.location]: locationFields,
  [triggerTypes.oneTime]: oneTimeFields,
  [triggerTypes.recurring]: recurringFields,
  [triggerTypes.cartAbandon]: recurringFields,
  [triggerTypes.orderAbandon]: recurringFields,
  [triggerTypes.eventsBased]: eventsBasedFields,
};

// TODO: Get from channelConfig
const creatives = {
  [bannerMediumId]: 'bannerCreative',
  [cashbackMediumId]: 'cashbackCreative',
  [emailMediumId]: 'emailCreative',
  [push2MediumId]: 'push2Creative',
  [notificationMediumId]: 'TimelineNotificationCreative',
};

export const setDateStrUtcToTimezone = (date, timezone, hasTime = false) =>
  moment
    .tz(date, tzUtc)
    .clone()
    .tz(timezone)
    .format(`YYYY-MM-DD${hasTime ? 'Thh:mm' : ''}`);

export const localToUtc = (date, timezone) => moment.tz(date, timezone).utc().format();

const mapLocalDaysToUtc = (localDays, localDateTime, timezone) => {
  if (!Array.isArray(localDays) || localDays.length === 0) {
    return [];
  }

  if (localDays[0] === '*') {
    return localDays;
  }

  return localDays.map(day => {
    const utcDayNumber = moment.tz(localDateTime, timezone).day(daysOfWeek.indexOf(day)).utc().day();

    return daysOfWeek[utcDayNumber];
  });
};

export const setCron = (localDays, localDateTime, timezone) => {
  const utcDays = mapLocalDaysToUtc(localDays, localDateTime, timezone);
  const utcHour = moment.tz(localDateTime, timezone).utc().hour();
  const utcMin = moment.tz(localDateTime, timezone).utc().minute();

  return `0 ${utcMin} ${utcHour} ? * ${utcDays.join(',')}`.trim();
};

const mapUtcDaysToLocal = (utcDays, utcDateTime, timezone) => {
  if (!Array.isArray(utcDays) || utcDays.length === 0) {
    return [];
  }

  if (utcDays[0] === '*') {
    return utcDays;
  }

  return utcDays.map(day => {
    const localDayIndex = moment.utc(utcDateTime).day(daysOfWeek.indexOf(day)).tz(timezone).day();

    return daysOfWeek[localDayIndex];
  });
};

export const setDays = (cron, utcDateTime, timezone) => {
  if (!cron || typeof cron !== 'string' || !cron.split('? *')[1]) {
    return ['*'];
  }

  const utcDays = cron.split('? *')[1].trim().split(',');

  return mapUtcDaysToLocal(utcDays, utcDateTime, timezone);
};

const orderStageIds = stages =>
  Object.values(stages)
    .sort((a, b) => a.order - b.order)
    .map(s => s.id);

const jsonifyCriteria = criteria => ({
  eventCriteriaJson: criteria.eventCriteriaJson || [],
  eventCriteria: criteria.eventCriteria || stringifyPredicates(criteria.eventCriteriaJson),
  userCriteriaJson: criteria.userCriteriaJson || [],
  userCriteria: criteria.userCriteria || stringifyPredicates(criteria.userCriteriaJson),
});

const jsonifyBannerCriteria = bannerCriteria => {
  if (!bannerCriteria || !bannerCriteria.userCriteriaJson.length) {
    return;
  }
  return {
    userCriteriaJson: bannerCriteria.userCriteriaJson || [],
    userCriteria: bannerCriteria.userCriteria || stringifyPredicates(bannerCriteria.userCriteriaJson),
  };
};

const jsonifyJourneyCriteria = criteria => ({
  ...criteria,
  eventCriteriaJson: criteria.eventCriteriaJson || [],
  eventCriteria: criteria.eventCriteria || stringifyPredicates(criteria.eventCriteriaJson),
});

const sendJourneyStages = stages => {
  const convertEdges = orderStageIds(stages).map((id, index) => ({
    id: index + 1,
    previousStageId: index,
    nextStageId: index + 1,
    criteria: jsonifyJourneyCriteria(stages[id].criteria),
  }));

  const convertStages = orderStageIds(stages).map((id, index) => {
    const { criteria, ...stageAPI } = stages[id]; // remove criteria from stages in API
    return {
      ...stageAPI,
      id: index + 1,
      calculation: stages[id].calculation,
    };
  });
  return {
    stages: convertStages,
    edges: convertEdges,
  };
};

// Map Edge Array to Prev Node Map
const PrevNodeMap = (edges: IEdge[]) =>
  edges.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.previousStageId]: curr,
    }),

    {},
  );

// Map Edge Array to Next Node Map
const NextNodeMap = (edges: IEdge[]) =>
  edges.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.nextStageId]: curr,
    }),

    {},
  );

// Map Stage Array to Stage Map
const StageMap = (stages: IStage[]) =>
  stages.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }),

    {},
  );

export const getMappedStages = (stages: IStage[], edges: IEdge[]) => {
  const stageMaps = StageMap(stages);
  let startEdge = edges[0];
  const initialEdge = startEdge;
  const secHalfStages = [
    {
      ...stageMaps[startEdge.nextStageId],
      criteria: startEdge.criteria,
      calculation: stageMaps[startEdge.nextStageId].calculation,
    },
  ];

  const prevNodes = PrevNodeMap(edges);
  while (prevNodes[startEdge.nextStageId]) {
    const nextEdge = prevNodes[startEdge.nextStageId];
    const nextStage = stageMaps[nextEdge.nextStageId];
    secHalfStages.push({
      ...nextStage,
      criteria: nextEdge.criteria,
      calculation: nextStage.calculation,
    });

    startEdge = nextEdge;
  }

  if (secHalfStages.length === stages.length) return secHalfStages;
  const nextNodes = NextNodeMap(edges);
  startEdge = initialEdge;
  const firstHalfStages = [];
  while (nextNodes[startEdge.previousStageId]) {
    const prevEdge = nextNodes[startEdge.previousStageId];
    const prevStage = stageMaps[prevEdge.nextStageId];
    firstHalfStages.unshift({
      ...prevStage,
      criteria: prevEdge.criteria,
      calculation: prevStage.calculation,
    });

    startEdge = prevEdge;
  }

  return [...firstHalfStages, ...secHalfStages];
};

const setCashbackCreative = creative => {
  const { regular, journey, type } = creative;
  const regularCriteria = {
    ...regular.criteria,
    ...jsonifyCriteria(regular.criteria),
  };

  let newCreative = {};
  if (type === cashbackTypes.regular || !type) {
    newCreative = {
      type: cashbackTypes.regular,
      ...regular,
      cashbackPromoBanner: !regular.cashbackPromoBanner
        ? null
        : {
            ...regular.cashbackPromoBanner,
            type: cashbackTypes.regular,
          },

      criteria: regularCriteria,
    };
  } else {
    const { stages, ...rest } = journey;
    const cashbackPromoBanner = isNull(journey.cashbackPromoBanner)
      ? null
      : {
          ...journey.cashbackPromoBanner,
          type: cashbackTypes.journey,
        };

    newCreative = {
      ...rest,
      type: cashbackTypes.journey,
      creativeUserCriteria: {
        ...rest.creativeUserCriteria,
        userCriteria: rest.creativeUserCriteria.userCriteria || stringifyPredicates(rest.creativeUserCriteria.userCriteriaJson),
      },

      ...sendJourneyStages(stages),
      cashbackPromoBanner: cashbackPromoBanner,
    };
  }
  return newCreative;
};

const setPush2Creative = (creative, schemaId) => {
  if (schemaId && creative.templated) creative.payload.notification.content.schemaId = schemaId;

  delete creative.deeplinkType;
  delete creative.sites;

  return creative;
};

const setTimelineNotificationCreative = creative => {
  return creative.payload;
};

const setCreative = (id, creative, schemaId = '') => {
  switch (id) {
    case bannerMediumId:
      return toBannerCampaignCreative(creative);
    case cashbackMediumId:
      return setCashbackCreative(creative);
    case push2MediumId:
      return setPush2Creative(creative, schemaId);
    case notificationMediumId:
      return setTimelineNotificationCreative(creative);
    default:
      return creative;
  }
};

// In some cases, data_type is passed in, so fall back to this value if dataType is not present
const mapVariables = map(v =>
  map(variableType => ({
    ...variableType,
    dataType: has('dataType', variableType) ? variableType.dataType : has('data_type', variableType) ? variableType.data_type : undefined,
  }))(v),
);

export const mapScheduling = (campaign, timezone) => {
  const {
    startDate,
    startTime,
    expiryDate,
    expiryTime,
    triggerType,
    dedupeStrategy,
    customerCountPerExecution,
    quotaPeriodInDays,
    defaultRadius,
    csvUrl,
    timeRange,
    numberOfImpressions,
    days,
  } = campaign;

  const firstOccurrence = (startDate && localToUtc(`${startDate}T${startTime}`, timezone)) || '';
  const utcExpiryDate = (expiryDate && localToUtc(`${expiryDate}T${expiryTime}`, timezone)) || '';

  const allScheduling = {
    triggerType,
    dedupeStrategy,
    customerCountPerExecution,
    quotaPeriodInDays,
    defaultRadius,
    csvUrl,
    firstOccurrence,
    expiryDate: utcExpiryDate,
    cron: setCron(days, `${startDate}T${startTime}`, timezone),
    timeRange: [timeRange],
    numberOfImpressions,
  };

  const scheduling = allFields[triggerType].reduce((acc, f) => ({ ...acc, [f]: allScheduling[f] }), {});

  return scheduling;
};

export const criteriaToCampaignApi = (criteria, triggerType) => {
  switch (triggerType) {
    case triggerTypes.orderAbandon:
      return {
        ...criteria,
        orderCategories: mapIds(criteria?.orderCategories),
      };

    case triggerTypes.eventsBased:
      if (criteria?.delayTime) {
        const triggerDelayInSeconds = getDelayInSeconds(criteria.delayTime?.value, criteria.delayTime?.unit);
        const result = { ...criteria, triggerDelayInSeconds };
        delete result.delayTime;

        return result;
      }

      return criteria;
    default:
      return null;
  }
};

export const getSegmentIds = (segments, audienceFiltersEnabled) => {
  let result = segments;
  if (audienceFiltersEnabled) {
    result = segments.filter(x => x.type === segmentTypeFlags.RULE_BASED);
  }
  return mapIds(result);
};

export const setCampaign = state => {
  const {
    campaigns: { campaign },
    features: { schemaId },
    user: { timezone },
  } = state();

  const {
    bannerCreative,
    general: {
      includedSegments,
      excludedSegments,
      includedSegmentsFilters,
      excludedSegmentsFilters,
      audienceFiltersEnabled,
      categories2,
      tags,
      name,
      businessPhase,
      useEveryoneAudience,
      variables,
      promocode,
      triggerType,
      criteria,
      exclusionCampaign,
      campaignPromotionCode,
      goal,
      objective,
      audienceId,
    },
  } = campaign;

  const mediumId = Math.floor(campaign.general.mediumId);

  const creative = dissoc('valid')(campaign[creatives[mediumId]]);

  return {
    name,
    criteria: criteriaToCampaignApi(criteria, triggerType),
    bannerRealTimeUserCriteria: jsonifyBannerCriteria(bannerCreative?.bannerRealTimeUserCriteria),
    scheduling: mapScheduling(campaign.general, timezone),
    creative: setCreative(mediumId, creative, schemaId),
    includedSegmentIds: getSegmentIds(includedSegments, audienceFiltersEnabled),
    excludedSegmentIds: getSegmentIds(excludedSegments, audienceFiltersEnabled),
    includedSegmentsFilters: mapIds(includedSegmentsFilters),
    excludedSegmentsFilters: mapIds(excludedSegmentsFilters),
    category2Ids: mapIds(categories2),
    exclusionCampaign,
    tagIds: mapIds(tags),
    mediumId,
    promocode,
    useEveryoneAudience,
    variables: mapVariables(variables),
    campaignPromotionCode,
    businessPhaseId: businessPhase?.id || null,
    goalId: goal?.id,
    objectiveId: objective?.id,
    audienceId,
  };
};

export const transformCampaignVariables = campaign => {
  if (Array.isArray(campaign.variables)) {
    return campaign.variables;
  }
  return Object.values(campaign.variables).reduce((total, variablesArray) => [...total, ...variablesArray], []);
};

export const setTestDeliveryCreative = (sourceCampaign, evaluatedTemplate) => {
  let evaluatedCreative;

  switch (sourceCampaign.mediumId) {
    case push2MediumId:
      evaluatedCreative = {
        content: {},
        interactive: {
          click: {},
        },
      };

      Object.keys(evaluatedTemplate).forEach(key => {
        if (key === 'deeplink') evaluatedCreative.interactive.click.value = evaluatedTemplate.deeplink;
        else evaluatedCreative.content[key] = evaluatedTemplate[key];
      });

      return {
        ...sourceCampaign.creative,
        payload: {
          ...sourceCampaign.creative.payload,
          notification: {
            ...sourceCampaign.creative.payload.notification,
            content: {
              ...sourceCampaign.creative.payload.notification.content,
              ...evaluatedCreative.content,
            },

            interactive: {
              ...sourceCampaign.creative.payload.notification.interactive,
              click: {
                ...sourceCampaign.creative.payload.notification.interactive.click,
                ...evaluatedCreative.interactive.click,
              },
            },
          },
        },
      };

    default:
      return sourceCampaign.creative;
  }
};
