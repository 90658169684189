import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { NavLink } from 'reactstrap';

export const NotFound = ({ errorTitle, goBackPath, feature, classes = {} }) => (
  <section className={classes.root}>
    <Typography className={classes.error} align="center" variant="h5">
      {errorTitle}
    </Typography>
    <Button variant="contained" color="primary" component={NavLink} to={goBackPath} className={classes.button} size="large">
      View all {feature}
    </Button>
  </section>
);

NotFound.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    error: PropTypes.string,
    button: PropTypes.string,
  }),

  errorTitle: PropTypes.string.isRequired,
  goBackPath: PropTypes.string.isRequired,
  feature: PropTypes.string.isRequired,
};
