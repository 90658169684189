import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { saveBanners as apiSaveBanners } from 'app/api/campaigns/bannerIds';
import { getBannerCreative } from 'app/ducks/campaigns/campaign/bannerCreative/selectors';
import { setBannerCreative } from 'app/ducks/campaigns/campaign/actions';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { Button, Spinner } from 'app/midgarComponents';
import { ICampaign } from 'app/types';
import { IBannerCampaignSpec } from 'app/types/Creatives/BannerCreative';
import { bannerMediumId } from 'configs/channels/mediumIds';

import BannerSelection from '../Creation/BannerSelection';

type Props = {
  bannerUpdate?: (campaignId: number, arg1: Array<IBannerCampaignSpec>) => void;
  campaign: ICampaign;
  closeModal: () => void;
  open: boolean;
  setCampaignField?: (arg0: Record<string, any>) => void; // Supplied if the container is the campaign overview page
};

export default ({ bannerUpdate, campaign, closeModal, open, setCampaignField }: Props) => {
  const { creative, id: campaignId, mediumId } = campaign;
  const { bannerSpecs, bannerRealTimeUserCriteria, valid } = useSelector(getBannerCreative);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBannerCreative(creative));
  }, [campaignId, creative, dispatch]);

  const saveBanners = async () => {
    const newBannerSpecs = bannerSpecs?.filter(spec => spec && !isEmpty(spec));
    if (newBannerSpecs?.length > 0) {
      if (valid) {
        try {
          setSaving(true);
          await apiSaveBanners(campaignId, newBannerSpecs);

          bannerUpdate && bannerUpdate(campaign?.id, newBannerSpecs);
          setCampaignField &&
            setCampaignField({ creative: { banners: newBannerSpecs, bannerRealTimeUserCriteria: bannerRealTimeUserCriteria } });

          displaySuccess('Banners successfully updated');
          closeModal();
        } catch (err) {
          displayError(`Error saving banner ids: ${err.message}`);
        } finally {
          setSaving(false);
        }
      } else {
        displayError('You must complete all fields');
      }
    } else {
      displayError('Must specify at least one banner');
    }
  };

  if (mediumId !== bannerMediumId) {
    const msg = `BannerInputModal: Opened for a non-banner campaign. MediumId = ${mediumId}, but expects ${bannerMediumId}`;
    console.error(msg);
    displayError(msg);
    return null;
  }

  return (
    <Dialog
      aria-labelledby="edit-banners-dialog-title"
      data-qa="edit-banners-dialog"
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle id="edit-banners-dialog-title">Edit Banner IDs</DialogTitle>

      <DialogContent dividers={true}>
        <BannerSelection />
      </DialogContent>

      <DialogActions>
        <Button data-qa="cancel" onClick={closeModal} type="secondary">
          Cancel
        </Button>

        <Button data-qa="save" disabled={saving} onClick={saveBanners} type="primary">
          {saving ? <Spinner /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
