import { ICmaStorefront } from 'app/api/storefront/types';

import * as types from './types';

export const getStorefrontsError = (error: string) => ({
  type: types.GET_STOREFRONTS_ERROR,
  payload: { error },
});

export const getStorefrontsStart = () => ({
  type: types.GET_STOREFRONTS_START,
});

export const getStorefrontsSuccess = ({
  permittedStorefronts,
  storefronts,
}: {
  permittedStorefronts: Array<ICmaStorefront>;
  storefronts: Array<ICmaStorefront>;
}) => ({
  type: types.GET_STOREFRONTS_SUCCESS,
  payload: { permittedStorefronts, storefronts },
});
