/**
 * @param {*} tags
 * @deprecated To be removed when the component TagsEdit is removed
 */
export const asTagNames = tags => tags.allIds.map(id => tags.byId[id].name);

/**
 * @param {*} tagName
 * @param {*} state
 * @deprecated To be removed when the component TagsEdit is removed
 */
export const findTagId = (tagName, state) => state.tags.idByName[tagName.toLowerCase()];

/**
 * Return the new custom tags which are in use in the tag IDs array.
 *
 * A tagId < 0 is required for all custom tags which have not been created yet.
 *
 * @param {*} newTags = Cache of custom tags which are to be created
 * @param {*} tagIds = Array of tagIds which are in use
 */
export const filterTagsToCreate = (newTags, tagIds) => {
  const newTagIds = (tagIds || []).filter(id => id < 0);
  return (newTags || []).filter(tag => newTagIds.includes(tag.id));
};

/**
 * Return the tag results which failed.
 *
 * @param {*} tagResults
 */
export const failed = tagResults => (tagResults || []).filter(res => !res.success);

/**
 * Return the tag results which succeeded.
 *
 * @param {*} tagResults
 */
export const succeeded = tagResults => (tagResults || []).filter(res => res.success);

/**
 * Return a new array of tagIds where the custom tag IDs (< 0) are replaced with the succesfully created tag IDs.
 * @param {*} tagIds
 * @param {*} tagResults
 */
export const replaceNewTagIds = (tagIds, tagResults) => {
  const createdTagIds = (tagResults || []).filter(tagRes => tagRes.success).reduce((acc, curr) => ({ ...acc, [curr.id]: curr.newId }), {});
  return (tagIds || []).map(id => (id < 0 ? createdTagIds[id.toString()] || id : id));
};

/**
 * Return a user-friendly error message for the number of tag creation results which failed.
 *
 * @param {*} res
 */
export const errorMsgPostMultiFailed = tagResults => {
  const failedRes = failed(tagResults) || [];
  if (!failedRes.length) return '';
  return `${failedRes.length} of ${tagResults.length} new tag(s) could not be created.\n${failedRes
    .map(thisRes => thisRes.name)
    .join('\n')}`;
};
