import * as React from 'react';
import styled from 'styled-components';

import postDeliver, { minimalEmailCampaign } from 'app/api/campaigns/testDelivery';
import { TestDelivery } from 'app/components';
import { msgDeliveryFailed, msgDeliverySuccess } from 'app/components/TestDelivery/utils';
import { displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { Button as _Button, Grid } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ITemplate } from 'app/types';
import { channelNameEmail } from 'configs/channels/constants';

const campaignFromTemplate = ({ subject, sendFrom, sendFromName, replyTo, replyToName, content }) =>
  minimalEmailCampaign({
    subject,
    sender: sendFrom,
    senderName: sendFromName,
    replyTo,
    replyToName,
    html: content,
  });

export default class Actions extends React.PureComponent<{
  template: ITemplate;
}> {
  handleTestDelivery = async targetEmails => {
    const { template } = this.props;
    try {
      const result = await postDeliver(campaignFromTemplate(template), targetEmails);
      displaySuccess(msgDeliverySuccess(result));
    } catch (error) {
      template.setError(msgDeliveryFailed(error));
    }
  };

  render() {
    const { template } = this.props;
    return (
      <Grid container>
        <Grid item sm={9}>
          <TestDelivery buttonText="Send Sample" deliveryChannel={channelNameEmail} handleSend={this.handleTestDelivery} templatesMax={3} />
        </Grid>
        <Grid item sm={3}>
          <Button disabled={!template.editable} to={`/channels/email/${template.id}`}>
            Edit
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const Button = styled(_Button)`
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  left: ${sc.gutterSmallest};
`;
