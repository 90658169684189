import React from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import { RouterHistory } from 'react-router-dom';
import moment from 'moment';

import { IMasterplanApiParams } from 'app/api/masterplan/types';
import { withAppConfig } from 'app/decorators';
import { parseDateTimeUtc } from 'app/utilities/date';
import { IAppConfig } from 'configs/apps/types';
import { defaultDateFormat } from 'configs/dateTime';

import Priorities from './priorities';
import Executions from './executions';
import { defaultTriggerOption, triggerOptionsByMedium } from './constants';
import { getChannelOptions, isStarted } from './utils';
import { compose } from 'recompose';

type Props = IMasterplanApiParams & {
  appConfig: IAppConfig;
  history: RouterHistory;
  isPermitted: (arg0: Array<string>) => boolean;
  search?: string;
  timezone: string;
};

const urlReader = ({ appConfig, history, ...rest }) => {
  const { date: dateRaw, medium: mediumRaw, search, trigger } = qs.parse(history.location.search);
  const channelOptions = getChannelOptions(appConfig) || [];
  const selectedChannel = channelOptions.find(opt => opt.id === mediumRaw) || (channelOptions.length ? channelOptions[0] : null);
  const date = parseDateTimeUtc(dateRaw);
  return {
    medium: selectedChannel ? selectedChannel.id : '',
    date: (date || moment()).format(defaultDateFormat),
    search,
    trigger: trigger || defaultTriggerOption.id,
    appConfig,
    history,
    ...rest,
  };
};

class MasterPlan extends React.Component<Props> {
  handleChangeDate = (date: string) => {
    const { history, medium, search, trigger } = urlReader(this.props);
    history.push(`?${qs.stringify({ date: date || moment().format(defaultDateFormat), medium, search, trigger })}`);
  };

  handleChangeMedium = ({ id: medium }) => {
    const { history, date, search } = urlReader(this.props);
    let { trigger } = this.props;
    const triggerOptions = triggerOptionsByMedium[medium];
    if (!triggerOptions.find(opt => opt.id === trigger)) {
      trigger = triggerOptions[0].id;
    }

    history.push(`?${qs.stringify({ date, medium, search, trigger })}`);
  };

  handleChangeTrigger = ({ id: trigger }) => {
    const { history, date, medium, search } = urlReader(this.props);
    history.push(`?${qs.stringify({ date, medium, search, trigger })}`);
  };

  handleSearch = (search: string) => {
    const { history, date, medium, trigger } = urlReader(this.props);
    history.push(`?${qs.stringify({ date, medium, search, trigger })}`);
  };

  render() {
    const { date, medium, search, timezone, trigger } = urlReader(this.props);
    const started = isStarted(date, timezone);

    const childProps = {
      date,
      medium,
      search,
      trigger,
      handleChangeDate: this.handleChangeDate,
      handleChangeMedium: this.handleChangeMedium,
      handleChangeTrigger: this.handleChangeTrigger,
      handleSearch: this.handleSearch,
    };

    return started ? <Executions {...childProps} /> : <Priorities {...childProps} />;
  }
}

export default compose(
  withAppConfig,
  connect(({ user: { timezone } }) => ({ timezone })),
)(MasterPlan);
