import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

import { Dropzone } from 'app/components';
import { withAppConfig } from 'app/decorators';
import { segmentActions, segmentOperations } from 'app/ducks';
import { Icon, SimpleTable, SimpleTableBody, SimpleTableCell, SimpleTableRow, Spinner as _Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IAppConfig } from 'configs/apps/types';

import { validateFileContents } from './utils';

const DEF_COLUMN_NAMES = ['CustomerId'];
const FILE_READ_OFFSET = 0;
const CHUNK_SIZE = 1024;

type Props = {
  appConfig: IAppConfig;
  className?: string;
  csvName?: string;
  loading?: boolean;
  loadingFailed: (arg0: string) => any; // addCsvFail = error
  startLoading: () => any; // const addCsv = () => ({
  uploadFile: (arg0: File) => any;
};

type State = {
  rejected: Array<{
    file: File;
    errorMessage: string;
  }>;
};

class Upload extends React.PureComponent<Props, State> {
  state = {
    rejected: [],
  };

  getCsvColumnNames = () => {
    const { appConfig } = this.props;
    const { csvColumnNames = DEF_COLUMN_NAMES } = appConfig.audiences || {};
    return csvColumnNames;
  };

  onDrop = (acceptedFiles: Array<File>, rejectedFiles: Array<File>) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      if (acceptedFiles.length > 1) {
        console.error(`Uploaded ${acceptedFiles.length} files, but can only accept one. Will use ${file.name}`); // eslint-disable-line no-console
      }

      const { startLoading } = this.props;
      startLoading();
      const slice = file.slice(FILE_READ_OFFSET, FILE_READ_OFFSET + CHUNK_SIZE);
      const reader = new FileReader();
      reader.onload = ev => this.onSuccess(file, ev.target.result);
      reader.onerror = () => this.onFailure(file, 'Error while reading file');
      reader.readAsText(slice);
    }

    if (rejectedFiles && rejectedFiles.length) {
      rejectedFiles.forEach(file => this.onFailure(file, 'Must be .CSV'));
    }
  };

  onFailure = (file: File, errorMessage: string) => {
    const { loadingFailed } = this.props;
    loadingFailed(errorMessage);

    const { rejected } = this.state;
    this.setState({
      rejected: [...rejected, { file, errorMessage }],
    });
  };

  onSuccess = (file: File, contents: string) => {
    const { isValid, errorMessage } = validateFileContents(contents, this.getCsvColumnNames());
    if (isValid) {
      const fileForm = new FormData();
      fileForm.append('file', file);
      const { uploadFile } = this.props;
      uploadFile(fileForm);
    } else {
      this.onFailure(file, errorMessage);
    }
  };

  render() {
    const { className, csvName, loading } = this.props;

    const { rejected } = this.state;

    if ((this.getCsvColumnNames() || []).length === 0) {
      return (
        <Container className={className}>
          <ErrorContainer>No header row configured. This is an error in the App configuration. Contact support.</ErrorContainer>
        </Container>
      );
    }

    return (
      <Container className={className}>
        <Instructions>
          <p>Upload the IDs that make up this audience. The file must have the format:</p>

          <List>
            <li>
              Header Row (required): Must be one of the following values [<ColumnName>{this.getCsvColumnNames().join(', ')}]</ColumnName>
            </li>

            <li>Remaining Rows: One ID per row</li>
          </List>

          <p>Duplicate rows will not be checked so please make sure there is no duplication before uploading.</p>
        </Instructions>

        <MainSection>
          {csvName ? (
            <>
              <Heading>Uploaded File</Heading>

              <div>{csvName}</div>
            </>
          ) : loading ? (
            <Spinner />
          ) : (
            <Dropzone onDrop={this.onDrop} accept=".csv">
              <Icon name="file-upload" />

              <div>Click or drag and drop a CSV file</div>
            </Dropzone>
          )}
        </MainSection>

        <Results>
          {rejected && rejected.length > 0 && (
            <section>
              <Heading>Rejected Files</Heading>

              <SimpleTable>
                <SimpleTableBody>
                  {rejected.map(({ file, errorMessage }) => (
                    <SimpleTableRow key={file.name}>
                      <SimpleTableCell>{file.name}</SimpleTableCell>
                      <SimpleTableCell>{errorMessage}</SimpleTableCell>
                    </SimpleTableRow>
                  ))}
                </SimpleTableBody>
              </SimpleTable>
            </section>
          )}
        </Results>
      </Container>
    );
  }
}

export default compose(
  withAppConfig,
  connect(
    ({
      segments: {
        // eslint-disable-next-line no-unused-vars
        segment: { csvName, csvUrl, error, loading },
      },
    }) => ({
      // eslint-disable-line no-unused-vars
      csvName: csvName || csvUrl,
      csvUrl,
      // error,
      loading,
    }),

    {
      loadingFailed: segmentActions.addCsvFail,
      startLoading: segmentActions.addCsv,
      uploadFile: segmentOperations.uploadFile,
    },
  ),
)(Upload);

const ColumnName = styled.span`
  font-family: monospace;
  font-weight: bold;
`;

const Container = styled.section`
  margin-top: ${sc.gutter};
  & > section {
    margin-bottom: ${sc.gutter};
  }
`;

const ErrorContainer = styled.section`
  color: ${sc.danger};
  font-style: italic;
`;

const Heading = styled.div`
  font-size: ${sc.fontSizeLarge};
  font-weight: bold;
  margin-bottom: ${sc.gutter};
`;

const Instructions = styled.section`
  & > p,
  & > ol {
    margin-bottom: ${sc.gutter};
  }
`;

const List = styled.ol`
  list-style: inside;
  padding-left: ${sc.gutterSmall};
  & > li {
    margin-bottom: ${sc.gutterSmaller};
  }
`;

const MainSection = styled.section`
  min-height: 10rem;
  margin-bottom: ${sc.gutter};
`;

const Results = styled.section`
  & > ul {
    margin-bottom: ${sc.gutter};
  }
`;

const Spinner = styled(_Spinner)`
  padding-top: 4rem;
`;
