export const styles = theme => ({
  listContainer: {
    maxHeight: '20rem',
    overflow: 'auto',
  },

  selected: {
    backgroundColor: theme.palette.action.selected,
  },
});
