import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import _Input from 'app/midgarComponents/Input';
import Button from 'app/midgarComponents/Button';
import Icon from 'app/midgarComponents/Icon';

const newId = () => Date.now().toString(36);

type Item = { key: string; value: string };
type StateItem = { id: string; key: string; value: string };

type Props = {
  keyValueArray: Array<Item>;
  readOnly?: boolean;
  updateFn: (arg0: Array<Item>) => any;
};

type State = {
  items: Array<StateItem>;
};

export default class KeyValueNoId extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { keyValueArray } = this.props;
    this.state = {
      items: [...(keyValueArray || []).map(kv => ({ ...kv, id: newId() }))],
    };
  }

  handleAdd = () => {
    const { items } = this.state;
    const newItems = [...items, { key: null, value: null, id: newId() }];
    this.handleUpdate(newItems);
  };

  handleDelete = (index: number) => {
    const { items } = this.state;
    const newItems = [...items];
    newItems.splice(index, 1);
    this.handleUpdate(newItems);
  };

  handleInputChange = ({ name, value }, index) => {
    const { items } = this.state;
    items[index][name] = value;
    this.handleUpdate(items);
  };

  handleInputBlur = ({ name, value }, index) => {
    const { items } = this.state;
    items[index][name] = value.trim();
    this.handleUpdate(items);
  };

  handleUpdate = (items: Array<StateItem>) => {
    const { updateFn } = this.props;
    this.setState({ items });
    updateFn(items.map(({ key, value }) => ({ key, value })));
  };

  isValidKey = (key: string | null | undefined, index: string): boolean => {
    const { items } = this.state;
    return !!key && !items.some((item, i) => i !== index && item && item.key === key);
  };

  isValidValue = (value: string | null | undefined): boolean => !!value;

  render() {
    const { readOnly } = this.props;
    const { items } = this.state;

    return (
      <>
        <Button disabled={readOnly} onClick={this.handleAdd} type="button" data-qa="add-custom-meta">
          Add additional Metadata
        </Button>

        <div>
          {items.length > 0 &&
            items.map(({ id, key, value }, index) => (
              <Row key={id}>
                <RemoveButton
                  data-qa="meta-delete"
                  color="primary"
                  disabled={readOnly}
                  name="delete"
                  onClick={() => this.handleDelete(index)}
                  size={25}
                />

                <Input
                  data-qa={`meta-key-${id}`}
                  disabled={readOnly}
                  error={!this.isValidKey(key, index)}
                  name="key"
                  onChange={({ target }) => this.handleInputChange(target, index)}
                  onBlur={({ target }) => this.handleInputBlur(target, index)}
                  placeholder="Key"
                  type="text"
                  value={key || ''}
                />

                <Input
                  data-qa={`meta-value-${id}`}
                  disabled={readOnly}
                  error={!this.isValidValue(value)}
                  name="value"
                  onChange={({ target }) => this.handleInputChange(target, index)}
                  onBlur={({ target }) => this.handleInputBlur(target, index)}
                  placeholder="Value"
                  type="text"
                  value={value || ''}
                />
              </Row>
            ))}
        </div>
      </>
    );
  }
}

const RemoveButton = styled(Icon)`
  margin: 2rem 1rem;
`;

const Input = styled(_Input)`
  height: auto;
  color: ${sc.greyDark};
`;

const Row = styled.div`
  margin: 1rem 0;
  display: flex;

  & > * {
    max-width: 300px;
    margin-right: 1rem;
  }
`;
