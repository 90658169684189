import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { mapStateToStorefronts } from 'app/ducks/storefrontsBms/selectors';
import { storefrontTypes } from 'app/features/BannerManagement/Storefronts/constants';
import { urlView } from 'app/features/BannerManagement/routeUtils';
import { Label as _Label } from 'app/features/Campaigns/Overview/common/ChannelDetailsComponents';
import usePermissions from 'app/hooks/usePermissions';
import { Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { BANNER_MANAGEMENT_READ_ONLY } from 'configs/permissions';

import { ErrorText, IconLink } from '../../common/components';

const DEFAULT_ENTITY_TYPE = storefrontTypes.category;

type Props = {
  bannerId?: number;
  storefrontId?: number;
  viewId?: number;
  highlight?: boolean;
};

export default ({ bannerId, storefrontId, viewId, highlight }: Props) => {
  const isPermitted = usePermissions();
  const { storefronts, storefrontsLoading } = useSelector(mapStateToStorefronts);
  const entityId = useMemo(() => storefronts?.find(s => s.id === storefrontId), [storefrontId, storefronts]);

  return (
    <Row highlight={highlight}>
      <div>
        <Label>Storefront ID : {storefrontId || 'N/A'}</Label>
      </div>

      <div>
        <Label>View ID : {viewId || 'N/A'}</Label>
      </div>

      <div>
        <Label>Banner ID : {bannerId || <ErrorText>Error</ErrorText>}</Label>
      </div>

      {isPermitted([BANNER_MANAGEMENT_READ_ONLY]) && (
        <>
          {storefrontsLoading ? (
            <Spinner size={14} />
          ) : (
            !!storefrontId &&
            !!entityId && (
              <div>
                <a href={urlView(viewId, storefrontId, entityId, DEFAULT_ENTITY_TYPE)} rel="noopener noreferrer" target="_blank">
                  <IconLink name="launch" size={14} />
                </a>
              </div>
            )
          )}
        </>
      )}
    </Row>
  );
};

const Label = styled(_Label)`
  min-width: 9rem;
`;

const Row = styled.section`
  display: flex;
  margin-bottom: ${sc.gutterSmallest};
  & > div {
    margin-right: ${sc.gutterSmall};
  }
  &>div: last-child {
    margin-left: -${sc.gutterSmall};
    margin-right: 0;
  }

  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
`;
