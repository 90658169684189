import { Match, RouterHistory } from 'react-router-dom';
import qs from 'query-string';
import { isEmpty } from 'ramda';

import {
  BANNERS_BINDER,
  BANNERS_CREATIVE_EDIT,
  BANNERS_CREATIVE_NEW,
  BANNERS_STOREFRONTS_EDIT,
  BANNERS_STOREFRONTS_REPORT,
} from 'configs/routes';

// === CREATIVES ===

export const urlCreativeClone = (creativeId: number) => `${urlCreativeNew()}${String(creativeId)}`;
export const urlCreativeEdit = (creativeId: number) => BANNERS_CREATIVE_EDIT.replace(':id', String(creativeId)); // TODO: Handle '?' at end
export const urlCreativeNew = () => BANNERS_CREATIVE_NEW.substring(0, BANNERS_CREATIVE_NEW.indexOf(':id'));

// === STOREFRONTS ===

export const urlStorefronts = () => BANNERS_STOREFRONTS_REPORT;

export const urlStorefrontEdit = (storefrontId?: number, entityId?: number, entityType?: string) => {
  // Regex allows for an optional '?' in the path, which indicates that storefrontId is optional
  const baseUrl = BANNERS_STOREFRONTS_EDIT.replace(
    /:storefrontId\??/,
    storefrontId !== undefined && storefrontId !== null ? String(storefrontId) : '',
  );

  if (entityId || entityType) {
    const query = {
      ...(entityId ? { entityId } : {}),
      ...(entityType ? { entityType } : {}),
    };

    return `${baseUrl}?${qs.stringify(query)}`;
  }

  return baseUrl;
};

export const urlStorefrontNew = () => urlStorefrontEdit();

export const parsePathStorefrontEdit = ({ history, match }: { history: RouterHistory; match: Match }) => {
  const { storefrontId } = match.params;
  const { entityId, entityType } = qs.parse(history.location.search);

  return {
    ...(storefrontId !== undefined && storefrontId !== null ? { storefrontId: Number(storefrontId) } : {}),
    ...(entityId ? { entityId: Number(entityId) } : {}),
    ...(entityType ? { entityType } : {}),
  };
};

// === VIEWS (a.k.a. BINDER) ===

export const urlView = (
  viewId: number | null | undefined,
  storefrontId: number,
  entityId?: number,
  entityType?: string,
  query?: Record<string, string>,
) => {
  const baseUrl = BANNERS_BINDER.replace(':storefrontId', String(storefrontId)).replace(
    /:viewId\??/,
    viewId !== null ? String(viewId) : '',
  );

  if (entityId || entityType || !isEmpty(query)) {
    const fullQuery = {
      ...(query || {}),
      ...(entityId ? { entityId } : {}),
      ...(entityType ? { entityType } : {}),
    };

    return `${baseUrl}?${qs.stringify(fullQuery)}`;
  }

  return baseUrl;
};

export const urlViewNew = (storefrontId: number, entityId?: number, entityType?: string, query?: Record<string, string>) =>
  urlView(null, storefrontId, entityId, entityType, query);

export const parsePathView = ({ history, match }: { history: RouterHistory; match: Match }) => {
  const { storefrontId, viewId } = match.params;
  const { entityId, entityType } = qs.parse(history.location.search);

  return {
    storefrontId: Number(storefrontId),
    viewId: viewId !== null && viewId !== undefined ? Number(viewId) : null,
    ...(entityId ? { entityId: Number(entityId) } : {}),
    ...(entityType ? { entityType } : {}),
  };
};
