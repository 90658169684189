import * as React from 'react';
import PropTypes from 'prop-types';

import { map } from 'ramda';
import { TableHeader } from '../Table/TableHeader';
import { TableHeaderCell } from '../Table/TableHeaderCell';

const renderCell = column => (
  <TableHeaderCell key={column.key}>{column.renderHeader ? column.renderHeader(column) : column.label}</TableHeaderCell>
);

export const Header = ({ columns = [], rowEditable = true }) => {
  const tableHeaderCells = map(renderCell)(columns);
  if (rowEditable) {
    tableHeaderCells.push(<TableHeaderCell />);
  }
  return <TableHeader>{tableHeaderCells}</TableHeader>;
};

Header.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      renderHeader: PropTypes.func,
    }),
  ),

  rowEditable: PropTypes.bool,
};
