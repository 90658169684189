import { IApiViewItemForCreative } from 'app/api/bannerManagementV2/creatives/types';
import { BmsError } from 'app/api/bannerManagementV2/types';
import { Action } from 'app/types';

import * as types from './types';

export const fetchActiveViewItemsError = (error: BmsError): Action => ({
  type: types.FETCH_ACTIVE_VIEW_ITEMS_ERROR,
  payload: { error },
});

export const fetchActiveViewItemsStart = (): Action => ({
  type: types.FETCH_ACTIVE_VIEW_ITEMS_START,
});

export const fetchActiveViewItemsSuccess = (viewItems: Array<IApiViewItemForCreative>): Action => ({
  type: types.FETCH_ACTIVE_VIEW_ITEMS_SUCCESS,
  payload: { viewItems },
});
