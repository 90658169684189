import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField } from '@material-ui/core';
import { Publish as FileUploadIcon } from '@material-ui/icons';
import { connect } from 'react-redux';

import Dropzone from 'app/components/Dropzone';
import { setLocationTrigger } from 'app/ducks/campaigns/campaign/actions';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { triggerTypes, minDefaultRadius } from 'app/utilities/constants';
import { uploadLatLngCsv, generateDownloadableUrl, getCsvSampleUrl } from 'app/api/campaigns/trigger';
import { SectionContainer } from '../SectionContainer';

const notificationMessages = {
  UNSUCCESSFUL_FILE_UPLOAD: 'File Upload was unsuccessful. Please try again.',
  SUCCESSFUL_FILE_UPLOAD: 'File successfuly uploaded. Scheduling may have been changed. Please review it.',
  UNSUCCESSFUL_FILE_DOWNLOAD: 'Failed to generate a downloadable link for csv file uploaded',
};

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.uploadResult = null;
    this.state = {
      defaultRadius: props.defaultRadius || minDefaultRadius,
      downloadableUrl: '',
      loading: false,
    };

    if (props.csvUrl) {
      this.getDownloadableUrl(props.csvUrl);
    }
  }

  getDownloadableUrl = async csvUrl => {
    try {
      const result = await generateDownloadableUrl(csvUrl);
      this.setState({
        downloadableUrl: result,
      });
    } catch (error) {
      displayError(notificationMessages.UNSUCCESSFUL_FILE_DOWNLOAD);
    }
  };

  uploadFile = async acceptedFiles => {
    this.setState({ loading: true });
    try {
      this.uploadResult = await uploadLatLngCsv(acceptedFiles);
      this.getDownloadableUrl(this.uploadResult.url);
      this.saveLocationTrigger();
      displaySuccess(notificationMessages.SUCCESSFUL_FILE_UPLOAD);
      this.setState({ loading: false });
    } catch (error) {
      displayError(JSON.parse(error.message).message);
      this.setState({ loading: false });
    }
  };

  updateDefaultRadius = ({ target: { value } }) => {
    if (value >= minDefaultRadius) {
      this.setState(
        s => ({ ...s, defaultRadius: value }),
        () => this.saveLocationTrigger(),
      );
    }
  };

  saveLocationTrigger = () => {
    const { defaultRadius } = this.state;
    const { csvUrl, dispatchLocationTrigger, triggerType } = this.props;

    dispatchLocationTrigger({
      triggerType: triggerType || triggerTypes.location,
      defaultRadius,
      csvUrl: this.uploadResult ? this.uploadResult.url : csvUrl,
    });
  };

  render() {
    const { defaultRadius, downloadableUrl, loading } = this.state;
    const { csvUrl = null, classes: { locationContainer, inputSection, rightMargin, notification, download, inverted, leftMargin } = {} } =
      this.props;

    return (
      <SectionContainer className={locationContainer} data-qa="geo-location">
        <p>
          <a className={download} href={getCsvSampleUrl()} download="sample.csv">
            Sample CSV
            <FileUploadIcon className={inverted} />
          </a>
        </p>
        <Dropzone
          accept=".csv"
          onDropAccepted={this.uploadFile}
          onDropRejected={() => displayError(notificationMessages.UNSUCCESSFUL_FILE_UPLOAD)}
          loading={loading}
        >
          <FileUploadIcon />
          <Typography>Click or Drag and Drop the CSV file containing latitudes, longitudes, and radius.</Typography>
        </Dropzone>
        {csvUrl ? (
          <div className={inputSection}>
            <div className={notification}>
              <a className={leftMargin} href={downloadableUrl} download>
                Download File
                <FileUploadIcon className={inverted} />
              </a>
            </div>
            <span className={rightMargin}>Default Radius (minimum value {minDefaultRadius}):</span>
            <TextField
              placeholder={`minimum ${minDefaultRadius}`}
              name="radius"
              required
              type="number"
              id="defaultRadius"
              onChange={this.updateDefaultRadius}
              value={defaultRadius}
            />{' '}
            meters
          </div>
        ) : null}
      </SectionContainer>
    );
  }
}

Location.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  dispatchLocationTrigger: PropTypes.func.isRequired,
  csvUrl: PropTypes.string,
  defaultRadius: PropTypes.number,
  triggerType: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  dispatchLocationTrigger: params => dispatch(setLocationTrigger(params)),
});

export default connect(null, mapDispatchToProps)(Location);
