import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { creativeActions } from 'app/ducks';
import { APP_CLIENT_NAMES, APP_SITES } from 'app/features/BannerManagement/common/appVersions-india.conf';
import { Dropdown, Icon as _Icon, TextField } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Action } from 'app/types';

import { isNumeric } from './helpers';

const clients = APP_CLIENT_NAMES.map(client => ({ id: client.toLowerCase(), label: client }));

type Props = {
  addAppVersionGroup: (index: number, newIndex: number) => Action;
  appVersionKey: string;
  client: string;
  deleteAppVersionGroup: (variantIndex: number, versionIndex: number) => Action;
  isLast: boolean;
  operator: string;
  readOnly: boolean;
  siteId: string;
  updateVariantsAppVersionsField: (index: number, versionIndex: number, field: string, value: any) => Action;
  variantKey: string;
  version: string;
};

class AppVersionGroup extends React.PureComponent<Props> {
  onVersionChange = (ev: Event) => {
    const { appVersionKey, updateVariantsAppVersionsField, variantKey } = this.props;

    const version = ev.target.value;
    const last = version.slice(-1);

    if (last === '' || last === '.' || isNumeric(last)) {
      updateVariantsAppVersionsField(variantKey, appVersionKey, 'version', version);
    }
  };

  render() {
    const {
      addAppVersionGroup,
      appVersionKey,
      client,
      deleteAppVersionGroup,
      isLast,
      operator,
      readOnly,
      siteId,
      updateVariantsAppVersionsField,
      variantKey,
      version,
    } = this.props;

    return (
      <Container>
        <InlineField>
          <Dropdown
            label="Site"
            value={APP_SITES.find(x => x.id === siteId)}
            options={APP_SITES}
            onChange={option =>
              updateVariantsAppVersionsField(Number(variantKey), Number(appVersionKey), 'siteId', typeof option === 'object' && option.id)
            }
            disabled={readOnly}
          />
        </InlineField>

        <InlineField>
          <Dropdown
            label="Client"
            value={clients.find(x => x.id === client)}
            options={clients}
            onChange={option =>
              updateVariantsAppVersionsField(Number(variantKey), Number(appVersionKey), 'client', typeof option === 'object' && option.id)
            }
            disabled={readOnly}
          />
        </InlineField>

        <OperatorField>
          <Dropdown
            label="Operator"
            value={operator}
            options={['=', '>=', '<=']}
            onChange={option => updateVariantsAppVersionsField(Number(variantKey), Number(appVersionKey), 'operator', option)}
            disabled={readOnly}
          />
        </OperatorField>

        <InlineField>
          <TextField
            type="text"
            id="version"
            name="version"
            label="Version"
            value={version || ''}
            onChange={this.onVersionChange}
            disabled={readOnly}
          />
        </InlineField>

        {isLast ? (
          <Icon
            size={25}
            name="add"
            onClick={() => !readOnly && addAppVersionGroup(Number(variantKey), Number(appVersionKey) + 1)}
            disabled={readOnly}
          />
        ) : (
          <Icon
            size={20}
            name="remove"
            onClick={() => !readOnly && deleteAppVersionGroup(Number(variantKey), Number(appVersionKey))}
            disabled={readOnly}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (
  {
    bannerManagement: {
      creative: { form },
    },
  },

  { appVersionKey, variantKey },
) => ({
  siteId: form.variants[variantKey].appVersions[appVersionKey].siteId,
  client: form.variants[variantKey].appVersions[appVersionKey].client,
  operator: form.variants[variantKey].appVersions[appVersionKey].operator,
  version: form.variants[variantKey].appVersions[appVersionKey].version,
});

const mapDispatchToProps = {
  addAppVersionGroup: creativeActions.addAppVersionGroup,
  deleteAppVersionGroup: creativeActions.deleteAppVersionGroup,
  updateVariantsAppVersionsField: creativeActions.updateVariantsAppVersionsField,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppVersionGroup);

const Container = styled.div`
  position: relative;
  margin-bottom: ${sc.gutter};

  &:last-child {
    margin-bottom: 0;
  }
`;

const InlineField = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 25%;
  margin-right: 2%;

  &:last-child {
    margin-right: 0;
  }
`;

const OperatorField = styled(InlineField)`
  width: 14%;
`;

const Icon = styled(_Icon)`
  position: absolute;
  top: 50%;
  right: 0;

  color: ${sc.subHeadingColor};
  padding: ${sc.gutterSmallest};
  transform: translate(0, -50%);

  &:hover {
    color: ${props => !props.disabled && sc.primary};
    cursor: pointer;
  }
`;
