import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Dropdown from 'app/midgarComponents/Dropdown';
import { addVariable, removeVariable } from 'app/ducks/campaigns/campaign/operations';

import { useInitBasedOnEvent } from 'app/features/Personalization/useInitBasedOnEvent';
import { useInitBatchCampaign } from 'app/features/Personalization/useInitBatchCampaign';

type FeatureEventType = {
  name: string;
  feature_id: string;
  feature_name: string;
  source: string;
  id: string;
  [key: string]: string;
};

type CurrTemplateType = { id: string; defaults: { event: Array<FeatureEventType>; features: Array<FeatureEventType> } };

type Props = {
  disabled: boolean;
  onChange: (ev: { target: { value: string; name: string } }) => void;
  name: string;
  currTemplate: CurrTemplateType;
  setCurrTemplate: (temp: CurrTemplateType) => void;
};

type RootState = {
  features: {
    data: {
      allNames: Array<FeatureEventType>;
    };
    realTimeEvents: Array<FeatureEventType>;
  };
};

export const TemplateDropdown = (props: Props): React.ReactElement => {
  const { disabled, onChange, name, currTemplate, setCurrTemplate } = props;

  const [templates, setTemplates] = React.useState([]);

  useInitBasedOnEvent(setTemplates);
  useInitBatchCampaign(setTemplates);

  const { realTimeEvents, allNames = [] } = useSelector(
    ({
      features: {
        data: { allNames },
        realTimeEvents,
      },
    }: RootState) => ({
      realTimeEvents,
      allNames,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();

  // remove all variables related to current template
  const removeAllVariables = React.useCallback(() => {
    const {
      defaults: { event: currEvents, features: currFeatures },
    } = currTemplate;

    [...currEvents, ...currFeatures].forEach(({ source, id: currId, name }) => {
      dispatch(removeVariable({ id: source === 'event' ? name : Number(`-${currId}`), source }));
    });
  }, [currTemplate, dispatch]);

  const addVariableForFeature = React.useCallback(
    async (events: Array<FeatureEventType>, features: Array<FeatureEventType>) => {
      const findRealTimeFeatures = events.map(selectedEventName => {
        const eventName = Object.keys(selectedEventName)[0];
        const selectedEvent = realTimeEvents.find(ev => ev.name === eventName);
        return { ...selectedEvent, defaultValue: selectedEventName[eventName] };
      });

      const findFeatures = features.map(selectedEventName => {
        const eventName = Object.keys(selectedEventName)[0];

        const currEvent = allNames.find(event => event.name === eventName);
        return { ...currEvent, defaultValue: selectedEventName[eventName] };
      });

      [...findRealTimeFeatures, ...findFeatures].forEach(event => {
        dispatch(addVariable(event));
      });
      return { findRealTimeFeatures, findFeatures };
    },
    [allNames, dispatch, realTimeEvents],
  );

  const onChangeTemplate = React.useCallback(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    async (ev: any) => {
      removeAllVariables();

      const {
        template = '',
        defaults: { event, features },
      } = ev || {};

      const { findRealTimeFeatures, findFeatures } = await addVariableForFeature(event, features);
      setCurrTemplate({ ...ev, defaults: { event: findRealTimeFeatures, features: findFeatures } });
      onChange({ target: { value: template, name } });
    },
    [addVariableForFeature, name, onChange, removeAllVariables, setCurrTemplate],
  );

  return (
    <Dropdown
      disabled={disabled}
      id="templateSelection"
      options={templates}
      value={templates.find((template: { id: string }) => template.id === currTemplate?.id)}
      label="Select a template"
      onChange={onChangeTemplate}
    ></Dropdown>
  );
};
