import { IUserBudgetToken } from 'app/features/CashbackPromoManagement';

import * as types from './types';

export const add = (userBudgetToken: IUserBudgetToken) => ({
  type: types.ADD,
  payload: { userBudgetToken },
});

export const getError = (error: Error) => ({
  type: types.GET_ERROR,
  payload: error,
});

export const getInit = () => ({
  type: types.GET_INIT,
});

export const getSuccess = (userBudgetTokens: Array<IUserBudgetToken>) => ({
  type: types.GET_SUCCESS,
  payload: { userBudgetTokens },
});

export const update = (userBudgetToken: IUserBudgetToken) => ({
  type: types.UPDATE,
  payload: { userBudgetToken },
});
