export const pushTypes = {
  push: {
    id: 'push',
    name: 'Push',
  },

  inbox: {
    id: 'inbox',
    name: 'Inbox',
  },

  bigPicture: {
    id: 'big_picture',
    name: 'Big Picture',
  },
};

export const deviceTypeValues = {
  android: {
    label: 'Android',
    value: 'android',
    selected: false,
  },

  ios: {
    label: 'iOS',
    value: 'ios',
    selected: false,
  },
};

export const InteractiveTypes = ['webview', 'share', 'dismiss', 'deep_link', 'noop'];

export const expiryTypes = {
  date: 'date',
  datetime: 'datetime',
  relative: 'relative',
};

export const PUSH2_SITE_ID_PARAM_MEDIUM_NAME = 'push2';
