import { compose, withHandlers, withState } from 'recompose';

import { creatorMy, creatorAll } from 'app/midgarComponents/OptionsSwitch/CreatedBySwitch';

type Props = {
  userEmail: string;
  searchPhrase: string;
  selectedSearchType: string;
  value: creatorAll | creatorMy;
  pushSearchBar: (arg0: { searchPhrase: string; selectedSearchType: string }) => void;
};

export default (searchCreatedByName: string, searchAllName?: string) =>
  compose(
    withState('value', 'setValue', ({ value = creatorAll }) => value),
    withHandlers({
      handleSelectAll:
        ({ pushSearchBar, setValue, userEmail }: Props) =>
        () => {
          const newSearchPhraseAndType = { searchPhrase: '', selectedSearchType: searchCreatedByName };
          setValue(creatorAll);
          pushSearchBar(newSearchPhraseAndType);
        },
      handleSelectMy:
        ({ pushSearchBar, setValue, userEmail }: Props) =>
        () => {
          const newSearchPhraseAndType = { searchPhrase: userEmail, selectedSearchType: searchCreatedByName };
          setValue(creatorMy);
          pushSearchBar(newSearchPhraseAndType);
        },
    }),
  );
