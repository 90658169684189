import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';

const color = {
  bodyText: '#29394f',
  border: '#e6ecf0',
  headerBackground: 'hsla(218, 30%, 93%, .4)',
  headerText: '#536d90',
  hoverShadow: 'rgba(0,0,0,0.1)',
  hoverBackground: sc.greyLightest,
};

const ResponsiveTableContainer = ({ children, className, ...rest }: { children: React.ReactNode; className?: string }) => (
  <div className={className} style={{ overflowX: 'auto' }} {...rest}>
    {children}
  </div>
);

const TableHeader = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <StyledHeader className={className}>
    <tr>{children}</tr>
  </StyledHeader>
);

const StyledHeader = styled.thead`
  background-color: ${color.headerBackground};
  border-bottom: ${color.border};
`;

const Table = styled.table``;

const TableBody = styled.tbody``;

const TableCell = styled.td`
  color: ${color.bodyText};
  padding: ${sc.gutterSmallest};
`;

const TableHeaderCell = styled.th`
  color: ${color.headerText};
  font-weight: 600;
  padding: ${sc.gutterSmallest};
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: ${sc.sectionBackgroundColor};
  }
`;

export { ResponsiveTableContainer, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow };
