import * as React from 'react';

import { segmentTypeFlags } from 'app/features/Segments/utils';

import CsvUpload from './CsvUpload';
import Rules from './Rules';

type Props = {
  type: string;
  classes: {
    header: string;
  };

  calculate: () => boolean;
  id: number;
  state: string;
  downloadUrls: Array<{}>;
  toTableData: () => () => void;
  featureMetadata: {};
  includedRules: Array<{}>;
  includedSeedSegments: Array<{}>;
  excludedSeedSegments: Array<{}>;
  includedUniverseSegments: Array<{}>;
  excludedUniverseSegments: Array<{}>;
  maxAudienceSize: number;
};

export const Switcher = ({ type, classes, calculate, id, state, downloadUrls, toTableData, featureMetadata, includedRules }: Props) => {
  switch (type) {
    case segmentTypeFlags.CSV_BASED:
      return <CsvUpload {...{ type, classes, calculate, id, state, downloadUrls }} />;
    default:
      return <Rules {...{ classes, calculate, toTableData, featureMetadata, includedRules }} />;
  }
};
