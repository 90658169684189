const GET_ALL_CAMPAIGNS = 'map/campaigns/GET_ALL';
const GET_ALL_CAMPAIGNS_SUCCESS = 'map/campaigns/GET_ALL_SUCCESS';
const GET_ALL_CAMPAIGNS_FAIL = 'map/campaigns/GET_ALL_FAIL';
const SELECT_ROW = 'map/campaigns/SELECT_ROW';
const SET_STATUS = 'map/campaigns/SET_STATUS';
const UPDATE_BANNER = 'map/campaigns/UPDATE_BANNER';
const UPDATE_SCHEDULING = 'map/campaigns/UPDATE_SCHEDULING';
const LOAD_MORE_CAMPAIGNS = 'map/campaigns/LOAD_MORE';
const LOAD_MORE_CAMPAIGNS_SUCCESS = 'map/campaigns/LOAD_MORE_SUCCESS';
const LOAD_MORE_CAMPAIGNS_FAIL = 'map/campaigns/LOAD_MORE_FAIL';

export {
  GET_ALL_CAMPAIGNS,
  GET_ALL_CAMPAIGNS_SUCCESS,
  GET_ALL_CAMPAIGNS_FAIL,
  SELECT_ROW,
  SET_STATUS,
  UPDATE_BANNER,
  UPDATE_SCHEDULING,
  LOAD_MORE_CAMPAIGNS,
  LOAD_MORE_CAMPAIGNS_SUCCESS,
  LOAD_MORE_CAMPAIGNS_FAIL,
};
