import * as React from 'react';

import { IFeature } from 'app/api/features/types';
import DrawerError from 'app/components/DrawerError/index';
import { IPaging } from 'app/hocs/withReporting';
import { Page, PageHeader, PageMain, Paging, SearchBar, Spinner } from 'app/midgarComponents';
import { Footer, SearchContainer } from 'app/midgarComponents/ReportingPage';
import { IQueryParams } from 'app/types';

import FeaturesTable from './FeaturesTable';
import { searchBarDefs } from './search.config';

type Props = {
  error: Error;
  features: Array<IFeature>;
  goToPage: (arg0: number) => unknown;
  handleSearchPhraseChange: (arg0: string) => void;
  handleSearchTypeChange: (arg0: string) => void;
  loading: boolean;
  msgIfEmpty?: string;
  paging: IPaging;
  pushSearch: (arg0: IQueryParams) => IQueryParams;
  pushSearchBar: (arg0: { searchPhrase: string; selectedSearchType: string }) => void;
  search: IQueryParams;
  searchPhrase: string;
  selectedSearchType: string;
  setSearchState: (arg0: IQueryParams) => void;
};

export class Reporting extends React.PureComponent<Props> {
  handleChangeTextSearch = paramName => ev => {
    const { search, setSearchState } = this.props;
    setSearchState({ ...search, [paramName]: ev.target.value });
  };

  handleKeyDownTextSearch = ev => {
    if (ev.key === 'Enter') {
      const { pushSearch, search } = this.props;
      pushSearch(search);
    }
  };

  render() {
    const {
      error,
      features,
      goToPage,
      handleSearchPhraseChange,
      handleSearchTypeChange,
      loading,
      msgIfEmpty,
      paging,
      pushSearchBar,
      searchPhrase,
      selectedSearchType,
    } = this.props;

    return (
      <Page>
        <PageHeader title="Features" />

        <PageMain>
          <SearchContainer>
            <SearchBar
              searchPhrase={searchPhrase}
              selectedSearchType={selectedSearchType}
              searchTypes={searchBarDefs}
              handleSearchPhraseChange={handleSearchPhraseChange}
              handleSearchTypeChange={handleSearchTypeChange}
              pushSearchBar={pushSearchBar}
            />
          </SearchContainer>

          {error && (
            <DrawerError error="Error while retrieving features" errorDetails={error.message} allowDismiss={false} defaultExpanded />
          )}

          {loading && <Spinner />}

          {!loading && (
            <>
              <FeaturesTable features={features} msgIfEmpty={msgIfEmpty} />

              <Footer>
                <Paging {...paging} handleGoToPage={goToPage} />
              </Footer>
            </>
          )}
        </PageMain>
      </Page>
    );
  }
}
