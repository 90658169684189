export const styles = () => ({
  modalStyles: {
    background: 'white',
    width: '500px',
    height: 'fit-content',
    padding: '2rem',
    margin: '0 auto',
    marginTop: '20vh',
    alignItems: 'center',
  },
});
