import styled from 'styled-components';
import { sc } from 'app/styles';

import { Button } from 'app/midgarComponents';

const StyledHeader = styled.header`
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  background: hsl(0, 100%, 100%);
  border-bottom: 1px solid #dbe2e9;
`;

const Title = styled.h2`
  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
  height: 48px;
  font-size: 1.75em;
  font-weight: bold;
  color: rgba(41, 57, 79, 0.9);
  margin-top: 0.9375rem;
`;

const Link = styled.span`
  color: #00b9f5;
  padding: 0.5rem 0;
  font-size: 0.75em;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;

const Arrow = styled.section`
  display: inline-block;
  position: relative;
  top: 0.1rem;
  height: 0;
  width: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid #00b9f5;
  margin: 0 0.3125rem;
  &:hover {
    cursor: pointer;
  }
`;

const ActionButton = styled(Button)`
  margin-left: 1.25rem;
`;

export { StyledHeader, Title, Link, Arrow, ActionButton };
