export default {
  // colors
  primary: '#00B9F5',
  secondary: '#002E6E',
  tertiary: '#D086DC',

  success: '#00C673',
  warning: '#FF9E00',
  danger: '#FF4B55',

  bodyColor: '#182233',
  headingColor: '#536E92',
  subHeadingColor: '#86A0C2',
  lightGreen: 'rgba(187, 247, 163, .6)',

  // fonts
  fontSizeSmallest: '10px',
  fontSizeSmaller: '12px',
  fontSizeSmall: '14px',
  fontSize: '16px',
  fontSizeLarge: '20px',
  fontSizeLarger: '24px',
  fontSizeLargest: '28px',

  fontFamily: '"Nunito Sans", sans-serif',

  // shades of grey
  greyLightest: '#fafafa',
  greyLighter: '#eeeeee',
  greyLight: '#bdbdbd',
  grey: '#757575',
  greyDark: '#424242',
  greyDarker: '#292929',
  greyDarkest: '#1c1c1c',

  // gutter
  gutterSmallest: '5px',
  gutterSmaller: '10px',
  gutterSmall: '15px',
  gutter: '20px',
  gutterLarge: '25px',
  gutterLarger: '30px',
  gutterLargest: '35px',

  // overlays
  overlayLighter: 'rgba(0,0,0,0.15)',
  overlayLight: 'rgba(0,0,0,0.3)',
  overlay: 'rgba(0,0,0,0.45)',
  overlayDark: 'rgba(0,0,0,0.6)',
  overlayDarker: 'rgba(0,0,0,0.75)',

  // shadows
  shadowLightest: 'rgba(100,120,120,0.05)',
  shadowLighter: 'rgba(100,120,120,0.1)',
  shadowLight: 'rgba(100,120,120,0.15)',
  shadow: 'rgba(100,120,120,0.2)',
  shadowDark: 'rgba(100,120,120,0.3)',
  shadowDarker: 'rgba(100,120,120,0.4)',
  shadowDarkest: 'rgba(100,120,120,0.5)',

  // sections
  sectionWhiteColor: '#ffffff',
  sectionBorderColor: '#E6EBF3',
  sectionBackgroundColor: '#F6F8FC',

  // tooltips
  tooltipBackgroundColor: 'rgba(51,122,183,0.8)',
};
