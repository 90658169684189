import { State } from './reducers';

type Store = { bannerManagement: { tags: State } };

export const mapStateToTags = ({
  bannerManagement: {
    tags: { error: tagsError, loading: tagsLoading, tags },
  },
}: Store) => ({
  tags,
  tagsError,
  tagsLoading,
});
