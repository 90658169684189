import PropTypes from 'prop-types';
import { lifecycle, compose } from 'recompose';
import { connect } from 'react-redux';

import * as operations from './operations';
import * as selectors from './selectors';

const mapStateToProps = state => ({
  tags: selectors.tags(state),
  tagsLoading: selectors.loading(state),
  tagsError: selectors.error(state),
});

const connectToRedux = connect(mapStateToProps, operations);

const fetchTags = lifecycle({
  componentDidMount() {
    this.props.getAllTags();
  },
});

const ConnectedTags = compose(connectToRedux, fetchTags);

const TagsContainer = ({ children, ...props }) => <>{children && children(props)}</>;

TagsContainer.propTypes = {
  children: PropTypes.func,
};

const ConnectedTagsContainer = ConnectedTags(TagsContainer);

export { ConnectedTags, ConnectedTagsContainer };
