import React from 'react';
import styled from 'styled-components';
import { inject } from 'app/decorators';
import { Button as _Button } from 'app/midgarComponents';
import * as SegmentsOperations from 'app/ducks/segments/segments/operations';
import { download, getDownloadLabel } from './helpers';
import { compose } from 'recompose';

const INTERVAL_TIMEOUT = 30000;

type Props = {
  id: number;
  state: string;
  downloadUrls: Array<string>;
  SegmentsOperations: typeof SegmentsOperations;
};

class Download extends React.Component<Props> {
  pollInterval = null;

  static defaultProps = {
    SegmentsOperations: {},
  };

  componentDidMount() {
    const {
      id,
      state,
      SegmentsOperations: { getDownloadStatus },
    } = this.props;

    if (state === 'PENDING') {
      this.pollInterval = setInterval(() => getDownloadStatus(id), INTERVAL_TIMEOUT);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      id,
      state,
      SegmentsOperations: { getDownloadStatus },
    } = this.props;

    if (prevProps.state !== state && state === 'ERROR') {
      this.removePoll();
    }

    if (state !== prevProps.state && state === 'PENDING') {
      this.removePoll();
      this.pollInterval = setInterval(() => getDownloadStatus(id), INTERVAL_TIMEOUT);
    }
  }

  componentWillUnmount() {
    this.removePoll();
  }

  removePoll = () => {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  };

  onClick = (ev: Event) => {
    const {
      id,
      state,
      downloadUrls,
      SegmentsOperations: { getDownloadStatus },
    } = this.props;

    ev.preventDefault();
    ev.stopPropagation();

    if (state === 'COMPLETED' && downloadUrls) {
      return download(downloadUrls);
    }

    return getDownloadStatus(id);
  };

  render() {
    const { state } = this.props;
    const isDisabled = state === 'LOADING';

    return (
      <Button type="secondary" disabled={isDisabled} onClick={this.onClick}>
        {getDownloadLabel(state)}
      </Button>
    );
  }
}

export default compose(inject({ SegmentsOperations }))(Download);

const Button = styled(_Button)`
  border: none;
  box-shadow: none;
  width: 200px;
  height: 0;
  line-height: 0;
  padding: 0.5rem 0;
  text-align: left;
  &:hover,
  &:active,
  &:focus {
    background-color: unset;
    color: #00b9f5;
    border: none;
    text-decoration: underline;
  }
`;
