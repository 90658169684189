export default {
  sectionHeading: `
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.16667em;
    color: rgba(0, 0, 0, 0.87);
  `,

  sectionSubHeading: `
    font-size: 0.75rem;
    line-height: 1.375em;
    color: rgba(0, 0, 0, 0.54);
  `,

  transparentGradient: (color: string, size: number) => `
    background-size: ${size}px ${size}px;
    background-position: 0 0, 0 ${size / 2}px, ${size / 2}px -${size / 2}px, -${size / 2}px 0px;
    background-image: linear-gradient(45deg, ${color} 25%, transparent 25%),
      linear-gradient(-45deg, ${color} 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, ${color} 75%),
      linear-gradient(-45deg, transparent 75%, ${color} 75%);
  `,
};
