import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { sc } from 'app/styles';

export default class Button extends React.PureComponent<{
  to?: string;
  target?: string;
  children: string;
}> {
  render() {
    const { children, to, target, ...otherProps } = this.props;
    if (to) {
      return (
        <Link to={to} target={target}>
          <BaseButton {...otherProps}>{children}</BaseButton>
        </Link>
      );
    }
    return <BaseButton {...this.props}>{children}</BaseButton>;
  }
}

const buttonStyles = {
  height: {
    small: '32px',
    medium: '40px',
    large: '56px',
  },

  color: {
    primary: sc.sectionWhiteColor,
    secondary: sc.primary,
    disabled: sc.subHeadingColor,
    link: sc.primary,
    hover: {
      primary: sc.sectionWhiteColor,
      secondary: sc.sectionWhiteColor,
      link: sc.secondary,
    },
  },

  backgroundColor: {
    primary: sc.primary,
    secondary: sc.sectionWhiteColor,
    disabled: sc.sectionBackgroundColor,
    link: 'transparent',
    hover: {
      primary: 'hsl(221,100%,28.4%)',
      secondary: sc.primary,
      link: 'transparent',
    },
  },

  border: {
    primary: sc.primary,
    secondary: sc.sectionBorderColor,
    disabled: sc.sectionBorderColor,
    link: 'transparent',
    hover: {
      primary: 'hsl(221,100%,28.4%)',
      secondary: sc.primary,
      link: 'transparent',
    },
  },
};

const BaseButton = styled.button.attrs(({ disabled, type = 'primary' }) => ({
  type: disabled ? 'disabled' : type,
}))`
  height: ${({ size = 'medium' }) => (size ? buttonStyles.height[size] : size)};
  font-size: 14px;
  line-height: 1.43rem;
  font-weight: bold;
  padding: 0 20px;
  cursor: ${({ type }) => (type === 'disabled' ? 'not-allowed' : 'pointer')} ;
  color: ${({ type }) => buttonStyles.color[type]};
  border: ${({ type }) => `1px solid ${buttonStyles.border[type]}`};
  background-color: ${({ type }) => buttonStyles.backgroundColor[type]};
  border-radius: 2px;
  // border-bottom: ${({ type }) => (type === 'link' ? `1px solid ${sc.primary}` : null)};
  &:hover,
  &:active,
  &:focus {
    color: ${({ type }) => buttonStyles.color.hover[type]};
    border: ${({ type }) => `1px solid ${buttonStyles.border.hover[type]}`};
    background-color: ${({ type }) => buttonStyles.backgroundColor.hover[type]};
  }
  }
`;
