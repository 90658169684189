import { ICategory } from 'app/types';

import * as types from './types';

export const getEngageCategoriesFailed = (error: string) => ({
  type: types.GET_ENGAGE_CATEGORIES_FAILED,
  payload: { error },
});

export const getEngageCategoriesStart = () => ({
  type: types.GET_ENGAGE_CATEGORIES_START,
});

export const getEngageCategoriesSuccess = (engageCategories: Array<ICategory>) => ({
  type: types.GET_ENGAGE_CATEGORIES_SUCCESS,
  payload: { engageCategories },
});
