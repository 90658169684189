import { compose, setPropTypes, withHandlers, withProps } from 'recompose';
import PropTypes from 'prop-types';
import { sort } from 'app/utilities/idAndName';
import { withStyles } from '@material-ui/core/styles';
import { ListSelectable } from './ListSelectable';

import { styles } from './styles';

const addHandlers = withHandlers({
  handleClick:
    ({ handleClick }) =>
    id =>
    () => {
      if (handleClick) {
        handleClick(id);
      }
    },
});

const doSort = withProps(({ items, sorted }) => ({
  items: items && sorted !== false ? sort(items) : items,
}));

const propTypes = setPropTypes({
  handleClick: PropTypes.func,
});

const enhance = compose(addHandlers, doSort, propTypes, withStyles(styles, { withTheme: true }));

export default enhance(ListSelectable);
