const buildTagsLookups = tags => {
  const tagsLookups = {
    allIds: [],
    allNames: [],
    idByName: {},
    byId: {},
  };

  tags.forEach(tag => {
    const { id: tagId, name: tagName } = tag;
    const tagNameInLowerCase = tagName.toLowerCase();

    tagsLookups.allIds.push(tagId);
    tagsLookups.allNames.push(tagNameInLowerCase);
    tagsLookups.idByName[tagNameInLowerCase] = tagId;
    tagsLookups.byId[tagId] = tag;
  });

  return tagsLookups;
};

const buildV2TagsLookups = tags => {
  const audienceTagsLookups = {
    allNamesV2: [],
  };

  tags.forEach(tag => {
    audienceTagsLookups.allNamesV2.push(tag);
  });
  return audienceTagsLookups;
};

export { buildTagsLookups, buildV2TagsLookups };
