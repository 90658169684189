import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { useAppConfig } from 'app/hooks/useAppConfig';
import { mapIds } from 'app/ducks/helpers';
import { segmentTypeFlags } from 'app/features/Segments2/utils';
import { getMaxAudienceLimit } from 'app/ducks/campaigns/campaign/operations';
import * as api from 'app/api/segments2';
import { Segment, Segments } from '../../../../../../types/typescript/Segments';
import { QueryParams } from '../../../../../../types/typescript/QueryParams';
import { SectionContainer } from '../SectionContainer';
import AudienceVersions from './AudienceVersions';

export const PAGE_SIZE = 25;

const getSegmentsPaged = async (params: QueryParams): Promise<Segments> => {
  try {
    const res = await api.getSegmentsPaged(params);
    return res.content;
  } catch (err) {
    // TODO: Display error in UI
    return [];
  }
};

export const fetchSegments = (
  value: string,
  getSegmentsPaged: (params: QueryParams) => Promise<Segments>,
  pageSize: number,
): Promise<Segments> => {
  const trimmedValue = typeof value === 'string' ? value.trim() : value;
  const nameParams = { searchBy: 'name', searchTerm: trimmedValue, page: 0, size: pageSize };
  const idParams: QueryParams | undefined = trimmedValue.length && !Number.isNaN(Number(trimmedValue)) ? { id: trimmedValue } : undefined;
  const queries: Array<QueryParams | undefined> = [nameParams, idParams];

  const mergeResultSets = (resultSets: Array<Segments>): Segments => {
    return ([] as Segment[]).concat(...resultSets).filter(r => !!r);
  };

  function notUndefined<QueryParams>(value: QueryParams | undefined): value is QueryParams {
    return !!value;
  }

  const segmentQueriesFiltered: Array<QueryParams> = queries.filter(notUndefined);
  const segmentCalls = segmentQueriesFiltered.map(q => {
    return getSegmentsPaged(q);
  });

  return Promise.all(segmentCalls).then(resultSets => {
    return mergeResultSets(resultSets);
  });
};

type CampaignState = {
  campaigns: {
    campaign: {
      general: {
        customerCountPerExecution: number;
        submitAllForms: boolean;
        exclusionCampaign: boolean;
        includedSegmentsFilters: Segments;
        excludedSegmentsFilters: Segments;
        audienceFiltersEnabled: boolean;
        includedSegmentsGeoFilters: Segments;
        maxAudienceLimit: string | null;
      };
    };
  };
  audience: {
    audience: {
      includedSegments: Segments;
      excludedSegments: Segments;
    };
  };
};

const Target = (): JSX.Element => {
  const dispatch = useDispatch();
  const appConfig = useAppConfig();
  const [isMounted, setIsMounted] = React.useState(false);
  const [segments, setSegments] = React.useState<Segments>([]);
  const [segmentsFetching, setSegmentsFetching] = React.useState(false);

  const {
    general: {
      includedSegmentsFilters,
      excludedSegmentsFilters,
      audienceFiltersEnabled,
      includedSegmentsGeoFilters,
      exclusionCampaign,
      customerCountPerExecution,
      submitAllForms,
      maxAudienceLimit,
    },
    audience: { includedSegments = [], excludedSegments = [] },
  } = useSelector(
    ({
      campaigns: {
        campaign: { general },
      },
      audience: { audience },
    }: CampaignState) => ({ general, audience }),
  );

  const getSegments = debounce(async (value: string) => {
    if (isMounted) {
      setSegmentsFetching(true);
    }

    let segments: Array<Segment> = [];
    try {
      segments = await fetchSegments(value, getSegmentsPaged, PAGE_SIZE);
      if (isMounted) {
        setSegments(segments);
        setSegmentsFetching(false);
      }
    } catch (_) {
      if (isMounted) {
        setSegments(segments);
      }
    }
  }, 300);

  React.useEffect(() => {
    setIsMounted(true);
    getSegments('');
    return (): void => {
      setIsMounted(false);
    };
  }, [isMounted]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (appConfig.enableMaxAudienceLimit && !maxAudienceLimit) {
      dispatch(getMaxAudienceLimit());
    }
  }, [appConfig.enableMaxAudienceLimit, dispatch, maxAudienceLimit]);

  const getEligibleSegments = (
    segments: Segments,
    ineligibleItems: Segments = [],
    allowBoostSegments: boolean,
    audienceFiltersEnabled: boolean,
  ): Segments => {
    if (audienceFiltersEnabled) {
      if (allowBoostSegments) {
        return segments.filter(x => x.type !== segmentTypeFlags.CSV_BASED && mapIds(ineligibleItems).indexOf(x.id) === -1);
      }
      return segments.filter(x => x.type === segmentTypeFlags.RULE_BASED && mapIds(ineligibleItems).indexOf(x.id) === -1);
    }

    return segments;
  };

  return (
    <SectionContainer data-qa="audiences-section">
      <AudienceVersions
        includedSegments={includedSegments}
        excludedSegments={excludedSegments}
        includedSegmentsFilters={includedSegmentsFilters}
        excludedSegmentsFilters={excludedSegmentsFilters}
        includedSegmentsGeoFilters={includedSegmentsGeoFilters}
        customerCountPerExecution={customerCountPerExecution}
        audienceFiltersEnabled={audienceFiltersEnabled}
        exclusionCampaign={exclusionCampaign}
        appConfig={appConfig}
        segments={segments}
        // eslint-disable-next-line
        // @ts-ignore
        getSegments={getSegments}
        segmentsFetching={segmentsFetching}
        maxAudienceLimit={maxAudienceLimit}
        submitAllForms={submitAllForms}
        getEligibleSegments={getEligibleSegments}
      />
    </SectionContainer>
  );
};

export default Target;
