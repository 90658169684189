import { connect } from 'react-redux';
import { cashbackActions } from 'app/ducks/campaigns/campaign/cashbackCreative';
import { getRegularCashbackCreative } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { Fulfillment } from '../../../Creation/Fulfillment';

export const RegularFulfillmentContainer = connect(
  state => {
    const cashbackCreative = getRegularCashbackCreative(state);
    const { fulfillment } = cashbackCreative;
    return fulfillment;
  },
  {
    updateFulfillment: cashbackActions.updateCashbackFulfillment,
    updateFulfillmentType: cashbackActions.updateCashbackFulfillmentType,
  },
)(Fulfillment);
