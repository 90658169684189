const GET_ALL_SEGMENTS = 'map/segments/GET_ALL';
const GET_ALL_SEGMENTS_SUCCESS = 'map/segments/GET_ALL_SUCCESS';
const GET_ALL_SEGMENTS_FAIL = 'map/segments/GET_ALL_FAIL';
const GET_DOWNLOAD_STATUS = 'map/segments/GET_DOWNLOAD_STATUS';
const GET_DOWNLOAD_STATUS_SUCCESS = 'map/segments/GET_DOWNLOAD_STATUS_SUCCESS';
const GET_DOWNLOAD_STATUS_FAIL = 'map/segments/GET_DOWNLOAD_STATUS_FAIL';
const LOAD_MORE_SEGMENTS = 'map/segments/LOAD_MORE';
const LOAD_MORE_SEGMENTS_SUCCESS = 'map/segments/LOAD_MORE_SUCCESS';
const LOAD_MORE_SEGMENTS_FAIL = 'map/segments/LOAD_MORE_FAIL';

export {
  GET_ALL_SEGMENTS,
  GET_ALL_SEGMENTS_SUCCESS,
  GET_ALL_SEGMENTS_FAIL,
  GET_DOWNLOAD_STATUS,
  GET_DOWNLOAD_STATUS_SUCCESS,
  GET_DOWNLOAD_STATUS_FAIL,
  LOAD_MORE_SEGMENTS,
  LOAD_MORE_SEGMENTS_SUCCESS,
  LOAD_MORE_SEGMENTS_FAIL,
};
