import { ILocation, ILocations } from 'app/types/BannerManagement';

import { IApiLocationFilter } from '../types';

const fromApiLocation = ({ location, locationType }: IApiLocationFilter): ILocation => ({
  id: location.toLowerCase(),
  name: location,
  type: locationType,
});

export const fromApiLocationFilter = (locationFilter?: Array<IApiLocationFilter>): ILocations =>
  (locationFilter || []).reduce(
    (acc, locnFilter, i) => {
      const { filterType, locationType } = locnFilter;
      if (filterType === 'INCLUDE') {
        if (locationType === 'City') {
          acc.includedCities.push(fromApiLocation(locnFilter));
        } else if (locationType === 'State') {
          acc.includedStates.push(fromApiLocation(locnFilter));
        }
      } else if (filterType === 'EXCLUDE') {
        if (locationType === 'City') {
          acc.excludedCities.push(fromApiLocation(locnFilter));
        } else if (locationType === 'State') {
          acc.excludedStates.push(fromApiLocation(locnFilter));
        }
      }
      return acc;
    },
    {
      includedCities: [],
      includedStates: [],
      excludedCities: [],
      excludedStates: [],
    },
  );

const toLocationFilter =
  (filterType: 'EXCLUDE' | 'INCLUDE') =>
  (location: ILocation): IApiLocationFilter => ({
    filterType,
    location: location.name,
    locationType: location.type,
  });

export const toLocationFilters = ({
  excludedCities,
  excludedStates,
  includedCities,
  includedStates,
}: {
  includedCities: Array<ILocation>;
  excludedCities: Array<ILocation>;
  includedStates: Array<ILocation>;
  excludedStates: Array<ILocation>;
}): Array<IApiLocationFilter> => {
  const locationFilters = [];

  locationFilters.push(...(includedCities || []).map(toLocationFilter('INCLUDE')));

  locationFilters.push(...(includedStates || []).map(toLocationFilter('INCLUDE')));

  locationFilters.push(...(excludedCities || []).map(toLocationFilter('EXCLUDE')));

  locationFilters.push(...(excludedStates || []).map(toLocationFilter('EXCLUDE')));

  return locationFilters;
};
