import React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { useSelector } from 'react-redux';
// import { getCampaignSize, getCampaignSizeSuccess, getCampaignSizeFail } from 'app/ducks/campaigns/campaign/actions';
import { Spinner, Icon } from 'app/midgarComponents';
import { formatNumber } from 'app/utilities/numberFormat';
import { segmentTypeFlags } from 'app/features/Segments2/utils';
import { useRouteMatch } from 'react-router-dom';
import { Segments } from '../../../../../../types/typescript/Segments';
// import * as fetch from 'app/utilities/http';
// import { mapIds } from 'app/ducks/helpers';
// import { getSegmentIds } from 'app/ducks/campaigns/campaign/helpers';

type Props = {
  type?: string;
  label?: string;
  includedSegments: Segments;
  excludedSegments: Segments;
  isExclusionCampaign?: boolean;
  includedSegmentsFilters?: Segments;
  excludedSegmentsFilters?: Segments;
  includedSegmentsGeoFilters?: Segments;
  maxAudienceLimit?: number;
  audienceFiltersEnabled: boolean;
};

type CampaignState = {
  campaigns: {
    campaign: {
      general: {
        campaignSize: {
          rawCount: { audienceCount: number; geoFilterCount: number };
          loading: boolean;
          error?: boolean;
        };
      };
    };
  };
};

const PotentialReach: React.FC<Props> = ({
  label,
  includedSegments,
  excludedSegments,
  includedSegmentsFilters,
  excludedSegmentsFilters,
  includedSegmentsGeoFilters,
  isExclusionCampaign,
  type,
  maxAudienceLimit,
  audienceFiltersEnabled,
}): JSX.Element => {
  // const dispatch = useDispatch();
  const match: { params: { id: string; type: string } } = useRouteMatch();
  const { campaignSize } = useSelector((state: CampaignState) => state.campaigns.campaign.general);
  const mounted: { current?: boolean } = React.useRef();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clone = urlParams.get('clone');
    if (match?.params?.id || clone) {
      // only run this on edit or clone
      // calculatePotentialReach(match.params);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      // if (match?.params) {
      //   calculatePotentialReach(match.params);
      // } else {
      //   calculatePotentialReach({
      //     id: null,
      //     type: 'new',
      //   });
      // }
    }
  }, [match, includedSegments, excludedSegments, includedSegmentsFilters, excludedSegmentsFilters, includedSegmentsGeoFilters]); //eslint-disable-line react-hooks/exhaustive-deps

  // const calculatePotentialReach = async ({ type, id }: { id: string | null; type: string }): Promise<void> => {
  //   const api = {
  //     getCampaignSize: '/campaigns/:id/size',
  //     postCampaignSize: '/campaigns/size',
  //   };
  //   const campaignBody = {
  //     includedSegmentIds: getSegmentIds(includedSegments, audienceFiltersEnabled),
  //     excludedSegmentIds: getSegmentIds(excludedSegments, audienceFiltersEnabled),
  //     includedSegmentsFilters: mapIds(includedSegmentsFilters),
  //     excludedSegmentsFilters: mapIds(excludedSegmentsFilters),
  //   };
  //
  //   dispatch(getCampaignSize());
  //
  //   try {
  //     if (type === 'edit' || type === 'new') {
  //       if (campaignBody.includedSegmentIds.length > 0) {
  //         const size = await fetch.post(api.postCampaignSize, campaignBody);
  //         dispatch(getCampaignSizeSuccess(size));
  //       } else {
  //         dispatch(getCampaignSizeSuccess(0));
  //       }
  //     } else if (id) {
  //       const size = await fetch.get(api.getCampaignSize.replace(':id', id));
  //       dispatch(getCampaignSizeSuccess(size));
  //     }
  //   } catch {
  //     dispatch(getCampaignSizeFail());
  //   }
  // };

  const isCsvOrBoostSegmentSelected = (): boolean => {
    if (includedSegments.some(seg => seg.type === segmentTypeFlags.CSV_BASED)) return true;
    if (excludedSegments.some(seg => seg.type === segmentTypeFlags.CSV_BASED)) return true;
    return isExclusionCampaign || false;
  };

  const renderCount = (): JSX.Element | string | number => {
    if (isCsvOrBoostSegmentSelected()) {
      return (
        <>
          Not applicable
          <Small>
            <Icon name="lightbulb-outline" size="14" color={sc.secondary} />
            Potential Reach cannot be immediately calculated when csv based, lookalike audience, or exclusion campaign is selected.
          </Small>
        </>
      );
    }

    if (!campaignSize.rawCount) return 0;

    const count = campaignSize.rawCount.audienceCount || 0;

    if (maxAudienceLimit && typeof count === 'number' && count > maxAudienceLimit) {
      return (
        <span>
          {formatNumber(count)}
          <Small data-qa="exceed-reminder">Please note that the audience you selected is larger than max audience limit</Small>
        </span>
      );
    }
    return formatNumber(count);
  };

  return (
    <Container data-qa="potential-reach">
      <h3>{label || 'Potential Reach'}</h3>
      {campaignSize.loading ? <Spinner /> : <h4 data-qa="count-section">{renderCount()}</h4>}
      {campaignSize.error && <p data-qa="potential-reach-error-message">Error refreshing campaign count</p>}
    </Container>
  );
};
export default React.memo(PotentialReach);

const Container = styled.div`
  position: absolute;
  top: 1rem;
  right: 2rem;

  h3 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: ${sc.secondary};
  }

  h4 {
    color: ${sc.tertiary};
    font-size: ${sc.fontSize};
    font-weight: 500;
  }
`;

const Small = styled.div`
  color: ${sc.secondary};
  font-size: ${sc.fontSizeSmaller};
  font-style: italic;
  line-height: 14px;
  margin-top: 0.5rem;
  width: 150px;
`;
