import { ICmaView } from 'app/api/storefront/types';

import { State } from './state';

type Store = {
  storefrontsCma: {
    viewsByStorefrontId: State;
  };
};

export const mapStateToViews =
  (storefrontId?: number) =>
  ({
    storefrontsCma: { viewsByStorefrontId },
  }: Store): {
    views: Array<ICmaView> | null | undefined;
    viewsError: string | null | undefined;
    viewsLoading: boolean;
  } => {
    const viewsEntry = storefrontId ? viewsByStorefrontId?.[storefrontId] : null;

    if (viewsEntry) {
      return {
        views: viewsEntry.views,
        viewsError: viewsEntry.error,
        viewsLoading: viewsEntry.loading,
      };
    }

    return {
      views: null,
      viewsError: null,
      viewsLoading: false,
    };
  };
