import * as React from 'react';
import PropTypes from 'prop-types';

export const Page = ({ classes, children, renderHeader = null }) => (
  <section className={classes.page}>
    {renderHeader && <section className={classes.header}>{renderHeader()}</section>}
    <section className={classes.body}>{children}</section>
  </section>
);

Page.propTypes = {
  classes: PropTypes.shape({
    page: PropTypes.string,
    header: PropTypes.string,
  }).isRequired,
  children: PropTypes.element.isRequired,
  renderHeader: PropTypes.func,
};
