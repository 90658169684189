import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import _Input from 'app/midgarComponents/Input';
import Button from 'app/midgarComponents/Button';
import Icon from 'app/midgarComponents/Icon';

const newId = () => Date.now().toString(36);

type Item = { id: string; key: string; value: string };

type Props = {
  keyValueArray: Array<Item>;
  readOnly?: boolean;
  updateFn: (arg0: Array<Item>) => any;
};

export default class KeyValue extends React.PureComponent<Props> {
  handleAdd = () => {
    const { keyValueArray, updateFn } = this.props;
    updateFn([...keyValueArray, { key: null, value: null, id: `kv-${newId()}` }]);
  };

  handleDelete = (index: number) => {
    const { keyValueArray, updateFn } = this.props;
    const newKeyValueArray = [...keyValueArray];
    newKeyValueArray.splice(index, 1);
    updateFn(newKeyValueArray);
  };

  handleInputChange = ({ name, value }, index) => {
    const { keyValueArray, updateFn } = this.props;
    keyValueArray[index][name] = value;
    updateFn(keyValueArray);
  };

  handleInputBlur = ({ name, value }, index) => {
    const { keyValueArray, updateFn } = this.props;
    keyValueArray[index][name.trim()] = value.trim();
    updateFn(keyValueArray);
  };

  isValidKey = (key: string | null | undefined, index: number): boolean => {
    const { keyValueArray } = this.props;
    return !!key && !keyValueArray.some((item, i) => i !== index && item && item.key === key);
  };

  isValidValue = (value: string | null | undefined): boolean => !!value;

  render() {
    const { keyValueArray, readOnly } = this.props;
    return (
      <>
        <Button disabled={readOnly} onClick={this.handleAdd} type="secondary">
          Add additional Metadata
        </Button>

        <div>
          {keyValueArray &&
            keyValueArray.length > 0 &&
            keyValueArray.map(({ id, key, value }, index) => (
              <Row key={id}>
                <RemoveButton color="primary" disabled={readOnly} name="delete" onClick={() => this.handleDelete(index)} size={25} />

                <Input
                  data-qa="key-input"
                  disabled={readOnly}
                  error={!this.isValidKey(key, index)}
                  name="key"
                  onChange={({ target }) => this.handleInputChange(target, index)}
                  onBlur={({ target }) => this.handleInputBlur(target, index)}
                  placeholder="Key"
                  type="text"
                  value={key || ''}
                />

                <Input
                  data-qa="value-input"
                  disabled={readOnly}
                  error={!this.isValidValue(value)}
                  name="value"
                  onChange={({ target }) => this.handleInputChange(target, index)}
                  onBlur={({ target }) => this.handleInputBlur(target, index)}
                  placeholder="Value"
                  type="text"
                  value={value || ''}
                />
              </Row>
            ))}
        </div>
      </>
    );
  }
}

const Input = styled(_Input)`
  height: auto;
  color: ${sc.greyDark};
`;

const RemoveButton = styled(Icon)`
  margin: 2rem 1rem;
`;

const Row = styled.div`
  margin: 1rem 0;
  display: flex;

  & > * {
    max-width: 300px;
    margin-right: 1rem;
  }
`;
