import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isNotEmpty } from 'ramda-adjunct';

import { Button, Typography, Accordion, AccordionSummary, AccordionDetails, Badge } from '@material-ui/core';

import { Error, GroupAdd, Group, ExpandMore } from '@material-ui/icons';

import { mapIndexed, R } from 'app/helpers/RamdaHelpers/helpers';
import { addComponentPropsIfNot } from 'app/helpers/ComponentHelpers/helpers';

import AndRuleGroup from '../AndRuleGroup';
import OrSeparator from '../OrSeparator';

const keyOf = (key, index) => `${index} - ${key}`;

const toErrorMessage = errors =>
  R.join(
    ', ',
    R.map(([desc, number]) => `${number} ${desc}`, R.toPairs(errors)),
  );

const toAndRuleGroup = R.curry(
  (
    {
      newItem,
      pathDict,
      originDict,
      initialOptions,
      inclusivePathsOfFeature,
      optionsBySelections,
      idOf,
      nameOf,
      descOf,
      handleAdd,
      handleUpdate,
      handleDelete,
      handleClone,
      lastIndex,
      orSeparator,
      errors,
      classes,
    },

    { key: groupKey, value: groupItems },
    index,
  ) => {
    const validationErrors = R.pathOr({}, [index], errors);

    return (
      <OrRuleGroupWrapper key={keyOf(groupKey, index)}>
        <Accordion defaultExpanded className={classes.marginBottom}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className={classes.wrapper2}
            style={{
              padding: '0px 10px',
            }}
          >
            <div className={classes.flexOneThird}>
              <Typography className={classes.heading}>
                <Badge className={classes.badge} badgeContent={groupItems.length} color="primary">
                  <Group />
                </Badge>
                AND Rule Group #{index + 1}
              </Typography>
            </div>
            {isNotEmpty(validationErrors) && (
              <div className={classes.flexOneThird}>
                <Typography className={classes.wrapper2}>
                  <Error color="error" style={{ marginRight: '0.2rem' }} />
                  <i>{toErrorMessage(validationErrors)} rule(s)</i>
                </Typography>
              </div>
            )}
          </AccordionSummary>

          <AccordionDetails
            style={{
              overflow: 'scroll',
              padding: '0px 10px 8px 10px',
            }}
          >
            <AndRuleGroup
              {...{
                index,
                lastIndex,
                classes,
                newItem,
                pathDict,
                originDict,
                initialOptions,
                inclusivePathsOfFeature,
                optionsBySelections,
                idOf,
                nameOf,
                descOf,
                data: groupItems,
                onUpdate: update => handleUpdate(update, index),
                onDelete: () => handleDelete(index, handleAdd),
                onClone: () => handleClone(index, handleAdd),
              }}
            />
          </AccordionDetails>
        </Accordion>
        {index < lastIndex && orSeparator}
      </OrRuleGroupWrapper>
    );
  },
);

const addAddButton = (handleAdd, classes) => {
  const addDisabled = window.location.pathname === '/audience/new' ? true : R.isNil(handleAdd);
  const addButtonText = `Add AND Rule Group ${addDisabled ? '(disabled)' : ''}`;

  const button = (
    <Button
      color="secondary"
      aria-label={addButtonText}
      disabled={addDisabled}
      {...addComponentPropsIfNot(addDisabled, { onClick: () => handleAdd() })}
    >
      <GroupAdd className={classes.marginRight} />
      {addButtonText}
    </Button>
  );

  return (
    <div className={classes.addGroupButtonContainer} data-tip={addButtonText} data-for="addAndGroupButton">
      {button}
    </div>
  );
};

export const OrRuleGroup = ({ data, handleAdd, newItem, handleReceiveDomRef, classes, ...otherProps }) => {
  const lastIndex = data.length - 1;
  const orSeparator = <OrSeparator classes={classes} />;

  return (
    <div classes={classes.container}>
      {mapIndexed(
        toAndRuleGroup({
          newItem,
          handleAdd,
          lastIndex,
          orSeparator,
          classes,
          ...otherProps,
        }),

        data,
      )}

      {addAddButton(handleAdd, classes)}

      <div ref={handleReceiveDomRef} />
    </div>
  );
};
OrRuleGroup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleAdd: PropTypes.func.isRequired,
  newItem: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleReceiveDomRef: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const OrRuleGroupWrapper = styled.div`
  margin-top: 2rem;
`;
