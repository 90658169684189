import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { ConfirmModal } from 'app/midgarComponents';
import { withAppConfig } from 'app/decorators';
import { IAppConfig } from 'configs/apps/types';
import { findChannelSetup } from 'app/ducks/configStore/utils';

import { withCampaignActions, ActionNames } from '../../actions';

import { actionsConfirmationMessages } from '../../Overview/Header/Actions';
import { compose } from 'recompose';

const preventEvent = e => {
  e.preventDefault();
  e.stopPropagation();
};

type Props = {
  actionNames: Array<string>;
  classes: { buttons: string };
  handleAction: (arg0: string) => unknown;
  id: number;
  medium: any;
  appConfig: IAppConfig;
};

type State = {
  open: boolean;
  currentActionName: null;
  anchorEl: any;
};

class Actions extends React.PureComponent<Props, State> {
  state = {
    open: false,
    currentActionName: null,
    anchorEl: null,
  };

  constructor() {
    super();
    this.anchorRef = React.createRef();
  }

  close = ev => {
    preventEvent(ev);
    this.setState({ open: false, currentActionName: null });
  };

  open = ev => {
    preventEvent(ev);
    this.setState({ open: true });
  };

  select = (actionName: string) => ev => {
    const { handleAction } = this.props;
    preventEvent(ev);
    this.setState({ currentActionName: actionName, open: false });
    if (!(actionName === ActionNames.stop || actionName === ActionNames.pause || actionName === ActionNames.approve)) {
      handleAction(actionName);
    }
  };

  closeConfirmModal = e => {
    preventEvent(e);
    this.setState({
      currentActionName: null,
    });
  };

  componentDidMount() {
    this.setState({ anchorEl: this.anchorRef.current });
  }

  confirmAction = actionName => e => {
    const { handleAction } = this.props;
    this.closeConfirmModal(e);
    handleAction(actionName);
  };

  render() {
    const { actionNames, classes, id, medium = {}, appConfig } = this.props;
    const { anchorEl, open, currentActionName } = this.state;
    const confirmationMessage =
      currentActionName && actionsConfirmationMessages[currentActionName] ? actionsConfirmationMessages[currentActionName] : null;
    const currentTenantChannelConfig = findChannelSetup(appConfig, medium.name) || {};

    return (
      <>
        <span>
          <Button data-qa="action-campaign-btn" disableRipple onClick={this.open} className={classes.buttons}>
            <MoreVert ref={this.anchorRef} />
          </Button>

          <Menu
            id={`${id}-menu`}
            anchorEl={anchorEl ?? undefined}
            open={open}
            onClose={this.close}
            anchorReference="anchorEl"
            getContentAnchorEl={null}
            anchorPosition={{ top: 200, left: 400 }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            {actionNames.length && !currentTenantChannelConfig.readOnly ? (
              // to do list: disable approve here because validation for table is not setup
              actionNames.map(action =>
                action === ActionNames.approve ? null : (
                  <MenuItem key={action} data-qa={`menu-item-${action}`} onClick={this.select(action)}>
                    {action}
                  </MenuItem>
                ),
              )
            ) : (
              <MenuItem>No Actions Available</MenuItem>
            )}
          </Menu>
        </span>
        <ConfirmModal
          title="Confirmation"
          confirmText="Yes"
          isOpen={!!confirmationMessage}
          onClose={this.closeConfirmModal}
          onConfirm={this.confirmAction(currentActionName)}
        >
          {confirmationMessage}
        </ConfirmModal>
      </>
    );
  }
}

export default compose(withAppConfig, withCampaignActions({ allowNewTabs: true }))(Actions);
