import * as React from 'react';

import { Link } from 'react-router-dom';
import { map, reduce } from 'ramda';
import { ConnectedUser } from 'app/ducks';
import { PageHeader } from 'app/midgarComponents';

import { Channel, ChannelHeader, ChannelWrapper } from './styledComponents';
import { linkConfig } from './link.config';

type LinkTuple = [string, string, Array<string>];

const renderLinks = isPermitted =>
  reduce(
    (acc, [to, text, permissions = []]: LinkTuple) =>
      isPermitted(permissions)
        ? [
            ...acc,
            <Link to={to} key={text}>
              {text}
            </Link>,
          ]
        : acc,
    [],
  );

const renderChannels = ({ isPermitted }) => {
  const permittedLinks = renderLinks(isPermitted);
  return map(({ links, sectionHeader, Icon }) => {
    const permittedChannelLinks = permittedLinks(links);
    return permittedChannelLinks.length ? (
      <Channel key={sectionHeader}>
        <ChannelHeader>
          <Icon />
          {sectionHeader}
        </ChannelHeader>
        {permittedChannelLinks}
      </Channel>
    ) : undefined;
  })(linkConfig);
};

export const Channels = () => (
  <>
    <PageHeader title="Channels" />
    <ChannelWrapper>
      <ConnectedUser>{renderChannels}</ConnectedUser>
    </ChannelWrapper>
  </>
);
