import { IVariantType } from 'app/types/BannerManagement';

const ACTION_FETCH_ERROR = 'ACTION_FETCH_ERROR';
const ACTION_FETCH_START = 'ACTION_FETCH_START';
const ACTION_FETCH_SUCCESS = 'ACTION_FETCH_SUCCESS';

export type State = {
  hasWidgetSubtypes: boolean;
  widgetSubtypes: Array<IVariantType> | null | undefined;
  widgetSubtypesError: string | null | undefined;
  widgetSubtypesLoading: boolean;
};

export const initialState: State = {
  hasWidgetSubtypes: false,
  widgetSubtypes: [],
  widgetSubtypesError: null,
  widgetSubtypesLoading: false,
};

export const Actions = {
  fetchError: (error: string) => ({ type: ACTION_FETCH_ERROR, payload: { error } }),
  fetchStart: () => ({ type: ACTION_FETCH_START }),
  fetchSuccess: (widgetSubtypes: Array<IVariantType>) => ({ type: ACTION_FETCH_SUCCESS, payload: { widgetSubtypes } }),
};

export default (state: State, { type, payload }: { type: string; payload?: Record<string, any> }) => {
  switch (type) {
    case ACTION_FETCH_ERROR:
      return {
        ...initialState,
        widgetSubtypesError: payload?.error,
      };

    case ACTION_FETCH_START:
      return {
        ...initialState,
        widgetSubtypesLoading: true,
      };

    case ACTION_FETCH_SUCCESS:
      return {
        ...initialState,
        widgetSubtypes: payload?.widgetSubtypes || [],
        hasWidgetSubtypes: (payload?.widgetSubtypes || []).length > 0,
      };

    default:
      return state;
  }
};
