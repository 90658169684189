import { State } from './reducers';

type Store = { bannerManagement: { engageCategories: State } };

export const mapStateToEngageCategories = ({
  bannerManagement: {
    engageCategories: { engageCategories, error: engageCategoriesError, loading: engageCategoriesLoading },
  },
}: Store) => ({
  engageCategories,
  engageCategoriesError,
  engageCategoriesLoading,
});
