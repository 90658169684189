import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { connect } from 'react-redux';

import { enableEngage as opEnableEngage } from 'app/ducks/bannerManagement/storefront/operations';
import { ConfirmModal, Button as _Button, Spinner } from 'app/midgarComponents';

type Props = {
  enableEngage: (storefrontId: number, entityId: number, viewId: number) => (...args: Array<any>) => any;
  enablingViewId: number | null | undefined;
  entityId?: number | null | undefined;
  storefrontId: number;
  viewId: number;
};

type State = {
  isModalOpen: boolean;
};

class EnableEngage extends PureComponent<Props, State> {
  state = {
    isModalOpen: false,
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  onConfirm = () => {
    const { enableEngage, entityId, storefrontId, viewId } = this.props;

    enableEngage(storefrontId, entityId, viewId);
    this.setState({ isModalOpen: false });
  };

  render() {
    const { enablingViewId, viewId } = this.props;
    const { isModalOpen } = this.state;

    return (
      <section>
        <Button onClick={this.openModal} type="secondary">
          {enablingViewId && enablingViewId === viewId ? <Spinner color={sc.sectionWhiteColor} /> : 'Enable'}
        </Button>

        <ConfirmModal isOpen={isModalOpen} onClose={this.closeModal} confirmText="Enable" onConfirm={this.onConfirm}>
          Are you sure you want to enable Engage?
        </ConfirmModal>
      </section>
    );
  }
}

const mapStateToProps = ({
  bannerManagement: {
    storefront: { enablingViewId },
  },
}) => ({ enablingViewId });

export default connect(mapStateToProps, { enableEngage: opEnableEngage })(EnableEngage);

const Button = styled(_Button)`
  width: 90px;
`;
