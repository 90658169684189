import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import Button from 'app/midgarComponents/Button';
import Icon from 'app/midgarComponents/Icon';

import RuleRow2 from 'app/components/RuleRow2';

import { mapIndexed, R } from 'app/helpers/RamdaHelpers/helpers';

import { displayInfo } from 'app/helpers/NotificationHelpers/helpers';

import { addComponentPropsIf, addComponentPropsIfNot } from 'app/helpers/ComponentHelpers/helpers';

// const keyOf = (data, index) =>
//   `${index} - ${andGroupDataHash(data)}`;

const keyOf = (groupKey, index) => `${index} - ${groupKey}`;

const toRuleRow = R.curry(
  (
    {
      pathDict,
      originDict,
      initialOptions,
      inclusivePathsOfFeature,
      optionsBySelections,
      idOf,
      nameOf,
      descOf,
      handleChange,
      handleDelete,
      canDelete,
      handleCloneOneRule,
      classes,
    },

    { key: groupKey, value: rowItems },
    index,
  ) => (
    <RuleRow2
      {...{
        index,
        classes,
        pathDict,
        originDict,
        initialOptions,
        inclusivePathsOfFeature,
        optionsBySelections,
        idOf,
        nameOf,
        descOf,
        items: rowItems,
        key: keyOf(groupKey, index),
        onChange: change => handleChange(change, index),
        onClone: () => handleCloneOneRule(index),
      }}
      {...addComponentPropsIf(canDelete, { onDelete: () => handleDelete(index) })}
    />
  ),
);

toRuleRow.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const addAddButton = (handleAdd, newItem) => {
  const addDisabled = R.isNil(handleAdd);
  const addButtonText = `Add Rule ${addDisabled ? '(disabled)' : ''}`;

  return (
    <ButtonWrapper>
      <Button
        type="secondary"
        aria-label={addButtonText}
        disabled={addDisabled}
        {...addComponentPropsIfNot(addDisabled, { onClick: () => handleAdd(newItem) })}
      >
        <Icon name="add" />
        {addButtonText}
      </Button>
    </ButtonWrapper>
  );
};

const addDeleteGroupButton = onDelete => {
  const deleteButtonText = 'Delete group';

  return (
    <ButtonWrapper>
      <Button type="secondary" aria-label={deleteButtonText} onClick={onDelete}>
        <Icon name="delete" />
        {deleteButtonText}
      </Button>
    </ButtonWrapper>
  );
};

const addCloneGroupButton = onClone => {
  const cloneButtonText = 'Clone group';

  return (
    <ButtonWrapper>
      <Button
        type="secondary"
        aria-label={cloneButtonText}
        onClick={() => {
          displayInfo('Group cloned');
          onClone();
        }}
      >
        <Icon name="copy" />
        {cloneButtonText}
      </Button>
    </ButtonWrapper>
  );
};

export const AndRuleGroup = ({ data, handleAdd, newItem, onDelete, onClone, classes, lastIndex, ...otherProps }) => (
  <div classes={classes.container} style={{ width: '100%' }}>
    {mapIndexed(
      toRuleRow({
        classes,
        ...otherProps,
        canDelete: data.length > 1,
      }),

      data,
    )}

    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      // spacing={24}
    >
      {addAddButton(handleAdd, newItem)}
      {addCloneGroupButton(onClone)}
      {lastIndex > 0 && addDeleteGroupButton(onDelete)}
    </Grid>
  </div>
);

AndRuleGroup.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleAdd: PropTypes.func.isRequired,
  newItem: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const ButtonWrapper = styled.div`
  margin: 2rem 1rem 1rem 1rem;
`;
