export const defaultMediumId = 0;
export const emailMediumId = 1;
export const bannerMediumId = 4;
export const cashbackMediumId = 8;
export const push2MediumId = 10;
export const notificationMediumId = 12;
/*
How to add a channel:

Add the channel ID above. It must be unique.

Also add the appropriate code to:

1. ducks/campaigns/campaign/actions
  set<Channel>Creative

2. ducks/campaigns/campaign/types
  SET_<Channel>_CREATIVE

3. ducks/campaigns/campaign/operations
  [<Channel>MediumId]: actions.set<Channel>Creative,

4. ducks/campaigns/campaign/<ChannelCreative>/reducers
  Add case for the action in step 2.
  The payload is the creative. It should be cloned and returned, to overwrite the state with a copy of the creative.
  It also injects a `valid: boolean` property.
  Example:
    case SET_<Channel>_CREATIVE: {
      return {
        valid: true,
        ...payload,
      };
    }

5. configs/permissions/index.js
  Add permissions for CAMPAIGN_<Channel>_DRAFT and CAMPAIGN_<Channel>_CREATE

6. features/Campaigns/actions.js
  Add the above permissions to entries for the new channel in:
    editPermissionsByChannel
    pausePermissionsByChannel
*/
