// TODO: should be supplied by the host/parent component
const styles = theme => ({
  root: {
    width: '100%',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 90,
  },

  regularFont: {
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: theme.typography.fontWeightLight,
  },

  boldFont: {
    fontWeight: theme.typography.fontWeightMedium,
  },

  selectedItemStyle: {
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: 'hsla(198, 100%, 85%, 1) !important',
  },

  nonSelectedItemStyle: {
    fontWeight: theme.typography.fontWeightLight,
  },

  button: {
    margin: theme.spacing(1),
  },

  leftIcon: {
    marginRight: theme.spacing(1),
  },

  rightIcon: {
    marginLeft: theme.spacing(1),
  },

  fab: {
    margin: theme.spacing(2),
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 110,
  },

  badge: {
    margin: `0 ${theme.spacing(2)}px`,
  },

  avatar: {
    margin: 10,
  },

  row: {
    display: 'flex',
    justifyContent: 'center',
  },

  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    margin: '0.6rem 0',
  },

  wrapper2: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },

  flexOneThird: {
    flexBasis: '33.33%',
  },

  addGroupButtonContainer: {
    zIndex: 1000,
  },

  element: {
    verticalAlign: 'top',
  },

  wrapperInner: {
    marginLeft: '0.2rem',
    marginRight: '0.2rem',
  },

  menuList: {
    maxHeight: 200,
    overflow: 'hidden',

    '&:hover': {
      overflow: 'auto',
    },
  },

  tooltip: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    // pointerEvents: 'auto !important',
    // '&:hover': {
    //   visibility: 'visible !important',
    //   opacity: '1 !important',
    // },
  },
  marginRight: {
    marginRight: '0.5rem',
  },

  marginBottom: {
    marginBottom: '0.5rem',
  },
});

export { styles };
