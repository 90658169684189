import { compose, withStateHandlers } from 'recompose';

import { ReadOnlyInputWithSingleSelectDialog } from './ReadOnlyInputWithSingleSelectDialog';

const addStateHandlers = withStateHandlers(
  ({ value }) => ({
    value,
    isOpen: false,
  }),

  {
    handleOpen: state => () => ({
      ...state,
      isOpen: true,
    }),

    handleClose:
      (_, { value: oldValue, onChange }) =>
      newValue => {
        if (newValue !== oldValue) {
          onChange(newValue);
        }

        return {
          isOpen: false,
          value: newValue,
        };
      },
  },
);

const enhance = compose(addStateHandlers);

export default enhance(ReadOnlyInputWithSingleSelectDialog);
