import * as React from 'react';
import { Typography } from '@material-ui/core';

export const Notsupported = () => (
  <section style={{ height: '100%', width: '100%', paddingTop: '10rem', textAlign: 'center' }}>
    <Typography variant="h5" style={{ color: 'red' }}>
      This feature is not supported for the selected tenant.
    </Typography>
    <Typography>
      Please contact <a href="mailto:pai-growth-tool@paypay-corp.co.jp">pai-growth-tool@paypay-corp.co.jp</a> if you think it should be.
    </Typography>
  </section>
);
