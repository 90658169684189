import { fetchUserBudgetTokens, postUserBudgetToken } from 'app/api/cashbackPromo';
import { IUserBudgetToken } from 'app/features/CashbackPromoManagement';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { DispatchFn, GetStateFn } from 'app/types';

import * as actions from './actions';

export const addUserBudgetToken = (token: IUserBudgetToken) => async (dispatch: DispatchFn) => {
  try {
    const { id } = await postUserBudgetToken(token);
    if (id) {
      dispatch(actions.add({ ...token, id }));
    } else {
      displayError('Error during save: SAVE_USER_BUDGET_TOKEN_01');
    }
  } catch (e) {
    displayError(e.message);
  }
};

export const getUserBudgetTokens = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    cashbackPromoConfig: {
      userBudgetTokens: { userBudgetTokens: prevTokens },
    },
  } = getState();
  if (!prevTokens || prevTokens.length === 0) {
    dispatch(actions.getInit());
    try {
      const tokens = await fetchUserBudgetTokens();
      dispatch(actions.getSuccess(tokens));
    } catch (e) {
      dispatch(actions.getError(e));
    }
  }
};
