import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { Separator } from 'app/features/BannerManagement/common/components';
import { sc } from 'app/styles';

import MetadataItem from './MetadataItem';

const asArray = (metadata: Record<string, string> | null | undefined) =>
  Object.entries(metadata || {})
    .map(([keyName, keyValue]) => ({ keyName, keyValue: String(keyValue) }))
    .sort((a, b) => {
      if (a.keyName > b.keyName) {
        return 1;
      }

      if (a.keyName < b.keyName) {
        return -1;
      }

      return 0;
    });

const asObject = (items: Array<{ keyName: string; keyValue: string }> | null | undefined): Record<string, string> =>
  (items || []).reduce((acc, { keyName, keyValue }) => (keyName && keyName.length > 0 ? { ...acc, [keyName]: keyValue } : acc), {});

type Props = {
  metadata: Record<string, string>;
  readOnly?: boolean;
  title?: string;
  updateMetadata: (metadata: Record<string, string>) => void;
};

type State = {
  items: Array<{
    keyName: string;
    keyValue: string;
  }>;
};

export default class Metadata extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { metadata } = this.props;
    const items = asArray(metadata);
    this.state = {
      items: items.length > 0 ? items : [{ keyName: '', keyValue: '' }],
    };
  }

  componentDidUpdate(prevProps: Props) {
    // This method ensures that the component is updated when a fetch has completed. It is only needed for opening existing variants.
    // This solution is not a good one, but it works at short notice. A better solution would be to introduce a loading flag.
    const { metadata } = this.props;
    const { items } = this.state;
    if (
      Object.keys(prevProps.metadata || {}).length === 0 &&
      Object.keys(metadata || {}).length > 0 &&
      Object.keys(asObject(items)).length === 0
    ) {
      // Disabled warning because this is a quick fix, and should only happen the first time a variant is retrieved
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        items: asArray(metadata),
      });
    }
  }

  isKeyNameDuplicate = (idx: number) => {
    const { items } = this.state;
    return items.some(({ keyName }, i, arr) => i !== idx && keyName === arr[idx].keyName);
    // TODO: If duplicate key names are problematic, then the "Update" button should be disabled.
    // This would require a field for this purpose in Redux, so it's not worth the complication unless it turns out to be a user issue.
  };

  onAdd = () => {
    const { items } = this.state;
    this.setState({
      items: [...items, { keyName: '', keyValue: '' }],
    });
  };

  onDelete = (idx: number) => () => {
    const { updateMetadata } = this.props;
    const { items } = this.state;
    const newItems = items.slice();
    newItems.splice(idx, 1);
    this.setState({
      items: newItems,
    });

    updateMetadata(asObject(newItems));
  };

  onChange = (idx: number) => (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const { updateMetadata } = this.props;
    const { items } = this.state;
    const newItems = items.slice();
    newItems[idx][ev.target.name] = ev.target.value.trim();
    this.setState({
      items: newItems,
    });

    updateMetadata(asObject(newItems));
  };

  render() {
    const { readOnly = false, title } = this.props;
    const { items = [] } = this.state;

    return (
      <Container>
        {title && (
          <Separator>
            <span>{title}</span>
          </Separator>
        )}

        {items.map(({ keyName, keyValue }, idx) => (
          <MetadataItem
            key={idx} // eslint-disable-line react/no-array-index-key
            isLast={idx === items.length - 1}
            keyName={keyName}
            keyNameError={this.isKeyNameDuplicate(idx)}
            keyValue={String(keyValue)}
            readOnly={readOnly}
            onAdd={this.onAdd}
            onDelete={this.onDelete(idx)}
            onChange={this.onChange(idx)}
          />
        ))}
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  margin-top: ${sc.gutter};
  width: 100%;
`;
