import * as types from './types';

export const getError = (error: Error) => ({
  type: types.GET_ERROR,
  payload: { error },
});

export const getInit = () => ({
  type: types.GET_INIT,
});

export const getSuccess = (events: Array<string>) => ({
  type: types.GET_SUCCESS,
  payload: {
    events,
  },
});
