import * as React from 'react';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';

export const Chips = ({ items = [], handleRemoveItem, classes = {} }) =>
  items.map(item => (
    <Chip
      id={item.id}
      key={item.id}
      label={item.name}
      className={classes.chip}
      onDelete={handleRemoveItem ? () => handleRemoveItem(item.id) : null}
    />
  ));

Chips.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ).isRequired,
  handleRemoveItem: PropTypes.func,
  classes: PropTypes.shape({
    chip: PropTypes.string,
  }),
};
