import buildLookups from 'app/utilities/buildLookups';
import * as types from './types';

const getAll = () => ({
  type: types.GET_ALL_TEMPLATES,
});

const getAllSuccess = (templates, paged = {}) => ({
  type: types.GET_ALL_TEMPLATES_SUCCESS,
  payload: {
    paged,
    templatesLookups: buildLookups(templates),
  },
});

const getAllFail = error => ({
  type: types.GET_ALL_TEMPLATES_FAIL,
  payload: { error },
});

export { getAll, getAllSuccess, getAllFail };
