import React from 'react';

import { Spinner } from 'app/midgarComponents';
import { ISuggestion } from 'app/types';

import { handleSelectSuggestion, toSuggestion, toTitle } from '../../utils';
import { AutocompleteWithDisabled, ErrorContainer } from '../../common/components';
import useViewsCma from './useViewsCma';
import { checkStorefront } from './utils';
import { useSelector } from 'react-redux';

type Props = {
  className?: string;
  disabled?: boolean;
  onSelectView: (arg0: ISuggestion) => (...args: Array<any>) => any;
  permittedStorefronts: Array<ISuggestion> | null | undefined;
  storefrontId?: number;
  storefronts: Array<ISuggestion> | null | undefined;
  viewId?: number;
};

export default ({ className, disabled, onSelectView, permittedStorefronts, storefrontId, storefronts, viewId }: Props) => {
  const { views, viewsError, viewsLoading } = useViewsCma(storefrontId);
  const user = useSelector(state => state.user);
  const permittedViews = user.storefrontsAndViews[storefrontId];

  if (viewsLoading) {
    return <Spinner size={30} />;
  }

  const { isStorefrontPermitted, storefrontExists } = checkStorefront({ permittedStorefronts, storefrontId, storefronts });

  const viewSuggestions =
    views?.reduce((acc, view) => {
      if (permittedViews.includes(view.id)) {
        acc.push(toSuggestion(view));
      }
      return acc;
    }, []) || [];

  const selectedView = viewId ? views?.find(v => v.id === viewId) : undefined;
  const viewExists = !!viewId && !!selectedView;
  const isError = !storefrontExists || !!viewsError || !viewSuggestions.length;

  return (
    <div className={className}>
      <AutocompleteWithDisabled
        disabled={isError || !isStorefrontPermitted || disabled}
        error={isError || !viewExists}
        id="view"
        label="View"
        loading={viewsLoading}
        name="view"
        onSelect={handleSelectSuggestion(views, onSelectView)}
        required
        suggestions={viewSuggestions}
        value={selectedView ? toTitle(selectedView) : String(viewId || '')}
      />

      {storefrontExists && (
        <>
          {!!viewId && !selectedView && <ErrorContainer>View {viewId} does not exist</ErrorContainer>}

          {viewSuggestions.length === 0 && <ErrorContainer>No Views in Storefront {storefrontId}</ErrorContainer>}

          {!!viewsError && <ErrorContainer>Error Retrieving Views</ErrorContainer>}
        </>
      )}
    </div>
  );
};
