import React from 'react';
import PropTypes from 'prop-types';

import { Button, TextField, MenuItem } from '@material-ui/core';
import Tooltip from '../Tooltip';
// import { get } from './renderedSearchResultCache';
import { List } from 'react-virtualized';

import { isNeitherNilNorEmpty, isNilOrEmpty, joinWithSpace, mapIndexed, R } from 'app/helpers/RamdaHelpers/helpers';

const pathComponentFrom = (key, content, classes) => (
  <li key={key} className={classes.pathComponent}>
    <span className={classes.pathContent}>{content}</span>
  </li>
);

const padding = joinWithSpace(R.repeat('.', 5));

const breadcrumbFrom = (paths, alias, classes, desc) => {
  const isLast = R.equals(R.length(paths) - 1);
  const mapper = (path, index) => pathComponentFrom(index, isLast(index) ? alias || path : path, classes);

  return (
    <Tooltip title={desc || ''} placement="top">
      <ul className={classes.container}>
        {mapIndexed(mapper, paths)}
        {pathComponentFrom(paths.length, padding, classes)}
      </ul>
    </Tooltip>
  );
};
const breadcrumbButtonFrom = (paths, description, alias, handleClick, classes) => (
  <Tooltip title={description} placement="top">
    <Button disableRipple onClick={handleClick} className={classes.button}>
      {breadcrumbFrom(paths, alias, classes)}
    </Button>
  </Tooltip>
);

const pluralize = (word, size) => `${word}${size > 1 ? 's' : ''}`;

const searchHintOf = (phrase, isTooShort, minNumOfChars, numberOfMatches) => {
  if (isTooShort(phrase)) {
    const diff = minNumOfChars - phrase.length;

    return `Type ${diff} more ${pluralize('character', diff)} to search`;
  }

  return numberOfMatches > 0 ? `${numberOfMatches} matching ${pluralize('feature', numberOfMatches)}` : 'No matching feature found';
};

export const BreadcrumbButton = ({
  paths = [],
  description,
  alias,
  searchPhrase = '',
  minNumOfChars,
  isTooShort,
  placeholder = 'Select a feature',
  isCascadingMenuOpen,
  handleSearch,
  debouncedSearch,
  matchingFeatures,
  handleClick,
  onFeatureSelection,
  classes,
}) => {
  if (isNeitherNilNorEmpty(paths)) {
    return breadcrumbButtonFrom(paths, description, alias, handleClick, classes);
  }

  const handleSearchInput = ({ target: { value } }) => {
    if (isNilOrEmpty(value)) {
      handleSearch(''); // this is used to dismiss the previous result pane right away.
      debouncedSearch(''); // this is a trick to make sure two states are synced.
    } else {
      debouncedSearch(value);

      if (isCascadingMenuOpen) {
        handleClick();
      }
    }
  };
  const handleFeatureSelection = breadcrumb => {
    onFeatureSelection(R.last(breadcrumb));
  };
  const onSelectFeatureButtonClick = () => {
    // handleSearch(valueClearedEvent);
    handleSearch('');
    handleClick();
  };
  const customRowRender = ({ index, key, isScrolling, style }) => {
    const [paths, alias, desc] = matchingFeatures[index];
    const content = isScrolling ? 'Loading...' : breadcrumbFrom(paths, alias, classes, desc);
    return (
      <MenuItem disableRipple key={`MenuItem-${key}`} style={style} data-qa={index} onClick={() => handleFeatureSelection(paths)}>
        {content}
      </MenuItem>
    );
  };

  const numberOfMatches = matchingFeatures.length;

  return (
    <>
      <Button
        onClick={onSelectFeatureButtonClick}
        variant="contained"
        color="primary"
        style={{
          textTransform: 'none',
        }}
      >
        {placeholder}
      </Button>

      <TextField
        autoFocus
        margin="dense"
        type="search"
        placeholder="or type here to search..."
        data-qa="breadcrumbSearch"
        onChange={handleSearchInput}
        className={classes.textField}
        {...(isNilOrEmpty(searchPhrase)
          ? {}
          : {
              helperText: searchHintOf(searchPhrase, isTooShort, minNumOfChars, numberOfMatches),
            })}
        style={{
          minWidth: 200,
          marginLeft: 10,
        }}
      />

      {numberOfMatches > 0 && (
        <List
          style={{
            flexBasis: '100%',
            maxWidth: '100%',
          }}
          height={200}
          width={800}
          rowHeight={40}
          rowRenderer={customRowRender}
          rowCount={numberOfMatches}
        />
      )}
    </>
  );
};

BreadcrumbButton.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  alias: PropTypes.string,
  searchPhrase: PropTypes.string,
  minNumOfChars: PropTypes.number.isRequired,
  isTooShort: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isCascadingMenuOpen: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  debouncedSearch: PropTypes.func.isRequired,
  matchingFeatures: PropTypes.arrayOf(PropTypes.array).isRequired,
  handleClick: PropTypes.func.isRequired,
  onFeatureSelection: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};
