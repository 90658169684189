import { MailOutline as EmailIcon, ViewHeadline as BannerIcon } from '@material-ui/icons';

import * as permissions from 'configs/permissions';
import { CHANNELS_BANNER_DEBUG_DEFAULT, CHANNELS_EMAIL_TEMPLATE_REPORTING } from 'configs/routes';

export const linkConfig = [
  {
    sectionHeader: 'Email',
    Icon: EmailIcon,
    links: [[CHANNELS_EMAIL_TEMPLATE_REPORTING, 'Templates', permissions.templateReadPermissions]],
  },

  {
    sectionHeader: 'Banner',
    Icon: BannerIcon,
    links: [[CHANNELS_BANNER_DEBUG_DEFAULT, 'Debug', permissions.bannerDebugReadPermissions]],
  },
];

const permissionsFromConfigLinks = links => links.reduce((acc, link) => [...acc, ...link[2]], []);

export const channelsPermissions = linkConfig.reduce((acc, config) => [...acc, ...permissionsFromConfigLinks(config.links)], []);
