import { compose, withProps } from 'recompose';
import { asReplacements, replaceImagesHTML } from 'app/helpers/ImageUploadManager';

import { Design } from './Design';
import { missingImages } from './helpers';

const addProps = withProps(({ content = '', fileUploads = [] }) => ({
  missingImages: missingImages(fileUploads, content),
  transformContent: rawHtml => replaceImagesHTML(rawHtml, asReplacements(fileUploads)),
}));

const enhance = compose(addProps);

export default enhance(Design);
