import api from 'app/utilities/api';
import { DispatchFn } from 'app/types';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import result from 'app/utilities/result';
import * as actions from './actions';

export const getAclSFUsers = (storefrontId: number) => async (dispatch: DispatchFn) => {
  try {
    const { users } = await api.get(`/acl/storefronts/${storefrontId}`);
    if (users) {
      dispatch(actions.getAclSFUsersSuccess(users));
    }
  } catch (err) {
    const msg = result('error.msg', err);
    console.error(err); // eslint-disable-line no-console
    displayError(`Storefront ${storefrontId} is not accessible at the momonet, please report the issue to dev team! ${msg || ''}`);
    return dispatch(actions.getAclSFUsersFailed());
  }
};
