import React from 'react';
import debounce from 'lodash.debounce';

import { filterBy } from 'app/helpers/RamdaHelpers/helpers';
import { MultiSelectDialog } from './MultiSelectDialog';

const updateSelection = (selectionSet, targetOption, selected) => {
  const existed = selectionSet.has(targetOption);
  const newSelectionSet = new Set(selectionSet);

  if (selected && !existed) {
    return newSelectionSet.add(targetOption);
  }
  if (!selected && existed) {
    newSelectionSet.delete(targetOption);
    return newSelectionSet;
  }
  return selectionSet;
};

const listContainerMargin = 48 * 2 + 141 + 52;

class MultiSelectDialogContainer extends React.Component {
  constructor(props) {
    super(props);
    const { selection } = this.props;
    this.state = {
      selectionSet: new Set(selection || []),
      searchPhrase: '',
      listContainerHeight: Math.max(window.innerHeight - listContainerMargin, 1),
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = debounce(() => {
    const newListContainerHeight = Math.max(window.innerHeight - listContainerMargin, 1);
    this.setState({ listContainerHeight: newListContainerHeight });
  }, 500);

  handleChange = ({ target: { value: optionClicked } }, checked) => {
    const { selectionSet } = this.state;
    this.setState({ selectionSet: updateSelection(selectionSet, optionClicked, checked) });
  };

  handleClose = okayed => {
    const { selectionSet } = this.state;
    const { selection: oldSelection = [], onClose } = this.props;
    const selection = okayed ? [...selectionSet] : oldSelection;

    onClose(selection);

    this.setState({
      selectionSet: okayed ? selectionSet : new Set(oldSelection),
      searchPhrase: '',
      options: undefined,
    });
  };

  handleSelectAll = () => {
    const { options } = this.props;

    this.setState({
      selectionSet: new Set(options),
    });
  };

  handleClear = () => {
    this.setState({
      selectionSet: new Set(),
    });
  };

  handleSearch = ({ target: { value } }) => {
    const { options } = this.props;
    this.setState({
      options: filterBy(value, options),
      searchPhrase: value,
    });
  };

  render() {
    const { options } = this.props;
    const { selectionSet, searchPhrase, listContainerHeight, options: stateOptions } = this.state;

    return (
      <MultiSelectDialog
        {...this.props}
        options={stateOptions || options}
        selectionSet={selectionSet}
        searchPhrase={searchPhrase}
        listContainerHeight={listContainerHeight}
        handleChange={this.handleChange}
        handleClose={this.handleClose}
        handleSelectAll={this.handleSelectAll}
        handleClear={this.handleClear}
        handleSearch={this.handleSearch}
      />
    );
  }
}

export default MultiSelectDialogContainer;
