import styled from 'styled-components';

import Paper from 'app/midgarComponents/Paper';
import { sc } from 'app/styles';

export const ChannelWrapper = styled(Paper)`
  display: flex;
  height: calc(100% - 90px);
  width: 100%;
  padding: 2rem;
`;

export const Channel = styled.section`
  width: 12rem;
  a {
    margin: 1rem 0 0 2.5rem;
    display: block;
    text-decoration: none;
    font-size: 0.85rem;
    color: ${sc.bodyColor};
    &:hover,
    &:active {
      color: ${sc.primary};
    }
  }
`;

export const ChannelHeader = styled.h3`
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  height: 2rem;
  color: ${sc.greyDarkest};
  svg {
    margin-right: 1rem;
  }
`;
