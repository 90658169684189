import { HOC } from 'recompose';
import { withProps } from 'recompose';
import moment from 'moment';
import 'moment-timezone';

import channelConfig from 'configs/channels/channel.config';
import { getChannelCreativeConfig } from 'configs/channels';
import { emailMediumId, push2MediumId, bannerMediumId } from 'configs/channels/mediumIds';
import { findErrors } from 'app/utilities/variable';
import { isValid as isPredicateValid } from 'app/midgarComponents/PredicateBuilder/helper';
import { ICampaign } from 'app/types';
import { IAppConfig } from 'configs/apps/types';
import { triggerTypes, dayInSeconds } from 'app/utilities/constants';
import { getDelayInSeconds } from 'app/features/Campaigns/utils';

import {
  failMsgCreativeInvalid,
  failMsgNoChannel,
  failMsgRequired,
  failMsgFormErrors,
  failMsgSegmentFilters,
  failMsgVariables,
  failPredicateFilter,
  failBannerUserCriteria,
  missingRequiredFields,
  validateSegmentFilters,
  validateVariables,
  getDuplicateVariables,
  failMsgScheduleStartTime,
  failMsgBannerEvents,
  failMsgEvent,
  failMsgEventValidTime,
  isValidTimeValid,
  failMsgDelayTime,
  compareDates,
  failMsgEndDate,
  failMsgMaxDurationExceeded,
  failMsgMaxAudienceLimit,
} from './validationUtils';

import { isMaxAudienceRequired, maxAudienceProp } from '../campaignReach';
import { startTimeDiff } from './constants';

type IFeaturesType = Record<
  string,
  {
    dataType: string;
    originalDataType: string;
    featureId: number;
    label: string;
  }
>;

type IValidatedType = {
  valid: boolean;
  validationFailures: Array<unknown>;
};

type Props = {
  campaign: ICampaign;
  features: IFeaturesType;
  timezone: string;
  appConfig: IAppConfig;
};

export const validate = ({ campaign, features = {}, timezone, appConfig }: Props): IValidatedType => {
  const {
    mediumId,
    triggerType,
    useEveryoneAudience,
    variables,
    criteria,
    exclusionCampaign,
    customerCountPerExecution,
    formErrors,
    numberOfImpressions,
    startDate,
    startTime,
    expiryDate,
    expiryTime,
    maxAudienceLimit,
  } = campaign.general;
  const channel = channelConfig[mediumId];
  const creative = campaign[channel.creative] || {};
  const templated = creative.templated;
  const { bannerRealTimeUserCriteria } = creative;
  let validationFailures = [];

  if (startDate && startTime) {
    const startUTCTime = moment?.tz ? moment.tz(`${startDate}T${startTime}`, timezone) : undefined;
    const currentUTCTime = moment();

    if (currentUTCTime.diff(startUTCTime) > startTimeDiff) {
      validationFailures.push(failMsgScheduleStartTime);
    }
  }

  // check if end date is earlier than start date
  if (triggerType !== triggerTypes.oneTime) {
    const datesComparisonResult = compareDates(startDate, startTime, expiryDate, expiryTime);
    if (datesComparisonResult.isSameOrAfter) {
      validationFailures.push(failMsgEndDate);
    }
    if (datesComparisonResult.isMaxDurationExceeded) {
      validationFailures.push(failMsgMaxDurationExceeded);
    }
  }

  const commonRequiredFields = [
    { name: 'startDate', title: 'Start Date' },
    { name: 'startTime', title: 'Start Time' },
    { name: 'includedSegments', title: 'Included Audiences' },
  ];

  const commonRequiredFieldNames = commonRequiredFields.map(f => f.name);

  // Look up of display title for field name (used for validation failure messages)
  // If a title is not provided, then the raw field name will be displayed
  const fieldTitleByName = {
    ...commonRequiredFields.reduce((acc, f: { name: string; title: string }) => ({ ...acc, [f.name]: f.title }), {}),
    [maxAudienceProp]: 'Max Audience',
    dedupeStrategy: 'Dedupe Strategy',
    excludedSegments: 'Excluded Audiences',
  };

  const requiredFieldNames = [...commonRequiredFieldNames, ...(channel.requiredFields || [])]
    .filter(name => name !== maxAudienceProp || (isMaxAudienceRequired(triggerType) && !useEveryoneAudience && !exclusionCampaign))
    .filter(name => name !== 'includedSegments' || !useEveryoneAudience);

  if (exclusionCampaign && requiredFieldNames.includes('includedSegments') && !useEveryoneAudience) {
    requiredFieldNames.splice(requiredFieldNames.indexOf('includedSegments'), 1, 'excludedSegments');
  }

  if (requiredFieldNames.indexOf('customerCountPerExecution') > -1) {
    if (customerCountPerExecution && maxAudienceLimit) {
      if (Number(customerCountPerExecution) > Number(maxAudienceLimit)) {
        validationFailures.push(failMsgMaxAudienceLimit(maxAudienceLimit));
      }
    }
  }

  const missingFields = missingRequiredFields(requiredFieldNames, campaign.general);

  const indexOfMaxAudienceLimit = missingFields.indexOf('customerCountPerExecution');
  if (indexOfMaxAudienceLimit > -1) {
    missingFields.splice(indexOfMaxAudienceLimit, 1);
  }

  const indexIncludedSegments = missingFields.indexOf('includedSegments');
  if (indexIncludedSegments > -1) {
    missingFields.splice(indexIncludedSegments, 1);
  }

  if (missingFields.length > 0) {
    validationFailures.push(failMsgRequired(missingFields, fieldTitleByName));
  }

  const areSegmentFiltersValid = validateSegmentFilters(campaign.general.excludedSegments, campaign.general.excludedSegmentsFilters);
  if (!areSegmentFiltersValid) {
    validationFailures.push(failMsgSegmentFilters);
  }

  const nonFilterVariables = {
    features: [],
    events: [],
  };

  nonFilterVariables.events =
    appConfig.enableFeatureFilters && variables.events
      ? variables.events.filter(v => !v['nested_types'] || v['nested_types'].length === 0)
      : variables.events;

  nonFilterVariables.features =
    appConfig.enableFeatureFilters && variables.features
      ? variables.features.filter(v => !v['nested_types'] || v['nested_types'].length === 0)
      : variables.features;

  const areVariablesValid = validateVariables(nonFilterVariables, creative, mediumId);
  if (!areVariablesValid) {
    validationFailures.push(failMsgVariables);
  }
  const duplicateVariables = getDuplicateVariables(variables);
  if (duplicateVariables.length) {
    validationFailures.push(`Following variables appear twice - ${duplicateVariables.join(', ')}`);
  }

  const isChannelSelected = mediumId > 0;
  if (!isChannelSelected) {
    validationFailures.push(failMsgNoChannel);
  }

  if (triggerType === triggerTypes.eventsBased) {
    const { deactivationEvent } = criteria || {};

    if (mediumId === bannerMediumId && !numberOfImpressions && !deactivationEvent) {
      validationFailures.push(failMsgBannerEvents);
    }

    if (criteria?.eventName && criteria?.delayTime) {
      const delayTimeInSeconds = getDelayInSeconds(criteria.delayTime?.value, criteria.delayTime.unit);

      if (delayTimeInSeconds > dayInSeconds * 2) {
        validationFailures.push(failMsgDelayTime);
      }
    }

    if (
      !isValidTimeValid(criteria?.validTimeFrom, criteria?.validTimeTo) ||
      !isValidTimeValid(deactivationEvent?.validTimeFrom, deactivationEvent?.validTimeTo)
    ) {
      validationFailures.push(failMsgEventValidTime);
    }

    if (!criteria?.eventName) {
      validationFailures.push(failMsgEvent);
    }
  }

  const { valid: isCreativeValid, errorMessages } = creative;
  const channelCreativeConfig = appConfig && isChannelSelected ? getChannelCreativeConfig(appConfig, mediumId) : {};

  if ((isChannelSelected && !isCreativeValid) || (channelCreativeConfig.useCampaignRole && !creative.merchantRole)) {
    if (errorMessages && errorMessages.length) {
      validationFailures = validationFailures.concat(errorMessages);
    } else {
      validationFailures.push(failMsgCreativeInvalid);
    }
  }

  const variablesToValidate = Array.isArray(variables) ? variables : [...variables.features, ...variables.events];
  const featuresWithEvents = {
    ...variables.features.reduce((acc, feature) => ({ ...acc, [feature.name]: feature }), {}),
    ...variables.events.reduce((acc, event) => ({ ...acc, [event.name]: event }), {}),
  };

  const content = creative?.payload?.notification?.content || {};
  const click = creative?.payload?.notification?.interactive?.click || {};

  const templateChannelList = {
    [emailMediumId]: creative.html,
    [push2MediumId]: [content.body, content.title, content.summary, click.value].filter(Boolean).join('\n'),
  };

  // TODO: Select Email channel, specify variable without a default, then change channel. Error persists
  const templateErrors =
    !templated && templateChannelList[mediumId]
      ? findErrors({ html: templateChannelList[mediumId], hashedValues: featuresWithEvents, variables: variablesToValidate })
      : undefined;

  const { featureErrors: featureEmailErrors = [], variableErrors: variableEmailErrors = [] } = templateErrors || {};

  if (featureEmailErrors.length) {
    validationFailures.push(featureEmailErrors);
  }
  if (variableEmailErrors.length) {
    validationFailures.push(variableEmailErrors);
  }

  // Predicate builder validator
  if (criteria && criteria.predicateFilterObject && !isPredicateValid(criteria.predicateFilterObject)) {
    validationFailures.push(failPredicateFilter);
  }
  if (bannerRealTimeUserCriteria?.userCriteriaJson && !isPredicateValid(bannerRealTimeUserCriteria.userCriteriaJson)) {
    validationFailures.push(failBannerUserCriteria);
  }

  if (Object.keys(formErrors).length) {
    const errorneousSections = [];

    Object.keys(formErrors).forEach(formSection => {
      if (formErrors[formSection]) errorneousSections.push(formSection);
    });

    if (errorneousSections.length) {
      validationFailures.push(failMsgFormErrors(errorneousSections.join(', ')));
    }
  }

  return {
    valid: !validationFailures.length,
    validationFailures,
  };
};

export const withValidation: HOC<any, Props> = withProps(validate);
