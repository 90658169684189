import React from 'react';
import moment from 'moment';
import { Icon as _Icon } from 'app/midgarComponents';
import styled from 'styled-components';
import { formatDateTime } from 'app/utilities/date';
import { sc } from 'app/styles';

import { ScheduleStatus, ScheduleStatusLabels } from '../helpers';

class ScheduleColumn extends React.Component<
  {
    status: number;
    warningMessage?: Record<string, any>;
    validFrom?: string;
    validUpto?: string;
    timezone: string;
  },
  {
    tooltipVisible: boolean;
    warningTooltipVisible: boolean;
  }
> {
  state = {
    tooltipVisible: false,
    warningTooltipVisible: false,
  };

  render() {
    const { tooltipVisible, warningTooltipVisible } = this.state;
    const { status, validFrom, validUpto, warningMessage, timezone } = this.props;

    return (
      <Container>
        {validFrom ? (
          <IconContainer>
            <TimeIcon
              name="time"
              status={status}
              onMouseEnter={() => this.setState({ tooltipVisible: true })}
              onMouseLeave={() => this.setState({ tooltipVisible: false })}
            />

            <CustomTooltip visible={tooltipVisible}>
              <TooltipLabel>{ScheduleStatusLabels[status]}</TooltipLabel>
              <TooltipField>{`Start time: ${formatDateTime(moment(validFrom), timezone)}`}</TooltipField>
              <TooltipField>{`End time: ${formatDateTime(moment(validUpto), timezone)}`}</TooltipField>
            </CustomTooltip>
          </IconContainer>
        ) : null}

        {warningMessage ? (
          <IconContainer>
            <WarningIcon
              name="warning"
              onMouseEnter={() => this.setState({ warningTooltipVisible: true })}
              onMouseLeave={() => this.setState({ warningTooltipVisible: false })}
            />

            <CustomTooltip type="warning" visible={warningTooltipVisible}>
              <TooltipLabel>Error</TooltipLabel>
              <TooltipField>{warningMessage.error}</TooltipField>
            </CustomTooltip>
          </IconContainer>
        ) : null}
      </Container>
    );
  }
}

export default ScheduleColumn;

const CustomTooltip = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  transform: translate(-45%, -25%);
  transition: all 50ms ease-in-out;

  border: 1px solid ${props => (props.type === 'warning' ? sc.danger : sc.sectionBorderColor)};
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 10px ${sc.shadowLight};
  pointer-events: none;
`;

const Container = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
`;

const IconContainer = styled.div`
  padding: ${sc.gutterSmallest} 0;
`;

const TimeIcon = styled(_Icon)`
  display: block;
  cursor: pointer;

  ${(props: any) => {
    switch (props.status) {
      case ScheduleStatus.Ready:
        return `color: ${sc.greyLight};`;

      case ScheduleStatus.Running:
        return `color: ${sc.success};`;

      case ScheduleStatus.Completed:
        return `color: ${sc.primary};`;

      default:
        return `color: ${sc.greyLighter};`;
    }
  }}
`;

const WarningIcon = styled(_Icon)`
  display: block;
  cursor: pointer;
  color: ${sc.danger};
`;

const TooltipField = styled.span`
  display: block;
  padding: ${sc.gutterSmallest};
`;

const TooltipLabel = styled(TooltipField)`
  font-weight: bold;
`;
