import * as React from 'react';
import { Segments } from '../../../types/typescript/Segments';
import Audience from './Audience';

type Props = {
  segmentsFetching: boolean;
  fetchSegments: Function;
  exclusionCampaign: boolean;
  includedSegments: Segments;
  excludedSegments: Segments;
  includedsegmentOptions: Segments;
  excludedsegmentOptions: Segments;
  supportedTypes: string;
  audienceId: Segments;
  versionChecked: boolean;
  mediumId: number;
  resetAudience: Function;
};

const Audiences = ({
  includedsegmentOptions,
  excludedsegmentOptions,
  segmentsFetching,
  fetchSegments,
  exclusionCampaign,
  includedSegments,
  excludedSegments,
  supportedTypes,
  audienceId,
  versionChecked,
  resetAudience,
  mediumId,
}: Props): JSX.Element => {
  React.useEffect(() => {
    if (mediumId === 0 && versionChecked) resetAudience(false);
  }, [mediumId]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {versionChecked && (
        <Audience
          required={versionChecked}
          id="audienceId"
          name="audienceId"
          label="Audience Id"
          segments={includedsegmentOptions}
          segmentsFetching={segmentsFetching}
          selected={audienceId}
          fetchSegments={fetchSegments}
          typeBase={supportedTypes}
          audienceType={'V2'}
        />
      )}
      {!versionChecked && !exclusionCampaign && (
        <Audience
          required={!exclusionCampaign}
          id="includedSegments"
          name="includedSegments"
          label="Included Audiences"
          segments={includedsegmentOptions}
          segmentsFetching={segmentsFetching}
          selected={includedSegments}
          fetchSegments={fetchSegments}
          typeBase={supportedTypes}
        />
      )}

      {!versionChecked && (
        <Audience
          required={exclusionCampaign}
          id="excludedSegments"
          name="excludedSegments"
          label="Excluded Audiences"
          segments={excludedsegmentOptions}
          segmentsFetching={segmentsFetching}
          selected={excludedSegments}
          fetchSegments={fetchSegments}
          typeBase="RULE,CSV"
        />
      )}
    </>
  );
};

export default Audiences;
