import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'ramda';

import withAppConfig from 'app/decorators/withAppConfig';
import { creativeActions } from 'app/ducks';
import { mapStateToLandingPageTypes } from 'app/ducks/bannerManagement/landingPageTypes/selectors';
import { mapStateToVariantTypes } from 'app/ducks/bannerManagement/variantTypes/selectors';
import { mapStateToWidgetTypes } from 'app/ducks/bannerManagement/widgetTypes/selectors';
import useWidgetSubtypes from 'app/features/BannerManagement/hooks/useWidgetSubtypes';
import VariantType from 'app/features/BannerManagement/common/VariantType';
import WidgetType from 'app/features/BannerManagement/common/WidgetType';
import { showWidgetType } from 'app/features/BannerManagement/utils';
import { Autocomplete, TextField } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Action, ISuggestion } from 'app/types';
import { ILandingPageType, IVariantType, IWidgetType } from 'app/types/BannerManagement';
import { getBannerCreativeConfig, LEVEL_VARIANT } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

type Props = {
  appConfig: IAppConfig;
  isCreative: boolean;
  itemId: string;
  landingPageType: string;
  landingPageTypes: Array<ILandingPageType>;
  name: string;
  readOnly?: boolean;
  shouldValidate: boolean;
  subTitle?: string;
  title?: string;
  updateVariantsField: (index: number, field: string, value: any) => Action;
  url: string;
  variantFieldHasError: (variantKey: string, fieldName: string) => boolean;
  variantKey: string;
  variantType?: IVariantType;
  widgetType: string;
  widgetTypes: Array<IWidgetType>;
  imageUrl: string;
  alternateImageUrl: string;
};

const Fields = ({
  appConfig,
  isCreative,
  itemId,
  landingPageType,
  landingPageTypes,
  name,
  readOnly,
  shouldValidate,
  subTitle,
  title,
  updateVariantsField,
  url,
  variantFieldHasError,
  variantKey,
  variantType,
  widgetType,
  widgetTypes,
  imageUrl,
  alternateImageUrl,
}: Props) => {
  const creativeConfig = getBannerCreativeConfig(appConfig);

  const handleChangeNumber = (fieldName: string) => (ev: React.SyntheticEvent<HTMLInputElement>) =>
    updateVariantsField(Number(variantKey), fieldName, Number(ev.currentTarget.value));

  const handleChangeString = (fieldName: string) => (ev: React.SyntheticEvent<HTMLInputElement>) =>
    updateVariantsField(Number(variantKey), fieldName, ev.currentTarget.value);

  const handleBlurString = (fieldName: string) => (ev: React.SyntheticEvent<HTMLInputElement>) =>
    updateVariantsField(Number(variantKey), fieldName, ev.currentTarget.value.trim());

  const handleChangeWidgetType = (widgetType: string) => {
    // TODO: This is temporary step to clean up the UX. See the TODO comment in withWidgetSubtypes.
    creativeConfig.useVariantType && updateVariantsField(Number(variantKey), 'variantType', '');
    updateVariantsField(Number(variantKey), 'widgetType', widgetType);
  };

  const { hasWidgetSubtypes, widgetSubtypes, widgetSubtypesError, widgetSubtypesLoading } = useWidgetSubtypes({
    appConfig,
    setVariantType: variantType => updateVariantsField(Number(variantKey), 'variantType', variantType),
    variantType,
    widgetType,
  });

  const selectedLandingPageType = landingPageTypes.find(type => type.name === landingPageType);

  return (
    <Container>
      <FieldGroup>
        <InlineField>
          <TextField
            disabled={readOnly}
            error={shouldValidate && variantFieldHasError(variantKey, 'name')}
            id="variant-name"
            label="Name"
            name="name"
            onChange={handleChangeString('name')}
            onBlur={handleBlurString('name')}
            required
            value={name || ''}
          />
        </InlineField>
      </FieldGroup>

      {showWidgetType(creativeConfig, LEVEL_VARIANT) && (
        <FieldGroup>
          <InlineField>
            <WidgetType
              disabled={readOnly || !isCreative}
              error={shouldValidate && variantFieldHasError(variantKey, 'widgetType')}
              onChangeWidgetType={handleChangeWidgetType}
              required
              value={widgetType}
            />
          </InlineField>

          {creativeConfig.useVariantType && (
            <InlineField>
              <VariantType
                disabled={readOnly}
                error={shouldValidate && variantFieldHasError(variantKey, 'variantType')}
                hasWidgetSubtypes={hasWidgetSubtypes}
                onChangeVariantType={variantType => updateVariantsField(Number(variantKey), 'variantType', variantType)}
                required
                variantType={variantType}
                widgetSubtypes={widgetSubtypes}
                widgetSubtypesError={widgetSubtypesError}
                widgetSubtypesLoading={widgetSubtypesLoading}
              />
            </InlineField>
          )}
        </FieldGroup>
      )}

      {/* TODO: Render loading indicator and error for landingPageTypes */}

      <FieldGroup>
        <InlineField>
          <Autocomplete
            disabled={readOnly}
            error={shouldValidate && variantFieldHasError(variantKey, 'landingPageType')}
            id="landing-page-type-dropdown"
            label="Landing Page Type"
            name="landing-page-type-dropdown"
            onSelect={(suggestion: ISuggestion) => updateVariantsField(Number(variantKey), 'landingPageType', suggestion.id)}
            required
            suggestions={landingPageTypes.map(x => ({ id: x.name, name: x.label }))}
            value={selectedLandingPageType?.label || ''}
          />
        </InlineField>

        <InlineField>
          {selectedLandingPageType?.item_id_required ? (
            <TextField
              disabled={readOnly}
              error={shouldValidate && variantFieldHasError(variantKey, 'itemId')}
              id="id"
              label="ID"
              name="id"
              onChange={handleChangeNumber('itemId')}
              required
              type="number"
              value={itemId || ''}
            />
          ) : (
            <TextField
              disabled={readOnly}
              error={shouldValidate && variantFieldHasError(variantKey, 'url')}
              id="landing-page-url"
              label="Symlink URL"
              name="symlink"
              onChange={handleChangeString('url')}
              onBlur={handleBlurString('url')}
              required
              value={url || ''}
            />
          )}
        </InlineField>
      </FieldGroup>
      <FieldGroup>
        <InlineField>
          <TextField
            id="banner-image-url"
            name="bannerImage"
            label="Banner Image URL"
            required
            value={
              imageUrl
                ? imageUrl.replace('https://s3.ap-northeast-1.amazonaws.com/pai-gm-resources-stg', 'https://pai-gm-stg.paypay-corp.co.jp')
                : imageUrl
            }
            onChange={handleChangeString('imageUrl')}
            onBlur={handleBlurString('imageUrl')}
            error={shouldValidate && variantFieldHasError(variantKey, 'imageUrl')}
          />

          <Message>
            <Pill>URL must start with https:// or http://</Pill>
          </Message>
        </InlineField>
        <InlineField>
          <TextField
            id="alternate-image-url"
            name="alternateImage"
            label="Alternate Image URL"
            value={
              alternateImageUrl
                ? alternateImageUrl.replace(
                    'https://s3.ap-northeast-1.amazonaws.com/pai-gm-resources-stg',
                    'https://pai-gm-stg.paypay-corp.co.jp',
                  )
                : alternateImageUrl
            }
            onChange={handleChangeString('alternateImageUrl')}
            onBlur={handleBlurString('alternateImageUrl')}
            error={shouldValidate && variantFieldHasError(variantKey, 'alternateImageUrl')}
          />

          <Message>
            <Pill>URL must start with https:// or http://</Pill>
          </Message>
        </InlineField>
      </FieldGroup>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    bannerManagement: {
      creative: {
        form: { variants },
      },
    },
  } = state;
  const { variantKey } = ownProps;
  const variant = variants[variantKey];

  return {
    ...mapStateToLandingPageTypes(state),
    ...mapStateToVariantTypes(state),
    ...mapStateToWidgetTypes(state),
    itemId: variant.itemId,
    landingPageType: variant.landingPageType,
    name: variant.name,
    subTitle: variant.subTitle,
    title: variant.title,
    url: variant.url,
    variantType: variant.variantType,
    widgetType: variant.widgetType,
    imageUrl: variant.imageUrl,
    alternateImageUrl: variant.alternateImageUrl,
  };
};

const mapDispatchToProps = {
  updateVariantsField: creativeActions.updateVariantsField,
};

export default compose(withAppConfig, connect(mapStateToProps, mapDispatchToProps))(Fields);

const Container = styled.div``;

const FieldGroup = styled.div`
  margin-bottom: ${sc.gutter};
`;

const InlineField = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 49%;
  margin-right: 2%;

  &:last-child {
    margin-right: 0;
  }
`;

const Pill = styled.span`
  background-color: #eeeeee;
  border: solid 1px #eeeeee;
  color: ${sc.greyDarkest};
  border-radius: 4px;
  display: inline-block;
  justify-self: end;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: justify;
  width: inherit;
`;

const Message = styled.div`
  border-color: ${props => (props.invalid ? sc.danger : sc.greyLighter)};
  display: flex;
  justify-content: 1rem;
  justify-items: flex-start;
`;
