import { withHandlers } from 'recompose';

const errors = {
  duplicateName: 'Cannot upload files with the same name',
  rejected: 'Rejected because not an image file',
  apiFailed: 'Upload API Failed',
};

const registerAcceptedFiles = (acceptedFiles, existingUploads) =>
  acceptedFiles.map(file => {
    if (existingUploads && existingUploads.find(upload => upload.file.name === file.name)) {
      return { file, error: errors.duplicateName };
    }
    return { file, loading: true };
  });

const registerRejectedFiles = rejectedFiles => rejectedFiles.map(file => ({ file, error: errors.rejected }));

const updateUploads = (toUpdate, currUploads) =>
  [...currUploads].map(upload => (upload.file.name === toUpdate.file.name ? toUpdate : upload));

const handleUploadApiSuccess = (file, uploadResult, setFileUploads) =>
  setFileUploads(currUploads => updateUploads({ file, url: uploadResult.url }, currUploads));

const handleUploadApiFailure = (file, error, setFileUploads) => setFileUploads(currUploads => updateUploads({ file, error }, currUploads));

const uploadFile = async (upload, setFileUploads, apiFileUpload) => {
  try {
    const uploadResult = await apiFileUpload(upload.file);
    handleUploadApiSuccess(upload.file, uploadResult, setFileUploads);
  } catch (ex) {
    // The error message is not user friendly (stringified JSON {status, message}) so ignore it for now
    // console.debug('uploadFile Failure', ex);
    handleUploadApiFailure(upload.file, errors.apiFailed, setFileUploads);
  }
};

export const ImageUploadManager = withHandlers({
  handleUpload:
    ({ fileUploads, setFileUploads, apiFileUpload }) =>
    (acceptedFiles, rejectedFiles) => {
      const rejectedUploads = registerRejectedFiles(rejectedFiles);
      const acceptedUploads = registerAcceptedFiles(acceptedFiles, fileUploads);
      setFileUploads([...acceptedUploads, ...rejectedUploads, ...fileUploads]);
      const newUploads = acceptedUploads.filter(s => s.loading);
      newUploads.forEach(upload => uploadFile(upload, setFileUploads, apiFileUpload));
    },
});
