import React from 'react';
import moment from 'moment';
import { prop, equals } from 'ramda';
import styled from 'styled-components';

import { withAppConfig } from 'app/decorators';
import { Details, Header, Label, MainPage, Name, Section, Text } from 'app/features/Campaigns/Overview/common/ChannelDetailsComponents';
import { printPredicateFilter, highlightCriteria } from 'app/features/Campaigns/utils';
import { Grid } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { ICriteria } from 'app/types';
import { triggerDisplayValues, triggerTypes } from 'app/utilities/constants';
import { IAppConfig } from 'configs/apps/types';
import { diffArray } from 'app/helpers/RamdaHelpers/helpers';

import UserCriteria from '../../Cashback/Overview/UserCriteria';
import BannerSpec from '../Creation/BannerSpecs/BannerSpec';
import { ErrorContainer, Title } from '../common/components';
import useBannerCampaignCreative from '../common/useBannerCampaignCreative';
import useStorefrontsBms from '../common/useStorefrontsBms';
import { toTitle } from '../utils';
import { PAYPAY_CONSUMER } from 'configs/apps/tenants/PAYPAY_CONSUMER';
import { getSelectedTenant } from 'configs/user';
import { ChannelStatus } from '../../../components/ChannelStatus/ChannelStatus';

const printLabelText = (label, text, highlight) => (
  <Section data-qa={(label || '').trim()} $highlight={highlight}>
    <Label>{label}</Label>
    <Text data-qa={`${(label || '').trim()}-text`}>{text || 'Not Set'}</Text>
  </Section>
);

const formatDate = date => (date === 'Not set' ? 'Not set' : moment(date).format("Do MMM'YY"));
const formatTime = date => (date === 'Not set' ? 'Not set' : moment(date).format('hh : mm a'));

type Props = {
  appConfig: IAppConfig;
  criteria: ICriteria;
  dedupeStrategy: string;
  scheduling: Partial<{
    expiryDate: string;
    maximumLimit: string;
    miniumInterval: string;
    startDate: string;
    startTime: string;
    validDays: string;
  }>;

  state: string;
  startDate: string;
  startTime: string;
  expiryDate: string;
  triggerType: string;
  originalCampaign?: Record<string, any>;
};

const Overview = ({
  appConfig,
  criteria,
  dedupeStrategy,
  scheduling,
  startDate,
  startTime,
  expiryDate,
  state,
  triggerType,
  originalCampaign,
}: Props) => {
  const { bannerSpecs, error, sourceCreative, targets, bannerRealTimeUserCriteria } = useBannerCampaignCreative();

  const { userCriteria, userCriteriaJson } = bannerRealTimeUserCriteria;

  useStorefrontsBms(appConfig);

  const showBannerSpecs = bannerSpecs?.length > 0;
  const showSource = sourceCreative || targets?.length > 0;
  const hasInvalidBanners = bannerSpecs?.some(b => !!b && !b.id);

  return (
    <MainPage>
      <Header>
        <Name>BANNER</Name>
        <ChannelStatus state={state} />
      </Header>

      <CreativeSection>
        {showSource && !showBannerSpecs && (
          <section>
            <CreativeLabel>Creative : {!!sourceCreative ? toTitle(sourceCreative) : 'Not Set'}</CreativeLabel>

            {targets?.map(
              (target, i) =>
                target && (
                  <Target key={i}>
                    <CreativeLabel>Storefront : {target.storefront ? toTitle(target.storefront) : 'N/A'}</CreativeLabel>

                    <Views>
                      <ViewLabel>Views :</ViewLabel>

                      <CreativeLabel>
                        {(target.views || []).map(view => (
                          <div key={view.id}>{toTitle(view)}</div>
                        ))}
                      </CreativeLabel>
                    </Views>
                  </Target>
                ),
            )}
          </section>
        )}

        {showBannerSpecs && (
          <section>
            <Title>Banners</Title>

            {bannerSpecs?.map(
              spec =>
                !!spec && (
                  <BannerSpec
                    bannerId={spec.id}
                    key={`${spec.id || 'na'}:${spec.viewId || 'na'}:${spec.storefrontId || 'na'}`}
                    storefrontId={spec.storefrontId}
                    viewId={spec.viewId}
                    highlight={
                      originalCampaign?.creative &&
                      diffArray(bannerSpecs, originalCampaign.creative.banners).find(diff => equals(diff, spec))
                    }
                  />
                ),
            )}
          </section>
        )}

        {(error || hasInvalidBanners) && (
          <ErrorContainer>
            {error && <div>{error}</div>}

            {hasInvalidBanners && <div>Check the target view for any failed banners. There may be no active slot.</div>}
          </ErrorContainer>
        )}
      </CreativeSection>

      <Details>
        {/* TODO: Should be extracted to shared across all channels */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {printLabelText(
              'Trigger Type :',
              triggerDisplayValues[triggerType],
              originalCampaign && originalCampaign.triggerType !== triggerType,
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {printLabelText('Dedupe Strategy :', dedupeStrategy, originalCampaign && originalCampaign.dedupeStrategy !== dedupeStrategy)}
          </Grid>

          <Grid item xs={12} sm={6}>
            {printLabelText('Start Date :', formatDate(startDate), originalCampaign && originalCampaign.startDate !== startDate)}
          </Grid>

          <Grid item xs={12} sm={6}>
            {printLabelText(
              'End Date :',
              formatDate(prop('Expiry Date', scheduling)),
              originalCampaign && originalCampaign.expiryDate !== expiryDate,
            )}
          </Grid>

          <Grid item xs={12} sm={12}>
            {printLabelText(
              'Start Time :',
              formatTime(prop('Start Date', scheduling)),
              originalCampaign && originalCampaign.startTime !== startTime,
            )}
          </Grid>

          {triggerType === triggerTypes.eventsBased && (
            <>
              <Grid item xs={12} sm={6}>
                {printLabelText(
                  'Activation Trigger Event: ',
                  criteria?.eventName,
                  originalCampaign &&
                    (originalCampaign.criteria?.eventName || criteria?.eventName) &&
                    originalCampaign.criteria?.eventName !== criteria?.eventName,
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                {printLabelText(
                  'Deactivation Trigger Event: ',
                  criteria?.deactivationEvent?.eventName,
                  originalCampaign &&
                    (originalCampaign.criteria?.deactivationEvent?.eventName || criteria?.deactivationEvent?.eventName) &&
                    originalCampaign.criteria?.deactivationEvent?.eventName !== criteria?.deactivationEvent?.eventName,
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                {printLabelText(
                  'Activation Predicate Filter: ',
                  printPredicateFilter(criteria),
                  originalCampaign &&
                    highlightCriteria({
                      jsonArr: criteria?.predicateFilterObject,
                      str: criteria?.predicateFilter,
                      originalJsonArr: originalCampaign.criteria?.predicateFilterObject,
                      originalStr: originalCampaign.criteria?.predicateFilter,
                    }),
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                {printLabelText(
                  'Deactivation Predicate Filter: ',
                  printPredicateFilter(criteria?.deactivationEvent),
                  originalCampaign &&
                    highlightCriteria({
                      jsonArr: criteria?.deactivationEvent?.predicateFilterObject,
                      str: criteria?.deactivationEvent?.predicateFilter,
                      originalJsonArr: originalCampaign.criteria?.deactivationEvent?.predicateFilterObject,
                      originalStr: originalCampaign.criteria?.deactivationEvent?.predicateFilter,
                    }),
                )}
              </Grid>

              {criteria?.delayTime && (
                <Grid item xs={12} sm={12}>
                  {printLabelText('Trigger in ', `${criteria.delayTime?.value} ${criteria.delayTime?.unit}`)}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Details>
      {getSelectedTenant() === PAYPAY_CONSUMER && (
        <Details>
          <Grid item xs={12} sm={12}>
            <UserCriteria
              userCriteria={userCriteria}
              userCriteriaJson={userCriteriaJson}
              originalCriteria={originalCampaign?.bannerRealTimeUserCriteria}
            />
          </Grid>
        </Details>
      )}
    </MainPage>
  );
};

export default withAppConfig(Overview);

const CreativeSection = styled.section`
  padding: ${sc.gutterLarge};
  padding-bottom: 0;
`;

const CreativeLabel = styled(Label)`
  margin-right: ${sc.gutter};
  min-width: 10rem;
`;

const Target = styled.div`
  display: flex;
  margin-top: ${sc.gutter};
`;

const ViewLabel = styled(Label)`
  margin-right: ${sc.gutterSmallest};
`;

const Views = styled.div`
  display: flex;
`;
