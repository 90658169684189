import { compose, lifecycle, withHandlers, withProps } from 'recompose';
import { emailMediumId } from 'configs/channels/mediumIds';

import { TemplatePage } from './TemplatePage';
import { createSaveProps, enhanceCommon, enhanceCommonRedux } from './common';

const initNewTemplate = lifecycle({
  componentDidMount() {
    this.props.newTemplate();
  },
});

const addCreateProps = withProps(props => createSaveProps('Create Email Template', 'Save Template', props));

const addCreateHandlers = withHandlers({
  handleSave:
    ({ history, createTemplate, fileUploads }) =>
    () =>
      createTemplate(history, emailMediumId, fileUploads),
});

export const BaseCreatePage = compose(enhanceCommon, addCreateProps)(TemplatePage);

export default compose(enhanceCommon, enhanceCommonRedux, addCreateProps, addCreateHandlers, initNewTemplate)(TemplatePage);
