import * as types from './types';

// TODO: Since the ID field is `feature_id`, not `id`, we need to modify the logic of buildLookups
const buildLookups = features =>
  features.reduce(
    (acc, f) => ({
      allIds: [...acc.allIds, f.feature_id],
      byId: {
        ...acc.byId,
        [f.feature_id]: f,
      },
    }),

    { allIds: [], byId: {} },
  );

export const getFeatures = () => ({
  type: types.GET_FEATURES,
});

export const getFeaturesSuccess = (features: Array<Record<string, any>>, paged: Record<string, any> = {}) => ({
  type: types.GET_FEATURES_SUCCESS,
  payload: { paged, featuresLookups: buildLookups(features) },
});

export const getFeaturesFail = (error: Error) => ({
  type: types.GET_FEATURES_FAIL,
  payload: { error },
});
