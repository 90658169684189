import { defaultWhen } from 'ramda-adjunct';

import { PAYPAY_CONSUMER } from 'configs/apps/tenants/PAYPAY_CONSUMER';
import { getSelectedTenant } from 'configs/user';
import getCookie from 'app/utilities/getCookie';
import { tzJapan } from 'configs/dateTime';

import { isNilOrEmpty } from '../RamdaHelpers/helpers';

const userName = () => getCookie('mapUserName');

const activeTenant = () => defaultWhen(isNilOrEmpty, PAYPAY_CONSUMER, getSelectedTenant);

const timezones = {
  [PAYPAY_CONSUMER]: tzJapan,
};

const timeZone = () => timezones[activeTenant()()] || tzJapan;

export { userName, timeZone };
