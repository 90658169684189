const root = 'map/cashbackPromo/banner';

export const GET_ERROR = `${root}/GET_ERROR`;
export const GET_INIT = `${root}/GET_INIT`;
export const GET_SUCCESS = `${root}/GET_SUCCESS`;
export const NEW = `${root}/NEW`;
export const SAVE_END = `${root}/SAVE_END`;
export const SAVE_START = `${root}/SAVE_START`;
export const SET_ADDITIONAL_ATTRIBUTES = `${root}/SET_ADDITIONAL_ATTRIBUTES`;
export const SET_ATTR_FIELD = `${root}/SET_ATTR_FIELD`;
export const SET_FIELD = `${root}/SET_FIELD`;
export const UPDATE_TYPE = `${root}/UPDATE_TYPE`;
