import * as React from 'react';
import { Icon } from 'app/midgarComponents';

import { VariableProps } from './types';
import { VariableContainer, StyledInput, VariableLabel } from './styledComponents';

export const Variable = ({
  removeVariable,
  updateDefault,
  name,
  nested_type,
  defaultValue,
  id,
  data_type: dataType,
  source,
  prefix,
}: VariableProps) => {
  const handleRemove = () => removeVariable({ id: source === 'event' ? name : id, source });
  const handleChange = ({ target: { value } }) => updateDefault({ id: id || name, value, source });

  return (
    <VariableContainer data-qa="variable-container">
      <VariableLabel data-qa="variable-name-text">{prefix ? `${prefix}.${name}` : name}</VariableLabel>
      {!nested_type && (
        <>
          <StyledInput name={name} type={dataType || 'text'} placeholder="Default value" value={defaultValue} onChange={handleChange} />
          <Icon name="delete" onClick={handleRemove} />
        </>
      )}
    </VariableContainer>
  );
};
