import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'ramda';

import { withAppConfig } from 'app/decorators';
import { creativeActions } from 'app/ducks';
import Metadata from 'app/features/BannerManagement/common/Metadata';
import { Icon } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Action } from 'app/types';
import { IVariant, IViewItem } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import AppVersions from './AppVersions';
import EngageCategories from './EngageCategories';
import Fields from './Fields';
import IconLabels from './IconLabels';
import ImagePreview from './ImagePreview';
import Locations from './Locations';
import SchemaMetadata from './SchemaMetadata';
import VariantGeo from './VariantGeo';
import VariantInfoFields from './VariantInfoFields';
import { DrawerError } from 'app/components';

type Props = {
  appConfig: IAppConfig;
  isCreative: boolean;
  loading: boolean;
  parentWidgetType?: string;
  readOnly?: boolean;
  removeVariantGroup?: (key: number) => Action; // Only for new creative
  saveVariant?: (key: number) => (...args: Array<any>) => any; // Only for view item
  saveVariantStatus?: (viewItem: IViewItem, variant: IVariant, status: boolean) => (...args: Array<any>) => any; // Only for view item
  shouldValidate: boolean;
  updateVariantsMetadata: (variantIndex: number, metadata: Record<string, string>) => Action;
  updateVariantsPersonalizationMetadata: (variantIndex: number, metadata: Record<string, string>) => Action;
  variantCategoryFieldHasError: (variantKey: string, categoryKey: string, fieldName: string) => boolean;
  variantCategoryHasErrors: (variantKey: string, categoryKey: string) => boolean;
  variantFieldHasError: (variantKey: string, fieldName: string) => boolean;
  variantHasErrors: (variantKey: string) => boolean;
  variants: Record<string, IVariant>; // Sourced from Redux creative for both creative and view item
  viewItem?: IViewItem; // Only for view item
};

class Variants extends React.PureComponent<Props> {
  creativeConfig = getBannerCreativeConfig(this.props.appConfig);

  renderTopAction(variantKey: string) {
    if (Number.isNaN(Number(variantKey))) {
      console.error(`VariantKey "${variantKey}" is non-numeric. Skipping the topAction of the Variants component`);
      return null;
    }

    const { readOnly, removeVariantGroup, saveVariant, duplicateMetadataKeyFound } = this.props;

    if (removeVariantGroup && this.creativeConfig.useMultipleVariants) {
      return (
        <ActionButton disabled={readOnly} onClick={() => removeVariantGroup(Number(variantKey))}>
          <Icon name="remove" />
        </ActionButton>
      );
    }

    if (saveVariant) {
      return (
        <SaveButton
          disabled={readOnly || duplicateMetadataKeyFound}
          // disabled={readOnly || variantHasErrors(variantKey)}
          onClick={() => saveVariant(Number(variantKey))}
        >
          Update
        </SaveButton>
      );
    }

    return null;
  }

  updatePersonalizationMetadata = (variantKey: string) => (metadata: Record<string, string>) => {
    const { updateVariantsPersonalizationMetadata } = this.props;
    updateVariantsPersonalizationMetadata(variantKey, metadata);
  };

  componentWillUnmount() {
    const { updateDuplicateMetadataFound } = this.props;
    updateDuplicateMetadataFound(false);
  }

  renderDrawerErrors = variants => {
    const errors = [];
    const drawerErrorFunc = lable => <DrawerError error={lable} errorDetails={''} allowDismiss={false} defaultExpanded={false} />;

    if (typeof variants[0].url !== 'undefined' && (!variants[0].url.startsWith('paypay://') || !variants[0].url.includes('paypay://'))) {
      errors.push(drawerErrorFunc("CONFIRM: We suggest symlink URL to be started with 'paypay://'"));
    }

    if (
      typeof variants[0].imageUrl !== 'undefined' &&
      variants[0].imageUrl !== null &&
      !variants[0].imageUrl.startsWith('http://') &&
      !variants[0].imageUrl.startsWith('https://')
    ) {
      errors.push(drawerErrorFunc("CONFIRM: We suggest banner image to start with 'http://' or 'https://'"));
    }

    if (
      typeof variants[0].alternateImageUrl !== 'undefined' &&
      variants[0].alternateImageUrl !== null &&
      !variants[0].alternateImageUrl.startsWith('http://') &&
      !variants[0].alternateImageUrl.startsWith('https://')
    ) {
      errors.push(drawerErrorFunc("CONFIRM: We suggest alternate image to start with 'http://' or 'https://'"));
    }
    return errors;
  };

  render() {
    const {
      isCreative,
      loading,
      parentWidgetType,
      readOnly,
      saveVariantStatus,
      shouldValidate,
      updateVariantsMetadata,
      variantCategoryFieldHasError,
      variantCategoryHasErrors,
      variantFieldHasError,
      variants,
      viewItem,
    } = this.props;

    const variantKeys = Object.keys(variants || {});

    return (
      <Container data-qa="banner-creative-variants" disabled={readOnly}>
        <Heading>Variants</Heading>
        {this.renderDrawerErrors(variants)}
        {variantKeys.map((variantKey: string) => (
          <Group key={`variants-${variantKey}`}>
            <ImageSection>
              <ImagePreview
                readOnly={readOnly}
                shouldValidate={shouldValidate}
                variantFieldHasError={variantFieldHasError}
                variantKey={variantKey}
              />

              <VariantInfoFields
                // TODO: Do we need validation?
                readOnly={readOnly}
                saveVariantStatus={saveVariantStatus}
                variantKey={variantKey}
                viewItem={viewItem}
              />

              <VariantGeo
                // TODO: Do we need validation?
                readOnly={readOnly}
                variantKey={variantKey}
                viewItem={viewItem}
              />
            </ImageSection>

            <ContentSection>
              <Fields
                isCreative={isCreative}
                readOnly={!!readOnly}
                shouldValidate={shouldValidate}
                variantFieldHasError={variantFieldHasError}
                variantKey={variantKey}
              />

              {/* TODO: Add validation */}
              {this.creativeConfig.useLocations && <Locations readOnly={!!readOnly} variantKey={variantKey} />}

              <EngageCategories
                readOnly={!!readOnly}
                shouldValidate={shouldValidate}
                variantCategoryFieldHasError={variantCategoryFieldHasError}
                variantCategoryHasErrors={variantCategoryHasErrors}
                variantKey={variantKey}
              />

              {/* TODO: Add validation */}
              {this.creativeConfig.useAppVersion && <AppVersions readOnly={!!readOnly} variantKey={variantKey} />}

              {/* TODO: Add validation */}
              {this.creativeConfig.usePersonalization && (
                <section data-qa="variant-personalization-metadata">
                  <Metadata
                    metadata={variants[variantKey].personalizationMetadata || {}}
                    readOnly={readOnly}
                    title="Personalization Metadata"
                    updateMetadata={this.updatePersonalizationMetadata(variantKey)}
                    variantKey={variantKey}
                  />
                </section>
              )}

              <section data-qa="variant-metadata">
                <SchemaMetadata
                  loading={loading}
                  metadata={variants[variantKey].metadata || {}}
                  readOnly={readOnly}
                  updateMetadata={updateVariantsMetadata}
                  variantKey={variantKey}
                  widgetType={variants[variantKey].widgetType || parentWidgetType}
                />
              </section>

              <IconLabels
                readOnly={!!readOnly}
                shouldValidate={shouldValidate}
                variantFieldHasError={variantFieldHasError}
                variantKey={variantKey}
              />
            </ContentSection>

            {this.renderTopAction(variantKey)}
          </Group>
        ))}
      </Container>
    );
  }
}

// Always sourced from Redux creative, even for ViewItem.
// The fetchViewItem() Redux operation populates the Redux creative variants.
const mapStateToProps = ({
  bannerManagement: {
    creative: {
      form: { variants, widgetType },
      duplicateMetadataKeyFound,
    },
  },
}) => ({
  parentWidgetType: widgetType,
  variants,
  duplicateMetadataKeyFound,
});

const mapDispatchToProps = {
  updateVariantsMetadata: creativeActions.updateVariantsMetadata,
  updateVariantsPersonalizationMetadata: creativeActions.updateVariantsPersonalizationMetadata,
  updateDuplicateMetadataFound: creativeActions.updateDuplicateMetadataFound,
};

export default compose(withAppConfig, connect(mapStateToProps, mapDispatchToProps))(Variants);

const ActionButton = styled.span`
  display: block;
  position: absolute;

  top: -${sc.gutterSmallest};
  right: -${sc.gutterSmallest};
  padding: 7px;

  color: ${props => (props.disabled ? sc.subHeadingColor : '')};
  border: 1px solid ${sc.sectionBorderColor};
  border-radius: 3px;
  background-color: ${props => (props.disabled ? sc.sectionBackgroundColor : sc.sectionWhiteColor)};

  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  z-index: 40;

  &:hover {
    cursor: pointer;
    border-color: ${sc.primary};
    background-color: ${sc.primary};
    & > .ic-icon {
      color: #fff;
    }
  }
`;

const Container = styled.div`
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const ContentSection = styled.section`
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 200px - ${sc.gutter});
`;

const Group = styled.div`
  border: 1px solid ${sc.sectionBorderColor};
  padding: ${sc.gutter};
  margin-bottom: ${sc.gutter};
  position: relative;
`;

export const Heading = styled.h2`
  padding: ${sc.gutter} 0;
  font-weight: 600;
`;

const ImageSection = styled.section`
  display: inline-block;
  width: 200px;
  margin-right: ${sc.gutter};
`;

export const SaveButton = styled(ActionButton)`
  top: -${sc.gutterSmallest};
  right: -${sc.gutterSmallest};
  padding: ${sc.gutterSmaller} ${sc.gutterSmall};

  color: ${props => (props.disabled ? sc.subHeadingColor : sc.sectionWhiteColor)};
  font-size: 14px;
  font-weight: 600;
  background-color: ${props => (props.disabled ? sc.sectionBackgroundColor : sc.primary)};
  border-color: ${props => (props.disabled ? sc.sectionBorderColor : sc.primary)};

  &:hover {
    border-color: ${sc.secondary};
    background-color: ${sc.secondary};
  }
`;
