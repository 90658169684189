import React, { PureComponent } from 'react';
import { triggerTypes } from 'app/utilities/constants';
import { Grid } from 'app/midgarComponents';
import { equalsArray } from 'app/helpers/RamdaHelpers/helpers';
import { Label, Text, Section } from '../../Campaigns/Overview/common/ChannelDetailsComponents';

const printLabelNumber = (label: string, value: number, highlight?: boolean) => (
  <Section $highlight={highlight}>
    <Label>{label}</Label>
    <Text>{value || value === 0 ? value : 'Not set'}</Text>
  </Section>
);

const printLabelText = (label: string, value: string, highlight?: boolean) => (
  <Section $highlight={highlight}>
    <Label>{label}</Label>
    <Text>{value}</Text>
  </Section>
);

export const formatDays = (days: Array<string>) => {
  if (!Array.isArray(days)) {
    return 'Not Set';
  }

  return days[0] === '*' ? 'Everyday' : days.join(', ');
};

export default class RecurringOverview extends PureComponent<{
  quotaPeriodInDays: number;
  triggerType: string;
  days: Array<string>;
  originalCampaign?: Record<string, any>;
}> {
  render() {
    const { triggerType, quotaPeriodInDays, days, originalCampaign } = this.props;

    if (triggerType !== triggerTypes.oneTime) {
      return (
        <>
          <Grid item xs={12} sm={6}>
            {printLabelNumber(
              'Min interval between two triggers:',
              quotaPeriodInDays,
              originalCampaign &&
                (quotaPeriodInDays || originalCampaign.quotaPeriodInDays) &&
                // eslint-disable-next-line eqeqeq
                quotaPeriodInDays != originalCampaign.quotaPeriodInDays,
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            {printLabelText('Days of Week : ', formatDays(days), originalCampaign && !equalsArray(days, originalCampaign.days))}
          </Grid>
        </>
      );
    }
    return null;
  }
}
