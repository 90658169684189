import * as types from './types';

const updateTemplateManagementFields = payload => ({
  type: types.UPDATE_TEMPLATE_MANAGEMENT_FIELDS,
  payload,
});

const updateCurrentTemplate = template => ({
  type: types.UPDATE_CURRENT_TEMPLATE,
  payload: template,
});

const updateTemplateForm = form => ({
  type: types.UPDATE_TEMPLATE_FORM,
  payload: form,
});

const resetTemplateManagement = () => ({
  type: types.RESET_TEMPLATE_MANAGEMENT,
});

export { updateTemplateManagementFields, updateTemplateForm, updateCurrentTemplate, resetTemplateManagement };
