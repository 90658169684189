import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TableRow, TableCell, Collapse } from '@material-ui/core';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { isNeitherNilNorEmpty, R } from 'app/helpers/RamdaHelpers/helpers';

import { addComponentPropsIf } from 'app/helpers/ComponentHelpers/helpers';

const rowValue = value => (R.isNil(value) ? '' : typeof value === 'boolean' ? String(value) : value);

const toTableCell = R.curry((row, classes, { key, isNumeric, customComponent, style }) => (
  <TableCell
    key={key}
    numeric={isNumeric}
    className={classes.tableCell}
    {...addComponentPropsIf(isNeitherNilNorEmpty(style), { style })}
    padding="normal"
  >
    {isNeitherNilNorEmpty(customComponent) ? customComponent(key, row) : rowValue(row[key])}
  </TableCell>
));

export const ExpandableTableRow = ({
  canExpandRow,
  id,
  row,
  columns,
  handleRowClick,
  handleRowExpansion,
  expanded,
  handleExpandClick,
  classes,
}) => [
  <TableRow
    data-qa="table-row"
    key={id}
    {...addComponentPropsIf(isNeitherNilNorEmpty(handleRowClick), {
      onClick: event => {
        handleRowClick(row, event);
      },
      hover: true,
      style: {
        cursor: 'pointer',
      },
    })}
  >
    {R.map(toTableCell(row, classes), columns)}
    {canExpandRow && (
      <TableCell>
        <ExpandMoreIcon
          className={classnames(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Show more"
        />
      </TableCell>
    )}
  </TableRow>,
  canExpandRow && (
    <Collapse
      key={`${id}-expand`}
      timeout="auto"
      // component={'p'}
      in={expanded}
      unmountOnExit
    >
      {handleRowExpansion(row)}
    </Collapse>
  ),
];

ExpandableTableRow.propTypes = {
  canExpandRow: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleRowClick: PropTypes.func,
  handleRowExpansion: PropTypes.func,
  expanded: PropTypes.bool.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
