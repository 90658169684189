import update from 'immutability-helper';

import { ICampaign } from 'app/types';

// TODO: The backend doesn't support the carousel field, so this temporarily strips the field
export const stripCarousel = (campaign: ICampaign): ICampaign => {
  if (campaign.creative && campaign.creative.type === 'push' && campaign.creative.version === 2) {
    if (campaign.creative.payload && campaign.creative.payload.notification && campaign.creative.payload.notification.content) {
      return update(campaign, {
        creative: {
          payload: { notification: { content: { $unset: ['carousel'] } } },
        },
      });
    }
  }
  return campaign;
};
