import styled from 'styled-components';
import { sc } from 'app/styles';
import _Dropdown from 'app/midgarComponents/Dropdown';
import { Input } from 'app/midgarComponents';

export const DeleteFilter = styled.span`
  position: relative;
  top: -5px;
  left: 5px;
`;

export const VariableSection = styled.section`
  margin-bottom: 2rem;
  h2 {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1rem;
  }
`;

export const VariableContainer = styled.section`
  margin-top: 1rem;
  height: 3rem;
  padding-left: 1rem;

  span {
    display: inline-block;
    margin-right: 1rem;
    &:first-child {
      width: 400px;
      word-break: break-word;
    }
  }
  .remove {
    cursor: pointer;
    position: relative;
    top: ${sc.gutterSmallest};
  }
`;

export const FilterContainer = styled.section`
  background: ${sc.greyLightest};
  padding: 0 1rem 1rem 1rem;
  margin: 0.5rem;
  border: 1px solid ${sc.greyLighter};
`;

export const FilterLabel = styled.h4`
  color: ${sc.greyDark};
  font-size: ${sc.fontSizeSmall};
  padding: 1rem 0 0 0;
`;

export const FilterVariableLabel = styled.h4`
  font-size: ${sc.fontSizeSmall};
  color: ${sc.secondary};
  min-width: 150px;
  padding: 20px 0;
`;

export const StyledInput = styled(Input)`
  width: 200px;
  margin-right: 1rem;
  display: inline-block;
  height: 3rem;
`;

export const VariableLabel = styled.span`
  font-size: ${sc.fontSizeSmall};
  color: ${sc.grey};
  min-width: 100px;
  margin-bottom: 1rem;
`;

export const Dropdown = styled(_Dropdown)`
  width: 150px;
  margin-right: 1rem;
`;

export const FilterRow = styled.div`
  display: inline-flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const Col = styled.div`
  display: inherit;
  width: ${props => `${props.width}%`};
`;

export const SpaceHolder = styled.div`
  min-width: 150px;
`;
