export const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },

  input: {},
  suggestion: {
    display: 'block',
  },

  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 1000,
  },

  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },

  textField: {
    width: '100%',
  },
});
