import React from 'react';
import PropTypes from 'prop-types';

import { joinWithSpace, mapIndexed, R } from 'app/helpers/RamdaHelpers/helpers';

const padding = joinWithSpace(R.repeat('.', 5));

export const StandaloneBreadcrumbLabel = ({ content, classes }) => (
  <ul className={classes.container}>
    {mapIndexed(
      (text, index) => (
        <li key={`-${index}`} className={classes.pathComponent}>
          <span className={classes.pathContent}>{text}</span>
        </li>
      ),

      [content, padding],
    )}
  </ul>
);

StandaloneBreadcrumbLabel.propTypes = {
  content: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};
