import styled from 'styled-components';

export const TableHeaderCell = styled.th`
  line-height: 32px;
  font-size: 12px;
  color: #536d90;
  font-weight: 600;
  vertical-align: middle;
  text-align: left;
  padding-left: 24px;
`;
