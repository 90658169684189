export const styles = theme => ({
  root: {
    width: '90%',
    backgroundColor: 'initial',
    margin: '0 auto',
  },

  button: {
    marginRight: theme.spacing(1),
  },

  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 69,
    padding: '0 1rem',
  },
});
