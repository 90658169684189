import { ILandingPageType } from 'app/types/BannerManagement';

import * as types from './types';

export const getLandingPageTypesFailed = (error: string) => ({
  type: types.GET_LANDING_PAGE_TYPES_FAILED,
  payload: { error },
});

export const getLandingPageTypesStart = () => ({
  type: types.GET_LANDING_PAGE_TYPES_START,
});

export const getLandingPageTypesSuccess = (landingPageTypes: Array<ILandingPageType>) => ({
  type: types.GET_LANDING_PAGE_TYPES_SUCCESS,
  payload: { landingPageTypes },
});
