import { State } from './reducers';

type Store = { bannerManagement: { slotGroupTypes: State } };

export const mapStateToSlotGroupTypes = ({
  bannerManagement: {
    slotGroupTypes: { error: slotGroupTypesError, loading: slotGroupTypesLoading, slotGroupTypes },
  },
}: Store) => ({
  slotGroupTypes,
  slotGroupTypesError,
  slotGroupTypesLoading,
});
