import { ISearchDefs } from 'app/hocs/withReporting';
import { audienceTypes } from 'app/utilities/constants';

export const searchTypeAudienceType = 'type';
export const searchTypeCreatedBy = 'createdby';
export const searchTypeName = 'name';

export const searchDefs: ISearchDefs = [
  { name: searchTypeAudienceType, label: 'By Type', options: [audienceTypes.rule, audienceTypes.csv] },
  { name: 'id', label: 'By Audience ID', type: 'number' },
  { name: searchTypeName, label: 'By Name' },
  { name: 'tag', label: 'By Tag' },
  { name: searchTypeCreatedBy, label: 'By Creator' },
];

export const searchBarDefs: ISearchDefs = searchDefs.filter(def => def.name !== searchTypeAudienceType);

export const defaultSearch = ({ userEmail }: { userEmail: string }) => ({
  [searchTypeAudienceType]: audienceTypes.rule,
  [searchTypeCreatedBy]: userEmail,
});
