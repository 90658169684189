import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Loading from '../Loading';

import { StepperHelpers } from './helpers';

export const MapStepper = ({ classes, steps, activeStep, Component, goBack, goNext, onCancel, onComplete, loading }) => (
  <section style={{ height: '100%' }}>
    <Stepper activeStep={activeStep} classes={{ root: classes.root }}>
      {steps.map(({ label }) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
    <section
      style={{
        minHeight: 'calc(100% - 8.8rem)',
        padding: '0 1rem',
        position: 'relative',
      }}
    >
      <Component />
    </section>
    <section className={classes.actions}>
      <Button onClick={onCancel}>Cancel</Button>
      <section>
        {!StepperHelpers.isFirstStep(activeStep) && (
          <Button className={classes.button} color="primary" onClick={goBack}>
            Back
          </Button>
        )}

        {StepperHelpers.isLastStep(activeStep, steps) ? (
          <Button
            variant="contained"
            color="primary"
            disabled={loading || StepperHelpers.isDisabled(activeStep, steps)}
            onClick={onComplete}
          >
            Create
          </Button>
        ) : (
          <Button variant="contained" disabled={StepperHelpers.isDisabled(activeStep, steps)} color="primary" onClick={goNext}>
            Next
          </Button>
        )}
      </section>
    </section>
    {loading ? (
      <section style={{ position: 'fixed', top: 0, left: 0, height: '100%', width: '100%' }}>
        <Loading />
      </section>
    ) : null}
  </section>
);

MapStepper.propTypes = {
  classes: PropTypes.shape({ button: PropTypes.string }).isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })).isRequired,
  activeStep: PropTypes.number.isRequired,
  Component: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
