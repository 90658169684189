import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { sc } from 'app/styles';

import _MetadataItem from './MetadataItem';

const asArray = (metadata: Record<string, string> | null | undefined) =>
  Object.entries(metadata || {})
    .map(([key, value]) => ({ key, value: String(value) }))
    .sort((a, b) => {
      if (a.key > b.key) {
        return 1;
      }

      if (a.key < b.key) {
        return -1;
      }

      return 0;
    });

const asObject = (items: Array<{ key: string; value: string }> | null | undefined): Record<string, string> =>
  (items || []).reduce((acc, { key, value }) => (key && key.length > 0 ? { ...acc, [key]: value } : acc), {});

type Props = {
  className?: string;
  metadata: Record<string, string>;
  readOnly?: boolean;
  updateMetadata: (metadata: Record<string, string>) => unknown;
};

type State = {
  items: Array<{
    key: string;
    value: string;
  }>;
};

export default class Metadata extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { metadata } = this.props;
    const items = asArray(metadata);
    this.state = {
      items: items.length > 0 ? items : [{ key: '', value: '' }],
    };
  }

  componentDidUpdate(prevProps: Props) {
    // This method ensures that the component is updated when a fetch has completed. It is only needed for opening existing variants.
    // This solution is not a good one, but it works at short notice. A better solution would be to introduce a loading flag.
    const { metadata } = this.props;
    const { items } = this.state;
    if (
      Object.keys(prevProps.metadata || {}).length === 0 &&
      Object.keys(metadata || {}).length > 0 &&
      Object.keys(asObject(items)).length === 0
    ) {
      // Disabled warning because this is a quick fix, and should only happen the first time a variant is retrieved
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        items: asArray(metadata),
      });
    }
  }

  isKeyNameDuplicate = (idx: number) => {
    const { items } = this.state;
    return items.some(({ key }, i, arr) => i !== idx && key === arr[idx].key);
    // TODO: If duplicate key names are problematic, then the "Update" button should be disabled.
    // This would require a field for this purpose in Redux, so it's not worth the complication unless it turns out to be a user issue.
  };

  onAdd = () => {
    const { items } = this.state;
    this.setState({
      items: [...items, { key: '', value: '' }],
    });
  };

  onDelete = (idx: number) => () => {
    const { updateMetadata } = this.props;
    const { items } = this.state;
    const newItems = items.slice();
    newItems.splice(idx, 1);
    this.setState({
      items: newItems,
    });

    updateMetadata(asObject(newItems));
  };

  onChange = (idx: number) => (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const { updateMetadata } = this.props;
    const { items } = this.state;
    const newItems = items.slice();
    newItems[idx][ev.target.name] = ev.target.value;
    this.setState({
      items: newItems,
    });

    updateMetadata(asObject(newItems));
  };

  render() {
    const { className, readOnly = false } = this.props;
    const { items = [] } = this.state;

    return (
      <section className={className}>
        {items.map(({ key, value }, idx) => (
          <MetadataItem
            key={idx} // eslint-disable-line react/no-array-index-key
            name={key}
            nameError={this.isKeyNameDuplicate(idx)}
            onAdd={this.onAdd}
            onChange={this.onChange(idx)}
            onDelete={this.onDelete(idx)}
            readOnly={readOnly}
            showAdd={idx === items.length - 1}
            showDelete={idx < items.length - 1}
            value={String(value)}
          />
        ))}
      </section>
    );
  }
}

const MetadataItem = styled(_MetadataItem)`
  margin-bottom: ${sc.gutterSmaller};
  &:last-child {
    margin-bottom: 0;
  }
`;
