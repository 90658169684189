import { State } from './state';

type Store = {
  campaigns: {
    campaign: {
      bannerCreative: State;
    };
  };
};

export const getBannerCreative = ({
  campaigns: {
    campaign: {
      bannerCreative: {
        banners,
        error,
        source: { creative, targets, validFrom, validUpto },
        valid,
        bannerRealTimeUserCriteria,
      },
    },
  },
}: Store) => ({
  bannerSpecs: banners,
  error,
  sourceCreative: creative,
  targets,
  valid,
  validFrom,
  validUpto,
  bannerRealTimeUserCriteria,
});

export const getUserCriteria = (state: Store) => {
  const {
    bannerRealTimeUserCriteria: { userCriteria },
  } = getBannerCreative(state);
  return userCriteria;
};

export const getUserCriteriaJson = (state: Store) => {
  const {
    bannerRealTimeUserCriteria: { userCriteriaJson },
  } = getBannerCreative(state);
  return userCriteriaJson;
};
