import * as React from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';

import Spinner from 'app/midgarComponents/Spinner';
import _Button from 'app/midgarComponents/Button';

// eslint-disable-next-line import/no-cycle
import withAppConfig from 'app/decorators/withAppConfig';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { sc } from 'app/styles';
import { Tooltip } from 'app/components';
import { IAppConfig } from 'configs/apps/types';

import { IDeliveryChannel } from './deliveryChannels';
import './deliveryChannels';
import { parseRecipients, tooltipText } from './utils';

type Props = {
  appConfig: IAppConfig;
  buttonText?: string;
  className?: string;
  deliveryChannel: IDeliveryChannel;
  disabled?: boolean;
  handleSend: (...args: Array<any>) => any;
  label?: string;
  rowsMax?: number;
};

type State = {
  sending: boolean;
  value: string;
};

class TestDelivery extends React.PureComponent<Props, State> {
  state = {
    sending: false,
    value: '',
  };

  static defaultProps = {
    buttonText: 'Send',
    disabled: false,
    label: `Recipient User ID's`,
    rowsMax: 5,
  };

  handleChange = ev => this.setState({ value: ev.target.value.trim() });

  handleSend = async () => {
    const { appConfig, deliveryChannel, handleSend } = this.props;
    if (handleSend) {
      const { value } = this.state;
      const recipients = parseRecipients(deliveryChannel, appConfig, value);
      if (recipients && recipients.length) {
        this.setState({ sending: true });
        try {
          await handleSend(recipients);
          this.setState({ sending: false });
        } catch (err) {
          displayError(`Failed: ${err.message}`);
        }
      } else {
        displayError('No valid recipients');
      }
    }
  };

  render() {
    const { appConfig, buttonText, className, deliveryChannel, disabled, label, rowsMax } = this.props;

    const { sending, value } = this.state;

    return (
      <section className={className} data-qa="test-delivery">
        <Tooltip placement="bottom" title={tooltipText(deliveryChannel, appConfig)}>
          <TextField disabled={disabled} fullWidth label={label} multiline onChange={this.handleChange} maxRows={rowsMax} value={value} />
        </Tooltip>

        <Button disabled={disabled || sending || !value} onClick={this.handleSend}>
          {sending ? <Spinner /> : buttonText}
          {/* if sending: <Button><Spinner color={sc.sectionWhiteColor} /></Button> */}
        </Button>
      </section>
    );
  }
}

export default withAppConfig(TestDelivery);

const Button = styled(_Button)`
  margin-top: ${sc.gutter};
`;
