import * as React from 'react';
import styled from 'styled-components';

import { DrawerError } from 'app/components';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { IPaging } from 'app/hocs/withReporting';
import { Page, PageHeader, PageMain, Paging, SearchBar as _SearchBar } from 'app/midgarComponents';
import { CreateNewButton, Footer, SearchContainer } from 'app/midgarComponents/ReportingPage';
import { displayWarning } from 'app/helpers/NotificationHelpers/helpers';
import { sc } from 'app/styles';
import { IUser } from 'app/types/User';
import { usersEditPermissions } from 'configs/permissions';
import { USERS_NEW } from 'configs/routes';
import DownloadCSV from '../../../components/DownloadCSV';
import { fetchUsersPaged } from 'app/api/users';
import { searchDefs } from './search.config';
import UserTable from './UserTable';
import moment from 'moment';
import { compose } from 'recompose';
import { withPermissions } from 'app/decorators';

type Props = {
  users: Array<IUser>;
  isLoading: boolean;
  isExporting: boolean;
  isDataLoaded: boolean;
  error: Error;
  goToPage: (arg0: number) => unknown;
  paging: IPaging;
  searchPhrase: string;
  selectedSearchType: string;
  handleSearchPhraseChange: (arg0: string) => void;
  handleSearchTypeChange: (arg0: string) => void;
  pushSearchBar: (arg0: { searchPhrase: string; selectedSearchType: string }) => void;
  isPermitted: IsPermittedFn;
  deleteUser: (arg0: string) => (...args: Array<any>) => any;
};

class Reporting extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef(null);
    this.state = { users: [], isExporting: false, isDataLoaded: false };
  }

  handleKeyDownTextSearch = ev => {
    if (ev.key === 'Enter') {
      const { pushSearch, search } = this.props;
      pushSearch(search);
    }
  };

  async getAllUsers() {
    try {
      const { content } = await fetchUsersPaged({ page: 0, size: 999 });
      this.setState({ users: content });
      return content;
    } catch (err) {
      return [];
    }
  }

  exportUsers = () => {
    const users = this.state.users;
    if (this.state.isExporting) return [];
    this.setState({ isExporting: true });

    if (users.length === 0 && !this.state.isDataLoaded) {
      this.getAllUsers()
        .then(data => {
          this.setState({ isDataLoaded: true });

          const userData = this.transformUserData(data);

          this.setState({ users: userData, isExporting: false });
          this.inputRef.current.click();
          return userData;
        })
        .catch(() => {
          this.setState({ isDataLoaded: true });
          return [];
        });
    } else {
      const userData = this.transformUserData(users);

      this.setState({ users: userData, isExporting: false });
      if (users.length === 0) return displayWarning(`No users found, Please contact Growth Platform team!`);
    }
  };

  transformUserData = users => {
    return users
      .filter(user => {
        return !user.email.includes('PROFILE_');
      })
      .map(item => {
        return {
          ...item,
          storefronts: item.storefrontsAndViews ? this.transformStorefronts(item.storefrontsAndViews) : item.storefronts,
        };
      });
  };

  transformStorefronts = storefronts => {
    let result = '';
    for (const [key, value] of Object.entries(storefronts)) {
      result = result + `${key}: ${value} \n`;
    }
    return result;
  };

  renderButton = () => {
    const CSV_HEADERS = [
      { label: 'Email', key: 'email' },
      { label: 'Permissions', key: 'permissions' },
      { label: 'Storefronts and Views', key: 'storefronts' },
    ];

    return (
      <ButtonContainer>
        <div ref={this.inputRef} onClick={this.exportUsers} id={'export'}>
          <DownloadCSV
            data={this.state.users}
            headers={CSV_HEADERS}
            filename={`PAI_GM_Users_${moment().toISOString()}.csv`}
            linkText={this.state.isExporting ? 'Exporting ...' : 'Export Users'}
          />
        </div>
        <CreateNewButton to={USERS_NEW} />
      </ButtonContainer>
    );
  };

  render() {
    const {
      users,
      isLoading,
      error,
      goToPage,
      paging,
      searchPhrase,
      selectedSearchType,
      handleSearchPhraseChange,
      handleSearchTypeChange,
      pushSearchBar,
      isPermitted,
      deleteUser,
    } = this.props;

    return (
      <Page>
        <PageHeader title="Users">{isPermitted?.(usersEditPermissions) && this.renderButton()}</PageHeader>
        <PageMain>
          <SearchContainer>
            <SearchBar
              searchPhrase={searchPhrase}
              selectedSearchType={selectedSearchType}
              searchTypes={searchDefs}
              handleSearchPhraseChange={handleSearchPhraseChange}
              handleSearchTypeChange={handleSearchTypeChange}
              pushSearchBar={pushSearchBar}
            />
          </SearchContainer>

          {error && error.message && (
            <DrawerError error="Error while retrieving users" errorDetails={error.message} allowDismiss={false} defaultExpanded />
          )}

          <UserTable users={users} isLoading={isLoading} deleteUser={deleteUser} />

          {isLoading ? undefined : (
            <Footer>
              <Paging {...paging} handleGoToPage={goToPage} />
            </Footer>
          )}
        </PageMain>
      </Page>
    );
  }
}

Reporting = compose(withPermissions)(Reporting);

export { Reporting };
const SearchBar = styled(_SearchBar)`
  border-bottom: 1px solid ${sc.sectionBorderColor};
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 315px;
`;
