import * as React from 'react';
import PropTypes from 'prop-types';
import ImageAvatar from '../ImageAvatar';

const defaultPicture = 'https://i1.wp.com/www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png?fit=256%2C256&quality=100&ssl=1';

export const UserInfo = ({ name, picture = defaultPicture, classes }) => (
  <section className={classes.root}>
    <ImageAvatar picture={picture} />
    <p className={classes.name}>{name}</p>
  </section>
);

UserInfo.propTypes = {
  name: PropTypes.string.isRequired,
  picture: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
