import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ICmaStorefront } from 'app/api/storefront/types';
import { getStorefronts } from 'app/ducks/storefrontsCma/storefronts/operations';
import { mapStateToStorefronts } from 'app/ducks/storefrontsCma/storefronts/selectors';

export default (): {
  permittedStorefronts: Array<ICmaStorefront> | null | undefined;
  storefronts: Array<ICmaStorefront> | null | undefined;
  storefrontsError: string;
  storefrontsLoading: boolean;
} => {
  const dispatch = useDispatch();
  const { permittedStorefronts, storefronts, storefrontsError, storefrontsLoading } = useSelector(mapStateToStorefronts);

  useEffect(() => {
    dispatch(getStorefronts());
  }, [dispatch, storefronts, storefrontsError, storefrontsLoading]);

  return {
    permittedStorefronts,
    storefronts,
    storefrontsError,
    storefrontsLoading,
  };
};
