const buildLookups = items => {
  const lookups = { allIds: [], byId: {} };
  items.forEach(item => {
    lookups.allIds.push(item.id);
    lookups.byId[item.id] = item;
  });

  return lookups;
};

export default buildLookups;
