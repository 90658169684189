import PropTypes from 'prop-types';
import { lifecycle, compose } from 'recompose';
import { connect } from 'react-redux';

import * as operations from './operations';
import * as selectors from './selectors';

const mapStateToProps = state => ({
  categories: selectors.categories(state),
  categoriesLoading: selectors.loading(state),
  categoriesError: selectors.error(state),
});

const connectToRedux = connect(mapStateToProps, operations);

const fetchCategories = lifecycle({
  componentDidMount() {
    this.props.getAllCategories();
  },
});

const ConnectedCategories = compose(connectToRedux, fetchCategories);

const CategoriesContainer = ({ children, ...props }) => <>{children && children(props)}</>;

CategoriesContainer.propTypes = {
  children: PropTypes.func,
};

const ConnectedCategoriesContainer = ConnectedCategories(CategoriesContainer);

export { ConnectedCategories, ConnectedCategoriesContainer };
