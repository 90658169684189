import qs from 'query-string';
import * as fetch from 'app/utilities/http';
import { fromApi, toApi } from 'app/dto/template';

import { IQueryParams, ITemplate } from 'app/types';
import { ResultGetAll, ResultGetPaged } from '../types';

import endpoints from './endpoints';

const fetchTemplate = async (id: number): Promise<ITemplate> =>
  fetch.get(endpoints.get.replace(':id', id)).then(template => fromApi(template));

const fetchTemplates = async (params: IQueryParams): ResultGetAll<ITemplate> =>
  fetch.get(`${endpoints.getAll}?${qs.stringify(params)}`).then(templates => templates.map(t => fromApi(t)));

// TODO: Use the paged endpoint when ready
const fetchTemplatesPaged = async (params: IQueryParams): ResultGetPaged<ITemplate> =>
  fetch.get(`${endpoints.getAll}?${qs.stringify(params)}`).then((rawTemplates?: Array<ITemplate>) => {
    const { page: pageIndex, size: rowsPerPage } = params;
    const templates = (rawTemplates || []).map(fromApi);
    // `last` does not catch the case where the last page is a full page of results
    const last = templates.length < rowsPerPage && templates.length > 0;
    // `totalElements` is undefined if we get to the end, and then page backwards. Not worth fixing.
    const totalElements = last ? Number(pageIndex) * Number(rowsPerPage) + templates.length : undefined;
    return {
      content: templates,
      first: pageIndex === 0,
      last,
      number: pageIndex,
      size: rowsPerPage,
      totalElements,
    };
  });

// TODO: During creation, the tagIds property is set correctly, so does not call reduxToApi() here. However, reduxToApi() should be called.
const postTemplate = async (template: ITemplate): Promise<{ id: number }> => fetch.post(endpoints.post, template);

const putTemplate = async (template: ITemplate): Promise<{ id: number }> =>
  fetch.put(endpoints.put.replace(':id', template.id), toApi(template));

export { fetchTemplate, fetchTemplates, fetchTemplatesPaged, postTemplate, putTemplate };
