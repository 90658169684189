import styled from 'styled-components';

const Item = styled.section`
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 600px) {
    width: ${props => (props.xs / 12) * 100}%;
  }
  @media screen and (min-width: 600px) {
    width: ${props => (props.sm / 12) * 100}%;
  }
  @media screen and (min-width: 768px) {
    width: ${props => (props.md / 12) * 100}%;
  }
  @media screen and (min-width: 992px) {
    width: ${props => (props.lg / 12) * 100}%;
  }
  @media screen and (min-width: 1200px) {
    width: ${props => (props.xl / 12) * 100}%;
  }
`;

const Container = styled.section`
  display: flex;
  flex-wrap: ${props => props.wrap};
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justifyContent || 'inherit'};
  > section {
    padding: 0 0 ${props => props.spacing * 10}px 0;
  }
`;

export { Container, Item };
