// TODO: NOT USED

import PropTypes from 'prop-types';
import { lifecycle, compose } from 'recompose';
import { connect } from 'react-redux';

import * as operations from './operations';
import * as selectors from './selectors';

const mapStateToProps = state => ({
  templates: selectors.templates(state),
  templatesById: selectors.byId(state),
  templatesLoading: selectors.loading(state),
  templatesError: selectors.error(state),
});

const connectToRedux = connect(mapStateToProps, operations);

const fetchTemplates = lifecycle({
  componentDidMount() {
    this.props.getTemplates();
  },
});

const ConnectedTemplates = compose(connectToRedux, fetchTemplates);

const TemplatesContainer = ({ children = null, ...props }) => <>{children && children(props)}</>;

TemplatesContainer.propTypes = {
  children: PropTypes.func,
};

const ConnectedTemplatesContainer = ConnectedTemplates(TemplatesContainer);

export { ConnectedTemplates, ConnectedTemplatesContainer };
