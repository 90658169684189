import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { ICampaignAttributeValue } from 'app/features/CashbackPromoManagement/types';
import { sc } from 'app/styles';

import _TypedAttributeItem from './TypedAttributeItem';

type Props = {
  attributes: Array<ICampaignAttributeValue>;
  className?: string;
  readOnly?: boolean;
  shouldValidate?: boolean;
  updateAttribute?: (arg0: ICampaignAttributeValue) => any;
  updateAttributes?: (arg0: Array<ICampaignAttributeValue>) => any;
};

export default class TypedAttributes extends PureComponent<Props> {
  handleChange = (att: ICampaignAttributeValue) => {
    const { updateAttribute, updateAttributes, attributes } = this.props;

    if (updateAttribute) {
      updateAttribute(att);
    }

    if (updateAttributes) {
      const newAttributes = attributes.map(item => (item.id === att.id ? att : item));
      updateAttributes(newAttributes);
    }

    if (!updateAttribute && !updateAttributes) {
      // eslint-disable-next-line no-console
      console.error('TypedAttributes: At least one of updateAttribute and updateAttributes must be provided');
    }
  };

  render() {
    const { attributes, className, readOnly, shouldValidate } = this.props;

    return (
      <section className={className}>
        {attributes.map(item => (
          <TypedAttributeItem
            key={`typed-attribute-${item.id}`}
            attribute={item}
            handleChange={this.handleChange}
            readOnly={readOnly}
            shouldValidate={shouldValidate}
          />
        ))}
      </section>
    );
  }
}

const TypedAttributeItem = styled(_TypedAttributeItem)`
  margin-bottom: ${sc.gutterSmaller};
  &:last-child {
    margin-bottom: 0;
  }
`;
