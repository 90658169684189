import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose';
import { connect } from 'react-redux';

import { featureSelectors, audienceOperations, audienceSelectors } from 'app/ducks';
import { getSuccess } from 'app/ducks/segments2/segment/actions';

import { New } from './New';
import { validate } from './validation';
import { enhanceSegment, toAudienceTypeState } from '../utils';

const mapStateToProps = state => {
  const audience = audienceSelectors.readMe(state);
  return {
    audience,
    loading: audience.loading,
    featureDicts: featureSelectors.metaData(featureSelectors.readMeV2(state)),
  };
};

const mapDispatchToProps = {
  cloneSegment: audienceOperations.cloneSegment,
  newSegment: audienceOperations.newSegment,
  postSegment: audienceOperations.postSegment,
  resetSegment: audienceOperations.resetSegment,
  setSegment: audience => dispatch => dispatch(getSuccess(audience)),
};

const addState = withState('audienceType', 'setAudienceType', ({ audienceType }) => audienceType);

const addValidation = withProps(({ audienceType, audience }) => validate(audience, audienceType));

const addHandlers = withHandlers({
  handleCreate:
    ({ history, postSegment }) =>
    () =>
      postSegment(history),
});

const initStore = lifecycle({
  async componentDidMount() {
    const {
      history: {
        location: { state },
      },
    } = this.props;
    if (state) {
      const { id, audience } = state;
      const { featureDicts } = this.props;
      if (audience) {
        this.props.setSegment(enhanceSegment(audience, featureDicts));
        this.props.setAudienceType(toAudienceTypeState(audience.audienceType));
      } else if (id !== undefined) {
        await this.props.cloneSegment(id, featureDicts);
        this.props.setAudienceType(toAudienceTypeState(this.props.audience.audienceType));
      }
    } else {
      this.props.newSegment();
    }
  },
  componentWillUnmount() {
    this.props.resetSegment();
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), addState, addValidation, addHandlers, initStore);

export default enhance(New);
