import styled from 'styled-components';
import sc from 'app/styles/config';
import { statusColors } from '../../../../components/ChannelStatus/ChannelStatus';

export const MainPage = styled.section`
  border: 1px solid #eeeeee;
  border-radius: 0.375rem;
`;

export const Header = styled.section`
  height: 50px;
  padding: 0 1.5625rem;
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled.p`
  font-size: 1em;
  font-weight: bold;
  color: rgba(41, 57, 79, 0.9);
  margin-left: 0.125rem;
`;

export const StateChip = styled.section`
  width: 5rem;
  height: 26px;
  text-align: center;
  font-size: 0.7rem;
  font-weight: bold;
  background: ${props => statusColors[props.state]};
  color: #fff;
  border-radius: 0.625rem;
  padding: 0.4375rem 0;
  margin-left: 0.625rem;
`;

export const Details = styled.section`
  display: flex;
  padding: 1.5625rem;
`;

export const LeftColumn = styled.section`
  width: 66%;
`;

export const RightColumn = styled.section`
  width: 34%;
  border: 1px solid #eeeeee;
  padding: 1rem;
`;

export const Label = styled.section`
  color: #536d90;
  font-size: 0.875em;
`;

export const Text = styled.span`
  color: #536d90;
  font-size: 0.875em;
  margin-left: 0.4375rem;
`;

export const Section = styled.section`
  display: flex;
  align-items: center;
  background: ${({ $highlight }) => ($highlight ? sc.lightGreen : 'transparent')};
`;
