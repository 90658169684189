import styled from 'styled-components';
import { sc } from 'app/styles';
import { Link } from 'react-router-dom';
import { Dropzone as _Dropzone, Page as _Page, PageMain as _PageMain, PageHeader } from 'app/midgarComponents';

export const Content = styled.section`
  min-height: 150px;
  max-height: 500px;
  font-size: ${sc.fontSizeSmall};
  padding: ${sc.gutterSmall} ${sc.gutterLarge};
  overflow-y: scroll;
`;

export const DateGroup = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 49.5%;
  margin-right: 1%;

  &:last-child {
    margin: 0;
  }
`;

export const Dropzone = styled(_Dropzone)`
  padding: 0;
  width: 100%;
  height: 55px;
  overflow: hidden;
  padding: ${sc.gutterSmaller};

  cursor: pointer;
  transition: all 150ms linear;

  border: 1px dashed ${sc.greyLight};
  border-radius: 4px;

  & > section {
    margin: 0;
    padding: 0;
    height: 100%;
  }
`;

export const ErrorContainer = styled.div`
  color: ${sc.danger};
  font-style: italic;
`;

export const Header = styled.section`
  background-color: ${sc.sectionBackgroundColor};
  font-weight: bold;
  font-size: ${sc.fontSize};
  padding: ${sc.gutterSmall} ${sc.gutterLarge};
`;

export const HeaderTitle = styled.div`
  font-size: ${sc.fontSizeLarger};
  font-weight: 500;

  & > p {
    font-size: ${sc.fontSizeSmall};
    margin-bottom: ${sc.gutterSmallest};
    color: ${sc.subHeadingColor};

    &:first-child {
      font-size: ${sc.fontSizeLarge};
      color: ${sc.bodyColor};
    }
  }

  & > span {
    display: block;
    font-size: ${sc.fontSizeSmaller};
    color: ${sc.subHeadingColor};
    padding: 2px 0;
  }
`;

export const HeaderTitleLink = styled(Link)`
  font-size: ${sc.fontSizeLarger};
  font-weight: 500;
  color: ${sc.bodyColor};

  &:hover {
    cursor: pointer;
    color: ${sc.primary};
  }

  & > p {
    font-size: ${sc.fontSize};
    color: ${sc.subHeadingColor};
    margin-bottom: ${sc.gutterSmallest};

    &:first-child {
      font-size: ${sc.fontSizeLarge};
      color: ${sc.bodyColor};
    }
    &:hover {
      cursor: pointer;
      color: ${sc.primary};
    }
  }
`;

export const Label = styled.span`
  font-weight: bold;
`;

export const Page = styled(_Page)`
  background-color: ${sc.sectionWhiteColor};
  height: 100%;
`;

export const PageMain = styled(_PageMain)`
  padding: 0;
  background-color: ${sc.sectionWhiteColor};
`;

export const Separator = styled.div`
  position: relative;
  height: 1px;
  margin-bottom: ${sc.gutter};
  background-color: ${sc.sectionBorderColor};

  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 0 ${sc.gutterSmall};
    background-color: #fff;

    color: ${sc.subHeadingColor};
    font-size: ${sc.fontSizeSmaller};
    text-transform: uppercase;
  }
`;

export const StickyHeader = styled(PageHeader)`
  position: sticky;
  top: 56px;
  z-index: 100;
`;

export const Text = styled.span`
  display: block;
  padding: 2px 0;
  font-size: ${sc.fontSizeSmaller};
  font-weight: ${props => (props.dark ? 'bold' : 'normal')};
  color: ${props => (props.dark ? sc.headingColor : sc.subHeadingColor)};

  & > span {
    display: block;
  }
`;

export const TextContent = styled.span`
  margin-left: ${sc.gutterSmall};
  color: ${sc.subHeadingColor};
`;

export const VariantErrorMessage = styled.p`
  color: ${sc.danger};
`;
