import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { connectToRedux as connectTemplateToRedux } from 'app/ducks/templates/template';
import { msgDeliveryFailed, msgDeliverySuccess } from 'app/components/TestDelivery/utils';
import { ApiImageUpload, ImageUploadManagerState, asReplacements, replaceImagesHTML } from 'app/helpers/ImageUploadManager';
import { ConnectedEmailSenders } from 'app/ducks/emailSenders';
import postDeliver, { minimalEmailCampaign } from 'app/api/campaigns/testDelivery';
import { displaySuccess } from 'app/helpers/NotificationHelpers/helpers';

const addProps = withProps(({ emailSenders }) => ({
  emailSenders: emailSenders.map(sender => ({ id: sender.id, name: sender.address })),
}));

const connectUser = connect(({ user: { currentTenant } }) => ({ env: currentTenant }));

const testDeliveryHandler = withHandlers({
  handleTestDelivery:
    ({ fileUploads, setError, template }) =>
    async targetEmails => {
      const campaign = minimalEmailCampaign({
        subject: template.subject,
        sender: template.sendFrom,
        senderName: template.sendFromName,
        replyTo: template.replyTo,
        replyToName: template.replyToName,
        html: replaceImagesHTML(template.content, asReplacements(fileUploads)),
      });

      try {
        const result = await postDeliver(campaign, targetEmails);
        displaySuccess(msgDeliverySuccess(result));
      } catch (error) {
        const msgError = msgDeliveryFailed(error);
        setError(msgError.error, msgError.errorDetails);
      }
    },
});

const dismissErrorHandler = withHandlers({
  handleDismissError:
    ({ clearError }) =>
    () =>
      clearError && clearError(),
});

const enhanceCommon = compose(ImageUploadManagerState);

const enhanceCommonRedux = compose(
  connectTemplateToRedux,
  ConnectedEmailSenders,
  connectUser,
  ApiImageUpload,
  addProps,
  testDeliveryHandler,
  dismissErrorHandler,
);

const validateProps = ({ template }) =>
  !template.name ||
  !template.description ||
  !template.content ||
  !template.subject ||
  !template.sendFrom ||
  !template.sendFromName ||
  !template.tags ||
  template.tags.length === 0;

const createSaveProps = (pageTitle, saveButtonLabel, props) =>
  validateProps(props)
    ? { pageTitle, saveButtonLabel, saveButtonTooltip: 'All fields are required', saveButtonDisabled: true }
    : { pageTitle, saveButtonLabel };

export { createSaveProps, enhanceCommon, enhanceCommonRedux };
