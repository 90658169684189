import * as React from 'react';
import PropTypes from 'prop-types';
import { sc } from 'app/styles';

import styled from 'styled-components';

import Tab from './Tab';

const TabContainer = styled.section`
  height: 40px;
  display: flex;
  border-bottom: 1px solid ${sc.sectionBorderColor};
  margin-bottom: ${sc.gutterSmall};
`;

export const Tabs = ({ tabs, value, onChange, spacing = 2.5, className, ...props }) => (
  <TabContainer className={className}>
    {tabs.map((t, i) => (
      <Tab {...props} key={t.label} selected={value === i} value={i} handleChange={onChange} spacing={spacing}>
        {t.label}
      </Tab>
    ))}
  </TabContainer>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  spacing: PropTypes.number,
  className: PropTypes.string,
};
