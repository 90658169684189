import * as React from 'react';
import { map } from 'ramda';
import { Row } from './Row';
import { EmptyTableBody } from './EmptyTableBody';
import { LoadingTable } from './LoadingTable';
import { TableBody } from '../Table';

type Props = {
  rows: Array<{}>;
  rowsLoading: boolean;
  columns: Array<{}>;
  msgIfEmpty?: string;
  [key: string]: unknown;
};

const renderTableRow = props => row => <Row key={row.bannerId || row.id} {...props} row={row} />;

export const Body = ({ rows, rowsLoading, columns, msgIfEmpty, ...props }: Props) => (
  <TableBody>
    {rowsLoading && <LoadingTable columns={columns} {...props} />}
    {!rows.length && !rowsLoading ? <EmptyTableBody columns={columns} msg={msgIfEmpty} /> : null}
    {rows.length && !rowsLoading ? map(renderTableRow({ ...props, columns }))(rows) : null}
  </TableBody>
);
