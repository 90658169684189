export default theme => ({
  header: {
    position: 'relative',
  },

  tabs: {
    position: 'absolute',
    top: '-4px',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },

  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
});
