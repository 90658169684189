import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Typography, TextField } from '@material-ui/core';

import { Search } from '@material-ui/icons';

import { isNeitherNilNorEmpty } from 'app/helpers/RamdaHelpers/helpers';

export const TableTitleWithSearchBar = ({ title, isFiltered, handleFilter, classes }) => (
  <Toolbar className={classes.root}>
    <div
      className={classes.title}
      style={{
        alignItems: 'flex-end',
        display: 'flex',
      }}
    >
      <Typography variant="h6">{title}</Typography>
      {isFiltered ? (
        <Typography
          variant="caption"
          style={{
            marginLeft: '0.2rem',
          }}
        >
          <i>(filtered)</i>
        </Typography>
      ) : undefined}
    </div>
    {isNeitherNilNorEmpty(handleFilter) ? (
      <>
        <div className={classes.spacer} />
        <div
          className={classes.actions}
          style={{
            alignItems: 'flex-end',
            display: 'flex',
          }}
        >
          <Search
            style={{
              marginRight: '0.5rem',
            }}
          />

          <TextField id="search" label="Search" type="search" margin="dense" onChange={handleFilter} className={classes.textField} />
        </div>
      </>
    ) : undefined}
  </Toolbar>
);

TableTitleWithSearchBar.propTypes = {
  title: PropTypes.string.isRequired,
  isFiltered: PropTypes.bool.isRequired,
  handleFilter: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
