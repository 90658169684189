const UPDATE_PUSH2_TYPE = 'map/campaigns/push2/UPDATE_PUSH2_TYPE';
const UPDATE_PUSH2_FIELD = 'map/campaigns/push2/UPDATE_PUSH2_FIELD';
const UPDATE_PUSH2_CONTEXT = 'map/campaigns/push2/UPDATE_PUSH2_CONTEXT';
const UPDATE_PUSH2_CONTENT = 'map/campaigns/push2/UPDATE_PUSH2_CONTENT';
const RESET_PUSH2_NOTIFICATION_FIELDS = 'map/campaigns/push2/RESET_PUSH2_NOTIFICATION_FIELDS';
const UPDATE_PUSH2_OVERRIDES = 'map/campaigns/push2/UPDATE_PUSH2_OVERRIDES';
const UPDATE_PLATFORM = 'map/campaigns/push2/UPDATE_PLATFORM';
const RESET_DEEPLINK = 'map/campaigns/push2/RESET_DEEPLINK';
const UPDATE_PUSH2_DEEPLINK = 'map/campaigns/push2/UPDATE_PUSH2_DEEPLINK';
const UPDATE_PUSH2_LEGACY_DEEPLINK = 'map/campaigns/push2/UPDATE_PUSH2_LEGACY_DEEPLINK';
const UPDATE_PUSH2_DEEPLINK_EXTRA = 'map/campaigns/push2/UPDATE_PUSH2_DEEPLINK_EXTRA';
const ADD_INTERACTIVE = 'map/campaigns/push2/ADD_INTERACTIVE';
const UPDATE_INTERACTIVE = 'map/campaigns/push2/UPDATE_INTERACTIVE';
const REMOVE_INTERACTIVE = 'map/campaigns/push2/REMOVE_INTERACTIVE';
const ADD_EXTRA_CONTEXT = 'map/campaigns/push2/ADD_EXTRA_CONTEXT';
const UPDATE_EXTRA_CONTEXT = 'map/campaigns/push2/UPDATE_EXTRA_CONTEXT';
const REMOVE_EXTRA_CONTEXT = 'map/campaigns/push2/REMOVE_EXTRA_CONTEXT';
const UPDATE_OVERRIDES_ANDROID = 'map/campaigns/push2/UPDATE_OVERRIDES_ANDROID';
const UPDATE_OVERRIDES_IOS = 'map/campaigns/push2/UPDATE_OVERRIDES_IOS';
const ADD_CAROUSEL_IMAGE = 'map/campaigns/push2/ADD_CAROUSEL_IMAGE';
const SET_CAROUSEL_IMAGE = 'map/campaigns/push2/SET_CAROUSEL_IMAGE';
const REMOVE_CAROUSEL_IMAGE = 'map/campaigns/push2/REMOVE_CAROUSEL_IMAGE';

export {
  UPDATE_PUSH2_TYPE,
  UPDATE_PUSH2_FIELD,
  UPDATE_PUSH2_CONTENT,
  UPDATE_PUSH2_CONTEXT,
  UPDATE_PUSH2_OVERRIDES,
  UPDATE_PLATFORM,
  RESET_DEEPLINK,
  UPDATE_PUSH2_DEEPLINK,
  UPDATE_PUSH2_LEGACY_DEEPLINK,
  UPDATE_PUSH2_DEEPLINK_EXTRA,
  ADD_INTERACTIVE,
  UPDATE_INTERACTIVE,
  REMOVE_INTERACTIVE,
  ADD_EXTRA_CONTEXT,
  UPDATE_EXTRA_CONTEXT,
  REMOVE_EXTRA_CONTEXT,
  UPDATE_OVERRIDES_ANDROID,
  UPDATE_OVERRIDES_IOS,
  ADD_CAROUSEL_IMAGE,
  SET_CAROUSEL_IMAGE,
  REMOVE_CAROUSEL_IMAGE,
  RESET_PUSH2_NOTIFICATION_FIELDS,
};
