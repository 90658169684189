import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchBanners as apiFetchBanners } from 'app/api/storefront';
import { ICmaBanner } from 'app/api/storefront/types';
import { mapStateToStorefronts } from 'app/ducks/storefrontsCma/storefronts/selectors';

export default (
  storefrontId?: number,
  viewId?: number,
): {
  banners: Array<ICmaBanner>;
  bannersError: string | null | undefined;
  bannersLoading: boolean;
} => {
  const [banners, setBanners] = useState([]);
  const [bannersError, setBannersError] = useState(null);
  const [bannersLoading, setBannersLoading] = useState(false);
  const { storefronts } = useSelector(mapStateToStorefronts);

  const prevState: { current: { storefrontId?: number; viewId?: number } } = useRef({});
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchBanners = async (storefrontId: number, entityId: number, viewId: number) => {
      setBanners([]);
      setBannersError(null);
      setBannersLoading(true);

      try {
        const banners: Array<ICmaBanner> = await apiFetchBanners(viewId, storefrontId, entityId);
        setBanners(banners);
        setBannersError(null);
        setBannersLoading(false);
      } catch (err) {
        setBanners([]);
        setBannersError(err.message);
        setBannersLoading(false);
      }
    };

    const prevStorefrontId = prevState.current.storefrontId;
    const prevViewId = prevState.current.viewId;

    if (storefrontId !== prevStorefrontId || viewId !== prevViewId) {
      if (storefrontId && viewId) {
        if (storefronts?.length > 0) {
          const storefront = storefronts?.find(s => s.id === storefrontId);
          if (storefront) {
            fetchBanners(storefront.id, storefront.entityAssociatedWith, viewId);
          } else {
            setBanners([]);
            setBannersError(`Access denied to StorefrontId ${storefrontId}`);
            setBannersLoading(false);
          }

          prevState.current.storefrontId = storefrontId;
          prevState.current.viewId = viewId;
        }
      } else {
        setBanners([]);
        setBannersError(null);
        setBannersLoading(false);
      }
    }
  }, [dispatch, storefronts, storefrontId, viewId]);

  return {
    banners,
    bannersError,
    bannersLoading,
  };
};
