import qs from 'query-string';

import { IQueryParams } from 'app/types';
import { get, post } from 'app/utilities/http';
import endpoints from './endpoints';

export const getSegment = (id: number) => get(endpoints.getSegment.replace(':id', id));

export const downloadSegment = (id: number) => post(endpoints.downloadSegment.replace(':id', String(id)));

export const getSegments = (params: IQueryParams) => get(endpoints.getSegments.replace(':params', qs.stringfy(params)));

export const getSegmentsPaged = (params: IQueryParams) => get(endpoints.getSegmentsPaged.replace(':params', qs.stringify(params)));

export const getSegmentMaxSizeLimit = () => get(endpoints.getSegmentMaxLimit);
