import * as React from 'react';
import { StyledLabel } from './styledComponents';
import { Props } from './types';

export const Label = ({ children, ...rest }: Props) => (
  <StyledLabel {...rest}>
    <span className="label-text">{children[0]}</span>
    {children[1]}
  </StyledLabel>
);
