// TODO: Should import from a common location, but these come through as undefined, so define them here

export const channelNameEmail = 'Email';
export const channelNamePush2 = 'Push2';
export const channelNameTimelineNotification = 'Notification_Center';

export type IDeliveryChannel = 'Email' | 'Push2' | 'Notification_Center';

// Use naive email validation
export const validateEmail = (s: string) => /^\S+@\S+\.\S+$/.test(s);

export const validateNumber = (s: string) => /^\d+$/.test(s);

export const validateString = (s: string) => !!s && s.trim().length > 0;

export const deliveryChannels: Record<
  IDeliveryChannel,
  { validator: (arg0: string) => boolean; tooltipText: string | ((arg0: string | null | undefined) => string) }
> = {
  // [channelNameEmail]: {
  //   validator: validateEmail,
  //   tooltipText: 'Enter multiple email addresses of the form xxxxx@xxx.xxx, separated by commas or new lines',
  // },
  [channelNameEmail]: {
    validator: validateString,
    tooltipText: dataType => `Enter multiple ${dataType || ''} recipient user ID's separated by commas or new lines`,
  },

  [channelNamePush2]: {
    validator: validateString,
    tooltipText: dataType => `Enter multiple ${dataType || ''} recipient user ID's separated by commas or new lines`,
  },

  [channelNameTimelineNotification]: {
    validator: validateString,
    tooltipText: dataType => `Enter multiple ${dataType || ''} recipient IDs separated by commas`,
  },
};
