import * as api from 'app/api/segments';
import { Action, DispatchFn, IQueryParams } from 'app/types';

import * as actions from './actions';

export const getAllSegments =
  (params: IQueryParams) =>
  async (dispatch: DispatchFn): Action => {
    dispatch(actions.getAll());
    try {
      const segments = await api.getSegments(params);
      return dispatch(actions.getAllSuccess(segments));
    } catch (e) {
      return dispatch(actions.getAllFail(e));
    }
  };

export const getDownloadStatus =
  (id: number) =>
  async (dispatch: DispatchFn): Action => {
    dispatch(actions.checkDownloadStatus(id));
    try {
      const { state, urls: downloadUrls } = await api.downloadSegment(id);
      dispatch(actions.checkDownloadStatusSuccess({ state, downloadUrls, id }));
    } catch (e) {
      dispatch(actions.checkDownloadStatusError(id));
    }
  };

// TODO: getSegmentsPaged does not use Redux, it should be moved outside of /ducks
export const getSegmentsPaged = (params: IQueryParams) => async () => {
  try {
    const res = await api.getSegmentsPaged(params);
    return res.content;
  } catch (err) {
    // TODO: Display error in UI
    return null;
  }
};

export const getAllSegmentsPaged =
  (params: IQueryParams) =>
  async (dispatch: DispatchFn): Action => {
    dispatch(actions.getAll());
    try {
      const { first, last, number, totalElements, size, content: segments } = await api.getSegmentsPaged(params);

      return dispatch(
        actions.getAllSuccess(segments, {
          first,
          last,
          number,
          size,
          totalElements,
        }),
      );
    } catch (e) {
      return dispatch(actions.getAllFail(e));
    }
  };

export const fetchMoreSegments =
  () =>
  async (dispatch: DispatchFn, getState: (...args: Array<any>) => any): Action => {
    const {
      segments: {
        segments: { allIds, byId, number: page, size: pageSize },
      },
    } = getState();
    const currentSegments = allIds.map(id => byId[id]);
    const params = { type: 'rule', page: page + 1, size: pageSize };

    dispatch(actions.loadMore());

    try {
      const { number, first, last, totalElements, size, content } = await api.getSegmentsPaged(params);

      return dispatch(
        actions.loadMoreSuccess(currentSegments.concat(content), {
          number,
          first,
          last,
          totalElements,
          size,
        }),
      );
    } catch (err) {
      return dispatch(actions.loadMoreFail(err));
    }
  };
