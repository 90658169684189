import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';

import { Dropdown } from 'app/midgarComponents';

type Props = {
  value: string | null;
  onChangeType: (...args: Array<any>) => any;
  options?: Record<string, any>;
};

export default class NotificationCategories extends React.PureComponent<Props> {
  render() {
    const { value, onChangeType, options } = this.props;

    return (
      <Wrapper data-qa="timeline-notification-expiration">
        <Box>
          <StyledDropdown
            label="Categories"
            name="Categories"
            value={options[value]?.displayName || undefined}
            options={Object.values(options)}
            onChange={onChangeType}
          />
        </Box>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: -webkit-box;
  margin-bottom: 1rem;

  & .MuiTextField-root {
    border: 1px solid ${sc.greyLighter};
    padding: 1rem;
  }
`;

const StyledDropdown = styled(Dropdown)`
  width: 20rem;
`;
