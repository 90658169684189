import * as React from 'react';
import styled from 'styled-components';

import _Button from 'app/midgarComponents/Button';
import _Icon from 'app/midgarComponents/Icon';

import { sc } from 'app/styles';

import { IPaging } from 'app/hocs/withReporting';

type Props = Partial<
  IPaging & {
    handleGoToPage: (arg0: number) => unknown;
  }
>;

type State = {
  pageNum: string;
};

const getTotalPages = ({ rowsPerPage, totalRows }) =>
  totalRows !== undefined && totalRows >= 0 ? Math.max(Math.ceil(totalRows / rowsPerPage), 1) : undefined;

const pageIndexToNum = (pageIndex: number): string => String(pageIndex + 1).valueOf();

export default class Paging extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pageNum: pageIndexToNum(props.pageIndex),
    };
  }

  handleInputChange: (...args: Array<any>) => any = (ev: Event) => {
    const newValue = ev.target.value;
    this.setState({ pageNum: newValue });
  };

  handleInputKeyUp: (...args: Array<any>) => any = (ev: Event) => {
    const { pageIndex } = this.props;

    if (ev.key === 'Escape') {
      this.setState({ pageNum: pageIndexToNum(pageIndex) });
    } else if (ev.key === 'Enter') {
      let { pageNum: pageNumStr } = this.state;
      const pageNum = Number(pageNumStr);
      if (Number.isFinite(pageNum)) {
        const totalPages = getTotalPages(this.props);
        if (totalPages !== undefined && pageNum > totalPages) {
          pageNumStr = String(totalPages).valueOf();
          this.setState({ pageNum: pageNumStr });
        } else if (pageNum <= 0) {
          pageNumStr = '1';
          this.setState({ pageNum: pageNumStr });
        }
        this.pushPageNum(pageNumStr);
      } else {
        this.setState({ pageNum: pageIndexToNum(pageIndex) });
      }
    }
  };

  handleClickNext: (...args: Array<any>) => any = () => {
    const { pageNum: prevPageNum } = this.state;
    const newPageNum = String(Number(prevPageNum).valueOf() + 1).valueOf();
    this.setState({ pageNum: newPageNum });
    this.pushPageNum(newPageNum);
  };

  handleClickPrev: (...args: Array<any>) => any = () => {
    const { pageNum: prevPageNum } = this.state;
    const newPageNum = String(Number(prevPageNum).valueOf() - 1).valueOf();
    this.setState({ pageNum: newPageNum });
    this.pushPageNum(newPageNum);
  };

  pushPageNum(pageNum: string) {
    const { handleGoToPage } = this.props;
    handleGoToPage(Number(pageNum).valueOf() - 1);
  }

  render() {
    const { pageNum: pageNumStr } = this.state;
    const pageNum = Number(pageNumStr).valueOf();
    const { first, last, rowsPerPage, totalRows } = this.props;
    const totalPages = getTotalPages({ rowsPerPage, totalRows });

    return (
      <Section>
        <Button disabled={first || pageNum <= 1} onClick={this.handleClickPrev}>
          <Icon name="chevron-left" color={sc.primary} />
        </Button>

        <Input value={pageNumStr} onFocus={ev => ev.target.select()} onChange={this.handleInputChange} onKeyUp={this.handleInputKeyUp} />

        <Button disabled={last || (totalPages && pageNum >= totalPages)} onClick={this.handleClickNext}>
          <Icon name="chevron-right" />
        </Button>

        <TotalPages>of {totalPages || 'N/A'}</TotalPages>
      </Section>
    );
  }
}

const commonStyles = `
  height: 2rem;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: normal;
  padding: 0;
`;

const Icon = styled(_Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Input = styled.input`
  ${commonStyles}
  border: 1px solid ${sc.primary};
  width: 3rem;
  text-align: center;
`;

const Button = styled(_Button).attrs(() => ({ type: 'secondary' }))`
  ${commonStyles};
  box-shadow: none;
  border-radius: 0;
  width: 2rem;
  position: relative;
  &:hover,
  &:focus,
  &:active {
    & > .ic-icon {
      color: ${props => (props.disabled ? '' : sc.sectionWhiteColor)};
    }
  }
`;

const Section = styled.section`
  display: flex;
`;

const TotalPages = styled.div`
  ${commonStyles};
  padding-left: 0.5rem;
  width: 5rem;
`;
