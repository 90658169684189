import * as types from './types';

export const getGaTypesFailed = (error: string) => ({
  type: types.GET_GA_TYPES_FAILED,
  payload: { error },
});

export const getGaTypesStart = () => ({
  type: types.GET_GA_TYPES_START,
});

export const getGaTypesSuccess = (gaTypes: Array<string>) => ({
  type: types.GET_GA_TYPES_SUCCESS,
  payload: { gaTypes },
});
