import acls from './acls';
import bannerManagement from './bannerManagement';
import campaigns from './campaigns';
import cashbackPromoConfig from './cashbackPromo';
import categories from './categories';
import debug from './debug';
import deeplinks from './deeplinks';
import emailSenders from './emailSenders';
import features from './features';
import featuresSimple from './featuresSimple';
import interactives from './interactives';
import masterplan from './masterplan';
import segments from './segments';
import audience from './segments2';
import storefrontsBms from './storefrontsBms';
import storefrontsCma from './storefrontsCma';
import tags from './tags';

// This is just email templates, will be merged to templateManagement in the future
import templates from './templates';

import user from './user';
import users from './users';
import templateManagement from './templateManagement/reducers';
import config from './configStore';

export * from './acls';
export * from './bannerManagement';
export * from './campaigns';
export * from './cashbackPromo';
export * from './categories';
export * from './debug';
export * from './deeplinks';
export * from './emailSenders';
export * from './features';
export * from './featuresSimple';
export * from './interactives';
export * from './masterplan';
export * from './segments';
export * from './segments2';
export * from './storefrontsCma';
export * from './tags';
export * from './templates';
export * from './user';
export * from './users';

export default {
  acls,
  bannerManagement,
  campaigns,
  cashbackPromoConfig,
  categories,
  debug,
  deeplinks,
  emailSenders,
  features,
  featuresSimple,
  interactives,
  masterplan,
  segments,
  audience,
  storefrontsBms,
  storefrontsCma,
  tags,
  templates,
  templateManagement,
  user,
  users,
  config,
};
