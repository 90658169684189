import * as React from 'react';
import styled from 'styled-components';

type Props = {
  text: string;
  highlight?: string;
};

export default ({ text, highlight }: Props) => {
  const re = highlight ? new RegExp(`(${highlight})`, 'gi') : undefined;

  return !!text && <>{text.split(re).map((t, i) => (i % 2 === 0 ? <span key={`${t}-${i}`}>{t}</span> : <Highlight>{t}</Highlight>))}</>;
};

const Highlight = styled.span`
  font-weight: bold;
  font-style: italic;
`;
