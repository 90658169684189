import { NEW_CAMPAIGN, SET_PUSH2_CREATIVE } from 'app/ducks/campaigns/campaign/types';
import { addItem, replacetItemAtIndex, removeItemAtIndex } from 'app/ducks/helpers';
import { deviceTypeValues, expiryTypes, pushTypes } from 'src/app/features/Push2/Creation/Push2Constants';
import { deeplinkType } from 'app/features/Push2/constants';
import * as types from './types';
import validate from './validation';

export const initialState = {
  type: 'push', // push, flash, inbox, big_picture
  version: '2', // Update this when data model changes.
  templated: false,
  merchantRole: null,
  site_id: '',
  deeplinkType: '',
  sites: [],
  platforms: [], // android, iOS
  payload: {
    notification: {
      extras: [],
      content: {
        title: '',
        body: '',
        carousel: [],
      },

      interactive: {
        buttons: [],
        click: {
          type: deeplinkType.standard, // deep_link or noop.
          value: '',
          legacyDeeplink: {
            options: {},
          },
        },
      },
    },

    context: {
      utm_medium: 'push',
      utm_content: '',
      utm_source: '',
    },
  },

  overrides: {
    android: {
      expiry: null,
      subtext: '',
      icon_src: '',
    },

    ios: {
      priority: null,
    },
  },

  valid: false,
  isValidCarousel: true,
  sendCampaignTo: null,
};

export const initialBigPicture = {
  ...initialState,
  type: 'big_picture',
  payload: {
    ...initialState.payload,
    notification: {
      ...initialState.payload.notification,
      content: {
        ...initialState.payload.notification.content,
        img_source: '',
        summary: '',
      },
    },
  },

  overrides: {
    android: {
      expiry: null,
      subtext: '',
      summary: '',
    },

    ios: {},
  },
};

export const initialInboxState = {
  ...initialState,
  type: 'inbox',
  platforms: [deviceTypeValues.android.value, deviceTypeValues.ios.value],
  payload: {
    ...initialState.payload,
    notification: {
      ...initialState.payload.notification,
      content: {
        ...initialState.payload.notification.content,
        icon_source: '',
        expiry: null, // epoch
        expiry_type: expiryTypes.datetime,
      },
    },
  },

  overrides: {},
};

export const initialFlashState = {
  ...initialState,
  type: 'flash',
  platforms: [deviceTypeValues.android.value, deviceTypeValues.ios.value],
  payload: {
    ...initialState.payload,
    notification: {
      ...initialState.payload.notification,
      content: {
        ...initialState.payload.notification.content,
        position: 'top', // top | bottom
        duration: null, // in seconds
        expiry: null, // epoch
        expiry_type: expiryTypes.datetime,
      },
    },
  },

  overrides: {},
};

const addOrRemove = (state, arr) => value => ({
  ...state,
  [arr]: state[arr].includes(value) ? state[arr].filter(d => d !== value) : [...state[arr], value],
});

const push2Creative = (state = initialState, { type, payload }) => {
  switch (type) {
    case NEW_CAMPAIGN:
      return initialState;

    case SET_PUSH2_CREATIVE:
      return {
        ...state,
        ...payload.creative,
      };

    case types.UPDATE_PUSH2_FIELD:
      return {
        ...state,
        ...payload,
      };

    case types.UPDATE_PLATFORM:
      return addOrRemove(state, 'platforms')(payload);

    case types.ADD_INTERACTIVE:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            interactive: {
              ...state.payload.notification.interactive,
              buttons: addItem({ type: 'webview', value: '' })(state.payload.notification.interactive.buttons),
            },
          },
        },
      };

    case types.REMOVE_INTERACTIVE:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            interactive: {
              ...state.payload.notification.interactive,
              buttons: removeItemAtIndex(state.payload.notification.interactive.buttons, payload),
            },
          },
        },
      };

    case types.UPDATE_INTERACTIVE:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            interactive: {
              ...state.payload.notification.interactive,
              buttons: replacetItemAtIndex(payload.value, payload.index)(state.payload.notification.interactive.buttons),
            },
          },
        },
      };

    case types.UPDATE_PUSH2_TYPE: {
      const pushTypeStates = {
        [pushTypes.inbox.id]: initialInboxState,
        [pushTypes.bigPicture.id]: initialBigPicture,
        [pushTypes.push.id]: initialState,
      };

      return pushTypeStates[payload.value]
        ? {
            ...pushTypeStates[payload.value],
            site_id: state.site_id,
            deeplinkType: state.deeplinkType,
            templated: state.templated,
            sites: state.sites,
          }
        : initialState;
    }
    case types.UPDATE_PUSH2_CONTEXT:
      return {
        ...state,
        payload: {
          ...state.payload,
          context: {
            ...state.payload.context,
            ...payload,
          },
        },
      };

    case types.UPDATE_EXTRA_CONTEXT:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            extras: replacetItemAtIndex(
              { ...state.payload.notification.extras[payload.index], ...payload.obj },
              payload.index,
            )(state.payload.notification.extras),
          },
        },
      };

    case types.ADD_EXTRA_CONTEXT:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            extras: addItem({})(state.payload.notification.extras),
          },
        },
      };

    case types.REMOVE_EXTRA_CONTEXT:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            extras: removeItemAtIndex(state.payload.notification.extras, payload),
          },
        },
      };

    case types.UPDATE_PUSH2_CONTENT:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            content: {
              ...state.payload.notification.content,
              ...payload,
            },
          },
        },
      };

    case types.RESET_PUSH2_NOTIFICATION_FIELDS:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            content: initialState.payload.notification.content,
            interactive: {
              ...state.payload.notification.interactive,
              click: {
                ...state.payload.notification.interactive.click,
                type: deeplinkType.standard,
                value: '',
              },
            },
          },
        },
      };

    case types.RESET_DEEPLINK:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            interactive: {
              ...state.payload.notification.interactive,
              click: {
                ...initialState.payload.notification.interactive.click,
              },
            },
          },
        },
      };

    case types.UPDATE_PUSH2_DEEPLINK:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            interactive: {
              ...state.payload.notification.interactive,
              click: {
                ...state.payload.notification.interactive.click,
                value: payload,
              },
            },
          },
        },
      };

    case types.UPDATE_PUSH2_LEGACY_DEEPLINK:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            interactive: {
              ...state.payload.notification.interactive,
              click: {
                ...state.payload.notification.interactive.click,
                legacyDeeplink: { ...payload },
              },
            },
          },
        },
      };

    case types.UPDATE_PUSH2_DEEPLINK_EXTRA:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            interactive: {
              ...state.payload.notification.interactive,
              click: {
                ...state.payload.notification.interactive.click,
                legacyDeeplink: {
                  ...state.payload.notification.interactive.click.legacyDeeplink,
                  options: {
                    ...state.payload.notification.interactive.click.legacyDeeplink.options,
                    ...payload,
                  },
                },
              },
            },
          },
        },
      };

    case types.UPDATE_OVERRIDES_ANDROID:
      return {
        ...state,
        overrides: {
          ...state.overrides,
          android: {
            ...state.overrides.android,
            ...payload,
          },
        },
      };

    case types.UPDATE_OVERRIDES_IOS:
      return {
        ...state,
        overrides: {
          ...state.overrides,
          ios: {
            ...state.overrides.ios,
            ...payload,
          },
        },
      };

    case types.ADD_CAROUSEL_IMAGE:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            content: {
              ...state.payload.notification.content,
              carousel: [...state.payload.notification.content.carousel, payload],
            },
          },
        },
      };

    case types.SET_CAROUSEL_IMAGE:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            content: {
              ...state.payload.notification.content,
              carousel: replacetItemAtIndex(payload.image, payload.index)(state.payload.notification.content.carousel),
            },
          },
        },
      };

    case types.REMOVE_CAROUSEL_IMAGE:
      return {
        ...state,
        payload: {
          ...state.payload,
          notification: {
            ...state.payload.notification,
            content: {
              ...state.payload.notification.content,
              carousel: removeItemAtIndex(state.payload.notification.content.carousel, payload),
            },
          },
        },
      };

    default:
      return state;
  }
};

export default (state = initialState, { type, payload }) => validate(push2Creative(state, { type, payload }));
