export const styles = {
  root: {
    width: '100%',
    position: 'relative',
    padding: '1rem',
    paddingTop: 0,
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },

  variablesContainer: {
    margin: '1rem 0',
  },

  templateTooltip: {
    marginRight: '1rem',
  },

  templateSelect: {
    width: '25rem',
    margin: '0 2rem',
  },
};
