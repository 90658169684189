import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouterHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import { DrawerError } from 'app/components';
import { withAppConfig, withPermissions } from 'app/decorators';
import { storefrontsOperations } from 'app/ducks';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { IPaging, IPagingSelectorFunction } from 'app/hocs/withReporting';
import withReporting from 'app/hocs/withReporting';
import withDropdown from 'app/hocs/withReporting/withDropdown';
import withSearchBar from 'app/hocs/withReporting/withSearchBar';
import { Page, PageHeader, PageMain, Paging, SearchBar, Spinner, Dropdown } from 'app/midgarComponents';
import { CreateNewButton, Footer, SearchContainer } from 'app/midgarComponents/ReportingPage';
import { IQueryParams } from 'app/types';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';
import { bannerManagementEditPermissions } from 'configs/permissions';

import { urlStorefrontNew } from '../../routeUtils';
import { defaultSearch, searchBarDefiner, searchByEntityType, searchDefiner } from './search.config';
import StorefrontTable from './StorefrontTable';
import { compose } from 'recompose';

const pagingSelector: IPagingSelectorFunction = ({
  bannerManagement: {
    storefronts: { paging },
  },
}) => paging;

const SearchByEntityTypeBase = withDropdown(searchByEntityType)(Dropdown);

type Props = {
  appConfig: IAppConfig;
  error: string;
  history: RouterHistory;
  isPermitted: IsPermittedFn;
  loading: boolean;
  storefronts: Array<Record<string, any>>;
  permittedStorefronts: Record<string, any>;
  isSuperAdmin: boolean;

  // withReporting properties
  paging: IPaging;
  goToPage: (arg0: number) => unknown;
  search: IQueryParams;
  searchPhrase: string;
  msgIfEmpty?: string;
  selectedSearchType: string;
  handleSearchPhraseChange: (...args: Array<any>) => any;
  handleSearchTypeChange: (...args: Array<any>) => any;
  pushSearchBar: (...args: Array<any>) => any;
  pushSearch: (...args: Array<any>) => any;
};

const mapStateToProps = ({
  bannerManagement: {
    storefronts: { error, loading, storefronts },
  },

  user: { storefrontsAndViews: permittedStorefronts, isSuperAdmin },
}) => ({
  error,
  loading,
  storefronts,
  permittedStorefronts,
  isSuperAdmin,
  defaultQueryParams: { rowsPerPage: 200 },
});

const mapDispatchToProps = (dispatch: Dispatch<{ type: string }>, { appConfig }: { appConfig: IAppConfig }) => {
  const { bmsVersion } = getBannerCreativeConfig(appConfig);

  return {
    fetchStorefronts: params => dispatch(storefrontsOperations.getStorefronts(bmsVersion)(params)),
  };
};

class Storefronts extends PureComponent<Props> {
  render() {
    const {
      appConfig,
      error,
      isPermitted,
      loading,
      storefronts,
      permittedStorefronts,

      paging,
      goToPage,
      search,
      searchPhrase,
      selectedSearchType,
      msgIfEmpty,
      handleSearchPhraseChange,
      handleSearchTypeChange,
      pushSearch,
      pushSearchBar,
      isSuperAdmin,
    } = this.props;

    const { useStorefrontCreate, useStorefrontType } = getBannerCreativeConfig(appConfig);

    const { entityType } = search;

    const filteredStorefronts = isSuperAdmin ? storefronts : storefronts.filter(storefront => !!permittedStorefronts[storefront.id]);

    return (
      <Page>
        <PageHeader title="Storefronts">
          {useStorefrontCreate && isPermitted(bannerManagementEditPermissions) && <CreateNewButton to={urlStorefrontNew()} />}
        </PageHeader>

        <PageMain>
          <div>
            {useStorefrontType && <SearchByEntityType search={search} pushSearch={pushSearch} />}
            <Search>
              <SearchBar
                searchPhrase={searchPhrase}
                selectedSearchType={selectedSearchType}
                searchTypes={searchBarDefiner(this.props)}
                handleSearchPhraseChange={handleSearchPhraseChange}
                handleSearchTypeChange={handleSearchTypeChange}
                pushSearchBar={pushSearchBar}
              />
            </Search>
          </div>

          {error ? (
            <DrawerError allowDismiss={false} defaultExpanded error="Could Not Retrieve Storefronts" errorDetails={error} />
          ) : loading ? (
            <Spinner />
          ) : (
            <>
              <StorefrontTable entityType={entityType} msgIfEmpty={msgIfEmpty} storefronts={filteredStorefronts} />

              <Footer data-qa="banner-storefronts-footer">
                <Paging {...paging} handleGoToPage={goToPage} />
              </Footer>
            </>
          )}
        </PageMain>
      </Page>
    );
  }
}

export default compose(
  withAppConfig,
  withPermissions,
  connect(mapStateToProps, mapDispatchToProps),
  withReporting('fetchStorefronts', pagingSelector, {
    defaultSearch,
    searchDefiner,
  }),

  withSearchBar(searchBarDefiner),
)(Storefronts);

const Search = styled(SearchContainer)`
  display: inline-block;
  width: calc(100% - 200px);
  vertical-align: top;
`;

const SearchByEntityType = styled(SearchByEntityTypeBase)`
  display: inline-block;
  width: 200px;
  vertical-align: top;
`;
