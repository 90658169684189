import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { withAppConfig } from 'app/decorators';
import { fetchLocations as opFetchLocations } from 'app/ducks/bannerManagement/locations/operations';
import { mapStateToLocations } from 'app/ducks/bannerManagement/locations/selectors';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';

const mapDispatchToProps = {
  fetchLocations: opFetchLocations,
};

export default compose(
  withAppConfig,
  connect(mapStateToLocations, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { appConfig, cities, fetchLocations, locationsLoading, states } = this.props;

      const { useLocations } = getBannerCreativeConfig(appConfig);

      if (useLocations && !(cities || []).length && !(states || []).length && !locationsLoading) {
        fetchLocations();
      }
    },
  }),
);
