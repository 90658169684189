import * as React from 'react';
import styled from 'styled-components';

import { withPermissions } from 'app/decorators';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { FullTable, Spinner, Button } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IBannerCreative } from 'app/types/BannerManagement';
import { bannerManagementEditPermissions } from 'configs/permissions';

import { urlCreativeClone, urlCreativeEdit } from '../../routeUtils';
import { getVariantTypes, getWidgetTypes } from '../../utils';
import PreviewColumn from '../PreviewColumn';
import { formatTimestamp } from '../helpers';
import { Text } from '../styles';

const tableColumns = (
  timezone: string,
  onSelect?: (...args: Array<any>) => any,
  openPreviewModal?: (...args: Array<any>) => any,
  isPermitted: IsPermittedFn,
) => {
  const genTableColumn = (label, key, customComponent) => ({ key, label, customComponent });

  const renderImageColumn = (_, row) => <PreviewColumn row={row} openPreviewModal={openPreviewModal} />;

  const renderNameAndID = (_, { id, name }) => (
    <>
      <Text dark>{name}</Text>

      <Text>{`Creative ID: ${id}`}</Text>
    </>
  );

  const renderSite = (_, { site }) => (
    <Text>
      {site.map(x => (
        <span key={`site-${x}`}>{x}</span>
      ))}
    </Text>
  );

  const renderPlatform = (_, { platform }) => (
    <Text>
      {platform.map(x => (
        <span key={`platform-${x}`}>{x}</span>
      ))}
    </Text>
  );

  const renderWidgetType = (_, creative) => {
    const widgetTypes = getWidgetTypes(creative);
    const variantTypes = getVariantTypes(creative);

    return (
      <>
        {widgetTypes.map(w => (
          <Text key={w}>{w}</Text>
        ))}

        {variantTypes.length > 0 && <Text>Variants: {variantTypes.join(', ')}</Text>}
      </>
    );
  };

  const renderCreatedOn = (_, { createdAt, createdBy }) => (
    <>
      <Text dark>{formatTimestamp(createdAt, timezone) || 'N/A'}</Text>
      <Text>{createdBy || 'N/A'}</Text>
    </>
  );

  const renderAction = (_, row) =>
    onSelect && typeof onSelect === 'function' ? (
      <Button type="secondary" onClick={() => onSelect && onSelect(row)}>
        Select
      </Button>
    ) : (
      <ButtonsContainer>
        <Button type="secondary" data-qa="creatives-edit-button" to={urlCreativeEdit(row.id)}>
          View
        </Button>

        {isPermitted(bannerManagementEditPermissions) && (
          <Button data-qa="creatives-clone-button" type="secondary" to={urlCreativeClone(row.id)}>
            Clone
          </Button>
        )}
      </ButtonsContainer>
    );

  return [
    genTableColumn('Preview', 'preview', renderImageColumn),
    genTableColumn('Name/Id', 'nameAndId', renderNameAndID),
    genTableColumn('Widget Type', 'widgetType', renderWidgetType),
    genTableColumn('Site', 'site', renderSite),
    genTableColumn('Platform', 'platform', renderPlatform),
    genTableColumn('Created on', 'createdOn', renderCreatedOn),
    genTableColumn('Action', 'action', renderAction),
  ];
};

type Props = {
  bannerCreatives: Array<IBannerCreative>;
  isPermitted: IsPermittedFn;
  loading: boolean;
  onSelect?: (...args: Array<any>) => any;
  openPreviewModal?: (...args: Array<any>) => any;
  timezone: string;
};

class CreativesTable extends React.PureComponent<Props> {
  render() {
    const { bannerCreatives, loading, timezone, onSelect, openPreviewModal, isPermitted } = this.props;

    return loading ? (
      <Spinner />
    ) : (
      <FullTable rows={bannerCreatives} columns={tableColumns(timezone, onSelect, openPreviewModal, isPermitted)} rowEditable={false} />
    );
  }
}

export default withPermissions(CreativesTable);

const ButtonsContainer = styled.div`
  & > a {
    display: block;
    margin-bottom: ${sc.gutterSmallest};
  }
`;
