export const styles = theme => ({
  iconRow: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '0.5rem',
  },

  input: {
    margin: '0 0 0 0.5rem',
    minWidth: '216px',
  },

  tooltip: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
  },
});
