import { compose, withHandlers, withProps } from 'recompose';
import { dissoc } from 'ramda';
import { creatorMy, creatorAll } from 'app/midgarComponents/OptionsSwitch/CreatedBySwitch';

import { IQueryParams } from 'app/types';

type Props = {
  pushSearch: (arg0: IQueryParams) => void;
  search: IQueryParams;
  userEmail: string;
};

export default (searchCreatedByName: string) =>
  compose(
    withProps(({ search, userEmail }: Props) => ({
      value: search[searchCreatedByName] === userEmail ? creatorMy : creatorAll,
    })),

    withHandlers({
      handleSelectAll:
        ({ pushSearch, search }: Props) =>
        () =>
          pushSearch(dissoc(searchCreatedByName, search)),
      handleSelectMy:
        ({ pushSearch, search, userEmail }: Props) =>
        () =>
          pushSearch({ ...search, [searchCreatedByName]: userEmail }),
    }),
  );
