import styled from 'styled-components';

export const StyledLabel = styled.label`
  width: 100%;
  .label-text {
    display: inline-block;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
`;
