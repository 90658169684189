import styled from 'styled-components';
import { sc } from 'app/styles';

export const Title = styled.p`
  color: ${sc.grey};
  font-weight: bold;
  margin-bottom: 0.3rem;
`;

export const Sub = styled.span`
  color: ${sc.grey};
  size: 12px;
  line-height: 1.5;
  display: inline-block;
  margin-right: 1rem;
`;
