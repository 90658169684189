export const SITE_PAYTM = 'PAYTM';
export const SITE_ID_PAYTM = 1;

export const SITE_PAYTM_MALL = 'PAYTM_MALL';
export const SITE_ID_PAYTM_MALL = 2;

export const APP_SITES: Array<{ id: number; label: string }> = [
  { id: SITE_ID_PAYTM, label: SITE_PAYTM },
  { id: SITE_ID_PAYTM_MALL, label: SITE_PAYTM_MALL },
];

export const CLIENT_ANDROID = 'ANDROID';
export const CLIENT_IOS = 'IOS';
export const APP_CLIENT_NAMES: Array<string> = [CLIENT_ANDROID, CLIENT_IOS];

// From creativeEditor/AppVersionGroup:

// const sites = [
//   { id: 1, label: 'PAYTM' },
//   { id: 2, label: 'PAYTM MALL' },
// ];

// const clients = [
//   { id: 'ios', label: 'IOS' },
//   { id: 'android', label: 'ANDROID' },
// ];

// From creativeEditor/AppVersions:
