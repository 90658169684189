import { combineReducers, legacy_createStore as createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import rootReducer from 'app/ducks';

const isProduction = process.env.NODE_ENV === 'production';

const createRootReducer = history => combineReducers({ ...rootReducer, router: connectRouter(history) });

export const configureStore = history => {
  const middleWare = isProduction
    ? applyMiddleware(thunk, routerMiddleware(history))
    : composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)));

  return createStore(createRootReducer(history), {}, middleWare);
};
