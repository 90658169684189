import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { withAppConfig } from 'app/decorators';
import { sc } from 'app/styles';
import { IAppConfig } from 'configs/apps/types';
import { IEvent } from 'app/types';
import { IFeature } from 'app/api/features/types';

class Personalization extends PureComponent<{
  appConfig: IAppConfig;
  variables: {
    events: Array<IEvent>;
    features: Array<IFeature>;
  };
}> {
  static defaultProps = {
    appConfig: {},
    variables: [],
  };

  // CMA is not saving filter rules in proper JSON format. Fix it then
  renderFilterRules = filterRules => {
    return <></>;
    // return <Text>{JSON.stringify(filterRules)}</Text>;
  };

  renderVariable = variables => {
    return (variables || []).map((v, indx) => (
      <section key={indx}>
        <Label>{v.name}</Label>
        {v.filterRules ? this.renderFilterRules(v.filterRules) : <Text>{v.defaultValue}</Text>}
      </section>
    ));
  };

  render() {
    const {
      variables: { events, features },
    } = this.props;

    return (
      <>
        <SectionLabel>Personalization Variables</SectionLabel>
        {this.renderVariable(events)}
        {this.renderVariable(features)}
      </>
    );
  }
}

export default withAppConfig(Personalization);

const Label = styled.section`
  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmall};
`;

const SectionLabel = styled.section`
  margin: 1rem 0;
  font-size: ${sc.fontSize};
  color: ${sc.grey};
`;

const Text = styled.span`
  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmall};
  margin-left: 0.438rem;
`;
