import { filter, map } from 'ramda';
/*
 * payload = Object with one or more keys.
 *           Each key has a value which is a primitive string or number.
 *           E.g. { a: 'X', b: 'Y', c: 9 }
 *
 * These payload values are added to the state under the corresponding keys.
 * (The existing value in the state must be an array.)
 *
 * If a value is already present in the state, it is not added again.
 * If a key is not present in the state, a new key is created whose value is an array of containing a single item (the payload value).
 *
 * Example:
 * payload = { a: 'X', b: 'Y', c: 9 }
 * state   = { a: ['A', 'B'], c: [1, 2, 3] }
 *
 * Result = { a: ['A', 'B', 'X'], b: ['Y'], c: [1, 2, 3, 9]}
 */
export const appendSingleValueToArrays = (payload, state) => {
  const entries = Object.entries(payload || {});
  if (entries.length === 0) {
    return state;
  }
  const modifiedEntries = entries.map(entry => {
    const key = entry[0];
    const newVal = entry[1];
    const newValsSet = new Set(state[key] || []).add(newVal);
    const setArray = Array.from(newValsSet);
    return { [key]: [...setArray] };
  });
  return Object.assign({}, state || {}, ...modifiedEntries);
};

/*
 * payload = Object with one or more keys.
 *           Each key has a value which is a primitice string or number.
 *           E.g. { a: 'X', b: 'Y', c: 9 }
 *
 * If the payload values are present under the given keys in the state, they are removed.
 * If the key or the value is not present in the state, that value is not removed from the state.
 *
 * Example:
 * payload = { a: 'A', b: 'Y', c: 9 }
 * state   = { a: ['A', 'B'], c: [1, 2, 3] }
 *
 * Result = { a: [B'], c: [1, 2, 3]}
 */
export const removeSingleValueFromArrays = (payload, state) => {
  const entries = Object.entries(payload || {});
  if (entries.length === 0) {
    return state;
  }
  const prunedEntries = entries.filter(entry => (state[entry[0]] || []).length > 0);
  const modifiedEntries = prunedEntries.map(entry => ({
    [entry[0]]: (state[entry[0]] || []).filter(thisVal => thisVal !== entry[1]),
  }));

  return Object.assign({}, state || {}, ...modifiedEntries);
};

export const addItem = x => xs => [...xs, x];
export const replacetItemAtIndex = (x, index) => xs => xs.map((item, i) => (i === index ? x : xs[i]));
export const removeItemAtIndex = (xs, index) => xs.filter((x, i) => i !== index);
export const removeItem = x => filter(({ id }) => id !== x.id);
export const mapIds = map(({ id }) => id);
export const removeItemByName = x => filter(({ name }) => name !== x.name);

// eslint-disable-next-line no-control-regex
export const hasFullWidthChar = str => !!str?.match(/[^\u0000-\u007F]+/);
