import { CAMPAIGN_EMAIL_CREATE, CAMPAIGN_EMAIL_DRAFT } from 'configs/permissions';
import { emailMediumId } from 'configs/channels/mediumIds';
import { IChannelConfig } from 'configs/channels/types';
import { triggerTypes, oneTimeTrigger, recurringTrigger } from 'app/utilities/constants';

import { CampaignSettings } from 'app/features/Campaigns/Settings';

import EmailCreation from './Creation';
import EmailOverview from './Overview';
import FullSchedule from '../Campaigns/Schedule/FullSchedule';

export default {
  createPermission: [CAMPAIGN_EMAIL_CREATE],
  creative: 'emailCreative',
  defaultTrigger: triggerTypes.oneTime,
  icon: 'mail',
  mediumId: emailMediumId,
  name: 'Email',
  permission: [CAMPAIGN_EMAIL_CREATE, CAMPAIGN_EMAIL_DRAFT],
  requiredFields: ['dedupeStrategy', 'customerCountPerExecution'],
  triggers: [oneTimeTrigger, recurringTrigger],
  CreationComponent: EmailCreation,
  OverviewComponent: EmailOverview,
  ScheduleComponent: FullSchedule,
  SettingComponent: CampaignSettings,
} as IChannelConfig;
