import React from 'react';
import { FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

type Props = {
  updateDaysFn: (...args: Array<any>) => any;
  selectedDays: Array<string>;
};

const DAYS_OF_WEEK = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export default class DaysOfWeek extends React.PureComponent<Props> {
  handleChange = ({ target: { value } }) => {
    const {
      updateDaysFn = () => {
        /* no-op */
      },
      selectedDays = [],
    } = this.props;
    const updatedDays = selectedDays.includes(value) ? selectedDays.filter(day => day !== value) : [...selectedDays, value];
    updateDaysFn(updatedDays);
  };

  render() {
    const { selectedDays } = this.props;
    return (
      <FormGroup row>
        {DAYS_OF_WEEK.map(day => (
          <FormControlLabel
            key={day}
            control={<Checkbox checked={selectedDays.includes(day)} value={day} onChange={this.handleChange} />}
            label={day}
          />
        ))}
      </FormGroup>
    );
  }
}
