import { IPaging } from 'app/hocs/withReporting';
import { IStorefront } from 'app/types/BannerManagement';

import * as types from './types';

export const getStorefrontsFailed = (error: string) => ({
  type: types.GET_STOREFRONTS_FAILED,
  payload: { error },
});

export const getStorefrontsStart = () => ({
  type: types.GET_STOREFRONTS_START,
});

export const getStorefrontsSuccess = (storefronts: Array<IStorefront>, paging: IPaging) => ({
  type: types.GET_STOREFRONTS_SUCCESS,
  payload: { storefronts, paging },
});

export const setStorefrontType = (storefrontType: string) => ({
  type: types.SET_STOREFRONT_TYPE,
  payload: { storefrontType },
});
