const ROOT = 'map/campaigns/cashback';

export const UPDATE_CREATIVE_TYPE = `${ROOT}/UPDATE_CREATIVE_TYPE`;

export const UPDATE_CASHBACK_FIELD = `${ROOT}/UPDATE_CASHBACK_FIELD`;
export const UPDATE_CASHBACK_CALCULATION_FIELD = `${ROOT}/UPDATE_CASHBACK_CALCULATION_FIELD`;
export const UPDATE_CASHBACK_CALCULATION_TYPE = `${ROOT}/UPDATE_CASHBACK_CALCULATION_TYPE`;
export const UPDATE_CASHBACK_CRITERIA = `${ROOT}/UPDATE_CASHBACK_CRITERIA`;
export const UPDATE_CASHBACK_FULFILLMENT = `${ROOT}/UPDATE_CASHBACK_FULFILLMENT`;
export const UPDATE_CASHBACK_FULFILLMENT_TYPE = `${ROOT}/UPDATE_CASHBACK_FULFILLMENT_TYPE`;
export const UPDATE_CASHBACK_STACK_CEILING = `${ROOT}/UPDATE_CASHBACK_STACK_CEILING`;
export const UPDATE_CASHBACK_PERCENTAGE_TYPE = `${ROOT}/UPDATE_CASHBACK_PERCENTAGE_TYPE`;
export const UPDATE_CASHBACK_PAYMENT_METHODS = `${ROOT}/UPDATE_CASHBACK_PAYMENT_METHODS`;

export const ADD_JOURNEY_STAGE = `${ROOT}/ADD_JOURNEY_STAGE`;
export const DELETE_JOURNEY_STAGE = `${ROOT}/DELETE_JOURNEY_STAGE`;
export const EXCHANGE_JOURNEY_STAGE_ORDER = `${ROOT}/EXCHANGE_JOURNEY_STAGE_ORDER`;
export const UPDATE_JOURNEY_CALCULATION_TYPE = `${ROOT}/UPDATE_JOURNEY_CALCULATION_TYPE`;
export const UPDATE_JOURNEY_CALCULATION_FIELD = `${ROOT}/UPDATE_JOURNEY_CALCULATION_FIELD`;
export const UPDATE_JOURNEY_STACK_CEILING = `${ROOT}/UPDATE_JOURNEY_STACK_CEILING`;
export const UPDATE_JOURNEY_FULFILLMENT_TYPE = `${ROOT}/UPDATE_JOURNEY_FULFILLMENT_TYPE`;
export const UPDATE_JOURNEY_FULFILLMENT = `${ROOT}/UPDATE_JOURNEY_FULFILLMENT`;
export const UPDATE_JOURNEY_REVERSE = `${ROOT}/UPDATE_JOURNEY_REVERSE`;
export const UPDATE_JOURNEY_EVENT_CRITERIA = `${ROOT}/UPDATE_JOURNEY_EVENT_CRITERIA`;
export const UPDATE_JOURNEY_USER_CRITERIA = `${ROOT}/UPDATE_JOURNEY_USER_CRITERIA`;
export const UPDATE_JOURNEY_USER_CRITERIA_JSON = `${ROOT}/UPDATE_JOURNEY_USER_CRITERIA_JSON`;
export const UPDATE_JOURNEY_FIELD = `${ROOT}/UPDATE_JOURNEY_FIELD`;
export const UPDATE_JOURNEY_STAGE_ATTRIBUTES = `${ROOT}/UPDATE_JOURNEY_STAGE_ATTRIBUTES`;
