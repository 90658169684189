import React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { Button, Icon, IconButton as _IconButton } from 'app/midgarComponents';
import { sc } from 'app/styles';

import {
  getJourneyCalculation,
  getJourneyEventCriteria,
  getJourneyOrder,
  getOrderedStageIds,
} from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';

import { cashbackActions } from 'app/ducks/campaigns/campaign/cashbackCreative';
import * as CashBackPromoConfigSelectors from 'app/ducks/cashbackPromo/selectors';
import { eventSchemaOperations } from 'app/ducks/cashbackPromo/eventSchema';

import Calculation from '../../Creation/Calculation';
import { EventsCriteria } from '../../Criteria/EventsCriteria';
import { IPromoEvent } from '../../../CashbackPromoManagement/EventSchema/types';
import { IPaymentCeiling } from '../../../CashbackPromoManagement/PaymentCeilings/types';
import { IStage } from './types';
import StageAttributes from './StageAttributes';

type ICustomerEvent = {
  customerEvent: any; // fix types in PredicateBuilder Rule
  loadingCustomerEvent: boolean;
};

type Props = {
  stages: Array<IStage>;
  promoEvents: Array<IPromoEvent>;
  customerEvent: ICustomerEvent;
  addJourneyStage: () => void;
  deleteJourneyStage: (arg0: { stageNum: string }) => void;
  updateJourneyCalculationType: (arg0: { stageNum: string; calculationType: string; stackCeiling: IPaymentCeiling }) => void;

  updateJourneyCalculationField: (arg0: { stageNum: string; [payload: string]: any }) => void;

  updateJourneyEventCriteria: (arg0: { stageNum: string; [payload: string]: any }) => void;

  getAllEvents: () => void;
  getCustomerEvent: () => void;
};

const StageIcon = styled(Icon)`
  color: ${sc.tertiary};
`;

const IconButton = styled(_IconButton)`
  height: 2rem;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const TimeSlotContainer = styled.div`
  display: grid;
  grid-template-columns: 3rem auto;
  grid-template-rows: 1.5rem 1rem auto;
`;

const TimeIcon = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  span {
    display: block;
    margin: 0 auto;
  }
`;

const Headline = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DashTime = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 4;
  border: none;
  margin-left: 1.42rem;
  border-left: 2px dashed #000;
  max-height: 0;
  transition: max-height 450ms ease-in-out;
`;

const ContentBlock = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  max-height: 0;
  overflow: hidden;
  transition: max-height 450ms ease-in-out;
  & > section {
    margin: 0;
    margin-left: 0.1rem;
    margin-top: 1rem;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.75rem;
`;

const RotateIcon = styled(StageIcon)`
  transition: transform 450ms ease-in-out;
`;

const transitionStyles = {
  entering: { maxHeight: '2500px' },
  entered: { maxHeight: '2500px', overflow: 'visible' },
  exiting: { maxHeight: 0 },
  exited: { maxHeight: 0 },
};

const rotationStyles = {
  entering: { transform: 'rotate(-90deg)' },
  entered: { transform: 'rotate(-180deg)' },
  exiting: { transform: 'rotate(-90deg)' },
  exited: { transform: 'rotate(0deg)' },
};

const AddButtonContainer = styled.div`
  margin: 1rem;
`;

export class StagesComponent extends React.Component<Props> {
  state = {};

  componentDidMount() {
    const { getAllEvents, stages } = this.props;
    getAllEvents();
    const openState = stages.reduce(
      (acc, curr, index) => ({
        ...acc,
        [curr.id]: index === 0,
      }),

      {},
    );

    this.setState(openState);
  }

  onToggleStage = id => {
    this.setState(state => ({
      ...state,
      [id]: state[id] !== undefined ? !state[id] : false,
    }));
  };

  getAvailablePromoEvents = () => {
    const { promoEvents: allPromoEvents } = this.props;
    // have only 5 events available for journey
    const promoEvents = allPromoEvents
      ? allPromoEvents.filter(
          event =>
            [
              'Transaction',
              'AddPaymentMethod',
              'Topup',
              'P2P',
              'KYC',
              'MynaPointApplication',
              'Referrer',
              'Referee',
              'HybridCardTransaction',
              'PayPayCardTransaction',
            ].indexOf(event.name) > -1,
        )
      : [];
    return promoEvents;
  };

  showEventsSelectError = (order: number) => {
    const { stages } = this.props;
    if (order !== 0) {
      if (stages.length > 0) {
        const stage = stages[0];
        const {
          criteria: { eventType },
        } = stage;
        if (!eventType) return 'Please select event type for the first stage first';
      }
    }
    return '';
  };

  render() {
    const {
      stages,
      addJourneyStage,
      deleteJourneyStage,
      updateJourneyCalculationType,
      updateJourneyCalculationField,
      updateJourneyEventCriteria,
      getAllEvents,
    } = this.props;

    const { ...state } = this.state;
    return (
      <>
        {stages.map(stage => {
          const {
            calculation: { payoutStrategy },
            criteria,
          } = stage;

          return (
            <Row key={stage.id}>
              <Transition in={state[stage.id] !== undefined ? state[stage.id] : true} timeout={0}>
                {transState => (
                  <>
                    <div style={{ width: '90%' }}>
                      <TimeSlotContainer>
                        <TimeIcon>
                          <Icon name="radio-checked" />
                        </TimeIcon>
                        <Headline>
                          <h2>Stage {stage.order + 1}</h2>
                          <IconButton onClick={() => this.onToggleStage(stage.id)}>
                            <RotateIcon style={rotationStyles[transState]} name="chevron-down" size={32} />
                          </IconButton>
                        </Headline>
                        <DashTime style={transitionStyles[transState]} />
                        <ContentBlock style={transitionStyles[transState]}>
                          <StageAttributes stageId={stage.id} />
                          <EventsCriteria
                            criteria={criteria}
                            promoEvents={this.getAvailablePromoEvents()}
                            getAllEvents={getAllEvents}
                            updateCriteria={payload =>
                              updateJourneyEventCriteria({
                                ...payload,
                                stageNum: stage.id,
                              })
                            }
                          />

                          <ErrorMessage>{this.showEventsSelectError(stage.order)}</ErrorMessage>

                          <Calculation
                            {...payoutStrategy}
                            updateCalculationField={payload =>
                              updateJourneyCalculationField({
                                ...payload,
                                stageNum: stage.id,
                              })
                            }
                            udpateCalculationType={payload =>
                              updateJourneyCalculationType({
                                ...payload,
                                stageNum: stage.id,
                              })
                            }
                            updatePercentageType={payload =>
                              updateJourneyCalculationField({
                                ...payload,
                                stageNum: stage.id,
                              })
                            }
                            updatePaymentMethods={payload =>
                              updateJourneyCalculationField({
                                ...payload,
                                stageNum: stage.id,
                              })
                            }
                            hasLottery={false}
                            hasNoReward
                            stageNum={stage.id}
                          />
                        </ContentBlock>
                      </TimeSlotContainer>
                    </div>
                    <IconButton onClick={() => deleteJourneyStage({ stageNum: stage.id })}>
                      <StageIcon name="remove" size="30" />
                    </IconButton>
                  </>
                )}
              </Transition>
            </Row>
          );
        })}
        <AddButtonContainer>
          <Button onClick={addJourneyStage} color="primary">
            Add Stage
          </Button>
        </AddButtonContainer>
      </>
    );
  }
}

const StagesContainer = connect(
  state => ({
    stages: getOrderedStageIds(state).map(id => ({
      id,
      calculation: getJourneyCalculation(state, id),
      criteria: getJourneyEventCriteria(state, id),
      order: getJourneyOrder(state, id),
    })),

    promoEvents: CashBackPromoConfigSelectors.getPromoEvents(state),
    customerEvent: CashBackPromoConfigSelectors.getCustomerEvent(state),
  }),

  {
    addJourneyStage: cashbackActions.addJourneyStage,
    deleteJourneyStage: cashbackActions.deleteJourneyStage,
    updateJourneyCalculationType: cashbackActions.updateJourneyCalculationType,
    updateJourneyCalculationField: cashbackActions.updateJourneyCalculationField,
    updateJourneyEventCriteria: cashbackActions.updateJourneyEventCriteria,
    getAllEvents: eventSchemaOperations.getAllEvents,
    getCustomerEvent: eventSchemaOperations.getCustomerEvent,
  },
)(StagesComponent);

export default StagesContainer;
