import React, { PureComponent } from 'react';
import window from 'global/window';
import styled from 'styled-components';
import { lighten } from 'polished';

import _Dropzone from 'app/midgarComponents/Dropzone';
import { sc } from 'app/styles';

type Props = {
  className?: string;
  error?: boolean;
  id?: string;
  imageUrl: string | URL | undefined;
  onUpload: (fileUri: string, file: File) => Promise<void>;
  onRemove?: () => void;
  onView?: () => void;
  placeholder?: string;
};

type State = {
  hovered: boolean;
};

export default class ImageUpload extends PureComponent<Props, State> {
  state = {
    hovered: false,
  };

  onRemove = (ev: Event) => {
    const { onRemove } = this.props;
    ev.stopPropagation();
    ev.preventDefault();
    onRemove();
  };

  onView = (ev: Event) => {
    const { imageUrl, onView } = this.props;
    ev.stopPropagation();
    ev.preventDefault();

    window.open(imageUrl);

    if (onView) {
      onView();
    }
  };

  onUpload = (files: Array<File>) => {
    const { onUpload } = this.props;
    const file = files && files.length ? files[0] : null;

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (ev: Event) => {
        onUpload(ev.target.result, file);
      };
    }
  };

  renderViewAction() {
    const { onView } = this.props;

    if (typeof onView !== 'function') {
      return null;
    }

    return <Action onClick={this.onView}>View</Action>;
  }

  renderRemoveAction() {
    const { onRemove } = this.props;

    if (typeof onRemove !== 'function') {
      return null;
    }

    return <Action onClick={this.onRemove}>Remove</Action>;
  }

  render() {
    const { hovered } = this.state;
    const { className, error, id, imageUrl, placeholder } = this.props;

    return (
      <Container className={className}>
        <Dropzone accept=".jpg,.jpeg,.gif,.png" error={!!error} data-qa={`${id || 'image-upload'}`} multiple={false} onDrop={this.onUpload}>
          {imageUrl ? (
            <PreviewImage
              $imageUrl={imageUrl}
              onMouseOut={() => this.setState({ hovered: false })}
              onMouseOver={() => this.setState({ hovered: true })}
            >
              <ImageActions $hovered={hovered}>
                <Actions>
                  <Action>Edit</Action>
                  {this.renderViewAction()}
                  {this.renderRemoveAction()}
                </Actions>
              </ImageActions>
            </PreviewImage>
          ) : (
            <DropzonePlaceholder>{placeholder || 'Upload Image'}</DropzonePlaceholder>
          )}
        </Dropzone>
      </Container>
    );
  }
}

const Container = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 200px;
  margin-right: ${sc.gutter};
  position: relative;
`;

const DropzonePlaceholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;

  color: ${sc.greyLight};
  font-size: ${sc.fontSizeSmall};
  transform: translate(-50%, -50%);
`;

const Dropzone = styled(_Dropzone)`
  padding: 0;
  width: 100%;
  height: 120px;
  overflow: hidden;

  ${({ error }) => (error ? `border: 2px dashed ${sc.danger};` : `border: 1px dashed ${sc.greyLight};`)}
  border-radius: 4px;

  cursor: pointer;
  transition: all 150ms linear;

  & > section {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  &:hover {
    border-color: ${sc.primary};
    transform: scale(1.015);

    & ${DropzonePlaceholder} {
      color: ${sc.primary};
    }
  }
`;

const PreviewImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  background-color: #fff;
  background-image: url('${props => props.$imageUrl}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const ImageActions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 100ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.$hovered ? 1 : 0)};
  pointer-events: ${props => (props.$hovered ? 'auto' : 'none')};
`;

const Actions = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Action = styled.div`
  color: #fff;
  font-size: ${sc.fontSizeSmaller};
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: ${sc.gutterSmaller} ${sc.gutterSmallest};
  border-bottom: 1px solid #fff;
  transition: all 100ms ease-in-out;

  &:hover {
    color: ${lighten(0.2, sc.primary)};
    cursor: pointer;
  }

  &:last-child {
    border: none;
  }
`;
