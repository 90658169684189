import React from 'react';
import PropTypes from 'prop-types';
import { mapIndexed, R } from 'app/helpers/RamdaHelpers/helpers';
// eslint-disable-next-line import/no-cycle
import SubFeatureRuleRow from '../../components/SubFeatureRuleRow';

const toRuleRow = R.curry(({ parentFeature, handleChange, classes }, rowItems, index) => (
  <SubFeatureRuleRow
    {...{
      index,
      classes,
      key: `sub-feature-row-${parentFeature}-${index}`,
      items: rowItems,
      onChange: change => handleChange(change, index),
    }}
  />
));

export const SubFeatureRuleGroup = ({ data, parentFeature, handleChange, classes }) => (
  <div
    {...{
      classes: classes.container,
      style: {
        width: '100%',
        // todo: apply level and move this out
        marginLeft: '120px',
      },
    }}
  >
    {mapIndexed(toRuleRow({ parentFeature, classes, handleChange }), data)}
  </div>
);

SubFeatureRuleGroup.propTypes = {
  parentFeature: PropTypes.string.isRequired,
  // todo: specify shape here
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};
