export default cname => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';').reduce((agg, c) => {
    const inner = c.trim().split('=');
    return {
      ...agg,
      [inner[0]]: inner[1],
    };
  }, {});
  return cookies[cname] ? cookies[cname] : '';
};
