import { connect } from 'react-redux';

import * as actions from 'app/ducks/campaigns/campaign/TimelineNotificationCreative/actions';

const mapStateToProps = ({
  campaigns: {
    campaign: { TimelineNotificationCreative },
  },
}) => {
  return {
    ...TimelineNotificationCreative,
  };
};

const timelineNotificationConnect = connect(mapStateToProps, actions);

export const ConnectedTimelineNotification = timelineNotificationConnect(({ children, ...props }) => children && <>{children(props)}</>);
