import * as React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { Typography } from '@material-ui/core';

import { ConnectedUser } from 'app/ducks';
import { Card } from 'app/components';
import { segmentTypesDisplayValue, segmentTypesColorCodes } from 'app/features/Segments2/utils';

const SegmentList = ({ segments, history }) => {
  const handleClick = id => {
    return history.push(`/audience/${id}/`, {
      state: {
        from: history.location.pathname,
      },
    });
  };
  if (typeof segments === 'string' || segments.length === 0) {
    return <Value>{segments || 'N/A'}</Value>;
  }
  return (
    <UL>
      {segments.map(segment => {
        return (
          <Li key={segment.audienceId}>
            <Predicate onClick={() => handleClick(segment.audienceId)}>
              <SuggestionTag color={(segmentTypesColorCodes || [])[segment.audienceType]}>
                {segmentTypesDisplayValue[segment.audienceType]}
              </SuggestionTag>
              {segment.name}
            </Predicate>
          </Li>
        );
      })}
    </UL>
  );
};

export default class CsvUpload extends React.PureComponent<{
  audienceType: string;
  classes: {
    header: string;
  };

  includedSegments: Array<string>;
  excludedSegments: Array<string>;
  history: Array<{}>;
}> {
  render() {
    const { classes, includedSegments, excludedSegments, history } = this.props;
    return (
      <ConnectedUser>
        {({ isPermitted }) => (
          <Card
            className={classes.header}
            label={() => [
              <Typography variant="subtitle1" key="label">
                Composition
              </Typography>,
            ]}
          >
            <Row>
              <Name>Included Segments</Name>
              <SegmentList segments={includedSegments || 'N/A'} history={history} />
            </Row>
            <Row>
              <Name>Excluded Segments</Name>
              <SegmentList segments={excludedSegments || 'N/A'} history={history} />
            </Row>
          </Card>
        )}
      </ConnectedUser>
    );
  }
}

const Row = styled.div`
  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmall};
  display: flex;
  margin: ${sc.gutterSmaller} 0;
  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
`;

const Name = styled.div`
  margin-right: ${sc.gutterSmallest};
  width: 25%;
  min-width: 210px;
`;

const UL = styled.ul`
  overflow: hidden;
  word-break: break-all;
`;

const Predicate = styled.div`
  overflow-wrap: anywhere;
  overflow-y: scroll;
  max-height: 200px;
  display: list-item;
  cursor: default;
  padding: 9px;
`;

const Li = styled.li`
  flex-grow: 1;
  min-width: 10rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin: 0 1rem 0.5rem 0;
  list-style: inside;
  display: block;
  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
`;

const Value = styled.div`
  flex-grow: 1;
  min-width: 10rem;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-height: 200px;
  overflow-y: auto;
  background: ${({ highlight }) => (highlight ? sc.lightGreen : 'transparent')};
`;

const SuggestionTag = styled.span`
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid #${props => props.color};
  color: #${props => props.color};
  background: #${props => props.color}11;
  margin: 0 5px 0 0;
`;
