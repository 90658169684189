import { compose, withStateHandlers } from 'recompose';
import { withKey } from 'app/helpers/FeatureMetadataHelpers/helpers';
import { R } from 'app/helpers/RamdaHelpers/helpers';
import { AndRuleGroup } from './AndRuleGroup';

const updateStateWith = (update, { onUpdate }) => {
  onUpdate(update);
  return { data: update };
};

const addItem = (item, data, props) => updateStateWith(R.append(withKey(item), data), props);

const addStateHandlers = withStateHandlers(({ data = [] }) => ({ data }), {
  handleAdd:
    ({ data }, props) =>
    newItem =>
      addItem(newItem, data, props),
  handleChange:
    ({ data }, props) =>
    (change, index) =>
      updateStateWith(R.update(index, R.merge(data[index], { value: change }), data), props),
  handleDelete:
    ({ data }, props) =>
    index =>
      updateStateWith(R.remove(index, 1, data), props),
  handleCloneOneRule:
    ({ data }, props) =>
    index =>
      addItem(data[index].value, data, props),
});

const enhance = compose(addStateHandlers);

export default enhance(AndRuleGroup);
export { enhance };
