import * as React from 'react';
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import { CircularProgress } from '@material-ui/core';

export const Dropzone = ({ children, loading = false, disabled = false, classes = {}, ...otherProps }) => (
  <ReactDropzone
    disabled={loading || disabled}
    className={classes.root}
    activeClassName={classes.loading}
    disabledClassName={classes.loading}
    {...otherProps}
  >
    <section>{children}</section>
    {loading && <CircularProgress />}
  </ReactDropzone>
);

Dropzone.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    loading: PropTypes.string,
  }),
};
