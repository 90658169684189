import * as types from './types';

const updatePush2Type = value => ({
  type: types.UPDATE_PUSH2_TYPE,
  payload: { value },
});

const updatePush2Field = (field: Record<string, any>) => ({
  type: types.UPDATE_PUSH2_FIELD,
  payload: field,
});

const updatePush2Context = value => ({
  type: types.UPDATE_PUSH2_CONTEXT,
  payload: value,
});

const updatePush2Content = value => ({
  type: types.UPDATE_PUSH2_CONTENT,
  payload: value,
});

const updatePush2Platform = value => ({
  type: types.UPDATE_PLATFORM,
  payload: value,
});

const resetDeeplink = () => ({
  type: types.RESET_DEEPLINK,
});

const updateDeeplink = value => ({
  type: types.UPDATE_PUSH2_DEEPLINK,
  payload: value,
});

const updateLegacyDeeplink = value => ({
  type: types.UPDATE_PUSH2_LEGACY_DEEPLINK,
  payload: value,
});

const updateDeeplinkExtra = value => ({
  type: types.UPDATE_PUSH2_DEEPLINK_EXTRA,
  payload: value,
});

const addInteractive = () => ({ type: types.ADD_INTERACTIVE });

const updateInteractive = value => ({
  type: types.UPDATE_INTERACTIVE,
  payload: value,
});

const removeInteractive = value => ({
  type: types.REMOVE_INTERACTIVE,
  payload: value,
});

const updateExtraContext = (obj, index) => ({
  type: types.UPDATE_EXTRA_CONTEXT,
  payload: { obj, index },
});

const addExtraContext = obj => ({
  type: types.ADD_EXTRA_CONTEXT,
  payload: obj,
});

const removeExtraContext = index => ({
  type: types.REMOVE_EXTRA_CONTEXT,
  payload: index,
});

const updateOverrides = (platform, value) =>
  platform === 'ios'
    ? {
        type: types.UPDATE_OVERRIDES_ANDROID,
        payload: value,
      }
    : {
        type: types.UPDATE_OVERRIDES_IOS,
        payload: value,
      };

const addCarouselImage = (
  image = {
    url: '',
    description: '',
    action: {
      type: 'deep_link',
      value: '',
    },
  },
) => ({
  type: types.ADD_CAROUSEL_IMAGE,
  payload: image,
});

const setCarouselImage = (image, index) => ({
  type: types.SET_CAROUSEL_IMAGE,
  payload: {
    image,
    index,
  },
});

const resetPush2NotificationFields = () => ({ type: types.RESET_PUSH2_NOTIFICATION_FIELDS });

const removeCarouselImage = index => ({
  type: types.REMOVE_CAROUSEL_IMAGE,
  payload: index,
});

export {
  updatePush2Type,
  updatePush2Field,
  updatePush2Context,
  updatePush2Content,
  updatePush2Platform,
  resetDeeplink,
  updateDeeplink,
  updateLegacyDeeplink,
  updateDeeplinkExtra,
  addInteractive,
  updateInteractive,
  removeInteractive,
  updateExtraContext,
  addExtraContext,
  removeExtraContext,
  updateOverrides,
  addCarouselImage,
  setCarouselImage,
  resetPush2NotificationFields,
  removeCarouselImage,
};
