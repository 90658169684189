import * as React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setCampaignField } from 'app/ducks/campaigns/campaign/actions';
import channelConfig from 'configs/channels/channel.config';
import { getChannelCreativeConfig } from 'configs/channels';
import { DialogShell, Tooltip } from 'app/components';
import { styles } from './styles';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Typography } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { AppConfig } from '../../../../configs/apps/typescript/types';
import { eventsBased } from 'app/utilities/constants';

type Props = {
  mediumId: number;
  triggerType: string;
  appConfig: AppConfig;
  useEveryoneAudience: boolean;
  filterEnableHandler: Function;
  setFieldValue: Function;
  resetAudience: Function;
};

const useStyles = makeStyles(styles);
const UseEveryone: React.FC<Props> = ({
  mediumId,
  triggerType,
  appConfig,
  useEveryoneAudience,
  filterEnableHandler,
  setFieldValue,
  resetAudience,
}): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [audiencePayload, setAudiencePayload] = React.useState<{ [x: string]: string | boolean } | null>(null);

  const handleClose = (okay: boolean): void => {
    if (okay) {
      dispatch(setCampaignField(audiencePayload));
    }
    setAudiencePayload(null);
  };

  const onChangeCheckbox = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked, name } = ev.target;
    const foundChannelOptions = appConfig.channels.find(channel => {
      return channel.name === channelConfig[Number(mediumId)].name;
    });

    // Avoid saving previously stored segment now potentially hidden by toggle
    if (checked) {
      if (
        foundChannelOptions &&
        foundChannelOptions.triggers &&
        foundChannelOptions.triggers.find(trigger => trigger.value === eventsBased.value) &&
        triggerType !== eventsBased.value
      ) {
        setAudiencePayload({ triggerType: eventsBased.value, [name]: checked });
      } else {
        dispatch(setCampaignField({ useEveryoneAudience: checked }));
      }

      setFieldValue('audienceFiltersEnabled', false, false);
      dispatch(setCampaignField({ exclusionCampaign: false }));

      setFieldValue('includedSegmentsFilters', [], false);
      setFieldValue('excludedSegmentsFilters', [], false);

      filterEnableHandler(false);

      setFieldValue('audienceId', [], false); //TODO: add validation flag when audience v2 goes live
      resetAudience(false);
      setFieldValue('excludedSegments', [], false);
      return setFieldValue('includedSegments', [], false);
    }
    dispatch(setCampaignField({ [name]: checked }));
  };

  return (
    <>
      <DialogShell dialogHeader="Confirm Audience Change" isOpen={!!audiencePayload} onClose={(okay: boolean): void => handleClose(okay)}>
        <Typography>
          &quot;Send to Everyone&quot; is not supported after choosing &quot;One time&quot; or &quot;Recurring&quot; scheduling. Do you want
          to switch to &quot;Event-based&quot; scheduling?
        </Typography>
      </DialogShell>
      <UseEveryoneContainer>
        {getChannelCreativeConfig(appConfig, mediumId).sendToEveryoneTooltip && (
          <Tooltip
            placement="bottom"
            title="'Send to Everyone' should only be used if you want to create a Push/Email/Banner campaign with 'Event-based' scheduling. Otherwise, you should not enable this checkbox."
          >
            <InfoIcon className={classes.templateTooltip} />
          </Tooltip>
        )}
        <div>Send to Everyone</div>

        <Checkbox
          data-qa="send-to-everyone-checkbox"
          checked={!!useEveryoneAudience}
          name="useEveryoneAudience"
          onChange={onChangeCheckbox}
        />
      </UseEveryoneContainer>
    </>
  );
};

export default UseEveryone;

const UseEveryoneContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;
