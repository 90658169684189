import { timeZone } from 'app/helpers/AppEnvHelpers/helpers';
import types from './types';

const defaultUser = {
  name: '',
  permittedTenants: [],
  currentTenant: undefined,
  email: '',
  permissions: [],
  picture: '',
  storefronts: '',
  storefrontsAndViews: {},
  storefrontsMgmtExclude: [],
  storefrontsMgmtInclude: [],
  timezone: timeZone(),
  isSuperAdmin: false,
  isSecureRoom: false,
};

const user = (state = defaultUser, { type, payload }) => {
  switch (type) {
    case types.INIT_USER_STATE: {
      const { permissions } = payload;
      const isSuperAdmin = permissions.some(permission => permission === 'SuperAdmin');
      return {
        ...state,
        ...payload,
        isSuperAdmin,
      };
    }
    case types.SET_CURRENT_TENANT: {
      return {
        ...state,
        currentTenant: payload,
      };
    }
    default:
      return state;
  }
};
export default user;
