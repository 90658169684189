import { compose, lifecycle, withHandlers, withProps } from 'recompose';

import { TemplatePage } from './TemplatePage';
import { createSaveProps, enhanceCommon, enhanceCommonRedux } from './common';

const retrieveTemplate = lifecycle({
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.getTemplate(id);
  },
});

const addEditProps = withProps(props => createSaveProps('Email Template', 'Save', props));

const addEditHandlers = withHandlers({
  handleSave:
    ({ updateTemplate, fileUploads }) =>
    () =>
      updateTemplate(fileUploads),
});

export const BaseEditPage = compose(enhanceCommon, addEditProps)(TemplatePage);

export default compose(enhanceCommon, enhanceCommonRedux, addEditProps, addEditHandlers, retrieveTemplate)(TemplatePage);
