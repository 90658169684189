import { fetchTags as apiFetchTags } from 'app/api/bannerManagementV2/viewItems';
import { DispatchFn } from 'app/types';

import * as actions from './actions';

export const fetchTags = () => async (dispatch: DispatchFn) => {
  dispatch(actions.getTagsStart());
  try {
    const tags = await apiFetchTags();
    return dispatch(actions.getTagsSuccess(tags));
  } catch (err) {
    const errorStr = err.error && err.error.msg;
    return dispatch(actions.getTagsFailed(errorStr));
  }
};
