import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { inject } from 'app/decorators';
import { sc } from 'app/styles';
import * as templateOperation from 'app/ducks/templates/template/operations';
import { TextField as _TextField } from 'app/midgarComponents';
import { ITag } from 'app/types';
import Tags from 'app/features/Tags';
import { compose } from 'recompose';

class General extends React.PureComponent<{
  name?: string;
  handleChangeName: () => void;
  description?: string;
  handleChangeDescription: () => void;
  templateOperation: {
    addTag: (arg0: ITag) => void;
    removeTag: (arg0: ITag) => void;
  };

  selectedTags: Array<ITag>;
}> {
  static defaultProps = {
    name: '',
    description: '',
  };

  render() {
    const {
      name,
      handleChangeName,
      handleChangeDescription,
      description,
      templateOperation: { addTag, removeTag },
      selectedTags,
    } = this.props;
    return (
      <Container>
        <TextField required id="name" label="Name" name="name" value={name} onChange={handleChangeName} />
        <TextField
          required
          id="description"
          name="description"
          label="Description"
          value={description}
          onChange={handleChangeDescription}
        />

        <Tags size={60} selected={selectedTags} addTag={addTag} removeTag={removeTag} />
      </Container>
    );
  }
}

export default compose(
  inject({ templateOperation }),
  connect(
    ({
      templates: {
        template: { tags },
      },
    }) => ({
      selectedTags: tags,
    }),
  ),
)(General);

const Container = styled.section`
  padding: 2rem 0;
  width: 100%;
  border-bottom: 1px solid ${sc.sectionBorderColor};
`;

const TextField = styled(_TextField)`
  width: 60%;
  margin-bottom: ${sc.gutter};
`;
