import { CAMPAIGN_PUSH_CREATE, CAMPAIGN_PUSH_DRAFT } from 'configs/permissions';
import { push2MediumId } from 'configs/channels/mediumIds';
import { IChannelConfig } from 'configs/channels/types';
import { triggerTypes, oneTimeTrigger, recurringTrigger, locationTrigger, cartAbandonTrigger } from 'app/utilities/constants';
import {
  NAME as name,
  ICON as icon,
  DISPLAY as display,
  CREATIVE as creative,
  REQUIRED_FIELDS as requiredFields,
} from './push2ConfigConstants';

import Push2Creation from './Creation/Push2Creation';
import Push2Overview from './Overview';
import FullSchedule from '../Campaigns/Schedule/FullSchedule';
import { CampaignSettings } from 'app/features/Campaigns/Settings';

export default {
  defaultTrigger: triggerTypes.oneTime,
  mediumId: push2MediumId,
  name,
  icon,
  display,
  requiredFields,
  triggers: [oneTimeTrigger, recurringTrigger, locationTrigger, cartAbandonTrigger],
  CreationComponent: Push2Creation,
  OverviewComponent: Push2Overview,
  ScheduleComponent: FullSchedule,
  SettingComponent: CampaignSettings,
  creative,
  permission: [CAMPAIGN_PUSH_CREATE, CAMPAIGN_PUSH_DRAFT],
  createPermission: [CAMPAIGN_PUSH_CREATE],
} as IChannelConfig;
