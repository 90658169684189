const ROOT = 'map/cashbackPromo/stackableGroup/campaigns';

export const GET_ERROR = `${ROOT}/GET_ERROR`;
export const GET_INIT = `${ROOT}/GET_INIT`;
export const GET_SUCCESS = `${ROOT}/GET_SUCCESS`;

export const SAVE_ERROR = `${ROOT}/SAVE_ERROR`;
export const SAVE_START = `${ROOT}/SAVE_START`;
export const SAVE_SUCCESS = `${ROOT}/SAVE_SUCCESS`;

export const UPDATE = `${ROOT}/UPDATE`;
