import { ICampaignExecution } from 'app/types';

import * as types from './types';

export const getExecutions = () => ({ type: types.GET_EXECUTIONS });
export const getExecutionsSuccess = (payload: { executions: Array<ICampaignExecution> }) => ({
  type: types.GET_EXECUTIONS_SUCCESS,
  payload,
});

export const getExecutionsError = (error: Error) => ({ type: types.GET_EXECUTIONS_ERROR, payload: { error } });
