import * as React from 'react';
import styled from 'styled-components';
import { DraggableList } from 'app/midgarComponents';

import { sc } from 'app/styles';

import { IStackableGroupCampaign } from '../types';

type Props = {
  campaigns: Array<IStackableGroupCampaign>;
  className?: string;
  readOnly?: boolean;
  updateCampaignPriorities: (arg0: Array<IStackableGroupCampaign>) => any;
};

export default class CampaignsList extends React.PureComponent<Props> {
  render() {
    const { campaigns, className, readOnly, updateCampaignPriorities } = this.props;

    return (
      <section className={className}>
        <DraggableList
          rows={campaigns}
          updatePriortiesFn={updateCampaignPriorities}
          readOnly={readOnly}
          identifier="id"
          withCheckbox
          withMoveToTop
        >
          {campaign => (
            <Campaign>
              <CampaignId>ID:{campaign.id}</CampaignId>
              <CampaignName>{campaign.name}</CampaignName>
            </Campaign>
          )}
        </DraggableList>
      </section>
    );
  }
}

const Campaign = styled.section`
  display: flex;
  padding: ${sc.gutterSmall};
`;

const CampaignId = styled.div`
  color: ${sc.grey};
  font-size: ${sc.fontSizeSmall};
  width: 6rem;
`;

const CampaignName = styled.div``;
