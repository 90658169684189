import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import * as operations from './operations';
import * as selectors from './selectors';

const mapStateToProps = state => ({
  emailSenders: selectors.emailSenders(state),
  emailSendersLoading: selectors.loading(state),
  emailSendersError: selectors.error(state),
});

const mapDispatchToProps = {
  getAllEmailSenders: operations.getAllEmailSenders,
};

const connectEmailSenders = connect(mapStateToProps, mapDispatchToProps);

const fetchEmailSenders = lifecycle({
  componentDidMount() {
    this.props.getAllEmailSenders();
  },
});

const ConnectedEmailSenders = compose(connectEmailSenders, fetchEmailSenders);

export { connectEmailSenders, ConnectedEmailSenders };
