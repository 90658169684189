import template from './reducers';

import * as templateActions from './actions';
import * as templateOperations from './operations';
import * as templateSelectors from './selectors';
import connectToRedux from './connect';

export { templateActions, templateOperations, templateSelectors, connectToRedux };

export default template;
