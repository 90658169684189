import document from 'global/document';

export const download = (urls: Array<string>) => {
  const link = document.createElement('a');

  link.setAttribute('download', '');
  link.style.display = 'none';

  document.body.appendChild(link);

  urls.forEach(url => {
    link.setAttribute('href', url);
    link.click();
  });

  document.body.removeChild(link);
};
