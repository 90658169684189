import * as React from 'react';
import { connect } from 'react-redux';
import { Match, RouterHistory } from 'react-router-dom';
import { compose } from 'ramda';
import styled from 'styled-components';

import { withAppConfig, withPermissions } from 'app/decorators';
import { creativeActions, creativeOperations } from 'app/ducks';
import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { Page, Button, Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { IBannerCreative } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { bannerManagementEditPermissions } from 'configs/permissions';
import { BANNERS_CREATIVE } from 'configs/routes';
import { IAppConfig } from 'configs/apps/types';

import CreativeEditor from '../CreativeEditor';
import withCreativeValidation from '../validation/withCreativeValidation';
import { ICreativeValidation } from '../validation/types';
import { PageMain, StickyHeader } from '../../common/components';
import withBannerDetailContext from '../../hoc/withBannerDetailContext';

type Props = ICreativeValidation & {
  appConfig: IAppConfig;
  cloneCreative: (id: number | string) => (...args: Array<any>) => any;
  form: IBannerCreative;
  formCloning: boolean;
  history: RouterHistory;
  isPermitted: IsPermittedFn;
  isSaving: boolean;
  match: Match;
  resetCreativeForm: () => any;
  saveCreative: (redirect?: (...args: Array<any>) => any) => (...args: Array<any>) => any;
  updateVariantsEngageCategoriesField: (index: number, catIndex: number, field: string, value: any) => any;
  widgetTypesError?: string;
  widgetTypesLoading: boolean;
};

class CreativeNew extends React.PureComponent<Props> {
  componentDidMount() {
    const { appConfig, cloneCreative, match, updateVariantsEngageCategoriesField } = this.props;

    if (!getBannerCreativeConfig(appConfig).useAutopilot) {
      // TODO: Set the default weight of the first category to 100 until categories are made optional
      updateVariantsEngageCategoriesField(0, 0, 'weight', 100);
    }

    if (match.params.id) {
      cloneCreative(match.params.id);
    }
  }

  componentWillUnmount() {
    // TODO: Same is for Edit
    const { resetCreativeForm } = this.props;
    resetCreativeForm();
  }

  onSave = () => {
    // TODO: Similar to Edit
    const { appConfig, creativeHasErrors, history, isSaving, metaHasErrors, saveCreative, setShouldValidate } = this.props;

    const { validateMeta = false } = getBannerCreativeConfig(appConfig);

    setShouldValidate(true);

    if (!isSaving) {
      if (creativeHasErrors()) {
        displayError('Some fields are invalid');
      } else if (validateMeta && metaHasErrors()) {
        displayError('Metadata value cannot be empty');
      } else {
        saveCreative(() => history.push(BANNERS_CREATIVE));
      }
    }
  };

  render() {
    const {
      creativeFieldHasError,
      creativeHasErrors,
      formCloning,
      isPermitted,
      isSaving,
      match,
      metaHasErrors,
      setShouldValidate,
      shouldValidate,
      variantCategoryFieldHasError,
      variantCategoryHasErrors,
      variantFieldHasError,
      variantHasErrors,
      widgetTypesError,
      widgetTypesLoading,
    } = this.props;

    const title = match.params.id ? 'Clone Creative' : 'New Creative';

    return (
      <Page>
        <StickyHeader title={title}>
          <div>
            <Button type="primary" to={BANNERS_CREATIVE}>
              Back
            </Button>

            {isPermitted(bannerManagementEditPermissions) && (
              <CreateButton
                data-qa="banner-creative-submit-button"
                disabled={isSaving || (shouldValidate && creativeHasErrors())}
                onClick={this.onSave}
                type="primary"
              >
                {isSaving ? <Spinner color={sc.primary} /> : 'Create'}
              </CreateButton>
            )}
          </div>
        </StickyHeader>

        <Container>
          {formCloning ? (
            <Spinner color={sc.primary} />
          ) : (
            <CreativeEditor
              creativeFieldHasError={creativeFieldHasError}
              creativeHasErrors={creativeHasErrors}
              metaHasErrors={metaHasErrors}
              setShouldValidate={setShouldValidate}
              shouldValidate={shouldValidate}
              variantCategoryFieldHasError={variantCategoryFieldHasError}
              variantCategoryHasErrors={variantCategoryHasErrors}
              variantFieldHasError={variantFieldHasError}
              variantHasErrors={variantHasErrors}
              widgetTypesError={widgetTypesError}
              widgetTypesLoading={widgetTypesLoading}
            />
          )}
        </Container>
      </Page>
    );
  }
}

const mapStateToProps = ({ bannerManagement: { creative } }) => ({
  form: creative.form,
  formCloning: creative.formCloning,
  isSaving: creative.isSaving,
});

const mapDispatchToProps = {
  cloneCreative: creativeOperations.cloneCreative,
  resetCreativeForm: creativeActions.resetCreativeForm,
  saveCreative: creativeOperations.saveCreative,
  updateVariantsEngageCategoriesField: creativeActions.updateVariantsEngageCategoriesField,
};

export default compose(
  withAppConfig,
  withBannerDetailContext,
  withPermissions,
  connect(mapStateToProps, mapDispatchToProps),
  withCreativeValidation,
)(CreativeNew);

const Container = styled(PageMain)`
  padding: ${sc.gutter};
`;

const CreateButton = styled(Button)`
  margin-left: ${sc.gutterSmaller};
`;
