import { ISearchDefs } from 'app/hocs/withReporting';

export const searchParams = {
  id: 'feature_id',
  name: 'feature_name',
  label: 'feature_label',
  vertical: 'vertical',
  category: 'category',
  featureClass: 'feature_class',
};

export const searchDefs: ISearchDefs = [
  { name: searchParams.id, label: 'Feature ID' },
  { name: searchParams.name, label: 'Name' },
  { name: searchParams.label, label: 'Label' },
  { name: searchParams.vertical, label: 'Vertical' },
  { name: searchParams.category, label: 'Category' },
  { name: searchParams.featureClass, label: 'Feature Class' },
];

export { searchDefs as searchBarDefs };

export const searchLabel = (name: string) => (searchDefs.find(def => def.name === name) || {}).label || '';
