import { fetchPaymentCeilings, putPaymentCeiling } from 'app/api/cashbackPromo';
import { IPaymentCeiling } from 'app/features/CashbackPromoManagement/types';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { DispatchFn, GetStateFn } from 'app/types';

import * as actions from './actions';

export const getPaymentCeilings = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    cashbackPromoConfig: {
      paymentCeilings: { paymentCeilings: prevPaymentCeilings },
    },
  } = getState();
  if (!prevPaymentCeilings || prevPaymentCeilings.length === 0) {
    dispatch(actions.getInit());
    try {
      const paymentCeilings = await fetchPaymentCeilings();
      dispatch(actions.getSuccess(paymentCeilings));
    } catch (err) {
      dispatch(actions.getError(err));
    }
  }
};

export const updatePaymentCeiling = (paymentCeiling: IPaymentCeiling) => async (dispatch: DispatchFn) => {
  try {
    const resCeiling = await putPaymentCeiling(paymentCeiling);
    if (resCeiling) {
      dispatch(actions.updatePaymentCeiling(resCeiling));
    } else {
      displayError('Error during save: SAVE_PAYMENT_CEILING_01');
    }
  } catch (err) {
    displayError(`Error during save: ${err.message}`);
  }
};
