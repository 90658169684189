import { compose, withProps, withState } from 'recompose';

import { IBannerCreative, ILandingPageType } from 'app/types/BannerManagement';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';

import { areMetadataValid, validateCategoryWeights, validateCreativeFields, validateVariants } from './validation';

export default compose(
  withState('shouldValidate', 'setShouldValidate', false),
  withProps(
    ({
      appConfig,
      form: creative,
      landingPageTypes,
    }: {
      appConfig: IAppConfig;
      form: IBannerCreative;
      landingPageTypes: Array<ILandingPageType>;
    }) => {
      const bannerConfig = getBannerCreativeConfig(appConfig);

      // Validate and create the error structures
      const creativeErrors: Array<string> = validateCreativeFields(creative, bannerConfig);
      const { variants = {} } = creative || {};

      const variantErrorsByKey = validateVariants(variants, landingPageTypes, bannerConfig);
      // TODO: Need to pass in landingPageTypes, in order to check item_id_required, which tells whether to use itemId or url
      // const mapStateToProps = ({ bannerManagement: { creative: { form, landingPageTypes } } }, { variantKey }) => ({

      const categoryErrorsByVariantKey: Record<string, Record<string, Array<string>>> = Object.keys(variants).reduce((acc, variantKey) => {
        const variant = (variants || {})[variantKey] || {};
        // TODO: Check that the sum is 100
        return {
          ...acc,
          [variantKey]: validateCategoryWeights((variant || {}).engageCategories),
        };
      }, {});

      // landingPageTypes for PayPay:
      // [{ id, item_id_required: boolean, label, name }]
      // required fields = item_id_required ? { itemId: true, url: false } : { itemId: false, url: true }

      // Functions to check for errors
      const variantCategoryHasErrors = (variantKey: string, categoryKey: string) =>
        ((categoryErrorsByVariantKey[variantKey] || {})[categoryKey] || []).length > 0;

      const variantCategoryFieldHasError = (variantKey: string, categoryKey: string, fieldName: string) => {
        const categoryErrors = (categoryErrorsByVariantKey[variantKey] || {})[categoryKey] || [];
        return categoryErrors.includes(fieldName);
      };

      const variantHasErrors = (variantKey: string) =>
        (variantErrorsByKey[variantKey] || []).length > 0 ||
        Object.keys(categoryErrorsByVariantKey[variantKey]).some(categoryKey => variantCategoryHasErrors(variantKey, categoryKey));
      const variantFieldHasError = (variantKey: string, fieldName: string) => (variantErrorsByKey[variantKey] || []).includes(fieldName);

      const creativeHasErrors = () => creativeErrors.length > 0 || Object.keys(variantErrorsByKey).some(variantHasErrors);
      const creativeFieldHasError = (fieldName: string) => creativeErrors.includes(fieldName);
      const metaHasErrors = () => areMetadataValid(variants);

      return {
        creativeFieldHasError,
        creativeHasErrors,
        variantCategoryFieldHasError,
        variantCategoryHasErrors,
        variantFieldHasError,
        variantHasErrors,
        metaHasErrors,
      };
    },
  ),
);
