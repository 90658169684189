import moment from 'moment';

export const ScheduleStatus = {
  Ready: 0,
  Running: 1,
  Completed: 2,
  Invalid: -1,
};

export const ScheduleStatusLabels = {
  [ScheduleStatus.Ready]: 'Ready',
  [ScheduleStatus.Running]: 'Running',
  [ScheduleStatus.Completed]: 'Completed',
  [ScheduleStatus.Invalid]: 'Invalid',
};

export const getScheduleStatus = (fromDate?: string, toDate?: string): number => {
  const now = moment();
  const from = moment(fromDate);
  const to = moment(toDate);

  if (now.diff(from) >= 0 && now.diff(to) <= 0) {
    return ScheduleStatus.Running;
  }

  if (now.diff(from) < 0) {
    return ScheduleStatus.Ready;
  }

  if (now.diff(to) > 0) {
    return ScheduleStatus.Completed;
  }

  return ScheduleStatus.Invalid;
};
