/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { eventsBased } from 'app/utilities/constants';
import { pushTypes } from 'app/features/Push2/Creation/Push2Constants';
import { updatePush2Type } from 'app/ducks/campaigns/campaign/push2Creative/actions';
import { getChannelCreativeConfig } from 'configs/channels';
import { displayWarning } from 'app/helpers/NotificationHelpers/helpers';

import Push from './Push';
import Inbox from './Inbox';
import BigPicture from './BigPicture';

const Push2Content = () => {
  const {
    general: { mediumId, triggerType },
    config: appConfig,
    push2Creative: { type: pushType, templated },
  } = useSelector(
    ({
      campaigns: {
        campaign: { general, push2Creative },
      },

      config,
      user,
    }) => ({
      general,
      config,
      push2Creative,
      user,
    }),
  );

  const dispatch = useDispatch();

  const creativeConfig = getChannelCreativeConfig(appConfig, mediumId);

  const showTemplateToggleV2 =
    !!creativeConfig.isDynamicTemplatesEnabledV2 && (creativeConfig.isBatchTemplatesEnabled ? true : triggerType === eventsBased.value);
  const isDynamicTemplatesDeeplinkEnable =
    !!creativeConfig.isDynamicTemplatesDeeplinkEnable &&
    (creativeConfig.isBatchTemplatesEnabled ? true : triggerType === eventsBased.value);

  React.useEffect(() => {
    /* no-op */
  }, [dispatch, pushType, templated]);

  let Component;

  switch (pushType) {
    case pushTypes.push.id:
      Component = Push;
      break;
    case pushTypes.inbox.id:
      Component = Inbox;
      break;
    case pushTypes.bigPicture.id:
      Component = BigPicture;
      break;
    case 'flash': // fallback for deprecated pushType Flash to Push
      dispatch(updatePush2Type(pushTypes.push.id));
      displayWarning('Flash is deprecated, please select a different Push Type');
      Component = Push;
      break;
    default:
      Component = '';
  }

  if (!Component) return null;

  return <Component showTemplateToggleV2={showTemplateToggleV2} isDynamicTemplatesDeeplinkEnable={isDynamicTemplatesDeeplinkEnable} />;
};

export default Push2Content;
