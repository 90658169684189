import * as React from 'react';
import styled from 'styled-components';
import { Chip, Grid, Typography } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { audienceTypeDisplay } from 'app/utilities/constants';

import { Card, Page } from 'app/components';
import { Button } from 'app/midgarComponents';
import { segmentCreationPermissions, segmentDownloadPermissions } from 'configs/permissions';
import sc from 'app/styles/config';

import { IsPermittedFn } from 'app/ducks/user/ConnectedUser';
import { segmentTypeFlags } from 'app/features/Segments/utils';

import { Switcher } from './Components/Switcher';
import Download from '../components/Download';

const Title = ({ type }) => {
  switch (type) {
    case segmentTypeFlags.CSV_BASED:
      return audienceTypeDisplay.csv;
    default:
      return audienceTypeDisplay.rule;
  }
};

export default class Overview extends React.PureComponent<{
  description: string;
  name: string;
  tags: Array<{ id: number; name: string }>;
  type: string;
  classes: {
    title: string;
    grid: string;
    inline: string;
    half: string;
    cardBody: string;
    card: string;
    full: string;
    header: string;
    chip: string;
  };

  toTableData: () => () => void;
  featureMetadata: {};
  includedRules: Array<{}>;
  id: number;
  countOfSegment: () => void;
  state: string;
  downloadUrls: Array<{}>;
  createdBy: string;
  backToTableHandler: () => void;
  handleCloneClicked: () => void;
  isPermitted: IsPermittedFn;
  includedSeedSegments: Array<{}>;
  excludedSeedSegments: Array<{}>;
  includedUniverseSegments: Array<{}>;
  excludedUniverseSegments: Array<{}>;
  maxAudienceSize: number;
  ingestState: string;
}> {
  calculate = () => {
    const { countOfSegment, id } = this.props;
    countOfSegment(id);
    return true;
  };

  render() {
    const {
      description,
      name,
      tags,
      type,
      classes,
      toTableData,
      featureMetadata,
      includedRules,
      id,
      state,
      downloadUrls,
      createdBy,
      backToTableHandler,
      handleCloneClicked,
      isPermitted,
      ingestState,
      includedSeedSegments,
      excludedSeedSegments,
      includedUniverseSegments,
      excludedUniverseSegments,
      maxAudienceSize,
    } = this.props;

    return (
      <Page
        renderHeader={() => [
          <Typography className={classes.title} key="header" variant="subtitle1">
            <Title type={type} />
            <KeyboardArrowRight />
            {name}
          </Typography>,
          <React.Fragment key="headerLinkDownload">
            {isPermitted(segmentDownloadPermissions) && (
              <Download id={id} state={state} downloadUrls={downloadUrls} type={type} ingestState={ingestState} />
            )}
          </React.Fragment>,
          <ActionButtons key="actions">
            {isPermitted(segmentCreationPermissions) && (
              <Button data-qa="clone-btn" onClick={handleCloneClicked} color="primary">
                Clone
              </Button>
            )}

            <Button data-qa="return-btn" onClick={backToTableHandler} color="primary">
              Return to Audiences
            </Button>
          </ActionButtons>,
        ]}
      >
        <section className={classes.grid}>
          <Grid className={classes.inline}>
            <Grid item className={classes.half}>
              <Card className={classes.card} label="General">
                <Typography>Description: {description}</Typography>
                <Typography>Creator: {createdBy}</Typography>
              </Card>
            </Grid>
            <Grid item className={classes.half}>
              <Card label="Tag manager">
                <section className={classes.cardBody}>
                  {tags.map(({ name: chipName, id: tagId }) => (
                    <Chip label={chipName} key={tagId} className={classes.chip} />
                  ))}
                </section>
              </Card>
            </Grid>
          </Grid>

          <Grid item className={classes.full}>
            <Switcher
              calculate={this.calculate}
              {...{
                type,
                classes,
                id,
                state,
                downloadUrls,
                toTableData,
                featureMetadata,
                includedRules,
                includedSeedSegments,
                excludedSeedSegments,
                includedUniverseSegments,
                excludedUniverseSegments,
                maxAudienceSize,
              }}
            />
          </Grid>
        </section>
      </Page>
    );
  }
}

const ActionButtons = styled.section`
  & > * {
    margin-right: ${sc.gutterSmaller};
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
