import * as React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';

import { ImageUpload } from 'app/midgarComponents';
import { ConnectedTimelineNotification } from 'app/ducks';
import { ApiImageUpload } from 'app/helpers/ImageUploadManager/ApiImageUpload';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import Generic from './Generic';
import NotificationCategories from '../NotificationCategories';

type Props = {
  apiFileUpload: (...args: Array<any>) => any;
};

class Notification extends React.Component<Props> {
  onUpload = async (fileUri: string, file: File, updatePayloadContent: (...args: Array<any>) => any) => {
    if (file) {
      const { apiFileUpload } = this.props;
      const { status, url } = await apiFileUpload(file);
      if (status === 'SUCCESSFUL') {
        displaySuccess('Image upload Successful');
        updatePayloadContent({ backgroundImage: url });
      } else {
        displayError(`Image upload failed with the API status ${status}`);
      }
    } else {
      displayError('The file was rejected. It may have the wrong extension.');
    }
  };

  render() {
    return (
      <ConnectedTimelineNotification>
        {({ updatePayloadContent, categoryList, payload: { backgroundImage, threadId } }) => {
          return (
            <>
              <NotificationCategories
                value={threadId}
                options={categoryList}
                onChangeType={val => updatePayloadContent({ threadId: val.threadId, threadType: val.threadType })} //updatePayloadContent({ expiry_type: val, expiry: null })
              />
              <Box>
                <ImageUpload
                  id="upload-notification-picture"
                  placeholder="Upload Notification Image less than 5MB"
                  imageUrl={backgroundImage}
                  onUpload={(uri, file) => this.onUpload(uri, file, updatePayloadContent)}
                  onRemove={() => {
                    updatePayloadContent({ backgroundImage: '' });
                  }}
                />

                <InputContainer>
                  <Generic />
                </InputContainer>
              </Box>
            </>
          );
        }}
      </ConnectedTimelineNotification>
    );
  }
}

const Box = styled.div`
  display: flex;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;
// TODO: RECOMPOSE MUST DIE
export default compose(ApiImageUpload)(Notification);
