import React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';

type Props = {
  type?: string;
  value: string;
  onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  error?: boolean;
  allowDecimal?: boolean;
  allowComma?: boolean;
};

export const Input = (props: Props) => {
  const { allowDecimal = false, type, allowComma, error, ...otherProps } = props;
  const isCommaSeparatedNumber = type === 'Number' && allowComma;

  const modifiedType = isCommaSeparatedNumber ? 'text' : type;

  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { type } = props;
    const { value } = event.target;
    if (isCommaSeparatedNumber) {
      if (/^[0-9,]*$/.test(value)) {
        props.onChange(event);
      }
      return;
    }
    if (type === 'number') {
      const parsedValue = allowDecimal ? parseFloat(value) : parseInt(value, 10);

      const modifiedEvent: React.SyntheticEvent<HTMLInputElement> = {
        ...event,
        target: {
          name: event.target.name,
          value: parsedValue,
        },
      } as any;

      props.onChange(modifiedEvent);
      return;
    }
    props.onChange(event);
  };

  return <StyledInput {...otherProps} onChange={onChange} type={modifiedType} $error={error} />;
};

const StyledInput = styled.input`
  border: solid 1px ${props => (props.$error ? sc.danger : sc.greyLighter)};
  border-radius: 2px;
  height: ${({ height = '100%' }) => height};
  width: ${({ width = '100%' }) => width};
  margin: ${({ margin = '0' }) => margin};
  font-size: 14px;
  color: ${sc.secondary};
  padding: ${({ padding = '1.5rem 1rem' }) => padding};
  margin-bottom: 1rem;
  &::placeholder {
    color: ${sc.greyLight};
  }
  &:disabled {
    cursor: not-allowed;
    background: ${sc.greyLighter};
  }
`;
