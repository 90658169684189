import {
  fetchCashbackBanner,
  saveCashbackBanner as apiSaveCashbackBanner,
  saveJourneyBanner as apiSaveJourneyBanner,
  updateCashbackBanner as apiUpdateCashbackBanner,
  updateJourneyBanner as apiUpdateJourneyBanner,
} from 'app/api/cashbackPromo';

import { ICashbackBannerCreative } from 'app/features/CashbackPromoManagement/types';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { DispatchFn, GetStateFn } from 'app/types';
import { cashbackTypes } from 'app/features/Cashback/constants';
import { CashbackType } from 'app/features/Cashback/types';

import { RouterHistory } from 'react-router-dom';

import { getBanner } from '../banners/selectors';
import * as actions from './actions';

export const getCashbackBanner = (bannerId: number) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  dispatch(actions.getInit());
  try {
    let banner: ICashbackBannerCreative | null | undefined = getBanner(bannerId, getState());
    if (!banner) {
      banner = await fetchCashbackBanner(bannerId);
    }

    if (banner) {
      return dispatch(actions.getSuccess(banner));
    }

    return dispatch(actions.getError(Error(`Banner ID ${bannerId} not found`)));
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    return dispatch(actions.getError(error));
  }
};

export const saveCashbackBanner =
  (cashbackType: CashbackType, history: RouterHistory) => async (dispatch: DispatchFn, getState: GetStateFn) => {
    dispatch(actions.saveStart());
    try {
      const {
        cashbackPromoConfig: {
          banner: { banner },
        },
      } = getState();
      if (banner) {
        const { id } =
          cashbackType === cashbackTypes.regular || !cashbackType
            ? await apiSaveCashbackBanner(banner)
            : await apiSaveJourneyBanner(banner);
        if (id) {
          displaySuccess('Saved');
          // go to list
          history.push({
            pathname: '/cashback/banners',
          });
        } else {
          displayError('Save error from API: CASHBACK_BANNER_SAVE_02');
        }
      } else {
        displayError('Could not save: CASHBACK_BANNER_SAVE_01');
      }
    } catch (error) {
      displayError(error.message);
    } finally {
      dispatch(actions.saveEnd());
    }
  };

export const updateCashbackBanner = (id: number, cashbackType: CashbackType) => async (dispatch: DispatchFn, getState: GetStateFn) => {
  dispatch(actions.saveStart());
  try {
    const {
      cashbackPromoConfig: {
        banner: { banner },
      },
    } = getState();
    if (banner) {
      const { id: bannerId } =
        cashbackType === cashbackTypes.regular || !cashbackType
          ? await apiUpdateCashbackBanner(banner, id)
          : await apiUpdateJourneyBanner(banner, id);
      if (bannerId) {
        displaySuccess('Updated!');
      } else {
        displayError('Update error from API: CASHBACK_BANNER_UPDATE_02');
      }
    } else {
      displayError('Could not update: CASHBACK_BANNER_UPDATE_01');
    }
  } catch (error) {
    displayError(error.message);
  } finally {
    dispatch(actions.saveEnd());
  }
};
