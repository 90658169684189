import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@material-ui/core';

import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';

export const TablePaginationActions = ({
  page: pageIndex,
  lastPageIndex,
  handleFirstPageButtonClick,
  handleBackButtonClick,
  handleNextButtonClick,
  handleLastPageButtonClick,
  classes,
}) => {
  const isOnFirstPage = pageIndex === 0;
  const isOnLastPage = pageIndex >= lastPageIndex;

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={isOnFirstPage} aria-label="Go to First Page">
        <FirstPage />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={isOnFirstPage} aria-label="Go to Previous Page">
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton onClick={handleNextButtonClick} disabled={isOnLastPage} aria-label="Go to Next Page">
        <KeyboardArrowRight />
      </IconButton>

      <IconButton onClick={handleLastPageButtonClick} disabled={isOnLastPage} aria-label="Go to Last Page">
        <LastPage />
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  page: PropTypes.number.isRequired,
  lastPageIndex: PropTypes.number.isRequired,
  handleFirstPageButtonClick: PropTypes.func.isRequired,
  handleBackButtonClick: PropTypes.func.isRequired,
  handleNextButtonClick: PropTypes.func.isRequired,
  handleLastPageButtonClick: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
