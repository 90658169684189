export const getBoundingClientRect = (node: (Node | null | undefined) | (Range | null | undefined)) => {
  if (node) {
    if (node instanceof Range) {
      return node.getBoundingClientRect();
    } else if (node instanceof Element) {
      return node.getBoundingClientRect();
    }
  }
  return null;
};
