import { ChildrenArray } from 'react';
import React, { PureComponent } from 'react';
import { Link as _Link } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import { sc } from 'app/styles';

export default class PageHeader extends PureComponent<{
  title: string;
  backLink?: string;
  children?: ChildrenArray<any>;
  className?: string;
}> {
  render() {
    const { children, title, backLink, className, sticky } = this.props;

    return (
      <Container className={className} sticky={sticky}>
        <Title $hasBackLink={!!backLink}>{title}</Title>

        {backLink ? (
          <Link to={backLink}>
            <BackIcon />
          </Link>
        ) : null}

        {children}
      </Container>
    );
  }
}

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 70px;
  width: 100%;
  padding: 0 ${sc.gutter};
  background: ${sc.sectionWhiteColor};
  ${({ sticky }) =>
    sticky &&
    `
    position: sticky;
    top: 56px;
    z-index: 20;
    border-bottom: 1px solid #e6ebf3;
  `}
`;

const Title = styled.h2`
  color: ${sc.secondary};
  text-transform: uppercase;
  font-size: ${sc.fontSizeLarger};
  display: inline-block;
  line-height: 1.43;
  font-weight: bold;
  height: 40px;
  padding-left: ${props => (props.$hasBackLink ? '3rem' : '0')};
`;

const Link = styled(_Link)`
  display: block;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translate(0, -45%);

  & > svg {
    display: block;
    vertical-align: top;
    font-size: 30px;
    color: ${sc.bodyColor};
  }

  &:hover > svg {
    color: ${sc.primary};
  }
`;
