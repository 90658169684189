import { compose, withHandlers, withProps } from 'recompose';
import { dissoc } from 'ramda';

import { IQueryParams } from 'app/types';
import { ISearchDef } from 'app/hocs/withReporting';
import { IDropdownOption } from 'app/midgarComponents/Dropdown';

type Props = {
  options?: Array<IDropdownOption>;
  pushSearch: (arg0: IQueryParams) => void;
  search: IQueryParams;
};

/**
 * Connects a `Dropdown` to the reporting search functionality.
 */
export default (searchDef: ISearchDef) =>
  compose(
    withProps(({ options: overrideOptions, search }: Props) => {
      const options = overrideOptions || searchDef.options || [];
      return {
        label: searchDef.label || '',
        value: options.find(o => o.id === search[searchDef.name]),
        options,
      };
    }),
    withHandlers({
      onChange:
        ({ onChange, pushSearch, search }) =>
        (selectedOption: string | IDropdownOption) => {
          const id = typeof selectedOption === 'string' ? selectedOption : selectedOption.id;

          if (onChange) {
            onChange(selectedOption);
          }

          if (id) {
            pushSearch({ ...search, [searchDef.name]: id });
          } else {
            pushSearch(dissoc(searchDef.name, search));
          }
        },
    }),
  );
