import * as types from './types';
import { fetch } from '../../commonStates';
import { POST_CAMPAIGN_SUCCESS } from '../campaign/types';

const campaignsState = {
  ...fetch,
  allIds: [],
  byId: {},
  loading: true,
  moreLoading: false,
};

const campaigns = (state = campaignsState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_CAMPAIGNS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.GET_ALL_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ...payload.campaignsLookups,
        ...payload.paged,
      };

    case types.GET_ALL_CAMPAIGNS_FAIL:
      return {
        ...state,
        loading: false,
        allIds: [],
        byId: {},
        error: payload.error,
      };

    case POST_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.SELECT_ROW:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            expand: !state.byId[payload.id].expand,
          },
        },
      };

    case types.SET_STATUS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            state: payload.status,
          },
        },
      };

    case types.UPDATE_BANNER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            creative: {
              banners: [...payload.banners],
            },
          },
        },
      };

    case types.UPDATE_SCHEDULING:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId[payload.id],
            scheduling: {
              ...state.byId[payload.id].scheduling,
              ...payload.scheduling,
            },
          },
        },
      };

    case types.LOAD_MORE_CAMPAIGNS:
      return {
        ...state,
        moreLoading: true,
      };

    case types.LOAD_MORE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        moreLoading: false,
        ...payload.paged,
        ...payload.campaignsLookups,
      };

    case types.LOAD_MORE_CAMPAIGNS_FAIL:
      return {
        ...state,
        moreLoading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};

export default campaigns;
