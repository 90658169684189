const root = 'map/template';

const GET_TEMPLATE = `${root}/GET`;
const GET_TEMPLATE_SUCCESS = `${root}/GET_SUCCESS`;
const GET_TEMPLATE_FAIL = `${root}/GET_FAIL`;
const POST_TEMPLATE = `${root}/POST`;
const POST_TEMPLATE_SUCCESS = `${root}/POST_SUCCESS`;
const POST_TEMPLATE_FAIL = `${root}/POST_FAIL`;
const SET_ERROR = `${root}/SET_ERROR`;
const CLEAR_ERROR = `${root}/CLEAR_ERROR`;
const NEW_TEMPLATE = `${root}/NEW`;
const SET_TEMPLATE_FIELD = `${root}/SET_FIELD`;
const ADD_TAG = `${root}/ADD_TAG`;
const REMOVE_TAG = `${root}/REMOVE_TAG`;

export {
  GET_TEMPLATE,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATE_FAIL,
  POST_TEMPLATE,
  POST_TEMPLATE_SUCCESS,
  POST_TEMPLATE_FAIL,
  SET_ERROR,
  CLEAR_ERROR,
  NEW_TEMPLATE,
  SET_TEMPLATE_FIELD,
  ADD_TAG,
  REMOVE_TAG,
};
