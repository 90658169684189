import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { withAppConfig } from 'app/decorators';
import { fetchGaTypes as opFetchGaTypes } from 'app/ducks/bannerManagement/gaTypes/operations';
import { mapStateToGaTypes } from 'app/ducks/bannerManagement/gaTypes/selectors';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';

const mapDispatchToProps = {
  fetchGaTypes: opFetchGaTypes,
};

export default compose(
  withAppConfig,
  connect(mapStateToGaTypes, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { appConfig, fetchGaTypes, gaTypes, gaTypesLoading } = this.props;

      const { useGACategory } = getBannerCreativeConfig(appConfig);

      if (useGACategory && !(gaTypes || []).length && !gaTypesLoading) {
        fetchGaTypes();
      }
    },
  }),
);
