import * as React from 'react';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Moment from 'moment';

import { DialogShell } from 'app/components';
import { utcToLocal } from 'app/ducks/campaigns/campaign/operations';
import { updateScheduling } from 'app/api/campaigns/scheduling';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { localToUtc, mapScheduling } from 'app/ducks/campaigns/campaign/helpers';
import { sc } from 'app/styles';
import { Action, ICampaign } from 'app/types';
import { IScheduling } from 'app/types/Scheduling';
import { IUser } from 'app/types/User';

import { PartialSchedule } from './PartialSchedule';

const updateExpiryDate = withStateHandlers(({ campaign: { expiryDate, expiryTime } }) => ({ expiryDate, expiryTime, loading: false }), {
  handleChangeExpiryDateAndTime: state => (moment: Moment) => {
    const { date = '', time = '' } = moment || {};
    return { ...state, expiryDate: date, expiryTime: time };
  },
  setLoading: state => loading => ({ ...state, loading }),
});

const handleActions = withHandlers({
  handleClick:
    ({ campaign, closeModal, setLoading, setCampaignField, schedulingUpdate, user, expiryDate, expiryTime }) =>
    okay => {
      if (!okay) {
        return closeModal();
      }

      const expiryDateTime = (expiryDate && localToUtc(`${expiryDate}T${expiryTime}`, user.timezone)) || '';

      const scheduling = {
        id: campaign.scheduleId,
        ...mapScheduling(campaign, user.timezone),
        expiryDate: expiryDateTime,
      };

      setLoading(true);
      return updateScheduling(scheduling)
        .then(res => {
          if (schedulingUpdate) {
            schedulingUpdate(campaign.id, res);
          }

          if (setCampaignField) {
            setCampaignField(utcToLocal(res, user.timezone));
          }

          displaySuccess('Scheduling updated');
          return closeModal();
        })
        .catch(() => {
          displayError('Error saving banner ids');
          return setLoading(false);
        });
    },
});

const enhance = compose(updateExpiryDate, handleActions);

type Props = {
  campaign: ICampaign;
  closeModal: () => void;
  handleClick: (arg0: boolean) => void;
  loading: boolean;
  open: boolean;
  schedulingUpdate?: (arg0: number, arg1: IScheduling) => void;
  setCampaignField: (arg0: Record<string, any>) => Action;
  user: IUser;
};

export const PartialScheduleUpdateModal = enhance(({ open, campaign, loading, handleClick, closeModal, ...props }: Props) => (
  <DialogShell isOpen={open} okayButtonText="Save" onClose={handleClick} dialogProps={{ fullWidth: true }}>
    <Container>
      <Typography variant="h6" gutterBottom>
        Edit Expiry
      </Typography>

      <PartialSchedule id="PartialScheduleUpdateModal" isDisabled closeModal={closeModal} {...campaign} {...props} />
    </Container>
  </DialogShell>
));

const Container = styled.div`
  margin: ${sc.gutter};
`;
