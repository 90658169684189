import PropTypes from 'prop-types';
import { lifecycle, compose } from 'recompose';
import { connect } from 'react-redux';

import * as operations from './operations';
import { readMe, isLoading, readCampaign } from './selectors';

const mapStateToProps = state => {
  const features = readMe(state);
  const campaign = readCampaign(state);

  return {
    features,
    campaign,
    isLoading: isLoading(features),
  };
};

const redux = connect(mapStateToProps, operations);

const fetchFeatures = lifecycle({
  componentDidMount() {
    this.props.refreshFeatureMetadata();
  },
  componentDidUpdate(prevProps) {
    const {
      campaign: { criteria: newCriteria },
    } = this.props;
    const {
      campaign: { criteria: oldCriteria },
    } = prevProps;
    if (!newCriteria) {
      return;
    }
    if (!oldCriteria || newCriteria.eventName !== oldCriteria.eventName) {
      this.props.refreshSchemaMeta();
    }
  },
});

export const FeaturesConnect = compose(redux, fetchFeatures);

const Features = ({ children, ...props }) => <>{children && children(props)}</>;

Features.propTypes = {
  children: PropTypes.func,
};

export default FeaturesConnect(Features);
