import update from 'immutability-helper';

import { BmsError } from 'app/api/bannerManagementV2/types';
import { Action, ICategory } from 'app/types';
import { IBannerCreative } from 'app/types/BannerManagement';

import activeViewItemsReducer from './activeViewItems';
import { emptyForm, emptyVariant, populateForm, populateFormVariants } from './helpers';
import * as types from './types';

type State = {
  error: BmsError | null | undefined;
  engageCategories?: Array<ICategory>;
  fetchCreativeLoading: boolean;
  form: IBannerCreative;
  formCloning: boolean;
  duplicateMetadataKeyFound: boolean;
};

const initialState: State = {
  error: null,
  fetchCreativeLoading: false,
  form: emptyForm(),
  formCloning: false,
  duplicateMetadataKeyFound: false,
};

const creativeReducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.RESET_CREATIVE_FORM:
      return update(state, {
        form: { $set: emptyForm() },
      });

    case types.SET_CREATIVE_FORM_ERRORS:
      return update(state, {
        form: {
          errors: { $set: payload.errors },
        },
      });

    case types.UPDATE_CREATIVE_FORM_FIELD:
      return update(state, {
        form: {
          [payload.field]: { $set: payload.value },
        },
      });

    case types.UPDATE_CREATIVE_FORM_ADD_VARIANT_GROUP: {
      const { newIndex } = payload;

      return update(state, {
        form: {
          variants: {
            [newIndex]: { $set: emptyVariant() },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_REMOVE_VARIANT_GROUP: {
      const { index } = payload;
      const variants = { ...state.form.variants };
      delete variants[index];

      return update(state, {
        form: {
          variants: { $set: variants },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_ADD_ENGAGE_CATEGORY_GROUP: {
      const { index, newIndex } = payload;

      return update(state, {
        form: {
          variants: {
            [index]: {
              engageCategories: {
                [newIndex]: { $set: {} },
              },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_DELETE_ENGAGE_CATEGORY_GROUP: {
      const { variantIndex, categoryIndex } = payload;
      const categories = { ...state.form.variants[variantIndex].engageCategories };
      delete categories[categoryIndex];

      return update(state, {
        form: {
          variants: {
            [variantIndex]: {
              engageCategories: { $set: categories },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_ADD_APP_VERSION_GROUP: {
      const { index, newIndex } = payload;

      return update(state, {
        form: {
          variants: {
            [index]: {
              appVersions: {
                [newIndex]: { $set: {} },
              },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_DELETE_APP_VERSION_GROUP: {
      const { variantIndex, versionIndex } = payload;
      const appVersions = { ...state.form.variants[variantIndex].appVersions };
      delete appVersions[versionIndex];

      return update(state, {
        form: {
          variants: {
            [variantIndex]: {
              appVersions: { $set: appVersions },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_FIELD: {
      const updatedVariant = { ...(state.form.variants[payload.index] || {}) };

      if (payload.field === 'landingPageType') {
        delete updatedVariant.url;
        delete updatedVariant.itemId;
      }

      updatedVariant[payload.field] = payload.value;

      return update(state, {
        form: {
          variants: {
            [payload.index]: { $set: updatedVariant },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_LOCATIONS_FIELD: {
      const variants = state.form.variants[payload.index] || {};
      const updatedLocations = variants.locations ? variants.locations : {};
      updatedLocations[payload.field] = payload.value;

      return update(state, {
        form: {
          variants: {
            [payload.index]: {
              locations: { $set: updatedLocations },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_ENGAGE_CATEGORIES_FIELD: {
      const categories = state.form.variants[payload.index].engageCategories;
      const category = categories[payload.catIndex];
      category[payload.field] = payload.value;

      return update(state, {
        form: {
          variants: {
            [payload.index]: {
              engageCategories: {
                [payload.catIndex]: { $set: category },
              },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_APP_VERSIONS_FIELD: {
      const variants = state.form.variants[payload.index] || {};
      const appVersions = variants.appVersions ? variants.appVersions : {};
      let appVersion = appVersions[payload.versionIndex];

      if (!appVersion) {
        appVersion = { [payload.field]: payload.value };
        appVersions[payload.versionIndex] = appVersion;

        return update(state, {
          form: {
            variants: {
              [payload.index]: {
                appVersions: { $set: appVersions },
              },
            },
          },
        });
      }

      appVersion[payload.field] = payload.value;

      return update(state, {
        form: {
          variants: {
            [payload.index]: {
              appVersions: {
                [payload.versionIndex]: { $set: appVersion },
              },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_LABEL_FIELD: {
      let labelDetails = { ...state.form.variants[payload.index].labelDetails };

      if (!payload.value || !payload.value.length) {
        if (payload.field === 'label') {
          labelDetails = {};
        } else {
          delete labelDetails[payload.field];
        }
      } else {
        labelDetails[payload.field] = payload.value;
      }

      return update(state, {
        form: {
          variants: {
            [payload.index]: {
              labelDetails: { $set: labelDetails },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_METADATA: {
      const { variantIndex, metadata } = payload;

      return update(state, {
        form: {
          variants: {
            [variantIndex]: {
              metadata: { $set: metadata },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_VARIANTS_PERSONALIZATION_METADATA: {
      const { variantIndex, personalizationMetadata } = payload;

      return update(state, {
        form: {
          variants: {
            [variantIndex]: {
              personalizationMetadata: { $set: personalizationMetadata },
            },
          },
        },
      });
    }

    case types.UPDATE_CREATIVE_FORM_SET_DUPLICATE_METADATA_FOUND: {
      const { duplicateMetadataFound } = payload;

      return update(state, {
        duplicateMetadataKeyFound: { $set: duplicateMetadataFound },
      });
    }

    // Save creative
    case types.SAVE_CREATIVE_START: {
      return update(state, {
        isSaving: { $set: true },
      });
    }

    case types.SAVE_CREATIVE_SUCCESS: {
      return update(state, {
        isSaving: { $set: false },
        error: { $set: null },
        form: { $set: initialState.form },
      });
    }

    case types.SAVE_CREATIVE_ERROR: {
      return update(state, {
        isSaving: { $set: false },
        error: { $set: payload.error },
      });
    }

    // Fetch creative
    case types.FETCH_CREATIVE: {
      return update(state, {
        fetchCreativeLoading: { $set: true },
      });
    }

    case types.FETCH_CREATIVE_SUCCESS: {
      return update(state, {
        fetchCreativeLoading: { $set: false },
        form: { $set: payload.creative },
        error: { $set: null },
      });
    }

    case types.FETCH_CREATIVE_ERROR: {
      return update(state, {
        fetchCreativeLoading: { $set: false },
        form: { $set: initialState.form },
        error: { $set: payload.error },
      });
    }

    case types.POPULATE_CREATIVE_FORM_VARIANTS: {
      // viewItem is actually either a viewItem or a bannerCreative
      const { engageCategories, viewItem } = payload;
      const populatedVariants = populateFormVariants(viewItem.variants, {
        categories: engageCategories,
      });

      return update(state, {
        form: {
          variants: { $set: populatedVariants },
          populated: { $set: true },
        },
      });
    }

    case types.POPULATE_CREATIVE_FORM_START: {
      return update(state, {
        formCloning: { $set: true },
      });
    }

    case types.POPULATE_CREATIVE_FORM_SUCCESS: {
      const { creative } = payload;
      const form = populateForm(creative, {
        new: true,
        categories: state.engageCategories,
      });

      return update(state, {
        form: { $set: form },
        formCloning: { $set: false },
      });
    }

    case types.POPULATE_CREATIVE_FORM_ERROR: {
      return update(state, {
        formCloning: { $set: false },
      });
    }

    default:
      return state;
  }
};

export default (state: Record<string, any> = initialState, { type, payload }: Action) => {
  const activeViewItems = activeViewItemsReducer(state.activeViewItems, { type, payload });

  const nextState = {
    ...state,
    activeViewItems,
  };

  return creativeReducer(nextState, { type, payload });
};
