import { ISuggestion } from 'app/types';
import { IBannerCampaignSpec } from 'app/types/Creatives/BannerCreative';

export const compareBannerSpecs = (a: IBannerCampaignSpec, b: IBannerCampaignSpec) => {
  if (!b) return 1;
  if (!a) return -1;

  if (a.storefrontId !== b.storefrontId) return (a.storefrontId || 0) - (b.storefrontId || 0);

  if (a.viewId !== b.viewId) return (a.viewId || 0) - (b.viewId || 0);

  return (a.id || 0) - (b.id || 0);
};

export const handleSelectSuggestion =
  (src: Array<any> | null | undefined, setSelectedState: (...args: Array<any>) => any) => (sugg: ISuggestion) => {
    const selected = src?.find(({ id }) => id === sugg.id);
    if (selected) {
      setSelectedState(selected);
    }
  };

export const handleChangeSearchValue =
  (setSearchFn: (arg0: string) => void, setSelectedState: (...args: Array<any>) => any) => (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = ev.currentTarget;
    setSearchFn(value);
    if (!(value || '').trim()) {
      setSelectedState(null);
    }
  };

export const toSuggestion = ({ id, name }: { id: number; name: string }) => ({ id, name: toTitle({ id, name }) });

export const toTitle = ({ id, name }: { id: number; name: string }) => (id > 0 ? `[${id}] ${name}` : name);
