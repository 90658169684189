import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import withAppConfig from 'app/decorators/withAppConfig';
import { IAppConfig } from 'configs/apps/types';
import * as RoutesConfig from 'configs/routes';
import { sc } from 'app/styles';
import { isEmailValid } from 'app/utilities/strings';
import {
  Button as _Button,
  Icon,
  Input,
  Page,
  Pills as _Pills,
  PageHeader as _PageHeader,
  PageMain as _PageMain,
} from 'app/midgarComponents';

import { Loading } from 'app/components';
import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';
import { createNewUser, fetchUser } from 'app/api/users';
import { ProfilesModal } from './ProfilesModal';
import { fetchUserProfiles } from 'app/api/users';
import { fetchStorefronts } from '../../../api/bannerManagementV2/storefronts';
import { fetchViews } from '../../../api/bannerManagementV2/views';
import { AllStoreFrontsQuery } from '../../../ducks/storefrontsBms/operations';

type Props = {
  match: {
    params: {
      email: string;
    };
  };

  appConfig: IAppConfig;
};

type DropdownItem = {
  name: string;
};

type State = {
  email: string;
  isNewUser: boolean;
  loading: boolean;
  permissions: Set<string>;
  permissionTypeIn: string;
  tenant: string;
  valid: {
    email: boolean;
    permissions: boolean;
  };

  openModal: boolean;
};

class NewUser extends React.Component<Props, State> {
  state: State = {
    email: '',
    isNewUser: true,
    loading: false,
    permissions: new Set(),
    permissionTypeIn: '',
    tenant: 'PAYPAY_CONSUMER',
    valid: {
      email: false,
      permissions: false,
    },

    openModal: false,
    shouldShowStorefront: false,
    storefrontsData: {},
    selectedStorefrontAndViews: {},
    storefrontInput: '',
    viewInput: '',
  };

  bannerPermissionSet = [];

  componentDidMount = async () => {
    const {
      match: {
        path,
        params: { email },
      },
    } = this.props;
    if (email) {
      const isNew = path === RoutesConfig.USERS_COPY;
      this.setState({
        loading: true,
      });

      try {
        fetchUser(email)
          .then(async userObj => {
            const userStorefrontsAndViews = userObj.storefrontsAndViews || {};

            const userStorefronts = Object.keys(userStorefrontsAndViews);

            const viewPromises = userStorefronts.map(storefrontId => () => this.getViewData(storefrontId));

            const [storefrontData, ...viewsData] = await Promise.all([
              this.getStorefrontData(),
              ...viewPromises.map(promiseFn => promiseFn()),
            ]);

            userStorefronts.forEach((storefrontId, i) => {
              storefrontData[storefrontId].views = viewsData[i];
            });

            this.setState({
              ...userObj,
              email: isNew ? '' : userObj.email,
              isNewUser: isNew,
              loading: false,
              permissions: new Set(userObj.permissions),
              selectedStorefrontAndViews: userStorefrontsAndViews,
              storefrontsData: storefrontData,
              valid: {
                email: !isNew,
                permissions: true,
              },
            });

            return null;
          })
          .catch(err => this.handleFetchErr(err));
      } catch (err) {
        this.handleErr(err);
      }
    }

    const userProfileData = await fetchUserProfiles();

    this.bannerPermissionSet = userProfileData?.content?.find(elem => elem.email === 'PROFILE_BANNER_MANAGER')?.permissions || [];
  };

  componentDidUpdate = async () => {
    const shouldShowStorefront = this.bannerPermissionSet.some(perm => this.state.permissions.has(perm));

    if (this.state.shouldShowStorefront !== shouldShowStorefront) {
      this.setState(state => ({
        ...state,
        shouldShowStorefront,
      }));
    }

    if (shouldShowStorefront && !Object.keys(this.state.storefrontsData).length) {
      const storefrontsData = await this.getStorefrontData();

      this.setState(state => ({
        ...state,
        storefrontsData,
      }));
    }
  };

  getStorefrontData = async () => {
    const storefronts = await fetchStorefronts(AllStoreFrontsQuery);

    return storefronts.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  };

  handleChange = event => {
    const {
      target: { name, value },
    } = event;
    this.updateInput(name, value);
  };

  handleErr = err => {
    this.setState({ loading: false });
    displayError(`Failed to add user: ${err.error}`);
  };

  handleFetchErr = err => displayError(`Failed to fetch user ${err.error}`);

  handleSuccess = () => {
    const { email, isNewUser } = this.state;
    const message = isNewUser ? `${email} was successfully added.` : `${email} was successfully updated.`;
    displaySuccess(message);
    this.setState({ loading: false });
  };

  isValid = () => {
    const { valid } = this.state;
    return Object.values(valid).every(item => item);
  };

  setPermissions = (permissions: string[]) => {
    const permissionsSet = new Set(permissions);
    this.setState({
      permissions: permissionsSet,
    });

    this.validate('permissions', permissionsSet);
  };

  addSinglePermission = ({ name }: DropdownItem) => {
    this.setState(prevState => {
      const updatedPermissions = new Set(prevState.permissions); //have cloned prevState.permissions to maintain immutability
      updatedPermissions.add(name);
      this.validate('permissions', updatedPermissions);

      return { permissions: updatedPermissions };
    });
  };

  removeSinglePermission = ({ name }: DropdownItem) => {
    this.setState(prevState => {
      const updatedPermissions = new Set(prevState.permissions); //same as above
      updatedPermissions.delete(name);
      this.validate('permissions', updatedPermissions);

      return { permissions: updatedPermissions };
    });
  };

  save = () => {
    this.setState({ loading: true });
    const { email, isNewUser, tenant, selectedStorefrontAndViews } = this.state;

    const userObj = {
      email,
      tenant,
      permissions: this.getPermissionsArray(),
      ...(Object.keys(selectedStorefrontAndViews).length && { storefrontsAndViews: selectedStorefrontAndViews }),
    };

    try {
      createNewUser(userObj, isNewUser)
        .then(() => this.handleSuccess())
        .catch(err => this.handleErr(err));
    } catch (err) {
      this.handleErr(err);
    }
  };

  updateInput = (name, value) => {
    this.setState({
      [name]: value,
    });

    this.validate(name, value);
  };

  validate = (name, value) => {
    const { valid } = this.state;

    this.setState({
      valid: {
        ...valid,
        [name]: name === 'email' ? isEmailValid(value) : name === 'permissions' ? value.size > 0 : value.trim().length > 0, // Check if permissions set is not empty
      },
    });
  };

  setOpenModal = (openModal: boolean) => {
    this.setState({
      openModal,
    });
  };

  changePermissionTypeIn = (value: string) => {
    this.setState({
      permissionTypeIn: value,
    });
  };

  getViewData = async storefrontId => {
    const viewData = await fetchViews(storefrontId);

    return viewData.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  };

  onStorefrontSelect = async ({ id: storefrontId }) => {
    this.setState(prevState => ({
      selectedStorefrontAndViews: {
        ...prevState.selectedStorefrontAndViews,
        [storefrontId]: [],
      },
    }));

    const viewData = await this.getViewData(storefrontId);

    this.setState(prevState => {
      const updatedStorefrontData = { ...prevState.storefrontsData };
      updatedStorefrontData[storefrontId].views = viewData;
      return { storefrontsData: updatedStorefrontData };
    });
  };

  onStorefrontDeselect = ({ id }) =>
    this.setState(prevState => {
      const updatedStorefront = { ...prevState.selectedStorefrontAndViews };
      delete updatedStorefront[id];

      return { selectedStorefrontAndViews: updatedStorefront };
    });

  onViewSelect = ({ id, storefrontId }) =>
    this.setState(prevState => ({
      selectedStorefrontAndViews: {
        ...prevState.selectedStorefrontAndViews,
        [storefrontId]: [...prevState.selectedStorefrontAndViews[storefrontId], id],
      },
    }));

  onViewDeselect = ({ id, storefrontId }) =>
    this.setState(prevState => {
      const selectedViews = prevState.selectedStorefrontAndViews[storefrontId];
      const updatedViews = selectedViews.filter(viewId => viewId !== id);

      return {
        selectedStorefrontAndViews: {
          ...prevState.selectedStorefrontAndViews,
          [storefrontId]: updatedViews,
        },
      };
    });

  getPermissionsArray = () => Array.from(this.state.permissions);

  getStorefrontsDataArray = () => Object.values(this.state.storefrontsData);

  getViewsArray = storefrontId => Object.values(this.state.storefrontsData[storefrontId].views || {});

  getStorefrontSuggestions = () =>
    this.getStorefrontsDataArray().map(({ id, name }) => ({
      id,
      name: `(${id}) ${name}`,
    }));

  getSelectedStorefronts = () =>
    Object.keys(this.state.selectedStorefrontAndViews).map(storefrontId => ({
      id: storefrontId,
      name: `(${storefrontId}) ${this.state.storefrontsData?.[storefrontId].name}`,
    }));

  getViewSuggestions = storefrontId =>
    this.getViewsArray(storefrontId)?.map(({ id, name }) => ({
      id,
      name: `(${id}) ${name}`,
      storefrontId,
    }));

  getSelectedViews = storefrontId =>
    this.state.selectedStorefrontAndViews[storefrontId]?.map(viewId => ({
      id: viewId,
      name: `(${viewId}) ${this.state.storefrontsData[storefrontId].views[viewId].name}`,
      storefrontId,
    }));

  render() {
    const { email, isNewUser, loading, permissionTypeIn, valid, openModal } = this.state;

    const { appConfig } = this.props;
    const { supportedPermissions } = appConfig;

    return (
      <Page>
        <PageHeader title="Add New User">
          <BackButton to="/users">Back to Users List</BackButton>
          <Button onClick={this.save} disabled={!this.isValid()}>
            {isNewUser ? 'Create User' : 'Update User'}
          </Button>
        </PageHeader>

        <PageMain>
          {loading ? <Loading /> : null}
          <>
            <Row>
              <Label>Email</Label>
              <Input
                value={email}
                name="email"
                disabled={!isNewUser}
                required
                onChange={this.handleChange}
                placeholder="email@paypay-corp.co.jp"
              />

              {valid.email ? <ValidationIcon name="check-circle" size={28} color={sc.success} /> : null}
            </Row>
            <Row>
              <Label>Permissions</Label>

              <Pills
                required
                id="SelectPermissions"
                name="Select Permissions"
                label="Select Permissions"
                suggestions={supportedPermissions.map(permission => ({ id: permission, name: permission }))}
                selected={this.getPermissionsArray().map(permission => ({ id: permission, name: permission }))}
                loading={false}
                value={permissionTypeIn}
                onChange={evt => this.changePermissionTypeIn(evt.target.value)}
                onSelect={this.addSinglePermission}
                onRemove={this.removeSinglePermission}
              />

              <ProfileButton type="tertiary" onClick={() => this.setOpenModal(true)}>
                Or Assign Profile
              </ProfileButton>
            </Row>
            {this.state.shouldShowStorefront && (
              <Row>
                <Label>Storefronts</Label>
                <Pills
                  required
                  id="SelectStorefront"
                  name="Select Strorefronts"
                  label="Select Storefronts"
                  suggestions={this.getStorefrontSuggestions()}
                  selected={this.getSelectedStorefronts()}
                  loading={!this.getStorefrontsDataArray().length}
                  value={this.state.storefrontInput}
                  onChange={e => {
                    this.setState({
                      storefrontInput: e.target.value,
                    });
                  }}
                  onSelect={this.onStorefrontSelect}
                  onRemove={this.onStorefrontDeselect}
                />
              </Row>
            )}

            {Object.keys(this.state.selectedStorefrontAndViews).map(storefrontId => (
              <Row key={storefrontId}>
                <Label>
                  Storefront - <SubLabel>({storefrontId})</SubLabel>
                  {this.state.storefrontsData[storefrontId].name}
                </Label>
                <Pills
                  required
                  id={`SelectViews-${storefrontId}`}
                  name="Select Views"
                  label={`Select Views for Storefront ${this.state.storefrontsData[storefrontId].name}`}
                  suggestions={this.getViewSuggestions(storefrontId)}
                  selected={this.getSelectedViews(storefrontId)}
                  loading={!this.state.storefrontsData[storefrontId].views}
                  value={this.state.viewInput}
                  onChange={e => {
                    this.setState({
                      viewInput: e.target.value,
                    });
                  }}
                  onSelect={this.onViewSelect}
                  onRemove={this.onViewDeselect}
                />
              </Row>
            ))}
          </>
        </PageMain>
        {openModal && (
          <ProfilesModal
            openModal={openModal}
            setOpenModal={this.setOpenModal}
            setPermissions={this.setPermissions}
            permissionsArr={this.getPermissionsArray()}
          />
        )}
      </Page>
    );
  }
}

export default withAppConfig(NewUser);

const BackButton = styled(Link)`
  font-size: 0.75rem;
  text-decoration-line: none;
  line-height: 1.25rem;
  color: ${sc.primary};
  left: 2rem;
  top: 10px;
  position: absolute;

  &:before {
    content: ' ◂';
    font-size: 1.25rem;
    margin-right: 3px;
  }
`;

const Button = styled(_Button)`
  float: right;
  margin-right: 2rem;
`;

const ProfileButton = styled(_Button)`
  margin-left: 1rem;
`;

const Label = styled.label`
  margin: 1rem;
  width: 220px;
  text-align: left;
  color: ${sc.headingColor};
`;

const PageHeader = styled(_PageHeader)`
  padding: 3rem 2rem 2rem 2rem;
`;

const PageMain = styled(_PageMain)`
  width: 100%;
  padding: 2rem 1rem;
`;

const Row = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;

  input {
    width: 75%;
    max-width: 500px;
  }
`;

const Pills = styled(_Pills)`
  width: 75%;
  max-width: 500px;
  margin-bottom: 1rem;
`;

const SubLabel = styled.label`
  margin: 0;
  text-align: left;
  font-size: ${sc.fontSizeSmaller};
  color: ${sc.greyLight};
`;

const ValidationIcon = styled(Icon)`
  margin: 1rem;
  font-weight: 900;
`;
