import React from 'react';
import PropTypes from 'prop-types';

import { OrRuleGroup, SegmentCount } from 'app/components';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { TextField, DateTimeUtcField } from 'app/midgarComponents';
import styled from 'styled-components';
import { sc } from 'app/styles';

const onCalcuate = (validateRules, calculate) => () => {
  const { isValid, errorMessage, rules } = validateRules();

  if (isValid) calculate(rules);
  else displayError(errorMessage);

  return isValid;
};

export const Rules = ({
  rules,
  errors = [],
  pathDict,
  originDict,
  pathsOfFeature,
  optionsByPathKey,
  newRowValue,
  initialRuleForGroup,
  handleExtractionDetails,
  updateState,
  validateRules,
  countOfQuery,
  classes,
  audience,
}) => (
  <section className={classes.customContainer}>
    <SegmentCount calculate={onCalcuate(validateRules, countOfQuery)} classes={classes} calcOnLoad={false} />
    <Row>
      <CoreFields>
        <Row>
          <TextField
            label="Extraction Interval"
            name="extractionIntervalInHours"
            type={'number'}
            value={audience.extractionIntervalInHours || ''}
            onChange={({ target: { value } }) => handleExtractionDetails({ extractionIntervalInHours: value > 0 ? value : 1 })}
            required
          />
        </Row>

        <Row>
          <DateTimeUtcField
            id="firstExtractionAt"
            label="First Extraction"
            name="firstExtractionAt"
            onChange={value => handleExtractionDetails({ firstExtractionAt: value })}
            required
            value={audience.firstExtractionAt || ''}
          />

          <DateTimeUtcField
            id="willBeUpdatedUntil"
            label="Extraction Ends"
            name="willBeUpdatedUntil"
            onChange={value => handleExtractionDetails({ willBeUpdatedUntil: value })}
            required
            value={audience.willBeUpdatedUntil || ''}
          />
        </Row>
      </CoreFields>
    </Row>
    <OrRuleGroup
      data={rules}
      newItem={newRowValue}
      {...{
        errors,
        pathDict,
        originDict,
        pathsOfFeature,
        optionsByPathKey,
        initialRuleForGroup,
        updateState,
      }}
    />
  </section>
);

Rules.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  pathDict: PropTypes.objectOf(PropTypes.array).isRequired,
  originDict: PropTypes.objectOf(PropTypes.object).isRequired,
  pathsOfFeature: PropTypes.func.isRequired,
  optionsByPathKey: PropTypes.func.isRequired,
  newRowValue: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialRuleForGroup: PropTypes.shape({
    key: PropTypes.number.isRequired,
    value: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  updateState: PropTypes.func.isRequired,
  audience: PropTypes.objectOf(PropTypes.string).isRequired,
  validateRules: PropTypes.func.isRequired,
  countOfQuery: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const Row = styled.section`
  display: flex;
  margin-top: ${sc.gutterSmaller};
  & > div,
  & > section {
    width: 100%;
    margin-right: ${sc.gutterSmall};
  }
  & > div:last-child,
  & > section:last-child {
    margin-right: 0;
  }
`;

const CoreFields = styled.section`
  flex-grow: 1;
  margin-bottom: -${sc.gutterSmaller};
`;
