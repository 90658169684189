import * as types from './types';

const addEventScema = payload => ({
  type: types.ADD_EVENT_SCHEMA,
  payload,
});

const getAllEvents = payload => ({
  type: types.GET_ALL_EVENTS,
  payload,
});

const getCustomerEvent = payload => ({
  type: types.GET_CUSTOMER_EVENT,
  payload,
});

const initLoadingCustomerEvent = () => ({
  type: types.INIT_CUSTOMER_EVENT,
});

export { addEventScema, getAllEvents, getCustomerEvent, initLoadingCustomerEvent };
