import * as types from './types';

const updateTimelineNotificationContent = value => {
  return {
    type: types.UPDATE_TIMELINE_NOTIFICATION_CONTENT,
    payload: value,
  };
};

const updatePayloadContent = value => {
  return {
    type: types.UPDATE_PAYLOAD_CONTENT,
    payload: value,
  };
};

const updateCategoryList = value => {
  return {
    type: types.UPDATE_CATEGORY_LIST,
    payload: value,
  };
};

const updatePush2Platform = value => ({
  type: types.UPDATE_PLATFORM,
  payload: value,
});

export { updateTimelineNotificationContent, updatePush2Platform, updatePayloadContent, updateCategoryList };
