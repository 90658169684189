import * as React from 'react';
import { Header } from './Header';
import { Body } from './Body';
import { Table, TableWrapper } from '../Table/Table';

export const EditableTable = props => (
  <TableWrapper>
    <Table>
      <Header {...props} />
      <Body {...props} />
    </Table>
  </TableWrapper>
);
