import { compose, withHandlers, withProps } from 'recompose';
import qs from 'query-string';

import { RouterHistory } from 'react-router-dom';
import { IQueryParams } from 'app/types';

/**
 * Parse the parameters in the query string (read from `history.location`).
 *
 * Injects the following properties:
 *  - `queryParams` The parsed parameters from the query string.
 *  - 'apiQueryParams' Maps the `queryParams` to names required by the API.
 *  - `pushQuery` Pushes the given query parameters to the URL (no merge performed).
 */
export const withUrlReader = compose(
  withProps(({ history, defaultQueryParams = {} }: { history: RouterHistory }): IQueryParams => {
    if (!history) {
      // eslint-disable-next-line no-console
      console.error(`withReporting: No history property passed to withUrlReader. Cannot read reportin parameters from the history.
Ensure that the reporting container is the top level on the page, that you use the withRouter HOC, or pass the history property to this element explicitly.
Alternatively, if the reporting state is not taken from the url, pass the state directly as an object.
        `);
      // console.error('No history property passed to withUrlReader. If you mean to use withPropsReader, pass reportingState.');
      return undefined;
    }
    const queryParams = qs.parse(history.location.search);
    for (const property in defaultQueryParams) {
      if (!queryParams[property]) {
        queryParams[property] = defaultQueryParams[property];
      }
    }
    return {
      queryParams,
      apiQueryParams: queryParams,
    };
  }),
  withHandlers({
    pushQuery:
      ({ history }: { history: RouterHistory }) =>
      (newQueryParams: IQueryParams) =>
        history.push(`${history.location.pathname}?${qs.stringify(newQueryParams)}`),
  }),
);
