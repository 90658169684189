import { isNilOrEmpty, isNeitherNilNorEmpty, R } from 'app/helpers/RamdaHelpers/helpers';

const defaultTableSorter = (rows, sortOrder, sortColumn) => {
  const read = R.compose(R.defaultTo(''), R.prop(sortColumn));
  const compare = sortOrder === 'asc' ? R.ascend : R.descend;

  return R.sort(compare(read), rows);
};

const maxRowsPerPage = 100;

const computeRowsPerPageOptions = (numberOfRows = maxRowsPerPage) => [
  ...R.filter(R.gt(numberOfRows), [5, 10, 15, 20, 25, 30]),
  Math.min(maxRowsPerPage, numberOfRows),
];

const containedOrFirst = R.curry((x, xs) => (isNeitherNilNorEmpty(xs) ? (xs.includes(x) ? x : xs[0]) : x));

const containedOrLast = R.curry((x, xs) => (isNeitherNilNorEmpty(xs) ? (xs.includes(x) ? x : R.last(xs)) : x));

const rowsByPage = R.curry((pageIndex, rowsPerPage, rows) => {
  if (isNilOrEmpty(rows)) {
    return [];
  }

  const rowIndex = pageIndex * rowsPerPage;

  return rows.slice(rowIndex, rowIndex + rowsPerPage);
});

export { defaultTableSorter, computeRowsPerPageOptions, containedOrFirst, containedOrLast, rowsByPage };
