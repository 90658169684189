import { branch, compose, lifecycle, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import { featureOperations, featureSelectors, segmentOperations, segmentSelectors } from 'app/ducks';

import { Loading } from 'app/components';
import { pathDictLens, originDictLens } from 'app/helpers/FeatureMetadataHelpers/common';
import { optionsByBreadcrumb, pathsByFeatureName, seedDataError, withKey } from 'app/helpers/FeatureMetadataHelpers/helpers';
import { R } from 'app/helpers/RamdaHelpers/helpers';
import { withStyles } from '@material-ui/core/styles';
import { Rules } from './Rules';

import { validateRules } from './validation';
import { styles } from './styles';

const mapStateToProps = state => {
  const { readMe: readFeatureMeta, isLoading: isLoadingFeatureMeta, metaData, seedData } = featureSelectors;

  const featureData = readFeatureMeta(state);
  const featureDicts = metaData(featureData);
  const newRowValue = seedData(featureData);
  const segmentData = segmentSelectors.readMe(state);
  const initialRuleForGroup = withKey([withKey(newRowValue)]);

  return {
    isLoading: isLoadingFeatureMeta(featureData),
    pathDict: R.view(pathDictLens, featureDicts),
    originDict: R.view(originDictLens, featureDicts),
    pathsOfFeature: pathsByFeatureName(R.PAPH, featureDicts),
    optionsByPathKey: optionsByBreadcrumb(R.PAPH, featureDicts),
    newRowValue,
    initialRuleForGroup,
    rules: segmentData.ruleData || [initialRuleForGroup],
    errors: segmentData.includedRuleErrors || seedDataError,
    validateRules: () => validateRules(segmentData),
  };
};

const mapDispatchToProps = {
  fetchFeatMetadata: featureOperations.refreshFeatureMetadata,
  updateState: segmentOperations.setIncludedRules,
  countOfQuery: segmentOperations.countOfQuery,
};

const addLifeCyle = lifecycle({
  componentDidMount() {
    this.props.fetchFeatMetadata();
  },
});

const withLoading = branch(({ isLoading }) => isLoading, renderComponent(Loading));

const addStyles = withStyles(styles, { withTheme: true });

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), addLifeCyle, withLoading, addStyles);

export default enhance(Rules);
