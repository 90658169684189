export const StepperHelpers = {
  skippedSetAfterGoingNext: (activeStep, skipped) => new Set([...skipped].filter(a => a !== activeStep)),
  isStepSkipped: (step, skipped) => skipped.has(step),
  isFirstStep: step => step === 0,
  isLastStep: (activeStep, steps) => activeStep === steps.length - 1,
  isDone: (activeStep, steps) => activeStep >= steps.length,
  isStepOptional: step => step === 1,
  isStepRequired(step) {
    return !this.isStepOptional(step);
  },
  skippedSetAfterSkipping(step, skipped) {
    return this.isStepRequired(step) ? skipped : new Set([...skipped, step]);
  },
  isDisabled: (activeStep, steps) => !steps[activeStep].valid,
};
