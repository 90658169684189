import { IWidgetType } from 'app/types/BannerManagement';

import * as types from './types';

export const getWidgetTypesFailed = (error: string) => ({
  type: types.GET_WIDGET_TYPES_FAILED,
  payload: { error },
});

export const getWidgetTypesStart = () => ({
  type: types.GET_WIDGET_TYPES_START,
});

export const getWidgetTypesSuccess = (widgetTypes: Array<IWidgetType>) => ({
  type: types.GET_WIDGET_TYPES_SUCCESS,
  payload: { widgetTypes },
});
