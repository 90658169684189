import React from 'react';
import styled from 'styled-components';
import { sc } from 'app/styles';

type FieldError = { error: string | { [errorKey: string]: string } };

export default ({ error }: FieldError): JSX.Element | null => {
  const resolveErrorText = (): JSX.Element[] | JSX.Element =>
    typeof error === 'string' ? (
      <span data-qa="field-error-text">{error}</span>
    ) : (
      Object.keys(error).map(errorKey => (
        <span data-qa="field-error-text" key={`formError-${errorKey}`}>
          {error[errorKey]}
        </span>
      ))
    );

  return error && ['string', 'object'].includes(typeof error) ? <ErrorText>{resolveErrorText()}</ErrorText> : null;
};

const ErrorText = styled.div`
  color: ${sc.danger};
  font-size: ${sc.fontSizeSmaller};
  padding: 0.25rem 0;
  display: flex;
  flex-direction: column;
`;
