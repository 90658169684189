import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { TextField, DateTimeUtcField } from 'app/midgarComponents';
import { audienceOperations } from 'app/ducks';
import Target from './Target';
import styled from 'styled-components';
import { sc } from 'app/styles';

const mapStateToProps = state => {
  const {
    audience: { audience },
  } = state;

  return {
    audience: audience,
  };
};

const mapDispatchToProps = {
  handleExtractionDetails: audienceOperations.setExtractionDetails,
};

export const ComposeAudience = props => {
  const { audience, handleExtractionDetails } = props;
  return (
    <>
      <Row>
        <CoreFields>
          <Row>
            <TextField
              label="Composition Extraction Interval"
              name="compositionIntervalInHours"
              type={'number'}
              value={audience.compositionIntervalInHours || ''}
              onChange={({ target: { value } }) => handleExtractionDetails({ compositionIntervalInHours: value > 0 ? value : 1 })}
              required
            />
          </Row>

          <Row>
            <DateTimeUtcField
              id="firstExtractionAt"
              label="First Extraction"
              name="firstExtractionAt"
              onChange={value => handleExtractionDetails({ firstExtractionAt: value })}
              required
              value={audience.firstExtractionAt || ''}
            />

            <DateTimeUtcField
              id="willBeUpdatedUntil"
              label="Extraction Ends"
              name="willBeUpdatedUntil"
              onChange={value => handleExtractionDetails({ willBeUpdatedUntil: value })}
              required
              value={audience.willBeUpdatedUntil || ''}
            />
          </Row>
        </CoreFields>
      </Row>
      <Target />
    </>
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ComposeAudience);

const Row = styled.section`
  display: flex;
  margin-top: ${sc.gutterSmaller};
  margin-bottom: ${sc.gutterSmaller};
  & > div,
  & > section {
    width: 100%;
    margin-right: ${sc.gutterSmall};
  }
  & > div:last-child,
  & > section:last-child {
    margin-right: 0;
  }
`;

const CoreFields = styled.section`
  flex-grow: 1;
  margin-bottom: -${sc.gutterSmaller};
`;
