import React from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import { Undo as UndoIcon } from '@material-ui/icons';

import { R } from '../RamdaHelpers/helpers';

const { error, success, warn, info } = toast;

const config = {
  position: 'top-center',
};

const defaultMessage = 'Something went wrong';

const displayToast = R.curry((toastType, message) => {
  if (typeof message === 'object') {
    toastType(defaultMessage, config);
  } else {
    toastType(message || defaultMessage, config);
  }
});

const [displayError, displaySuccess, displayWarning, displayInfo] = R.map(displayToast, [error, success, warn, info]);

const ToastUndo = ({ message, undo }) => (
  <div>
    {message}
    <Button
      {...{
        onClick: () => {
          undo();
        },
        color: 'primary',
        style: { marginLeft: '1rem' },
      }}
    >
      <UndoIcon style={{ marginRight: '0.5rem' }} />
      Undo
    </Button>
  </div>
);

ToastUndo.propTypes = {
  message: PropTypes.string.isRequired,
  undo: PropTypes.func.isRequired,
};

const displayUndo = (message, undo) => toast.info(<ToastUndo {...{ message, undo }} />, config);

export { displayError, displaySuccess, displayWarning, displayInfo, displayUndo };
