import React from 'react';
import { connect } from 'react-redux';
import withAppConfig from 'app/decorators/withAppConfig';
import { IAppConfig } from 'configs/apps/types';
import * as campaignOps from 'app/ducks/campaigns/campaign/operations';
import styled from 'styled-components';
import { sc } from 'app/styles';
import { isMetricsEnabled } from '../../utils';
import { SectionContainer } from '../../SectionContainer';
import { compose } from 'recompose';

type Props = {
  appConfig: IAppConfig;
  mediumId: string;
  state: string;
  id: string;
  campaignOps: typeof campaignOps;
  metrics: Record<string, any>;
};

class MetricsDetails extends React.Component<Props> {
  componentDidMount() {
    const { appConfig, mediumId, state, id } = this.props;

    if (isMetricsEnabled(appConfig, mediumId, state)) {
      campaignOps.getMetrics(id);
    }
  }

  render() {
    const { metrics, appConfig, mediumId, state, id } = this.props;
    if (!isMetricsEnabled(appConfig, mediumId, state)) {
      return <></>;
    }
    if (!metrics) {
      return <></>;
    }
    const { delivered = '-', opens = '-', uniqueOpens = '-', uniqueClicks = '-', error } = metrics;
    if (error) {
      return (
        <Container>
          <Header>Metrics</Header>
          <ErrorContainer>Cannot find metrics for Campaign {id}</ErrorContainer>
        </Container>
      );
    }
    return (
      <Container>
        <Header>Metrics</Header>
        <Row>
          <Box>Delivered: {delivered}</Box>
          <Box>Open Rate: {opens}</Box>
          <Box>Unique Opens: {uniqueOpens}</Box>
          <Box>Unique Clicks: {uniqueClicks}</Box>
        </Row>
      </Container>
    );
  }
}

export default compose(
  withAppConfig,
  connect(
    ({
      campaigns: {
        campaign: { general },
      },
    }) => ({
      id: general.id,
      mediumId: general.mediumId,
      state: general.state,
      metrics: general.metrics,
    }),
  ),
)(MetricsDetails);

const Container = styled(SectionContainer)`
  padding: 1.75rem 1.625rem 3rem 1.625rem;
`;

const Header = styled.h2`
  font-size: 1.125em;
  font-weight: 900;
  color: rgba(41, 57, 79, 0.9);
`;

const Box = styled.div`
  border-radius: 8px;
  border: solid 1.5px rgba(0, 0, 0, 0.12);
  margin-right: 20px;
  padding: 10px;
`;

const Row = styled.div`
  margin-top: 20px;
  display: flex;
`;

const ErrorContainer = styled.section`
  color: ${sc.danger};
`;
