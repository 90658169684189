import * as React from 'react';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import { compose } from 'ramda';
import { Typography } from '@material-ui/core';
import { findErrors } from 'app/utilities/variable';

const setErrors = withProps(({ html, hashedValues, variables }) => ({
  errors: compose(findErrors)({ html: html || '', hashedValues, variables }),
}));

const VariableErrors = ({ errors }) => (
  <section>
    {errors.featureErrors.length ? (
      <Typography style={{ color: 'red' }}>
        {errors.featureErrors.join(', ')} {errors.featureErrors.length > 1 ? 'are not valid features' : 'is not a valid feature'}
      </Typography>
    ) : null}
    {errors.variableErrors.length ? (
      <Typography style={{ color: 'red' }}>{errors.variableErrors.join(', ')} requires a default value</Typography>
    ) : null}
  </section>
);

VariableErrors.propTypes = {
  errors: PropTypes.shape({
    variableErrors: PropTypes.arrayOf(PropTypes.string),
    featureErrors: PropTypes.arrayOf(PropTypes.string),
    totalErrors: PropTypes.number,
  }).isRequired,
};

export default setErrors(VariableErrors);
