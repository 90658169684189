import { withStateHandlers, compose } from 'recompose';

import { RuleMultiSelect } from './RuleMultiSelect';

const addStateHandlers = withStateHandlers(({ selected = [] }) => ({ value: selected }), {
  handleChange:
    ({ value: oldValue }, { onChange }) =>
    ({ target: { value: newValue } }) => {
      // if (new Set(oldValue) === new Set(newValue)) {
      if (oldValue === newValue) {
        return undefined;
      }

      onChange(newValue);
      return { value: newValue };
    },
});

const enhance = compose(addStateHandlers);

export default enhance(RuleMultiSelect);
