import { fetchStorefronts } from 'app/api/storefront';
import { ICmaStorefront } from 'app/api/storefront/types';
import { displayError } from 'app/helpers/NotificationHelpers/helpers';
import { DispatchFn, GetStateFn } from 'app/types';

import * as actions from './actions';
import { mapStateToStorefronts } from './selectors';

export const getStorefronts =
  () =>
  async (dispatch: DispatchFn, getState: GetStateFn): Promise<void> => {
    const { storefronts, storefrontsError, storefrontsLoading } = mapStateToStorefronts(getState());

    if (storefronts || storefrontsError || storefrontsLoading) {
      return;
    }

    dispatch(actions.getStorefrontsStart());

    try {
      const storefronts: Array<ICmaStorefront> = await fetchStorefronts();
      const { user } = getState();
      const userStorefronts = Object.keys(user.storefrontsAndViews).length ? Object.keys(user.storefrontsAndViews) : user.storefronts;
      const permittedStorefronts = storefronts.filter(storefront => userStorefronts?.includes(storefront.id.toString()));
      dispatch(actions.getStorefrontsSuccess({ permittedStorefronts, storefronts }));
    } catch (err) {
      displayError(`Error fetching storefronts: ${err.message}`);
      dispatch(actions.getStorefrontsError(err.message));
    }
  };
