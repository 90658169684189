import React, { PureComponent } from 'react';
import document from 'global/document';
import styled, { css } from 'styled-components';
import { sc } from 'app/styles';
import { ISuggestion } from 'app/types';

import Spinner from '../Spinner';

type Props = {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  id: string;
  label: string;
  loading?: boolean;
  name: string;
  onChange?: (ev: React.SyntheticEvent<HTMLInputElement>) => unknown;
  onFocus?: (ev: Event) => void;
  onSelect: (suggestion: ISuggestion) => void;
  required?: boolean;
  suggestions: ReadonlyArray<string | ISuggestion>;
  type?: string;
  value?: string;
};

type State = {
  active: boolean;
  focused: boolean;
  isOpen: boolean;
  value: string;
};

type Ref = { current: null | HTMLElement };

export default class Autocomplete extends PureComponent<Props, State> {
  wrapperRef: Ref;

  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value || '',
      isOpen: false,
      active: !!(props.value && props.value.length),
      focused: false,
    };

    this.wrapperRef = React.createRef();

    document.addEventListener('mousedown', this.handleOutsideClick);
    document.addEventListener('touchstart', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
    document.removeEventListener('touchstart', this.handleOutsideClick);
  }

  componentDidUpdate(prevProps: Props) {
    const { value, suggestions } = this.props;
    const suggested = suggestions ? suggestions.find(x => (typeof x === 'string' ? x === value : x.id === value)) : null;

    if (!prevProps.value && value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        value: suggested ? (typeof suggested === 'string' ? suggested : suggested.name) : value,
        active: !!(value && value.length),
      });
    }
  }

  onFocus = (ev: Event) => {
    const { onFocus, disabled } = this.props;

    if (!disabled) {
      this.setState({
        isOpen: true,
        active: true,
        focused: true,
      });

      if (typeof onFocus === 'function') {
        onFocus(ev);
      }
    }
  };

  onBlur = () => {
    const { value } = this.state;

    if (!value || !value.length) {
      return this.setState({ active: false, focused: false });
    }

    return this.setState({ focused: false });
  };

  onSelect = (suggestion: string | ISuggestion) => {
    const { onSelect } = this.props;
    onSelect(suggestion);

    if (typeof suggestion === 'string') {
      this.setState({
        value: suggestion,
        focused: false,
        isOpen: false,
      });
    } else {
      this.setState({
        value: suggestion.name,
        focused: false,
        isOpen: false,
      });
    }
  };

  onChange = (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const { onChange, disabled } = this.props;

    if (!disabled) {
      this.setState({ value: ev.target.value });

      if (typeof onChange === 'function') {
        onChange(ev);
      }
    }
  };

  handleOutsideClick = (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const node = this.wrapperRef.current;

    if (node && !node.contains(ev.target)) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    const { value, isOpen, active, focused } = this.state;
    const { id, name, label, required, suggestions, loading, className, error, disabled, type } = this.props;

    return (
      <Container ref={this.wrapperRef} className={className} $focused={focused || isOpen} $error={!!error} disabled={!!disabled}>
        <InputContainer>
          <InnerInput
            id={id}
            name={name}
            data-qa={name}
            value={value}
            required={!!required}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            type={type || 'text'}
          />

          <Label required={!!required} active={active}>
            {label}
          </Label>
        </InputContainer>

        {loading ? (
          <Suggestions isOpen={isOpen}>
            <StyledSpinner />
          </Suggestions>
        ) : suggestions && suggestions.length ? (
          <Suggestions isOpen={isOpen} data-qa={`${name}-options`}>
            {filterSuggestions(suggestions, value).map((suggestion: string | ISuggestion) => {
              const key = typeof suggestion === 'string' ? parseKey(suggestion) : suggestion.id;

              return (
                <li data-qa={`auto-suggestion-${key}`} key={`auto-suggestion-${key}`}>
                  <Suggestion onMouseDown={() => this.onSelect(suggestion)}>
                    {typeof suggestion === 'string' ? suggestion : suggestion.name}
                  </Suggestion>
                </li>
              );
            })}
          </Suggestions>
        ) : null}
      </Container>
    );
  }
}

const parseKey = (value: string): string => value.toLowerCase().trim().replace(' ', '-');
const filterSuggestions = (arr: ReadonlyArray<string | ISuggestion>, value: string) =>
  arr.filter(x => {
    if (typeof x === 'string') {
      return x.toLowerCase().indexOf(value.toLowerCase()) > -1;
    }

    return x.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
  });

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid ${props => (props.$error ? sc.danger : props.$focused ? sc.primary : sc.sectionBorderColor)};
  transition: border 150ms ease-in-out;

  opacity: ${props => (props.disabled ? 0.8 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const InputContainer = styled.div`
  position: relative;
`;

const InnerInput = styled.input`
  position: relative;
  width: 100%;
  height: 55px;
  border: none;
  padding: ${sc.gutter} ${sc.gutterSmall} ${sc.gutterSmallest};

  font-size: ${sc.fontSizeSmall};
  font-weight: bold;
  z-index: 10;
`;

const Label = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: ${sc.gutterSmall};

  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmall};
  pointer-events: none;

  transform: translate(0, -50%);
  transition: all 100ms ease-out;
  z-index: 11;

  ${props =>
    props.required
      ? css`
          &:after {
            content: ' *';
          }
        `
      : ''}

  ${props =>
    props.active
      ? css`
          top: 30%;
          font-size: ${sc.fontSizeSmaller};
        `
      : ''}
`;

const Suggestions = styled.ul`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(0, 100%);

  list-style: none;
  margin: 0;
  padding: 0;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  max-height: 300px;
  overflow-y: scroll;

  border: 1px solid ${sc.greyLighter};
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  z-index: 12;

  & > li {
    border-bottom: 1px solid ${sc.greyLighter};

    &:last-child {
      border-bottom: none;
    }
  }
`;

const Suggestion = styled.div`
  padding: ${sc.gutterSmall} ${sc.gutterSmall};
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: ${sc.greyLightest};
  }
`;

const StyledSpinner = styled(Spinner)`
  padding: ${sc.gutter};
`;
