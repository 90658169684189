export const styles = theme => ({
  label: {
    color: theme.palette.primary.dark,
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    borderBottom: '1px solid #ebebeb',
  },

  body: {
    height: 'calc(100% - 5rem)',
    position: 'relative',
    padding: '1rem',
    minHeight: '6rem',
    overflow: 'scroll',
  },

  default: {
    height: '100%',
    position: 'relative',
    boxShadow: theme.shadows[3],
    overflow: 'scroll',
    '& > header': {
      background: '#ebebeb',
    },
  },

  primary: {
    height: '100%',
    position: 'relative',
    overflow: 'scroll',
    boxShadow: theme.shadows[3],
    '& > header': {
      background: theme.palette.background.paper,
    },
  },
});
