import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';

import { R } from 'app/helpers/RamdaHelpers/helpers';

const toMenuItem = R.curry((classes, selections, value) => (
  <MenuItem key={value} value={value} className={selections.includes(value) ? classes.boldFont : classes.regularFont}>
    {value}
  </MenuItem>
));

export const RuleMultiSelect = ({ id, options, handleChange, classes, label = '', value = [] }) => (
  <FormControl className={classes.formControl}>
    <InputLabel htmlFor={id}>{label}</InputLabel>

    <Select
      multiple
      value={value}
      onChange={handleChange}
      inputProps={{
        name: id,
        id,
      }}
      autoWidth
    >
      {R.map(toMenuItem(classes, value), options)}
    </Select>
  </FormControl>
);

RuleMultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
};
