export const styles = {
  inputContainer: {
    display: 'flex',
    marginBottom: '1rem',
    minHeight: 48,
    alignItems: 'center',
  },

  marginTop: {
    marginTop: '1rem',
  },

  marginBottom: {
    marginBottom: '1rem',
  },

  select: {
    minWidth: '5rem',
    margin: '0 1rem',
  },
};
