import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { useAppConfig } from 'app/hooks/useAppConfig';

import { cashbackTypes } from 'app/features/Cashback/constants';
import {
  fetchCashbackBanner,
  saveCashbackBanner,
  saveJourneyBanner,
  updateCashbackBanner,
  updateJourneyBanner,
} from 'app/api/cashbackPromo';

import { displayError, displaySuccess } from 'app/helpers/NotificationHelpers/helpers';

import { Button, Dropdown as _Dropdown, Page, PageHeader, PageMain, Spinner } from 'app/midgarComponents';

import { useReadOnly } from '../useReadOnly';

import { BannerForm } from './BannerForm';
import { useMetaData } from './useMetaData';

const OverView = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const readOnly = useReadOnly();
  const appConfig = useAppConfig();

  const [banner, setBanner] = useState({
    additionalAttributes: [],
    customBannerAttributes: {},
    priority: null,
    endDate: '',
    id: -1,
    startDate: '',
    title: '',
  });

  const [loadBanner, setLoadBanner] = useState(false);

  const [cashbackType, setCashbackType] = useState(cashbackTypes.regular);
  const { campaignAttributes, userBudgetTokens, loadMeta } = useMetaData(cashbackType);

  const [loadSave, setLoadSave] = useState(false);

  const {
    params: { id },
  } = match;
  const bannerId = Number(id);

  const search = history && history.location && history.location.search ? history.location.search : '';
  const query = queryString.parse(search);
  const { clone } = query;

  const pageTitle = bannerId ? (banner ? `[${bannerId}] ${banner.title || ''}` : '') : 'New Cashback Banner';

  useEffect(() => {
    if (bannerId) {
      const fetchBannerAPI = async _id => {
        setLoadBanner(true);
        try {
          const fetchedBanner = await fetchCashbackBanner(_id);
          setBanner(fetchedBanner);
          if (fetchedBanner.cashbackType) {
            setCashbackType(fetchedBanner.cashbackType);
          }
        } catch (error) {
          console.log(error);
          // display error
        } finally {
          setLoadBanner(false);
        }
      };
      fetchBannerAPI(bannerId);
    }
  }, [bannerId]);

  const handleCloneClicked = () => {
    setBanner({ ...banner, title: `${banner.title} (Copy)`, priority: null });
    history.push({
      pathname: '/cashback/banner/new',
      search: '?clone=true',
    });
  };

  const onSave = async values => {
    setLoadSave(true);
    if (!bannerId) {
      // create new
      try {
        const { id: newCreatedId } =
          cashbackType === cashbackTypes.regular ? await saveCashbackBanner(values) : await saveJourneyBanner(values);
        if (newCreatedId) {
          displaySuccess('Saved');
          history.push({
            pathname: `/cashback/banner/${newCreatedId}`,
          });
        } else {
          displayError('Save error from API: CASHBACK_BANNER_SAVE_02');
        }
      } catch (error) {
        displayError(error.message);
      } finally {
        setLoadSave(false);
      }
    } else {
      // update
      const withIdValues = {
        ...values,
        id: bannerId,
      };

      try {
        const { id: updatedCreatedId } =
          cashbackType === cashbackTypes.regular
            ? await updateCashbackBanner(withIdValues, bannerId)
            : await updateJourneyBanner(withIdValues, bannerId);
        if (updatedCreatedId) {
          displaySuccess('Updated!');
        } else {
          displayError('Update error from API: CASHBACK_BANNER_UPDATE_02');
        }
      } catch (error) {
        displayError(error.message);
      } finally {
        setLoadSave(false);
      }
    }
  };

  return (
    <Page>
      {loadBanner || loadMeta ? (
        <Spinner />
      ) : (
        <>
          <PageHeader title={pageTitle}>
            <RightContainer>
              {bannerId || clone
                ? null
                : appConfig.isJourneyEnabled && (
                    <SelectTypeDropdown
                      label="Select Type"
                      value={cashbackType}
                      options={Object.values(cashbackTypes)}
                      required
                      onChange={setCashbackType}
                    />
                  )}

              {clone !== 'true' ? (
                <Button
                  data-qa="clone-banner"
                  disabled={readOnly}
                  onClick={handleCloneClicked}
                  color="primary"
                  style={{ marginRight: '1rem' }}
                >
                  Clone
                </Button>
              ) : null}
            </RightContainer>
          </PageHeader>

          <PageMain>
            {banner && (
              <BannerForm
                readOnly={readOnly}
                banner={banner}
                saving={loadSave}
                cashbackType={cashbackType}
                userBudgetTokens={userBudgetTokens}
                campaignAttributes={campaignAttributes}
                onSubmit={onSave}
              />
            )}
          </PageMain>
        </>
      )}
    </Page>
  );
};

export default OverView;

const SelectTypeDropdown = styled(_Dropdown)`
  width: 15rem;
  margin-right: 1rem;
`;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4.5rem;
`;
