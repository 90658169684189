/* eslint-disable import/no-cycle */

import { findChannelSetup, getChannelNames, getChannels as _getChannels } from 'app/ducks/configStore/utils';
import { IAppConfig } from 'configs/apps/types';

import channelConfig from './channel.config';
import { IChannelConfig } from './types';

import { channelNameBanner, channelNameCashback, channelNameEmail, channelNamePush2, channelNameTimelineNotification } from './constants';

import { bannerMediumId, emailMediumId, push2MediumId, cashbackMediumId, notificationMediumId } from './mediumIds';

const Channels = {
  [channelNameBanner]: {
    id: bannerMediumId,
    config: {
      ...channelConfig[bannerMediumId],
      name: channelNameBanner,
    },
  },

  [channelNameCashback]: {
    id: cashbackMediumId,
    config: channelConfig[cashbackMediumId],
  },

  [channelNameEmail]: {
    id: emailMediumId,
    config: channelConfig[emailMediumId],
  },

  [channelNamePush2]: {
    id: push2MediumId,
    config: channelConfig[push2MediumId],
  },

  [channelNameCashback]: {
    id: cashbackMediumId,
    config: channelConfig[cashbackMediumId],
  },

  [channelNameTimelineNotification]: {
    id: notificationMediumId,
    config: channelConfig[notificationMediumId],
  },
};

export const getChannelsConfig = (appConfig?: IAppConfig): Record<number, IChannelConfig> => {
  const channelNames = getChannelNames(appConfig);
  return channelNames
    .map(name => [Channels[name].id, Channels[name].config])
    .reduce((acc, [mediumId, config]) => ({ ...acc, [mediumId]: config }), {});
};

export const getChannels = (appConfig: IAppConfig): Array<IChannelConfig> => {
  const channelNames = getChannelNames(appConfig);
  const reduxChannels = _getChannels(appConfig);
  return channelNames.map(name => {
    const { config } = Channels[name];
    const { subChannelsEnabled } = reduxChannels.find(channel => (channel.name ? channel.name === name : channel === name));
    if (subChannelsEnabled) {
      return {
        ...config,
        subChannels: config.subChannels.filter(sub => subChannelsEnabled.indexOf(sub.type) > -1),
      };
    }
    return config;
  });
};

export const getChannelCreativeConfig = (appConfig: IAppConfig, mediumId: number | void) => {
  if (Number.isNaN(Number(mediumId)) || !appConfig || !channelConfig[Number(mediumId)]) return {};

  const mediumName = (getChannelsConfig(appConfig)[Number(mediumId)] || {}).name;
  const foundChannelOptions = findChannelSetup(appConfig, mediumName);

  return (foundChannelOptions && foundChannelOptions.creative) || {};
};

// TODO:
// if a campaign is made with a disabled channel, you’ll get error:
// "message": "Validation Error",
// "details": "campaignCreationDto - Campaign id null with medium 'api' can't be created. Valid mediums are: promo, push",
