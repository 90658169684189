/* eslint-disable import/no-cycle */
import React from 'react';
import Notification from './Notification';
import DefaultSender from './DefaultSender';

const TimelineContent = () => {
  return (
    <>
      <Notification />
      <DefaultSender />
    </>
  );
};

export default TimelineContent;
