import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Match, RouterHistory } from 'react-router-dom';
import { compose } from 'ramda';
import styled from 'styled-components';

import { DrawerError } from 'app/components';
import { inject, withAppConfig } from 'app/decorators';
import { binderActions, binderOperations, storefrontsMgmtOperations } from 'app/ducks';
import {
  populateFormVariants as actionPopulateFormVariants,
  resetCreativeForm as actionResetCreativeForm,
} from 'app/ducks/bannerManagement/creative/actions';

import { Icon as _Icon, PageHeader as _PageHeader, Spinner as _Spinner } from 'app/midgarComponents';
import { sc } from 'app/styles';
import { Action, ICategory } from 'app/types';
import { ISlotGroupType, IView, IViewItem } from 'app/types/BannerManagement';
import result from 'app/utilities/result';
import { getBannerCreativeConfig } from 'configs/apps/creatives/banner';
import { IAppConfig } from 'configs/apps/types';
import { __DEV__ } from 'configs/settings';

import { isReadOnlyStorefront } from '../../common/helpers';
import Variants, { Heading } from '../../Creatives/CreativeEditor/Variants';
import withBannerDetailContext from '../../hoc/withBannerDetailContext';
import { HeaderTitle, HeaderTitleLink, Page, PageMain as _PageMain } from '../../common/components';
import { urlStorefrontEdit, urlStorefronts, urlView } from '../../routeUtils';
import EditableFields from './EditableFields';
import { parseRouteViewItemOverview } from '../routeUtils';
import { isNull } from 'ramda-adjunct';
import moment from 'moment';

type Props = {
  appConfig: IAppConfig;
  binderActions: typeof binderActions;
  binderOperations: typeof binderOperations;
  engageCategories: Array<ICategory>;
  engageCategoriesError?: string;
  error: Record<string, any>;
  form: Record<string, any>;
  history: RouterHistory;
  isReadOnly: (...args: Array<any>) => any;
  landingPageTypesLoading: boolean;
  loading: boolean;
  match: Match;
  populateFormVariants: (view: IView | null | undefined, viewItem: IViewItem, categories: Array<ICategory>) => Action;
  resetCreativeForm: () => Action;
  saveViewItemLoading: boolean;
  selectedDates: Record<string, any>;
  slotGroupTypes: Array<ISlotGroupType>;
  storefrontsMgmtOperations: typeof storefrontsMgmtOperations;
  view: Record<string, any> | null | undefined;
  viewItem: Record<string, any>;
};

class Overview extends PureComponent<Props> {
  componentDidMount() {
    const {
      appConfig,
      binderOperations: { fetchViewItem, getViewById },
      history,
      match,
      storefrontsMgmtOperations: { getAclSFUsers },
      view,
    } = this.props;

    const { useAclService, useSellerPanel } = getBannerCreativeConfig(appConfig);
    const { storefrontId, viewId, viewItemId } = parseRouteViewItemOverview({ history, match });

    if (viewItemId) {
      fetchViewItem(viewItemId);
    }

    if (!view || !view.slots || !view?.slots?.length) {
      getViewById(viewId, {
        redirect: () => history.push(urlStorefronts()),
        withSP: useSellerPanel,
      });
    }

    if (useAclService && !__DEV__ && storefrontId) {
      getAclSFUsers(storefrontId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      engageCategories,
      engageCategoriesError,
      populateFormVariants,
      slotGroupTypes,
      view,
      viewItem,
      form: { populated },
      binderActions: { setSelectedSlotGroupType, updateSelectedSlot },
    } = this.props;

    const viewId = result('view.id', view);
    const prevViewId = result('view.id', prevProps.view);

    if (viewId && viewItem) {
      if (viewId !== prevViewId) {
        const slot = view && view.slots.find(x => x.id === viewItem.slotId);
        const selectedSlotGroupType = slotGroupTypes.find(slotGroupType => slotGroupType.value === viewItem.type);

        slot && updateSelectedSlot(slot);
        selectedSlotGroupType && setSelectedSlotGroupType(selectedSlotGroupType);
      }

      if (!populated && (engageCategories?.length || engageCategoriesError)) {
        populateFormVariants(view?.view, viewItem, engageCategories);
      }
    }
  }

  componentWillUnmount() {
    const {
      binderActions: { resetViewItem },
      resetCreativeForm,
    } = this.props;

    resetViewItem();
    resetCreativeForm();
  }

  renderOverview() {
    const {
      binderOperations: { saveVariant, saveVariantStatus, saveViewItem },
      history,
      isReadOnly,
      landingPageTypesLoading,
      loading,
      match,
      saveViewItemLoading,
      selectedDates,
      view,
      viewItem,
    } = this.props;

    const { storefrontId } = parseRouteViewItemOverview({ history, match });

    if (!viewItem) {
      return loading ? <Spinner /> : <span>Not found</span>;
    }

    return landingPageTypesLoading ? (
      <Spinner size={50} />
    ) : (
      <>
        <Heading>Editable Fields</Heading>

        {!isNull(this.props.selectedDates.validFrom) &&
          Math.abs(moment(new Date()).diff(moment(this.props.selectedDates.validFrom), 'days')) > 30 && (
            <DrawerError
              error="CONFIRM: We suggest scheduled time to be within 30 days from today's date"
              errorDetails={''}
              allowDismiss={false}
              defaultExpanded={false}
            />
          )}

        <EditableFields
          loading={saveViewItemLoading}
          readOnly={isReadOnly(storefrontId)}
          saveViewItem={saveViewItem}
          selectedDates={selectedDates}
          view={view}
          viewItem={viewItem}
        />

        <Variants
          isCreative={false}
          readOnly={isReadOnly(storefrontId)}
          saveVariant={(variantKey: number) => saveVariant(viewItem, variantKey)}
          saveVariantStatus={saveVariantStatus}
          viewItem={viewItem}
        />
      </>
    );
  }

  render() {
    const { engageCategoriesError, error, history, match, viewItem } = this.props;
    const { entityId, entityType, storefrontId, viewId } = parseRouteViewItemOverview({ history, match });

    return (
      <Page>
        <PageHeader title="" backLink={urlView(viewId, storefrontId, entityId, entityType)}>
          <HeaderTitleLink to={urlStorefrontEdit(storefrontId, entityId, entityType)}>{storefrontId}</HeaderTitleLink>

          <Icon name="chevron-right" size={35} />

          <HeaderTitleLink to={urlView(viewId, storefrontId, entityId, entityType)}>{viewId}</HeaderTitleLink>

          <Icon name="chevron-right" size={35} />

          <HeaderTitle>
            {viewItem ? <p>{viewItem.name}</p> : null}

            {viewItem && viewItem.id ? <span>{`ID: ${viewItem.id}`}</span> : null}

            {viewItem && viewItem.sourceCreativeId ? <span>{`Creative ID: ${viewItem.sourceCreativeId}`}</span> : null}
          </HeaderTitle>
        </PageHeader>

        <PageMain>
          {error?.msg && (
            <DrawerError error="Error while retrieving viewItem" errorDetails={error.msg} allowDismiss={false} defaultExpanded>
              <p>If this error persists, please report the folllowing error message:</p>
            </DrawerError>
          )}

          {engageCategoriesError && (
            <DrawerError
              error="Error while retrieving categories"
              errorDetails={engageCategoriesError}
              allowDismiss={false}
              defaultExpanded
            >
              <p>If this error persists, please report the folllowing error message:</p>
            </DrawerError>
          )}

          {this.renderOverview()}
        </PageMain>
      </Page>
    );
  }
}

const mapStateToProps = ({ acls: { storefrontsMgmt }, bannerManagement: { binder, creative }, user }) => ({
  error: binder.error,
  form: creative.form,
  isReadOnly: isReadOnlyStorefront(user, storefrontsMgmt),
  loading: binder.viewItemLoading,
  saveViewItemLoading: binder.saveViewItemLoading,
  selectedDates: binder.selectedDates,
  view: binder.view,
  viewItem: binder.viewItem,
});

const mapDispatchToProps = {
  populateFormVariants: actionPopulateFormVariants,
  resetCreativeForm: actionResetCreativeForm,
};

export default compose(
  withAppConfig,
  withBannerDetailContext,
  connect(mapStateToProps, mapDispatchToProps),
  inject({
    binderActions: binderActions,
    binderOperations: binderOperations,
    storefrontsMgmtOperations,
  }),
)(Overview);

const Icon = styled(_Icon)`
  margin: 0 ${sc.gutterSmallest};
`;

const PageHeader = styled(_PageHeader)`
  justify-content: start;
`;

const PageMain = styled(_PageMain)`
  padding: 0 ${sc.gutterLarge} ${sc.gutterLargest} ${sc.gutterLarge};
`;

const Spinner = styled(_Spinner)`
  padding: 200px 0;
`;
