import { branch, compose } from 'recompose';
import { identity } from 'ramda';

import { IPagingSelectorFunction } from 'app/hocs/withReporting/withPaging';
import { SearchDefsArg } from 'app/hocs/withReporting/withSearch';

import withFetchRecords from './withFetchRecords';
import { withPropsReader, withUrlReader } from './readers';
import withPaging from './withPaging';
import withSearch from './withSearch';

/**
 * Fetches records whenever the report state is changed.
 *
 * # Fetching
 * Whenever the report state is changed, records are retrieved during the `componentDidUpdate` lifecycle phase.
 *
 * # Paging
 * Adds support for paging. This assumes that the initial paging state is contained in Redux.
 *
 * The `pagingSelector` parameter is the function which takes the Redux state as the single argument,
 * and returns the properties which represent the current paging state.
 *
 * If `pagingSelector` is ommitted, no paging functionality is injected.
 *
 * # Search
 * TODO
 *
 * @param {string} fetchRecordsFnName (required)
 * @param {IPagingSelectorFunction} pagingSelector (optional)
 * @param {{ searchDefiner?: SearchDefsArg, searchDefs?: ISearchDefs, defaultSearch?: Function }} search (optional)
 */
export default (
  fetchRecordsFnName: string,
  pagingSelector?: IPagingSelectorFunction | null,

  search?: { searchDefiner?: SearchDefsArg; searchDefs?: SearchDefsArg; defaultSearch?: (...args: Array<any>) => any } | null,
) =>
  compose(
    branch(({ reportingState }) => !!reportingState, withPropsReader, withUrlReader),
    pagingSelector ? withPaging(pagingSelector) : identity,
    // TODO: Temporarily provide backward compatibility for search.searchDefs
    search && (search.searchDefiner || search.searchDefs)
      ? withSearch(search.searchDefiner || search.searchDefs || (() => []), search.defaultSearch)
      : identity,
    withFetchRecords(fetchRecordsFnName),
  );

export * from './withPaging';
export * from './withSearch';
