import styled from 'styled-components';

export const TableWrapper = styled.section`
  width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
`;
