import { Action } from 'app/types';
import * as types from './types';

export const getAclSFUsersSuccess = (users: Array<string> | null | undefined): Action => ({
  type: types.GET_ACL_STOREFRONT_USERS_SUCCESS,
  payload: { users },
});

export const getAclSFUsersFailed = (): Action => ({
  type: types.GET_ACL_STOREFRONT_USERS_FAILED,
});
