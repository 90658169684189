import * as React from 'react';
import PropTypes from 'prop-types';

import { Typeahead } from 'app/midgarComponents';

const VariableSelection = ({ selectVariable, getSuggestions, type }) => (
  <Typeahead
    debounceTime={500}
    getSuggestions={getSuggestions}
    onItemSelect={selectVariable}
    placeholder="Type to search in variables..."
    name={`${type}Variables` || 'variables'}
  />
);

VariableSelection.propTypes = {
  selectVariable: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default VariableSelection;
