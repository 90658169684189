import React from 'react';
import PropTypes from 'prop-types';

import MultiSelectDialog from '../MultiSelectDialog';
import MultiValueTextField from '../MultiValueTextField';

export const ReadOnlyInputWithMultiSelectDialog = ({
  id,
  label,
  selection,
  stringifier,
  options,
  handleOpen,
  handleClose,
  isOpen,
  dialogTitle,
  classes,
}) => (
  <>
    <MultiValueTextField
      {...{
        id,
        label,
        stringifier,
        handleOpen,
        classes,
        values: selection,
        tooltipId: `${id}-tooltip`,
      }}
    />

    <MultiSelectDialog
      {...{
        selection,
        isOpen,
        options,
        dialogTitle,
        classes,
        onClose: handleClose,
      }}
    />
  </>
);

ReadOnlyInputWithMultiSelectDialog.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  stringifier: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
