import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import window from 'global/window';
import styled from 'styled-components';

import ImagePreviewModal from 'app/features/BannerManagement/common/ImagePreviewModal';
import { sc } from 'app/styles';
import { IViewItem } from 'app/types/BannerManagement';
import { findPageFromPriority } from 'app/utilities/pagination';

import BulkActionsToast from '../BulkActions/BulkActionsToast';
import MoveModal from './MoveModal';
import MultiSelectCheckbox from './MultiSelectCheckbox';
import Pagination from './Pagination';
import SortableItem from './SortableItem';

type Props = {
  entityId?: number;
  entityType?: string;
  fetchSlotViewItems: (...args: Array<any>) => any;
  highlightedViewItem: IViewItem | null | undefined;
  onBind: (arg0: number | null | undefined) => (...args: Array<any>) => any;
  onDelete: (...args: Array<any>) => any;
  onPageChange: (...args: Array<any>) => any;
  readOnly?: boolean;
  reorderViewItems: (...args: Array<any>) => any;
  slotViewItems: Array<IViewItem>;
  slotViewItemsPage: number;
  slotViewItemsPageSize: number;
  slotViewItemsTotalPages: number;
  storefrontId: number;
  timezone: string;
  viewId: any;
};

type State = {
  isMoveModalOpen: boolean;
  isPreviewModalOpen: boolean;
  selected: Array<IViewItem>;
  selectedViewItem: IViewItem;
};

export default class SortableTable extends React.PureComponent<Props, State> {
  state = {
    isMoveModalOpen: false,
    isPreviewModalOpen: false,
    selected: [],
    selectedViewItem: {},
  };

  componentDidMount() {
    const { highlightedViewItem, slotViewItemsPageSize } = this.props;

    if (highlightedViewItem && highlightedViewItem.id) {
      const TABLE_OFFSET = 50;
      const BANNER_HEIGHT = 110;
      const PAGE = findPageFromPriority(highlightedViewItem.priority, slotViewItemsPageSize);
      const PAGE_OFFSET = (PAGE - 1) * slotViewItemsPageSize;

      window.scroll({
        top: TABLE_OFFSET + BANNER_HEIGHT * (highlightedViewItem.priority - PAGE_OFFSET),
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  onSelect = (ev: Event, element: Record<string, any>, sortIndex: number) => {
    const { selected } = this.state;
    const elementIdx = selected.findIndex(x => x.id === element.id);
    const updated = selected.slice();

    ev.preventDefault();
    ev.stopPropagation();

    if (elementIdx > -1) {
      updated.splice(elementIdx, 1);
    } else {
      updated.push({ ...element, sortIndex });
    }

    this.setState({
      selected: updated.sort((a: Record<string, any>, b: Record<string, any>) => a.sortIndex - b.sortIndex),
    });
  };

  onSelectAll = () => {
    const { selected } = this.state;
    const { slotViewItems } = this.props;

    if (selected.length === slotViewItems.length) {
      this.setState({ selected: [] });
    } else {
      this.setState({ selected: slotViewItems });
    }
  };

  onDeselectAll = () => {
    this.setState({ selected: [] });
  };

  openPreviewModal = (viewItem: IViewItem) => {
    this.setState({
      selectedViewItem: viewItem,
      isPreviewModalOpen: true,
    });
  };

  openMoveModal = (viewItem: Record<string, any>) => {
    this.setState({
      selectedViewItem: viewItem,
      isMoveModalOpen: true,
    });
  };

  onDragEnd = (result: any) => {
    const { selected } = this.state;
    const { slotViewItems, reorderViewItems } = this.props;

    if (!result.destination) {
      return;
    }

    const updated = slotViewItems.slice();

    if (selected && selected.length) {
      selected.map(x => {
        const idx = updated.findIndex(y => y.id === x.id);

        if (idx > -1) {
          updated.splice(idx, 1);
        }
      });

      updated.splice(result.destination.index, 0, ...selected);
    } else {
      const [removed] = updated.splice(result.source.index, 1);
      updated.splice(result.destination.index, 0, removed);
    }

    reorderViewItems(updated);
  };

  render() {
    const { isMoveModalOpen, isPreviewModalOpen, selected, selectedViewItem } = this.state;

    const {
      entityId,
      entityType,
      fetchSlotViewItems,
      highlightedViewItem,
      onBind,
      onDelete,
      onPageChange,
      readOnly,
      slotViewItems,
      slotViewItemsPage,
      slotViewItemsPageSize,
      slotViewItemsTotalPages,
      storefrontId,
      timezone,
      viewId,
    } = this.props;

    const selectedIds = selected.map(x => x.id);

    return (
      <Container>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef}>
                {slotViewItems.map((viewItem, index) => (
                  <Draggable key={viewItem.id} draggableId={String(viewItem.id)} index={index}>
                    {({ innerRef, draggableProps, dragHandleProps }) => (
                      <div ref={innerRef} {...draggableProps}>
                        <SortableItem
                          key={viewItem.id}
                          dragHandleProps={dragHandleProps}
                          entityId={entityId}
                          entityType={entityType}
                          isHighlighted={highlightedViewItem ? highlightedViewItem.id === viewItem.id : false}
                          isSelected={selected.findIndex(x => x.id === viewItem.id) > -1}
                          onBind={onBind}
                          onDelete={onDelete}
                          onSelect={ev => this.onSelect(ev, viewItem, index)}
                          openMoveModal={this.openMoveModal}
                          openPreviewModal={this.openPreviewModal}
                          readOnly={readOnly}
                          storefrontId={storefrontId}
                          timezone={timezone}
                          variantStatus={viewItem?.variants?.[0].status}
                          viewId={viewId}
                          viewItem={viewItem}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {slotViewItems.length > 0 && (
          <MultiSelectContainer>
            <MultiSelectCheckbox onSelect={selected => this.setState({ selected })} selected={selected} slotViewItems={slotViewItems} />
          </MultiSelectContainer>
        )}

        {!!selected.length && <BulkActionsToast selectedIds={selectedIds} onDeselectAll={this.onDeselectAll} />}

        <ImagePreviewModal
          closeModal={() => this.setState({ isPreviewModalOpen: false })}
          isOpen={isPreviewModalOpen}
          readOnly={readOnly}
          viewItem={selectedViewItem}
          withStatus
        />

        <MoveModal
          isMoving={false}
          selected={selected}
          viewItem={selectedViewItem}
          isOpen={isMoveModalOpen}
          fetchSlotViewItems={fetchSlotViewItems}
          closeModal={() => this.setState({ isMoveModalOpen: false })}
        />

        <Pagination
          onPageChange={onPageChange}
          slotViewItemsPage={slotViewItemsPage}
          slotViewItemsPageSize={slotViewItemsPageSize}
          slotViewItemsTotalPages={slotViewItemsTotalPages}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: ${sc.gutter} 0;

  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmaller};
  line-height: ${sc.fontSize};
  border-top: 1px solid ${sc.sectionBorderColor};
`;

const MultiSelectContainer = styled.div`
  position: absolute;
  left: 18px;
  top: -55px;
  z-index: 90;
`;
