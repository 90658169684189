import React from 'react';
import { Typography } from '@material-ui/core';
import withAppConfig from 'app/decorators/withAppConfig';

import { IAppConfig } from 'configs/apps/types';
import { VariablesProps } from './types';
import { Icon } from 'app/midgarComponents';
import { VariableSection, FilterContainer, FilterLabel, DeleteFilter } from './styledComponents';
import { Variable } from './Variable';
import { Filter } from './Filter';
import VariableErrors from './VariableErrors';
import VariableSelection from './VariableSelection';

type Props = VariablesProps & {
  appConfig: IAppConfig;
};

const Variables = ({
  appConfig,
  hideTitle,
  title,
  type,
  variables,
  getSuggestions,
  onSelect,
  html,
  features,
  removeVariable,
  updateDefault,
  prefix,
}: Props) => {
  const handleRemove = filterVariable => removeVariable(filterVariable);

  return features.error ? (
    <span>Something has gone wrong loading features</span>
  ) : (
    <VariableSection data-qa={type ? `${type}-variable-selection` : 'variable-selection'}>
      {!hideTitle && (
        <Typography color="primary" style={{ margin: '1rem 0' }} variant="subtitle1">
          {title || 'Variables'}
        </Typography>
      )}

      <VariableSelection style={{ margin: '1rem 0' }} getSuggestions={getSuggestions} selectVariable={onSelect} type={type} />
      {variables[type] &&
        variables[type].map((v, indx) => (
          <section key={indx}>
            {v['nested_types'] && v['nested_types'].length > 0 && appConfig.enableFeatureFilters ? (
              <FilterContainer>
                <FilterLabel>
                  <b>{v.name} </b>
                  filters
                  <DeleteFilter className="remove" tabIndex={0} role="button" onClick={() => handleRemove(v)}>
                    <Icon name="delete" />
                  </DeleteFilter>
                </FilterLabel>
                {v['nested_types'].map((nestedFeature, i) => (
                  <Filter
                    optionDict={features.data.dicts.optionDict}
                    key={nestedFeature.name}
                    type={type}
                    {...nestedFeature}
                    parentFeature={v}
                    updateDefault={updateDefault}
                    source={nestedFeature.source}
                    prefix={prefix}
                    rule={v.filterRules?.operands[0].operands[0].operands[i]}
                  />
                ))}
              </FilterContainer>
            ) : (
              <Variable
                key={v.name}
                type={type}
                {...v}
                name={v.name}
                removeVariable={removeVariable}
                updateDefault={updateDefault}
                source={v.source}
                prefix={prefix}
              />
            )}
          </section>
        ))}

      {features.data && <VariableErrors html={html} hashedValues={features.data.dicts.dataTypeDict} variables={variables[type]} />}
    </VariableSection>
  );
};

export default withAppConfig(Variables);
