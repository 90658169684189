import { combineReducers } from 'redux';

import template, { templateActions, templateOperations, templateSelectors } from './template';
import templates, { templatesActions, templatesOperations, templatesSelectors } from './templates';

export { templateActions, templateOperations, templateSelectors, templatesActions, templatesOperations, templatesSelectors };

export default combineReducers({
  template,
  templates,
});
