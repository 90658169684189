import { ICashbackBannerCreative } from 'app/features/CashbackPromoManagement/types';
import { IPaging } from 'app/hocs/withReporting';
import * as types from './types';

export const getError = (error: Error) => ({
  type: types.GET_ERROR,
  payload: { error },
});

export const getInit = () => ({
  type: types.GET_INIT,
});

export const getSuccess = (banners: Array<ICashbackBannerCreative>, paging?: IPaging) => ({
  type: types.GET_SUCCESS,
  payload: { banners, paging },
});
